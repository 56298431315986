import React from "react";

export const VideoAttach = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M3.775 2.75C3.4037 2.75 3.0476 2.90844 2.78505 3.19047C2.5225 3.47249 2.375 3.855 2.375 4.25384V19.2462C2.375 19.645 2.5225 20.0275 2.78505 20.3095C3.0476 20.5916 3.4037 20.75 3.775 20.75H21.975C22.3463 20.75 22.7024 20.5916 22.9649 20.3095C23.2275 20.0275 23.375 19.645 23.375 19.2462V7.21541C23.375 6.82321 23.2323 6.44654 22.9774 6.16573L20.2866 3.20316C20.1561 3.05962 20.0002 2.94558 19.828 2.86775C19.6559 2.78993 19.471 2.74989 19.2842 2.75H3.775Z"
            stroke="#808080"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19 8.5L16 9.5V8.5C16 8.10218 15.842 7.72064 15.5607 7.43934C15.2794 7.15804 14.8978 7 14.5 7H8.5C8.10218 7 7.72064 7.15804 7.43934 7.43934C7.15804 7.72064 7 8.10218 7 8.5V14.5C7 14.8978 7.15804 15.2794 7.43934 15.5607C7.72064 15.842 8.10218 16 8.5 16H14.5C14.8978 16 15.2794 15.842 15.5607 15.5607C15.842 15.2794 16 14.8978 16 14.5V13.5L19 14.5V8.5Z"
            stroke="#808080"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
