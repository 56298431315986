import React, { useEffect, useState } from "react";
import { Switch, Button, Breadcrumb, Input } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getCustomerDetails } from "../../redux/slice/customerManagementSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { isRouteExistInMenu } from "../../utils/commonFunctions";

const ViewCustomerDetails = ({ match }) => {
  var CryptoJS = require("crypto-js");
  const Tablet = useMediaQuery({ maxWidth: 1025 });
  const dispatch = useDispatch();
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // setUserType(decryptedData);

    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      setTimeout(() => {
        setCustomerLoading(false);
      }, 2000);
      getSelectedCustomerDetails();
    }
  }, [CryptoJS.AES]);

  const getSelectedCustomerDetails = async () => {
    const response = await dispatch(
      getCustomerDetails({ customer_id: match.params.customer_id })
    );
    const { details } = response.payload.data.data;
    setCustomerDetails(details);
  };

  if (customerLoading) return <CommonLoader />;
  else
    return (
      <div className="view-all-common">
        <div className="d-flex justify-content-between common-sticky-header bg-light w-100">
          <div>
            <h4 className="fs-24">View Customer Details</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>
                <Link to="/customermanagement">Admin Console</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/customermanagement">Customer Management</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>View Customer Details</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        {/* <Main Div */}
        <div className="bg-white p-3">
          {/* section account details */}
          <section>
            <div className="row mt-5">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20 px-2">
                  Account Details
                </h6>
              </div>
            </div>
            <div className="row mt-4">
              <div className={Tablet ? "col-4 text-end" : "col-3 text-end"}>
                <label htmlFor="" className="text-secondary">
                  Active
                </label>
              </div>
              <div className={Tablet ? "col-2" : "col-1"}>
                <Switch checked={customerDetails.is_active} />
              </div>
              <div className="col-3">
                {/* <Button type="primary" style={{ width: "190px" }}><span className="px-2"><BinWhite /></span>Delete Account</Button> */}
              </div>
            </div>
            <div className="row mt-4">
              <div className={Tablet ? "col-4 text-end" : "col-3 text-end"}>
                <label htmlFor="" className="text-secondary mt-1">
                  Account Name
                </label>
              </div>
              <div className="col-3">
                <Input
                  name=""
                  id=""
                  className="w-100 border-gray rounded fs-bold-14"
                  value={customerDetails?.account_name}
                  disabled
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className={Tablet ? "col-4 text-end" : "col-3 text-end"}>
                <label htmlFor="" className="text-secondary">
                  License Start Date
                </label>
              </div>
              <div className="col-3">
                <Input
                  disabled
                  type="text"
                  className="w-1oo border-gray rounded fs-bold-14"
                  value={moment(
                    customerDetails?.license_period_start_date
                  ).format("DD-MM-YYYY")}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className={Tablet ? "col-4 text-end" : "col-3 text-end"}>
                <label htmlFor="" className="text-secondary">
                  License End Date
                </label>
              </div>
              <div className="col-3">
                <Input
                  disabled
                  type="text"
                  className="w-1oo border-gray rounded fs-bold-14"
                  value={moment(
                    customerDetails?.license_period_end_date
                  ).format("DD-MM-YYYY")}
                />
              </div>
            </div>

            <div className="row mt-4 ">
              <div className={Tablet ? "col-4 text-end" : "col-3 text-end"}>
                <label htmlFor="" className="text-secondary mt-1">
                  Main Contact Name
                </label>
              </div>
              <div className="col-3">
                <Input
                  disabled
                  name=""
                  id=""
                  className="w-100 border-gray rounded fs-bold-14"
                  value={customerDetails?.main_contact_person}
                />
              </div>
            </div>

            <div className="row mt-4 pb-4 border-bottom-gray">
              <div className={Tablet ? "col-4 text-end" : "col-3 text-end"}>
                <label htmlFor="" className="text-secondary mt-1">
                  Email Address
                </label>
              </div>
              <div className="col-3">
                <Input
                  disabled
                  name=""
                  id=""
                  className="w-100 border-gray rounded fs-bold-14"
                  value={customerDetails?.main_contact_email}
                />
              </div>
              <div className="col-3"></div>
            </div>
          </section>

          {/* section Account Permissions */}
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20 px-2">
                  Account Permissions
                </h6>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Max # Events
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_no_of_events === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails?.customer_customer_settings_id
                        ?.max_no_of_events
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Max # Concurrent Events
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_no_of_concurrent_events === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails?.customer_customer_settings_id
                        ?.max_no_of_concurrent_events
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Max # Events Per Month
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_no_events_per_month === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails?.customer_customer_settings_id
                        ?.max_no_events_per_month
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Max # Brands
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_no_of_exhibitors === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails?.customer_customer_settings_id
                        ?.max_no_of_exhibitors
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Max # Brands Per Event
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_no_of_exhibitors_per_event === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails?.customer_customer_settings_id
                        ?.max_no_of_exhibitors_per_event
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Max # Users
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_no_of_users === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails.customer_customer_settings_id
                        ?.max_no_of_users
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Max # Concurrent Users
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_no_of_concurrent_users === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails.customer_customer_settings_id
                        ?.max_no_of_concurrent_users
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  {/* Max # Bandwidth per month Gb */}
                  Streams : Max Gb Bandwidth Per Month
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_bandwidth_per_month === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails.customer_customer_settings_id
                        ?.max_bandwidth_per_month
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  {/* Max # Storage Size Gb */}
                  Storage : Max Gb
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_storage_size === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails.customer_customer_settings_id
                        ?.max_storage_size
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4 pb-4 ">
              <div
                className={
                  Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  {/* Max # Storage Size Gb */}
                  Video Rooms : Max Hours Per Month
                </label>
              </div>
              <div className={Tablet ? "col-2 mt-1" : "col-1 mt-1"}>
                <Switch
                  checked={
                    customerDetails &&
                    customerDetails.customer_customer_settings_id
                      ?.max_video_room_hours_per_month === 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-2 text-start">
                <div
                  class="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                  role="group"
                  aria-label="Second group"
                >
                  <input
                    name="max_no_of_events"
                    type="number"
                    placeholder="Max # Events"
                    className="border-gray rounded px-2 w-100 text-center"
                    value={
                      customerDetails?.customer_customer_settings_id &&
                      customerDetails.customer_customer_settings_id
                        ?.max_video_room_hours_per_month
                    }
                    min="0"
                    style={{ border: "transparent" }}
                    disabled
                  />
                </div>
              </div>
            </div>
          </section>

          {/* section Cancel */}
          <section>
            <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
              <div></div>
              <div>
                <Link to="/customermanagement">
                  <Button className="rounded button-pink px-3">Cancel</Button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
};

export default ViewCustomerDetails;
