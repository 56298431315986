import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

export const getCustomer = createAsyncThunk(
  "customerManagement/getCustomer",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/website/customer/get?is_details=false",
      {
        ...params,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    );
    return response.data.data;
  }
);

export const changeStatus = createAsyncThunk(
  "customerManagement/changeStatus",
  async (body) => {
    await ApiCall("PUT", body, "/website/customer/status/update");
    const response = await ApiCall(
      "GET",
      "",
      "/website/customer/get?is_details=false",
      { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }
    );
    return response.data.data;
  }
);

export const getCustomerDetails = createAsyncThunk(
  "customerManagement/getCustomerDetails",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/website/customer/details/get",
      params,
      true
    );

    return response;
  }
);

export const createCustomer = createAsyncThunk(
  "customerManagement/createCustomer",
  async (body) => {
    const response = await ApiCall("POST", body, "/website/customer/create");
    return response.data;
  }
);

export const editCustomer = createAsyncThunk(
  "customerManagement/editCustomer",
  async (body) => {
    const response = await ApiCall("PUT", body, "/website/customer/update");
    return response.data;
  }
);

export const createRegisterCustomer = createAsyncThunk(
  "customerManagement/createCustomer",
  async (body) => {
    const response = await ApiCall(
      "POST",
      body,
      "/website/customer/password/create",
      "",
      null
    );

    return response.data;
  }
);

export const customerManagementSlice = createSlice({
  name: "customerManagement",
  initialState: {
    loading: false,
    data: [],
    customerDetails: [],
  },
  reducers: {},
  extraReducers: {
    [getCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomer.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [getCustomer.rejected]: (state, action) => {
      state.loading = false;
    },

    [editCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [editCustomer.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [editCustomer.rejected]: (state, action) => {
      state.loading = false;
    },
    [getCustomerDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomerDetails.fulfilled]: (state, { payload, meta }) => {
      state.customerDetails = payload;
      state.loading = false;
    },
    [getCustomerDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [createCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [createCustomer.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
    },
    [createCustomer.rejected]: (state, action) => {
      state.loading = false;
    },
    [createRegisterCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [createRegisterCustomer.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
    },
    [createRegisterCustomer.rejected]: (state, action) => {
      state.loading = false;
    },
    [changeStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [changeStatus.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [changeStatus.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const customerReducer = customerManagementSlice.reducer;
