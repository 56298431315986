import React from "react";

export const Calendar3 = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="#000000"
			>
				<g id="Group_1096" data-name="Group 1096">
					<g id="Group_1095" data-name="Group 1095">
                        <path id="Path_629" data-name="Path 629" d="M279,280.4a1.564,1.564,0,0,0,1.563,1.563H282.4a1.564,1.564,0,0,0,1.563-1.562v-1.836A1.564,1.564,0,0,0,282.4,277h-1.836A1.564,1.564,0,0,0,279,278.563Zm1.563-1.836H282.4V280.4h-1.837Z" transform="translate(-268.102 -266.18)"/>
                        <path id="Path_630" data-name="Path 630" d="M19.219,13.75A.781.781,0,0,0,20,12.969V4.688a3.129,3.129,0,0,0-3.125-3.125H15.859V.781a.781.781,0,0,0-1.562,0v.781H10.742V.781a.781.781,0,0,0-1.562,0v.781H5.664V.781A.781.781,0,0,0,4.1.781v.781H3.125A3.129,3.129,0,0,0,0,4.688V16.875A3.129,3.129,0,0,0,3.125,20h13.75A3.129,3.129,0,0,0,20,16.875a.781.781,0,0,0-1.562,0,1.564,1.564,0,0,1-1.562,1.563H3.125a1.564,1.564,0,0,1-1.562-1.562V4.688A1.564,1.564,0,0,1,3.125,3.125H4.1v.781a.781.781,0,0,0,1.563,0V3.125H9.18v.781a.781.781,0,0,0,1.563,0V3.125H14.3v.781a.781.781,0,0,0,1.563,0V3.125h1.016a1.564,1.564,0,0,1,1.563,1.563v8.281A.781.781,0,0,0,19.219,13.75Z"/>
                        <circle id="Ellipse_23" data-name="Ellipse 23" cx="0.86" cy="0.86" r="0.86" transform="translate(7.516 10.636)"/>
                        <circle id="Ellipse_24" data-name="Ellipse 24" cx="0.86" cy="0.86" r="0.86" transform="translate(7.516 13.939)"/>
                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="0.86" cy="0.86" r="0.86" transform="translate(4.192 7.333)"/>
                        <circle id="Ellipse_26" data-name="Ellipse 26" cx="0.86" cy="0.86" r="0.86" transform="translate(7.495 7.333)"/>
                        <circle id="Ellipse_27" data-name="Ellipse 27" cx="0.86" cy="0.86" r="0.86" transform="translate(10.799 7.333)"/>
                        <circle id="Ellipse_28" data-name="Ellipse 28" cx="0.86" cy="0.86" r="0.86" transform="translate(14.102 7.333)"/>
                        <circle id="Ellipse_29" data-name="Ellipse 29" cx="0.86" cy="0.86" r="0.86" transform="translate(4.192 13.947)"/>
                        <circle id="Ellipse_30" data-name="Ellipse 30" cx="0.86" cy="0.86" r="0.86" transform="translate(4.192 10.636)"/>
					</g>
				</g>
			</svg>
		</>
	);
};
