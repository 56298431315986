import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminLayout from "../components/layouts/AdminLayout";
import EventManagement from "../views/eventManagement/index";
import CustomerManagement from "../views/customerManagement/index";
import Login from "../views/login";
import SurveyManagement from "../views/surveyManagement/SurveyManagement";
import AddNewSurvey from "../views/surveyManagement/AddNewSurvey";
import CreateNewAccount from "../views/customerManagement/CreateNewAccount";
import BrandManagement from "../views/brandManagement";
import CreateNewBrand from "../views/brandManagement/CreateNewBrand";
import UserManagement from "../views/UserManagement/UserManagement";
import InviteAttend from "../views/UserManagement/InviteAttend";
import CreateNewZone from "../views/ZoneManagement/CreateNewZone";
import ZoneManagement from "../views/ZoneManagement/ZoneManagement";
import ViewEvent from "../views/eventManagement/ViewEvent";
import ViewBrandDetails from "../views/brandManagement/ViewBrandDetails";
import EditBrandDetails from "../views/brandManagement/EditBrandDetails";
import ViewCustomerDetails from "../views/customerManagement/ViewCustomerDetails";
import EditEvent from "../views/eventManagement/EditEvent";
import ViewSurvey from "../views/surveyManagement/ViewSurvey";
import EditSurvey from "../views/surveyManagement/EditSurvey";
import AddUser from "../views/UserManagement/AddUser";
import EditZone from "../views/ZoneManagement/EditZone";
import ViewZone from "../views/ZoneManagement/ViewZone";
import EditCustomer from "../views/customerManagement/EditCustomer";
import ViewUser from "../views/UserManagement/ViewUser";
import EditUser from "../views/UserManagement/EditUser";
import Registration from "../views/registration";
import MyCalendar from "../views/myCalendar/MyCalendar";
import BrandZoneDetails from "../views/ExpoMap/BrandZoneDetails";
import ExpoMap from "../views/ExpoMap/ExpoMap";
import ZoneCalendar from "../views/ZoneCalender/ZoneCalender";
import EventCalender from "../views/EventCalender";
import BrandCalendarUI from "../views/brandCalendar";
import Messages from "../views/messages";
import Brand from "../views/brand";
import SocialWall from "../views/socialWall";
import Networking from "../views/networking";
import HelpAndSupport from "../views/HelpAndSupport";
import Feedback from "../views/feedback";
import People from "../views/People/People";
import MyProfile from "../views/MyProfile/MyProfile";
import Auditorium from "../views/auditorium";
import Popups from "../views/Popups";
import Survey from "../views/surveyManagement/surveyscreens";
import ResetPassword from "../views/reset-password";
import ForgotPassword from "../views/forgot-password";
import NetworkTableUI from "../views/networkTable";
import CustomerRegistration from "../views/customerManagement/CustomerRegistration";
import PageNotFound from "../views/common/PageNotFound";
import PageNotAvailable from "../views/common/PageNotAvailable";
import Analytics from "../views/analytics";
import WelcomePage from "../views/common/WelcomePage";
import VideoPlayerPage from "../views/common/VideoPlayerPage";
import VideoConference from "../views/VideoConference/VideoConference";
import MeetContainer from "../views/VideoConference/MeetContainer";
import Streaming from "../views/VideoConference/Streaming";
import GroupChat from "../views/GroupChat/GroupChat";
import SurveyModal from "../views/common/Modal/SurveyModal";
import { isNonAdminLevelUser } from "../utils/commonFunctions";
import GoogleAuth from "../views/common/GoogleAuth";
import Privacy from "../views/privacy/index";
import GoogleSuccessPage from "../views/common/GoogleSuccessPage";
import MessagesUI from "../views/MessagesUI/MessagesUi";

const Main = (mainRouteProps) => {
  const { isLoggedIn } = useSelector((state) => state.login);
  const userType = localStorage.getItem("userType");
  const AdminRoute = ({ component: Component, ...rest }) => {
    return (
      <AdminLayout {...mainRouteProps}>
        <Route
          {...rest}
          {...mainRouteProps}
          render={(props) =>
            // <Component {...props} />
            isLoggedIn ? (
              <Component {...props} {...mainRouteProps} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      </AdminLayout>
    );
  };

  return (
    <Switch>
      <Route exact={true} path="/registration" component={Registration} />
      <Route path="/login" exact={true} component={Login} />
      <Route exact={true} path="/resetpass" component={ResetPassword} />
      <Route exact={true} path="/forgotpass" component={ForgotPassword} />
      <Route exact={true} path="/privacyandpolicy" component={Privacy} />
      <Route
        exact={true}
        path="/admin/google/oauth2authenticationcallback"
        component={GoogleSuccessPage}
      />

      <Route
        exact={true}
        path="/googleSuccessPage"
        component={GoogleSuccessPage}
      />

      <Route
        exact={true}
        path="/customer-registration"
        component={CustomerRegistration}
      />
      <Route exact={true} path="/videoplayer" component={VideoPlayerPage} />
      {/* <Route
        exact={true}
        path="/streaming"
        component={Streaming}
        {...mainRouteProps}
      /> */}
      <Route
        exact={true}
        path="/streaming"
        // component={Streaming}
        // {...mainRouteProps}
        params={{ ...mainRouteProps }}
        render={(props) => <Streaming {...props} {...mainRouteProps} />}
      />
      {userType &&
      (isNonAdminLevelUser(userType) || userType === "BRAND ADMIN") ? (
        <AdminRoute exact={true} path="/" component={Messages} />
      ) : userType &&
        (userType === "SUPER ADMIN" || userType === "CUSTOMER") ? (
        <AdminRoute exact={true} path="/" component={EventCalender} />
      ) : (
        <AdminRoute exact={true} path="/" component={MyProfile} />
      )}

      <AdminRoute exact={true} path="/groupChat" component={GroupChat} />
      <AdminRoute
        exact={true}
        path="/eventmanagement"
        component={EventManagement}
      />
      <AdminRoute
        exact={true}
        path="/customermanagement"
        component={CustomerManagement}
      />
      <AdminRoute exact={true} path="/createevent" component={EditEvent} />
      <AdminRoute
        exact={true}
        path="/viewevent/:event_id"
        component={ViewEvent}
      />
      <AdminRoute
        exact={true}
        path="/editevent/:event_id"
        component={EditEvent}
      />
      <AdminRoute
        exact={true}
        path="/surveyManagement"
        component={SurveyManagement}
      />
      <AdminRoute exact={true} path="/addnewsurvey" component={AddNewSurvey} />
      <AdminRoute
        exact={true}
        path="/createnewaccount"
        component={CreateNewAccount}
      />
      <AdminRoute
        exact={true}
        path="/brandmanagement"
        component={BrandManagement}
      />
      <AdminRoute
        exact={true}
        path="/createnewbrand"
        component={CreateNewBrand}
      />
      <AdminRoute
        exact={true}
        path="/usermanagement"
        component={UserManagement}
      />
      <AdminRoute exact={true} path="/inviteattend" component={InviteAttend} />
      <AdminRoute exact={true} path="/adduser" component={AddUser} />
      <AdminRoute
        exact={true}
        path="/zonemanagement"
        component={ZoneManagement}
      />
      <AdminRoute
        exact={true}
        path="/createnewzone"
        component={CreateNewZone}
      />
      <AdminRoute
        exact={true}
        path="/viewbranddetails/:brand_id"
        component={ViewBrandDetails}
      />
      <AdminRoute
        exact={true}
        path="/editbranddetails/:brand_id"
        component={EditBrandDetails}
      />
      <AdminRoute exact={true} path="/editzone/:zone_id" component={EditZone} />
      <AdminRoute exact={true} path="/viewzone/:zone_id" component={ViewZone} />
      <AdminRoute
        exact={true}
        path="/viewcustomerdetails/:customer_id"
        component={ViewCustomerDetails}
      />
      <AdminRoute
        exact={true}
        path="/editcustomer/:customer_id"
        component={EditCustomer}
      />
      <AdminRoute
        exact={true}
        path="/viewsurvey/:surveyid"
        component={ViewSurvey}
      />
      <AdminRoute
        exact={true}
        path="/editsurvey/:surveyid"
        component={EditSurvey}
      />
      <AdminRoute exact={true} path="/viewuser/:userid" component={ViewUser} />
      <AdminRoute exact={true} path="/edituser/:userid" component={EditUser} />
      <AdminRoute exact={true} path="/mycalendar" component={MyCalendar} />
      <AdminRoute
        {...mainRouteProps}
        exact={true}
        path="/brand"
        component={Brand}
      />
      <AdminRoute exact={true} path="/expomap" component={ExpoMap} />
      <AdminRoute exact={true} path="/concourse" component={ExpoMap} />
      <AdminRoute
        exact={true}
        path="/expomap/brandzonedetails/:brand_id/:zone_id"
        component={BrandZoneDetails}
        {...mainRouteProps}
      />
      <AdminRoute
        exact={true}
        path="/zonecalendar/:event_id/:brand_id/:zone_id"
        component={ZoneCalendar}
      />
      <AdminRoute
        exact={true}
        path="/eventCalendar"
        component={EventCalender}
      />
      <AdminRoute
        exact={true}
        path="/brandCalendar/:event_id/:brand_id"
        component={BrandCalendarUI}
      />
      <AdminRoute exact={true} path="/people" component={People} />
      <AdminRoute exact={true} path="/myprofile" component={MyProfile} />
      <AdminRoute exact={true} path="/messages" component={Messages} />
      <AdminRoute exact={true} path="/auditorium" component={Auditorium} />
      <AdminRoute exact={true} path="/socialWall" component={SocialWall} />
      <AdminRoute
        exact={true}
        path="/networking"
        component={Networking}
        {...mainRouteProps}
      />
      <AdminRoute
        exact={true}
        path="/networkTable"
        component={NetworkTableUI}
      />
      <AdminRoute exact={true} path="/analysis" component={Analytics} />
      <AdminRoute
        {...mainRouteProps}
        exact={true}
        path="/helpAndSupport"
        component={HelpAndSupport}
      />
      <AdminRoute exact={true} path="/feedback" component={Feedback} />
      <AdminRoute exact={true} path="/popups" component={Popups} />
      <AdminRoute exact={true} path="/userSurvey" component={Survey} />
      <AdminRoute exact={true} path="/welcomepage" component={WelcomePage} />
      <AdminRoute exact={true} path="/pagenotfound" component={PageNotFound} />
      <AdminRoute
        exact={true}
        path="/videoConference"
        component={MeetContainer}
      />
      <AdminRoute
        exact={true}
        path="/videoConference2"
        component={VideoConference}
      />
      <AdminRoute
        exact={true}
        path="/registrationsurvey"
        component={SurveyModal}
      />
      <AdminRoute
        {...mainRouteProps}
        exact={true}
        path="/googleauth"
        component={GoogleAuth}
      />

      <AdminRoute
        {...mainRouteProps}
        exact={true}
        path="/messagesui"
        component={MessagesUI}
      />

      {/* Keep this route at last else routes declared after this wont be visible */}
      <Route path="*" component={PageNotAvailable} />
    </Switch>
  );
};

export default Main;
