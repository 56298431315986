import React from "react";

export const User = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="#000000"
			>
				<path id="Path_627" data-name="Path 627" d="M19.95,17.138a.781.781,0,0,0-1.53.318.816.816,0,0,1-.165.684.794.794,0,0,1-.625.3H2.371a.794.794,0,0,1-.625-.3.816.816,0,0,1-.165-.684,8.632,8.632,0,0,1,8.213-6.835q.1,0,.207,0t.208,0a8.594,8.594,0,0,1,6.941,3.815.781.781,0,1,0,1.3-.87,10.162,10.162,0,0,0-5.266-4,5.312,5.312,0,1,0-6.359,0A10.161,10.161,0,0,0,.051,17.138,2.374,2.374,0,0,0,2.371,20H17.63a2.374,2.374,0,0,0,2.32-2.862ZM6.25,5.313a3.75,3.75,0,1,1,3.94,3.745l-.19,0-.189,0A3.755,3.755,0,0,1,6.25,5.313Z" transform="translate(0)"/>
			</svg>
		</>
	);
};
