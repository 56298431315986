
import React from 'react'

export const EmailRed = () => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="20.055" height="15.316" viewBox="0 0 20.055 15.316"  fill="#ef0855">
  <g id="_159-email" data-name="159-email" transform="translate(0 -60.5)">
    <g id="Group_1268" data-name="Group 1268" transform="translate(0 60.5)">
      <path id="Path_49262" data-name="Path 49262" d="M19.272,69.548a.783.783,0,0,0,.783-.783V63.634A3.137,3.137,0,0,0,16.922,60.5H3.134A3.137,3.137,0,0,0,0,63.634v9.048a3.137,3.137,0,0,0,3.134,3.134H16.922a3.137,3.137,0,0,0,3.134-3.134.783.783,0,0,0-1.567,0,1.569,1.569,0,0,1-1.567,1.567H3.134a1.569,1.569,0,0,1-1.567-1.567V63.8l6.806,4.232a3.114,3.114,0,0,0,3.309,0L18.488,63.8v4.965A.783.783,0,0,0,19.272,69.548ZM10.855,66.7a1.557,1.557,0,0,1-1.655,0l-6.98-4.34a1.558,1.558,0,0,1,.913-.294H16.922a1.558,1.558,0,0,1,.913.294Z" transform="translate(0 -60.5)"/>
    </g>
  </g>
</svg>

        </>
    )
}

export const EmailWhite = () => {
  return (
      <>
<svg xmlns="http://www.w3.org/2000/svg" width="20.055" height="15.316" viewBox="0 0 20.055 15.316"  fill="#ffffff">
<g id="_159-email" data-name="159-email" transform="translate(0 -60.5)">
  <g id="Group_1268" data-name="Group 1268" transform="translate(0 60.5)">
    <path id="Path_49262" data-name="Path 49262" d="M19.272,69.548a.783.783,0,0,0,.783-.783V63.634A3.137,3.137,0,0,0,16.922,60.5H3.134A3.137,3.137,0,0,0,0,63.634v9.048a3.137,3.137,0,0,0,3.134,3.134H16.922a3.137,3.137,0,0,0,3.134-3.134.783.783,0,0,0-1.567,0,1.569,1.569,0,0,1-1.567,1.567H3.134a1.569,1.569,0,0,1-1.567-1.567V63.8l6.806,4.232a3.114,3.114,0,0,0,3.309,0L18.488,63.8v4.965A.783.783,0,0,0,19.272,69.548ZM10.855,66.7a1.557,1.557,0,0,1-1.655,0l-6.98-4.34a1.558,1.558,0,0,1,.913-.294H16.922a1.558,1.558,0,0,1,.913.294Z" transform="translate(0 -60.5)"/>
  </g>
</g>
</svg>

      </>
  )
}


