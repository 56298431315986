import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Select,
  Switch,
  Button,
  DatePicker,
  Table,
  Divider,
  Empty,
} from "antd";
import moment from "moment";
import "../eventCalender.css";
import { blackbin, close } from "../../../components/Images/Images";
import { CloseOutlined, HolderOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import { useDispatch, useSelector } from "react-redux";
import { getZone } from "../../../redux/slice/zoneManagementSlice";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import {
  getAudiSession,
  getCountryList,
  getJobTitles,
  getSpeakersForBrand,
  getAudiInvitationDetails,
  getUserTypeAgents,
  getBrandDropdowns,
  getZoneDropdowns,
  getZoneDetails,
} from "../../../utils/commonApis";
import { cloneDeep, isEmpty, orderBy } from "lodash";
import { mapSelectedDdlData } from "./modals.utils";
import { CalenderGrey } from "../../../components/svgJS/CalenderGrey";
import {
  createAudiSession,
  updateAudiSession,
} from "../../../redux/slice/eventCalender";
import { toast } from "react-toastify";
import ReactDragListView from "react-drag-listview";
import { dialogs } from "../../../components/constsnts/string.constants";
import { getMinutesDiff, ifTimeSlotAvailable } from "../eventCalender.utils";
import {
  sortData,
  createISOTimeString,
  createTZString,
  getDisabledTime,
} from "../../../utils/commonFunctions";
import { useGoogleLogin } from "@react-oauth/google";

const AudiSession = (props) => {
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const format = "HH:mm";
  const { rows: zoneRow } = useSelector((state) => state.zoneManagement.data);
  const validator = useRef(new SimpleReactValidator());
  const validatorAddBrand = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [producers_ids, set_producers_ids] = useState([]);
  const [selected_producers_ids, set_selected_producers_ids] = useState([]);
  const [final_producers_ids, set_final_producers_ids] = useState([]);
  const [assigned_agent_ids, set_assigned_agent_ids] = useState([]);
  const [selected_assigned_agent_ids, set_selected_assigned_agent_ids] =
    useState([]);
  const [final_assigned_agent_ids, set_final_assigned_agent_ids] = useState([]);
  const [restricted_users, set_restricted_users] = useState([]);
  const [selected_restricted_users, set_selected_restricted_users] = useState(
    []
  );
  const [final_restricted_users, set_final_restricted_users] = useState([]);
  const [restricted_brands, set_restricted_brands] = useState([]);
  const [selected_restricted_brands, set_selected_restricted_brands] = useState(
    []
  );
  const [final_restricted_brands, set_final_restricted_brands] = useState([]);
  const [restricted_countries, set_restricted_countries] = useState([]);
  const [selected_restricted_countries, set_selected_restricted_countries] =
    useState([]);
  const [final_restricted_countries, set_final_restricted_countries] = useState(
    []
  );
  const [restricted_job_titles, set_restricted_job_titles] = useState([]);
  const [selected_restricted_job_titles, set_selected_restricted_job_titles] =
    useState([]);
  const [final_restricted_job_titles, set_final_restricted_job_titles] =
    useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [countryCollection, setCountryCollection] = useState([]);
  const [jobTitleCollection, setJobTitleCollection] = useState([]);
  const [brandsCollection, setBrandsCollection] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);
  const [brandCollectionForInvitees, setBrandCollectionForInvitees] = useState(
    []
  );
  const [zoneCollectionForInvitees, setZoneCollectionForInvitees] = useState(
    []
  );
  const [speakerCollection, setSpeakerCollection] = useState([]);
  const [auditorium_session_details, set_auditorium_session_details] = useState(
    {
      is_active: true,
      start_date_time: moment(moment().format("HH:mm"), TIME_FORMAT),
      end_date_time: moment(moment().format("HH:mm"), TIME_FORMAT),
      session_title: "",
      session_description: "",
      is_iCal_enabled: false,
      stream_key: "",
      stream_url: "",
    }
  );

  const [defaultFlags] = useState({
    is_chat_enabled: false,
    is_demo_room_enabled: false,
    is_assets_enabled: false,
    is_booth_link_enabled: true,
    is_video_call_enabled: false,
  });
  const defaultBlankNewBrand = {
    hosting_brand_id: null,
    assigned_brand_id: null,
    brand_title: "",
    assigned_zone_id: null,
    auditorium_hosting_speakers: [],
    is_delete: false,
    is_new_brand: true,
    ...defaultFlags,
  };
  const [add_new_brand, set_add_new_brand] = useState(defaultBlankNewBrand);
  const [selectedZoneData, setSelectedZoneData] = useState(defaultFlags);
  const [source_details, set_source_details] = useState({
    source_configuration_id: null,
    is_this_platform_enabled: false,
    is_private_room_enabled: false,
    private_room_name: "",
    is_zone_demo_room_enabled: false,
    brand_id: null,
    zone_id: null,
    is_from_external_source: false,
    external_source_link: "",
    external_source_passcode: null,
  });
  const [source_zone_list, set_source_zone_list] = useState([]);
  const [message_board_details, set_message_board_details] = useState({
    message_board_configuration_id: "",
    new_post_default_session_enabled: false,
    message_board_enabled: false,
    moderate_message_board_enabled: false,
    assigned_agent_ids: [],
  });

  const [media_asset_details, set_media_asset_details] = useState({
    is_asset_upload: false,
    media_asset_brand_id: "",
    media_asset_zone_id: "",
    media_asset_type: null,
    asset_details: [],
  });
  const [media_asset_zonesForBrands, set_media_asset_zonesForBrands] = useState(
    []
  );
  const [invitation_setup, set_invitation_setup] = useState({
    invitation_title: "",
    invitation_description: "",
    is_iCal_download_enabled: false,
    is_reinvite_enabled: false,
    send_invitation_now: false,
    date_time_to_invite: undefined,
    is_scheduled: false,
  });
  const [invited_brands, set_invited_brands] = useState([]);
  const [invited_zones, set_invited_zones] = useState([]);
  const [invited_user_types, set_invited_user_types] = useState([]);
  const [invited_countries, set_invited_countries] = useState([]);
  const [invited_job_titles, set_invited_job_titles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [audiSessionAllData, setAudiSessionAllData] = useState({});
  const [hosting_brand_details, set_hosting_brand_details] = useState([]);
  const [previous_pending_invites, set_previous_pending_invites] = useState([]);
  const [invitationModal, setInvitationModal] = useState({
    isOpen: false,
    data: null,
    isLoader: false,
  });
  const [invitationTableData, setInvitationTableData] = useState([]);
  const [media_assets_local, set_media_assets_local] = useState([]);
  const [delete_media_assets, set_delete_media_assets] = useState([]);
  const [delete_scheduled_invitations, set_delete_scheduled_invitations] =
    useState([]);
  const [isEmptyBrands, setIsEmptyBrands] = useState("no");
  const [googleSigned, setGoogleSigned] = useState(false);
  const [inviteesToggle, setInviteesToggle] = useState(false);
  const [existingStartEndTime, setExistingStartEndTime] = useState({
    startTime: "",
    endTime: "",
  });

  const [openProducersList, setOpenProducersList] = useState(false);
  const [openUserList, setOpenUsersList] = useState(false);
  const [openBrandsList, setOpenBrandsList] = useState(false);
  const [openCountriesList, setOpenCountriesList] = useState(false);
  const [openJobTitlesList, setOpenJobTitlesList] = useState(false);

  useEffect(() => {
    async function stateLoad() {
      if (props.sessionData) {
        const agentListAPI = await getUserTypeAgents({
          event_id: props.sessionData.event_id,
        });
        setAgentsCollectionData(agentListAPI);
        const brandListAPI = await getBrandDropdowns(
          props.sessionData.event_id
        );
        setBrandsCollection(brandListAPI);
        setBrandCollectionForInvitees(brandListAPI);
        const countryListAPI = await getCountryList();
        const jobTitlesListAPI = await getJobTitles();
        const zoneListAPI = await getZoneDropdowns(props.sessionData.event_id);
        setZoneCollection(zoneListAPI);
        setZoneCollectionForInvitees(zoneListAPI);
        let sessionAPIData = await getAudiSession({
          auditorium_configuration_id:
            props.sessionData.auditorium_configuration_id,
          auditorium_session_id: props.sessionData.auditorium_session_id,
        });

        setAudiSessionAllData(sessionAPIData);
        set_previous_pending_invites(sessionAPIData.previous_pending_invites);
        setTimeout(async () => {
          if (sessionAPIData && sessionAPIData.auditorium_session_details) {
            let sessData = sessionAPIData.auditorium_session_details;
            const start_date_time = createTZString(sessData?.start_date_time);
            const end_date_time = createTZString(sessData?.end_date_time);
            const default_time = moment(moment().format("HH:mm"), TIME_FORMAT);

            setExistingStartEndTime({
              startTime: moment(
                start_date_time?.format("HH:mm"),
                TIME_FORMAT
              )?.format("HH:mm"),
              endTime: moment(
                end_date_time?.format("HH:mm"),
                TIME_FORMAT
              )?.format("HH:mm"),
            });

            sessData.start_date_time = sessData?.start_date_time
              ? moment(start_date_time.format("HH:mm"), TIME_FORMAT)
              : default_time;
            sessData.end_date_time = sessData?.end_date_time
              ? moment(end_date_time.format("HH:mm"), TIME_FORMAT)
              : default_time;

            set_auditorium_session_details(sessData);
          } else {
            let cloned_auditorium_session_details = cloneDeep(
              auditorium_session_details
            );
            const start_date_t = moment(
              props.sessionData.hourItem.start_time,
              TIME_FORMAT
            );
            const end_date_t = moment(
              props.sessionData.hourItem.end_time,
              TIME_FORMAT
            );

            cloned_auditorium_session_details.start_date_time = start_date_t;
            cloned_auditorium_session_details.end_date_time = end_date_t;
            set_auditorium_session_details(cloned_auditorium_session_details);
          }

          if (sessionAPIData.source_details) {
            if (sessionAPIData.source_details.brand_id) {
              const filteredData =
                zoneListAPI &&
                zoneListAPI.filter(
                  (item) =>
                    item.brand_id === sessionAPIData.source_details.brand_id
                );
              set_source_zone_list(filteredData);
            }
            set_source_details(sessionAPIData.source_details);
          }

          if (sessionAPIData.message_board_details)
            set_message_board_details(sessionAPIData.message_board_details);

          if (sessionAPIData.media_asset_details) {
            if (sessionAPIData.media_asset_details.media_asset_brand_id) {
              const filteredData =
                zoneListAPI &&
                zoneListAPI.filter(
                  (item) =>
                    item.brand_id ===
                    sessionAPIData.media_asset_details.media_asset_brand_id
                );
              set_media_asset_zonesForBrands(filteredData);
            }
            set_media_asset_details(sessionAPIData.media_asset_details);

            if (sessionAPIData.media_asset_details.asset_details)
              set_media_assets_local(
                sessionAPIData.media_asset_details.asset_details
              );
          }

          if (sessionAPIData.invited_brands)
            set_invited_brands(sessionAPIData.invited_brands);

          if (sessionAPIData.invited_zones)
            set_invited_zones(sessionAPIData.invited_zones);

          if (sessionAPIData.invited_user_types)
            set_invited_user_types(sessionAPIData.invited_user_types);

          if (sessionAPIData.invited_countries)
            set_invited_countries(sessionAPIData.invited_countries);

          if (sessionAPIData.invited_job_titles)
            set_invited_job_titles(sessionAPIData.invited_job_titles);

          if (sessionAPIData.hosting_brand_details) {
            let cloned_hosting_brand_details = cloneDeep(
              sessionAPIData.hosting_brand_details
            );
            let finalData = [];
            cloned_hosting_brand_details &&
              cloned_hosting_brand_details.forEach((e) => {
                finalData.push({ ...e, agentsCollectionData: agentListAPI });
              });
            set_hosting_brand_details(finalData);
          }

          const dataHere = sessionAPIData;

          if (agentListAPI) {
            if (dataHere && !isEmpty(dataHere.producers_ids)) {
              set_final_producers_ids(dataHere.producers_ids);
              const clonedAgents = cloneDeep(agentListAPI);
              await mapSelectedDdlData(
                dataHere.producers_ids,
                [],
                clonedAgents,
                "user_id"
              )
                .then((agentsToMap) => {
                  if (agentsToMap && agentsToMap.mainList)
                    set_producers_ids(agentsToMap.mainList);
                  if (agentsToMap && agentsToMap.selectedList)
                    set_selected_producers_ids(agentsToMap.selectedList);
                })
                .catch((err) => {});
            } else {
              const clonedAgents = cloneDeep(agentListAPI);
              set_producers_ids(clonedAgents);
            }

            if (
              dataHere &&
              dataHere.message_board_details &&
              !isEmpty(dataHere.message_board_details.assigned_agent_ids)
            ) {
              set_final_assigned_agent_ids(
                dataHere.message_board_details.assigned_agent_ids
              );
              const clonedAgents = cloneDeep(agentListAPI);
              await mapSelectedDdlData(
                dataHere.message_board_details.assigned_agent_ids,
                [],
                clonedAgents,
                "user_id"
              )
                .then((agentsToMap) => {
                  if (agentsToMap && agentsToMap.mainList)
                    set_assigned_agent_ids(agentsToMap.mainList);
                  if (agentsToMap && agentsToMap.selectedList)
                    set_selected_assigned_agent_ids(agentsToMap.selectedList);
                })
                .catch((err) => {});
            } else {
              const clonedAgents = cloneDeep(agentListAPI);
              set_assigned_agent_ids(clonedAgents);
            }

            if (dataHere && !isEmpty(dataHere.restricted_users)) {
              set_final_restricted_users(dataHere.restricted_users);
              const clonedAgents = cloneDeep(agentListAPI);
              await mapSelectedDdlData(
                dataHere.restricted_users,
                [],
                clonedAgents,
                "user_id"
              )
                .then((agentsToMap) => {
                  if (agentsToMap && agentsToMap.mainList)
                    set_restricted_users(
                      orderBy(agentsToMap.mainList, "first_name", "asc")
                    );

                  if (agentsToMap && agentsToMap.selectedList)
                    set_selected_restricted_users(agentsToMap.selectedList);
                })
                .catch((err) => {});
            } else {
              const clonedAgents = cloneDeep(agentListAPI);

              set_restricted_users(orderBy(clonedAgents, "first_name", "asc"));
            }
          }

          if (dataHere && !isEmpty(dataHere.restricted_brands)) {
            set_final_restricted_brands(dataHere.restricted_brands);
            const clonedBrands = cloneDeep(brandListAPI);
            await mapSelectedDdlData(
              dataHere.restricted_brands,
              [],
              clonedBrands,
              "brand_id"
            )
              .then((brandsToMap) => {
                if (brandsToMap && brandsToMap.mainList)
                  set_restricted_brands(
                    orderBy(brandsToMap.mainList, "brand_title", "asc")
                  );
                if (brandsToMap && brandsToMap.selectedList)
                  set_selected_restricted_brands(brandsToMap.selectedList);
              })
              .catch((err) => {});
          } else
            set_restricted_brands(orderBy(brandListAPI, "brand_title", "asc"));

          if (
            props.sessionData.currentEventData &&
            props.sessionData.currentEventData
              .registration_and_communication_details
          ) {
            if (countryListAPI) {
              let event_country_ids =
                props.sessionData.currentEventData
                  .registration_and_communication_details
                  .country_collection_ids;
              if (event_country_ids) {
                let countriesOfEvents = [];
                countryListAPI.forEach((ele) => {
                  if (event_country_ids.includes(ele.country_id))
                    countriesOfEvents.push(ele);
                });
                if (countriesOfEvents) {
                  setCountryCollection(countriesOfEvents);

                  if (dataHere && !isEmpty(dataHere.restricted_countries)) {
                    set_final_restricted_countries(
                      dataHere.restricted_countries
                    );
                    await mapSelectedDdlData(
                      dataHere.restricted_countries,
                      [],
                      countriesOfEvents,
                      "country_id"
                    )
                      .then((countriesToMap) => {
                        if (countriesToMap && countriesToMap.mainList);
                        set_restricted_countries(
                          orderBy(countriesToMap.mainList, "country", "asc")
                        );
                        if (countriesToMap && countriesToMap.selectedList)
                          set_selected_restricted_countries(
                            countriesToMap.selectedList
                          );
                      })
                      .catch((err) => {});
                  } else
                    set_restricted_countries(
                      orderBy(countriesOfEvents, "country", "asc")
                    );
                }
              }
            }

            if (jobTitlesListAPI) {
              let event_job_titles_ids =
                props.sessionData.currentEventData
                  .registration_and_communication_details
                  .job_title_collection_ids;
              if (event_job_titles_ids) {
                let titlesOfEvents = [];
                jobTitlesListAPI.forEach((ele) => {
                  if (event_job_titles_ids.includes(ele.job_title_id))
                    titlesOfEvents.push(ele);
                });
                if (titlesOfEvents) {
                  setJobTitleCollection(titlesOfEvents);
                  if (dataHere && !isEmpty(dataHere.restricted_job_titles)) {
                    set_final_restricted_job_titles(
                      dataHere.restricted_job_titles
                    );
                    await mapSelectedDdlData(
                      dataHere.restricted_job_titles,
                      [],
                      titlesOfEvents,
                      "job_title_id"
                    )
                      .then((jobTitlesToMap) => {
                        if (jobTitlesToMap && jobTitlesToMap.mainList)
                          set_restricted_job_titles(
                            orderBy(jobTitlesToMap.mainList, "job_title", "asc")
                          );

                        if (jobTitlesToMap && jobTitlesToMap.selectedList)
                          set_selected_restricted_job_titles(
                            jobTitlesToMap.selectedList
                          );
                      })
                      .catch((err) => {});
                  } else
                    set_restricted_job_titles(
                      orderBy(titlesOfEvents, "job_title", "asc")
                    );
                }
              }
            }
          }
          setTimeout(() => {
            setLoading(false);
            // googleAutoSign();
          }, 1000);
        }, 500);
      } else {
        setLoading(false);
        // googleAutoSign();
      }
    }
    stateLoad();
  }, [props.sessionData]);

  function googleAutoSign() {
    if (!localStorage.getItem("google_auth"))
      setTimeout(() => {
        let ele = document.getElementById("google-btn");

        if (ele) ele.click();
      }, 500);
    else {
      const authData = JSON.parse(localStorage.getItem("google_auth"));
      onChangeFields(
        authData.access_token,
        "google_access_code",
        "auditorium_session_details"
      );
      setGoogleSigned(true);
    }
  }

  const onGoogleSuccess = (res) => {
    toast.success(`Google configuration is completed for the streaming.`);
    // refreshTokenSetup(res);
  };

  const onGoogleFailure = (res) => {
    toast.error(`Failed to login.`);
  };

  const glogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      localStorage.setItem("google_auth", JSON.stringify(codeResponse));
      onChangeFields(
        codeResponse.access_token,
        "google_access_code",
        "auditorium_session_details"
      );
      setGoogleSigned(true);
    },
    onError: (errorResponse) => console.log("errorResponse = ", errorResponse),
    flow: "implicit",
    scope: "https://www.googleapis.com/auth/youtube.force-ssl",
  });

  // const refreshTokenSetup = (res) => {
  //   // Timing to renew access token
  //   let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

  //   const refreshToken = async () => {
  //     const newAuthRes = await res.reloadAuthResponse();
  //     refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
  //     console.log("newAuthRes:", newAuthRes);
  //     // saveUserToken(newAuthRes.access_token);  <-- save new token
  //     // localStorage.setItem('authToken', newAuthRes.id_token);

  //     // Setup the other timer after the first one
  //     setTimeout(refreshToken, refreshTiming);
  //   };

  //   // Setup first refresh timer
  //   setTimeout(refreshToken, refreshTiming);
  // };

  async function handleAllowAllBlockAll(key, type) {
    let leftDDL, rightDDL;

    if (key === "restricted_users") {
      if (type === "blockAll") {
        let newArrUsers = selected_restricted_users.concat(restricted_users);

        rightDDL = cloneDeep(newArrUsers);
        leftDDL = [];
      }

      if (type === "allowAll") {
        rightDDL = cloneDeep([]);

        let newArrUsers = selected_restricted_users.concat(restricted_users);

        leftDDL = cloneDeep(newArrUsers);
      }
    } else if (key === "restricted_brands") {
      if (type === "blockAll") {
        let newArrBrands = selected_restricted_brands.concat(restricted_brands);

        rightDDL = cloneDeep(newArrBrands);
        leftDDL = [];
      }

      if (type === "allowAll") {
        let newArrBrands = selected_restricted_brands.concat(restricted_brands);

        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(newArrBrands);
      }
    } else if (key === "restricted_countries") {
      if (type === "blockAll") {
        let newArrCountries =
          selected_restricted_countries.concat(restricted_countries);

        rightDDL = cloneDeep(newArrCountries);
        leftDDL = [];
      }

      if (type === "allowAll") {
        let newArrCountries =
          selected_restricted_countries.concat(restricted_countries);

        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(newArrCountries);
      }
    } else if (key === "restricted_job_titles") {
      if (type === "blockAll") {
        let newArrJobTitles = selected_restricted_job_titles.concat(
          restricted_job_titles
        );

        rightDDL = cloneDeep(newArrJobTitles);
        leftDDL = [];
      }

      if (type === "allowAll") {
        let newArrJobTitles = selected_restricted_job_titles.concat(
          restricted_job_titles
        );

        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(newArrJobTitles);
      }
    }

    assignDDLData(leftDDL, rightDDL, key);
  }

  async function handleDDLChange(value, key, leftOrRight) {
    let leftDDL, rightDDL, index;
    if (key === "producers_ids") {
      leftDDL = cloneDeep(producers_ids);
      rightDDL = cloneDeep(selected_producers_ids);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.user_id === value);
      else index = rightDDL.findIndex((ele) => ele.user_id === value);
    } else if (key === "assigned_agent_ids") {
      leftDDL = cloneDeep(assigned_agent_ids);
      rightDDL = cloneDeep(selected_assigned_agent_ids);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.user_id === value);
      else index = rightDDL.findIndex((ele) => ele.user_id === value);

      if (leftOrRight === "left") {
        //remove it from the white list dropdown
        const cloned_restricted_users = cloneDeep(restricted_users);
        const filtered_restricted_users = cloned_restricted_users.filter(
          (ele) => ele.user_id !== value
        );
        set_restricted_users(filtered_restricted_users);
      } else {
        //adding it to the white list dropdown
        const clonedAgents = cloneDeep(agentsCollectionData);
        const filteredAgents = clonedAgents.filter(
          (ele) => ele.user_id === value
        );
        let cloned_restricted_users = cloneDeep(restricted_users);
        cloned_restricted_users = [
          ...cloned_restricted_users,
          ...filteredAgents,
        ];
        set_restricted_users(cloned_restricted_users);
      }
    } else if (key === "restricted_users") {
      leftDDL = cloneDeep(restricted_users);
      rightDDL = cloneDeep(selected_restricted_users);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.user_id === value);
      else index = rightDDL.findIndex((ele) => ele.user_id === value);
    } else if (key === "restricted_brands") {
      leftDDL = cloneDeep(restricted_brands);
      rightDDL = cloneDeep(selected_restricted_brands);

      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.brand_id === value);
      else index = rightDDL.findIndex((ele) => ele.brand_id === value);

      //filter the blacklisted brands and zones from invitees
      if (leftOrRight === "left") {
        const clonedBrandsInvitees = cloneDeep(brandCollectionForInvitees);
        const filteredBrandsInvitees = clonedBrandsInvitees.filter(
          (ele) => ele.brand_id !== value
        );
        setBrandCollectionForInvitees(filteredBrandsInvitees);
        const clonedZonesInvitees = cloneDeep(zoneCollectionForInvitees);
        const filteredZonesInvitees = clonedZonesInvitees.filter(
          (ele) => ele.brand_id !== value
        );
        setZoneCollectionForInvitees(filteredZonesInvitees);
      } else {
        const clonedBrands = cloneDeep(brandsCollection);
        const filteredBrands = clonedBrands.filter(
          (ele) => ele.brand_id === value
        );
        let clonedBrandsInvitees = cloneDeep(brandCollectionForInvitees);
        clonedBrandsInvitees = [...clonedBrandsInvitees, ...filteredBrands];
        setBrandCollectionForInvitees(clonedBrandsInvitees);

        const clonedZones = cloneDeep(zoneCollection);
        const filteredZones = clonedZones.filter(
          (ele) => ele.brand_id === value
        );

        let clonedZonesInvitees = cloneDeep(zoneCollectionForInvitees);
        clonedZonesInvitees = [...clonedZonesInvitees, ...filteredZones];
        setZoneCollectionForInvitees(clonedZonesInvitees);
      }
    } else if (key === "restricted_countries") {
      leftDDL = cloneDeep(restricted_countries);
      rightDDL = cloneDeep(selected_restricted_countries);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.country_id === value);
      else index = rightDDL.findIndex((ele) => ele.country_id === value);
    } else if (key === "restricted_job_titles") {
      leftDDL = cloneDeep(restricted_job_titles);
      rightDDL = cloneDeep(selected_restricted_job_titles);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.job_title_id === value);
      else index = rightDDL.findIndex((ele) => ele.job_title_id === value);
    }

    if (leftOrRight === "left") {
      rightDDL.push(leftDDL[index]);
      leftDDL.splice(index, 1);
    } else {
      leftDDL.unshift(rightDDL[index]);

      rightDDL.splice(index, 1);
    }

    assignDDLData(leftDDL, rightDDL, key);
  }

  function assignDDLData(leftDDL, rightDDL, key) {
    if (key === "producers_ids") {
      let onlyIdArr = rightDDL.map((elem) => elem.user_id);
      set_producers_ids(leftDDL);
      set_selected_producers_ids(rightDDL);
      set_final_producers_ids(onlyIdArr);
    } else if (key === "assigned_agent_ids") {
      let onlyIdArr = rightDDL.map((elem) => elem.user_id);
      set_assigned_agent_ids(leftDDL);
      set_selected_assigned_agent_ids(rightDDL);
      set_final_assigned_agent_ids(onlyIdArr);
    } else if (key === "restricted_users") {
      let onlyIdArr = rightDDL?.map((elem) => elem.user_id);

      set_restricted_users(orderBy(leftDDL, "first_name", "asc"));
      set_selected_restricted_users(rightDDL);
      set_final_restricted_users(onlyIdArr);
    } else if (key === "restricted_brands") {
      let onlyIdArr = rightDDL.map((elem) => elem.brand_id);
      set_restricted_brands(orderBy(leftDDL, "brand_title", "asc"));
      set_selected_restricted_brands(rightDDL);
      set_final_restricted_brands(onlyIdArr);
    } else if (
      key === "restricted_countries" &&
      selected_restricted_countries
    ) {
      let onlyIdArr = rightDDL.map((elem, index) => elem.country_id);
      set_restricted_countries(orderBy(leftDDL, "country", "asc"));
      set_selected_restricted_countries(rightDDL);
      set_final_restricted_countries(onlyIdArr);
    } else if (key === "restricted_job_titles") {
      let onlyIdArr = rightDDL.map((elem, index) => elem.job_title_id);
      set_restricted_job_titles(orderBy(leftDDL, "job_title", "asc"));
      set_selected_restricted_job_titles(rightDDL);
      set_final_restricted_job_titles(onlyIdArr);
    }
  }

  const createUpdateAPI = async (dataToAPI) => {
    setLoading(true);

    let dataToUpdate = dataToAPI;

    let formData = new FormData();

    if (media_assets_local) {
      media_assets_local.forEach((ele) => {
        formData.append("medias", ele);
      });
    }

    formData.append("data", JSON.stringify(dataToUpdate));

    if (props.sessionData.auditorium_session_id) {
      const updateRes = await dispatch(updateAudiSession(formData));

      if (updateRes?.payload?.data?.code === 200) {
        setLoading(false);
        toast.success(updateRes.payload.data.message);
        props.onSuccess(true);
      } else {
        setLoading(false);
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      const createRes = await dispatch(createAudiSession(formData));

      if (createRes?.payload?.data?.code === 200) {
        setLoading(false);
        toast.success(createRes.payload.data.message);
        props.onSuccess(true);
      } else {
        setLoading(false);
        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleSubmit = () => {
    if (hosting_brand_details) {
      const filteredData = hosting_brand_details.filter((ele) => ele.is_delete);
      if (isEmpty(hosting_brand_details)) setIsEmptyBrands("");
      else if (filteredData.length === hosting_brand_details.length)
        setIsEmptyBrands("");
      else setIsEmptyBrands("no");
    }

    setTimeout(function () {
      //form is getting  submitted even if invitation title and description are empty
      //below logic is to resolve it
      if (inviteesToggle) {
        if (!invitation_setup.invitation_title) {
          validator.current.fields = {
            ...validator.current.fields,
            invitation_title: false,
          };
        }
        if (!invitation_setup.invitation_description) {
          validator.current.fields = {
            ...validator.current.fields,
            invitation_description: false,
          };
        }
      }

      if (validator.current.allValid()) {
        let dataToUpdate = cloneDeep(audiSessionAllData);
        let checkForValidSponsors = [];
        if (auditorium_session_details) {
          let new_audi_session_details = cloneDeep(auditorium_session_details);

          const start_date_str =
            props.sessionData.currentDateForCalender +
            " " +
            moment(new_audi_session_details.start_date_time).format("HH:mm:ss");
          const start_utc = createISOTimeString(start_date_str);
          const end_date_str =
            props.sessionData.currentDateForCalender +
            " " +
            moment(new_audi_session_details.end_date_time).format("HH:mm:ss");
          const end_utc = createISOTimeString(end_date_str);
          new_audi_session_details.start_date_time = start_utc;
          new_audi_session_details.end_date_time = end_utc;

          dataToUpdate.auditorium_session_details = new_audi_session_details;
        }
        if (source_details) dataToUpdate.source_details = source_details;
        if (message_board_details) {
          dataToUpdate.message_board_details = message_board_details;
          dataToUpdate.message_board_details.assigned_agent_ids =
            final_assigned_agent_ids;
        }
        if (media_asset_details)
          dataToUpdate.media_asset_details = media_asset_details;
        if (hosting_brand_details) {
          let cloned_hosting_brand_details = cloneDeep(hosting_brand_details);
          cloned_hosting_brand_details.forEach(function (e) {
            delete e.agentsCollectionData;
          });

          checkForValidSponsors = cloned_hosting_brand_details?.filter(
            (ele) => {
              if (!ele?.is_delete) {
                return ele;
              }
            }
          );
          dataToUpdate.hosting_brand_details = cloned_hosting_brand_details;
        }
        if (invitation_setup) {
          let cloned_invitation_setup = cloneDeep(invitation_setup);

          const start_date_str = moment(
            invitation_setup?.date_time_to_invite
          ).format("YYYY/MM/DD HH:mm");
          const start_utc = createISOTimeString(start_date_str);
          cloned_invitation_setup.date_time_to_invite = start_utc;
          dataToUpdate.invitation_setup = cloned_invitation_setup;
        }
        dataToUpdate.producers_ids = final_producers_ids;
        dataToUpdate.restricted_users = final_restricted_users;
        dataToUpdate.restricted_brands = final_restricted_brands;
        dataToUpdate.restricted_countries = final_restricted_countries;
        dataToUpdate.restricted_job_titles = final_restricted_job_titles;
        dataToUpdate.invited_brands = invited_brands;
        dataToUpdate.invited_zones = invited_zones;
        dataToUpdate.invited_user_types = invited_user_types;
        dataToUpdate.invited_countries = invited_countries;
        dataToUpdate.invited_job_titles = invited_job_titles;
        dataToUpdate.allow_invitation = inviteesToggle;
        dataToUpdate.timezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        dataToUpdate.current_timestamp = moment().format("YYYY-MM-DD");

        if (props.sessionData.auditorium_session_id) {
          let isTimeModified = false;

          let newStartTime =
            auditorium_session_details?.start_date_time?.format("HH:mm");
          let newEndTime =
            auditorium_session_details?.end_date_time?.format("HH:mm");

          if (
            existingStartEndTime?.startTime !== newStartTime ||
            existingStartEndTime?.endTime !== newEndTime
          ) {
            isTimeModified = true;
          }

          dataToUpdate.is_time_modified = isTimeModified;
        }

        if (delete_media_assets)
          dataToUpdate.delete_media_assets = delete_media_assets;

        if (delete_scheduled_invitations)
          dataToUpdate.delete_scheduled_invitations =
            delete_scheduled_invitations;

        dataToUpdate.event_id = props.sessionData.event_id;
        dataToUpdate.auditorium_configuration_id =
          props.sessionData.auditorium_configuration_id ?? "";

        if (
          !ifTimeSlotAvailable(
            moment(dataToUpdate.auditorium_session_details.start_date_time),
            moment(dataToUpdate.auditorium_session_details.end_date_time),
            props.sessionData.existingTimings
          )
        )
          toast.error("Timeslot is not available");
        else if (
          moment(dataToUpdate.auditorium_session_details.start_date_time) >
          moment(dataToUpdate.auditorium_session_details.end_date_time)
        )
          toast.error(dialogs?.inCorrectTimeError);
        else if (
          getMinutesDiff(
            moment(dataToUpdate.auditorium_session_details.start_date_time),
            moment(dataToUpdate.auditorium_session_details.end_date_time)
          ) < 10
        )
          toast.error("Please select atleast 10 mins duration");
        else if (
          !dataToUpdate.source_details.is_this_platform_enabled &&
          !dataToUpdate.source_details.is_from_external_source
        )
          toast.error("Please choose the source");
        else if (
          dataToUpdate.source_details.is_this_platform_enabled &&
          !dataToUpdate.source_details.is_private_room_enabled &&
          !dataToUpdate.source_details.is_zone_demo_room_enabled
        )
          toast.error("Please choose the private room or zone room");
        else if (
          !dataToUpdate.hosting_brand_details ||
          dataToUpdate.hosting_brand_details === [] ||
          isEmpty(dataToUpdate.hosting_brand_details) ||
          checkForValidSponsors?.length < 1
        )
          toast.error("Please add hosting brands");
        else if (
          dataToUpdate.media_asset_details &&
          dataToUpdate.media_asset_details.is_asset_upload &&
          isEmpty(media_assets_local)
        ) {
          toast.error("Please upload media individual assets");
        } else if (isEmptyBrands !== "no")
          toast.error("Please select hosting brands");
        else {
          if (inviteesToggle) {
            //if its on then schedule invitation is mandatory other wise call update API
            if (
              !invitation_setup.is_scheduled &&
              !invitation_setup.send_invitation_now
            ) {
              toast.error("Please select time period for sending invitations");
            } else {
              createUpdateAPI(dataToUpdate);
            }
          } else {
            createUpdateAPI(dataToUpdate);
          }
        }
      } else {
        toast.error(dialogs.missingFields);
        validator.current.showMessages();
        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        setForceUpdate(!forceUpdate);
      }
    }, 1000);
  };

  const handleSubmitAddBrand = () => {
    if (validatorAddBrand.current.allValid()) {
      //add the data to brands drag and drop table
      //and reset the add new table data
      let cloned_add_new_brand = cloneDeep(add_new_brand);
      let final_data_speakers = [];
      if (
        cloned_add_new_brand &&
        cloned_add_new_brand.auditorium_hosting_speakers
      ) {
        let data_speakers_ids =
          cloned_add_new_brand.auditorium_hosting_speakers;
        let data_speakers = [];
        if (speakerCollection) {
          data_speakers_ids &&
            data_speakers_ids.forEach((id) => {
              data_speakers =
                speakerCollection &&
                speakerCollection.filter((data) => data.user_id === id);

              data_speakers &&
                data_speakers.forEach((ele) => {
                  final_data_speakers.push({
                    assigned_speaker_id: ele.user_id,
                    first_name: ele.first_name,
                    hosting_speaker_id: "",
                    last_name: ele.last_name,
                    rsvp_type: "PENDING",
                  });
                });
            });
        }
      }

      let clonedAgents = cloneDeep(agentsCollectionData);

      const filteredData =
        clonedAgents &&
        clonedAgents.filter(
          (item) =>
            item && item.brand_id === cloned_add_new_brand.assigned_brand_id
        );

      let cloned_hosting_brand_details = [];
      if (hosting_brand_details) {
        cloned_hosting_brand_details = cloneDeep(hosting_brand_details);
        cloned_hosting_brand_details.push({
          ...cloned_add_new_brand,
          auditorium_hosting_speakers: final_data_speakers,
          sequence: cloned_hosting_brand_details.length + 1,
          agentsCollectionData: filteredData,
        });
        set_hosting_brand_details(cloned_hosting_brand_details);
        // }
      } else {
        cloned_hosting_brand_details = [
          {
            ...cloned_add_new_brand,
            auditorium_hosting_speakers: final_data_speakers,
            sequence: 0,
            agentsCollectionData: filteredData,
          },
        ];
        set_hosting_brand_details(cloned_hosting_brand_details);
      }

      set_add_new_brand(defaultBlankNewBrand);
      setIsEmptyBrands("no");
      setSelectedZoneData(defaultFlags);
      validatorAddBrand.current.hideMessages();
    } else {
      validatorAddBrand.current.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const onChangeFields = (val, key, parentKey) => {
    if (parentKey === "auditorium_session_details")
      set_auditorium_session_details({
        ...auditorium_session_details,
        [key]: val,
      });
    if (parentKey === "add_new_brand")
      set_add_new_brand({ ...add_new_brand, [key]: val });
    if (parentKey === "source_details") {
      let cloned_source_details = cloneDeep(source_details);
      if (
        key === "is_this_platform_enabled" ||
        key === "is_zone_demo_room_enabled" ||
        key === "is_from_external_source" ||
        key === "is_zone_demo_room_enabled" ||
        key === "is_private_room_enabled"
      ) {
        if (key === "is_this_platform_enabled") {
          cloned_source_details.is_this_platform_enabled = val;
          cloned_source_details.is_zone_demo_room_enabled = false;
          cloned_source_details.is_from_external_source = false;
        } else if (key === "is_zone_demo_room_enabled") {
          cloned_source_details.is_zone_demo_room_enabled = val;
          cloned_source_details.is_private_room_enabled = false;
        } else if (key === "is_from_external_source") {
          cloned_source_details.is_this_platform_enabled = false;
          cloned_source_details.is_zone_demo_room_enabled = false;
          cloned_source_details.is_from_external_source = val;
          cloned_source_details.is_private_room_enabled = false;
        } else if (key === "is_zone_demo_room_enabled") {
          cloned_source_details.is_zone_demo_room_enabled = val;
          cloned_source_details.is_private_room_enabled = false;
        } else if (key === "is_private_room_enabled") {
          cloned_source_details.is_private_room_enabled = val;
          cloned_source_details.is_zone_demo_room_enabled = false;
        }
        set_source_details(cloned_source_details);
      } else {
        set_source_details({ ...source_details, [key]: val });
      }
    }
    if (parentKey === "message_board_details")
      set_message_board_details({ ...message_board_details, [key]: val });
    if (parentKey === "invitation_setup") {
      let cloned_invitation_setup = cloneDeep(invitation_setup);
      if (key === "is_scheduled") {
        cloned_invitation_setup.is_scheduled = val;
        cloned_invitation_setup.send_invitation_now = false;
        set_invitation_setup(cloned_invitation_setup);
      } else if (key === "send_invitation_now") {
        cloned_invitation_setup.is_scheduled = false;
        cloned_invitation_setup.send_invitation_now = val;
        set_invitation_setup(cloned_invitation_setup);
      } else {
        set_invitation_setup({ ...cloned_invitation_setup, [key]: val });
      }
    }
    if (parentKey === "media_asset_details") {
      if (key === "is_asset_upload") {
        if (val === true)
          set_media_asset_details({
            ...media_asset_details,
            [key]: val,
            media_asset_brand_id: undefined,
            media_asset_zone_id: undefined,
          });
        else set_media_asset_details({ ...media_asset_details, [key]: val });
      } else set_media_asset_details({ ...media_asset_details, [key]: val });
    }
  };

  const handleImageAssets = (event) => {
    let clonedData = cloneDeep(media_assets_local);
    let mediaToAdd = event.target.files[0];
    mediaToAdd.is_delete = false;
    clonedData.push(mediaToAdd);
    set_media_assets_local(clonedData);
  };

  const renderSwitches = (key, parentKey, checked) => {
    if (parentKey === "invitation_setup")
      return (
        <Switch
          disabled={!inviteesToggle ? true : false}
          checked={checked}
          onChange={(val) => onChangeFields(val, key, parentKey)}
          className="adminSatellite_switch"
        />
      );
    else
      return (
        <Switch
          checked={checked}
          onChange={(val) => onChangeFields(val, key, parentKey)}
          className="adminSatellite_switch"
        />
      );
  };

  const hosting_brand_columns = [
    {
      title: "Operates",
      key: "operate",
      width: "5%",
      render: (text, record, index) => {
        if (record && record.brand_title && !record.is_delete)
          return (
            <a className="drag-handle" href="/#">
              <HolderOutlined />
            </a>
          );
        else return null;
      },
    },
    {
      title: "Brand Title",
      dataIndex: "brand_title",
      width: "95%",
      render: (_, record, index) => {
        if (record && record.brand_title && !record.is_delete)
          return (
            <div className="d-flex flex-column">
              <Row className="adminSatellite_section_2_brand_row1">
                <Col span={23}>
                  <p className="adminSatellite_section_2_brand_name">
                    {record.brand_title}
                  </p>
                </Col>
                <Col span={1}>
                  <img
                    alt="delete"
                    onClick={() => {
                      let clonedDataSource = cloneDeep(hosting_brand_details);
                      clonedDataSource[index].is_delete = true;
                      clonedDataSource[index].sequence = null;
                      let finalData = [];
                      clonedDataSource &&
                        clonedDataSource.forEach((ele, index) => {
                          if (!ele.is_delete)
                            finalData.push({ ...ele, sequence: index });
                        });

                      finalData.push(clonedDataSource[index]);
                      set_hosting_brand_details(finalData);
                    }}
                    src={blackbin}
                  />
                </Col>
              </Row>
            </div>
          );
        else return null;
      },
    },
  ];

  const handleInviteesTableChange = (pagination, filters, sorter) => {
    if (sorter) {
      const sortedData = sortData(sorter, invitationTableData);

      setInvitationTableData(sortedData);
    }
  };

  const invitation_table_columns = [
    {
      title: "Date/Time",
      key: "date_time_to_invite",
      dataIndex: "date_time_to_invite",
      width: "25%",
      align: "center",
      sorter: (a, b) =>
        moment(a.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss") -
        moment(b.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss"),
      render: (_, record) => {
        return moment(record.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "User Name",
      key: "user_name",
      dataIndex: "user_name",
      width: "25%",
      align: "center",
      render: (_, record) => {
        return record.first_name + " " + record.last_name;
      },
    },
    {
      title: "User Type",
      key: "user_type",
      dataIndex: "user_type",
      width: "25%",
      align: "center",
      sorter: (a, b) => a.user_type.localeCompare(b.user_type),
    },
    {
      title: "Invite Status",
      key: "rsvp_type",
      dataIndex: "rsvp_type",
      width: "25%",
      align: "center",
      render: (_, record) => {
        return getStatusButton(record);
      },
      sorter: (a, b) => b.rsvp_type.localeCompare(a.rsvp_type),
    },
  ];

  const getStatusButton = (ele) => {
    if (ele.rsvp_type === "PENDING")
      return (
        <Button className="adminSatellite_section_2_pending">Pending</Button>
      );
    else if (ele.rsvp_type === "YES")
      return (
        <Button className="adminSatellite_section_2_accepted">Accepted</Button>
      );
    else if (ele.rsvp_type === "NO")
      return (
        <Button className="adminSatellite_section_2_reject">Rejected</Button>
      );
    else return null;
  };

  const openInviteesModalAndApiCall = async (ele, isFromSingle) => {
    setInvitationModal({
      isOpen: true,
      data: ele,
      isLoader: true,
    });
    let params = {
      auditorium_configuration_id: ele.auditorium_configuration_id,
      auditorium_session_id: ele.auditorium_session_id,
    };
    if (isFromSingle)
      params.auditorium_invitation_setup_id =
        ele.auditorium_invitation_setup_id;
    let invitationData = await getAudiInvitationDetails(params);
    setInvitationTableData(invitationData);
  };

  return (
    <Modal
      title={props.title}
      visible={true}
      width={950}
      onOk={() => {
        if (!loading) props.onCloseModal(false);
      }}
      onCancel={() => {
        if (!loading) props.onCloseModal(false);
      }}
      footer={[
        <Button
          disabled={loading}
          className="px-3 button-pink rounded"
          key="cancel"
          style={{ width: "11%" }}
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </Button>,
        <Button
          disabled={loading}
          className="px-3 rounded"
          key="save"
          type="primary"
          style={{ width: "25%" }}
          onClick={() => {
            validator.current.purgeFields();
            handleSubmit();
          }}
        >
          Save Changes & Send Invitations
        </Button>,
      ]}
    >
      {loading ? (
        <CommonLoader />
      ) : (
        <>
          <div className="adminSatellite_section_1_form">
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Active</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_active",
                  "auditorium_session_details",
                  auditorium_session_details.is_active
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>
                  Start Time
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <TimePicker
                  allowClear={false}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabled={
                    props.sessionData &&
                    props.sessionData.auditorium_session_id &&
                    props.sessionData.userType === "BRAND ADMIN"
                      ? true
                      : false
                  }
                  name="start_date_time"
                  id="start_date_time"
                  format={format}
                  value={auditorium_session_details?.start_date_time}
                  className="expohall_timepicker"
                  style={{ width: "100%" }}
                  onSelect={(val) => {
                    if (
                      moment(
                        auditorium_session_details?.end_date_time,
                        "HH:mm"
                      ) > moment(val)
                    ) {
                      onChangeFields(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "start_date_time",
                        "auditorium_session_details"
                      );
                    } else {
                      toast.error(dialogs?.inCorrectTimeError);
                    }
                  }}
                  onChange={(e) => {
                    if (
                      moment(
                        auditorium_session_details?.end_date_time,
                        "HH:mm"
                      ) > moment(e)
                    ) {
                      onChangeFields(
                        moment(moment(e).format("HH:mm"), TIME_FORMAT),
                        "start_date_time",
                        "auditorium_session_details"
                      );
                    } else {
                      toast.error(dialogs?.inCorrectTimeError);
                    }
                  }}
                  onBlur={() =>
                    validator.current.showMessageFor("start_date_time")
                  }
                />
                {validator.current.message(
                  "",
                  auditorium_session_details.start_date_time,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>
                  End Time
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <TimePicker
                  allowClear={false}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabled={
                    props.sessionData &&
                    props.sessionData.auditorium_session_id &&
                    props.sessionData.userType === "BRAND ADMIN"
                      ? true
                      : false
                  }
                  disabledTime={() =>
                    getDisabledTime(auditorium_session_details?.start_date_time)
                  }
                  name="end_date_time"
                  id="end_date_time"
                  format={format}
                  value={auditorium_session_details?.end_date_time}
                  className="expohall_timepicker"
                  style={{ width: "100%" }}
                  onSelect={(val) => {
                    if (
                      moment(val) >
                      moment(
                        auditorium_session_details?.start_date_time,
                        "HH:mm"
                      )
                    ) {
                      onChangeFields(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "end_date_time",
                        "auditorium_session_details"
                      );
                    } else {
                      toast.error(dialogs?.inCorrectTimeError);
                    }
                  }}
                  onChange={(val) => {
                    if (
                      moment(val) >
                      moment(
                        auditorium_session_details?.start_date_time,
                        "HH:mm"
                      )
                    ) {
                      onChangeFields(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "end_date_time",
                        "auditorium_session_details"
                      );
                    } else {
                      toast.error(dialogs?.inCorrectTimeError);
                    }
                  }}
                  onBlur={() =>
                    validator.current.showMessageFor("end_date_time")
                  }
                />
                {validator.current.message(
                  "",
                  auditorium_session_details.end_date_time,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>
                  Title
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <Input
                  name="session_title"
                  className="adminSatellite_inputTitle"
                  placeholder="Enter a Title here"
                  value={auditorium_session_details.session_title}
                  onChange={(e) =>
                    onChangeFields(
                      e.target.value,
                      "session_title",
                      "auditorium_session_details"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("session_title")
                  }
                  minLength={1}
                  maxLength={30}
                />
                {validator.current.message(
                  "Title",
                  auditorium_session_details.session_title,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p style={{ alignSelf: "flex-start" }}>
                  Description
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <TextArea
                  name="session_description"
                  className="adminSatellite_inputDescription"
                  placeholder="Enter a description here"
                  rows={4}
                  value={auditorium_session_details.session_description}
                  onChange={(e) =>
                    onChangeFields(
                      e.target.value,
                      "session_description",
                      "auditorium_session_details"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("session_description")
                  }
                  minLength={1}
                  maxLength={200}
                />
                {validator.current.message(
                  "Description",
                  auditorium_session_details.session_description,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Download iCal</p>
              </Col>
              <Col span={6} style={{ alignSelf: "center" }}>
                {renderSwitches(
                  "is_iCal_enabled",
                  "auditorium_session_details",
                  auditorium_session_details.is_iCal_enabled
                )}
              </Col>
            </Row>
          </div>
          <div className="adminSatellite_section_2_form">
            <Row>
              <Col span={24}>
                <h5 className="adminSatellite_section_2_title fs-pink">
                  Hosting Brands
                  <RedAsterick />
                </h5>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={24}>
                <p className="adminSatellite_section_2_info">
                  NB: The brands of speakers will automatically be added to the
                  list below, you can remove them (
                  <img
                    alt="delete"
                    className="adminSatellite_delete_icon"
                    src={blackbin}
                  />
                  ) or add more brands here.
                </p>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p style={{ alignSelf: "start", marginTop: "10px" }}>
                  Assigned Brands
                </p>
              </Col>
              <Col span={18}>
                {hosting_brand_details && !isEmpty(hosting_brand_details) ? (
                  <ReactDragListView
                    onDragEnd={(fromIndex, toIndex) => {
                      let data = cloneDeep(hosting_brand_details);
                      if (data) {
                        let firstEle = data[fromIndex - 1];
                        let secondEle = data[toIndex - 1];
                        data[fromIndex - 1] = {
                          ...secondEle,
                          sequence: firstEle.sequence,
                        };
                        data[toIndex - 1] = {
                          ...firstEle,
                          sequence: secondEle.sequence,
                        };
                      }
                      data = data.filter((item) => item);
                      set_hosting_brand_details(data);
                    }}
                    handleSelector={"a"}
                    ignoreSelector={"tr.ant-table-expanded-row"}
                    nodeSelector={"tr.ant-table-row"}
                  >
                    <Table
                      columns={hosting_brand_columns}
                      pagination={false}
                      dataSource={hosting_brand_details}
                      showHeader={false}
                      bordered={false}
                      rowClassName={"brand_row"}
                      scroll={{ y: 240 }}
                      rowKey={(obj) => obj.hosting_brand_id + "_hosting_brand"}
                      expandable={{
                        expandedRowRender: (record, index) => {
                          if (record && record.brand_title && !record.is_delete)
                            return (
                              <>
                                <Row className="brand_invite_row">
                                  <Col span={9}>
                                    <div>
                                      <span style={{ marginLeft: "10px" }}>
                                        Invite New Speaker
                                      </span>
                                    </div>
                                  </Col>
                                  <Col span={9}>
                                    <Select
                                      getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                      }}
                                      showSearch
                                      optionFilterProp="children"
                                      placeholder="Search Agent"
                                      className="adminSatellite_search_agent"
                                      // value={null}
                                      onChange={(e, i) => {
                                        let newSpeakerArr =
                                          record.agentsCollectionData &&
                                          record.agentsCollectionData.filter(
                                            (item) => item && item.user_id === e
                                          );

                                        let data = cloneDeep(
                                          hosting_brand_details
                                        );
                                        let singleData = data[index];
                                        let speakers =
                                          singleData.auditorium_hosting_speakers
                                            ? singleData.auditorium_hosting_speakers
                                            : [];

                                        let checkSpeakerExistArr =
                                          speakers &&
                                          speakers.filter(
                                            (item) =>
                                              item &&
                                              item.assigned_speaker_id === e &&
                                              item.is_delete === false
                                          );
                                        if (
                                          !checkSpeakerExistArr ||
                                          isEmpty(checkSpeakerExistArr)
                                        ) {
                                          speakers.push({
                                            assigned_speaker_id: e,
                                            first_name:
                                              newSpeakerArr[0].first_name,
                                            last_name:
                                              newSpeakerArr[0].last_name,
                                            rsvp_type: "PENDING",
                                            hosting_speaker_id: null,
                                            is_delete: false,
                                          });
                                          data[
                                            index
                                          ].auditorium_hosting_speakers =
                                            speakers;
                                          set_hosting_brand_details(data);
                                        } else
                                          toast.error("Speaker already added!");
                                      }}
                                    >
                                      {record.agentsCollectionData &&
                                        record.agentsCollectionData.map(
                                          (data) => (
                                            <Option
                                              key={
                                                "invite_users_selected_" +
                                                data.user_id
                                              }
                                              value={data.user_id}
                                              className="adminSatellite_section_4_taggedagents_item"
                                            >
                                              {data.first_name +
                                                " " +
                                                data.last_name}
                                            </Option>
                                          )
                                        )}
                                    </Select>
                                  </Col>
                                </Row>
                                {record &&
                                record.auditorium_hosting_speakers ? (
                                  <Divider style={{ margin: "0px 0" }} />
                                ) : null}
                                {record && record.auditorium_hosting_speakers
                                  ? record.auditorium_hosting_speakers.map(
                                      (ele, indexToDelete) => {
                                        if (ele && !ele.is_delete)
                                          return (
                                            <Row className="brand_invite_row">
                                              <Col span={9}>
                                                <span
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  {ele.first_name +
                                                    " " +
                                                    ele.last_name}
                                                </span>
                                              </Col>
                                              <Col span={9}>
                                                {getStatusButton(ele)}
                                              </Col>
                                              <Col
                                                span={4}
                                                style={{ textAlign: "center" }}
                                              ></Col>
                                              <Col span={2}>
                                                <img
                                                  alt="close"
                                                  style={{ marginLeft: "10px" }}
                                                  src={close}
                                                  onClick={() => {
                                                    let clonedDataSource =
                                                      cloneDeep(
                                                        hosting_brand_details
                                                      );
                                                    let clonedSpeakers =
                                                      cloneDeep(
                                                        clonedDataSource[index]
                                                          .auditorium_hosting_speakers
                                                      );
                                                    clonedSpeakers[
                                                      indexToDelete
                                                    ].is_delete = true;

                                                    clonedDataSource[
                                                      index
                                                    ].auditorium_hosting_speakers =
                                                      clonedSpeakers;
                                                    set_hosting_brand_details(
                                                      clonedDataSource
                                                    );
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          );
                                        else return null;
                                      }
                                    )
                                  : null}
                              </>
                            );
                          else return null;
                        },
                        expandRowByClick: true,
                        showExpandColumn: false,
                        defaultExpandAllRows: false,
                        // rowExpandable: true,
                      }}
                    />
                  </ReactDragListView>
                ) : (
                  <>
                    <Empty description="No Data Found" />
                    {isEmptyBrands !== "no" ? (
                      <span className="text-danger py-1">
                        Hosting brands are required
                      </span>
                    ) : null}
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col span={6} className="modal_field_label">
                <h6 className="adminSatellite_addNewBrandTitle addBrandTitle">
                  Add a New Brand
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Brand</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="assigned_brand_id"
                  optionFilterProp="children"
                  placeholder="Select Brand"
                  className="w-100 fs-bold-14"
                  value={
                    add_new_brand.assigned_brand_id
                      ? add_new_brand.assigned_brand_id
                      : undefined
                  }
                  onChange={async (e) => {
                    let cloned_add_new_brand = add_new_brand;
                    cloned_add_new_brand.assigned_brand_id = e;
                    let itemSelected = brandsCollection.find(
                      (item) => item.brand_id === e
                    );
                    cloned_add_new_brand.brand_title = itemSelected.brand_title;
                    cloned_add_new_brand.assigned_zone_id = undefined;

                    cloned_add_new_brand.is_chat_enabled = false;
                    cloned_add_new_brand.is_demo_room_enabled = false;
                    cloned_add_new_brand.is_assets_enabled = false;
                    cloned_add_new_brand.is_booth_link_enabled = true;
                    cloned_add_new_brand.is_video_call_enabled = false;
                    setSelectedZoneData(defaultFlags);
                    set_add_new_brand(cloned_add_new_brand);
                    dispatch(getZone({ brand_id: e, is_active: true }));
                    let speakersAPI = await getSpeakersForBrand({
                      event_id: props.sessionData.event_id,
                    });
                    if (speakersAPI) setSpeakerCollection(speakersAPI);
                  }}
                  onBlur={() =>
                    validatorAddBrand.current.showMessageFor(
                      "assigned_brand_id"
                    )
                  }
                  listHeight={120}
                  listItemHeight={4}
                >
                  {brandsCollection &&
                    brandsCollection.map((item, i) => {
                      return (
                        <Option
                          key={item.brand_id + "_brand"}
                          value={item.brand_id}
                        >
                          {item.brand_title}
                        </Option>
                      );
                    })}
                </Select>
                {validatorAddBrand.current.message(
                  "Brand Name",
                  add_new_brand.assigned_brand_id,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Zone</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="assigned_zone_id"
                  optionFilterProp="children"
                  className="w-100 fs-bold-14"
                  placeholder="Select Zone"
                  value={
                    add_new_brand.assigned_zone_id
                      ? add_new_brand.assigned_zone_id
                      : undefined
                  }
                  onChange={async (val) => {
                    const res = await getZoneDetails({ zone_id: val });
                    if (res?.zone_id) {
                      const fiveFlags = {
                        is_chat_enabled: res.is_text_chat_enable,
                        is_demo_room_enabled: res.is_video_demo_room_enable,
                        is_assets_enabled:
                          res.zones_assets_id?.length > 0 ? true : false,
                        is_booth_link_enabled: res.is_on_booth_leads_enable,
                        is_video_call_enabled:
                          res.is_one_to_one_video_calls_enable,
                      };
                      setSelectedZoneData(fiveFlags);
                      set_add_new_brand({
                        ...add_new_brand,
                        ...fiveFlags,
                        assigned_zone_id: val,
                      });
                    } else
                      onChangeFields(val, "assigned_zone_id", "add_new_brand");
                  }}
                  onBlur={() =>
                    validatorAddBrand.current.showMessageFor("assigned_zone_id")
                  }
                  listHeight={120}
                  listItemHeight={4}
                >
                  {zoneRow &&
                    zoneRow.map((item, i) => {
                      return (
                        <Option
                          key={"add_new_brand" + item.zone_id}
                          value={item.zone_id}
                        >
                          {item.zone_title}
                        </Option>
                      );
                    })}
                </Select>
                {validatorAddBrand.current.message(
                  "Zone",
                  add_new_brand.assigned_zone_id,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Speakers</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  mode="multiple"
                  // className="adminSatellite_selectZone"
                  style={{ width: "100%" }}
                  placeholder="Select Speakers"
                  value={add_new_brand.auditorium_hosting_speakers}
                  onChange={(val) =>
                    onChangeFields(
                      val,
                      "auditorium_hosting_speakers",
                      "add_new_brand"
                    )
                  }
                >
                  {speakerCollection &&
                    speakerCollection.map((data, i) => {
                      return (
                        <Option
                          key={"speaker_id_" + data.user_id}
                          value={data.user_id}
                          className="dropdown_options"
                        >
                          <span>{data.first_name + " " + data.last_name}</span>
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Chat</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={!selectedZoneData.is_chat_enabled ? true : false}
                  checked={add_new_brand.is_chat_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_chat_enabled,
                      "is_chat_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Demo Room</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={
                    !selectedZoneData.is_demo_room_enabled ? true : false
                  }
                  checked={add_new_brand.is_demo_room_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_demo_room_enabled,
                      "is_demo_room_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Asset/s</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={!selectedZoneData.is_assets_enabled ? true : false}
                  checked={add_new_brand.is_assets_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_assets_enabled,
                      "is_assets_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Booth Link</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={
                    !selectedZoneData.is_booth_link_enabled ? true : false
                  }
                  checked={add_new_brand.is_booth_link_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_booth_link_enabled,
                      "is_booth_link_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Video Call</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={
                    !selectedZoneData.is_video_call_enabled ? true : false
                  }
                  checked={add_new_brand.is_video_call_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_video_call_enabled,
                      "is_video_call_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={6} />
              <Col span={6}>
                <Button
                  type="primary"
                  className="adminSatellite_section_2_savechanges"
                  size={"large"}
                  onClick={handleSubmitAddBrand}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </div>
          <div className="adminSatellite_section_3_form">
            <Row>
              <Col span={24}>
                <h5 className="adminSatellite_section_3_title fs-pink">
                  Source
                  <RedAsterick />
                </h5>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">This Platform</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_this_platform_enabled",
                  "source_details",
                  source_details.is_this_platform_enabled
                )}
              </Col>
            </Row>
            {source_details.is_this_platform_enabled ? (
              <>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label" />
                  <Col span={18}>
                    <p className="adminSatellite_section_3_info">
                      NB: If ‘Demo’ is selected, a live stream from the ‘open’
                      demo room of the zone selected below will be shown at the
                      appropriate time.
                      <br /> If ‘Private’ is selected, then a private video room
                      will be created and a link given to the speakers.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}></Col>
                  <Col span={18}>
                    <Row>
                      <Col span={7} className="modal_field_label">
                        <p className="adminSatellite_fs-14">Private Room</p>
                      </Col>
                      <Col span={17}>
                        <Switch
                          disabled={!source_details.is_this_platform_enabled}
                          checked={source_details.is_private_room_enabled}
                          onChange={(val) =>
                            source_details.is_this_platform_enabled
                              ? onChangeFields(
                                  val,
                                  "is_private_room_enabled",
                                  "source_details"
                                )
                              : null
                          }
                          className="adminSatellite_switch"
                        />
                      </Col>
                    </Row>
                    {source_details.is_private_room_enabled ? (
                      <Row className="mb-3">
                        <Col span={7} className="modal_field_label">
                          <p>
                            Private Room Name
                            <RedAsterick />
                          </p>
                        </Col>
                        <Col span={17}>
                          <Input
                            disabled={!source_details.is_private_room_enabled}
                            name="private_room_name"
                            className="adminSatellite_section_3_linkInput"
                            onChange={(e) =>
                              onChangeFields(
                                e.target.value,
                                "private_room_name",
                                "source_details"
                              )
                            }
                            value={source_details.private_room_name}
                            onBlur={() =>
                              validator.current.showMessageFor(
                                "private_room_name"
                              )
                            }
                          />
                          {source_details.is_private_room_enabled
                            ? validator.current.message(
                                "Room name",
                                source_details.private_room_name,
                                "required",
                                { className: "text-danger py-1" }
                              )
                            : null}
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      <Col span={7} className="modal_field_label"></Col>
                      <Col span={7}>
                        <span className="adminSatellite_or">OR</span>
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={7} className="modal_field_label">
                        <p className="adminSatellite_fs-14">Zone Demo Room</p>
                      </Col>
                      <Col span={17}>
                        <Switch
                          disabled={!source_details.is_this_platform_enabled}
                          checked={source_details.is_zone_demo_room_enabled}
                          onChange={(val) =>
                            source_details.is_this_platform_enabled
                              ? onChangeFields(
                                  val,
                                  "is_zone_demo_room_enabled",
                                  "source_details"
                                )
                              : null
                          }
                          className="adminSatellite_switch"
                        />
                      </Col>
                    </Row>
                    {source_details.is_zone_demo_room_enabled ? (
                      <>
                        <Row className="mb-3">
                          <Col span={6} className="modal_field_label">
                            <p>Brand</p>
                          </Col>
                          <Col span={18}>
                            <Select
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                              name="brand_id"
                              optionFilterProp="children"
                              placeholder="Select Brand"
                              className="w-100 fs-bold-14"
                              value={source_details.brand_id}
                              disabled={
                                !source_details.is_zone_demo_room_enabled
                              }
                              onChange={async (e) => {
                                let source_details_old =
                                  cloneDeep(source_details);
                                source_details_old.brand_id = e;
                                source_details_old.zone_id = undefined;
                                set_source_details(source_details_old);
                                const filteredData =
                                  zoneCollection &&
                                  zoneCollection.filter(
                                    (item) => item.brand_id === e
                                  );

                                set_source_zone_list(filteredData);
                              }}
                              onBlur={() =>
                                validator.current.showMessageFor("brand_id")
                              }
                              listHeight={120}
                              listItemHeight={4}
                            >
                              {brandsCollection &&
                                brandsCollection.map((item, i) => {
                                  return (
                                    <Option
                                      key={item.brand_id + "_brand"}
                                      value={item.brand_id}
                                    >
                                      {item.brand_title}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {source_details.is_zone_demo_room_enabled
                              ? validator.current.message(
                                  "Brand",
                                  source_details.brand_id,
                                  "required",
                                  { className: "text-danger py-1" }
                                )
                              : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={6} className="modal_field_label">
                            <p>Zone</p>
                          </Col>
                          <Col span={18}>
                            <Select
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                              showSearch
                              name="zone_id"
                              bordered={false}
                              className="adminSatellite_selectZone"
                              placeholder="Select a Zone"
                              defaultValue={source_details.zone_id}
                              disabled={
                                !source_details.is_zone_demo_room_enabled
                              }
                              onChange={(val) =>
                                onChangeFields(val, "zone_id", "source_details")
                              }
                              onBlur={() =>
                                validator.current.showMessageFor("zone_id")
                              }
                            >
                              {source_zone_list &&
                                source_zone_list.map((item, i) => {
                                  return (
                                    <Option
                                      key={"source_zone_" + item.zone_id}
                                      value={item.zone_id}
                                    >
                                      {item.zone_title}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {source_details.is_zone_demo_room_enabled &&
                            source_details.brand_id
                              ? validator.current.message(
                                  "Zone",
                                  source_details.zone_id,
                                  "required",
                                  { className: "text-danger py-1" }
                                )
                              : null}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </>
            ) : null}

            <Row>
              <Col span={6} className="modal_field_label">
                <span className="adminSatellite_or">OR</span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6} className="modal_field_label">
                <p>External Source</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_from_external_source",
                  "source_details",
                  source_details.is_from_external_source
                )}
              </Col>
            </Row>
            {source_details.is_from_external_source ? (
              <>
                <Row className="mb-3">
                  <Col span={6} className="modal_field_label">
                    <p>Link</p>
                  </Col>
                  <Col span={18}>
                    <Input
                      disabled={!source_details.is_from_external_source}
                      name="external_source_link"
                      className="adminSatellite_section_3_linkInput"
                      onChange={(e) =>
                        onChangeFields(
                          e.target.value,
                          "external_source_link",
                          "source_details"
                        )
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("external_source_link")
                      }
                      value={source_details.external_source_link}
                      autoComplete="false"
                      onFocus={(e) =>
                        e.target.setAttribute("autocomplete", "off")
                      }
                    />
                    {source_details.is_from_external_source
                      ? validator.current.message(
                          "",
                          source_details.external_source_link,
                          "required",
                          { className: "text-danger py-1" }
                        )
                      : null}
                  </Col>
                </Row>
                <Row>
                  <Col span={6} className="modal_field_label">
                    <p>Key/passcode/password</p>
                  </Col>
                  <Col span={18}>
                    <Input.Password
                      disabled={!source_details.is_from_external_source}
                      name="external_source_passcode"
                      className="adminSatellite_section_3_inputPassword"
                      placeholder="****"
                      iconRender={(visible) => (visible = "")}
                      onChange={(e) =>
                        onChangeFields(
                          e.target.value,
                          "external_source_passcode",
                          "source_details"
                        )
                      }
                      value={source_details.external_source_passcode}
                      autoComplete="new-password"
                      onFocus={(e) =>
                        e.target.setAttribute("autocomplete", "off")
                      }
                    />
                  </Col>
                </Row>
              </>
            ) : null}
          </div>

          <div className="adminSatellite_section_4_form">
            <Row className="mb-3">
              <Col span={6}>
                <h5 className="adminSatellite_section_4_title fs-pink">
                  Producers / Curators
                </h5>
              </Col>
              <Col span={9}>
                <h6 className="adminSatellite_section_4_heading">Assigned</h6>
              </Col>
              <Col span={9}>
                <h6 className="adminSatellite_section_4_heading">
                  Tagged Agents
                </h6>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6}>
                <p className="modal_field_label">Agents</p>
              </Col>
              <Col span={8}>
                <div id="producers_ids_div" className="dropdown_container">
                  <Select
                    id="producers_ids"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) =>
                      handleDDLChange(value, "producers_ids", "left")
                    }
                    open={openProducersList}
                    onFocus={() => setOpenProducersList(true)}
                    onBlur={() => setOpenProducersList(false)}
                    onSearch={() => setOpenProducersList(true)}
                    getPopupContainer={() =>
                      document.getElementById("producers_ids_div")
                    }
                    listHeight={200}
                    listItemHeight={6}
                    value="All Agents"
                  >
                    {producers_ids &&
                      producers_ids.map((data) => (
                        <Option
                          key={"producer_main_" + data.user_id}
                          value={data.user_id}
                          className="dropdown_options"
                        >
                          {data.first_name + " " + data.last_name}
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={1}>
                <div
                  id="selected_producers_ids_div"
                  className="dropdown_container"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search Tagged Agents"
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("selected_producers_ids_div")
                    }
                    open={openProducersList}
                    onFocus={() => setOpenProducersList(true)}
                    onBlur={() => setOpenProducersList(false)}
                    onSearch={() => setOpenProducersList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="Tagged Agents"
                  >
                    {selected_producers_ids?.map((data) => {
                      return (
                        <Option
                          key={"producer_selected_" + data.user_id}
                          value={data.user_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.first_name + " " + data.last_name}
                            </span>
                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.user_id,
                                    "producers_ids",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
          <div className="adminSatellite_section_5_form">
            <Row className="modal_row">
              <Col span={6}>
                <h5 className="adminSatellite_section_5_title fs-pink">
                  Message Board
                </h5>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "new_post_default_session_enabled",
                  "message_board_details",
                  message_board_details.new_post_default_session_enabled
                )}
                <h6 className="adminSatellite_section_5_info">
                  Default status of new posts
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="label">
                <p className="fs-14">Message Board</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "message_board_enabled",
                  "message_board_details",
                  message_board_details.message_board_enabled
                )}
              </Col>
            </Row>
            {message_board_details.message_board_enabled ? (
              <Row className="modal_row">
                <Col span={6} className="label">
                  <p className="fs-14">Moderate Message Board</p>
                </Col>
                <Col span={18}>
                  {renderSwitches(
                    "moderate_message_board_enabled",
                    "message_board_details",
                    message_board_details.moderate_message_board_enabled
                  )}
                </Col>
              </Row>
            ) : null}

            {message_board_details.message_board_enabled &&
            message_board_details.moderate_message_board_enabled ? (
              <>
                <Row className="mb-3">
                  <Col span={6} />
                  <Col span={9}>
                    <h6 className="adminSatellite_section_5_heading">
                      Assigned
                    </h6>
                  </Col>
                  <Col span={9}>
                    <h6 className="adminSatellite_section_5_heading">
                      Tagged Agents
                    </h6>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col span={6}>
                    <p style={{ opacity: 1 }} className="label">
                      Assign Agents to Moderate
                    </p>
                  </Col>
                  <Col span={8}>
                    <div
                      id="message_board_ids_div_1"
                      className="dropdown_container"
                    >
                      <Select
                        disabled={
                          !message_board_details.moderate_message_board_enabled
                        }
                        id="assigned_agent_ids"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        onChange={(value) =>
                          handleDDLChange(value, "assigned_agent_ids", "left")
                        }
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById("message_board_ids_div_1")
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("assigned_agent_ids")
                        }
                        listHeight={100}
                        listItemHeight={4}
                        value="All Agentss"
                      >
                        {assigned_agent_ids &&
                          assigned_agent_ids.map((data) => (
                            <Option
                              key={"messageBoard_agent_" + data.user_id}
                              value={data.user_id}
                              className="dropdown_options"
                            >
                              <span>
                                {data.first_name + " " + data.last_name}
                              </span>
                            </Option>
                          ))}
                      </Select>
                      {message_board_details.moderate_message_board_enabled &&
                        validator.current.message(
                          "Agents",
                          selected_assigned_agent_ids,
                          "required",
                          {
                            className:
                              "text-danger py-1 agents-error-margin-top mb-2",
                          }
                        )}
                    </div>
                  </Col>
                  <Col span={8} offset={1}>
                    <div
                      id="selected_assigned_agent_ids_div"
                      className="adminSatellite_section_5_taggedagents_container"
                    >
                      <Select
                        disabled={
                          !message_board_details.moderate_message_board_enabled
                        }
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search Tagged Agents"
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById(
                            "selected_assigned_agent_ids_div"
                          )
                        }
                        listHeight={100}
                        listItemHeight={4}
                        value="Tagged Agents"
                      >
                        {selected_assigned_agent_ids?.map((data) => {
                          return (
                            <Option
                              key={"assigned_agents_selected_" + data.user_id}
                              value={data.user_id}
                              className="adminSatellite_section_4_taggedagents_item"
                            >
                              {data.first_name + " " + data.last_name}
                              <span className="float-end">
                                <CloseOutlined
                                  className="float-right text-danger mr-0"
                                  onClick={() =>
                                    handleDDLChange(
                                      data.user_id,
                                      "assigned_agent_ids",
                                      "right"
                                    )
                                  }
                                />
                              </span>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Col>
                </Row>
              </>
            ) : null}
          </div>

          <div className="adminSatellite_section_7_form">
            <Row>
              <Col span={24}>
                <h5 className="adminSatellite_section_7_title fs-pink">
                  Restrict Access
                </h5>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={24}>
                <p className="adminSatellite_section_3_info">
                  NB: Configure who can view / access your event.
                  <br /> Attendees with any black list attributes will not be
                  able to to see the event on any calendars or access the event.
                  <br /> If an attendee/attribute is on the White List they will
                  be able to see/attaned the event regardless of any black list
                  attributes.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label">
                <p className="pt-2">Agents</p>
              </Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  Allow List Users
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (selected_restricted_users.length !== 0) {
                        handleAllowAllBlockAll("restricted_users", "allowAll");
                      }
                    }}
                  >
                    Allow All
                  </Button>
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Block List Users
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (restricted_users.length !== 0) {
                        handleAllowAllBlockAll("restricted_users", "blockAll");
                      }
                    }}
                  >
                    Block All
                  </Button>
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div
                  id="restricted_users_div"
                  className="adminSatellite_section_5_taggedagents_container"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search Agents"
                    className="border border-gray fs-bold-14"
                    onChange={(val) =>
                      handleDDLChange(val, "restricted_users", "left")
                    }
                    open={openUserList}
                    onFocus={() => setOpenUsersList(true)}
                    onBlur={() => setOpenUsersList(false)}
                    onSearch={() => setOpenUsersList(true)}
                    getPopupContainer={() =>
                      document.getElementById("restricted_users_div")
                    }
                    listHeight={200}
                    listItemHeight={6}
                    value="All Agents"
                  >
                    {restricted_users?.map((data) => {
                      return (
                        <Option
                          key={"restricted_users_" + data.user_id}
                          value={data.user_id}
                          className="dropdown_options"
                        >
                          {data.first_name + " " + data.last_name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_users_div"
                  className="adminSatellite_section_5_taggedagents_container"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search Tagged Agents"
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("selected_restricted_users_div")
                    }
                    open={openUserList}
                    onFocus={() => setOpenUsersList(true)}
                    onBlur={() => setOpenUsersList(false)}
                    onSearch={() => setOpenUsersList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="Tagged Agents"
                  >
                    {selected_restricted_users?.map((data) => {
                      return (
                        <Option
                          key={"restricted_users_selected_" + data.user_id}
                          value={data.user_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.first_name + " " + data.last_name}
                            </span>

                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.user_id,
                                    "restricted_users",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}></Col>
              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label">
                <p className="pt-2">Brand List</p>
              </Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  Allow List Brands
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (selected_restricted_brands.length !== 0) {
                        handleAllowAllBlockAll("restricted_brands", "allowAll");
                      }
                    }}
                  >
                    Allow All
                  </Button>
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Block List Brands
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (restricted_brands.length !== 0) {
                        handleAllowAllBlockAll("restricted_brands", "blockAll");
                      }
                    }}
                  >
                    Block All
                  </Button>
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div id="restricted_brands_div" className="dropdown_container">
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("restricted_brands_div")
                    }
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    open={openBrandsList}
                    onFocus={() => setOpenBrandsList(true)}
                    onBlur={() => setOpenBrandsList(false)}
                    onSearch={() => setOpenBrandsList(true)}
                    onChange={(value) => {
                      if (source_details.brand_id === value)
                        toast.error(
                          "This brand cannot be blacklisted as it is in source"
                        );
                      else {
                        const filteredData =
                          hosting_brand_details &&
                          hosting_brand_details.filter(
                            (item) => item.assigned_brand_id === value
                          );
                        if (filteredData && !isEmpty(filteredData))
                          toast.error(
                            "This brand cannot be blacklisted as it is in hosting brands"
                          );
                        else
                          handleDDLChange(value, "restricted_brands", "left");
                      }
                    }}
                    className="border border-gray fs-bold-14"
                    listHeight={200}
                    listItemHeight={6}
                    value="All Brands"
                  >
                    {restricted_brands?.map((data) => (
                      <Option
                        key={"restricted_brands_" + data.brand_id}
                        value={data.brand_id}
                        className="dropdown_options"
                      >
                        {data.brand_title}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_brands_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_brands"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_brands_div")
                    }
                    open={openBrandsList}
                    onFocus={() => setOpenBrandsList(true)}
                    onBlur={() => setOpenBrandsList(false)}
                    onSearch={() => setOpenBrandsList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="All Brands"
                  >
                    {selected_restricted_brands &&
                      selected_restricted_brands.map((data) => (
                        <Option
                          key={"selected_restricted_brands_" + data.brand_id}
                          value={data.brand_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.brand_title}
                            </span>

                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.brand_id,
                                    "restricted_brands",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}></Col>
              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label">
                <p className="pt-2">Country List</p>
              </Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  Allow List Countries
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (selected_restricted_countries.length !== 0) {
                        handleAllowAllBlockAll(
                          "restricted_countries",
                          "allowAll"
                        );
                      }
                    }}
                  >
                    Allow All
                  </Button>
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Block List Countries
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (restricted_countries.length !== 0) {
                        handleAllowAllBlockAll(
                          "restricted_countries",
                          "blockAll"
                        );
                      }
                    }}
                  >
                    Block All
                  </Button>
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div
                  id="restricted_countries_div"
                  className="dropdown_container"
                >
                  <Select
                    id="restricted_countries"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) =>
                      handleDDLChange(value, "restricted_countries", "left")
                    }
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_countries_div")
                    }
                    open={openCountriesList}
                    onFocus={() => setOpenCountriesList(true)}
                    onBlur={() => setOpenCountriesList(false)}
                    onSearch={() => setOpenCountriesList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="All Countries"
                  >
                    {restricted_countries &&
                      restricted_countries.map((data) => (
                        <Option
                          key={"restricted_countries_" + data.country_id}
                          value={data.country_id}
                          className="dropdown_options"
                        >
                          {data.country}
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_countries_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_countries"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    className="border border-gray fs-bold-14"
                    open={openCountriesList}
                    onFocus={() => setOpenCountriesList(true)}
                    onBlur={() => setOpenCountriesList(false)}
                    onSearch={() => setOpenCountriesList(true)}
                    getPopupContainer={() =>
                      document.getElementById(
                        "selected_restricted_countries_div"
                      )
                    }
                    listHeight={200}
                    listItemHeight={6}
                    value="Tagged Countries"
                  >
                    {selected_restricted_countries &&
                      selected_restricted_countries.map((data) => (
                        <Option
                          key={
                            "selected_restricted_countries_" + data.country_id
                          }
                          value={data.country_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.country}
                            </span>
                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.country_id,
                                    "restricted_countries",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}></Col>

              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label">
                <p className="pt-2">Job Titles List</p>
              </Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  Allow List Job Titles
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (selected_restricted_job_titles.length !== 0) {
                        handleAllowAllBlockAll(
                          "restricted_job_titles",
                          "allowAll"
                        );
                      }
                    }}
                  >
                    Allow All
                  </Button>
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Block List Job Titles
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (restricted_job_titles.length !== 0) {
                        handleAllowAllBlockAll(
                          "restricted_job_titles",
                          "blockAll"
                        );
                      }
                    }}
                  >
                    Block All
                  </Button>
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div
                  id="restricted_job_titles_div"
                  className="dropdown_container"
                >
                  <Select
                    id="restricted_job_titles"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) =>
                      handleDDLChange(value, "restricted_job_titles", "left")
                    }
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_job_titles_div")
                    }
                    open={openJobTitlesList}
                    onFocus={() => setOpenJobTitlesList(true)}
                    onBlur={() => setOpenJobTitlesList(false)}
                    onSearch={() => setOpenJobTitlesList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="All Job Titles"
                  >
                    {restricted_job_titles &&
                      restricted_job_titles.map((data) => (
                        <Option
                          key={"restricted_job_titles_" + data.job_title_id}
                          value={data.job_title_id}
                          className="dropdown_options"
                        >
                          {data.job_title}
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_job_titles_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_job_titles"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    className="border border-gray fs-bold-14"
                    open={openJobTitlesList}
                    onFocus={() => setOpenJobTitlesList(true)}
                    onBlur={() => setOpenJobTitlesList(false)}
                    onSearch={() => setOpenJobTitlesList(true)}
                    getPopupContainer={() =>
                      document.getElementById(
                        "selected_restricted_job_titles_div"
                      )
                    }
                    listHeight={200}
                    listItemHeight={6}
                    value="All Job Titles"
                  >
                    {selected_restricted_job_titles &&
                      selected_restricted_job_titles.map((data) => (
                        <Option
                          key={
                            "selected_restricted_job_titles_" +
                            data.job_title_id
                          }
                          value={data.job_title_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.job_title}
                            </span>

                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.job_title_id,
                                    "restricted_job_titles",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
          <div className="adminSatellite_section_8_form">
            <Row>
              <Col span={24}>
                <h5 className="adminSatellite_section_8_title">
                  Previous & Pending Invitees
                </h5>
              </Col>
            </Row>
            {props.sessionData && props.sessionData.auditorium_session_id ? (
              <>
                {previous_pending_invites ? (
                  <Row className="modal_row">
                    <Col span={6} />
                    <Col span={9} className="adminSatellite_eyeViewBtn">
                      <Button
                        type="primary"
                        className="adminSatellite_section_8_previousInvitedBtn"
                        size={"large"}
                        onClick={() =>
                          openInviteesModalAndApiCall(
                            previous_pending_invites[0],
                            false
                          )
                        }
                      >
                        View List of Previously Invited
                      </Button>
                    </Col>
                  </Row>
                ) : null}

                <Row className="modal_row">
                  <Col span={6} />
                  <Col span={9}>
                    <h6 className="adminSatellite_section_8_heading">
                      Currently Scheduled Invitations
                    </h6>
                  </Col>
                </Row>
                {audiSessionAllData && previous_pending_invites ? (
                  previous_pending_invites.map((ele, index) => {
                    if (ele && !ele.is_delete)
                      return (
                        <Row
                          className="modal_row"
                          key={ele.auditorium_invitation_setup_id + "_inviteId"}
                        >
                          <Col span={6} className="modal_field_label">
                            Date & Time
                          </Col>
                          <Col span={7} className="invite_center">
                            {ele.date_time_to_invite
                              ? moment(ele.date_time_to_invite).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : null}
                          </Col>
                          <Col span={9} offset={2}>
                            <Button
                              type="primary"
                              className="adminSatellite_section_8_viewListBtn"
                              size={"large"}
                              loading={invitationModal.isLoader}
                              onClick={async () =>
                                openInviteesModalAndApiCall(ele, true)
                              }
                            >
                              View List
                            </Button>
                            <img
                              alt="delete"
                              style={{ marginLeft: "15px" }}
                              src={blackbin}
                              onClick={() => {
                                let clonedDeleteIds = cloneDeep(
                                  delete_scheduled_invitations
                                );
                                clonedDeleteIds.push(
                                  ele.auditorium_invitation_setup_id
                                );
                                set_delete_scheduled_invitations(
                                  clonedDeleteIds
                                );

                                let clonedData = cloneDeep(
                                  previous_pending_invites
                                );
                                clonedData[index].is_delete = true;
                                set_previous_pending_invites(clonedData);
                              }}
                            />
                          </Col>
                        </Row>
                      );
                    else return null;
                  })
                ) : (
                  <Empty description={"No Scheduled Invitations"} />
                )}
              </>
            ) : (
              <Empty description="No invitees" />
            )}
          </div>
          <Modal
            title={<div className="invite_center">{"Previously Invited"}</div>}
            visible={invitationModal.isOpen}
            width={650}
            onOk={() =>
              setInvitationModal({ isOpen: false, data: null, isLoader: false })
            }
            onCancel={() =>
              setInvitationModal({ isOpen: false, data: null, isLoader: false })
            }
            footer={null}
          >
            <div className="px-3">
              <Table
                columns={invitation_table_columns}
                pagination={false}
                dataSource={invitationTableData}
                bordered={false}
                onChange={handleInviteesTableChange}
                scroll={{ y: 240 }}
                rowKey={(obj) => obj.rsvp_detail_id + "_invi"}
              />
            </div>
          </Modal>

          <Row>
            <Col span={24} className="d-flex">
              <h5 className="section_title ms-1 adminSatellite_section_9_title fs-pink mt-4">
                New Invitees
                <Switch
                  className="ms-3"
                  onChange={(val) => {
                    setInviteesToggle(!inviteesToggle);
                    if (val === false) {
                      set_invited_brands([]);
                      set_invited_countries([]);
                      set_invited_job_titles([]);
                      set_invited_user_types([]);
                      set_invited_zones([]);
                      set_invitation_setup({
                        is_iCal_download_enabled: false,
                        is_reinvite_enabled: false,
                        send_invitation_now: false,

                        is_scheduled: false,
                      });
                    }
                  }}
                ></Switch>
              </h5>
            </Col>
          </Row>

          {inviteesToggle ? (
            <>
              <div className="">
                <Row>
                  <Col span={6} />
                  <Col span={9}>
                    <h6 className="adminSatellite_section_9_heading">
                      Filter Attendees
                    </h6>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>Brand</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      disabled={!inviteesToggle ? true : false}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Select Brands"
                      value={invited_brands}
                      onChange={(val) => set_invited_brands(val)}
                    >
                      {brandCollectionForInvitees &&
                        brandCollectionForInvitees.map((item, i) => {
                          return (
                            <Option
                              key={"invited_brands_" + item.brand_id}
                              value={item.brand_id}
                            >
                              {item.brand_title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>Zone</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      disabled={!inviteesToggle ? true : false}
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Select Zones"
                      value={invited_zones}
                      onChange={(val) => set_invited_zones(val)}
                    >
                      {zoneCollectionForInvitees &&
                        zoneCollectionForInvitees.map((item, i) => {
                          return (
                            <Option
                              key={"invited_zones" + item.zone_id}
                              value={item.zone_id}
                            >
                              {item.zone_title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>User Type</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      style={{ width: "100%" }}
                      mode="multiple"
                      disabled={!inviteesToggle ? true : false}
                      placeholder="Select User Types"
                      value={invited_user_types}
                      onChange={(val) => set_invited_user_types(val)}
                    >
                      <Option value="AGENT">AGENT</Option>
                      <Option value="DELEGATE">DELEGATE</Option>
                      <Option value="SPEAKER">SPEAKER</Option>
                    </Select>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>Country</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      disabled={!inviteesToggle ? true : false}
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Select Countries"
                      value={invited_countries}
                      onChange={(val) => set_invited_countries(val)}
                    >
                      {countryCollection &&
                        countryCollection.map((item, i) => {
                          return (
                            <Option
                              key={"invited_countries" + item.country_id}
                              value={item.country_id}
                            >
                              {item.country}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>Job Title</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      disabled={!inviteesToggle ? true : false}
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Select Job Titles"
                      value={invited_job_titles}
                      onChange={(val) => set_invited_job_titles(val)}
                    >
                      {jobTitleCollection &&
                        jobTitleCollection.map((item, i) => {
                          return (
                            <Option
                              key={"invited_job_titles" + item.job_title_id}
                              value={item.job_title_id}
                            >
                              <span>{item.job_title}</span>
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
              </div>
              <div className="adminSatellite_section_10_form">
                <Row>
                  <Col span={24}>
                    <h5 className="section_title section_theme_title fs-pink">
                      Invitation Setup
                    </h5>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>
                      Title
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={18}>
                    <Input
                      name="invitation_title"
                      className="adminSatellite_inputTitle"
                      placeholder="Enter a Title here"
                      value={invitation_setup.invitation_title}
                      onChange={(e) =>
                        onChangeFields(
                          e.target.value,
                          "invitation_title",
                          "invitation_setup"
                        )
                      }
                      disabled={!inviteesToggle ? true : false}
                      onBlur={() =>
                        validator.current.showMessageFor("invitation_title")
                      }
                      minLength={1}
                      maxLength={100}
                    />
                    {validator.current.message(
                      "",
                      invitation_setup.invitation_title,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p style={{ alignSelf: "flex-start" }}>
                      Description
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={18}>
                    <TextArea
                      name="invitation_description"
                      className="adminSatellite_inputDescription"
                      placeholder="Enter a description here"
                      disabled={!inviteesToggle ? true : false}
                      rows={4}
                      value={invitation_setup.invitation_description}
                      onChange={(e) =>
                        onChangeFields(
                          e.target.value,
                          "invitation_description",
                          "invitation_setup"
                        )
                      }
                      onBlur={() =>
                        validator.current.showMessageFor(
                          "invitation_description"
                        )
                      }
                      minLength={0}
                      maxLength={200}
                    />
                    {validator.current.message(
                      "",
                      invitation_setup.invitation_description,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p className="fs-14">Attach iCal</p>
                  </Col>
                  <Col span={18}>
                    {renderSwitches(
                      "is_iCal_download_enabled",
                      "invitation_setup",
                      invitation_setup.is_iCal_download_enabled
                    )}
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p className="fs-14">Re-invite</p>
                  </Col>
                  <Col span={18}>
                    {renderSwitches(
                      "is_reinvite_enabled",
                      "invitation_setup",
                      invitation_setup.is_reinvite_enabled
                    )}
                    <span style={{ marginLeft: "30px" }}>
                      (if already invited)
                    </span>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={24}>
                    <h5 className="section_title section_black_title">
                      When to Send Invitation
                    </h5>
                  </Col>
                </Row>

                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p className="fs-14">Scheduled</p>
                  </Col>
                  <Col span={18}>
                    {renderSwitches(
                      "is_scheduled",
                      "invitation_setup",
                      invitation_setup.is_scheduled
                    )}
                  </Col>
                </Row>

                {invitation_setup.is_scheduled ? (
                  <Row>
                    <Col span={6} className="modal_field_label">
                      <p>Date & Time</p>
                    </Col>
                    <Col span={7}>
                      <DatePicker
                        disabled={!invitation_setup.is_scheduled}
                        suffixIcon={<CalenderGrey width="15" />}
                        id="invitation_setup_date_time_to_invite"
                        name="invitation_setup_date_time_to_invite"
                        showTime
                        className="w-100"
                        disabledDate={(d) =>
                          !d ||
                          d.isSameOrBefore(
                            invitation_setup?.date_time_to_invite
                          )
                        }
                        disabledTime={(d) =>
                          !d ||
                          d.isSameOrBefore(
                            invitation_setup?.date_time_to_invite
                          )
                        }
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        value={moment(invitation_setup?.date_time_to_invite)}
                        allowClear={false}
                        format={"DD/MM/YYYY HH:mm"}
                        onChange={(e) =>
                          onChangeFields(
                            moment(e).format("YYYY-MM-DD HH:mm:ss"),
                            "date_time_to_invite",
                            "invitation_setup"
                          )
                        }
                        onBlur={() =>
                          validator.current.showMessageFor(
                            "invitation_setup_date_time_to_invite"
                          )
                        }
                      />
                      {invitation_setup.is_scheduled &&
                        !invitation_setup.date_time_to_invite &&
                        validator.current.message(
                          "Date & Time",
                          invitation_setup.date_time_to_invite,
                          "required",
                          { className: "text-danger py-1" }
                        )}
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={7}>
                    <span className="adminSatellite_or">OR</span>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p className="fs-14">Now</p>
                  </Col>
                  <Col span={18}>
                    {renderSwitches(
                      "send_invitation_now",
                      "invitation_setup",
                      invitation_setup.send_invitation_now
                    )}
                  </Col>
                </Row>
              </div>
            </>
          ) : null}
        </>
      )}
    </Modal>
  );
};

export default AudiSession;
