import React from "react";

export const Chat = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="#000000"
			>
				<path id="_379-chat-1" data-name="379-chat-1" d="M5.735,16.328a1.252,1.252,0,0,1-1.252-1.251V13.75a.86.86,0,0,0-.859-.859A3.128,3.128,0,0,1,.5,9.766V3.124A3.128,3.128,0,0,1,3.624,0H14.248a3.128,3.128,0,0,1,3.125,3.125V9.766a3.128,3.128,0,0,1-3.125,3.125H10.874a2.841,2.841,0,0,0-2.022.838L6.618,15.962a1.247,1.247,0,0,1-.883.367ZM3.624,1.562A1.564,1.564,0,0,0,2.062,3.124V9.766a1.564,1.564,0,0,0,1.562,1.562A2.424,2.424,0,0,1,6.046,13.75v.575l1.7-1.7a4.393,4.393,0,0,1,3.127-1.3h3.374A1.564,1.564,0,0,0,15.81,9.766V3.124a1.564,1.564,0,0,0-1.562-1.562ZM15.741,19.9a1.247,1.247,0,0,0,.772-1.155V17.421a.86.86,0,0,1,.859-.859A3.128,3.128,0,0,0,20.5,13.437v-8.2a.781.781,0,1,0-1.562,0v8.2A1.564,1.564,0,0,1,17.372,15a2.424,2.424,0,0,0-2.422,2.421V18l-1.7-1.7A4.394,4.394,0,0,0,10.123,15h-.64a.781.781,0,0,0,0,1.562h.64a2.841,2.841,0,0,1,2.022.838l2.234,2.234a1.247,1.247,0,0,0,1.362.271ZM9.756,6.485a.781.781,0,1,0-.781.781A.781.781,0,0,0,9.756,6.485Zm3.125,0a.781.781,0,1,0-.781.781A.781.781,0,0,0,12.881,6.485Zm-6.249,0a.781.781,0,1,0-.781.781A.781.781,0,0,0,6.632,6.485Zm0,0" transform="translate(-0.5 0)" opacity="0.7"/>
			</svg>
		</>
	);
};
