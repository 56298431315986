import React, { useState } from "react";
import {
  SearchOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Button,
  Table,
  Carousel
} from "antd";

import { Booth } from "../../components/svgJS/Booth";
import { EyeView } from "../../components/svgJS/EyeView";

import { BoothRed } from "../../components/svgJS/BoothRed";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";

// Components
import JoinSession from "./JoinSession";
import DemoRoom from "./DemoRoom";
import NetworkingSession from "./NetworkingSession";
import AuditoriumEvent from "./AuditoriumEvent";
import CreateMeeting from "./CreateMeeting";
import OpenMeeting from "./OpenMeeting";

const Popups = () => {
  return (
    <>
      <JoinSession />

      <DemoRoom />

      <NetworkingSession />

      <AuditoriumEvent />

      <CreateMeeting />
      <OpenMeeting />
    </>
  );
};

export default Popups;
