import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

export const getZone = createAsyncThunk(
  "zoneManagement/getZone",

  async (params) => {
    const response = await ApiCall("GET", "", "/admin/zones/get", params);

    return response.data.data;
  }
);

export const addZone = createAsyncThunk(
  "zoneManagement/addZone",

  async (body) => {
    const response = await ApiCall("POST", body, "/admin/zones/create");

    return response.data;
  }
);

export const editZone = createAsyncThunk(
  "zoneManagement/addZone",

  async (body) => {
    const response = await ApiCall("PUT", body, "/admin/zones/update");

    return response.data;
  }
);
export const changeStatus = createAsyncThunk(
  "zoneManagement/changeStatus",

  async (body) => {
    await ApiCall("PUT", body, "/admin/zones/status/update");

    const response = await ApiCall("GET", "", "/admin/zones/get");

    return response.data.data;
  }
);

export const fetchEventShow = createAsyncThunk(
  "zoneManagement/fetchEventShow",

  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/events/shows/get",
      params
    );

    return response.data.data;
  }
);

export const zoneManagementSlice = createSlice({
  name: "zoneManagement",
  initialState: {
    loading: false,
    data: [],
    eventShow: [],
  },
  reducers: {},
  extraReducers: {
    [getZone.pending]: (state, action) => {
      state.loading = true;
    },
    [getZone.fulfilled]: (state, { payload, meta }) => {
      if (payload) {
        state.data = payload;
      } else {
        state.data = [];
      }
      state.loading = false;
    },
    [getZone.rejected]: (state, action) => {
      state.loading = false;
    },
    //add zone
    [addZone.pending]: (state) => {
      state.loading = true;
    },
    [addZone.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.surveyList = payload
    },
    [addZone.rejected]: (state) => {
      state.loading = false;
    },

    //status

    [changeStatus.pending]: (state, action) => {
      state.loading = true;
    },

    [changeStatus.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [changeStatus.rejected]: (state, action) => {
      state.loading = false;
    },

    //eventshow
    [fetchEventShow.pending]: (state, action) => {
      state.loading = false;
    },

    [fetchEventShow.fulfilled]: (state, { payload, meta }) => {
      state.eventShow = payload;
      state.loading = false;
    },
    [fetchEventShow.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const zoneReducer = zoneManagementSlice.reducer;
