import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { ApiCall, ApiCallForm } from "../../utils/api";

export const getZoneDemoDetails = createAsyncThunk(
  "productZoneSession/getZoneDemoDetails",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/productzonesession/details/get",
      {
        ...params,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        current_timestamp: moment().format("YYYY-MM-DD"),
      }
    );
    return response;
  }
);

export const updateZoneDemoDetails = createAsyncThunk(
  "productzonesession/update",

  async (params) => {
    const response = await ApiCallForm(
      "PUT",
      params,
      "/admin/productzonesession/update",
      "",
      ""
    );

    return response.payload.data;
  }
);

export const createZoneDemo = createAsyncThunk(
  "productzonesession/create",

  async (params) => {
    const response = await ApiCallForm(
      "POST",
      params,
      "/admin/productzonesession/create",
      "",
      ""
    );

    return response.payload.data;
  }
);

export const getInvitationDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/productzonesessioninvitation/details/get",
    params
  );

  if (response?.data?.data) {
    return response.data.data;
  } else return [];
};
