import React, { useState, useEffect } from "react";
import { Switch, Button, Breadcrumb, Input, DatePicker } from "antd";
import { Link } from "react-router-dom";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";
import { useMediaQuery } from "react-responsive";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createCustomer } from "../../redux/slice/customerManagementSlice";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import {
  createISOTimeString,
  isRouteExistInMenu,
  todayIsBeetweenTwoDates,
} from "../../utils/commonFunctions";
import moment from "moment";
import CommonLoader from "../../components/Widgets/CommonLoader";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { INPUT_TEXT_LIMITS } from "../../components/constsnts/common.constants";
import { cloneDeep, values } from "lodash";
import { ApiCall } from "../../utils/api";

const CreateNewAccount = (props) => {
  const [showNewEmail, setshowNewEmail] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [user_type, setuser_type] = useState("");
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");
  const { loading } = useSelector((state) => state.customerManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [checkAccountNameExist, setCheckAccountNameExist] = useState("");
  const [checkIfEmailExist, setCheckIfEmailExist] = useState("");
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({
    flag: false,
    errorMess: "",
  });

  const [showAccountNameErrorMessage, setShowAccountNameErrorMessage] =
    useState({
      flag: false,
      errorMess: "",
    });

  useEffect(async () => {
    setCustomerLoading(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setuser_type(decryptedData);
    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      setTimeout(() => {
        setCustomerLoading(false);
      }, 1000);
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    //Below code is to detect real time if email exist or not
    const timer = setTimeout(() => {
      if (checkIfEmailAlreadyExist !== "") {
        checkIfEmailAlreadyExist();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [checkIfEmailExist]);

  useEffect(() => {
    //Below code is to detect real time if email exist or not
    const timer = setTimeout(() => {
      if (checkAccountNameExist !== "") {
        checkIfAccountNameAlreadyExist();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [checkAccountNameExist]);

  const checkIfEmailAlreadyExist = () => {
    ApiCall("get", null, "/website/customer/checkexist", {
      main_contact_email: checkIfEmailExist,
    }).then((response) => {
      if (response?.data?.code === 500) {
        setShowEmailErrorMessage({
          flag: true,
          errorMess: response?.data?.message,
        });
      } else {
        setShowEmailErrorMessage({
          flag: false,
          errorMess: response?.data?.message,
        });
      }
    });
  };

  const checkIfAccountNameAlreadyExist = () => {
    ApiCall("get", null, "/website/customer/checkexist", {
      account_name: checkAccountNameExist,
    }).then((response) => {
      if (response?.data?.code === 500) {
        setShowAccountNameErrorMessage({
          flag: true,
          errorMess: response?.data?.message,
        });
      } else {
        setShowAccountNameErrorMessage({
          flag: false,
          errorMess: response?.data?.message,
        });
      }
    });
  };

  const handleCreateCustomer = async (data) => {
    const addcustomerResp = await dispatch(createCustomer(data));
    if (addcustomerResp?.payload.code === 200) {
      toast.success(addcustomerResp?.payload.message);
      props.history.push("customermanagement");
    }
    if (addcustomerResp?.payload.code !== 200) {
      toast.error(addcustomerResp?.payload.message);
    }
  };

  const ValidationSchema = Yup.object().shape({
    account_name: Yup.string().required("Required").nullable(),
    main_contact_person: Yup.string()
      .required("Required")
      .nullable()
      .matches(/^[^!@#$%^&*+=<>.,:;|~0-9]*$/, {
        message: "Only Alphabets Are Allowed",
      }),
    main_contact_email: Yup.string()
      .email("Please Enter Valid Email")
      .required("Required")
      .nullable(),
    license_period_start_date: Yup.date().required("Required").nullable(),
    license_period_end_date: Yup.date()
      .min(
        Yup.ref("license_period_start_date"),
        "End Date should be greater than Start Date"
      )
      .required("Required")
      .nullable(),
  });

  return (
    <Formik
      validationSchema={ValidationSchema}
      isInitialValid={false}
      initialValues={{
        is_delete: false,
        is_active: false,
        account_name: "",
        license_period_start_date: null,
        license_period_end_date: null,
        main_contact_person: "",
        main_contact_email: "",
        is_exhibitor_booth_available: false,
        orgaziner_brand: 1,
        is_organizer_booth_available: false,
        max_no_of_events: 10,
        max_no_of_concurrent_events: 10,
        max_no_events_per_month: 10,
        max_no_of_exhibitors: 10,
        max_no_of_exhibitors_per_event: 10,
        max_no_of_users: 10,
        max_no_of_concurrent_users: 10,
        max_bandwidth_per_month: 2,
        max_storage_size: 2,
        max_video_room_hours_per_month: 2,
        max_no_of_events_switch: true,
        max_no_of_concurrent_events_switch: true,
        max_no_events_per_month_switch: true,
        max_no_of_exhibitors_switch: true,
        max_no_of_exhibitors_per_event_switch: true,
        max_no_of_users_switch: true,
        max_no_of_concurrent_users_switch: true,
        max_bandwidth_per_month_switch: true,
        max_storage_size_switch: true,
        max_video_room_hours_per_month_switch: true,
      }}
      onSubmit={(values, { setSubmitting }) => {
        let clonedValues = cloneDeep(values);

        const start_date_str =
          moment(clonedValues.license_period_start_date).format(
            "YYYY/MM/DD HH:mm"
          ) +
          " " +
          moment().format("HH:mm");
        const start_utc = createISOTimeString(start_date_str);
        const end_date_str =
          moment(clonedValues.license_period_end_date).format(
            "YYYY/MM/DD HH:mm"
          ) +
          " " +
          moment().format("HH:mm");
        const end_utc = createISOTimeString(end_date_str);

        clonedValues.license_period_start_date = start_utc;
        clonedValues.license_period_end_date = end_utc;
        handleCreateCustomer(clonedValues);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          {customerLoading ? (
            <CommonLoader />
          ) : (
            <>
              <div className="position-fixed d-flex justify-content-between bg-light w-100 common-sticky-header">
                <div>
                  <h4 className="fs-24">Create Customer Account</h4>
                  <Breadcrumb className="my-2" separator=">">
                    <Breadcrumb.Item>
                      <Link to="/customermanagement">Admin Console</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/customermanagement">Customer Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/createnewaccount">
                        Create Customer Account
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>

                <div></div>
              </div>

              <div className="bg-white p-3">
                <section>
                  <div className="row mt-5">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20 px-2">
                        Account Details
                      </h6>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary">
                        Active
                      </label>
                    </div>
                    <div className={Tablet ? "col-2" : "col-1"}>
                      <Switch
                        disabled
                        checked={values.is_active}
                        name="is_active"
                        onChange={() => {
                          user_type === "SUPER ADMIN" &&
                            setFieldValue("is_active", !values.is_active);
                        }}
                        // className="mx-3"
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary mt-1">
                        Account Name
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <Input
                        name="account_name"
                        className="w-100 border-gray rounded fs-bold-14"
                        value={values.account_name}
                        onKeyUp={(e) => {}}
                        onChange={(e) => {
                          setCheckAccountNameExist(e.target.value);
                          handleChange(e);
                        }}
                        autocomplete="off"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.account_name && touched.account_name ? (
                        <div className="text-danger">{errors.account_name}</div>
                      ) : null}

                      {showAccountNameErrorMessage?.flag ? (
                        <>
                          <span className="fs-pink">
                            {showAccountNameErrorMessage?.errorMess}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary">
                        License Start Date
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <DatePicker
                        suffixIcon={<CalenderGrey width="15" />}
                        format="DD-MM-YYYY"
                        // showTime
                        className="w-100 fs-bold-14"
                        name="license_period_start_date"
                        handleBlur={handleBlur}
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        defaultPickerValue={values.license_period_start_date}
                        onChange={(dateObj) => {
                          setFieldValue("license_period_start_date", dateObj);

                          const ed = values.license_period_end_date;
                          const sd = dateObj;
                          const isValidActive = todayIsBeetweenTwoDates(sd, ed);

                          if (isValidActive && ed !== null)
                            setFieldValue("is_active", true);
                          else setFieldValue("is_active", false);
                        }}
                      />
                      {errors.license_period_start_date &&
                      touched.license_period_start_date ? (
                        <div className="text-danger">
                          {errors.license_period_start_date}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary">
                        License End Date
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <DatePicker
                        format="DD-MM-YYYY"
                        suffixIcon={<CalenderGrey width="15" />}
                        // showTime
                        className="w-100 fs-bold-14"
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        name="license_period_end_date"
                        disabledDate={(d) =>
                          !d ||
                          d.isSameOrBefore(
                            moment(values.license_period_start_date)
                          )
                        }
                        disabledTime={(d) =>
                          !d ||
                          d.isSameOrBefore(values.license_period_start_datee)
                        }
                        handleBlur={handleBlur}
                        defaultPickerValue={values.license_period_end_date}
                        onChange={(dateObj, date) => {
                          setFieldValue("license_period_end_date", dateObj);
                          const sd = values.license_period_start_date;
                          const ed = dateObj;
                          const isValidActive = todayIsBeetweenTwoDates(sd, ed);

                          if (isValidActive && sd !== null) {
                            setFieldValue("is_active", true);
                          } else {
                            setFieldValue("is_active", false);
                          }
                        }}
                      />
                      {errors.license_period_end_date &&
                      touched.license_period_end_date ? (
                        <div className="text-danger">
                          {errors.license_period_end_date}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary mt-1">
                        Main Contact Name
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <Input
                        name="main_contact_person"
                        onChange={handleChange}
                        className="w-100 border-gray rounded fs-bold-14"
                        value={values.main_contact_person}
                        autocomplete="off"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.main_contact_person &&
                      touched.main_contact_person ? (
                        <div className="text-danger">
                          {errors.main_contact_person}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4 pb-4 border-bottom-gray">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary mt-1">
                        Email Address
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <Input
                        name="main_contact_email"
                        onChange={(e) => {
                          setCheckIfEmailExist(e.target.value);
                          handleChange(e);
                        }}
                        className="w-100 border-gray rounded fs-bold-14"
                        value={values.main_contact_email}
                        autocomplete="off"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.main_contact_email &&
                      touched.main_contact_email ? (
                        <div className="text-danger">
                          {errors.main_contact_email}
                        </div>
                      ) : null}
                      {showEmailErrorMessage?.flag ? (
                        <>
                          <span className="fs-pink">
                            {showEmailErrorMessage?.errorMess}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>

                  {showNewEmail && (
                    <div className="row my-4">
                      <div className="col-2 text-end">New Email Address</div>
                      <div className="col-3">
                        <Input
                          name="new_email"
                          className="w-100 border-gray rounded fs-bold-14"
                          placeholder="Enter New Email"
                          maxLength={INPUT_TEXT_LIMITS.MAX}
                        />
                      </div>
                    </div>
                  )}
                </section>

                {/* section Account Permissions */}
                <section>
                  <div className="row">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20 px-2">
                        Account Permissions
                      </h6>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Events
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_events_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_events", 0);
                          }
                          setFieldValue(
                            "max_no_of_events_switch",
                            !values.max_no_of_events_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_events <= 0 ||
                            values.max_no_of_events_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_events",
                              values.max_no_of_events - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={values.max_no_of_events_switch !== true}
                          type="text"
                          style={{
                            width: "50%",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                            textAlign: "center",
                          }}
                          value={values.max_no_of_events}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_events",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={values.max_no_of_events_switch !== true}
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_events",
                              values.max_no_of_events + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Concurrent Events
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_concurrent_events_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_concurrent_events", 0);
                          }
                          setFieldValue(
                            "max_no_of_concurrent_events_switch",
                            !values.max_no_of_concurrent_events_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_concurrent_events <= 0 ||
                            values.max_no_of_concurrent_events_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_concurrent_events",
                              values.max_no_of_concurrent_events - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_no_of_concurrent_events_switch !== true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_no_of_concurrent_events}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_concurrent_events",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_no_of_concurrent_events_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_concurrent_events",
                              values.max_no_of_concurrent_events + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Events Per Month
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_events_per_month_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_events_per_month", 0);
                          }
                          setFieldValue(
                            "max_no_events_per_month_switch",
                            !values.max_no_events_per_month_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_events_per_month <= 0 ||
                            values.max_no_events_per_month_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_events_per_month",
                              values.max_no_events_per_month - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_no_events_per_month_switch !== true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_no_events_per_month}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_events_per_month",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_no_events_per_month_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_events_per_month",
                              values.max_no_events_per_month + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Brands
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_exhibitors_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_exhibitors", 0);
                          }
                          setFieldValue(
                            "max_no_of_exhibitors_switch",
                            !values.max_no_of_exhibitors_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_exhibitors <= 0 ||
                            values.max_no_of_exhibitors_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_exhibitors",
                              values.max_no_of_exhibitors - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={values.max_no_of_exhibitors_switch !== true}
                          type="text"
                          style={{
                            width: "50%",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                            textAlign: "center",
                          }}
                          value={values.max_no_of_exhibitors}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_exhibitors",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={values.max_no_of_exhibitors_switch !== true}
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_exhibitors",
                              values.max_no_of_exhibitors + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Brands Per Event
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_exhibitors_per_event_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_exhibitors_per_event", 0);
                          }
                          setFieldValue(
                            "max_no_of_exhibitors_per_event_switch",
                            !values.max_no_of_exhibitors_per_event_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_exhibitors_per_event <= 0 ||
                            values.max_no_of_exhibitors_per_event_switch !==
                              true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_exhibitors_per_event",
                              values.max_no_of_exhibitors_per_event - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_no_of_exhibitors_per_event_switch !==
                            true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_no_of_exhibitors_per_event}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_exhibitors_per_event",
                                parseInt(value)
                              );
                            }
                          }}
                        />
                        {/* </button> */}
                        <button
                          disabled={
                            values.max_no_of_exhibitors_per_event_switch !==
                            true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_exhibitors_per_event",
                              values.max_no_of_exhibitors_per_event + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Users
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_users_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_users", 0);
                          }
                          setFieldValue(
                            "max_no_of_users_switch",
                            !values.max_no_of_users_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_users <= 0 ||
                            values.max_no_of_users_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_users",
                              values.max_no_of_users - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={values.max_no_of_users_switch !== true}
                          type="text"
                          style={{
                            width: "50%",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                            textAlign: "center",
                          }}
                          value={values.max_no_of_users}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue("max_no_of_users", parseInt(value));
                            }
                          }}
                        />

                        <button
                          disabled={values.max_no_of_users_switch !== true}
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_users",
                              values.max_no_of_users + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Concurrent Users
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_concurrent_users_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_concurrent_users", 0);
                          }
                          setFieldValue(
                            "max_no_of_concurrent_users_switch",
                            !values.max_no_of_concurrent_users_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_concurrent_users <= 0 ||
                            values.max_no_of_concurrent_users_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_concurrent_users",
                              values.max_no_of_concurrent_users - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_no_of_concurrent_users_switch !== true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_no_of_concurrent_users}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_concurrent_users",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_no_of_concurrent_users_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_concurrent_users",
                              values.max_no_of_concurrent_users + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Streams : Max Gb Bandwidth Per Month
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_bandwidth_per_month_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_bandwidth_per_month", 0);
                          }
                          setFieldValue(
                            "max_bandwidth_per_month_switch",
                            !values.max_bandwidth_per_month_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_bandwidth_per_month <= 0 ||
                            values.max_bandwidth_per_month_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_bandwidth_per_month",
                              values.max_bandwidth_per_month - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_bandwidth_per_month_switch !== true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_bandwidth_per_month}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_bandwidth_per_month",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_bandwidth_per_month_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_bandwidth_per_month",
                              values.max_bandwidth_per_month + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Storage : Max Gb
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_storage_size_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_storage_size", 0);
                          }
                          setFieldValue(
                            "max_storage_size_switch",
                            !values.max_storage_size_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_storage_size <= 0 ||
                            values.max_storage_size_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_storage_size",
                              values.max_storage_size - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={values.max_storage_size_switch !== true}
                          type="text"
                          style={{
                            width: "50%",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                            textAlign: "center",
                          }}
                          value={values.max_storage_size}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_storage_size",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={values.max_storage_size_switch !== true}
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_storage_size",
                              values.max_storage_size + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 pb-4 border-bottom-gray">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Video Rooms : Max Hours Per Month
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_video_room_hours_per_month_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_video_room_hours_per_month", 0);
                          }
                          setFieldValue(
                            "max_video_room_hours_per_month_switch",
                            !values.max_video_room_hours_per_month_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_video_room_hours_per_month <= 0 ||
                            values.max_video_room_hours_per_month_switch !==
                              true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_video_room_hours_per_month",
                              values.max_video_room_hours_per_month - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_video_room_hours_per_month_switch !==
                            true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_video_room_hours_per_month}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_video_room_hours_per_month",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_video_room_hours_per_month_switch !==
                            true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_video_room_hours_per_month",
                              values.max_video_room_hours_per_month + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
                    <div>
                      <Link to="/customermanagement">
                        <Button className="rounded button-pink px-3">
                          Cancel
                        </Button>
                      </Link>
                    </div>
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="rounded px-3"
                        onClick={() => {
                          if (!isValid) {
                            toast.error("Fields are Missing");
                          }
                          window.scrollTo(0, 0);
                        }}
                      >
                        {!loading ? (
                          "Create Account"
                        ) : (
                          <div style={{ width: "80px" }}>
                            <Spin
                              indicator={loadingIcon}
                              style={{ color: "white" }}
                            />
                          </div>
                        )}
                      </Button>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

export default CreateNewAccount;
