import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiCall } from "../../utils/api";

export const getEventAudiDetails = createAsyncThunk(
  "auditorium/getEventAudiDetails",
  async (params) => {
    const response = await ApiCall("GET", "", "/admin/auditoriums/get", params);

    if (response?.data?.code === 200) {
      if (response?.data?.data) return response?.data?.data;
      else return [];
    } else {
      toast.error(response?.data?.message);
      return [];
    }
  }
);

export const getAudiSessionDetails = createAsyncThunk(
  "auditorium/getAudiSessionDetails",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/auditoriumssessions/get",
      params
    );
    if (response?.data?.code === 200) {
      if (response?.data?.data) return response.data.data;
      else return [];
    } else {
      toast.error(response?.data?.message);
      return [];
    }
  }
);

export const audiSlice = createSlice({
  name: "auditorium",
  initialState: {
    loading: false,
    eventAudiData: [],
    audiSessionData: [],
  },
  reducers: {},
  extraReducers: {
    [getEventAudiDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getEventAudiDetails.fulfilled]: (state, { payload, meta }) => {
      state.eventAudiData = payload;
      state.loading = false;
    },
    [getEventAudiDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAudiSessionDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getAudiSessionDetails.fulfilled]: (state, { payload, meta }) => {
      state.audiSessionData = payload;
      state.loading = false;
    },
    [getAudiSessionDetails.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const audiReducer = audiSlice.reducer;
