import React from "react";

export const Filter = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.999"
        height="17.022"
        viewBox="0 0 15.999 17.022"
      >
        <g id="filter" transform="translate(-64)">
          <path
            id="XMLID_1314_"
            d="M78.586,0H65.413A1.414,1.414,0,0,0,64,1.412V3.388a.753.753,0,0,0,.176.484l5.191,6.188v6.213a.76.76,0,0,0,1.232.581l3.85-3.176a.753.753,0,0,0,.274-.581V9.967l.9-1.072a.753.753,0,1,0-1.155-.967l-.924,1.1h-3.07L65.506,3.114V1.506H78.493V3.114L76.351,5.636a.753.753,0,1,0,1.155.967l2.318-2.731A.753.753,0,0,0,80,3.388V1.412A1.414,1.414,0,0,0,78.586,0ZM73.217,12.743l-2.344,1.934V10.539h2.344Z"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
};
