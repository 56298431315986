import React from "react";

export const Menu1 = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="#000000"
			>
				<g id="_077-menu-1" data-name="077-menu-1" opacity="0.7">
                    <g id="Group_1122" data-name="Group 1122" transform="translate(10.781)">
                        <g id="Group_1121" data-name="Group 1121">
                            <path id="Path_633" data-name="Path 633" d="M282.094,0h-2.969A3.129,3.129,0,0,0,276,3.125V6.094a3.129,3.129,0,0,0,3.125,3.125h2.969a3.129,3.129,0,0,0,3.125-3.125V3.125A3.129,3.129,0,0,0,282.094,0Zm1.563,6.094a1.564,1.564,0,0,1-1.562,1.563h-2.969a1.564,1.564,0,0,1-1.562-1.562V3.125a1.564,1.564,0,0,1,1.563-1.563h2.969a1.564,1.564,0,0,1,1.563,1.563Z" transform="translate(-276)"/>
                        </g>
                    </g>
                    <g id="Group_1124" data-name="Group 1124">
                        <g id="Group_1123" data-name="Group 1123">
                            <path id="Path_634" data-name="Path 634" d="M6.094,0H3.125A3.129,3.129,0,0,0,0,3.125V6.094A3.129,3.129,0,0,0,3.125,9.219H6.094A3.129,3.129,0,0,0,9.219,6.094V3.125A3.129,3.129,0,0,0,6.094,0ZM7.656,6.094A1.564,1.564,0,0,1,6.094,7.656H3.125A1.564,1.564,0,0,1,1.563,6.094V3.125A1.564,1.564,0,0,1,3.125,1.563H6.094A1.564,1.564,0,0,1,7.656,3.125Z"/>
                        </g>
                    </g>
                    <g id="Group_1126" data-name="Group 1126" transform="translate(0 10.781)">
                        <g id="Group_1125" data-name="Group 1125">
                            <path id="Path_635" data-name="Path 635" d="M6.094,276H3.125A3.129,3.129,0,0,0,0,279.125v2.969a3.129,3.129,0,0,0,3.125,3.125H6.094a3.129,3.129,0,0,0,3.125-3.125v-2.969A3.129,3.129,0,0,0,6.094,276Zm1.563,6.094a1.564,1.564,0,0,1-1.562,1.563H3.125a1.564,1.564,0,0,1-1.563-1.562v-2.969a1.564,1.564,0,0,1,1.563-1.562H6.094a1.564,1.564,0,0,1,1.563,1.563Z" transform="translate(0 -276)"/>
                        </g>
                    </g>
                    <g id="Group_1128" data-name="Group 1128" transform="translate(10.781 10.781)">
                        <g id="Group_1127" data-name="Group 1127">
                            <path id="Path_636" data-name="Path 636" d="M284.438,281.313a.781.781,0,0,0-.781.781,1.564,1.564,0,0,1-1.562,1.563h-2.969a1.564,1.564,0,0,1-1.562-1.562v-2.969a1.564,1.564,0,0,1,1.563-1.562h2.969a1.564,1.564,0,0,1,1.433.937.781.781,0,0,0,1.432-.626A3.126,3.126,0,0,0,282.094,276h-2.969A3.129,3.129,0,0,0,276,279.125v2.969a3.129,3.129,0,0,0,3.125,3.125h2.969a3.129,3.129,0,0,0,3.125-3.125A.781.781,0,0,0,284.438,281.313Z" transform="translate(-276 -276)"/>
                        </g>
                    </g>
                </g>
			</svg>
		</>
	);
};
