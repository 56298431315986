import { configureStore } from "@reduxjs/toolkit";
import { eventReducer } from "./slice/eventManagement";
import { surveyReducer } from "./slice/surveyManagement";
import { loginReducer } from "./slice/login";
import { brandReducer } from "./slice/brandManagementSlice";
import { customerReducer } from "./slice/customerManagementSlice";
import { createEventReducer } from "./slice/createEvent";
import { userReducer } from "./slice/userManagementSlice";
import { customReducer } from "./slice/customerData";
import { updateEventReducer } from "./slice/updateEvent";
import { usersReducer } from "./slice/getUsers";
import { createAssetsReducer } from "./slice/createAssets";
// import logger from "redux-logger";
import { zoneReducer } from "./slice/zoneManagementSlice";
import editEventReducer from "./slice/editEvent.js";
import createEventNewReducer from "./slice/createEventNew.js";
import { registerReducer } from "./slice/register";
import { eventCalenderReducer } from "./slice/eventCalender";
import { peopleReducer } from "./slice/people";
import { profileReducer } from "./slice/myProfile";
import { audiReducer } from "./slice/auditorium";
import { networkingReducer } from "./slice/networking";
import { headerReducer } from "./slice/headerData";
import { vistaBrandReducer } from "./slice/vista";
import { notificationReducer } from "./slice/notification";
import { videoConferenceReducer } from "./slice/videoConference";

export const store = configureStore({
  reducer: {
    eventManagement: eventReducer,
    surveyManagement: surveyReducer,
    login: loginReducer,
    brandManagement: brandReducer,
    customerManagement: customerReducer,
    createEvent: createEventReducer,
    userManagement: userReducer,
    notifications: notificationReducer,
    customerData: customReducer,
    updateEvent: updateEventReducer,
    getUsers: usersReducer,
    creteAssets: createAssetsReducer,
    zoneManagement: zoneReducer,
    editEventData: editEventReducer,
    createEventData: createEventNewReducer,
    register: registerReducer,
    eventCalender: eventCalenderReducer,
    people: peopleReducer,
    profile: profileReducer,
    auditorium: audiReducer,
    networking: networkingReducer,
    header: headerReducer,
    vistaBrand: vistaBrandReducer,
    videoConference: videoConferenceReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  serializableCheck: false,
});
