import React, { useEffect, useRef, useState } from "react";

import { ApiCall } from "../../utils/api";

import { Breadcrumb, Button, Input, Modal, Table } from "antd";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import "./GoogleAuth.css";
import SimpleReactValidator from "simple-react-validator";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { generateHelmet } from "../../utils/commonFunctions";

const GoogleAuth = (props) => {
  let { socket } = props;
  const [rowData, setRowData] = useState([]);
  const [openSignInPopup, setOpenSignInPopup] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [openGoogleLinkInIframe, setOpenGoogleLinkInIframe] = useState(null);
  const [googleSignInLink, setGoogleSignInLink] = useState("");
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loadGoogleAuth, setLoadGoogleAuth] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState({
    flag: false,
    email: "",
  });
  const [isEnableLogin, setIsEnableLogin] = useState(false);

  const validator = useRef(new SimpleReactValidator());

  useEffect(async () => {
    getAllAccountDetails();
  }, []);

  useEffect(() => {
    const fethAccountDetails = (obj) => {
      console.log("fethAccountDetails", obj);
      if (obj.reloadGoogleAuthFlag) {
        getAllAccountDetails();
      }
    };

    socket?.on("reloadStreamingAccountScreen", fethAccountDetails);

    return () => {
      socket?.off("reloadStreamingAccountScreen");
    };
  }, [socket]);

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srno",
      key: "srno",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Login",
      dataIndex: "login",
      key: "login",
      render: (_, record) => (
        <>
          {record?.login ? (
            <Button
              className={"google-auth-login-btns"}
              // onClick={() => callGoogleAuthenticationApi(record?.email)}
              onClick={() =>
                setOpenConfirmationPopup({
                  flag: true,
                  email: record?.email,
                })
              }
              shape="round"
            >
              Click to Proceed
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  const getAllAccountDetails = async () => {
    setLoadGoogleAuth(true);
    const fetchResponse = await ApiCall(
      "GET",
      null,
      "/admin/google/acocuntdetails/fetch",
      null
    );

    if (fetchResponse?.data?.code === 200) {
      let dataArr = fetchResponse?.data?.data?.map((item, i) => {
        return {
          key: i,
          srno: i + 1,
          email: item?.email,
          status: item?.is_refresh_token_expired ? "Token Expired" : "Active",
          blurred: item?.is_refresh_token_expired ? false : true,
          login: item?.is_refresh_token_expired ? true : false,
        };
      });

      setRowData(dataArr);
    }
    setLoadGoogleAuth(false);
  };

  function MyPopUpWin(url, width, height) {
    var leftPosition, topPosition;
    //Allow for borders.
    leftPosition = window.screen.width / 2 - (width / 2 + 10);
    //Allow for title and status bars.
    topPosition = window.screen.height / 2 - (height / 2 + 50);
    //Open the window.
    window.open(
      url,
      "Window2",
      "status=no,height=" +
        height +
        ",width=" +
        width +
        ",resizable=yes,left=" +
        leftPosition +
        ",top=" +
        topPosition +
        ",screenX=" +
        leftPosition +
        ",screenY=" +
        topPosition +
        ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"
    );
  }

  const callGoogleAuthenticationApi = async (email = "") => {
    setOpenConfirmationPopup({
      flag: false,
      email: "",
    });

    setIsEnableLogin(false);
    let finalEmail = email !== "" ? email : inputEmail;

    if (validator.current.allValid()) {
      setSaveBtnLoader(true);
      const response = await ApiCall(
        "POST",
        {
          email: finalEmail,
        },
        "/admin/google/authentication",
        null,
        true
      );

      if (response?.data?.code === 200) {
        MyPopUpWin(response?.data?.data, 500, 500);

        // window.open(
        //   "https://www.facebook.com/",
        //   "_blank",
        //   "location=yes,height=570,width=520,scrollbars=yes,status=yes"
        // );
        setOpenSignInPopup(false);
        setInputEmail("");
        setSaveBtnLoader(false);
        getAllAccountDetails();
      } else {
        toast.error(response?.data?.message);
      }
    } else {
      validator.current.showMessages();

      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  if (loadGoogleAuth) return <CommonLoader />;
  else
    return (
      <div className="">
        {generateHelmet("Gravit8", "Streaming Accounts")}
        <div className="d-flex justify-content-between mt-2">
          <div className="">
            <h4 className="fs-24">Google Sign In</h4>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/googleauth">Admin Console</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/googleauth">Google Sign In</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Button
              className="googleAuth_add_account_btn text-center"
              id="google-btn"
              onClick={() => {
                setOpenSignInPopup(true);
                // glogin()
              }}
            >
              + Add Google Account
            </Button>
          </div>
        </div>

        {openSignInPopup ? (
          <Modal
            footer={null}
            open={openSignInPopup}
            title="Google Sign In"
            className=""
            onCancel={() => {
              setInputEmail("");
              setOpenSignInPopup(false);
            }}
          >
            <div className="d-flex justify-content-center align-items-center p-4">
              <span className="fs-18">Email</span>
              <Input
                className="ms-4"
                name="google_email"
                value={inputEmail}
                onChange={(e) => {
                  setInputEmail(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    callGoogleAuthenticationApi();
                  }
                }}
                onBlur={() => validator.current.showMessageFor("google_email")}
              />
            </div>
            <div className="text-center">
              {validator.current.message(
                "Email",
                inputEmail,
                "required|email",
                {
                  className: "text-danger py-1 ms-4",
                }
              )}
            </div>

            <div className="w-100 text-end mt-2 px-4">
              <Button
                onClick={() => {
                  callGoogleAuthenticationApi();
                }}
                loading={saveBtnLoader}
              >
                Save
              </Button>
            </div>
          </Modal>
        ) : null}

        {openConfirmationPopup?.flag ? (
          <Modal
            footer={null}
            open={openConfirmationPopup?.flag}
            title="Google Sign In"
            className=""
            onCancel={() => {
              setInputEmail("");
              setOpenConfirmationPopup({
                flag: false,
                email: "",
              });
            }}
          >
            <div className="d-flex justify-content-center align-items-center p-4">
              <span>
                <b>
                  Please make sure you are Logged In from the expired account in
                  the chrome and remove the youtube access by clicking on the
                  <a
                    target="_blank"
                    onClick={() => {
                      setIsEnableLogin(true);
                    }}
                    href="https://myaccount.google.com/permissions"
                  >
                    {" "}
                    Link{" "}
                  </a>
                  before you procced to Login
                </b>
              </span>
            </div>

            <div className="w-100 text-end mt-2 px-4">
              <Button
                onClick={() => {
                  callGoogleAuthenticationApi(openConfirmationPopup?.email);
                }}
                disabled={!isEnableLogin ? true : false}
              >
                Login
              </Button>
            </div>
          </Modal>
        ) : null}

        {openGoogleLinkInIframe ? (
          <Modal
            footer={null}
            open={openGoogleLinkInIframe}
            title="Google Sign In"
          >
            <div className="d-flex justify-content-center align-items-center">
              <iframe src={googleSignInLink} />
            </div>
          </Modal>
        ) : null}

        <Table
          className="mt-4"
          bordered={true}
          style={{
            border: "1px solid #ccc",
          }}
          dataSource={rowData}
          columns={columns}
          showSizeChange={true}
          pagination={false}
          rowClassName={(record) => !record?.blurred && "disabled2-row"}
        />
      </div>
    );
};

export default GoogleAuth;
