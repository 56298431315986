import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  Checkbox,
} from "antd";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { updateSurvey, viewSurvey } from "../../redux/slice/surveyManagement";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ApiCall } from "../../utils/api";
import * as Yup from "yup";
import SimpleReactValidator from "simple-react-validator";
import CommonLoader from "../../components/Widgets/CommonLoader";

import RedAsterick from "../../components/Widgets/RedAsterick";
import { isRouteExistInMenu } from "../../utils/commonFunctions";

const ViewSurvey = (props) => {
  var CryptoJS = require("crypto-js");
  const { Option } = Select;
  const { TextArea } = Input;
  const { match } = props;
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");
  const [surveyDetailData, setsurveyDetailData] = useState(null);
  const [eventList, seteventList] = useState(null);
  const validator = useRef(new SimpleReactValidator());
  const [loadingSurvey, setLoadingSurvey] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [surveyId, setSurveyId] = useState(null);

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      getSurveyDetail();
      getEventsList();
    }
  }, [CryptoJS.AES]);

  const getEventsList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then((response) => {
        seteventList(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSurveyDetail = async () => {
    setLoadingSurvey(true);
    const response = await dispatch(
      viewSurvey({ survey_id: match.params.surveyid })
    );
    if (response.payload.code === 404) {
      toast.error(response.payload.message);
      setAccessDenied(true);
    }
    if (response.payload.code === 200) {
      let surveyData = response.payload.data;
      setSurveyId(surveyData.survey_id);
      setsurveyDetailData({
        survey_id: surveyData.survey_id,
        survey_title: surveyData.survey_title,
        survey_description: surveyData.survey_description,
        survey_type: surveyData.survey_type,
        event_id: surveyData.event_id,
      });

      let initialQuesAnsData = surveyData.question_details.map((item) => {
        return {
          survey_qa_id: item.survey_qa_id,
          question_type: item.question_type,
          question_text: item.question,
          is_required: item.is_required,
          sequence: item.sequence,
          min_obligatory: item.min_obligatory,
          max_obligatory: item.max_obligatory,
          display_chart: item.display_chart,
          chart_type: item.chart_type,
          multi_select_question_type: item.multi_select_question_type,
          one_question_per_page: item.one_question_per_page,
          format: item.format,
          answer_details: item.answer_details.map((elem) => {
            return {
              choice_id: elem.choice_id,
              is_default_selected: elem.is_default_selected,
              option: elem.option,
              format_line_character_limit: elem.format_line_character_limit,
              is_correct: elem.is_correct,
              offText: elem.offLable,
              onText: elem.onLable,
            };
          }),
        };
      });
      setquestionArray(initialQuesAnsData);
    }
    setLoadingSurvey(false);
  };
  useEffect(() => {
    setformikInitialState([
      {
        survey_title: surveyDetailData && surveyDetailData.survey_title,
        survey_description:
          surveyDetailData && surveyDetailData.survey_description,
        survey_type: surveyDetailData && surveyDetailData.survey_type,
      },
    ]);
  }, [surveyDetailData]);

  const [questionArray, setquestionArray] = useState([
    {
      question_type: "",
      answer_details: [{ option: "", is_default_selected: false }],
      chart_type: "BAR",
      display_chart: false,
    },
  ]);
  const [formikInitialState, setformikInitialState] = useState([
    {
      survey_title: "",
      survey_description: "",
      survey_type: "EVENT",
    },
  ]);

  const addAnotherAnswer = (key) => {
    let question_array = questionArray;
    question_array[key].answer_details.push({
      option: "",
      is_default_selected: false,
      is_correct: false,
    });
    setquestionArray([...question_array]);
  };

  const addAnotherQuestion = () => {
    let question_array = questionArray;

    question_array.push({
      sequence: question_array[question_array.length - 1].sequence + 1,
      question_type: "",
      answer_details: [{ option: "", is_default_selected: false }],
      chart_type: "BAR",
      display_chart: false,
    });
    setquestionArray([...question_array]);
  };

  const handleQuestionArrChange = (val, key, name) => {
    let question_array = questionArray;
    question_array[key][[name]] = val;
    setquestionArray([...question_array]);
  };

  const handleQuestionArrAnswerChange = (val, key, index, name) => {
    let question_array = questionArray;
    question_array[key].answer_details[index][[name]] = val;
    setquestionArray([...question_array]);
  };

  const handleAddSurvey = async (data) => {
    setLoadingSurvey(true);
    const finalArr = questionArray.map((val, index) => {
      if (val.question_type === "TOGGLE") {
        return {
          ...val,
          answer_details: val.answer_details.map((elem) => {
            return {
              ...elem,
              is_toggle: true,
            };
          }),
        };
      } else {
        return val;
      }
    });

    let formData = {
      event_id: data.event_id,
      survey_id: surveyDetailData && surveyDetailData.survey_id,
      survey_type: data.survey_type,
      survey_title: data.survey_title,
      survey_description: data.survey_description,
      question_details: finalArr,
    };
    const addSurveyResp = await dispatch(updateSurvey(formData));
    if (addSurveyResp.payload.code === 200) {
      toast.success(addSurveyResp.payload.message);
      props.history.push("/surveyManagement");
    }
    if (addSurveyResp.payload.code !== 200) {
      toast.error(addSurveyResp.payload.message);
    }
    setLoadingSurvey(false);
  };
  const ValidationSchema = Yup.object().shape({
    survey_type: Yup.string().required("Required").nullable(),
    survey_title: Yup.string().required("Required").nullable(),
    survey_description: Yup.string().required("Required").nullable(),
  });

  const handleDeleteSurvey = async (surveyData) => {
    const body = {
      survey_id: surveyId,
      is_delete: false,
      question_details: [
        {
          is_delete: true,
          survey_qa_id: surveyData.survey_qa_id,
          answer_details: surveyData.answer_details.map((item) => {
            return {
              is_delete: true,
              choice_id: item.choice_id,
            };
          }),
        },
      ],
    };

    const response = await ApiCall(
      "DELETE",
      body,
      "/admin/survey/delete",
      "",
      ""
    );
    if (response.data.code === 200) {
      getSurveyDetail();
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  };

  const handleDeleteAnswer = async (surveyData, choice_id) => {
    const body = {
      survey_id: surveyId,
      is_delete: false,
      question_details: [
        {
          is_delete: false,
          survey_qa_id: surveyData.survey_qa_id,
          answer_details: surveyData.answer_details.map((item) => {
            if (item.choice_id === choice_id) {
              return {
                is_delete: true,
                choice_id: item.choice_id,
              };
            } else {
              return {
                is_delete: false,
                choice_id: item.choice_id,
              };
            }
          }),
        },
      ],
    };

    const response = await ApiCall(
      "DELETE",
      body,
      "/admin/survey/delete",
      "",
      ""
    );
    if (response.data.code === 200) {
      getSurveyDetail();
      toast.success("Answer Deleted Successfully");
    } else {
      toast.error(response.data.message);
    }
  };

  if (loadingSurvey) {
    return <CommonLoader />;
  } else
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          event_id: surveyDetailData && surveyDetailData.event_id,
          survey_title: surveyDetailData && surveyDetailData.survey_title,
          survey_description:
            surveyDetailData && surveyDetailData.survey_description,
          survey_type: surveyDetailData && surveyDetailData.survey_type,
          question_details: questionArray,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleAddSurvey(values);
        }}
      >
        {({
          values,
          errors,
          touched,

          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            {accessDenied === false && (
              <div className="view-all-common">
                <div className="d-flex justify-content-between bg-light w-100 common-sticky-header">
                  <div>
                    <h4 className="fs-24">View Survey</h4>
                    <Breadcrumb className="my-2" separator=">">
                      <Breadcrumb.Item>
                        <Link to="/surveyManagement">Admin Console</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/surveyManagement">Survey Management</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>View Survey</Breadcrumb.Item>
                    </Breadcrumb>
                  </div>
                </div>
                <div className="bg-white px-5 py-3 mt-5">
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">
                        Survey Details
                      </h6>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col-2 text-end">
                      Select Event
                      <RedAsterick />
                    </div>
                    <div className="col-9">
                      {/* <Select
                      showSearch
                      value={values.event_id}
                      style={{ width: 300 }}
                      className="fs-bold-14"
                      optionFilterProp="children"
                      name="event_id"
                      placeholder="Select Event"
                      // onChange={(val) => setFieldValue("event_id", val)}
                      disabled={
                        userType && userType === "BRAND ADMIN" ? true : false
                      }
                    >
                      {eventList &&
                        eventList.map((data) => (
                          <Option value={data.event_id}>
                            {data.event_name}
                          </Option>
                        ))}
                    </Select>
                    {errors.event_id && touched.event_id ? (
                      <div className="text-danger">{errors.event_id}</div>
                    ) : null} */}
                      <Input
                        disabled
                        name="event_id"
                        value={values.event_id}
                        style={{ width: 300 }}
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-2 text-end">
                      Select Type
                      <RedAsterick />
                    </div>
                    <div className="col-9">
                      <Input
                        disabled
                        name="event_id"
                        value={values.survey_type}
                        style={{ width: 300 }}
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-2 text-end">
                      Title
                      <RedAsterick />
                    </div>
                    <div className="col-9">
                      <Input
                        disabled
                        name="survey_title"
                        value={values.survey_title}
                        className="w-50 border-gray rounded fs-bold-14"
                        placeholder="End of Show"
                        // onChange={handleChange}
                      />
                      {errors.survey_title && touched.survey_title ? (
                        <div className="text-danger">{errors.survey_title}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-2 text-end">
                      Description
                      <RedAsterick />
                    </div>
                    <div className="col-9">
                      <TextArea
                        disabled
                        rows={6}
                        className="w-50 fs-bold-14"
                        placeholder="Enter a Description here"
                        value={values.survey_description}
                        name="survey_description"
                        // onChange={handleChange}
                      />
                      {errors.survey_description &&
                      touched.survey_description ? (
                        <div className="text-danger">
                          {errors.survey_description}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <Divider />

                  {/* question answer section  */}

                  <div className="row my-3">
                    <div className="col-10 mt-4">
                      <h6 className="fs-pink fw-bolder fs-20">Add Questions</h6>
                    </div>
                  </div>

                  <div className="question-div d-flex flex-column align-items-center">
                    {questionArray &&
                      questionArray.map((val, key) => (
                        <>
                          <div
                            style={{ marginLeft: "48px" }}
                            key={"surveyquesarr_" + key}
                            className="question_container p-2 my-2"
                          >
                            <h5 className="survey_question_number ">
                              {key + 1}
                            </h5>
                            {/* <span
                            // onClick={() => handleDeleteSurvey(val)}
                            className="survey_delete_icon cursor-pointer"
                          >
                            <Bin />
                          </span> */}

                            <div className="survey_design"></div>
                            <div className="row my-3">
                              <div className="col-3 text-end">
                                Question Type
                              </div>
                              <div className="col-4">
                                <Input
                                  disabled
                                  name="event_id"
                                  value={questionArray[key].question_type}
                                  style={{ width: 300 }}
                                />
                              </div>
                            </div>
                            {val.question_type === "TOGGLE" ? (
                              <>
                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Question Text
                                  </div>
                                  <div className="col-4">
                                    <Input
                                      disabled
                                      name="question_text"
                                      value={val?.question_text}
                                      className="border-gray rounded fs-bold-14"
                                      placeholder="Have you enjoyed the show"
                                      // onChange={(e) =>
                                      //   handleQuestionArrChange(
                                      //     e.target.value,
                                      //     key,
                                      //     "question_text"
                                      //   )
                                      // }
                                      // onBlur={() =>
                                      //   validator.current.showMessageFor(
                                      //     "question_text"
                                      //   )
                                      // }
                                    />
                                    {validator.current.message(
                                      "question_text",
                                      val?.question_text,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Obligatory on to Procced? (*)
                                  </div>
                                  <div className="col-4">
                                    <Switch
                                      disabled
                                      checked={val.is_required}
                                      name="obligatory"
                                      // onChange={(checked) =>
                                      //   handleQuestionArrChange(
                                      //     checked,
                                      //     key,
                                      //     "is_required"
                                      //   )
                                      // }
                                    />
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">Default</div>
                                  <div className="col-4">
                                    <span>Off label</span>
                                    <Switch
                                      disabled
                                      name="default"
                                      className="mx-1"
                                      checked={
                                        val?.answer_details?.[0]?.is_correct
                                      }
                                      // onChange={(checked) => {
                                      //   handleQuestionArrAnswerChange(
                                      //     checked,
                                      //     key,
                                      //     0,
                                      //     "is_correct"
                                      //   );
                                      //   handleQuestionArrAnswerChange(
                                      //     checked,
                                      //     key,
                                      //     1,
                                      //     "is_correct"
                                      //   );
                                      // }}
                                    />
                                    <span>On label</span>
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    'Off' label text
                                  </div>
                                  <div className="col-4">
                                    <Input
                                      disabled
                                      name="off_label_text"
                                      className="border-gray rounded fs-bold-14"
                                      value={val?.answer_details?.[1]?.offText}
                                      placeholder=""
                                      // onChange={(e) =>
                                      //   handleQuestionArrAnswerChange(
                                      //     e.target.value,
                                      //     key,
                                      //     1,
                                      //     "offText"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "off_label_text"
                                        )
                                      }
                                    />
                                    {validator.current.message(
                                      "",
                                      val?.offText,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    'On' label text
                                  </div>
                                  <div className="col-4">
                                    <Input
                                      disabled
                                      name="on_label_text"
                                      className="border-gray rounded fs-bold-14"
                                      value={val?.answer_details?.[0]?.onText}
                                      placeholder=""
                                      // onChange={(e) => {
                                      //   handleQuestionArrAnswerChange(
                                      //     e.target.value,
                                      //     key,
                                      //     0,
                                      //     "onText"
                                      //   );
                                      // }}
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "on_label_text"
                                        )
                                      }
                                    />
                                    {validator.current.message(
                                      "",
                                      val?.onText,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : val.question_type === "SINGLE SELECT" ? (
                              <>
                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Question Text
                                  </div>
                                  <div className="col-4">
                                    <Input
                                      name="question_text"
                                      disabled
                                      className="border-gray rounded fs-bold-14"
                                      value={val?.question_text}
                                      placeholder="Have you enjoyed the show"
                                      disabled
                                      // onChange={(e) =>
                                      //   handleQuestionArrChange(
                                      //     e.target.value,
                                      //     key,
                                      //     "question_text"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "question_text"
                                        )
                                      }
                                    />
                                    {validator.current.message(
                                      "question_text",
                                      val?.question_text,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Obligatory on to Procced? (*)
                                  </div>
                                  <div className="col-4">
                                    <Switch
                                      disabled
                                      name="obligatory"
                                      checked={val?.is_required}
                                      // onChange={(checked) =>
                                      //   handleQuestionArrChange(
                                      //     checked,
                                      //     key,
                                      //     "is_required"
                                      //   )
                                      // }
                                    />
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">Default</div>
                                  <div className="col-4">
                                    <Checkbox
                                      checked={
                                        val?.answer_details?.[0]
                                          ?.is_default_selected
                                      }
                                      disabled
                                      // onChange={(e) => {
                                      //   handleQuestionArrAnswerChange(
                                      //     e.target.checked,
                                      //     key,
                                      //     0,
                                      //     "is_default_selected"
                                      //   );
                                      // }}
                                    >
                                      Checkbox
                                    </Checkbox>
                                  </div>
                                </div>
                              </>
                            ) : val.question_type === "MULTIPLE SELECT" ? (
                              <>
                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Question Text
                                  </div>
                                  <div className="col-4">
                                    <Input
                                      disabled
                                      name="question_text"
                                      className="border-gray rounded fs-bold-14"
                                      value={val?.question_text}
                                      placeholder="Have you enjoyed the show"
                                      // onChange={(e) =>
                                      //   handleQuestionArrChange(
                                      //     e.target.value,
                                      //     key,
                                      //     "question_text"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "question_text"
                                        )
                                      }
                                    />
                                    {validator.current.message(
                                      "question_text",
                                      val?.question_text,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Minimum Obligatory Answers
                                  </div>
                                  <div className="col-4">
                                    <Select
                                      disabled
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="Select"
                                      optionFilterProp="children"
                                      value={val.min_obligatory}
                                      name="min_obligatory"
                                      className="fs-bold-14"
                                      onChange={(val) => {
                                        let intVal = parseInt(val);
                                        handleQuestionArrChange(
                                          intVal,
                                          key,
                                          "min_obligatory"
                                        );
                                      }}
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "min_obligatory"
                                        )
                                      }
                                    >
                                      <Option value="1">1</Option>
                                      <Option value="2">2</Option>
                                      <Option value="3">3</Option>
                                      <Option value="4">4</Option>
                                      <Option value="5">5</Option>
                                    </Select>
                                    {validator.current.message(
                                      "min_obligatory",
                                      val?.min_obligatory,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Maximum Answers?
                                  </div>
                                  <div className="col-4">
                                    <Select
                                      disabled
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="Select"
                                      optionFilterProp="children"
                                      value={val.max_obligatory}
                                      name="max_obligatory"
                                      className="fs-bold-14"
                                      // onChange={(val) => {
                                      //   let intVal = parseInt(val);
                                      //   handleQuestionArrChange(
                                      //     intVal,
                                      //     key,
                                      //     "max_obligatory"
                                      //   );
                                      // }}
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "max_obligatory"
                                        )
                                      }
                                    >
                                      <Option value="1">1</Option>
                                      <Option value="2">2</Option>
                                      <Option value="3">3</Option>
                                      <Option value="4">4</Option>
                                      <Option value="5">5</Option>
                                    </Select>
                                    {validator.current.message(
                                      "max_obligatory",
                                      val?.max_obligatory,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>

                                {val?.answer_details &&
                                  val?.answer_details.map((elem, index) => (
                                    <>
                                      <div
                                        className="row my-3"
                                        key={"surveyanswer_" + index}
                                      >
                                        <div className="col-3 text-end">
                                          Answer {index + 1}
                                        </div>
                                        <div className="col-4">
                                          <Input
                                            name="option"
                                            disabled
                                            className="border-gray rounded"
                                            value={elem.option}
                                            // onChange={(e) =>
                                            //   handleQuestionArrAnswerChange(
                                            //     e.target.value,
                                            //     key,
                                            //     index,
                                            //     "option"
                                            //   )
                                            // }
                                            // onBlur={() =>
                                            //   validator.current.showMessageFor(
                                            //     "option"
                                            //   )
                                            // }
                                          />
                                          {validator.current.message(
                                            "answer",
                                            elem?.option,
                                            "required",
                                            { className: "text-danger py-1" }
                                          )}
                                        </div>
                                        <div className="col-3">
                                          <Checkbox
                                            checked={elem.is_default_selected}
                                            disabled
                                            // onChange={(e) => {
                                            //   handleQuestionArrAnswerChange(
                                            //     e.target.checked,
                                            //     key,
                                            //     index,
                                            //     "is_default_selected"
                                            //   );
                                            // }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ))}

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Display Chart ?
                                  </div>
                                  <div className="col-4">
                                    <Switch
                                      checked={val.display_chart}
                                      name="display_chart"
                                      disabled
                                      // onChange={(checked) =>
                                      //   handleQuestionArrChange(
                                      //     checked,
                                      //     key,
                                      //     "display_chart"
                                      //   )
                                      // }
                                    />
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Chart Type ?
                                  </div>
                                  <div className="col-4">
                                    <span className="mx-1">Bar Chart</span>
                                    <Switch
                                      checked={val.chart_type === "PIE"}
                                      name="chart_type"
                                      disabled
                                      // onChange={(checked) =>
                                      //   handleQuestionArrChange(
                                      //     checked === true ? "PIE" : "BAR",
                                      //     key,
                                      //     "chart_type"
                                      //   )
                                      // }
                                    />
                                    <span className="mx-1">Pie Chart</span>
                                  </div>
                                </div>
                              </>
                            ) : val.question_type === "MULTIPLE CHOICE" ? (
                              <>
                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Question Text
                                  </div>
                                  <div className="col-4">
                                    <Input
                                      disabled
                                      name="question_text"
                                      value={val.question_text}
                                      className="border-gray rounded fs-bold-14"
                                      placeholder="Have you enjoyed the show"
                                      // onChange={(e) =>
                                      //   handleQuestionArrChange(
                                      //     e.target.value,
                                      //     key,
                                      //     "question_text"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "question_text"
                                        )
                                      }
                                    />
                                    {validator.current.message(
                                      "question_text",
                                      val?.question_text,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Quiz or Survey?
                                  </div>
                                  <div className="col-4">
                                    <Select
                                      disabled
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="Select"
                                      optionFilterProp="children"
                                      value={val.multi_select_question_type}
                                      name="quiz_or_survey"
                                      className="fs-bold-14"
                                      // onChange={(val) =>
                                      //   handleQuestionArrChange(
                                      //     val,
                                      //     key,
                                      //     "multi_select_question_type"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "quiz_or_survey"
                                        )
                                      }
                                    >
                                      <Option value="QUIZ">Quiz</Option>
                                      <Option value="SURVEY">Survey</Option>
                                    </Select>
                                    {validator.current.message(
                                      "quiz_or_survey",
                                      val?.multi_select_question_type,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>

                                {val.answer_details &&
                                  val.answer_details.map((elem, index) => (
                                    <>
                                      <div
                                        className="row my-3"
                                        key={"surveyanswer_" + index}
                                      >
                                        <div className="col-3 text-end">
                                          Answer {index + 1}
                                        </div>
                                        <div className="col-4">
                                          <Input
                                            disabled
                                            name="option"
                                            value={elem.option}
                                            className="border-gray rounded"
                                            placeholder="Yes"
                                            // onChange={(e) =>
                                            //   handleQuestionArrAnswerChange(
                                            //     e.target.value,
                                            //     key,
                                            //     index,
                                            //     "option"
                                            //   )
                                            // }
                                            onBlur={() =>
                                              validator.current.showMessageFor(
                                                "option"
                                              )
                                            }
                                          />
                                          {validator.current.message(
                                            "answer",
                                            elem?.option,
                                            "required",
                                            { className: "text-danger py-1" }
                                          )}
                                        </div>
                                        <div className="col-2">
                                          {val?.multi_select_question_type ===
                                          "QUIZ" ? (
                                            <>
                                              <Checkbox
                                                disabled
                                                checked={
                                                  elem.is_default_selected
                                                }
                                                // onChange={(e) =>
                                                //   handleQuestionArrAnswerChange(
                                                //     e.target.checked,
                                                //     key,
                                                //     index,
                                                //     "is_default_selected"
                                                //   )
                                                // }
                                              />
                                              <span className="fs-pink ms-2">
                                                Correct
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ))}

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Display Chart ?
                                  </div>
                                  <div className="col-4">
                                    <Switch
                                      disabled
                                      checked={val.display_chart}
                                      name="display_chart"
                                      // onChange={(checked) =>
                                      //   handleQuestionArrChange(
                                      //     checked,
                                      //     key,
                                      //     "display_chart"
                                      //   )
                                      // }
                                    />
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Chart Type ?
                                  </div>
                                  <div className="col-4">
                                    <span className="mx-1">Bar Chart</span>
                                    <Switch
                                      disabled
                                      checked={val.chart_type === "PIE"}
                                      name="chart_type"
                                      // onChange={(checked) =>
                                      //   handleQuestionArrChange(
                                      //     checked === true ? "PIE" : "BAR",
                                      //     key,
                                      //     "chart_type"
                                      //   )
                                      // }
                                    />
                                    <span className="mx-1">Pie Chart</span>
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    One Question per Page?
                                  </div>
                                  <div className="col-4">
                                    <Select
                                      disabled
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="Select"
                                      optionFilterProp="children"
                                      name="ques_per_page"
                                      className="fs-bold-14"
                                      value={val.one_question_per_page}
                                      // onChange={(val) =>
                                      //   handleQuestionArrChange(
                                      //     val,
                                      //     key,
                                      //     "one_question_per_page"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "ques_per_page"
                                        )
                                      }
                                    >
                                      <Option value={true}>Yes</Option>
                                      <Option value={false}>No</Option>
                                    </Select>
                                    {validator.current.message(
                                      "ques_per_page",
                                      val?.one_question_per_page,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : val.question_type === "FREEFORM TEXT" ? (
                              <>
                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Question Text
                                  </div>
                                  <div className="col-4">
                                    <Input
                                      disabled
                                      name="question_text"
                                      className="border-gray rounded fs-bold-14"
                                      value={val.question_text}
                                      placeholder="Have you enjoyed the show"
                                      // onChange={(e) =>
                                      //   handleQuestionArrChange(
                                      //     e.target.value,
                                      //     key,
                                      //     "question_text"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "question_text"
                                        )
                                      }
                                    />
                                    {validator.current.message(
                                      "question_text",
                                      val?.question_text,
                                      "required",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Required / Obligatory (*)
                                  </div>
                                  <div className="col-4">
                                    <Switch
                                      disabled
                                      checked={val.is_required}
                                      name="obligatory"
                                      // onChange={(checked) =>
                                      //   handleQuestionArrChange(
                                      //     checked,
                                      //     key,
                                      //     "is_required"
                                      //   )
                                      // }
                                    />
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">Format</div>
                                  <div className="col-4">
                                    <Select
                                      disabled
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="Select"
                                      value={val?.format}
                                      optionFilterProp="children"
                                      name="format"
                                      className="fs-bold-14"
                                      // onChange={(val) =>
                                      //   handleQuestionArrChange(
                                      //     val,
                                      //     key,
                                      //     "format"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "format"
                                        )
                                      }
                                    >
                                      <Option value="MULTI LINE">
                                        Multiple line
                                      </Option>
                                      <Option value="SINGLE LINE">
                                        Single line
                                      </Option>
                                    </Select>
                                  </div>
                                </div>

                                <div className="row my-3">
                                  <div className="col-3 text-end">
                                    Character Limit
                                  </div>
                                  <div className="col-4">
                                    <Input
                                      disabled
                                      placeholder="Enter Value"
                                      value={
                                        val.answer_details[0]
                                          .format_line_character_limit
                                      }
                                      style={{ width: "100%" }}
                                      name="character_limit"
                                      className="fs-bold-14"
                                      // onChange={(e) =>
                                      //   handleQuestionArrAnswerChange(
                                      //     e.target.value,
                                      //     key,
                                      //     0,
                                      //     "format_line_character_limit"
                                      //   )
                                      // }
                                      onBlur={() =>
                                        validator.current.showMessageFor(
                                          "character_limit"
                                        )
                                      }
                                    />

                                    {validator.current.message(
                                      "character_limit",
                                      val.answer_details[0]
                                        .format_line_character_limit,
                                      "required|number",
                                      { className: "text-danger py-1" }
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                  <div className="row my-2">
                    <div className="col-2"></div>

                    {/* <div className="col-2 mt-4">
                    <Button
                      className=" px-3 d-block button-pink rounded"
                      onClick={addAnotherQuestion}
                    >
                      + Add Another Question
                    </Button>
                  </div> */}
                  </div>
                </div>
                <div
                  className="shadow-sm p-3 d-flex justify-content-between"
                  style={{ backgroundColor: "#eeeeee" }}
                >
                  <Button
                    className="px-3 d-block button-pink rounded"
                    onClick={() => props.history.push("/surveyManagement")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
            {accessDenied === true && (
              <Link to="/surveyManagement">
                <Button className="px-3 d-block button-pink rounded mt-2">
                  Cancel
                </Button>
              </Link>
            )}
          </form>
        )}
      </Formik>
    );
};

export default ViewSurvey;
