import React, { useEffect, useState, useRef } from "react";
import { Table, Switch, Select, Input } from "antd";
import "./Tiers.css";
import { getTiers } from "../../../utils/commonApis";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import SimpleReactValidator from "simple-react-validator";
import { cloneDeep } from "lodash";
import { useMediaQuery } from "react-responsive";
import {
  handleCommonKeyDown,
  isValidNumber,
} from "../../../utils/commonFunctions";

const Tiers = ({ tiersData, onChangeData, onValid, initiateValidations }) => {
  const { Option } = Select;
  const [tiersAPI, setTiersAPI] = useState([]);
  const [tierDataLocal, setTierDataLocal] = useState(tiersData);
  const validator = useRef(new SimpleReactValidator());
  const Tablet = useMediaQuery({ maxWidth: 900 });

  useEffect(async () => {
    window.scrollTo(0, 0);
    const event_tier_details = await getTiers();
    setTiersAPI(event_tier_details);
    setTierDataLocal(tiersData.event_tier_details);
  }, []);

  useEffect(() => {
    setTierDataLocal(tiersData.event_tier_details);
  }, [tiersData]);

  useEffect(() => {
    if (initiateValidations) {
      handleFormSubmit();
    }
  }, [initiateValidations]);

  const handleFormSubmit = () => {
    if (validator.current.allValid()) onValid(true);
    else {
      onValid(false);
      validator.current.showMessages();
    }
  };

  const onHandleChange = (key, value, tier, indexHere) => {
    if (tierDataLocal) {
      let clonedTiers = cloneDeep(tierDataLocal);
      clonedTiers[indexHere][key] = value;
      onChangeData(clonedTiers);
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      className: "text-end",
    },
    {
      title: "Diamond",
      dataIndex: "diamond",
      key: "diamond",
      className: "text-center",
    },
    {
      title: "Platinum",
      dataIndex: "platinum",
      key: "address",
      className: "text-center",
    },
    {
      title: "Gold",
      key: "gold",
      dataIndex: "gold",
      className: "text-center",
    },
    {
      title: "Silver",
      key: "silver",
      dataIndex: "silver",
      className: "text-center",
    },
    {
      title: "Bronze",
      dataIndex: "bronze",
      key: "bronze",
      className: "text-center",
    },
  ];

  const renderDdl = (key, tier, index, placeHolder, label) => {
    return (
      <div className="px-3">
        <Select
          showSearch
          optionFilterProp="children"
          placeholder={placeHolder}
          className="w-100 fs-bold-14"
          allowClear
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
          defaultValue={
            tierDataLocal && tierDataLocal[index] && tierDataLocal[index][key]
              ? tierDataLocal[index][key]
              : null
          }
          value={
            tierDataLocal && tierDataLocal[index] && tierDataLocal[index][key]
              ? tierDataLocal[index][key]
              : null
          }
          onChange={(e) => onHandleChange(key, e, tier, index)}
          listHeight={120}
          listItemHeight={4}
        >
          {tiersAPI.map((item, i) => {
            return (
              <Option
                key={item.tier_id + "_tierOpt_" + i.toString()}
                value={item.tier_id}
              >
                {item.tier}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const renderNumberField = (key, tier, index) => {
    return (
      <div
      // className="btn-group mx-auto border-gray rounded"
      // role="group"
      // aria-label="Second group"
      >
        <button
          onClick={() => {
            if (
              tierDataLocal &&
              tierDataLocal[index] &&
              tierDataLocal[index][key] &&
              tierDataLocal[index][key] > 0
            )
              onHandleChange(key, tierDataLocal[index][key] - 1, tier, index);
            else onHandleChange(key, 0, tier, index);
          }}
          // type="button"
          className="tiers_plus_minus_btn"
        >
          -
        </button>
        <Input
          type="number"
          className="text-center"
          style={{ width: "50%" }}
          onKeyDown={handleCommonKeyDown}
          onChange={(e) => {
            const { value: inputValue } = e.target;
            const reg = /^-?\d*(\.\d*)?$/;
            if (
              reg.test(inputValue) ||
              inputValue === "" ||
              inputValue === "-"
            ) {
              if (isValidNumber(inputValue)) {
                onHandleChange(key, parseInt(inputValue), tier, index);
              }
            }
          }}
          value={
            tierDataLocal && tierDataLocal[index] && tierDataLocal[index][key]
              ? tierDataLocal[index][key]
              : 0
          }
        />
        <button
          onClick={() => {
            if (
              tierDataLocal &&
              tierDataLocal[index] &&
              tierDataLocal[index][key]
            )
              onHandleChange(key, tierDataLocal[index][key] + 1, tier, index);
            else onHandleChange(key, 0 + 1, tier, index);
          }}
          // type="button"
          className="tiers_plus_minus_btn"
        >
          +
        </button>
      </div>
    );
  };
  const renderSwitch = (key, tier, index) => {
    return (
      <span className="mx-4">
        <Switch
          onChange={(value) => {
            onHandleChange(key, value, tier, index);
          }}
          checked={
            tierDataLocal && tierDataLocal[index] && tierDataLocal[index][key]
              ? tierDataLocal[index][key]
              : false
          }
        />
      </span>
    );
  };

  const data = [
    {
      key: "1",
      name: (
        <label htmlFor="">
          Booth Template
          <RedAsterick />
        </label>
      ),
      diamond: renderDdl(
        "booth_template_id",
        "diamond",
        0,
        "None",
        "Booth Template"
      ),
      platinum: renderDdl(
        "booth_template_id",
        "platinum",
        1,
        "None",
        "Booth Template"
      ),
      gold: renderDdl("booth_template_id", "gold", 2, "None", "Booth Template"),
      silver: renderDdl(
        "booth_template_id",
        "silver",
        3,
        "None",
        "Booth Template"
      ),
      bronze: renderDdl(
        "booth_template_id",
        "bronze",
        4,
        "None",
        "Booth Template"
      ),
    },
    {
      key: "2",
      name: (
        <label htmlFor="">
          No. of Agents
          <RedAsterick />
        </label>
      ),
      diamond: renderNumberField("no_of_agents", "diamond", 0),
      platinum: renderNumberField("no_of_agents", "platinum", 1),
      gold: renderNumberField("no_of_agents", "gold", 2),
      silver: renderNumberField("no_of_agents", "silver", 3),
      bronze: renderNumberField("no_of_agents", "bronze", 4),
    },
    {
      key: "3",
      name: (
        <label htmlFor="">
          No. of Zones
          <RedAsterick />
        </label>
      ),
      diamond: renderNumberField("no_of_zones", "diamond", 0),
      platinum: renderNumberField("no_of_zones", "platinum", 1),
      gold: renderNumberField("no_of_zones", "gold", 2),
      silver: renderNumberField("no_of_zones", "silver", 3),
      bronze: renderNumberField("no_of_zones", "bronze", 4),
    },
    {
      key: "4",
      name: (
        <label htmlFor="">
          No. of Assets per zone
          <RedAsterick />
        </label>
      ),
      diamond: renderNumberField("no_of_assets_per_zone", "diamond", 0),
      platinum: renderNumberField("no_of_assets_per_zone", "platinum", 1),
      gold: renderNumberField("no_of_assets_per_zone", "gold", 2),
      silver: renderNumberField("no_of_assets_per_zone", "silver", 3),
      bronze: renderNumberField("no_of_assets_per_zone", "bronze", 4),
    },
    {
      key: "5",
      name: "Video Demo Room",
      diamond: renderSwitch("open_demo_rooms", "diamond", 0),
      platinum: renderSwitch("open_demo_rooms", "platinum", 1),
      gold: renderSwitch("open_demo_rooms", "gold", 2),
      silver: renderSwitch("open_demo_rooms", "silver", 3),
      bronze: renderSwitch("open_demo_rooms", "bronze", 4),
    },
    {
      key: "6",
      name: "1-1 Video Call",
      diamond: renderSwitch("new_video_rep", "diamond", 0),
      platinum: renderSwitch("new_video_rep", "platinum", 1),
      gold: renderSwitch("new_video_rep", "gold", 2),
      silver: renderSwitch("new_video_rep", "silver", 3),
      bronze: renderSwitch("new_video_rep", "bronze", 4),
    },
    {
      key: "7",
      name: "Text Chat",
      diamond: renderSwitch("chart_boards", "diamond", 0),
      platinum: renderSwitch("chart_boards", "platinum", 1),
      gold: renderSwitch("chart_boards", "gold", 2),
      silver: renderSwitch("chart_boards", "silver", 3),
      bronze: renderSwitch("chart_boards", "bronze", 4),
    },
    {
      key: "8",
      name: "Chat Bot",
      diamond: renderSwitch("chat_bots", "diamond", 0),
      platinum: renderSwitch("chat_bots", "platinum", 1),
      gold: renderSwitch("chat_bots", "gold", 2),
      silver: renderSwitch("chat_bots", "silver", 3),
      bronze: renderSwitch("chat_bots", "bronze", 4),
    },
    {
      key: "9",
      name: "On Booth Leads",
      diamond: renderSwitch("on_booth_leads", "diamond", 0),
      platinum: renderSwitch("on_booth_leads", "platinum", 1),
      gold: renderSwitch("on_booth_leads", "gold", 2),
      silver: renderSwitch("on_booth_leads", "silver", 3),
      bronze: renderSwitch("on_booth_leads", "bronze", 4),
    },
    {
      key: "10",
      name: "Survey Builder",
      diamond: renderSwitch("survey_builder", "diamond", 0),
      platinum: renderSwitch("survey_builder", "platinum", 1),
      gold: renderSwitch("survey_builder", "gold", 2),
      silver: renderSwitch("survey_builder", "silver", 3),
      bronze: renderSwitch("survey_builder", "bronze", 4),
    },
    {
      key: "11",
      name: "No.of Sponsorship",
      diamond: renderNumberField("no_of_sponserships", "diamond", 0),
      platinum: renderNumberField("no_of_sponserships", "platinum", 1),
      gold: renderNumberField("no_of_sponserships", "gold", 2),
      silver: renderNumberField("no_of_sponserships", "silver", 3),
      bronze: renderNumberField("no_of_sponserships", "bronze", 4),
    },
    {
      key: "12",
      name: "Stream Polling",
      diamond: renderSwitch("stream_polls", "diamond", 0),
      platinum: renderSwitch("stream_polls", "platinum", 1),
      gold: renderSwitch("stream_polls", "gold", 2),
      silver: renderSwitch("stream_polls", "silver", 3),
      bronze: renderSwitch("stream_polls", "bronze", 4),
    },
    // {
    //   key: "13",
    //   name: "Chat / Board Moderation",
    //   diamond: renderSwitch("chat_board_moderation", "diamond", 0),
    //   platinum: renderSwitch("chat_board_moderation", "platinum", 1),
    //   gold: renderSwitch("chat_board_moderation", "gold", 2),
    //   silver: renderSwitch("chat_board_moderation", "silver", 3),
    //   bronze: renderSwitch("chat_board_moderation", "bronze", 4)
    // },
    {
      key: "14",
      name: "Dashboard Level",
      diamond: renderDdl(
        "dashboard_level",
        "diamond",
        0,
        "None",
        "Dashboard Level"
      ),
      platinum: renderDdl(
        "dashboard_level",
        "platinum",
        1,
        "None",
        "Dashboard Level"
      ),
      gold: renderDdl("dashboard_level", "gold", 2, "None", "Dashboard Level"),
      silver: renderDdl(
        "dashboard_level",
        "silver",
        3,
        "None",
        "Dashboard Level"
      ),
      bronze: renderDdl(
        "dashboard_level",
        "bronze",
        4,
        "None",
        "Dashboard Level"
      ),
    },
  ];

  return (
    <div className="ant-layout-content">
      {/* Tiers Component */}
      <Table
        className="table-striped mb-4"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{
          x: Tablet ? 1300 : 0,
        }}
      />
    </div>
  );
};

export default Tiers;
