/**
 * @name OverlayContainer
 * Container for the content that appears in-front of the visa
 */

import React, { useContext } from "react";
import Switch from "../../components/switch/index";
import Popup from "../../components/popup/index";
import AppContext from "../../contexts/AppContext";

import Expo from "../../components/expo";
import Map from "../../components/map";

import Container from "./styles";

const OverlayContainer = (props) => {
  const { activeWindow } = useContext(AppContext);

  return (
    <Container>
      {/* <Switch active={activeWindow}> */}

      {/* Map */}
      {/* <Popup name="map">
            <Map />
        </Popup> */}

      {/* Expo */}
      {/* <Popup name="expo">
            <Expo />
        </Popup> */}

      {/* Default */}

      {/* // activeWindow === "image" ?
            //     <Popup name="image" url="" /> :
            //     activeWindow === "video" ?
            //         <Popup name="video" url="" /> :
            //         <Popup name="default">
            //             {`Sorry no pop up for ${activeWindow}`}
            //         </Popup> */}
      {activeWindow && (
        <Popup name={activeWindow} url={activeWindow.assetUrl} props={props} />
      )}

      {/* </Switch> */}
    </Container>
  );
};

export default OverlayContainer;
