import React from "react";

export const Cube = props => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill={props.fill}
      >
        <g
          id="_055-cube"
          data-name="055-cube"
          transform="translate(-0.25)"
          opacity="0.7"
        >
          <g id="Group_1097" data-name="Group 1097">
            <path
              id="Path_623"
              data-name="Path 623"
              d="M16.875,0H7.852A3.137,3.137,0,0,0,5.58.979L1.188,5.362A3.121,3.121,0,0,0,0,7.813v9.063A3.129,3.129,0,0,0,3.125,20h9.023a3.116,3.116,0,0,0,2.261-.97l.012-.012,4.665-4.623,0,0a3.105,3.105,0,0,0,.909-2.2V3.125A3.129,3.129,0,0,0,16.875,0ZM6.692,2.076l.018-.019a1.548,1.548,0,0,1,1.141-.495h9.019L13.456,4.974a3.107,3.107,0,0,0-1.307-.287H4.076ZM18.438,12.188a1.553,1.553,0,0,1-.452,1.1l-2.712,2.687V14.531a.781.781,0,0,0-1.562,0v2.344a1.564,1.564,0,0,1-1.562,1.563H3.125a1.564,1.564,0,0,1-1.562-1.562V7.813A1.564,1.564,0,0,1,3.125,6.25h9.023a1.564,1.564,0,0,1,1.563,1.563v2.813a.781.781,0,0,0,1.563,0V7.813a3.108,3.108,0,0,0-.6-1.843L18.253,2.39a1.553,1.553,0,0,1,.184.735Z"
              transform="translate(0.25)"
            />
          </g>
        </g>
      </svg>
    </>
  );
};
