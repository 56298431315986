import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  brandObj: {},
  expo_hall_sequence: null,
  expomap_redirect_link_name: {
    linkName: "",
    number: null,
    notFromExpoMap: false,
  },
  eventId: null,
  boothData: {
    tier: null,
    sequence: null,
    zones: [],
    final_booth_url: null,
  },
  vistaLocationInterval: null,
  vistaLocationId: null,
  concourseData: null,
  expoHallData: null,
  expoMapArr: null,
  menuItemFlagr: null,
  expoHallUrl: "",
  expoHallLocationNumber: null,
  boothLocationNumber: null,
  networkingEntryGateLocationNumber: null,
};

export const setVistaBrandData = createAsyncThunk(
  "vistaBrandData",

  async (brandObj) => {
    return brandObj;
  }
);

export const setVistaExpoHallSequence = createAsyncThunk(
  "vistaexpohallsequence",

  async (value) => {
    return value;
  }
);

export const setExpoMapRedirectLinkName = createAsyncThunk(
  "expomapredirectlinkname",

  async ({ linkName, number, notFromExpoMap }) => {
    return {
      linkName,
      number,
      notFromExpoMap,
    };
  }
);

export const setVistaEventId = createAsyncThunk(
  "vistaeventid",

  async (value) => {
    return value;
  }
);

export const setVisitBoothData = createAsyncThunk(
  "vistboothdata",

  async ({ tier, sequence, zones, final_booth_url }) => {
    return {
      tier,
      sequence,
      zones,
      final_booth_url,
    };
  }
);

export const setVistaLocationInterval = createAsyncThunk(
  "vistaLocationInterval",

  async (interval) => {
    return interval;
  }
);

export const setVistaLocationId = createAsyncThunk(
  "vistaLocationId",

  async (id) => {
    return id;
  }
);

export const setConcourseData = createAsyncThunk(
  "setConcourseData",

  async (data) => {
    return data;
  }
);

export const setExpoHallData = createAsyncThunk(
  "setExpoHallData",

  async (data) => {
    return data;
  }
);

export const setExpoMappingArr = createAsyncThunk(
  "setExpoMappingArr",

  async (data) => {
    return data;
  }
);

export const updateMenuItems = createAsyncThunk(
  "updateMenuItems",

  async (data) => {
    return data;
  }
);

export const setExpoHallUrl = createAsyncThunk(
  "setExpoHallUrl",

  async (data) => {
    return data;
  }
);

export const setExpoHallLocationNumber = createAsyncThunk(
  "setExpoHallLocationNumber",

  async (data) => {
    return data;
  }
);

export const setBoothLocationNumber = createAsyncThunk(
  "setBoothLocationNumber",

  async (data) => {
    return data;
  }
);

export const setNetworkingEntryGateLocationNumber = createAsyncThunk(
  "setNetworkingEntryGateLocationNumber",

  async (data) => {
    return data;
  }
);

export const setVistaBrandSlice = createSlice({
  name: "vistaBrandData",
  initialState,
  reducers: {},
  extraReducers: {
    [setVistaBrandData.pending]: (state) => {
      state.loading = true;
    },
    [setVistaBrandData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.brandObj = payload;
    },
    [setVistaBrandData.rejected]: (state) => {
      state.loading = false;
    },
    [setVistaExpoHallSequence.pending]: (state) => {
      state.loading = true;
    },
    [setVistaExpoHallSequence.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.expo_hall_sequence = payload;
    },
    [setVistaExpoHallSequence.rejected]: (state) => {
      state.loading = false;
    },
    [setExpoMapRedirectLinkName.pending]: (state) => {
      state.loading = true;
    },
    [setExpoMapRedirectLinkName.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.expomap_redirect_link_name = payload;
    },
    [setExpoMapRedirectLinkName.rejected]: (state) => {
      state.loading = false;
    },
    [setVistaEventId.pending]: (state) => {
      state.loading = true;
    },
    [setVistaEventId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.eventId = payload;
    },
    [setVistaEventId.rejected]: (state) => {
      state.loading = false;
    },
    [setVisitBoothData.pending]: (state) => {
      state.loading = true;
    },
    [setVisitBoothData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.boothData = payload;
    },
    [setVisitBoothData.rejected]: (state) => {
      state.loading = false;
    },
    [setVistaLocationInterval.pending]: (state) => {
      state.loading = true;
    },
    [setVistaLocationInterval.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vistaLocationInterval = payload;
    },
    [setVistaLocationInterval.rejected]: (state) => {
      state.loading = false;
    },
    [setVistaLocationId.pending]: (state) => {
      state.loading = true;
    },
    [setVistaLocationId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vistaLocationId = payload;
    },
    [setVistaLocationId.rejected]: (state) => {
      state.loading = false;
    },
    [setConcourseData.pending]: (state) => {
      state.loading = true;
    },
    [setConcourseData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.concourseData = payload;
    },
    [setConcourseData.rejected]: (state) => {
      state.loading = false;
    },
    [setExpoHallData.pending]: (state) => {
      state.loading = true;
    },
    [setExpoHallData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.expoHallData = payload;
    },
    [setExpoHallData.rejected]: (state) => {
      state.loading = false;
    },
    [setExpoMappingArr.pending]: (state) => {
      state.loading = true;
    },
    [setExpoMappingArr.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.expoMapArr = payload;
    },
    [setExpoMappingArr.rejected]: (state) => {
      state.loading = false;
    },
    [updateMenuItems.pending]: (state) => {
      state.loading = true;
    },
    [updateMenuItems.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.menuItemFlag = payload;
    },
    [updateMenuItems.rejected]: (state) => {
      state.loading = false;
    },
    [setExpoHallUrl.pending]: (state) => {
      state.loading = true;
    },
    [setExpoHallUrl.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.expoHallUrl = payload;
    },
    [setExpoHallUrl.rejected]: (state) => {
      state.loading = false;
    },
    [setExpoHallLocationNumber.pending]: (state) => {
      state.loading = true;
    },
    [setExpoHallLocationNumber.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.expoHallLocationNumber = payload;
    },
    [setExpoHallLocationNumber.rejected]: (state) => {
      state.loading = false;
    },
    [setBoothLocationNumber.pending]: (state) => {
      state.loading = true;
    },
    [setBoothLocationNumber.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.boothLocationNumber = payload;
    },
    [setBoothLocationNumber.rejected]: (state) => {
      state.loading = false;
    },
    [setNetworkingEntryGateLocationNumber.pending]: (state) => {
      state.loading = true;
    },
    [setNetworkingEntryGateLocationNumber.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.networkingEntryGateLocationNumber = payload;
    },
    [setNetworkingEntryGateLocationNumber.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const vistaBrandReducer = setVistaBrandSlice.reducer;
