import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  TimePicker,
  Collapse,
  Modal,
} from "antd";
import "./Zone.css";
import {
  MinusOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { EditRed } from "../../components/svgJS/EditRed";
import { Bin } from "../../components/svgJS/Bin";
import { Cube } from "../../components/svgJS/Cube";
import { ApiCall } from "../../utils/api";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  fetchEventShow,
  editZone,
} from "../../redux/slice/zoneManagementSlice";
import { toast } from "react-toastify";
import moment from "moment";
import FileUpload from "../common/FileUpload";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import AddNewSurvey from "../surveyManagement/AddNewSurvey";
import CommonLoader from "../../components/Widgets/CommonLoader";
import SimpleReactValidator from "simple-react-validator";
import {
  IMAGE_FILE_BASIC_PROPS,
  STATIC_URLS,
  TIME_FORMAT,
} from "../../components/constsnts/common.constants";
import {
  compareAllStartEndTimes,
  createISOTimeString,
  createTZString,
  getCookie,
  getDisabledTime,
  handleCommonKeyDown,
  isRouteExistInMenu,
  isValidHttpURL,
  isValidNumber,
} from "../../utils/commonFunctions";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import { AddGroup } from "../../components/svgJS/AddGroup";
import AddUser from "../UserManagement/AddUser";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";
import { getZoneDetails } from "../../utils/commonApis";

const EditZone = (props) => {
  var CryptoJS = require("crypto-js");
  const validator = useRef(new SimpleReactValidator());
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const { Option } = Select;
  const { TextArea } = Input;
  const { Panel } = Collapse;
  const user_name = getCookie("user_name");
  var bytes = CryptoJS.AES.decrypt(user_name, "user_name");
  var decryptedName = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const dispatch = useDispatch();

  const [surveyList, setSurveyList] = useState(null);
  const [allAgentsList, setAllAgentsList] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [zoneHeroImg, setZoneHeroImg] = useState();
  const [fetchedZoneData, setFetchedZoneData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [userType, setUserType] = useState();
  const [viewVisible, setViewVisible] = useState(false);
  const [assetsArray, setAssetsArray] = useState([]);
  const [updateAssetValue, setUpdateAssetValue] = useState(null);
  const [eventList, setEventList] = useState(null);
  const [brandList, setBrandList] = useState(null);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [boothLocationList, setBoothLocation] = useState(null);
  const [chatBotDetails, setChatBotDetails] = useState([]);
  const [prevEventId, setPrevEventId] = useState(null);
  const [prevChatBot, setPrevChatBot] = useState(null);
  const [chatBotFileUpload, setChatBotFileUpload] = useState(null);
  const [chatBotValidation, setChatBotValidation] = useState();
  const [localData, setLocalData] = useState({
    zone_id: parseInt(props?.match.params.zone_id),
    event_id: null,
    brand_id: null,
    booth_location_id: null,
    is_active: true,
    zone_title: null,
    zone_description: null,
    website_url: null,
    is_text_chat_enable: null,
    is_message_board_enable: null,
    is_moderated: null,
    is_one_to_one_video_calls_enable: null,
    survey_id: null,
    is_on_booth_leads_enable: null,
    is_video_rep_enable: null,
    is_video_demo_room_enable: null,
    is_text_bot_enable: null,
    is_individual: null,
    zones_agents: [],
    zone_chat_bot_setting_details: null,
    zones_social_media_handles_details: [],
    zones_assets_details: [],
    // zone_hero_img: null,
    chatbotUpload: null,
  });
  const [brandTier, setBrandTier] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [brand_id, set_brand_id] = useState(null);
  const [bool, setBool] = useState(false);
  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState(false);
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const [loaderZone, setLoaderZone] = useState(false);
  const [openUserList, setOpenUsersList] = useState(false);

  useEffect(async () => {
    setZoneLoading(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      getEventsList();
      getSurveyList();
      getSocialMediaList();
      await getZoneDetail();
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    //Restriced Tier Logic
    if (brandList && localData.brand_id) {
      brandList.forEach((elem) => {
        if (elem.brand_id === localData.brand_id) {
          callRestrictedAPI(elem?.brand_tier);
        }
      });
    }
    callRestrictedAPI();
    getBoothLocation(localData?.brand_id);
  }, [brandList, localData.brand_id, brand_id, brandTier]);

  useEffect(() => {
    if (localData.event_id) {
      getAgentsList();
      getSurveyList();
    }
  }, [localData.event_id, localData.brand_id]);

  useEffect(() => {
    if (allAgentsList && !bool) {
      localData.zones_agents.map((ele) => {
        if (agents.some((agent) => agent.user_id === ele)) {
          handleAgentsChange(ele);
          setBool(true);
        }
      });
    }
  }, [allAgentsList, localData.zones_agents, agents]);

  useEffect(() => {
    if (localData?.is_text_bot_enable && !localData?.zone_chat_bot_file_url)
      setChatBotValidation(null);
    else setChatBotValidation({ name: "dummy" });
  }, [localData?.is_text_bot_enable]);

  function handleAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);
    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
  }

  function handleSelectedAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);
  }

  const getEventsList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        setEventList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getBrandsList = (val) => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: val ? val : localData?.event_id,
    })
      .then((response) => {
        const { rows } = response.data.data;
        setBrandList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAgentsList = () => {
    ApiCall("get", "", "/admin/usertypeagents/get", {
      event_id: localData?.event_id ? localData?.event_id : null,
      brand_id: localData?.brand_id ? localData?.brand_id : null,
    })
      .then((response) => {
        setAgents(response?.data?.data);
        setSelectedAgents([]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const surveyParams = {
    event_id: localData?.event_id ? localData?.event_id : null,
    is_brandzone_survey: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    current_timestamp: moment().format("YYYY-MM-DD"),
  };
  const getSurveyList = () => {
    ApiCall("get", null, "/admin/survey/get", surveyParams)
      .then((response) => {
        setSurveyList(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getBoothLocation = (val) => {
    ApiCall("get", null, "/admin/boothlocations/get", { brand_id: val })
      .then((response) => {
        setBoothLocation(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleFieldsChange = (val, name) => {
    if (name === "event_id") {
      setLocalData({
        ...localData,
        [name]: val,
        brand_id: null,
      });
    } else if (name === "brand_id") {
      setLocalData({
        ...localData,
        [name]: val,
        booth_location_id: null,
        is_text_chat_enable: false,
        is_one_to_one_video_calls_enable: false,
        survey_id: null,
        is_on_booth_leads_enable: false,
        is_video_demo_room_enable: false,
        is_text_bot_enable: false,
      });
    } else if (name === "is_text_bot_enable") {
      if (val) {
        setChatBotValidation(null);
      } else {
        setChatBotValidation({
          dummy: "validation",
        });
      }
      setLocalData({
        ...localData,
        [name]: val,
      });
    } else setLocalData({ ...localData, [name]: val });
  };

  const getZoneDetail = async () => {
    const details = await getZoneDetails({
      zone_id: props?.match.params.zone_id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    if (details && details.zone_id) {
      setFetchedZoneData(details);
      setAssetsArray(details.zones_assets_id);
      let selected_agents = [];
      details?.zones_agents?.map((val) => {
        selected_agents.push(val?.zonesAgents_user_id?.user_id);
      });

      let zones_social_media_handles_details = [];
      details?.zones_social_media_handles_id?.forEach((ele) => {
        zones_social_media_handles_details.push({
          zone_social_id: ele.zone_social_id,
          social_media_id: ele.social_media_id,
          social_media_url: ele.social_media_url,
        });
      });

      let modified_zone_chat_bot_setting_details = [];

      details?.zone_chat_bot_setting_details &&
        details?.zone_chat_bot_setting_details.forEach((ele) => {
          const start_date_time = createTZString(ele.start_date_time);
          const end_date_time = createTZString(ele.end_date_time);
          const eleStartDate = moment(
            start_date_time.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          );
          const eleEndDate = moment(
            end_date_time.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          );
          const eleStartTime = moment(moment(start_date_time, TIME_FORMAT));
          const eleEndTime = moment(moment(end_date_time, TIME_FORMAT));

          if (ele.chat_bot_settings_id) {
            modified_zone_chat_bot_setting_details.push({
              chat_bot_settings_id: ele.chat_bot_settings_id,
              start_date: eleStartDate.format("YYYY-MM-DD"),
              start_time: eleStartTime,
              end_date: eleEndDate.format("YYYY-MM-DD"),
              end_time: eleEndTime,
              show_id: ele?.show_id,
            });
          }
        });

      //Calling this api to get updated event shows
      const response = await ApiCall("GET", "", "/admin/events/shows/get", {
        event_id: details?.event_id,
      });
      let newEventShows = [];
      if (response?.data?.code === 200) {
        newEventShows = response?.data?.data;
      }

      //Comparing existing event shows with new shows if existing does not contain  new show then add it in the existing array

      if (modified_zone_chat_bot_setting_details?.length !== 0) {
        // Step1 compare two arrays of obj and get unique array of objects from those two.
        let uniqueArr = newEventShows.filter((x) => {
          return !modified_zone_chat_bot_setting_details.find(
            (item) => item.show_id === x.show_id
          );
        });

        // Step2 modify uniques array of objects
        let modifiedUniqueArr = uniqueArr.map((ele) => {
          const start_date_time = createTZString(ele.start_date_time);
          const end_date_time = createTZString(ele.end_date_time);
          const eleStartDate = moment(
            start_date_time.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          );
          const eleEndDate = moment(
            end_date_time.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          );
          const eleStartTime = moment(moment(start_date_time, TIME_FORMAT));
          const eleEndTime = moment(moment(end_date_time, TIME_FORMAT));
          return {
            start_date: eleStartDate.format("YYYY-MM-DD"),
            start_time: eleStartTime,
            end_date: eleEndDate.format("YYYY-MM-DD"),
            end_time: eleEndTime,
            show_id: ele?.show_id,
          };
        });

        //Step3 merge the modifiy unique array of object with the old array of object
        modified_zone_chat_bot_setting_details =
          modified_zone_chat_bot_setting_details.concat(modifiedUniqueArr);
      } else {
        //if modified_zone_chat_bot_setting_details is empty populate the array with new shows
        newEventShows?.forEach((ele) => {
          const start_date_time = createTZString(ele.start_date_time);
          const end_date_time = createTZString(ele.end_date_time);
          const eleStartDate = moment(
            start_date_time.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          );
          const eleEndDate = moment(
            end_date_time.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          );
          const eleStartTime = moment(moment(start_date_time, TIME_FORMAT));
          const eleEndTime = moment(moment(end_date_time, TIME_FORMAT));

          modified_zone_chat_bot_setting_details.push({
            start_date_time: ele?.start_date_time,
            end_date_time: ele?.end_date_time,
            start_date: eleStartDate.format("YYYY-MM-DD"),
            start_time: eleStartTime,
            end_date: eleEndDate.format("YYYY-MM-DD"),
            end_time: eleEndTime,
            show_id: ele?.show_id,
          });
        });
      }

      if (details.zone_chat_bot_file_name) {
        setChatBotFileUpload({
          name: details.zone_chat_bot_file_name,
          url: details.zone_chat_bot_file_url,
        });
      }

      if (details?.zone_chat_bot_file_url) {
        setChatBotValidation({ name: "dummy" });
      }

      if (modified_zone_chat_bot_setting_details) {
        setChatBotDetails(modified_zone_chat_bot_setting_details);
        setPrevChatBot(modified_zone_chat_bot_setting_details);
      }

      setPrevEventId(details?.event_id);

      let brands = await getBrandsList(details?.event_id);
      if (brands) setBrandList(brands);
      let locations = await getBoothLocation(details?.brand_id);
      if (locations) {
        let final_locations = locations;
        if (details?.booth_location)
          final_locations?.push(details?.booth_location);

        setBoothLocation(locations);
      }
      setLocalData({
        ...localData,
        event_id: details?.event_id,
        brand_id: details?.brand_id,
        booth_location_id: details?.booth_location_id,
        is_active: details?.is_active,
        zone_title: details?.zone_title,
        zone_description: details?.zone_description,
        website_url: details?.website_url,
        is_text_chat_enable: details?.is_text_chat_enable,
        is_message_board_enable: details?.is_message_board_enable,
        is_moderated: details?.is_moderated,
        is_one_to_one_video_calls_enable:
          details?.is_one_to_one_video_calls_enable,
        survey_id: details?.survey_id,
        is_on_booth_leads_enable: details?.is_on_booth_leads_enable,
        is_video_rep_enable: details?.is_video_rep_enable,
        is_video_demo_room_enable: details?.is_video_demo_room_enable,
        is_text_bot_enable: details?.is_text_bot_enable,
        is_individual: details?.is_individual,
        zones_agents: selected_agents,
        zone_chat_bot_setting_details: modified_zone_chat_bot_setting_details,
        zones_social_media_handles_details: zones_social_media_handles_details,
        zones_assets_details: assetsArray,
        hero_img_url: details?.zone_hero_image_url,
        zone_chat_bot_file_url: details?.zone_chat_bot_file_url,
      });
      set_brand_id(details?.brand_id);
      setZoneLoading(false);
    } else setZoneLoading(false);
  };

  const handleImgChange = (event) => {
    setZoneHeroImg(URL.createObjectURL(event.target.files[0]));
    setLocalData({ ...localData, hero_img_url: event.target.files[0] });
  };
  const handleFileDelete = () => {
    setZoneHeroImg(null);
    setLocalData({ ...localData, hero_img_url: null });
  };

  const handleEditZone = async (e) => {
    e.preventDefault();
    setZoneLoading(true);
    let data_temp = localData;
    const areEventTimesProper = compareAllStartEndTimes(chatBotDetails);
    if (areEventTimesProper === "no" && localData?.is_text_bot_enable) {
      toast.error("Please re check event times");
      return;
    }
    const zone_chat_bot_setting_details = [];

    chatBotDetails?.forEach((ele) => {
      const start_date_str =
        ele.start_date +
        " " +
        moment(moment(ele.start_time, TIME_FORMAT)).format("HH:mm");
      const start_utc = createISOTimeString(start_date_str);
      const end_date_str =
        ele.end_date +
        " " +
        moment(moment(ele.end_time, TIME_FORMAT)).format("HH:mm");
      const end_utc = createISOTimeString(end_date_str);

      zone_chat_bot_setting_details.push({
        chat_bot_settings_id: ele.chat_bot_settings_id,
        show_id: ele?.show_id,
        start_date_time: start_utc,
        end_date_time: end_utc,
      });
    });

    const agentsIdArr = selectedAgents.map((ele) => ele.user_id);

    data_temp.zone_chat_bot_setting_details = zone_chat_bot_setting_details;

    data_temp.zones_agents = agentsIdArr;
    data_temp.zones_social_media_handles_details =
      localData.zones_social_media_handles_details;
    data_temp.zones_assets_details = assetsArray;
    if (validator.current.allValid()) {
      let allURLValid = "yes";
      if (localData && localData.zones_social_media_handles_details) {
        let clonedLinks = cloneDeep(
          localData.zones_social_media_handles_details
        );
        localData.zones_social_media_handles_details.forEach((ele, index) => {
          if (
            ele.social_media_url === "" ||
            !isValidHttpURL(ele.social_media_url) ||
            ele.social_media_url === "https://www.instagram.com/" || //Cr Point -> User should not be able to save this if its just  https://www.instagram.com/ there should user id after the last "/".
            ele.social_media_url === "https://www.facebook.com/" ||
            ele.social_media_url === "https://twitter.com/" ||
            ele.social_media_url === "https://www.linkedin.com/"
          ) {
            clonedLinks[index]["urlValid"] = "no";
            allURLValid = "no";
          }
        });
        if (allURLValid === "no")
          setLocalData({
            ...localData,
            zones_social_media_handles_details: clonedLinks,
          });
      }
      if (allURLValid === "no") {
        toast.error("Please check social media links");
        setZoneLoading(false);
        return;
      }
      let formData = new FormData();
      formData.append("data", JSON.stringify(data_temp));
      formData.append("zone_hero_image_file", localData?.hero_img_url);
      if (chatBotFileUpload) {
        formData.append("chat_bot_qa", chatBotFileUpload);
      }

      assetsArray.forEach((ele) => {
        if (ele.uploadObj) {
          let number = 1;
          formData.append(`asset_${number}`, ele.uploadObj);
          number++;
        }
      });

      const res = await dispatch(editZone(formData));
      if (res?.payload?.code === 200) {
        toast.success(res?.payload?.message);
        props.history.push("/zonemanagement");
      } else toast.error(res?.payload?.message);
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
      toast.error("Fields are missing");
    }

    setZoneLoading(false);
  };

  const fetchDetailsFromEventAndSetChatbot = async (event_id) => {
    await dispatch(fetchEventShow({ event_id }));
    const response = await ApiCall("GET", "", "/admin/events/shows/get", {
      event_id,
    });
    if (prevEventId !== event_id) {
      let tempArr = response?.data?.data?.map((ele) => {
        return {
          show_id: ele.show_id,
          start_date: moment(ele.start_date).format("YYYY-MM-DD"),
          start_time: moment(ele.start_time, "HH:mm"),
          end_date: moment(ele.end_date).format("YYYY-MM-DD"),
          end_time: moment(ele.end_time, "HH:mm"),
        };
      });
      setChatBotDetails(tempArr);
    } else setChatBotDetails(prevChatBot);
  };

  const handlechatbotArrChange = (val, key, name) => {
    let chatbot_arr = chatBotDetails;
    chatbot_arr[key][[name]] = val;
    setChatBotDetails([...chatbot_arr]);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addAsset = (data) => {
    setAssetsArray([...assetsArray, data]);
    setIsModalVisible(false);
  };

  const updateAsset = (data) => {
    const { tempIndex } = data;
    delete data.tempIndex;
    const arr = [...assetsArray];
    arr[tempIndex] = data;
    setAssetsArray(arr);
    setIsUpdateModalVisible(false);
    setViewVisible(false);
  };

  const handleAssetsUpdate = (elem, index) => {
    const temp = { ...elem, arrIndex: index };

    setUpdateAssetValue(temp);
    setIsUpdateModalVisible(true);
  };

  const onHandleSocialSwitchChange = (checked, idx) => {
    let social_media_list = socialMediaList;
    let zones_social_media_handles_details =
      localData.zones_social_media_handles_details;
    let isAvailable = zones_social_media_handles_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );
    if (checked) {
      zones_social_media_handles_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url:
          idx === 0
            ? "https://www.instagram.com/" //CR point -> To add prefix when toggle is on
            : idx === 1
            ? "https://www.linkedin.com/"
            : idx === 2
            ? "https://twitter.com/"
            : idx === 3
            ? "https://www.facebook.com/"
            : "",
      });
    } else {
      let index = zones_social_media_handles_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      zones_social_media_handles_details.splice(index, 1);
    }
    setLocalData({
      ...localData,
      zones_social_media_handles_details: zones_social_media_handles_details,
    });
  };

  const socialMediaHandleChange = (idx, event) => {
    let social_media_list = socialMediaList;
    let zones_social_media_handles_details =
      localData.zones_social_media_handles_details;
    let isAvailable = zones_social_media_handles_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );
    if (isAvailable) {
      let index = zones_social_media_handles_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );

      zones_social_media_handles_details[index]["social_media_url"] =
        event.target.value;
    } else {
      zones_social_media_handles_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: event.target.value,
      });
    }
    setLocalData({
      ...localData,
      zones_social_media_handles_details: zones_social_media_handles_details,
    });
  };

  const [restricedFields, setRestricedFields] = useState({
    tier_settings_id: null,
    open_demo_rooms: null,
    new_video_rep: null,
    chart_boards: null,
    chat_bots: null,
    on_booth_leads: null,
  });

  const callRestrictedAPI = async (tier) => {
    await ApiCall("get", null, "/admin/zone/restriction/details/get", {
      event_id: localData.event_id,
      brand_tier: brandTier ?? tier,
    })
      .then((response) => {
        let data = response?.data?.data;
        if (response?.data?.code === 200) {
          setRestricedFields({
            tier_settings_id: data?.tier_settings_id,
            open_demo_rooms: data?.open_demo_rooms,
            new_video_rep: data?.new_video_rep,
            chart_boards: data?.chart_boards,
            chat_bots: data?.chat_bots,
            on_booth_leads: data?.on_booth_leads,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const DetailComponent = ({ assetData }) => {
    return (
      <div className={Tablet ? "mt-4 py-2" : "col-4 py-2 mx-2"}>
        <div className="row border-gray rounded h-100 py-2">
          <div className="row">
            <div className="col-4 text-end">Active</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_active} disabled />
            </div>
            <div className="col-3 ">Whitelist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.whitelisted_domains}
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-end">Downloadable</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_downloadable} disabled />
            </div>
            <div className="col-3 ">Blacklist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.blacklisted_domains}
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-end"></div>
            <div className="col-2"></div>
            {assetData?.is_downloadable && (
              <>
                <div className="col-3 ">Max D/L's</div>
                <div className="col-2">
                  <Button className="py-1 px-3 rounded">
                    {assetData?.max_no_of_downloads}
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-3 text-end">Info</div>
            <div className="col-7">
              <span className="fs-12">
                Filename: {assetData?.original_file_name}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                Date: {moment(assetData?.uploaded_at).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">Uploaded By: {decryptedName}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">View Count: {assetData?.view_count}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                D/L Count: {assetData?.total_downloads}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (zoneLoading) return <CommonLoader />;
  else
    return (
      <>
        {accessDenied === false && (
          <form onSubmit={handleEditZone}>
            {zoneLoading ? (
              <CommonLoader />
            ) : (
              <div>
                <div className="d-flex justify-content-between bg-light w-100 common-sticky-header">
                  <div>
                    <h4 className="fs-24">Edit Zone</h4>
                    {props.isFromEvent ? null : (
                      <Breadcrumb className="my-2" separator=">">
                        <Breadcrumb.Item>
                          <Link to="/zonemanagement">Admin Console</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Link to="/zonemanagement">Zone Management</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Edit Zone</Breadcrumb.Item>
                      </Breadcrumb>
                    )}
                  </div>
                </div>
                <div className="bg-white px-5 py-3">
                  <div className="row my-3">
                    <div className="col-12 mt-5">
                      <h6 className="font-pink fw-bolder fs-20">Zone Editor</h6>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Active
                    </div>
                    <div className="col-4">
                      <Switch
                        checked={localData?.is_active}
                        name="is_active"
                        onChange={(checked) =>
                          handleFieldsChange(checked, "is_active")
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Select Event
                      <RedAsterick />
                    </div>
                    <div className="col-9">
                      <Select
                        showSearch
                        style={{ width: 300 }}
                        placeholder="Select Event"
                        optionFilterProp="children"
                        className={
                          validator?.current?.fields?.event === false &&
                          validator?.current?.messagesShown === true
                            ? "fs-bold-14 border_red"
                            : "fs-bold-14 "
                        }
                        name="event_id"
                        onChange={async (val) => {
                          handleFieldsChange(val, "event_id");
                          fetchDetailsFromEventAndSetChatbot(val);
                          getBrandsList(val);
                        }}
                        onBlur={() =>
                          validator.current.showMessageFor("event_id")
                        }
                        listHeight={120}
                        listItemHeight={4}
                        disabled={
                          props?.event_id
                            ? true
                            : userType === "BRAND ADMIN"
                            ? true
                            : false
                        }
                        value={localData.event_id ? localData.event_id : null}
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        allowClear
                      >
                        {eventList &&
                          eventList.map((data) => (
                            <Option value={data.event_id}>
                              {data.event_name}
                            </Option>
                          ))}
                      </Select>
                      {validator.current.message(
                        "event",
                        localData.event_id,
                        "required|text",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Select Brand
                      <RedAsterick />
                    </div>
                    <div className="col-9">
                      <Select
                        showSearch
                        style={{ width: 300 }}
                        placeholder="Select Brand"
                        className={
                          validator?.current?.fields?.brand === false &&
                          validator?.current?.messagesShown === true
                            ? "fs-bold-14 border_red"
                            : "fs-bold-14 "
                        }
                        optionFilterProp="children"
                        name="brand_id"
                        value={localData.brand_id ? localData.brand_id : null}
                        onChange={async (val) => {
                          await handleFieldsChange(val, "brand_id");
                          await set_brand_id(val);
                          await brandList.forEach((elem) => {
                            if (elem.brand_id === val) {
                              setLocalData((prev) => ({
                                ...prev,
                                brand_name: elem.brand_title,
                              }));

                              setBrandTier(elem?.brand_tier);
                            }
                          });
                        }}
                        onBlur={() =>
                          validator.current.showMessageFor("brand_id")
                        }
                        listHeight={120}
                        listItemHeight={4}
                        disabled={userType === "BRAND ADMIN" ? true : false}
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                      >
                        {brandList &&
                          brandList.map((data) => (
                            <Option value={data.brand_id}>
                              {data.brand_title}
                            </Option>
                          ))}
                      </Select>
                      {validator.current.message(
                        "brand",
                        localData.event_id,
                        "required|text",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Location on Wall
                      <RedAsterick />
                    </div>
                    <div className="col-9">
                      <Select
                        showSearch
                        style={{ width: 300 }}
                        placeholder="Select Wall"
                        optionFilterProp="children"
                        className={
                          validator?.current?.fields?.booth === false &&
                          validator?.current?.messagesShown === true
                            ? "fs-bold-14 border_red"
                            : "fs-bold-14 "
                        }
                        name="booth_location_id"
                        disabled={!localData?.is_active}
                        onChange={(val) =>
                          handleFieldsChange(val, "booth_location_id")
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("booth_location_id")
                        }
                        listHeight={120}
                        listItemHeight={4}
                        value={
                          localData.booth_location_id
                            ? localData.booth_location_id
                            : null
                        }
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                      >
                        {boothLocationList &&
                          boothLocationList.map((data) => (
                            <Option value={data.booth_location_id}>
                              {data.booth_location}
                            </Option>
                          ))}
                      </Select>
                      {validator.current.message(
                        "booth",
                        localData.booth_location_id,
                        "required|text",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  <div className="row my-4">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Zone Hero Image
                    </div>
                    <div
                      className={
                        Tablet ? "col-4 image-upload" : "col-3 image-upload"
                      }
                    >
                      <FileUpload
                        value=""
                        labelClassName="rounded border-pink btn-svg px-3 py-1"
                        labelTitle="Image Upload"
                        inputType="file"
                        inputOnChange={(e) => {
                          if (
                            IMAGE_FILE_BASIC_PROPS.heroImageMimeTypeArr.includes(
                              e.target.files[0].type
                            )
                          )
                            handleImgChange(e);
                          else toast.error("Please Upload JPG/PNG File");
                        }}
                        inputId="heroImglogoCreate"
                        inputName="hero_img_url"
                      />
                    </div>

                    {zoneHeroImg ? (
                      <div className="col-2">
                        <span className="mx-3">
                          {localData?.hero_img_url?.name}
                        </span>
                        <span onClick={handleFileDelete}>
                          <DeleteOutlined />
                        </span>
                      </div>
                    ) : null}

                    <div className="col-2 text-start mt-1">
                      {zoneHeroImg ? (
                        <div className="row ">
                          <img
                            src={zoneHeroImg}
                            alt=" Uploaded Logo"
                            className="img-fluid user-img-preview"
                          />
                        </div>
                      ) : (
                        <div className="row ">
                          <img
                            src={localData?.hero_img_url}
                            alt={localData.hero_img_url ? "Uploaded Logo" : ""}
                            className="img-fluid user-img-preview"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Zone Title
                      <RedAsterick />
                    </div>
                    <div className="col-4">
                      <Input
                        name="zone_title"
                        value={localData.zone_title}
                        className={
                          validator?.current?.fields?.zonetitle === false &&
                          validator?.current?.messagesShown === true
                            ? "w-100 rounded fs-bold-14 border_red"
                            : "w-100 border-gray rounded fs-bold-14"
                        }
                        placeholder="Enter a Title Here"
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "zone_title")
                        }
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {validator.current.message(
                        "zonetitle",
                        localData.zone_title,
                        "required|text",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Zone Description
                    </div>
                    <div className="col-4">
                      <TextArea
                        rows={4}
                        className="w-100 fs-bold-14"
                        name="zone_description"
                        value={localData.zone_description}
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "zone_description")
                        }
                        placeholder="Enter a Description"
                        maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">Interaction</h6>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Text Chat
                    </div>
                    <div className="col-4">
                      <Switch
                        disabled={localData.is_message_board_enable}
                        checked={localData.is_text_chat_enable}
                        name="is_text_chat_enable"
                        onChange={(checked) => {
                          if (restricedFields.chart_boards !== 0) {
                            handleFieldsChange(checked, "is_text_chat_enable");
                          } else {
                            toast.error("Restricted in Event Tier Settings");
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      1-1 Video Call
                    </div>
                    <div className="col-4">
                      <Switch
                        checked={localData.is_one_to_one_video_calls_enable}
                        name="is_one_to_one_video_calls_enable"
                        onChange={(checked) => {
                          if (restricedFields.new_video_rep !== 0) {
                            handleFieldsChange(
                              checked,
                              "is_one_to_one_video_calls_enable"
                            );
                          } else {
                            toast.error("Restricted in Event Tier Settings");
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Select Survey
                    </div>
                    <div className="col-4">
                      <Select
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Survey"
                        optionFilterProp="children"
                        value={localData?.survey_id}
                        name="survey_id"
                        className="fs-bold-14"
                        onChange={(val) => handleFieldsChange(val, "survey_id")}
                      >
                        {surveyList &&
                          surveyList.map((elem) => (
                            <>
                              <Option value={elem.survey_id}>
                                {elem.survey_title}
                              </Option>
                            </>
                          ))}
                      </Select>
                    </div>
                    <div className="col-4">
                      <Button
                        onClick={() => {
                          setIsSurveyModalVisible(true);
                        }}
                      >
                        + Create New Survey
                      </Button>
                    </div>
                  </div>
                  <Modal
                    title="Add Survey"
                    width={1100}
                    visible={isSurveyModalVisible}
                    onCancel={() => setIsSurveyModalVisible(false)}
                    footer={null}
                  >
                    <div className="p-2">
                      <AddNewSurvey
                        event_id={localData?.event_id}
                        isFromZone={true}
                        onCancelFromModal={() => setIsSurveyModalVisible(false)}
                        onSuccessFromModal={async (eventId) => {
                          let surveyParams = {
                            event_id: localData?.event_id
                              ? localData?.event_id
                              : eventId,
                            is_brandzone_survey: true,
                            timezone:
                              Intl.DateTimeFormat().resolvedOptions().timeZone,
                            current_timestamp: moment().format("YYYY-MM-DD"),
                          };

                          ApiCall(
                            "get",
                            null,
                            "/admin/survey/get",
                            surveyParams
                          )
                            .then((response) => {
                              setSurveyList(response?.data?.data?.rows);
                              setIsSurveyModalVisible(false);
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    </div>
                  </Modal>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      On Booth Leads
                    </div>
                    <div className="col-4">
                      <Switch
                        checked={localData.is_on_booth_leads_enable}
                        name="is_on_booth_leads_enable"
                        onChange={(checked) => {
                          if (restricedFields.on_booth_leads !== 0) {
                            handleFieldsChange(
                              checked,
                              "is_on_booth_leads_enable"
                            );
                          } else {
                            toast.error("Restricted in Event Tier Settings");
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Video Demo Room
                    </div>
                    <div className="col-4">
                      <Switch
                        checked={localData.is_video_demo_room_enable}
                        name="is_video_demo_room_enable"
                        onChange={(checked) => {
                          if (restricedFields.open_demo_rooms !== 0) {
                            handleFieldsChange(
                              checked,
                              "is_video_demo_room_enable"
                            );
                          } else {
                            toast.error("Restricted in Event Tier Settings");
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                    >
                      Chat Bot
                    </div>
                    <div className="col-4">
                      <Switch
                        checked={localData.is_text_bot_enable}
                        name="is_text_bot_enable"
                        onChange={(checked) => {
                          if (restricedFields.chat_bots !== 0) {
                            handleFieldsChange(checked, "is_text_bot_enable");
                          } else {
                            toast.error("Restricted in Event Tier Settings");
                          }
                        }}
                      />
                    </div>
                  </div>
                  {localData.is_text_bot_enable ? (
                    <>
                      <Divider />
                      <div className="row my-3">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20">
                            Chat Bot Settings
                          </h6>
                        </div>
                      </div>
                      <b>
                        A Chat Bot can be enabled for this product zone for set
                        dates / times
                      </b>
                      <br />
                      <b>1.Select the days you want the chatbot on for</b>
                      <br />
                      <b>
                        2.Select the times that the chatbot will be running for
                        each of the days selected
                      </b>
                      <div className="row mt-4  pb-4">
                        <div className="col-9 event-date-scroll-div">
                          <Collapse
                            bordered={false}
                            defaultActiveKey={["2"]}
                            expandIcon={({ isActive }) =>
                              (isActive && (
                                <MinusOutlined
                                  className="rounded minus-logo"
                                  style={{}}
                                />
                              )) || (
                                <PlusOutlined className="rounded plus-logo" />
                              )
                            }
                            className="site-collapse-custom-collapse"
                          >
                            {chatBotDetails &&
                              chatBotDetails.map((ele, index) => {
                                return (
                                  <>
                                    <Panel
                                      header={
                                        <span>
                                          <span className="fw-bold">
                                            Shows Days/Times - Block
                                            {index + 1}
                                          </span>
                                          <span
                                            className="font-pink mx-2
                                    "
                                          >
                                            {"[ " +
                                              moment(ele.start_date).format(
                                                "DD-MM-YYYY"
                                              ) +
                                              " - " +
                                              moment(ele.end_date).format(
                                                "DD-MM-YYYY"
                                              ) +
                                              " , " +
                                              moment(ele.start_time).format(
                                                "HH:mm"
                                              ) +
                                              " - " +
                                              moment(ele.end_time).format(
                                                "HH:mm"
                                              ) +
                                              " ]"}
                                          </span>
                                        </span>
                                      }
                                      key={index}
                                      className="site-collapse-custom-panel"
                                    >
                                      <div className="row mt-4">
                                        <div className="col-3 text-end">
                                          <label className="text-secondary mt-1">
                                            Start & End Time
                                          </label>
                                        </div>
                                        <div className="col-6 d-flex m-0">
                                          <TimePicker
                                            allowClear={false}
                                            showTime
                                            className=""
                                            name="temp_startDate"
                                            value={ele?.start_time}
                                            format="HH:mm"
                                            onSelect={(val) => {
                                              handlechatbotArrChange(
                                                val,
                                                index,
                                                "start_time"
                                              );
                                            }}
                                            onChange={(val) => {
                                              handlechatbotArrChange(
                                                val,
                                                index,
                                                "start_time"
                                              );
                                            }}
                                            getPopupContainer={(
                                              triggerNode
                                            ) => {
                                              return triggerNode.parentNode;
                                            }}
                                          />
                                          <div style={{ marginLeft: "5px" }}>
                                            <TimePicker
                                              allowClear={false}
                                              showTime
                                              className=""
                                              format="HH:mm"
                                              disabledTime={() =>
                                                getDisabledTime(ele?.start_time)
                                              }
                                              getPopupContainer={(
                                                triggerNode
                                              ) => {
                                                return triggerNode.parentNode;
                                              }}
                                              value={ele?.end_time}
                                              onSelect={(val) => {
                                                if (
                                                  moment(val) <
                                                  moment(
                                                    ele.start_time,
                                                    "HH:mm"
                                                  )
                                                ) {
                                                  handlechatbotArrChange(
                                                    ele?.end_time,
                                                    index,
                                                    "end_time"
                                                  );
                                                  toast.error(
                                                    "Please select correct time"
                                                  );
                                                } else
                                                  handlechatbotArrChange(
                                                    val,
                                                    index,
                                                    "end_time"
                                                  );
                                              }}
                                              onChange={(val) => {
                                                if (
                                                  moment(val) <
                                                  moment(
                                                    ele.start_time,
                                                    "HH:mm"
                                                  )
                                                ) {
                                                  handlechatbotArrChange(
                                                    ele?.end_time,
                                                    index,
                                                    "end_time"
                                                  );
                                                  toast.error(
                                                    "Please select correct time"
                                                  );
                                                } else
                                                  handlechatbotArrChange(
                                                    val,
                                                    index,
                                                    "end_time"
                                                  );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-3 text-end"></div>
                                        <div className="col-3 d-flex m-0"></div>
                                        <div className="col-3 d-flex m-0"></div>
                                      </div>
                                    </Panel>
                                  </>
                                );
                              })}
                          </Collapse>
                        </div>
                      </div>
                      <div className="d-flex">
                        <span className="">Bot Responses</span>
                        <div
                          className="ms-2 text-decoration-underline fs-pink cursor-pointer"
                          onClick={() => {
                            window.location.href = STATIC_URLS.default_chatbot;
                          }}
                        >
                          Click Here To Download Our Template
                        </div>
                      </div>
                      <div className="d-flex mt-4">
                        <span className="align-self-center mx-2">
                          Chat Bot
                          <RedAsterick />
                        </span>
                        <FileUpload
                          value=""
                          labelClassName="rounded border-pink btn-svg  px-3 py-2 ms-3"
                          labelTitle="File Upload"
                          name="chatbotUpload"
                          inputType="file"
                          inputOnChange={(e) => {
                            setChatBotFileUpload(e.target.files[0]);
                            setChatBotValidation({ name: "dummy" });
                          }}
                          accept={true}
                          inputId="chatbot-upload"
                          inputName="chatbotUpload"
                        />
                      </div>
                      <div
                        className="ms-2"
                        style={{ paddingLeft: "65px", paddingTop: "10px" }}
                      >
                        {chatBotFileUpload ? (
                          <>
                            <span className="">{chatBotFileUpload.name}</span>
                            <span
                              onClick={() => {
                                setChatBotFileUpload(null);
                                setChatBotValidation(null);
                                fetchedZoneData.zone_chat_bot_file_name = "";
                              }}
                            >
                              <DeleteOutlined />
                            </span>
                          </>
                        ) : (
                          <span>
                            {fetchedZoneData?.zone_chat_bot_file_name}
                          </span>
                        )}
                      </div>
                    </>
                  ) : null}
                  <div style={{ paddingLeft: "80px" }}>
                    {validator.current.message(
                      "chatbot",
                      chatBotValidation,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                  <Divider />
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">
                        Externals & Social Links
                      </h6>
                    </div>
                  </div>
                  N8: If you paste your details into these boxes then anyone
                  using site will be able to view/contact you via these platform
                  <div className="social-media-div border-bottom-gray pb-4 mb-4">
                    {socialMediaList &&
                      socialMediaList.map((data, idx) => (
                        <div className="row mt-4 ">
                          <div className="col-2 text-end ">
                            {data?.social_media}
                          </div>
                          <div className={Tablet ? "col-2" : "col-1 "}>
                            <Switch
                              checked={
                                localData?.zones_social_media_handles_details?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? true
                                  : false
                              }
                              onChange={(checked) =>
                                onHandleSocialSwitchChange(checked, idx)
                              }
                            />
                          </div>
                          <div className="col-4 text-start ">
                            <Input
                              placeholder="https://webaddress.com"
                              className="w-100 fs-bold-14"
                              disabled={
                                localData?.zones_social_media_handles_details?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? false
                                  : true
                              }
                              value={
                                localData?.zones_social_media_handles_details?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? localData
                                      .zones_social_media_handles_details[
                                      localData.zones_social_media_handles_details.findIndex(
                                        (social_media) =>
                                          social_media.social_media_id ===
                                          data.social_media_id
                                      )
                                    ]["social_media_url"]
                                  : ""
                              }
                              onChange={(event) =>
                                socialMediaHandleChange(idx, event)
                              }
                              onBlur={() =>
                                validator.current.showMessageFor("social_media")
                              }
                            />
                            {validator.current.message(
                              "social_media",
                              localData?.zones_social_media_handles_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                                ? localData?.zones_social_media_handles_details[
                                    localData?.zones_social_media_handles_details.findIndex(
                                      (social_media) =>
                                        social_media.social_media_id ===
                                        data.social_media_id
                                    )
                                  ]["social_media_url"]
                                : "",
                              localData?.zones_social_media_handles_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                                ? "required|url"
                                : "",
                              { className: "text-danger py-1" }
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                  <section>
                    <div className="row">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20 px-2">
                          Assign Agents to this Zone
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4 mb-4">
                      <div className="col-2 text-end mt-4">
                        <span className="text-secondary mt-4">Agents</span>
                      </div>
                      <div
                        className={Tablet ? "col-5" : "col-4"}
                        id="area"
                        style={{ position: "relative" }}
                      >
                        <label className="py-2 mx-2">
                          <b>All Agents</b>
                        </label>
                        <br />
                        <Select
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          dropdownAlign={{ offset: [0, 4] }}
                          showSearch
                          dropdownClassName="agent-dropdown"
                          style={{ width: Tablet ? 150 : 300, zIndex: "100" }}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          value="All Agents"
                          open={openUserList}
                          onFocus={() => setOpenUsersList(true)}
                          onBlur={() => setOpenUsersList(false)}
                          onSearch={() => setOpenUsersList(true)}
                          onChange={handleAgentsChange}
                          listItemHeight={4}
                          listHeight={130}
                          className="border border-gray fs-bold-14"
                        >
                          {agents ? (
                            agents?.map((data, key) => (
                              <Option value={data?.user_id}>
                                {data?.first_name} {data?.last_name}
                              </Option>
                            ))
                          ) : (
                            <span disabled>
                              No agents created for this zone
                            </span>
                          )}
                        </Select>
                      </div>
                      <div
                        className={Tablet ? "col-5" : "col-4"}
                        id="area1"
                        style={{ position: "relative" }}
                      >
                        <label className="py-2 mx-2">
                          <b>Tagged Agents</b>
                        </label>
                        <br />
                        <Select
                          getPopupContainer={() =>
                            document.getElementById("area1")
                          }
                          showSearch
                          dropdownClassName="agent-dropdown"
                          style={{ width: Tablet ? 150 : 300 }}
                          placeholder="Search Agents"
                          optionFilterProp="children"
                          value="Tagged Agents"
                          open={openUserList}
                          onFocus={() => setOpenUsersList(true)}
                          onBlur={() => setOpenUsersList(false)}
                          onSearch={() => setOpenUsersList(true)}
                          listItemHeight={4}
                          listHeight={130}
                          className="border border-gray  fs-bold-14"
                        >
                          {selectedAgents?.map((data) => (
                            <Option value={data.user_id}>
                              {data.first_name} {data.last_name}
                              <span className="float-end">
                                <CloseOutlined
                                  className="float-right text-danger mr-0"
                                  onClick={() =>
                                    handleSelectedAgentsChange(data.user_id)
                                  }
                                />
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="row mt-4 border-bottom-gray pb-4">
                      <div className="col-2"></div>
                      <div className="col-4">
                        <Button
                          className="px-3  rounded btn-svg"
                          onClick={(e) => {
                            setIsAddUserModalVisible(true);
                          }}
                        >
                          <AddGroup fill="#ef0855" />
                          <span className="mx-2">Add Agents</span>
                        </Button>
                        <Modal
                          title="Add User"
                          visible={isAddUserModalVisible}
                          onOk={() => setIsAddUserModalVisible(false)}
                          onCancel={() => setIsAddUserModalVisible(false)}
                          footer={null}
                          width={1000}
                        >
                          <AddUser
                            event_id={localData?.event_id}
                            brand_id={localData?.brand_id}
                            onCancelFromModal={() =>
                              setIsAddUserModalVisible(false)
                            }
                            onSuccessFromModal={() => {
                              setIsAddUserModalVisible(false);
                            }}
                          />
                        </Modal>
                        <Button
                          className={
                            "px-3  rounded btn-svg" +
                            (Tablet ? " mt-2" : " mx-2")
                          }
                          onClick={() =>
                            window.open(
                              `/brandCalendar/${localData?.event_id}/${localData.brand_id}`,
                              "_blank"
                            )
                          }
                        >
                          <CalenderGrey />
                          <span className="mx-2">Staffing Calendar</span>
                        </Button>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="row mt-4">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">Assets</h6>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className={Tablet ? "col-12" : "col-2"}>
                        <Button
                          className="px-3 button-pink rounded"
                          onClick={showModal}
                        >
                          + Add New Asset
                        </Button>
                      </div>
                      {assetsArray ? (
                        <div
                          className={
                            Tablet
                              ? "col-12 assets-scroll-div "
                              : "col-4 assets-scroll-div"
                          }
                        >
                          {assetsArray &&
                            assetsArray.map((elem, index) => (
                              <>
                                <div
                                  className={Tablet ? "mt-4" : " py-2"}
                                  onClick={() => setViewVisible(elem)}
                                >
                                  <div className="row border-gray rounded py-1">
                                    <div className="col-1 ">
                                      <Cube fill="#000" />
                                    </div>
                                    <div className="col-11">
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <span className="fs-18 mx-2">
                                            <b>{elem.asset_name}</b>
                                          </span>
                                        </div>
                                        <div>
                                          <span
                                            className="px-3 "
                                            onClick={() => {
                                              handleAssetsUpdate(elem, index);
                                            }}
                                          >
                                            <EditRed fill="#000" />
                                          </span>
                                          <span
                                            className="px-3 "
                                            onClick={async () => {
                                              let temp_asset = assetsArray;
                                              temp_asset.splice(index, 1);
                                              await setAssetsArray(temp_asset);
                                              await setViewVisible(false);
                                            }}
                                          >
                                            <Bin fill="#000" />
                                          </span>
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <span className="fs-16 mx-2">
                                          {elem.asset_description}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      ) : null}
                      {viewVisible || !assetsArray ? (
                        <DetailComponent assetData={viewVisible} />
                      ) : null}
                    </div>
                  </section>
                </div>
                <div
                  className="shadow-sm p-3 d-flex justify-content-between"
                  style={{ backgroundColor: "#eeeeee" }}
                >
                  {props.onCancelFromModal ? (
                    <Button
                      onClick={() => props.onCancelFromModal()}
                      className="rounded  px-3"
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Link to="/zonemanagement">
                      <Button className="px-3 d-block button-pink rounded">
                        Cancel
                      </Button>
                    </Link>
                  )}
                  <Button type="primary" onClick={handleEditZone}>
                    Edit Zone
                  </Button>
                </div>
              </div>
            )}
          </form>
        )}

        {accessDenied === true && (
          <Link to="/zonemanagement">
            <Button className="px-3 d-block button-pink rounded mt-2">
              Cancel
            </Button>
          </Link>
        )}
        <Modal
          title="Add New Assets"
          footer={false}
          visible={isModalVisible}
          onCancel={handleCancel}
          style={{ zIndex: "111111111111111111" }}
        >
          <div className="container px-5">
            <Formik
              initialValues={{
                asset_file_type: "",
                asset_file_name: "",
                asset_name: "",
                asset_description: "",
                is_active: true,
                is_downloadable: true,
                max_no_of_downloads: 1,
                total_downloads: 0,
                whitelisted_domains: 0,
                blacklisted_domains: 0,
                view_count: 0,
                uploadObj: null,
                webviewer_url: "",
                is_webviewer: false,
              }}
              validationSchema={Yup.object().shape({
                asset_name: Yup.string().required("Please enter name"),
                asset_description: Yup.string().required(
                  "Please enter description"
                ),
              })}
              enableReinitialize={true}
              onSubmit={(values, action) => {
                if (values?.is_webviewer) {
                  if (isValidHttpURL(values?.webviewer_url)) {
                    addAsset(values);
                    action.resetForm();
                  } else {
                    toast.error("URL is Not Valid");
                  }
                } else {
                  addAsset(values);
                  action.resetForm();
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                resetForm,
                /* and other goodies */
              }) => (
                <form>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">3d</div>
                    <div className="col-7">
                      <Switch
                        name="is_webviewer"
                        checked={values?.is_webviewer}
                        onChange={(val) => {
                          setFieldValue("is_webviewer", val);
                        }}
                      />
                    </div>
                  </div>

                  {values?.is_webviewer ? (
                    <div className="row mt-2">
                      <div className="col-5 pt-2 text-end">Asset Url</div>
                      <div className="col-7">
                        <Input
                          name="webviewer_url"
                          value={values.webviewer_url}
                          onChange={(e) => {
                            setFieldValue("webviewer_url", e.target.value);
                            setFieldValue("asset_file_type", "web/3d");
                          }}
                        />
                        {errors.webviewer_url && touched.webviewer_url ? (
                          <div className="text-danger">
                            {errors.webviewer_url}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-2">
                      <div className="col-5 pt-2 text-end">Assets File</div>

                      <div className="col-7 image-upload">
                        <FileUpload
                          value=""
                          labelClassName="rounded border-pink btn-svg px-3 py-1"
                          labelTitle="File Upload"
                          inputType="file"
                          inputOnChange={(e) => {
                            setFieldValue("uploadObj", e.target.files[0]);
                            setFieldValue(
                              "asset_file_name",
                              e.target.files[0].name
                            );
                            setFieldValue(
                              "asset_file_type",
                              e.target.files[0].type
                            );
                          }}
                          inputName="uploadObj"
                          inputId="pree"
                        />
                        {errors.uploadObj &&
                        touched.uploadObj &&
                        !values.uploadObj ? (
                          <div className="text-danger">{errors.uploadObj}</div>
                        ) : null}
                        {values.uploadObj ? (
                          <>
                            <span className="mx-3">
                              {values.uploadObj.name}
                            </span>
                            <span
                              onClick={() => {
                                setFieldValue("uploadObj", null);
                              }}
                            >
                              <DeleteOutlined />
                            </span>
                          </>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets Name</div>
                    <div className="col-7">
                      <Input
                        name="asset_name"
                        value={values.asset_name}
                        onChange={handleChange}
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.asset_name && touched.asset_name ? (
                        <div className="text-danger">{errors.asset_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">
                      Assets Description
                    </div>
                    <div className="col-7">
                      <TextArea
                        name="asset_description"
                        value={values.asset_description}
                        onChange={handleChange}
                      />
                      {errors.asset_description && touched.asset_description ? (
                        <div className="text-danger">
                          {errors.asset_description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Active</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_active}
                        name="is_active"
                        onChange={() =>
                          setFieldValue("is_active", !values.is_active)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Downloadable</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_downloadable}
                        name="is_downloadable"
                        onChange={(checked) => {
                          setFieldValue(
                            "is_downloadable",
                            !values.is_downloadable
                          );
                          checked && setFieldValue("max_no_of_downloads", 1);
                        }}
                      />
                    </div>
                  </div>
                  {values.is_downloadable && (
                    <div className="row mt-2">
                      <div className="col-5  text-end">Max No. of download</div>
                      <div className="col-7">
                        <Input
                          value={values.max_no_of_downloads}
                          type="number"
                          name="max_no_of_downloads"
                          onKeyDown={handleCommonKeyDown}
                          onChange={(e) => {
                            if (isValidNumber(e?.target?.value)) {
                              handleChange(e);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Whitelist</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        value={values.whitelisted_domains}
                        name="whitelisted_domains"
                        onKeyDown={handleCommonKeyDown}
                        onChange={(e) => {
                          if (isValidNumber(e?.target?.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Blacklist</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        value={values.blacklisted_domains}
                        name="blacklisted_domains"
                        onKeyDown={handleCommonKeyDown}
                        pattern="^[0-9]"
                        onChange={(e) => {
                          if (isValidNumber(e?.target?.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row py-4 mt-2">
                    <div className="col-6  text-end">
                      <Button
                        onClick={() => {
                          resetForm();
                          handleCancel();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button type="primary" onClick={handleSubmit}>
                        Add Assets
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
        <Modal
          title="Update Assets"
          footer={false}
          visible={isUpdateModalVisible}
          // onOk={handleOk}
          onCancel={() => {
            setIsUpdateModalVisible(false);
          }}
          style={{ zIndex: "111111111111111111" }}
        >
          <div className="container px-5">
            <Formik
              initialValues={{
                zone_asset_id: updateAssetValue?.zone_asset_id,
                asset_file_type: updateAssetValue?.asset_file_type,
                asset_file_name: updateAssetValue?.asset_file_name,
                asset_name: updateAssetValue?.asset_name,
                asset_description: updateAssetValue?.asset_description,
                is_active: updateAssetValue?.is_active,
                is_downloadable: updateAssetValue?.is_downloadable,
                max_no_of_downloads: updateAssetValue?.max_no_of_downloads,
                whitelisted_domains: updateAssetValue?.whitelisted_domains,
                blacklisted_domains: updateAssetValue?.blacklisted_domains,
                uploadObj: updateAssetValue?.uploadObj,
                tempIndex: updateAssetValue?.arrIndex,
                asset_file_url: assetsArray?.asset_file_url,
                webviewer_url: updateAssetValue?.webviewer_url,
                is_webviewer: updateAssetValue?.is_webviewer,
              }}
              validationSchema={Yup.object().shape({
                asset_name: Yup.string().required("Please enter name"),
                asset_description: Yup.string().required(
                  "Please enter description"
                ),
              })}
              enableReinitialize={true}
              onSubmit={(values, action) => {
                if (values?.is_webviewer) {
                  if (isValidHttpURL(values?.webviewer_url)) {
                    updateAsset(values);
                    action.resetForm();
                  } else {
                    toast.error("URL is Not Valid");
                  }
                } else {
                  updateAsset(values);
                  action.resetForm();
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">3d</div>
                    <div className="col-7">
                      <Switch
                        name="is_webviewer"
                        checked={values?.is_webviewer}
                        onChange={(val) => {
                          setFieldValue("is_webviewer", val);
                        }}
                      />
                    </div>
                  </div>

                  {values?.is_webviewer ? (
                    <div className="row mt-2">
                      <div className="col-5 pt-2 text-end">Asset Url</div>
                      <div className="col-7">
                        <Input
                          name="webviewer_url"
                          value={values.webviewer_url}
                          onChange={(e) => {
                            setFieldValue("webviewer_url", e.target.value);
                            setFieldValue("asset_file_type", "web/3d");
                          }}
                        />
                        {errors.webviewer_url && touched.webviewer_url ? (
                          <div className="text-danger">
                            {errors.webviewer_url}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-2">
                      <div className="col-5 pt-2 text-end">Assets File</div>

                      <div className="col-7 image-upload">
                        <FileUpload
                          value=""
                          labelClassName="rounded border-pink btn-svg px-3 py-1"
                          labelTitle="File Upload"
                          inputType="file"
                          inputOnChange={(e) => {
                            setFieldValue("uploadObj", e.target.files[0]);
                            setFieldValue(
                              "asset_file_type",
                              e.target.files[0].type
                            );
                            setFieldValue(
                              "asset_file_name",
                              e.target.files[0].name
                            );
                          }}
                          inputId="preeEdit"
                          inputName="uploadObj"
                        />
                        {values.uploadObj ? (
                          <>
                            <span className="mx-3">
                              {values.uploadObj.name}
                            </span>
                            <span
                              onClick={() => {
                                setFieldValue("uploadObj", null);
                              }}
                            >
                              <DeleteOutlined />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="mx-3">
                              {updateAssetValue?.original_file_name}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets Name</div>
                    <div className="col-7">
                      <Input
                        name="asset_name"
                        value={values.asset_name}
                        onChange={handleChange}
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.asset_name && touched.asset_name ? (
                        <div className="text-danger">{errors.asset_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">
                      Assets Description
                    </div>
                    <div className="col-7">
                      <TextArea
                        name="asset_description"
                        value={values.asset_description}
                        onChange={handleChange}
                        maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                      />
                      {errors.asset_description && touched.asset_description ? (
                        <div className="text-danger">
                          {errors.asset_description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Active</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_active}
                        name="is_active"
                        onChange={() =>
                          setFieldValue("is_active", !values.is_active)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Downloadable</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_downloadable}
                        name="is_downloadable"
                        onChange={(checked) => {
                          setFieldValue(
                            "is_downloadable",
                            !values.is_downloadable
                          );
                          checked && setFieldValue("max_no_of_downloads", 1);
                        }}
                      />
                    </div>
                  </div>
                  {values.is_downloadable && (
                    <div className="row mt-2">
                      <div className="col-5  text-end">Max No. of download</div>
                      <div className="col-7">
                        <Input
                          type="number"
                          name="max_no_of_downloads"
                          value={values.max_no_of_downloads}
                          onKeyDown={handleCommonKeyDown}
                          onChange={(e) => {
                            if (isValidNumber(e?.target?.value)) {
                              handleChange(e);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Whitelist</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="whitelisted_domains"
                        value={values.whitelisted_domains}
                        onKeyDown={handleCommonKeyDown}
                        onChange={(e) => {
                          if (isValidNumber(e?.target?.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Blacklist</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        onKeyDown={handleCommonKeyDown}
                        name="blacklisted_domains"
                        value={values.blacklisted_domains}
                        onChange={(e) => {
                          if (isValidNumber(e?.target?.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row py-4 mt-2">
                    <div className="col-6  text-end">
                      <Button onClick={() => setIsUpdateModalVisible(false)}>
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button type="primary" onClick={handleSubmit}>
                        Update Assets
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      </>
    );
};

export default EditZone;
