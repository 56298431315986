import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  TimePicker,
  Collapse,
  Modal,
} from "antd";
import "./Zone.css";
import { MinusOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import {
  IMAGE_FILE_BASIC_PROPS,
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
  TIME_FORMAT,
} from "../../components/constsnts/common.constants";
import { useMediaQuery } from "react-responsive";
import { EditRed } from "../../components/svgJS/EditRed";
import { Bin } from "../../components/svgJS/Bin";
import { Cube } from "../../components/svgJS/Cube";
import { ApiCall } from "../../utils/api";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addZone, fetchEventShow } from "../../redux/slice/zoneManagementSlice";
import { toast } from "react-toastify";
import { DeleteOutlined } from "@ant-design/icons";
import FileUpload from "../common/FileUpload";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { cloneDeep } from "lodash";
import AddNewSurvey from "../surveyManagement/AddNewSurvey";
import { getSurveys, getUserTypeAgents } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { STATIC_URLS } from "../../components/constsnts/common.constants";
import {
  compareAllStartEndTimes,
  createISOTimeString,
  createTZString,
  getCookie,
  getDisabledTime,
  isRouteExistInMenu,
  isValidHttpURL,
  handleCommonKeyDown,
  isValidNumber,
} from "../../utils/commonFunctions";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { orderBy } from "lodash-es";

const CreateNewZone = (props) => {
  var CryptoJS = require("crypto-js");
  const [forceUpdate, setForceUpdate] = useState(false);
  const { eventShow } = useSelector((state) => state.zoneManagement);
  const validator = useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const assetCreateUploadRef = useRef();
  const user_name = getCookie("user_name");
  var bytes = CryptoJS.AES.decrypt(user_name, "user_name");
  var decryptedName = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const { Option } = Select;
  const { TextArea } = Input;
  const { Panel } = Collapse;

  const [userType, setUserType] = useState("");
  const [surveyList, setSurveyList] = useState(null);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [zoneHeroImg, setZoneHeroImg] = useState(null);
  const [eventList, setEventList] = useState(null);
  const [brandList, setBrandList] = useState(null);
  const [boothLocationList, setBoothLocation] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [updateAssetValue, setUpdateAssetValue] = useState(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);
  const [chatBotFileUpload, setChatBotFileUpload] = useState(null);
  const [preEventId, setPreEventId] = useState(null);
  const [preBrandId, setPreBrandId] = useState(null);
  const [preBrandName, setPreBrandName] = useState(null);
  const [assetsArray, setAssetsArray] = useState([]);
  const [chatbotValidation, setChatbotValidation] = useState({
    dummy: "validation",
  });
  const [chatBotDetails, setChatBotDetails] = useState([]);
  const [localData, setLocalData] = useState({
    event_id: props?.event_id ?? null,
    brand_id: null,
    brand_name: null,
    booth_location_id: null,
    is_active: true,
    zone_title: "",
    zone_description: "",
    website_url: null,
    is_text_chat_enable: false,
    is_message_board_enable: false,
    is_moderated: false,
    is_one_to_one_video_calls_enable: false,
    survey_id: null,
    is_on_booth_leads_enable: false,
    is_video_rep_enable: false,
    is_video_demo_room_enable: false,
    is_text_bot_enable: false,
    is_individual: false,
    zone_agents: selectedAgents,
    zone_chat_bot_setting_details: chatBotDetails,
    zones_social_media_handles_details: [],
    zones_assets_details: assetsArray,
    zone_hero_img: null,
    chatbotUpload: null,
    temp_startDate: null,
    temp_endDate: null,
  });
  const [surveyModal, setSurveyModal] = useState(false);
  const [brand_id, set_brand_id] = useState(null);
  const [brandTier, setBrandTier] = useState(null);
  const [hero_thmbnail, set_hero_thmbnail] = useState(null);
  const [openUserList, setOpenUsersList] = useState(false);

  useEffect(async () => {
    setZoneLoading(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      setUserType(decryptedData);
      getEventsList();
      getBoothLocation();
      getSurveyList();
      getSocialMediaList();
      getBrandsList();
      setTimeout(() => {
        setZoneLoading(false);
      }, 2000);
    }
  }, [CryptoJS.AES]);

  useEffect(async () => {
    //To display Agents list depending upon Event and Brand
    addChatbot(localData?.event_id);
    getBrandsList(localData?.event_id);
    getBoothLocation();
    if (localData?.event_id) {
      const agentResponse = await getUserTypeAgents({
        event_id: localData?.event_id ?? preEventId,
        brand_id: localData?.brand_id ?? brand_id,
      });
      if (agentResponse) {
        setAgents(agentResponse);
        setSelectedAgents([]);
      }
    }
    callRestrictedAPI();
    getSurveyList();
  }, [localData?.event_id, localData?.brand_id, brandTier]);

  useEffect(() => {
    let tempArr = [];
    eventShow?.forEach((ele) => {
      const start_date_time = createTZString(ele.start_date_time);
      const end_date_time = createTZString(ele.end_date_time);
      const eleStartDate = moment(
        start_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      const eleEndDate = moment(
        end_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      tempArr.push({
        start_time: moment(), //current time by default for zone or event ka display karna hai??
        end_time: moment(),
        start_date: eleStartDate.format("YYYY-MM-DD"),
        end_date: eleEndDate.format("YYYY-MM-DD"),
        show_id: ele?.show_id,
      });
    });
    setChatBotDetails(tempArr);
  }, [eventShow]);

  useEffect(() => {
    if (preEventId && preBrandId) {
      setLocalData({
        ...localData,
        event_id: preEventId,
        brand_id: preBrandId,
        brand_name: preBrandName,
      });
    } else if (preEventId) {
      setLocalData({
        ...localData,
        event_id: preEventId,
      });
    }
  }, [preEventId, preBrandId, preBrandName]);

  const getEventsList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        setEventList(rows);
        if (rows.length === 1) {
          setPreEventId(rows[0]?.event_id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getBrandsList = (val) => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: val
        ? val
        : props?.event_id
        ? props?.event_id
        : localData.event_id,
    })
      .then((response) => {
        const { rows } = response.data.data;
        setBrandList(rows);
        if (rows.length === 1) {
          setPreBrandId(rows[0].brand_id);
          setPreBrandName(rows[0].brand_title);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getBoothLocation = () => {
    ApiCall("get", null, "/admin/boothlocations/get", {
      brand_id: localData?.brand_id ?? brand_id,
    })
      .then((response) => {
        setBoothLocation(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const surveyParams = {
    event_id: localData?.event_id ? localData?.event_id : null,
    is_brandzone_survey: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    current_timestamp: moment().format("YYYY-MM-DD"),
  };
  const getSurveyList = () => {
    ApiCall("get", null, "/admin/survey/get", surveyParams)
      .then((response) => {
        setSurveyList(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onHandleSocialSwitchChange = (checked, idx) => {
    let social_media_list = socialMediaList;
    let zones_social_media_handles_details =
      localData.zones_social_media_handles_details;
    let isAvailable = zones_social_media_handles_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (checked) {
      zones_social_media_handles_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url:
          idx === 0
            ? "https://www.instagram.com/" //CR point -> To add prefix when toggle is on
            : idx === 1
            ? "https://www.linkedin.com/"
            : idx === 2
            ? "https://twitter.com/"
            : idx === 3
            ? "https://www.facebook.com/"
            : "",
      });
    } else {
      let index = zones_social_media_handles_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      zones_social_media_handles_details.splice(index, 1);
    }
    setLocalData({
      ...localData,
      zones_social_media_handles_details: zones_social_media_handles_details,
    });
  };

  function handleAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);

    let finalSelectedAgents = orderBy(selectedAgents_list, "first_name", "ase");

    let finalAgents = orderBy(agents_list, "first_name", "ase");

    setSelectedAgents(finalSelectedAgents);
    setAgents(finalAgents);
  }

  function handleSelectedAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );

    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    let finalAgents = orderBy(agents_list, "first_name", "ase");
    let finalSelectedAgents = orderBy(selectedAgents_list, "first_name", "ase");
    setAgents(finalAgents);
    setSelectedAgents(finalSelectedAgents);
  }

  const handleFieldsChange = (val, name) => {
    if (name === "event_id") {
      setLocalData({
        ...localData,
        [name]: val,
        brand_id: null,
        booth_location_id: null,
      });
    } else if (name === "brand_id") {
      setLocalData({
        ...localData,
        [name]: val,
        booth_location_id: null,
        is_text_chat_enable: false,
        is_one_to_one_video_calls_enable: false,
        survey_id: null,
        is_on_booth_leads_enable: false,
        is_video_demo_room_enable: false,
        is_text_bot_enable: false,
      });
    } else if (name === "is_text_bot_enable") {
      if (val) {
        setChatbotValidation(null);
      } else {
        setChatbotValidation({
          dummy: "validation",
        });
      }
      setLocalData({
        ...localData,
        [name]: val,
      });
    } else setLocalData({ ...localData, [name]: val });
  };

  const handleCreateZone = async (e) => {
    e.preventDefault();
    let data_temp = localData;

    const areEventTimesProper = compareAllStartEndTimes(chatBotDetails);

    if (areEventTimesProper === "no" && localData?.is_text_bot_enable) {
      toast.error("Please re check event times");
      return;
    }

    const zone_chat_bot_setting_details = [];
    chatBotDetails?.forEach((ele) => {
      const start_date_str =
        ele.start_date +
        " " +
        moment(moment(ele.start_time, TIME_FORMAT)).format("HH:mm");
      const start_utc = createISOTimeString(start_date_str);
      const end_date_str =
        ele.end_date +
        " " +
        moment(moment(ele.end_time, TIME_FORMAT)).format("HH:mm");
      const end_utc = createISOTimeString(end_date_str);
      zone_chat_bot_setting_details.push({
        start_date_time: start_utc,
        end_date_time: end_utc,
        show_id: ele?.show_id,
      });
    });
    const agentsIdArr = selectedAgents?.map((ele) => ele.user_id);
    data_temp.zone_chat_bot_setting_details = zone_chat_bot_setting_details;
    data_temp.zone_agents = agentsIdArr;
    data_temp.zones_assets_details = assetsArray;

    if (validator.current.allValid()) {
      let allURLValid = "yes";
      if (localData && localData.zones_social_media_handles_details) {
        let clonedLinks = cloneDeep(
          localData.zones_social_media_handles_details
        );
        localData.zones_social_media_handles_details?.forEach((ele, index) => {
          if (
            ele.social_media_url === "" ||
            !isValidHttpURL(ele.social_media_url) ||
            ele.social_media_url === "https://www.instagram.com/" || //Cr Point -> User should not be able to save this if its just  https://www.instagram.com/ there should user id after the last "/".
            ele.social_media_url === "https://www.facebook.com/" ||
            ele.social_media_url === "https://twitter.com/" ||
            ele.social_media_url === "https://www.linkedin.com/"
          ) {
            clonedLinks[index]["urlValid"] = "no";
            allURLValid = "no";
          }
        });
        if (allURLValid === "no")
          setLocalData({
            ...localData,
            zones_social_media_handles_details: clonedLinks,
          });
      }

      if (allURLValid === "no") {
        toast.error("Please check social media links");
        return;
      }

      let formData = new FormData();
      formData.append("data", JSON.stringify(data_temp));
      formData.append("zone_hero_image_file", zoneHeroImg);
      assetsArray.forEach((ele, index) => {
        formData.append(`asset_${index + 1}`, ele.uploadObj);
      });
      formData.append("chat_bot_qa", chatBotFileUpload);
      setZoneLoading(true);
      const res = await dispatch(addZone(formData));

      if (res?.payload?.code === 200) {
        setZoneLoading(false);
        toast.success(res?.payload?.message);
        if (props.isFromEvent) props.onSuccessFromModal();
        else props.history.push("zonemanagement");
      } else if (res?.payload?.code !== 200) {
        toast.error(res?.payload?.message);
        setZoneLoading(false);
        return;
      }
    } else {
      validator.current.showMessages();
      setZoneLoading(false);
      setForceUpdate(!forceUpdate);
      toast.error("Fields are missing");
    }
  };

  const addChatbot = async (eventId) => {
    await dispatch(fetchEventShow({ event_id: eventId }));
  };

  const handlechatbotArrChange = (val, key, name) => {
    let chatbot_arr = chatBotDetails;
    chatbot_arr[key][[name]] = val;
    setChatBotDetails([...chatbot_arr]);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addAsset = (data) => {
    setAssetsArray([...assetsArray, data]);
    setIsModalVisible(false);
  };

  const handleAssetsUpdate = (ele, index) => {
    setUpdateAssetValue({ ...ele, arrIndex: index });
    setIsUpdateModalVisible(true);
  };

  const updateAsset = (data) => {
    const { tempIndex } = data;

    delete data.tempIndex;
    const arr = [...assetsArray];
    arr[tempIndex] = data;
    setAssetsArray(arr);
    setIsUpdateModalVisible(false);
    setViewVisible(false);
  };

  const [restricedFields, setRestricedFields] = useState({
    tier_settings_id: null,
    open_demo_rooms: null,
    new_video_rep: null,
    chart_boards: null,
    chat_bots: null,
    on_booth_leads: null,
  });

  const callRestrictedAPI = async () => {
    await ApiCall("get", null, "/admin/zone/restriction/details/get", {
      event_id: localData?.event_id,
      brand_tier: brandTier,
    })
      .then((response) => {
        let data = response?.data?.data;
        if (response?.data?.code === 200) {
          setRestricedFields({
            tier_settings_id: data?.tier_settings_id,
            open_demo_rooms: data?.open_demo_rooms,
            new_video_rep: data?.new_video_rep,
            chart_boards: data?.chart_boards,
            chat_bots: data?.chat_bots,
            on_booth_leads: data?.on_booth_leads,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const socialMediaHandleChange = (idx, event) => {
    let social_media_list = socialMediaList;
    let zones_social_media_handles_details =
      localData.zones_social_media_handles_details;
    let isAvailable = zones_social_media_handles_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (isAvailable) {
      let index = zones_social_media_handles_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );

      zones_social_media_handles_details[index]["social_media_url"] =
        event.target.value;
    } else {
      zones_social_media_handles_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: event.target.value,
      });
    }

    setLocalData({
      ...localData,
      zones_social_media_handles_details: zones_social_media_handles_details,
    });
  };

  const DetailComponent = ({ assetData }) => {
    return (
      <div className={Tablet ? "mt-4 py-2" : "col-5 py-2 mx-2"}>
        <div className="row border-gray rounded h-100 py-2">
          <div className="row">
            <div className="col-4 text-end ">Active</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_active} disabled />
            </div>
            <div className="col-3 ">Whitelist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.whitelisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end ">Downloadable</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_downloadable} disabled />
            </div>
            <div className="col-3 ">Blacklist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.blacklisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end"></div>
            <div className="col-2"></div>
            {assetData?.is_downloadable && (
              <>
                <div className="col-3 ">Max D/L's</div>
                <div className="col-2">
                  <Button className="py-1 px-3 rounded">
                    {assetData?.max_no_of_downloads}
                  </Button>
                </div>
              </>
            )}
          </div>

          <div className="row">
            <div className="col-3 text-end">Info</div>
            <div className="col-7">
              <span className="fs-12">
                Filename: {assetData?.original_file_name}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                Date: {moment(assetData?.uploaded_at).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">Uploaded By: {decryptedName}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">View Count: {assetData?.view_count}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                D/L Count: {assetData?.total_downloads}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  Yup.addMethod(Yup.string, "fileRequired", function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (value) {
      const { path, createError } = this;
      return (
        chatBotFileUpload === null ||
        createError({ path, message: errorMessage })
      );
    });
  });

  if (zoneLoading) return <CommonLoader />;
  else
    return (
      <>
        <form onSubmit={handleCreateZone}>
          {zoneLoading ? (
            <CommonLoader />
          ) : (
            <div>
              {props.isFromEvent ? null : (
                <div
                  className={
                    props?.event_id
                      ? "d-flex justify-content-between bg-light w-100"
                      : "d-flex justify-content-between bg-light w-100 common-sticky-header"
                  }
                >
                  <div>
                    <h4 className="fs-24">Create New Zone</h4>
                    <Breadcrumb className="my-2" separator=">">
                      <Breadcrumb.Item>
                        <Link to="/zonemanagement">Admin Console</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/zonemanagement">Zone Management</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/createnewzone">Add Zone</Link>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </div>
                </div>
              )}
              <div
                className={
                  props?.event_id ? "bg-white px-5 py-1" : "bg-white px-5 py-3"
                }
              >
                <div className={props?.event_id ? "d-none" : "row my-3 mt-5"}>
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">Zone Editor</h6>
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Active
                  </div>
                  <div className="col-4">
                    <Switch
                      checked={localData?.is_active}
                      name="is_active"
                      onChange={(checked) =>
                        handleFieldsChange(checked, "is_active")
                      }
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Select Event
                    <RedAsterick />
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Select Event"
                      optionFilterProp="children"
                      className={
                        validator?.current?.fields?.event === false &&
                        validator?.current?.messagesShown === true
                          ? "fs-bold-14 border_red"
                          : "fs-bold-14 "
                      }
                      name="event_id"
                      onChange={async (val) => {
                        handleFieldsChange(val, "event_id");
                      }}
                      onBlur={() =>
                        validator.current.showMessageFor("event_id")
                      }
                      listHeight={120}
                      listItemHeight={4}
                      disabled={
                        props?.event_id
                          ? true
                          : userType === "BRAND ADMIN"
                          ? true
                          : false
                      }
                      value={localData.event_id ? localData.event_id : null}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {eventList &&
                        eventList.map((data) => (
                          <Option value={data.event_id}>
                            {data.event_name}
                          </Option>
                        ))}
                    </Select>
                    {validator.current.message(
                      "event",
                      localData.event_id,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Select Brand
                    <RedAsterick />
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Select Brand"
                      className={
                        validator?.current?.fields?.brand === false &&
                        validator?.current?.messagesShown === true
                          ? "fs-bold-14 border_red"
                          : "fs-bold-14 "
                      }
                      optionFilterProp="children"
                      name="brand_id"
                      value={localData.brand_id ? localData.brand_id : null}
                      onChange={async (val) => {
                        await handleFieldsChange(val, "brand_id");
                        await set_brand_id(val);
                        await brandList.forEach((ele) => {
                          if (ele.brand_id === val) {
                            setLocalData((prev) => ({
                              ...prev,
                              brand_name: ele.brand_title,
                            }));
                            setBrandTier(ele?.brand_tier);
                          }
                        });
                      }}
                      onBlur={() =>
                        validator.current.showMessageFor("brand_id")
                      }
                      listHeight={120}
                      listItemHeight={4}
                      disabled={userType === "BRAND ADMIN" ? true : false}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {brandList &&
                        brandList.map((data) => (
                          <Option value={data.brand_id}>
                            {data.brand_title}
                          </Option>
                        ))}
                    </Select>
                    {validator.current.message(
                      "brand",
                      localData.event_id,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Location on Wall
                    <RedAsterick />
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Select Wall"
                      optionFilterProp="children"
                      className={
                        validator?.current?.fields?.booth === false &&
                        validator?.current?.messagesShown === true
                          ? "fs-bold-14 border_red"
                          : "fs-bold-14 "
                      }
                      name="booth_location_id"
                      disabled={!localData?.is_active}
                      onChange={(val) =>
                        handleFieldsChange(val, "booth_location_id")
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("booth_location_id")
                      }
                      listHeight={120}
                      listItemHeight={4}
                      value={
                        localData.booth_location_id
                          ? localData.booth_location_id
                          : null
                      }
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {boothLocationList &&
                        boothLocationList.map((data) => (
                          <Option value={data.booth_location_id}>
                            {data.booth_location}
                          </Option>
                        ))}
                    </Select>
                    {validator.current.message(
                      "booth",
                      localData.booth_location_id,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Zone Hero Image
                  </div>
                  <div
                    className={
                      Tablet ? "col-4 image-upload" : "col-2 image-upload"
                    }
                  >
                    <FileUpload
                      value=""
                      labelClassName="rounded border-pink btn-svg px-3 py-1"
                      labelTitle="Image Upload"
                      inputType="file"
                      inputOnChange={(e) => {
                        if (
                          IMAGE_FILE_BASIC_PROPS.heroImageMimeTypeArr.includes(
                            e.target.files[0].type
                          )
                        ) {
                          setZoneHeroImg(e.target.files[0]);
                          set_hero_thmbnail(
                            URL.createObjectURL(e.target.files[0])
                          );
                        } else toast.error("Please Upload JPG/PNG File");
                      }}
                      inputId="heroImglogoCreate"
                      inputName="zone_hero_img"
                    />
                  </div>

                  <div className="col">
                    <div className="mt-1">
                      {zoneHeroImg ? (
                        <>
                          <span className="mx-3 mb-1">{zoneHeroImg.name}</span>
                          <span
                            onClick={() => {
                              setZoneHeroImg(null);
                              // setFieldValue("zone_hero_img", null);
                              set_hero_thmbnail(null);
                            }}
                          >
                            <DeleteOutlined />
                          </span>
                          <span>
                            <img
                              src={hero_thmbnail}
                              alt="IMG"
                              className="img-fluid zone-img-preview"
                            />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Zone Title
                    <RedAsterick />
                  </div>
                  <div className="col-4">
                    <Input
                      name="zone_title"
                      value={localData.zone_title}
                      className={
                        validator?.current?.fields?.zonetitle === false &&
                        validator?.current?.messagesShown === true
                          ? "w-100 rounded fs-bold-14 border_red"
                          : "w-100 border-gray rounded fs-bold-14"
                      }
                      placeholder="Enter a Title Here"
                      onChange={(e) =>
                        handleFieldsChange(e.target.value, "zone_title")
                      }
                      maxLength={INPUT_TEXT_LIMITS.MAX}
                    />
                    {validator.current.message(
                      "zonetitle",
                      localData.zone_title,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Zone Description
                  </div>
                  <div className="col-4">
                    <TextArea
                      rows={4}
                      className="w-100 fs-bold-14"
                      name="zone_description"
                      value={localData.zone_description}
                      onChange={(e) =>
                        handleFieldsChange(e.target.value, "zone_description")
                      }
                      placeholder="Enter a Description"
                      maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                    />
                  </div>
                </div>
                <Divider />
                <div className="row my-3">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">Interaction</h6>
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Text Chat
                  </div>
                  <div className="col-4">
                    <Switch
                      disabled={localData.is_message_board_enable}
                      checked={localData.is_text_chat_enable}
                      name="is_text_chat_enable"
                      onChange={(checked) => {
                        if (restricedFields.chart_boards !== 0) {
                          handleFieldsChange(checked, "is_text_chat_enable");
                        } else {
                          toast.error("Restricted in Event Tier Settings");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    1-1 Video Call
                  </div>
                  <div className="col-4">
                    <Switch
                      checked={localData.is_one_to_one_video_calls_enable}
                      name="is_one_to_one_video_calls_enable"
                      onChange={(checked) => {
                        if (restricedFields.new_video_rep !== 0) {
                          handleFieldsChange(
                            checked,
                            "is_one_to_one_video_calls_enable"
                          );
                        } else {
                          toast.error("Restricted in Event Tier Settings");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Select Survey
                  </div>
                  <div className="col-4">
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Survey"
                      optionFilterProp="children"
                      value={localData?.survey_id}
                      name="survey_id"
                      className="fs-bold-14"
                      onChange={(val) => handleFieldsChange(val, "survey_id")}
                    >
                      {surveyList &&
                        surveyList.map((ele) => (
                          <Option value={ele.survey_id}>
                            {ele.survey_title}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-4">
                    <Button
                      onClick={() => {
                        setSurveyModal(true);
                      }}
                    >
                      + Create New Survey
                    </Button>
                  </div>
                </div>
                <Modal
                  title="Add Survey"
                  width={1100}
                  visible={surveyModal}
                  onCancel={() => setSurveyModal(false)}
                  footer={null}
                >
                  <div className="">
                    <AddNewSurvey
                      event_id={localData?.event_id}
                      isFromZone={true}
                      onCancelFromModal={() => setSurveyModal(false)}
                      onSuccessFromModal={async (eventId = null) => {
                        let surveyParams = {
                          event_id: localData?.event_id
                            ? localData?.event_id
                            : eventId,
                          is_brandzone_survey: true,
                          timezone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          current_timestamp: moment().format("YYYY-MM-DD"),
                        };

                        ApiCall("get", null, "/admin/survey/get", surveyParams)
                          .then((response) => {
                            setSurveyList(response?.data?.data?.rows);
                            setSurveyModal(false);
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    />
                  </div>
                </Modal>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    On Booth Leads
                  </div>
                  <div className="col-4">
                    <Switch
                      checked={localData.is_on_booth_leads_enable}
                      name="is_on_booth_leads_enable"
                      onChange={(checked) => {
                        if (restricedFields.on_booth_leads !== 0) {
                          handleFieldsChange(
                            checked,
                            "is_on_booth_leads_enable"
                          );
                        } else toast.error("Restricted in Event Tier Settings");
                      }}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Video Demo Room
                  </div>
                  <div className="col-4">
                    <Switch
                      checked={localData.is_video_demo_room_enable}
                      name="is_video_demo_room_enable"
                      onChange={(checked) => {
                        if (restricedFields.open_demo_rooms !== 0) {
                          handleFieldsChange(
                            checked,
                            "is_video_demo_room_enable"
                          );
                        } else toast.error("Restricted in Event Tier Settings");
                      }}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Chat Bot
                  </div>
                  <div className="col-4">
                    <Switch
                      checked={localData.is_text_bot_enable}
                      name="is_text_bot_enable"
                      onChange={(checked) => {
                        if (restricedFields.chat_bots !== 0)
                          handleFieldsChange(checked, "is_text_bot_enable");
                        else toast.error("Restricted in Event Tier Settings");
                      }}
                    />
                  </div>
                </div>
                {localData.is_text_bot_enable ? (
                  <>
                    <Divider />
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Chat Bot Settings
                        </h6>
                      </div>
                    </div>
                    <b>
                      A Chat Bot can be enabled for this product zone for set
                      dates / times
                    </b>
                    <br />
                    <b>1.Select the days you want the chatbot on for</b>
                    <br />
                    <b>
                      2.Select the times that the chatbot will be running for
                      each of the days selected
                    </b>
                    <div className="row mt-4  pb-4">
                      <div className="col-9 event-date-scroll-div">
                        <Collapse
                          bordered={false}
                          defaultActiveKey={["2"]}
                          expandIcon={({ isActive }) =>
                            (isActive && (
                              <MinusOutlined
                                className="rounded minus-logo"
                                style={{}}
                              />
                            )) || <PlusOutlined className="rounded plus-logo" />
                          }
                          className="site-collapse-custom-collapse"
                        >
                          {chatBotDetails &&
                            chatBotDetails.map((ele, index) => {
                              return (
                                <>
                                  <Panel
                                    header={
                                      <span>
                                        <span className="fw-bold">
                                          Shows Days/Times - Block
                                          {index + 1}
                                        </span>
                                        <span
                                          className="font-pink mx-2
                                    "
                                        >
                                          {"[ " +
                                            moment(ele.start_date).format(
                                              "DD-MM-YYYY"
                                            ) +
                                            " - " +
                                            moment(ele.end_date).format(
                                              "DD-MM-YYYY"
                                            ) +
                                            " , " +
                                            moment(ele.start_time).format(
                                              "HH:mm"
                                            ) +
                                            " - " +
                                            moment(ele.end_time).format(
                                              "HH:mm"
                                            ) +
                                            " ]"}
                                        </span>
                                      </span>
                                    }
                                    key={index}
                                    className="site-collapse-custom-panel"
                                  >
                                    <div className="row mt-4">
                                      <div className="col-3 text-end">
                                        <label className="text-secondary mt-1">
                                          Start & End Time
                                        </label>
                                      </div>
                                      <div className="col-6 d-flex m-0">
                                        <TimePicker
                                          allowClear={false}
                                          showTime
                                          className=""
                                          name="temp_startDate"
                                          value={ele.start_time}
                                          getPopupContainer={(triggerNode) => {
                                            return triggerNode.parentNode;
                                          }}
                                          format="HH:mm"
                                          onSelect={(val) =>
                                            handlechatbotArrChange(
                                              val,
                                              index,
                                              "start_time"
                                            )
                                          }
                                        />
                                        <div>
                                          <TimePicker
                                            allowClear={false}
                                            showTime
                                            className="ms-2"
                                            format="HH:mm"
                                            disabledTime={() =>
                                              getDisabledTime(ele.start_time)
                                            }
                                            value={ele.end_time}
                                            onSelect={(val) => {
                                              if (
                                                moment(val) <
                                                moment(ele.start_time, "HH:mm")
                                              ) {
                                                handlechatbotArrChange(
                                                  ele.end_time,
                                                  index,
                                                  "end_time"
                                                );
                                                toast.error(
                                                  "Please select correct time"
                                                );
                                              } else
                                                handlechatbotArrChange(
                                                  val,
                                                  index,
                                                  "end_time"
                                                );
                                            }}
                                            getPopupContainer={(
                                              triggerNode
                                            ) => {
                                              return triggerNode.parentNode;
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-3 text-end"></div>
                                      <div className="col-3 d-flex m-0"></div>
                                      <div className="col-3 d-flex m-0"></div>
                                    </div>
                                  </Panel>
                                </>
                              );
                            })}
                        </Collapse>
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="">Bot Responses</span>
                      <div
                        className="ms-2 text-decoration-underline fs-pink cursor-pointer"
                        onClick={() => {
                          window.location.href = STATIC_URLS.default_chatbot;
                        }}
                      >
                        Click Here To Download Our Template
                      </div>
                    </div>
                    <div className="d-flex mt-4">
                      <span className="align-self-center mx-2">
                        Chat Bot
                        <RedAsterick />
                      </span>
                      <FileUpload
                        value=""
                        labelClassName="rounded border-pink btn-svg  px-3 py-2 ms-3"
                        labelTitle="File Upload"
                        name="chatbotUpload"
                        inputType="file"
                        inputOnChange={(e) => {
                          setChatBotFileUpload(e.target.files[0]);
                          setChatbotValidation({ name: "dummy" });
                        }}
                        accept={true}
                        inputId="chatbot-upload"
                        inputName="chatbotUpload"
                      />
                    </div>
                    <div
                      className="ms-2"
                      style={{ paddingLeft: "65px", paddingTop: "10px" }}
                    >
                      {chatBotFileUpload ? (
                        <>
                          <span className="">{chatBotFileUpload.name}</span>
                          <span
                            onClick={() => {
                              setChatBotFileUpload(null);
                              setChatbotValidation(null);
                            }}
                          >
                            <DeleteOutlined />
                          </span>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null}
                <div style={{ paddingLeft: "80px" }}>
                  {validator.current.message(
                    "chatbot",
                    chatbotValidation,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </div>
                <Divider />
                <div className="row my-3">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">
                      Externals & Social Links
                    </h6>
                  </div>
                </div>
                N8: If you paste your details into these boxes then anyone using
                site will be able to view/contact you via these platform
                <div className="social-media-div border-bottom-gray pb-4 mb-4">
                  {socialMediaList &&
                    socialMediaList.map((data, idx) => (
                      <div className="row mt-4 ">
                        <div className="col-2 text-end ">
                          {data?.social_media}
                        </div>
                        <div className={Tablet ? "col-2" : "col-1 "}>
                          <Switch
                            checked={
                              localData?.zones_social_media_handles_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                                ? true
                                : false
                            }
                            onChange={(checked) =>
                              onHandleSocialSwitchChange(checked, idx)
                            }
                          />
                        </div>
                        <div className="col-4 text-start ">
                          <Input
                            placeholder="https://webaddress.com"
                            className="w-100 fs-bold-14"
                            disabled={
                              localData?.zones_social_media_handles_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                                ? false
                                : true
                            }
                            value={
                              localData?.zones_social_media_handles_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                                ? localData.zones_social_media_handles_details[
                                    localData.zones_social_media_handles_details.findIndex(
                                      (social_media) =>
                                        social_media.social_media_id ===
                                        data.social_media_id
                                    )
                                  ]["social_media_url"]
                                : ""
                            }
                            onChange={(event) =>
                              socialMediaHandleChange(idx, event)
                            }
                            onBlur={() =>
                              validator.current.showMessageFor("social_media")
                            }
                          />
                          {validator.current.message(
                            "social_media",
                            localData?.zones_social_media_handles_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? localData?.zones_social_media_handles_details[
                                  localData?.zones_social_media_handles_details.findIndex(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                ]["social_media_url"]
                              : "",
                            localData?.zones_social_media_handles_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? "required|url"
                              : "",
                            { className: "text-danger py-1" }
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <section>
                  <div className="row">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20 px-2">
                        Assign Agents to this Zone
                      </h6>
                    </div>
                  </div>
                  <div className="row mt-4 mb-4">
                    <div className="col-2 text-end mt-4">
                      <span className="text-secondary mt-4">Agents</span>
                    </div>

                    <div
                      className={Tablet ? "col-5" : "col-4"}
                      id="area"
                      style={{ position: "relative" }}
                    >
                      <label className="py-2 mx-2">
                        <b>All Agents</b>
                      </label>
                      <br />
                      <Select
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        style={{ width: Tablet ? 150 : 300 }}
                        dropdownAlign={{ offset: [0, 4] }}
                        showSearch
                        className="border border-gray fs-bold-14"
                        dropdownClassName="agent-dropdown"
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        open={openUserList}
                        onFocus={() => setOpenUsersList(true)}
                        onBlur={() => setOpenUsersList(false)}
                        onSearch={() => setOpenUsersList(true)}
                        value="All Agents"
                        onChange={handleAgentsChange}
                        listItemHeight={4}
                        listHeight={130}
                      >
                        {agents &&
                          agents?.map((data, key) => (
                            <Option value={data?.user_id}>
                              {data?.first_name} {data?.last_name}
                            </Option>
                          ))}
                      </Select>
                    </div>

                    <div
                      className={Tablet ? "col-5" : "col-4"}
                      id="area1"
                      style={{ position: "relative" }}
                    >
                      <label className="py-2 mx-2">
                        <b>Tagged Agents</b>
                      </label>
                      <br />
                      <Select
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        style={{ width: Tablet ? 150 : 300 }}
                        showSearch
                        className="border-gray fs-bold-14"
                        dropdownClassName={"agent-dropdown"}
                        placeholder="Search Agents"
                        optionFilterProp="children"
                        open={openUserList}
                        onFocus={() => setOpenUsersList(true)}
                        onBlur={() => setOpenUsersList(false)}
                        onSearch={() => setOpenUsersList(true)}
                        value="Tagged Agents"
                        listItemHeight={4}
                        listHeight={130}
                      >
                        {selectedAgents?.map((data) => (
                          <Option value={data.user_id}>
                            {data.first_name} {data.last_name}
                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleSelectedAgentsChange(data.user_id)
                                }
                              />
                            </span>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row mt-4 border-bottom-gray pb-4">
                    <div className="col-2"></div>
                    <div className="col-4">
                      <div className="d-flex"></div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="row mt-4">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">Assets</h6>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className={Tablet ? "col-12" : "col-2"}>
                      <Button
                        className="px-3 button-pink rounded my-3"
                        onClick={showModal}
                      >
                        + Add New Asset
                      </Button>
                    </div>
                    {assetsArray ? (
                      <div
                        className={
                          Tablet
                            ? "col-6 assets-scroll-div"
                            : "col-4 assets-scroll-div"
                        }
                      >
                        {assetsArray &&
                          assetsArray.map((ele, index) => (
                            <>
                              <div
                                className={Tablet ? "mt-4" : " py-2"}
                                onClick={() => setViewVisible(ele)}
                              >
                                <div className="row border-gray rounded py-1">
                                  <div className="col-1 ">
                                    <Cube fill="#000" />
                                  </div>
                                  <div className="col-11">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <span className="fs-18 mx-2">
                                          <b>{ele.asset_name}</b>
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          className="px-3 "
                                          onClick={() =>
                                            handleAssetsUpdate(ele, index)
                                          }
                                        >
                                          <EditRed fill="#000" />
                                        </span>
                                        <span
                                          className="px-3 "
                                          onClick={async () => {
                                            let temp_asset = assetsArray;
                                            temp_asset.splice(index, 1);
                                            await setAssetsArray(temp_asset);
                                            await setViewVisible(false);
                                          }}
                                        >
                                          <Bin fill="#000" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <span className="fs-16 mx-2">
                                        {ele.asset_description}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    ) : null}

                    {viewVisible || !assetsArray ? (
                      <DetailComponent assetData={viewVisible} />
                    ) : null}
                  </div>
                </section>
              </div>
              <div
                className="shadow-sm p-3 d-flex justify-content-between"
                style={{ backgroundColor: "#eeeeee" }}
              >
                {props.onCancelFromModal ? (
                  <Button
                    onClick={() => props.onCancelFromModal()}
                    className="rounded  px-3"
                  >
                    Cancel
                  </Button>
                ) : (
                  <Link to="/zonemanagement">
                    <Button className="px-3 d-block button-pink rounded">
                      Cancel
                    </Button>
                  </Link>
                )}
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => handleCreateZone}
                >
                  Create Zone
                </Button>
              </div>
            </div>
          )}
        </form>
        <Modal
          title="Add New Asset"
          footer={false}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          style={{ zIndex: "11111" }}
        >
          <div className="container px-5">
            <Formik
              initialValues={{
                asset_file_type: "",
                asset_file_name: "",
                asset_name: "",
                asset_description: "",
                is_active: true,
                is_downloadable: true,
                max_no_of_downloads: 1,
                total_downloads: 0,
                whitelisted_domains: 0,
                blacklisted_domains: 0,
                view_count: 0,
                uploadObj: null,
                webviewer_url: "",
                is_webviewer: false,
              }}
              validationSchema={Yup.object().shape({
                asset_name: Yup.string().required("Please enter name"),
                asset_description: Yup.string().required(
                  "Please enter description"
                ),
              })}
              enableReinitialize={true}
              onSubmit={(values, action) => {
                if (values?.is_webviewer) {
                  if (isValidHttpURL(values?.webviewer_url)) {
                    addAsset(values);
                    action.resetForm();
                  } else {
                    toast.error("URL is Not Valid");
                  }
                } else {
                  addAsset(values);
                  action.resetForm();
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                resetForm,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">3d</div>
                    <div className="col-7">
                      <Switch
                        name="is_webviewer"
                        checked={values?.is_webviewer}
                        onChange={(val) => {
                          setFieldValue("is_webviewer", val);
                        }}
                      />
                    </div>
                  </div>

                  {values?.is_webviewer ? (
                    <div className="row mt-2">
                      <div className="col-5 pt-2 text-end">Asset Url</div>
                      <div className="col-7">
                        <Input
                          name="webviewer_url"
                          value={values.webviewer_url}
                          onChange={(e) => {
                            setFieldValue("webviewer_url", e.target.value);
                            setFieldValue("asset_file_type", "web/3d");
                          }}
                        />
                        {errors.webviewer_url && touched.webviewer_url ? (
                          <div className="text-danger">
                            {errors.webviewer_url}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-2">
                      <div className="col-5 pt-2 text-end">Assets File</div>
                      <div className="col-7 image-upload">
                        <FileUpload
                          value=""
                          labelClassName="rounded border-pink btn-svg px-3 py-1"
                          labelTitle="File Upload"
                          inputType="file"
                          inputOnChange={(e) => {
                            setFieldValue("uploadObj", e.target.files[0]);
                            setFieldValue(
                              "asset_file_name",
                              e.target.files[0].name
                            );
                            setFieldValue(
                              "asset_file_type",
                              e.target.files[0].type
                            );
                          }}
                          inputId="pree"
                          inputName="uploadObj"
                          ref={assetCreateUploadRef}
                        />
                        {errors.uploadObj &&
                        touched.uploadObj &&
                        !values.uploadObj ? (
                          <div className="text-danger">{errors.uploadObj}</div>
                        ) : null}
                        {values.uploadObj ? (
                          <>
                            <span className="mx-3">
                              {values.uploadObj.name}
                            </span>
                            <span
                              onClick={() => {
                                setFieldValue("uploadObj", null);
                              }}
                            >
                              <DeleteOutlined />
                            </span>
                          </>
                        ) : null}
                      </div>
                    </div>
                  )}

                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets Name</div>
                    <div className="col-7">
                      <Input
                        name="asset_name"
                        value={values.asset_name}
                        onChange={handleChange}
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.asset_name && touched.asset_name ? (
                        <div className="text-danger">{errors.asset_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">
                      Assets Description
                    </div>
                    <div className="col-7">
                      <TextArea
                        name="asset_description"
                        value={values.asset_description}
                        onChange={handleChange}
                      />
                      {errors.asset_description && touched.asset_description ? (
                        <div className="text-danger">
                          {errors.asset_description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Active</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_active}
                        name="is_active"
                        onChange={() =>
                          setFieldValue("is_active", !values.is_active)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Downloadable</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_downloadable}
                        name="is_downloadable"
                        onChange={(checked) => {
                          setFieldValue(
                            "is_downloadable",
                            !values.is_downloadable
                          );
                          checked && setFieldValue("max_no_of_downloads", 1);
                        }}
                      />
                    </div>
                  </div>
                  {values.is_downloadable && (
                    <div className="row mt-2">
                      <div className="col-5  text-end">Max No. of download</div>
                      <div className="col-7">
                        <Input
                          type="number"
                          name="max_no_of_downloads"
                          value={values.max_no_of_downloads}
                          onKeyDown={handleCommonKeyDown}
                          onChange={(e) => {
                            if (isValidNumber(e?.target?.value)) {
                              handleChange(e);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Whitelist</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="whitelisted_domains"
                        value={values.whitelisted_domains}
                        onKeyDown={handleCommonKeyDown}
                        onChange={(e) => {
                          if (isValidNumber(e?.target?.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Blacklist</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="blacklisted_domains"
                        value={values.blacklisted_domains}
                        onKeyDown={handleCommonKeyDown}
                        onChange={(e) => {
                          if (isValidNumber(e?.target?.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row py-4 mt-2">
                    <div className="col-6  text-end">
                      <Button
                        onClick={() => {
                          resetForm();
                          setIsModalVisible(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button htmlType="submit" type="primary">
                        Add Asset
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>

        <Modal
          title="Update Assets"
          footer={false}
          visible={isUpdateModalVisible}
          onCancel={() => {
            setIsUpdateModalVisible(false);
          }}
          style={{ zIndex: "111111111111111111" }}
        >
          <div className="container px-5">
            <Formik
              initialValues={{
                asset_file_type: "",
                asset_file_name: "",
                asset_name: updateAssetValue?.asset_name,
                asset_description: updateAssetValue?.asset_description,
                is_active: updateAssetValue?.is_active,
                is_downloadable: updateAssetValue?.is_downloadable,
                max_no_of_downloads: updateAssetValue?.max_no_of_downloads,
                total_downloads: 0,
                whitelisted_domains: updateAssetValue?.whitelisted_domains,
                blacklisted_domains: updateAssetValue?.blacklisted_domains,
                view_count: 0,
                uploadObj: updateAssetValue?.uploadObj,
                tempIndex: updateAssetValue?.arrIndex,
                webviewer_url: updateAssetValue?.webviewer_url,
                is_webviewer: updateAssetValue?.is_webviewer,
              }}
              validationSchema={Yup.object().shape({
                asset_name: Yup.string().required("Please enter name"),
                asset_description: Yup.string().required(
                  "Please enter description"
                ),
              })}
              enableReinitialize={true}
              onSubmit={(values, action) => {
                if (values?.is_webviewer) {
                  if (isValidHttpURL(values?.webviewer_url)) {
                    updateAsset(values);
                    action.resetForm();
                  } else {
                    toast.error("URL is Not Valid");
                  }
                } else {
                  updateAsset(values);
                  action.resetForm();
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                resetForm,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">3d</div>
                    <div className="col-7">
                      <Switch
                        name="is_webviewer"
                        checked={values?.is_webviewer}
                        onChange={(val) => {
                          setFieldValue("is_webviewer", val);
                        }}
                      />
                    </div>
                  </div>

                  {values?.is_webviewer ? (
                    <div className="row mt-2">
                      <div className="col-5 pt-2 text-end">Asset Url</div>
                      <div className="col-7">
                        <Input
                          name="webviewer_url"
                          value={values.webviewer_url}
                          onChange={(e) => {
                            setFieldValue("webviewer_url", e.target.value);
                            setFieldValue("asset_file_type", "web/3d");
                          }}
                        />
                        {errors.webviewer_url && touched.webviewer_url ? (
                          <div className="text-danger">
                            {errors.webviewer_url}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-2">
                      <div className="col-5 pt-2 text-end">Assets File</div>

                      <div className="col-7 image-upload">
                        <FileUpload
                          value=""
                          labelClassName="rounded border-pink btn-svg px-3 py-1"
                          labelTitle="File Upload"
                          inputType="file"
                          inputOnChange={(e) => {
                            setFieldValue("uploadObj", e.target.files[0]);
                            setFieldValue(
                              "asset_file_type",
                              e.target.files[0].type
                            );
                            setFieldValue(
                              "asset_file_name",
                              e.target.files[0].name
                            );
                          }}
                          inputId="preeEdit"
                          inputName="pree"
                        />
                        {values.uploadObj ? (
                          <>
                            <span className="mx-3">
                              {values.uploadObj.name}
                            </span>
                            <span
                              onClick={() => {
                                setFieldValue("uploadObj", null);
                              }}
                            >
                              <DeleteOutlined />
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets Name</div>
                    <div className="col-7">
                      <Input
                        name="asset_name"
                        value={values.asset_name}
                        onChange={handleChange}
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.asset_name && touched.asset_name ? (
                        <div className="text-danger">{errors.asset_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">
                      Assets Description
                    </div>
                    <div className="col-7">
                      <TextArea
                        name="asset_description"
                        value={values.asset_description}
                        onChange={handleChange}
                        maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                      />
                      {errors.asset_description && touched.asset_description ? (
                        <div className="text-danger">
                          {errors.asset_description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Active</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_active}
                        name="is_active"
                        onChange={() =>
                          setFieldValue("is_active", !values.is_active)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Downloadable</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_downloadable}
                        name="is_downloadable"
                        onChange={(checked) => {
                          setFieldValue(
                            "is_downloadable",
                            !values.is_downloadable
                          );
                          checked && setFieldValue("max_no_of_downloads", 1);
                        }}
                      />
                    </div>
                  </div>
                  {values.is_downloadable && (
                    <div className="row mt-2">
                      <div className="col-5  text-end">Max No. of download</div>
                      <div className="col-7">
                        <Input
                          type="number"
                          min="0"
                          name="max_no_of_downloads"
                          value={values.max_no_of_downloads}
                          onKeyDown={handleCommonKeyDown}
                          onChange={(e) => {
                            if (isValidNumber(e?.target?.value)) {
                              handleChange(e);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Whitelist</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="whitelisted_domains"
                        value={values.whitelisted_domains}
                        onKeyDown={handleCommonKeyDown}
                        onChange={(e) => {
                          if (isValidNumber(e?.target?.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Blacklist</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="blacklisted_domains"
                        value={values.blacklisted_domains}
                        onKeyDown={handleCommonKeyDown}
                        onChange={(e) => {
                          if (isValidNumber(e?.target?.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row py-4 mt-2">
                    <div className="col-6  text-end">
                      <Button
                        onClick={() => {
                          resetForm();
                          setIsUpdateModalVisible(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button htmlType="submit" type="primary">
                        Update Asset
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      </>
    );
};

export default CreateNewZone;
