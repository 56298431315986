import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  customerList: [],
  loading: false,
};

export const getCustomerList = createAsyncThunk(
  "customerData/getCustomerList",
  async (params) => {
    const response = await ApiCall("GET", "", "/website/customer/get", params);
    if (response?.data?.data) return response.data.data;
    else return [];
  }
);

export const customerDataSlice = createSlice({
  name: "customerData",
  initialState,
  reducers: {},
  extraReducers: {
    [getCustomerList.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerList = payload;
    },
    [getCustomerList.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const customReducer = customerDataSlice.reducer;
