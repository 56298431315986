import React, { useState, useEffect, useRef } from "react";
import "./survey.css";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  Checkbox,
  InputNumber,
} from "antd";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addSurvey } from "../../redux/slice/surveyManagement";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ApiCall } from "../../utils/api";
import * as Yup from "yup";
import SimpleReactValidator from "simple-react-validator";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { Bin } from "../../components/svgJS/Bin";
import { cloneDeep, update } from "lodash";
import { useMediaQuery } from "react-responsive";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import {
  handleCommonKeyDown,
  isRouteExistInMenu,
  isValidNumber,
} from "../../utils/commonFunctions";
import { dialogs } from "../../components/constsnts/string.constants";
import { Tab } from "bootstrap";
import { isEmpty } from "lodash";

const AddNewSurvey = (props) => {
  var CryptoJS = require("crypto-js");
  const Tablet = useMediaQuery({ maxWidth: 1025 });
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.surveyManagement);
  const [userType, setUserType] = useState("");
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const validator = useRef(new SimpleReactValidator());
  const [eventId, setEventId] = useState(null);
  const [loadingSurvey, setLoadingSurvey] = useState(false);
  const [questionArray, setquestionArray] = useState([
    {
      question_type: "TOGGLE",
      answer_details: [
        { option: "", is_default_selected: false, offText: "", onText: "" },
      ],
      chart_type: "BAR",
      display_chart: false,
      is_required: false,
      question_text: "",
    },
  ]);
  const [eventList, seteventList] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [inititalState, setInitialState] = useState({
    event_id: props?.event_id ?? null,
    survey_title: "",
    survey_description: "",
    survey_type:
      props?.isFromEventSecondStep === true
        ? "REGISTRATION"
        : props?.isFromEvent === true
        ? "EVENT"
        : props?.isFromZone === true
        ? "BRAND/ZONE"
        : null,
    question_details: questionArray,
  });

  useEffect(async () => {
    setLoadingSurvey(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      getEventsList();
      setTimeout(() => {
        setLoadingSurvey(false);
      }, 1000);
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    if (userType === "BRAND ADMIN" && eventId) {
      setInitialState({
        ...inititalState,
        survey_type: "BRAND/ZONE",
        event_id: eventId,
      });
    } else if (userType === "CUSTOMER" && eventId) {
      setInitialState({ ...inititalState, event_id: eventId });
    }
  }, [userType, eventId]);

  const getEventsList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        seteventList(rows);
        if (rows.length === 1) {
          setEventId(rows[0]?.event_id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addAnotherAnswer = (key) => {
    let question_array = questionArray;

    question_array[key].answer_details.push({
      option: "",
      is_default_selected: false,
    });
    setquestionArray([...question_array]);
  };

  const addAnotherQuestion = () => {
    let question_array = questionArray;
    question_array.push({
      question_type: "",
      answer_details: [{ option: "", is_default_selected: false }],
      chart_type: "BAR",
      display_chart: false,
      is_required: false,
      question_text: "",
    });
    setquestionArray([...question_array]);
  };

  const handleQuestionArrChange = (val, key, name) => {
    let question_array = questionArray;
    question_array[key][[name]] = val;
    setquestionArray([...question_array]);
  };

  const handleQuestionArrAnswerChange = (val, key, index, name) => {
    let question_array = questionArray;
    question_array[key].answer_details[index][[name]] = val;
    setquestionArray([...question_array]);
  };

  const checkForEmptyFields = (data) => {
    let flag = true;

    if (
      !data?.event_id ||
      !data?.survey_type ||
      !data?.survey_title ||
      !data?.survey_description
    ) {
      flag = false;
    }

    questionArray.map((val, index) => {
      if (!val.question_type || !val.question_text) {
        flag = false;
      }

      if (val.question_type === "TOGGLE") {
        val.answer_details.map((item, i) => {
          if (!item?.offText || !item?.onText) {
            flag = false;
          }
        });
      }

      if (val.question_type === "MULTIPLE SELECT") {
        if (!val?.min_obligatory || !val?.max_obligatory) {
          flag = false;
        }
      }

      if (val.question_type === "MULTIPLE CHOICE") {
        if (!val?.multi_select_question_type || !val?.one_question_per_page) {
          flag = false;
        }
      }

      if (val.question_type === "FREEFORM TEXT") {
        if (!val?.format) {
          flag = false;
        }
        val.answer_details.map((item, i) => {
          if (!item?.format_line_character_limit) {
            flag = false;
          }
        });
      }

      if (
        val.question_type === "MULTIPLE SELECT" ||
        val.question_type === "MULTIPLE CHOICE"
      ) {
        val.answer_details.map((item, i) => {
          if (!item.option) {
            flag = false;
          }
        });
      }
    });

    return flag;
  };

  const handleAddSurvey = async (data) => {
    if (checkForEmptyFields(data)) {
      setLoadingSurvey(true);

      const sequesArr = questionArray.map((val, index) => {
        return {
          ...val,
          sequence: index + 1,
        };
      });

      const finalArr = sequesArr.map((val, index) => {
        if (val.question_type === "TOGGLE") {
          return {
            ...val,
            answer_details: val.answer_details.map((elem) => {
              return {
                ...elem,
                is_toggle: true,
              };
            }),
          };
        } else {
          return val;
        }
      });

      let formData = {
        event_id: data.event_id,
        survey_type: data.survey_type,
        survey_title: data.survey_title,
        survey_description: data.survey_description,
        question_details: finalArr,
      };
      const addSurveyResp = await dispatch(addSurvey(formData));

      if (addSurveyResp.payload.code === 200) {
        toast.success(addSurveyResp.payload.message);
        props?.history?.push("surveyManagement");
        if (props.isFromEvent || props?.isFromZone)
          props?.onSuccessFromModal(data?.event_id);
      }
      if (addSurveyResp.payload.code !== 200) {
        toast.error(addSurveyResp.payload.message);
      }
      setLoadingSurvey(false);
    } else {
      toast.error(dialogs.missingFields);
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  const children = [];
  for (let i = 1; i <= 50; i++) {
    children.push(<Option key={i}>{i}</Option>);
  }

  const handleDeleteAnswer = (key1, key2) => {
    let clonedArr = cloneDeep(questionArray);
    let answers = clonedArr[key1].answer_details;

    answers.forEach((elem, i) => {
      if (key2 === i) {
        answers.splice(i, 1);
      }
    });
    clonedArr[key1].answer_details = answers;
    setquestionArray(clonedArr);
  };

  const handleDeleteSurvey = (key) => {
    let newArr = questionArray.filter((elem, i) => {
      if (i !== key) {
        return elem;
      }
    });

    setquestionArray(newArr);
  };

  if (loadingSurvey) {
    return <CommonLoader />;
  } else
    return (
      <Formik
        initialValues={inititalState}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleAddSurvey(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              {props.isFromEvent || props?.isFromZone ? (
                ""
              ) : (
                <div
                  className={
                    props?.event_id
                      ? "d-flex justify-content-between bg-light w-100"
                      : "d-flex justify-content-between bg-light w-100 common-sticky-header"
                  }
                >
                  <div>
                    <h4 className="fs-24">Add Survey</h4>
                    <Breadcrumb className="my-2" separator=">">
                      <Breadcrumb.Item>
                        <Link to="/surveyManagement">Admin Console</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/surveyManagement">Surveys</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/addnewsurvey">Add Survey</Link>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </div>
                </div>
              )}
              <div
                className={
                  props?.event_id || props?.isFromZone
                    ? "bg-white px-5 py-1"
                    : "bg-white px-5 py-3 mt-5"
                }
              >
                <div className={props?.event_id ? "d-none" : "row my-3"}>
                  <div className="col-12 mt-4">
                    <h6 className="fs-pink fw-bolder fs-20">Survey Details</h6>
                  </div>
                </div>
                <div className={props?.event_id ? "row my-1" : "row my-3"}>
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Select Event
                    <RedAsterick />
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Select Event"
                      optionFilterProp="children"
                      name="event_id"
                      className={
                        validator?.current?.fields?.Event === false &&
                        validator?.current?.messagesShown === true
                          ? "fs-bold-14 border_red"
                          : "fs-bold-14 "
                      }
                      listHeight={120}
                      listItemHeight={4}
                      value={values?.event_id}
                      disabled={
                        props?.event_id
                          ? true
                          : userType === "BRAND ADMIN"
                          ? true
                          : false
                      }
                      onChange={(val) => setFieldValue("event_id", val)}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      onBlur={() =>
                        validator.current.showMessageFor("event_id")
                      }
                    >
                      {eventList &&
                        eventList.map((data) => (
                          <Option value={data.event_id}>
                            {data.event_name}
                          </Option>
                        ))}
                    </Select>

                    {validator.current.message(
                      "Event",
                      values?.event_id,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Survey Type
                    <RedAsterick />
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Select Type"
                      optionFilterProp="children"
                      name="survey_type"
                      value={values.survey_type}
                      className={
                        validator?.current?.fields?.SurveyType === false &&
                        validator?.current?.messagesShown === true
                          ? "fs-bold-14 border_red"
                          : "fs-bold-14 "
                      }
                      disabled={
                        props?.isFromEvent === true
                          ? true
                          : userType === "BRAND ADMIN"
                          ? true
                          : false
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("survey_type")
                      }
                      onChange={(value) => setFieldValue("survey_type", value)}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {userType === "BRAND ADMIN" ? null : (
                        <Option value="EVENT">Event</Option>
                      )}
                      {userType === "BRAND ADMIN" ? null : (
                        <Option value="REGISTRATION">Registration</Option>
                      )}
                      <Option value="BRAND/ZONE">Brand/Zone</Option>
                    </Select>

                    {validator.current.message(
                      "SurveyType",
                      values?.survey_type,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Title
                    <RedAsterick />
                  </div>
                  <div className="col-9">
                    <Input
                      name="survey_title"
                      value={values.survey_title}
                      className={
                        validator?.current?.fields?.SurveyTitle === false &&
                        validator?.current?.messagesShown === true
                          ? "w-50 rounded fs-bold-14 border_red"
                          : "w-50 border-gray rounded fs-bold-14"
                      }
                      placeholder="End of Show"
                      onChange={handleChange}
                      maxLength={INPUT_TEXT_LIMITS.MAX}
                      onBlur={() =>
                        validator.current.showMessageFor("survey_title")
                      }
                    />

                    {validator.current.message(
                      "SurveyTitle",
                      values?.survey_title,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    Description
                    <RedAsterick />
                  </div>
                  <div className="col-9">
                    <TextArea
                      rows={6}
                      className={
                        validator?.current?.fields?.Description === false &&
                        validator?.current?.messagesShown === true
                          ? "w-50 fs-bold-14 border_red"
                          : "w-50 fs-bold-14"
                      }
                      placeholder="Enter a Description here"
                      value={values.survey_description}
                      name="survey_description"
                      onChange={handleChange}
                      maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                      onBlur={() =>
                        validator.current.showMessageFor("survey_description")
                      }
                    />
                    {validator.current.message(
                      "Description",
                      values?.survey_description,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <Divider />

                {/* question answer section  */}

                <div className="row my-3">
                  <div className="col-10 mt-4">
                    <h6 className="fs-pink fw-bolder fs-20">Add Questions</h6>
                  </div>
                </div>

                <div className="question-div d-flex flex-column align-items-center">
                  {questionArray &&
                    questionArray.map((val, key) => (
                      <>
                        <div
                          key={"survey_ques_" + key}
                          style={{ marginLeft: "48px" }}
                          className={
                            Tablet
                              ? "question_container_tabview"
                              : props?.event_id
                              ? "question_container_modal p-2 my-2"
                              : "question_container p-2 my-2"
                          }
                        >
                          <h5 className="survey_question_number ">{key + 1}</h5>
                          <span
                            onClick={() => handleDeleteSurvey(key)}
                            className="survey_delete_icon cursor-pointer"
                          >
                            <Bin />
                          </span>
                          <div className="survey_design"></div>
                          <div className="row my-3">
                            <div
                              className={
                                Tablet ? "col-4 text-end" : "col-3 text-end"
                              }
                            >
                              Question Type
                              <RedAsterick />
                            </div>
                            <div className={Tablet ? "col-6" : "col-5"}>
                              <Select
                                showSearch
                                listHeight={120}
                                listItemHeight={4}
                                style={{ width: "100%" }}
                                placeholder="Select"
                                optionFilterProp="children"
                                value={val.question_type}
                                name={`question_type_${key}`}
                                className="fs-bold-14"
                                onChange={(val) =>
                                  handleQuestionArrChange(
                                    val,
                                    key,
                                    "question_type"
                                  )
                                }
                                onBlur={() =>
                                  validator.current.showMessageFor(
                                    `question_type_${key}`
                                  )
                                }
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                              >
                                <Option value="TOGGLE">Toggle</Option>
                                <Option value="SINGLE SELECT">
                                  Single Tick-box
                                </Option>
                                <Option value="MULTIPLE SELECT">
                                  Multiple Tick-boxes (select multiple)
                                </Option>
                                <Option value="MULTIPLE CHOICE">
                                  Multiple choice (select one)
                                </Option>
                                <Option value="FREEFORM TEXT">
                                  Freeform text
                                </Option>
                              </Select>

                              {validator.current.message(
                                "question type",
                                val.question_type,
                                "required",
                                { className: "text-danger py-1" }
                              )}
                            </div>
                          </div>
                          {val.question_type === "TOGGLE" ? (
                            <>
                              <div className="row my-3" key={"toggle_" + key}>
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Question Text
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Input
                                    name="toggle_question"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={(e) =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "toggle_question"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "Question Text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Obligatory on to Procced? (*)
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <Switch
                                    name="obligatory"
                                    onChange={(checked) =>
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "is_required"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Default
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <span>Off label</span>
                                  <Switch
                                    name="default"
                                    className="mx-1"
                                    onChange={(checked) =>
                                      handleQuestionArrAnswerChange(
                                        checked,
                                        key,
                                        0,
                                        "is_default_selected"
                                      )
                                    }
                                  />
                                  <span>On label</span>
                                </div>
                              </div>
                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  'Off' label text
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Input
                                    name="off_label_text"
                                    className="border-gray rounded fs-bold-14"
                                    maxLength={INPUT_TEXT_LIMITS.MAX}
                                    placeholder=""
                                    onChange={(e) =>
                                      handleQuestionArrAnswerChange(
                                        e.target.value,
                                        key,
                                        0,
                                        "offText"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "off_label_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "Off Text",
                                    val.answer_details[0]?.offText,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  'On' label text
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Input
                                    name="on_label_text"
                                    maxLength={INPUT_TEXT_LIMITS.MAX}
                                    className="border-gray rounded fs-bold-14"
                                    placeholder=""
                                    onChange={(e) =>
                                      handleQuestionArrAnswerChange(
                                        e.target.value,
                                        key,
                                        0,
                                        "onText"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "on_label_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "On Text",
                                    val.answer_details[0]?.onText,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                          {val.question_type === "SINGLE SELECT" ? (
                            <>
                              <div className="row my-3" key={"single_" + key}>
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Question Text
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Input
                                    name="single_select_question"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={(e) =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "single_select_question"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "question_text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Obligatory on to Procced? (*)
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <Switch
                                    name="obligatory"
                                    onChange={(checked) =>
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "is_required"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Default
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <Checkbox
                                    onChange={(e) => {
                                      handleQuestionArrAnswerChange(
                                        e.target.checked,
                                        key,
                                        0,
                                        "is_default_selected"
                                      );
                                    }}
                                  >
                                    Checkbox
                                  </Checkbox>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {val.question_type === "MULTIPLE SELECT" ? (
                            <>
                              <div
                                className="row my-3"
                                key={"multiple_select_" + key}
                              >
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Question Text
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Input
                                    name="multiple_select_question"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={(e) =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "multiple_select_question"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "Question Text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Minimum Obligatory Answers
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="min_obligatory"
                                    className="fs-bold-14"
                                    onChange={(val) => {
                                      let intVal = parseInt(val);
                                      handleQuestionArrChange(
                                        intVal,
                                        key,
                                        "min_obligatory"
                                      );
                                    }}
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "min_obligatory"
                                      )
                                    }
                                    getPopupContainer={(triggerNode) => {
                                      return triggerNode.parentNode;
                                    }}
                                  >
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                  </Select>
                                  {validator.current.message(
                                    "min_obligatory",
                                    val?.min_obligatory,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Maximum Answers?
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="max_obligatory"
                                    className="fs-bold-14"
                                    onChange={(val) => {
                                      let intVal = parseInt(val);
                                      handleQuestionArrChange(
                                        intVal,
                                        key,
                                        "max_obligatory"
                                      );
                                    }}
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "max_obligatory"
                                      )
                                    }
                                    getPopupContainer={(triggerNode) => {
                                      return triggerNode.parentNode;
                                    }}
                                  >
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                  </Select>
                                  {validator.current.message(
                                    "max_obligatory",
                                    val?.max_obligatory,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              {val.answer_details &&
                                val.answer_details.map((elem, index) => (
                                  <>
                                    <div key={index} className="row my-3">
                                      <div
                                        className={
                                          Tablet
                                            ? "col-4 text-end"
                                            : "col-3 text-end"
                                        }
                                      >
                                        Answer {index + 1}
                                        <RedAsterick />
                                      </div>
                                      <div
                                        className={Tablet ? "col-5" : "col-4"}
                                      >
                                        <Input
                                          name={`option_multiple_select_${index}`}
                                          className="border-gray rounded"
                                          placeholder="Yes"
                                          onChange={(e) =>
                                            handleQuestionArrAnswerChange(
                                              e.target.value,
                                              key,
                                              index,
                                              "option"
                                            )
                                          }
                                          onBlur={() =>
                                            validator.current.showMessageFor(
                                              `option_multiple_select_${index}`
                                            )
                                          }
                                        />
                                        {validator.current.message(
                                          "answer",
                                          elem?.option,
                                          "required",
                                          { className: "text-danger py-1" }
                                        )}
                                      </div>
                                      <div className="col-1">
                                        <Checkbox
                                          onChange={(e) => {
                                            handleQuestionArrAnswerChange(
                                              e.target.checked,
                                              key,
                                              index,
                                              "is_default_selected"
                                            );
                                          }}
                                        />
                                      </div>
                                      <div
                                        className={"col-1"}
                                        onClick={() =>
                                          handleDeleteAnswer(key, index)
                                        }
                                      >
                                        <Bin className="" />
                                      </div>
                                    </div>
                                  </>
                                ))}

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                ></div>
                                <div className="col-4">
                                  <Button
                                    type="primary"
                                    onClick={() => addAnotherAnswer(key)}
                                  >
                                    + Add Another Answer
                                  </Button>
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Display Chart ?
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <Switch
                                    name="display_chart"
                                    onChange={(checked) =>
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "display_chart"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Chart Type ?
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <span className="mx-1">Bar Chart</span>
                                  <Switch
                                    disabled={!val?.display_chart}
                                    name="chart_type"
                                    onChange={(checked) =>
                                      handleQuestionArrChange(
                                        checked === true ? "PIE" : "BAR",
                                        key,
                                        "chart_type"
                                      )
                                    }
                                  />
                                  <span className="mx-1">Pie Chart</span>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {val.question_type === "MULTIPLE CHOICE" ? (
                            <>
                              <div
                                className="row my-3"
                                key={"multiple_choice_" + key}
                              >
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Question Text
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Input
                                    name="multiple_choice_question"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={(e) =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "multiple_choice_question"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "Question Text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Quiz or Survey?
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="quiz_or_survey"
                                    className="fs-bold-14"
                                    onChange={(val) =>
                                      handleQuestionArrChange(
                                        val,
                                        key,
                                        "multi_select_question_type"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "quiz_or_survey"
                                      )
                                    }
                                    getPopupContainer={(triggerNode) => {
                                      return triggerNode.parentNode;
                                    }}
                                  >
                                    <Option value="QUIZ">Quiz</Option>
                                    <Option value="SURVEY">Survey</Option>
                                  </Select>
                                  {validator.current.message(
                                    "quiz_or_survey",
                                    val?.multi_select_question_type,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              {val.answer_details &&
                                val.answer_details.map((elem, index) => (
                                  <>
                                    <div className="row my-3">
                                      <div
                                        className={
                                          Tablet
                                            ? "col-4 text-end"
                                            : "col-3 text-end"
                                        }
                                      >
                                        Answer {index + 1}
                                        <RedAsterick />
                                      </div>
                                      <div
                                        className={Tablet ? "col-5" : "col-4"}
                                      >
                                        <Input
                                          name={`option_multiple_choice_${index}`}
                                          maxLength={INPUT_TEXT_LIMITS.MAX}
                                          value={elem.option}
                                          className="border-gray rounded"
                                          placeholder="Yes"
                                          onChange={(e) =>
                                            handleQuestionArrAnswerChange(
                                              e.target.value,
                                              key,
                                              index,
                                              "option"
                                            )
                                          }
                                          onBlur={() =>
                                            validator.current.showMessageFor(
                                              `option_multiple_choice_${index}`
                                            )
                                          }
                                        />
                                        {validator.current.message(
                                          "answer",
                                          elem?.option,
                                          "required",
                                          { className: "text-danger py-1" }
                                        )}
                                      </div>
                                      <div
                                        className={Tablet ? "col-1" : "col-2"}
                                      >
                                        {val?.multi_select_question_type ===
                                        "QUIZ" ? (
                                          <>
                                            <Checkbox
                                              onChange={(e) => {
                                                handleQuestionArrAnswerChange(
                                                  e.target.checked,
                                                  key,
                                                  index,
                                                  "is_default_selected"
                                                );
                                              }}
                                            />
                                            <span className="fs-pink ms-2">
                                              Correct
                                            </span>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="col-1"
                                        onClick={() =>
                                          handleDeleteAnswer(key, index)
                                        }
                                      >
                                        <Bin className="" />
                                      </div>
                                    </div>
                                  </>
                                ))}

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                ></div>
                                <div className="col-4">
                                  <Button
                                    type="primary"
                                    onClick={() => addAnotherAnswer(key)}
                                  >
                                    + Add Another Answer
                                  </Button>
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Display Chart ?
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <Switch
                                    name="display_chart"
                                    onChange={(checked) =>
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "display_chart"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Chart Type ?
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <span className="mx-1">Bar Chart</span>
                                  <Switch
                                    disabled={!val.display_chart}
                                    name="chart_type"
                                    onChange={(checked) =>
                                      handleQuestionArrChange(
                                        checked === true ? "PIE" : "BAR",
                                        key,
                                        "chart_type"
                                      )
                                    }
                                  />
                                  <span className="mx-1">Pie Chart</span>
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  One Question per Page?
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="ques_per_page"
                                    className="fs-bold-14"
                                    onChange={(val) =>
                                      handleQuestionArrChange(
                                        val,
                                        key,
                                        "one_question_per_page"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "ques_per_page"
                                      )
                                    }
                                    getPopupContainer={(triggerNode) => {
                                      return triggerNode.parentNode;
                                    }}
                                  >
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                  </Select>
                                  {validator.current.message(
                                    "ques_per_page",
                                    val?.one_question_per_page,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                          {val.question_type === "FREEFORM TEXT" ? (
                            <>
                              <div className="row my-3" key={"freeform_" + key}>
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Question Text
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Input
                                    name="freeform_question"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={(e) =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "freeform_question"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "Question Text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Required / Obligatory (*)
                                </div>
                                <div className={Tablet ? "col-6" : "col-4"}>
                                  <Switch
                                    name="obligatory"
                                    onChange={(checked) =>
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "is_required"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Format
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="format"
                                    className="fs-bold-14"
                                    onChange={(val) =>
                                      handleQuestionArrChange(
                                        val,
                                        key,
                                        "format"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor("format")
                                    }
                                    getPopupContainer={(triggerNode) => {
                                      return triggerNode.parentNode;
                                    }}
                                  >
                                    <Option value="MULTI LINE">
                                      Multiple line
                                    </Option>
                                    <Option value="SINGLE LINE">
                                      Single line
                                    </Option>
                                  </Select>
                                  {validator.current.message(
                                    "format",
                                    val?.format,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div
                                  className={
                                    Tablet ? "col-4 text-end" : "col-3 text-end"
                                  }
                                >
                                  Character Limit
                                  <RedAsterick />
                                </div>
                                <div className={Tablet ? "col-6" : "col-5"}>
                                  <input
                                    type="number"
                                    placeholder="Enter Value"
                                    style={{
                                      width: "100%",
                                    }}
                                    value={
                                      val?.answer_details?.[0]
                                        ?.format_line_character_limit
                                    }
                                    min={0}
                                    onKeyDown={handleCommonKeyDown}
                                    name="character_limit"
                                    className="fs-bold-14 border-gray py-1 px-2"
                                    onChange={(e) => {
                                      if (isValidNumber(e?.target?.value)) {
                                        handleQuestionArrAnswerChange(
                                          e.target.value,
                                          key,
                                          0,
                                          "format_line_character_limit"
                                        );
                                      }
                                    }}
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "character_limit"
                                      )
                                    }
                                  />

                                  {validator.current.message(
                                    "character_limit",
                                    val?.answer_details?.[0]
                                      ?.format_line_character_limit,
                                    "required|number",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </>
                    ))}
                </div>
                <div className="row my-2">
                  <div className="col-2"></div>

                  <div className="col-2 mt-4">
                    <Button
                      className=" px-3 d-block button-pink rounded"
                      onClick={addAnotherQuestion}
                    >
                      + Add Another Question
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="shadow-sm p-3 d-flex justify-content-between"
                style={{ backgroundColor: "#eeeeee" }}
              >
                {props.onCancelFromModal ? (
                  <Button
                    onClick={() => props.onCancelFromModal()}
                    className="rounded  px-3"
                  >
                    Cancel
                  </Button>
                ) : (
                  <Link to={`surveyManagement`}>
                    <Button className="px-3 d-block button-pink rounded">
                      Cancel
                    </Button>
                  </Link>
                )}

                <Button htmlType="submit" type="primary" disabled={loading}>
                  {!loading ? (
                    "Save Survey"
                  ) : (
                    <div style={{ width: "70px" }}>
                      <Spin indicator={loadingIcon} />
                    </div>
                  )}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
};

export default AddNewSurvey;
