import { Loop } from "@material-ui/icons";
import React, { useEffect } from "react";
import {
  clearInterval,
  clearTimeout,
  setInterval,
  setTimeout,
} from "worker-timers";

const CountDownTimer = ({
  hoursMinSecs,
  fetchTableLink,
  repeatShuffleTime,
  resetTimerToShuffleTime,
  showShuffleLoader,
  hideShuffleLoader,
}) => {
  const { minutes = 0, seconds = 0 } = hoursMinSecs;
  const [[mins, secs], setTime] = React.useState([minutes, seconds]);
  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  React.useEffect(() => {
    setTime([minutes, seconds]);
  }, [hoursMinSecs?.minutes]);

  const tick = () => {
    if (mins === 0 && secs === 0) {
      resetTimerToShuffleTime();
      // fetchTableLink();
      showShuffleLoader();
      setTimeout(() => {
        hideShuffleLoader();
      }, 2000);
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
      hideShuffleLoader();
    } else {
      setTime([mins, secs - 1]);
      hideShuffleLoader();
    }
  };

  const reset = () => {
    setTime([parseInt(repeatShuffleTime), parseInt(0)]);
  };

  return (
    <div>
      <span>{`${mins.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`}</span>
    </div>
  );
};

export default CountDownTimer;
