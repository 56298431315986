import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCallForm, ApiCall } from "../../utils/api";

const initialState = {
  assetLists: {},
  loading: false,
};

export const createAssets = createAsyncThunk(
  "createAssets/createAssets",

  async (body) => {
    // const response=await ApiCallForm("POST",params,"/admin/events/create","", "")
    const response = await ApiCallForm(
      "POST",
      body,
      "/admin/events/assets/create",
      ""
    );

    return response;
  }
);

export const updateAssets = createAsyncThunk(
  "createAssets/updateAssets",

  async (body) => {
    // const response=await ApiCallForm("POST",params,"/admin/events/create","", "")
    const response = await ApiCallForm(
      "PUT",
      body,
      "/admin/events/assets/updates",
      ""
    );

    return response;
  }
);

export const deleteAssets = createAsyncThunk(
  "createAssets/deleteAssets",

  async (params) => {
    const response = await ApiCall(
      "DELETE",
      params,
      "/admin/events/assets/delete",
      "",
      ""
    );

    return response;
  }
);

export const createAssetsSlice = createSlice({
  name: "createAssets",
  initialState,
  reducers: {},
  extraReducers: {
    [createAssets.pending]: (state) => {
      state.loading = true;
    },
    [createAssets.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.assetLists = payload;
    },
    [createAssets.rejected]: (state) => {
      state.loading = false;
    },

    [updateAssets.pending]: (state) => {
      state.loading = true;
    },
    [updateAssets.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.assetLists = payload;
    },
    [updateAssets.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const createAssetsReducer = createAssetsSlice.reducer;
