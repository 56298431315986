import React, { useState, useEffect } from "react";
import { Button, Modal, Row } from "antd";
import { cloneDeep } from "lodash";
import ICalendarLink from "react-icalendar-link";
import { Booth } from "../../components/svgJS/Booth";
import CommonLoader from "../../components/Widgets/CommonLoader";
import "./commonPopup.css";
import { createTZString } from "../../utils/commonFunctions";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
import moment from "moment";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { useDispatch } from "react-redux";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";

const ZoneSessionView = (props) => {
  const dispatch = useDispatch();
  const { data } = props;
  const [icalData, setIcalData] = useState(null);
  const [localData, setLocalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [canJoinSession, setCanJoinSession] = useState(false);

  useEffect(async () => {
    if (data) {
      setLoading(true);
      let dataToModify = cloneDeep(data);

      if (
        dataToModify &&
        dataToModify.start_date_time &&
        dataToModify.end_date_time
      ) {
        const d1 = new Date(dataToModify.start_date_time);
        const st = d1.toISOString().replace("Z", "");
        const d2 = new Date(dataToModify.end_date_time);
        const et = d2.toISOString().replace("Z", "");
        dataToModify["startTime"] = st;
        dataToModify["endTime"] = et;

        if (dataToModify.session_title)
          dataToModify["title"] = dataToModify.session_title;
        if (dataToModify.session_description)
          dataToModify["description"] = dataToModify.session_description;
        setIcalData(dataToModify);
      }

      const start_date_time = createTZString(data.start_date_time);
      const end_date_time = createTZString(data.end_date_time);

      setLocalData({
        ...data,
        start_date_time: start_date_time
          ? moment(start_date_time.format("HH:mm"), TIME_FORMAT).format("HH:mm")
          : "NA",
        end_date_time: end_date_time
          ? moment(end_date_time.format("HH:mm"), TIME_FORMAT).format("HH:mm")
          : "NA",
      });
      const currentDate = moment().format("YYYY-MM-DD");
      const currentTime = moment(moment().format("HH:mm"), TIME_FORMAT);
      const sessStartTime = moment(
        start_date_time.format("HH:mm"),
        TIME_FORMAT
      );
      const sessEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);
      if (
        currentDate === data.start_date &&
        currentTime >= sessStartTime &&
        currentTime <= sessEndTime
      )
        setCanJoinSession(true);

      setLoading(false);
    }
  }, []);

  const saveResponseData = async (bool) => {
    let objToPass = {};
    if (bool) objToPass = { ...data, rsvp_type: "YES" };
    else objToPass = { ...data, rsvp_type: "NO" };
    data !== null &&
      (await ApiCall("POST", objToPass, "/admin/mycalendarrsvp/save", null)
        .then((response) => {
          if (response.data.code === 200) {
            toast.success(response.data.message);
            window.location.reload();
            // getMyCalendarAppointmentDetails()
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        }));
  };

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "w-100 p-4"
              : "w-100"
          }
        >
          <Row>
            <span className="popup_label">Title :</span>
          </Row>
          <Row>
            <b>{localData?.session_title ?? "NA"}</b>
          </Row>
          <Row>
            <span className="popup_label">Time :</span>
          </Row>
          <Row>
            <b>
              {/* {localData?.start_date_time ??
                "NA" + " - " + localData?.end_date_time ??
                "NA"} */}
              {localData?.start_date_time + " - " + localData?.end_date_time}
            </b>
          </Row>
          <Row>
            <span className="popup_label">Description :</span>
          </Row>
          <Row>
            <b>{localData?.session_description ?? "NA"}</b>
          </Row>

          <Row>
            {localData?.is_iCal_enabled === true &&
            window.navigator.platform.toLowerCase().includes("mac") ? (
              <div className="d-flex mb-3">
                <ICalendarLink
                  className="audiEvent_downloadBtn"
                  event={icalData}
                >
                  <div>
                    <span>Download iCal</span>
                  </div>
                </ICalendarLink>
              </div>
            ) : null}
          </Row>
          {props?.data?.rsvp_type === "PENDING" ? (
            <>
              <div className="d-flex justify-content-end px-3">
                <Button
                  type="primary"
                  className="button-pink py-1 rounded"
                  onClick={() => saveResponseData(true)}
                >
                  Accept
                </Button>
                <Button
                  type="primary"
                  className="button-pink py-1 mx-3 rounded"
                  onClick={() => saveResponseData(false)}
                >
                  Reject
                </Button>
              </div>
            </>
          ) : canJoinSession ? (
            <div className="w-100 d-flex justify-content-end mt-4 mb-2">
              <div className="w-50"></div>
              <Button
                className="audiEvent_joinBtn mx-4 fs-16"
                onClick={() =>
                  dispatch(
                    setVideoConferenceData({
                      videoConferenceFlag: true,
                      videoConferenceUrl: localData?.join_session_url,
                      createPollFlag: localData?.is_producer_curator,
                      is_producer_curator: localData?.is_producer_curator,
                      isFromNetworking: false,
                      auditorium_session_id: localData?.auditorium_session_id,
                      auditorium_configuration_id:
                        localData?.auditorium_configuration_id,
                      is_speaker: localData?.is_speaker,
                      isPollOptionsVisible: false,
                      isFromProductZone: true,
                    })
                  )
                }
              >
                <Booth />
                <span className="ms-2">Join Session</span>
              </Button>
            </div>
          ) : null}
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  return (
    <Modal
      title={"Product Zone - Session"}
      visible={true}
      width={950}
      onOk={() => {
        if (!loading) props.onCancel();
      }}
      onCancel={() => {
        if (!loading) props.onCancel();
      }}
      footer={null}
    >
      {renderUI()}
    </Modal>
  );
};

export default ZoneSessionView;
