import React, { useState } from "react";
import "./DemoRoom.css";
import {
  SearchOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Button,
  Table,
  Carousel,
} from "antd";

import { Booth } from "../../components/svgJS/Booth";
import { EyeView } from "../../components/svgJS/EyeView";

import { BoothRed } from "../../components/svgJS/BoothRed";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";

import { Download } from "../../components/svgJS/Download";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";

const DemoRoom = () => {
  // Start of Demo Room Modal
  const [isDemoRoomModalVisible, setIsDemoRoomModalVisible] = useState(false);

  const showDemoRoomModal = () => {
    setIsDemoRoomModalVisible(true);
  };

  const handleDemoRoomOk = () => {
    setIsDemoRoomModalVisible(false);
  };

  const handleDemoRoomCancel = () => {
    setIsDemoRoomModalVisible(false);
  };

  // End of Demo Room Modal

  return (
    <>
      <Button className="ms-2" type="primary" onClick={showDemoRoomModal}>
        Open Demo Room Modal
      </Button>

      {/* Start of Demo Room Modal */}
      <Modal
        title=""
        visible={isDemoRoomModalVisible}
        onOk={handleDemoRoomOk}
        onCancel={handleDemoRoomCancel}
        className="demoroom_modal"
        width={650}
        footer={null}
      >
        <div className="demoroom_container w-100 p-4">
          <h6 className="opacity-75">Title :</h6>
          <h5>Speed Dating for Sales People</h5>
          <h6 className="opacity-75">Time :</h6>
          <p>14.10 - 15.10</p>
          <h6 className="opacity-75">Description :</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className="d-flex mb-3">
            <Button className="demoroom_downloadBtn">
              <Download />
              <span className="ms-2">Download iCal </span>
            </Button>
          </div>
          <h5>Speaker(s)</h5>
          <div className="d-flex p-3 border mx-4">
            <img
              width={120}
              height={120}
              src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
            />
            <div className="ms-3">
              <span className="opacity-75">Speaker</span>
              <h6>Alastair Reece</h6>
              <span>Bio</span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt magna aliqua.
              </p>
            </div>
          </div>

          <div className="w-100 d-flex mt-4 mb-4">
            <div className="w-75"></div>
            <Button className="demoroom_audiBtn mx-4 fs-16">
              <BoothRed />
              <span className="ms-2"> Join Session</span>
            </Button>
          </div>
        </div>
      </Modal>
      {/* End of Demo Room Modal */}
    </>
  );
};

export default DemoRoom;
