import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "./components/constsnts/common.constants";
import { GoogleOAuthProvider } from "@react-oauth/google";

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(COMETCHAT_CONSTANTS.REGION)
  .build();

CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(
  () => {
    ReactDOM.render(
      <Provider store={store}>
        <GoogleOAuthProvider clientId="3063522176-29sa410t8ct91po6oq6urjvg35slo4rg.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </Provider>,
      document.getElementById("root")
    );
  },
  (error) => {
    console.log("initialization failed with error = ", error);
  }
);
