import React from "react";
import { Result } from "antd";
const PageNotFound = () => {
  return (
    <div className="m-auto text-center h-100" style={{ height: "150%" }}>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        className="h-100"
      />
    </div>
  );
};
export default PageNotFound;
