/**
 * Vista component route
 */

import React, { useContext, useEffect } from "react";
import VistaContext from "../../contexts/VistaContext";
import AppContext from "../../contexts/AppContext";
import VistaFrame from "./styles";

const Vista = (props) => {
	const { frame } = useContext(VistaContext);
	const { src } = useContext(AppContext);

	if (src.includes("html") || src.includes("htm")) {
		return (
			<VistaFrame
				ref={frame}
				// src={`${process.env.PUBLIC_URL}${src}`}
				src={`${src}`}
				// src={props.url}
				title="View"
				name="vistaFrame"
				id="FrameID"
			/>
		);
	} else {
		return (
			<div className="flex_center h-100">
				<h1>Vista File Not Uploaded</h1>
			</div>
		);
	}
};

export default Vista;
