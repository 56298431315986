import moment from "moment";
import { toast } from "react-toastify";
import { ApiCall } from "../../../../../../utils/api";

export const getBrandZoneData = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/zone/card/details/get",
    params
  );

  return response;
};

export const getAudiSourceDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/vista/auditoriumsourcedetails/get",
    params
  );

  if (response?.data?.code === 200) {
    return response.data.data;
  } else {
    // toast.error(response?.data?.message);
    return null;
  }
};

export const getReceptionZoneDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/vista/eventdefaultzone/get",
    params
  );

  return response;
};

export const getConcourseDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/vista/concourselocation/details/get",
    params
  );
  if (response?.data?.code === 200) {
    return response;
  } else {
    toast.error(response?.data?.message);
    return null;
  }
};

//Need different response thats why made this api seperate dont remove it.
export const getSpecificZoneData = async (params) => {
  let paramsToSend = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    current_timestamp: moment().format("YYYY-MM-DD"),
  };
  if (params) paramsToSend = { ...params, ...paramsToSend };
  const response = await ApiCall(
    "get",
    null,
    "/admin/zones/details/get",
    paramsToSend
  );
  return response;
};
