import React, { useState, useEffect } from "react";
import { Switch, Button, Breadcrumb, Input, DatePicker } from "antd";
import { Link } from "react-router-dom";
import { EmailRed } from "../../components/svgJS/EmailRed";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";
import { useMediaQuery } from "react-responsive";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerDetails,
  editCustomer,
} from "../../redux/slice/customerManagementSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import {
  createISOTimeString,
  createTZString,
  isRouteExistInMenu,
  todayIsBeetweenTwoDates,
} from "../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { INPUT_TEXT_LIMITS } from "../../components/constsnts/common.constants";
import { ApiCall } from "../../utils/api";
import { cloneDeep } from "lodash";

const EditCustomer = (props) => {
  var CryptoJS = require("crypto-js");
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const dispatch = useDispatch();
  const { match } = props;
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [customerData, setCustomerData] = useState(null);
  const [showNewEmail, setShowNewEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable_switch, set_editable_switch] = useState(false);
  const [user_type, set_user_type] = useState("");
  const [initialState, setInitialState] = useState({
    is_delete: false,
    is_active: false,
    account_name: "",
    license_period_start_date: null,
    license_period_end_date: null,
    main_contact_person: "",
    main_contact_email: "",
    new_email: "",
    is_exhibitor_booth_available: false,
    orgaziner_brand: 1,
    is_organizer_booth_available: false,
    max_no_of_events: 1,
    max_no_of_concurrent_events: 1,
    max_no_events_per_month: 1,
    max_no_of_exhibitors: 1,
    max_no_of_exhibitors_per_event: 1,
    max_no_of_users: 1,
    max_no_of_concurrent_users: 1,
    max_bandwidth_per_month: 1,
    max_storage_size: 1,
    max_video_room_hours_per_month: 1,
    max_no_of_events_switch: true,
    max_no_of_concurrent_events_switch: true,
    max_no_events_per_month_switch: true,
    max_no_of_exhibitors_switch: true,
    max_no_of_exhibitors_per_event_switch: true,
    max_no_of_users_switch: true,
    max_no_of_concurrent_users_switch: true,
    max_bandwidth_per_month_switch: true,
    max_storage_size_switch: true,
    max_video_room_hours_per_month_switch: true,
  });
  const [checkIfEmailExist, setCheckIfEmailExist] = useState("");
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({
    flag: false,
    errorMess: "",
  });

  useEffect(async () => {
    setLoading(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    set_user_type(decryptedData);

    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      getCustomerDetail();
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    //Below code is to detect real time if email exist or not
    const timer = setTimeout(() => {
      if (checkIfEmailAlreadyExist !== "") {
        checkIfEmailAlreadyExist();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [checkIfEmailExist]);

  const getCustomerDetail = async () => {
    const responce = await dispatch(
      getCustomerDetails({ customer_id: match.params.customer_id })
    );

    const { details } = responce?.payload?.data?.data;
    if (details) {
      const start_date_time = createTZString(details.license_period_start_date);
      const end_date_time = createTZString(details.license_period_end_date);
      const eleStartDate = moment(
        start_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      const eleEndDate = moment(
        end_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      setInitialState({
        ...initialState,
        is_delete: details.is_delete,
        is_active: details.is_active,
        account_name: details.account_name,
        license_period_start_date: eleStartDate.format("YYYY-MM-DD"),
        license_period_end_date: eleEndDate.format("YYYY-MM-DD"),
        main_contact_person: details.main_contact_person,
        main_contact_email: details.main_contact_email,
        is_exhibitor_booth_available: details.is_exhibitor_booth_available,
        orgaziner_brand: details.orgaziner_brand,
        is_organizer_booth_available: details.is_organizer_booth_available,
        max_no_of_events:
          details.customer_customer_settings_id?.max_no_of_events,
        max_no_of_concurrent_events:
          details.customer_customer_settings_id?.max_no_of_concurrent_events,
        max_no_events_per_month:
          details.customer_customer_settings_id?.max_no_events_per_month,
        max_no_of_exhibitors:
          details.customer_customer_settings_id?.max_no_of_exhibitors,
        max_no_of_exhibitors_per_event:
          details.customer_customer_settings_id?.max_no_of_exhibitors_per_event,
        max_no_of_users: details.customer_customer_settings_id?.max_no_of_users,
        max_no_of_concurrent_users:
          details.customer_customer_settings_id?.max_no_of_concurrent_users,
        max_bandwidth_per_month: parseInt(
          details.customer_customer_settings_id?.max_bandwidth_per_month
        ),
        max_storage_size: parseInt(
          details.customer_customer_settings_id?.max_storage_size
        ),
        max_video_room_hours_per_month: parseInt(
          details.customer_customer_settings_id?.max_video_room_hours_per_month
        ),

        max_no_of_events_switch:
          details.customer_customer_settings_id?.max_no_of_events === 0
            ? false
            : true,
        max_no_of_concurrent_events_switch:
          details.customer_customer_settings_id?.max_no_of_concurrent_events ===
          0
            ? false
            : true,
        max_no_events_per_month_switch:
          details.customer_customer_settings_id?.max_no_events_per_month === 0
            ? false
            : true,
        max_no_of_exhibitors_switch:
          details.customer_customer_settings_id?.max_no_of_exhibitors === 0
            ? false
            : true,
        max_no_of_exhibitors_per_event_switch:
          details.customer_customer_settings_id
            ?.max_no_of_exhibitors_per_event === 0
            ? false
            : true,
        max_no_of_users_switch:
          details.customer_customer_settings_id?.max_no_of_users === 0
            ? false
            : true,
        max_no_of_concurrent_users_switch:
          details.customer_customer_settings_id?.max_no_of_concurrent_users ===
          0
            ? false
            : true,
        max_bandwidth_per_month_switch:
          details.customer_customer_settings_id?.max_bandwidth_per_month === 0
            ? false
            : true,
        max_storage_size_switch:
          details.customer_customer_settings_id?.max_storage_size === 0
            ? false
            : true,
        max_video_room_hours_per_month_switch:
          details.customer_customer_settings_id
            ?.max_video_room_hours_per_month === 0
            ? false
            : true,
      });
      const isValidActive = todayIsBeetweenTwoDates(
        moment(details?.license_period_start_date),
        moment(details?.license_period_end_date)
      );
      set_editable_switch(isValidActive);

      setCustomerData(details);
    }
  };

  const handleCreateCustomer = async (dataToUpdate) => {
    let clonedData = cloneDeep(dataToUpdate);
    const start_date_str =
      moment(clonedData.license_period_start_date).format("YYYY/MM/DD HH:mm") +
      " " +
      moment().format("HH:mm");
    const start_utc = createISOTimeString(start_date_str);
    const end_date_str =
      moment(clonedData.license_period_end_date).format("YYYY/MM/DD HH:mm") +
      " " +
      moment().format("HH:mm");
    const end_utc = createISOTimeString(end_date_str);

    clonedData.license_period_start_date = start_utc;
    clonedData.license_period_end_date = end_utc;

    const formData = {
      customer_id: parseInt(match.params.customer_id),
      customer_settings_id:
        customerData?.customer_customer_settings_id?.customer_settings_id,
      ...clonedData,
    };
    if (clonedData.new_email) {
      formData.main_contact_email = clonedData.new_email;
    }
    delete formData.new_email;
    const addcustomerResp = await dispatch(editCustomer(formData));
    if (addcustomerResp.payload.code === 200) {
      toast.success(addcustomerResp.payload.message);
      props.history.push("/customermanagement");
    }
    if (addcustomerResp.payload.code !== 200) {
      toast.error(addcustomerResp.payload.message);
    }
  };

  const handleResendInvite = async (email) => {
    const responce = await ApiCall(
      "POST",
      { main_contact_email: email },
      "/website/customer/re-invitation/send"
    );

    if (responce?.data?.code) {
      toast.success(responce?.data?.message);
    }
  };

  const ValidationSchema = Yup.object().shape({
    account_name: Yup.string().required("Required").nullable(),

    main_contact_person: Yup.string()
      .required("Required")
      .nullable()
      .matches(/^[^!@#$%^&*+=<>.,:;|~0-9]*$/, {
        message: "Only Alphabets Are Allowed",
      }),
    new_email: Yup.string().email("Please Enter Valid Email").nullable(),
    license_period_start_date: Yup.date().required("Required").nullable(),
    license_period_end_date: Yup.date()
      .min(
        Yup.ref("license_period_start_date"),
        "End Date should be greater than Start Date"
      )
      .required("Required")
      .nullable(),
  });

  const checkIfEmailAlreadyExist = () => {
    ApiCall("get", null, "/website/customer/checkexist", {
      main_contact_email: checkIfEmailExist,
    }).then((response) => {
      console.log("resp", response);
      if (response?.data?.code === 500) {
        setShowEmailErrorMessage({
          flag: true,
          errorMess: response?.data?.message,
        });
      } else {
        setShowEmailErrorMessage({
          flag: false,
          errorMess: response?.data?.message,
        });
      }
    });
  };

  if (loading) return <CommonLoader />;
  else
    return (
      <Formik
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={initialState}
        onSubmit={(values, { setSubmitting }) => {
          handleCreateCustomer(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
          /* and other goodies */
        }) => (
          <div className="view-all-common">
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-between common-sticky-header bg-light w-100">
                <div>
                  <h4 className="fs-24">Edit Customer Account</h4>
                  <Breadcrumb className="my-2" separator=">">
                    <Breadcrumb.Item>
                      <Link to="/customermanagement">Admin Console</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/customermanagement">Customer Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Edit Customer Account</Breadcrumb.Item>
                  </Breadcrumb>
                </div>

                <div></div>
              </div>

              <div className="bg-white p-3">
                <section>
                  <div className="row mt-5">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20 px-2">
                        Account Details
                      </h6>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary">
                        Active
                      </label>
                    </div>
                    <div className={Tablet ? "col-2" : "col-1"}>
                      <Switch
                        disabled={!editable_switch}
                        checked={values.is_active}
                        name="is_active"
                        onChange={() => {
                          user_type === "SUPER ADMIN" &&
                            setFieldValue("is_active", !values.is_active);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary mt-1">
                        Account Name
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <Input
                        name="account_name"
                        className="w-100 border-gray rounded fs-bold-14"
                        value={values.account_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.account_name && touched.account_name ? (
                        <div className="text-danger">{errors.account_name}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary">
                        License Start Date
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <DatePicker
                        allowClear={false}
                        suffixIcon={<CalenderGrey width="15" />}
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        format="DD-MM-YYYY"
                        className="w-100 fs-bold-14"
                        name="license_period_start_date"
                        value={moment(values.license_period_start_date)}
                        // format="YYYY/MM/DD"
                        onChange={(dateObj, date) => {
                          setFieldValue("license_period_start_date", dateObj);

                          const ed = values.license_period_end_date;
                          const sd = dateObj;
                          const isValidActive = todayIsBeetweenTwoDates(sd, ed);

                          if (isValidActive && ed !== null) {
                            setFieldValue("is_active", true);
                            set_editable_switch(true);
                          } else {
                            setFieldValue("is_active", false);
                            set_editable_switch(false);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.license_period_start_date &&
                      touched.license_period_start_date ? (
                        <div className="text-danger">
                          {errors.license_period_start_date}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary">
                        License End Date
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <DatePicker
                        allowClear={false}
                        suffixIcon={<CalenderGrey width="15" />}
                        format="DD-MM-YYYY"
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        className="w-100 fs-bold-14"
                        name="license_period_end_date"
                        // defaultValue={moment(values.license_period_end_date,'DD-MM-YYYY')}
                        value={moment(values.license_period_end_date)}
                        onChange={(dateObj, date) => {
                          setFieldValue("license_period_end_date", dateObj);

                          const sd = values.license_period_start_date;
                          const ed = dateObj;
                          const isValidActive = todayIsBeetweenTwoDates(sd, ed);

                          if (isValidActive && sd !== null) {
                            setFieldValue("is_active", true);
                            set_editable_switch(true);
                          } else {
                            setFieldValue("is_active", false);
                            set_editable_switch(false);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.license_period_end_date &&
                      touched.license_period_end_date ? (
                        <div className="text-danger">
                          {errors.license_period_end_date}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary mt-1">
                        Main Contact Name
                        <RedAsterick />
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <Input
                        name="main_contact_person"
                        onChange={handleChange}
                        className="w-100 border-gray rounded fs-bold-14"
                        value={values.main_contact_person}
                        onBlur={handleBlur}
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                      {errors.main_contact_person &&
                      touched.main_contact_person ? (
                        <div className="text-danger">
                          {errors.main_contact_person}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-4 pb-4 border-bottom-gray">
                    <div
                      className={Tablet ? "col-4 text-end" : "col-3 text-end"}
                    >
                      <label htmlFor="" className="text-secondary mt-1">
                        Email Address
                      </label>
                    </div>
                    <div className={Tablet ? "col-5" : "col-3"}>
                      <Input
                        disabled
                        name="main_contact_email"
                        onChange={handleChange}
                        className="w-100 border-gray rounded fs-bold-14"
                        value={values.main_contact_email}
                        onBlur={handleBlur}
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                      />
                    </div>
                    {Tablet ? <div className="col-4"></div> : ""}
                    <div className={Tablet ? "col-6 mt-3" : "col-6"}>
                      <Button
                        onClick={() => setShowNewEmail(true)}
                        className="px-3 font-pink button-pink rounded font-normal btn-svg"
                      >
                        <EmailRed /> <span className="mx-1"> Change</span>
                      </Button>

                      <Button
                        onClick={() =>
                          handleResendInvite(values?.main_contact_email)
                        }
                        className="px-3 ms-2 font-pink button-pink rounded font-normal btn-svg"
                      >
                        Re-send Invite
                      </Button>
                    </div>
                    {Tablet ? <div className="col-4"></div> : ""}
                  </div>
                  {showNewEmail && (
                    <>
                      <div className="row my-4">
                        {Tablet ? <div className="col-2"></div> : ""}

                        <div className="col-2 text-end">
                          New Email Address
                          <RedAsterick />
                        </div>
                        <div className="col-3">
                          <Input
                            name="new_email"
                            onChange={(e) => {
                              handleChange(e);
                              setCheckIfEmailExist(e.target.value);
                            }}
                            className="w-100 border-gray rounded fs-bold-14"
                            value={values.new_email}
                            onBlur={handleBlur}
                            maxLength={INPUT_TEXT_LIMITS.MAX}
                          />
                          {errors.new_email && touched.new_email ? (
                            <div className="text-danger">
                              {errors.new_email}
                            </div>
                          ) : null}

                          {showEmailErrorMessage?.flag ? (
                            <>
                              <span className="fs-pink">
                                {showEmailErrorMessage?.errorMess}
                              </span>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-3 text-end" : "col-2 text-end"
                          }
                        ></div>
                        <div className="col-6">
                          <span className="fs-16">
                            <b>NB: </b>This will send an email to the users new
                            email address <br /> (to create a new password).
                            Their old email address / password will no longer
                            work.
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </section>

                {/* section Account Permissions */}
                <section>
                  <div className="row">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20 px-2">
                        Account Permissions
                      </h6>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Events
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_events_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_events", 0);
                          }
                          setFieldValue(
                            "max_no_of_events_switch",
                            !values.max_no_of_events_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_events <= 0 ||
                            values.max_no_of_events_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_events",
                              values.max_no_of_events - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={values.max_no_of_events_switch !== true}
                          type="text"
                          style={{
                            width: "50%",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                            textAlign: "center",
                          }}
                          value={values.max_no_of_events}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_events",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={values.max_no_of_events_switch !== true}
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_events",
                              values.max_no_of_events + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Concurrent Events
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_concurrent_events_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_concurrent_events", 0);
                          }
                          setFieldValue(
                            "max_no_of_concurrent_events_switch",
                            !values.max_no_of_concurrent_events_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_concurrent_events <= 0 ||
                            values.max_no_of_concurrent_events_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_concurrent_events",
                              values.max_no_of_concurrent_events - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_no_of_concurrent_events_switch !== true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_no_of_concurrent_events}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_concurrent_events",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_no_of_concurrent_events_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_concurrent_events",
                              values.max_no_of_concurrent_events + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Events Per Month
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_events_per_month_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_events_per_month", 0);
                          }
                          setFieldValue(
                            "max_no_events_per_month_switch",
                            !values.max_no_events_per_month_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_events_per_month <= 0 ||
                            values.max_no_events_per_month_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_events_per_month",
                              values.max_no_events_per_month - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_no_events_per_month_switch !== true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_no_events_per_month}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_events_per_month",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_no_events_per_month_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_events_per_month",
                              values.max_no_events_per_month + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Brands
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_exhibitors_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_exhibitors", 0);
                          }
                          setFieldValue(
                            "max_no_of_exhibitors_switch",
                            !values.max_no_of_exhibitors_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_exhibitors <= 0 ||
                            values.max_no_of_exhibitors_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_exhibitors",
                              values.max_no_of_exhibitors - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={values.max_no_of_exhibitors_switch !== true}
                          type="text"
                          style={{
                            width: "50%",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                            textAlign: "center",
                          }}
                          value={values.max_no_of_exhibitors}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_exhibitors",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={values.max_no_of_exhibitors_switch !== true}
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_exhibitors",
                              values.max_no_of_exhibitors + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Brands Per Event
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_exhibitors_per_event_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_exhibitors_per_event", 0);
                          }
                          setFieldValue(
                            "max_no_of_exhibitors_per_event_switch",
                            !values.max_no_of_exhibitors_per_event_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_exhibitors_per_event <= 0 ||
                            values.max_no_of_exhibitors_per_event_switch !==
                              true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_exhibitors_per_event",
                              values.max_no_of_exhibitors_per_event - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_no_of_exhibitors_per_event_switch !==
                            true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_no_of_exhibitors_per_event}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_exhibitors_per_event",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_no_of_exhibitors_per_event_switch !==
                            true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_exhibitors_per_event",
                              values.max_no_of_exhibitors_per_event + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Users
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_users_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_users", 0);
                          }
                          setFieldValue(
                            "max_no_of_users_switch",
                            !values.max_no_of_users_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_users <= 0 ||
                            values.max_no_of_users_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_users",
                              values.max_no_of_users - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={values.max_no_of_users_switch !== true}
                          type="text"
                          style={{
                            width: "50%",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                            textAlign: "center",
                          }}
                          value={values.max_no_of_users}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue("max_no_of_users", parseInt(value));
                            }
                          }}
                        />

                        <button
                          disabled={values.max_no_of_users_switch !== true}
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_users",
                              values.max_no_of_users + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        Max # Concurrent Users
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_no_of_concurrent_users_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_no_of_concurrent_users", 0);
                          }
                          setFieldValue(
                            "max_no_of_concurrent_users_switch",
                            !values.max_no_of_concurrent_users_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_no_of_concurrent_users <= 0 ||
                            values.max_no_of_concurrent_users_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_concurrent_users",
                              values.max_no_of_concurrent_users - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_no_of_concurrent_users_switch !== true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_no_of_concurrent_users}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_no_of_concurrent_users",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_no_of_concurrent_users_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_no_of_concurrent_users",
                              values.max_no_of_concurrent_users + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        {/* Max # Bandwidth per month Gb */}
                        Streams : Max Gb Bandwidth Per Month
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_bandwidth_per_month_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_bandwidth_per_month", 0);
                          }
                          setFieldValue(
                            "max_bandwidth_per_month_switch",
                            !values.max_bandwidth_per_month_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_bandwidth_per_month <= 0 ||
                            values.max_bandwidth_per_month_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_bandwidth_per_month",
                              values.max_bandwidth_per_month - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_bandwidth_per_month_switch !== true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_bandwidth_per_month}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_bandwidth_per_month",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={
                            values.max_bandwidth_per_month_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_bandwidth_per_month",
                              values.max_bandwidth_per_month + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        {/* Max # Storage Size Gb */}
                        Storage : Max Gb
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_storage_size_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_storage_size", 0);
                          }
                          setFieldValue(
                            "max_storage_size_switch",
                            !values.max_storage_size_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_storage_size <= 0 ||
                            values.max_storage_size_switch !== true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_storage_size",
                              values.max_storage_size - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={values.max_storage_size_switch !== true}
                          type="text"
                          style={{
                            width: "50%",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                            textAlign: "center",
                          }}
                          value={values.max_storage_size}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_storage_size",
                                parseInt(value)
                              );
                            }
                          }}
                        />

                        <button
                          disabled={values.max_storage_size_switch !== true}
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_storage_size",
                              values.max_storage_size + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 pb-4 border-bottom-gray">
                    <div
                      className={
                        Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                      }
                    >
                      <label htmlFor="" className="text-secondary">
                        {/* Max # Storage Size Gb */}
                        Video Rooms : Max Hours Per Month
                      </label>
                    </div>
                    <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                      <Switch
                        checked={values.max_video_room_hours_per_month_switch}
                        onChange={(bool) => {
                          if (!bool) {
                            setFieldValue("max_video_room_hours_per_month", 0);
                          }
                          setFieldValue(
                            "max_video_room_hours_per_month_switch",
                            !values.max_video_room_hours_per_month_switch
                          );
                        }}
                      />
                    </div>
                    <div className={Tablet ? "col-3" : "col-2 text-start"}>
                      <div
                        class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                        role="group"
                        aria-label="Second group"
                      >
                        <button
                          disabled={
                            values.max_video_room_hours_per_month <= 0 ||
                            values.max_video_room_hours_per_month_switch !==
                              true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_video_room_hours_per_month",
                              values.max_video_room_hours_per_month - 1
                            )
                          }
                          class="btn py-1 "
                        >
                          -
                        </button>

                        <input
                          disabled={
                            values.max_video_room_hours_per_month_switch !==
                            true
                          }
                          type="text"
                          style={{
                            width: "50%",
                            textAlign: "center",
                            backgroundColor: "#FBFBFB",
                            border: "none",
                          }}
                          value={values.max_video_room_hours_per_month}
                          onChange={(e) => {
                            const { value } = e.target;

                            const reg = /^-?\d*(\.\d*)?$/;
                            if (
                              reg.test(value) &&
                              value &&
                              !value.includes("-")
                            ) {
                              setFieldValue(
                                "max_video_room_hours_per_month",
                                parseInt(value)
                              );
                            }
                          }}
                        />
                        {/* </button> */}
                        <button
                          disabled={
                            values.max_video_room_hours_per_month_switch !==
                            true
                          }
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "max_video_room_hours_per_month",
                              values.max_video_room_hours_per_month + 1
                            )
                          }
                          class="btn  py-1 "
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </section>

                {/* section Cancel */}
                <section>
                  <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
                    <div>
                      <Link to="/customermanagement">
                        <Button className="rounded button-pink px-3">
                          Cancel
                        </Button>
                      </Link>
                    </div>
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="rounded  px-3"
                        onClick={() => {
                          if (!isValid) {
                            toast.error("Fields are Missing");
                          }
                          window.scrollTo(0, 0);
                        }}
                      >
                        {!loading ? (
                          "Save Changes"
                        ) : (
                          <div style={{ width: "80px" }}>
                            <Spin
                              indicator={loadingIcon}
                              style={{ color: "white" }}
                            />
                          </div>
                        )}
                      </Button>
                    </div>
                  </div>
                </section>
              </div>
            </form>
          </div>
        )}
      </Formik>
    );
};

export default EditCustomer;
