import { Button, Modal, Row } from "antd";
import React, { useState, useEffect } from "react";
import CommonLoader from "../../components/Widgets/CommonLoader";
import ICalendarLink from "react-icalendar-link";
import "./commonPopup.css";
import { addToMyCalender } from "../../utils/commonApis";
import { CalenderRed } from "../../components/svgJS/CalenderRed";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
import { createTZString } from "../../utils/commonFunctions";
import moment from "moment";

const AudiNetViewAdmins = (props) => {
  const { data } = props;
  const [localData, setLocalData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (
      data &&
      (data?.auditorium_configuration_id || data?.networking_configuration_id)
    ) {
      setLoading(true);

      const d1 = new Date(data.start_date_time);
      const st = d1.toISOString().replace("Z", "");
      const d2 = new Date(data.end_date_time);
      const et = d2.toISOString().replace("Z", "");

      const start_date_time = createTZString(data?.start_date_time);
      const end_date_time = createTZString(data?.end_date_time);

      setLocalData({
        ...data,
        startTime: st,
        endTime: et,
        title: data?.session_title,
        description: data?.session_description,
        start_date_time: moment(
          start_date_time.format("HH:mm"),
          TIME_FORMAT
        ).format("HH:mm"),
        end_date_time: moment(
          end_date_time.format("HH:mm"),
          TIME_FORMAT
        ).format("HH:mm"),
      });
      setLoading(false);
    }
  }, []);

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "ant-modal-body p-4"
              : ""
          }
        >
          <div className={"ant-modal-body"} style={{ marginTop: "-30px" }}>
            <div className="w-100">
              <Row>
                <span className="popup_label">Title :</span>
              </Row>
              <Row>
                <b>{localData?.session_title ?? "NA"}</b>
              </Row>
              <Row>
                <span className="popup_label mt-2">Time :</span>
              </Row>
              <Row>
                <b>
                  {localData?.start_date_time ??
                    "NA" + " - " + localData?.end_date_time ??
                    "NA"}
                </b>
              </Row>
              <Row>
                <span className="popup_label mt-2">Description :</span>
              </Row>
              <Row>
                <b>{localData?.session_description ?? "NA"}</b>
              </Row>
              <Row>
                {localData?.is_iCal_enabled === true &&
                window.navigator.platform.toLowerCase().includes("mac") ? (
                  <ICalendarLink
                    className="audiEvent_downloadBtn"
                    event={localData}
                  >
                    <div>
                      <span>Download iCal</span>
                    </div>
                  </ICalendarLink>
                ) : null}
                {window.location.pathname !== "/mycalendar" ? (
                  <Button
                    className="joinsess_calendarBtn mt-2"
                    onClick={async () => {
                      await addToMyCalender({
                        event_id: localData.event_id,
                        hall_type: localData.hall_type,
                        hall_id: localData?.auditorium_configuration_id,
                        session_id: localData?.auditorium_session_id,
                      });
                    }}
                  >
                    <CalenderRed />
                    <span className="ms-2">Add to My Calendar </span>
                  </Button>
                ) : null}
              </Row>
            </div>
          </div>
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  else
    return (
      <Modal
        title={"Session Details"}
        visible={true}
        onOk={() => {
          if (!loading) props.onCancel();
        }}
        onCancel={() => {
          if (!loading) props.onCancel();
        }}
        width={950}
        footer={null}
      >
        {renderUI()}
      </Modal>
    );
};

export default AudiNetViewAdmins;
