import React, { useState, useEffect } from "react";
import CommonLoader from "../../components/Widgets/CommonLoader";
import "./commonPopup.css";

import { Modal } from "antd";

const PastDateDelegate = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "w-100 p-4"
              : "w-100"
          }
        >
          <div className="row mx-5">
            {props.isFromCalender === "event"
              ? props?.message
              : "The session's date is passed now"}
          </div>
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  return (
    <Modal
      title={"Session Details"}
      visible={true}
      width={950}
      onOk={() => {
        if (!loading) {
          if (props.isFromCalender === "event") props.onCloseModal(false);
          else props.onCancel();
        }
      }}
      onCancel={() => {
        if (!loading) {
          if (props.isFromCalender === "event") props.onCloseModal(false);
          else props.onCancel();
        }
      }}
      footer={null}
    >
      {renderUI()}
    </Modal>
  );
};

export default PastDateDelegate;
