import React from "react";

const index = () => {
  return (
    <>
      <div>
        <h2>1. Introduction</h2>
        <p>
          At Gravit8 Software, we&#8217;re committed to protecting and
          respecting your privacy.
        </p>
        <p>This privacy policy explains how:</p>
        <ul>
          <li>
            we use any Personal Information we collect about you when you
            contact us, engage with our products and services or use our website
          </li>
          <li>
            how you can review, update or remove your Personal Information
          </li>
          <li>
            and how we process your Personal Information in line with the
            General Data Protection Regulation (GDPR).
          </li>
        </ul>
      </div>
      <div
        id="section2"
        class="page-section aos-init aos-animate"
        data-aos="fade-up"
      >
        <p>&nbsp;</p>
        <h2>2. Privacy policy</h2>
      </div>
      <div
        id="section3"
        class="page-section aos-init aos-animate"
        data-aos="fade-up"
      >
        <p>&nbsp;</p>
        <h2>2.1. Who are we?</h2>
        <p>
          We are Gravit8 Software, we create software for live events (virtual,
          physical and hybrid) and customer experience centres. Gravit8 Software
          is a company limited by guarantee (no. 13337088). The registered
          address is Spectrum 800, Ashchurch Business Centre, Alexandra Way,
          Tewkesbury. Gloucestershire. GL20 8TD.
        </p>
      </div>
      <div
        id="section4"
        class="page-section aos-init aos-animate"
        data-aos="fade-up"
      >
        <h2>2.2. What Personal Information do we collect about you?</h2>
        <p>
          We collect information about you when you contact us, use our products
          and services, or use our website.
          <br />
          If you contact us or use our products and services, we will collect
          your:
        </p>
        <ul>
          <li>First name</li>
          <li>Last name</li>
          <li>Company name</li>
          <li>Job title</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Any communications with us</li>
          <li>
            Your consent (or not) for us to use your information for our own
            marketing purposes
          </li>
        </ul>
        <p>By using our website, we will collect:</p>
        <ul>
          <li>your IP address</li>
          <li>your web browser</li>
          <li>your time zone</li>
          <li>the search terms that referred you to the site</li>
        </ul>

        <div
          id="section5"
          class="page-section aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>2.3. How will we use and store your Personal Information?</h2>
          <p>We collect information about you to:</p>
          <ul>
            <li>provide quotations</li>
            <li>make telephone and email contact</li>
            <li>help with the delivery of our products and services</li>
            <li>
              email you marketing information which we believe may be of
              interest to you and your business
            </li>
          </ul>
          <p>
            This Personal Information will be stored securely on our systems,
            and you have the right to review or update your data or have it
            removed at any time.
          </p>
          <p>
            We use your IP address and other information collected from the
            website to:
          </p>
          <ul>
            <li>personalise your repeat visits to our website</li>
            <li>
              help us collect statistics about the behaviour of visitors to our
              website
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            We also use Google Analytics to help us understand how visitors use
            our site. You can read more about how Google uses this information
            here:{" "}
            <a href="https://policies.google.com/privacy?hl=en">
              https://policies.google.com/privacy?hl=en
            </a>
            , and you can opt-out of Google Analytics here:{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
          </p>
          <p>
            In processing any orders, we may send some of your Personal
            Information to and also use information from credit reference
            agencies and fraud prevention agencies.
          </p>
          <p>
            Your Personal Information is held in the United Kingdom using
            multiple secure servers. We do not store or transmit your Personal
            Information outside of the EU.
          </p>
        </div>
        <div
          id="section6"
          class="page-section aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>
            2.4. How will we use your Personal Information for marketing
            purposes?
          </h2>
          <p>
            We would like to send you information about products and services of
            ours which may be of interest to you. If you have consented to
            receive marketing, you may opt out at a later date.
          </p>
          <p>
            We will not share your Personal Information with third party
            organisations without your explicit consent, with the legal
            exceptions noted in the When will we share your Personal
            Information? section below.
          </p>
          <p>
            You have a right at any time to stop us from contacting you for
            marketing purposes at any time. If you no longer wish to be
            contacted for marketing purposes, you can contact us using the
            information at the end of this Policy.
          </p>
        </div>
        <div
          id="section7"
          class="page-section aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>2.5. How are cookies used on our website?</h2>
          <p>
            Cookies are text files placed on your computer to collect standard
            internet log information and visitor behaviour information. This
            information is used to track use of the website and to compile
            statistical reports on website activity.
          </p>
          <p>
            For further information visit{" "}
            <a href="https://www.aboutcookies.org/">www.aboutcookies.org</a> or
            www.allaboutcookies.org
          </p>
          <p>
            You can set your browser not to accept cookies and the above
            websites tell you how to remove cookies from your browser. However
            in a few cases some of our website features may not function as a
            result.
          </p>
        </div>
        <div
          id="section8"
          class="page-section aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>2.6. When will we share your Personal Information?</h2>
          <p>
            We may occasionally need to pass your Personal Information to third
            parties (“processors”) exclusively to process work on our behalf. We
            contractually require all of our processors to agree to process this
            information based solely on our instructions, and consistent with
            this Privacy Policy and the GDPR.
          </p>
          <p>
            We may also need to disclose your Personal Information to meet legal
            obligations, regulations or a valid governmental request. We may
            also need to enforce our Terms and Conditions, including
            investigating potential violations of our Terms and Conditions to
            detect, prevent or mitigate fraud or security or technical issues,
            or to protect against imminent harm to the rights, property or
            safety of our clients or the wider community.
          </p>
          <p>
            As mentioned in the above section How will we use and store your
            Personal Information?, we also use Google Analytics to help us
            understand how visitors use our website.
          </p>
        </div>
        <div
          id="section9"
          class="page-section aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>
            2.7. Your rights to access, change or remove your Personal
            Information
          </h2>
          <p>
            At any point while we possess your Personal Information, you have
            the following rights:
          </p>
          <ul>
            <li>
              Right of access (“Subject Access Request”) – you have the right to
              request a copy of the information that we hold about you.
            </li>
            <li>
              Right of rectification – you have a right to correct data that we
              hold about you that is inaccurate or incomplete.
            </li>
            <li>
              Right to be forgotten (“Data Erasure Request”) – in certain
              circumstances you can ask for the data we hold about you to be
              erased from our records.
            </li>
            <li>
              Right to restriction of processing – where certain conditions
              apply you have a right to restrict the processing.
            </li>
            <li>
              Right of portability – you have the right to have the data we hold
              about you transferred to another organisation.
            </li>
            <li>
              Right to object – you have the right to object to certain types of
              processing such as direct marketing.
            </li>
            <li>
              Right to object to automated processing, including profiling – you
              also have the right not to be subject to the legal effects of
              automated processing or profiling.
            </li>
          </ul>
          <p>
            In the unlikely event that we have to refuse your request, we will
            provide you with the reason for the refusal, and you have the right
            to legally challenge that refusal.
          </p>
          <p>You can request the following information:</p>
          <ul>
            <li>Contact details of the Data Protection Team.</li>
            <li>
              The purpose of the processing as well as the legal basis for
              processing.
            </li>
            <li>
              The categories of personal data collected, stored and processed.
            </li>
            <li>
              Recipient(s) or categories of recipients that the data is/will be
              disclosed to.
            </li>
            <li>How long the data will be stored.</li>
            <li>
              Details of your rights to correct, erase, restrict or object to
              such processing.
            </li>
            <li>
              Information about your right to withdraw consent at any time.
            </li>
            <li>
              How to lodge a complaint with the supervisory authority (in the UK
              this is the ICO).
            </li>
            <li>
              Whether the provision of personal data is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract, as well as whether you are obliged to provide the
              personal data and the possible consequences of failing to provide
              such data.
            </li>
            <li>
              The source of personal data if it wasn’t collected directly from
              you.
            </li>
            <li>
              Any details and information of automated decision making, such as
              profiling, and any meaningful information about the logic
              involved, as well as the significance and expected consequences of
              such processing.
            </li>
          </ul>
        </div>
        <div
          id="section10"
          class="page-section aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>
            2.8. Making a request to access, change or remove your Personal
            Information
          </h2>
          <p>
            When making a request such as a Subject Access Request or a Data
            Erasure Request, to protect your Personal Information we will need
            to check your identification. If we are dissatisfied with the
            quality of the identification, further identification may be sought
            before Personal Information can be released, such as a passport or
            driving licence, a utility bill, or a national ID card.
          </p>
          <p>
            Requests can be made by email, phone or in writing, using the
            contact details below.
          </p>
        </div>
        <div
          id="section11"
          class="page-section aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>2.9. Changes to our privacy policy</h2>
          <p>
            We keep our privacy policy under regular review. This privacy policy
            was last updated on 31<sup>st</sup> August 2021.
          </p>
        </div>
        <div
          id="section12"
          class="page-section aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>2.10. How to contact us</h2>
          <p>
            Please contact us to make a request about the Personal Information
            we hold about you, or if you have any questions about our privacy:
          </p>
          <ul>
            <li>
              By emailing us at:{" "}
              <a href="mailto:info@gravit8.co">info@gravit8.co</a>
            </li>
            <li>Or phone us on: +44 (0)3452 263083</li>
            <li>
              Or write to us at: Gravit8 Software., Spectrum 800, Ashchurch
              Business Centre, Alexandra Way, Tewkesbury, Gloucestershire, GL20
              8TD, United Kingdom
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default index;
