import React from "react";

export const Headphone = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="#000000"
			>
				<g id="_103-headphones" data-name="103-headphones" opacity="0.7">
                    <g id="Group_1139" data-name="Group 1139" transform="translate(0 0)">
                        <g id="Group_1138" data-name="Group 1138">
                            <path id="Path_646" data-name="Path 646" d="M374.343,264h-1.562a.781.781,0,0,0-.781.781V272.9a.781.781,0,0,0,.781.781h1.562a3.128,3.128,0,0,0,3.125-3.125v-3.437A3.128,3.128,0,0,0,374.343,264Zm1.562,6.562a1.564,1.564,0,0,1-1.562,1.562h-.781v-6.562h.781a1.564,1.564,0,0,1,1.562,1.562Z" transform="translate(-357.471 -253.689)"/>
                            <path id="Path_647" data-name="Path 647" d="M10,1.562A6.836,6.836,0,0,1,16.844,7.8.781.781,0,1,0,18.4,7.662a8.437,8.437,0,0,0-16.838.774V10.73A3.126,3.126,0,0,0,0,13.436v3.437A3.128,3.128,0,0,0,3.125,20H4.687a.781.781,0,0,0,.781-.781V11.092a.781.781,0,0,0-.781-.781H3.125V8.436A6.882,6.882,0,0,1,10,1.562ZM3.906,11.873v6.562H3.125a1.564,1.564,0,0,1-1.562-1.562V13.436a1.564,1.564,0,0,1,1.562-1.562Z" transform="translate(0 0)"/>
                        </g>
                    </g>
                </g>
			</svg>
		</>
	);
};
