import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Popover,
  Breadcrumb,
  Input,
  Select,
  Tooltip,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./index.css";
import { Link } from "react-router-dom";
import { getBrand } from "../../redux/slice/brandManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "../../components/svgJS/Filter";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  getCustomerListForListing,
  getEventsForListing,
  getBrandCreators,
  getTiers,
} from "../../utils/commonApis";
import {
  createTZString,
  generateHelmet,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import { PAGINATION_DEFAULT_SETTINGS } from "../../components/constsnts/common.constants";
import { cloneDeep, isEmpty } from "lodash";
import { ApiCall } from "../../utils/api";
import moment from "moment";

const BrandManagement = () => {
  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  const { Option } = Select;
  const brandSelector = useSelector((state) => state?.brandManagement?.data);
  let loading = useSelector((state) => state.brandManagement.loading);
  const [searchData, setsearchData] = useState("");
  const [userType, setUserType] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [eventList, setEventList] = useState();
  const [tierList, setTierList] = useState();
  const [creatorList, setCreatorList] = useState();
  const [fields, setFields] = useState({
    customer: null,
    event_id: null,
    id: null,
    is_customer: null,
    brand_tier: null,
    full_name: null,
  });
  const [page_no, set_page_no] = useState(PAGINATION_DEFAULT_SETTINGS.page_no);
  const [no_of_records, set_no_of_records] = useState(
    PAGINATION_DEFAULT_SETTINGS.no_of_records
  );

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);
      dispatch(getBrand({ page_no, no_of_records }));
      const customerParams = {
        is_details: false,
      };
      let customerResponse = await getCustomerListForListing(customerParams);
      setCustomerList(customerResponse);
      let tiersResponse = await getTiers();
      setTierList(tiersResponse);
    }
  }, [CryptoJS.AES]);

  useEffect(async () => {
    const creatorParams = {
      customer_id: fields?.customer,
      event_id: fields?.event_id,
    };

    let creatorResponse = await getBrandCreators(creatorParams);
    setCreatorList(creatorResponse);
  }, [fields?.customer, fields?.event_id]);

  useEffect(async () => {
    const eventParams = {
      is_filter: true,
      customer_id: fields?.customer,
    };

    let eventResponse = await getEventsForListing(eventParams);
    setEventList(eventResponse);
  }, [fields?.customer]);

  const tableData =
    brandSelector &&
    !isEmpty(brandSelector) &&
    brandSelector.rows &&
    brandSelector.rows.map((elem) => {
      const start_date_time_createdAt = createTZString(elem.created_at);

      const eleStartDateCreatedAt = moment(
        start_date_time_createdAt.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      // eleStartDateCreatedAt.format("DD-MM-YYYY");
      return {
        brand_id: elem.brand_id,
        brand: elem?.brand_title,
        name: elem?.brands_default_zone_id?.zone_title ?? " - ",
        domain: elem?.event_domain_name ?? " - ",
        tier: elem?.tier?.tier ?? " - ",
        zones: elem?.total_zone_count + "/" + elem?.no_of_zones,
        assets: elem?.total_assets_count + "/" + elem?.no_of_assets_per_zone,
        agents: elem?.total_agents_count + "/" + elem?.no_of_agents,
        storage:
          elem?.total_max_storage_size + "/" + elem?.max_storage_size + " gb" ??
          " - ",
        streaming:
          elem?.total_max_bandwidth_per_month +
            "/" +
            elem?.max_bandwidth_per_month +
            " gb" ?? " - ",
        videoHours:
          elem?.total_max_video_room_hours_per_month +
            "/" +
            elem?.max_video_room_hours_per_month +
            " hours" ?? " - ",
        contact: elem?.main_contact_person ?? " - ",
        createdBy: elem?.created_user_name ?? " - ",
        created_at: eleStartDateCreatedAt.format("DD-MM-YYYY"),
        logos:
          elem?.logo_url_status === true ? (
            <span className="fs-16" style={{ color: "#39e75f" }}>
              Complete
            </span>
          ) : (
            <span className="fs-16 text-danger">Incomplete</span>
          ),
        gfx:
          elem?.wall_graphics_url_status === true ? (
            <span className="fs-16" style={{ color: "#39e75f" }}>
              Complete
            </span>
          ) : (
            <span className="fs-16 text-danger">Incomplete</span>
          ),
        panos:
          elem?.final_booth_url_status === true ? (
            <span className="fs-16" style={{ color: "#39e75f" }}>
              Complete
            </span>
          ) : (
            <span className="fs-16 text-danger">Incomplete</span>
          ),
        is_active: elem?.is_active,
      };
    });

  const handleFieldsChange = (val, name) => {
    let cloneFields = cloneDeep(fields);
    if (name === "id") {
      cloneFields = {
        ...cloneFields,
        [name]: val?.id,
        is_customer: val?.is_customer,
        full_name: val?.full_name,
      };
    } else if (name === "customer") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        event_id: null,
        id: null,
        is_customer: null,
        full_name: null,
        brand_tier: null,
      };
    } else if (name === "event_id") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        id: null,
        is_customer: null,
        full_name: null,
        brand_tier: null,
      };
    } else {
      cloneFields = { ...cloneFields, [name]: val };
    }
    setFields({ ...cloneFields });
    const searchObj = {
      search: searchData,
      customer: cloneFields?.customer,
      event_id: cloneFields?.event_id,
      id: cloneFields?.id,
      is_customer: cloneFields?.is_customer,
      brand_tier: cloneFields?.brand_tier,
      page_no,
      no_of_records,
    };
    dispatch(getBrand(searchObj));
  };

  const handleStatus = async (id, bool) => {
    // dispatch(changeBrandStatus({ brand_id: id, is_active: !bool }));
    let body = {
      brand_id: id,
      is_active: !bool,
    };

    await ApiCall("PUT", body, "/admin/brand/status/update");
    dispatch(getBrand(page_no, no_of_records));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "brand_id",
      key: "brand_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 70,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      sorter: false,
      width: 150,
      className: "p-4",
      ellipsis: {
        showTitle: false,
      },
      render: (brand) => (
        <Tooltip placement="topLeft" title={brand}>
          {brand}
        </Tooltip>
      ),
    },
    {
      title: "Default Zone",
      dataIndex: "name",
      key: "name",
      sorter: false,
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      sorter: false,
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (domain) => (
        <Tooltip placement="topLeft" title={domain}>
          {domain}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (_, record) => (
        <button
          className={
            record.is_active === true
              ? "bg-button-green active_btns "
              : "bg-button-red  inactive_btns"
          }
          onClick={() => {
            handleStatus(record.brand_id, record.is_active);
          }}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 130,
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
      sorter: false,
      width: 100,
    },
    {
      title: "#Zones",
      dataIndex: "zones",
      key: "zones",
      sorter: false,
      width: 90,
    },
    {
      title: "#Assets",
      dataIndex: "assets",
      key: "assets",
      sorter: false,
      width: 90,
    },
    {
      title: "#Agents",
      dataIndex: "agents",
      key: "agents",
      sorter: false,
      width: 90,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      sorter: false,
      width: 100,
    },
    {
      title: "Streaming",
      dataIndex: "streaming",
      key: "streaming",
      sorter: false,
      width: 100,
    },
    {
      title: "Video Hours",
      dataIndex: "videoHours",
      key: "videoHours",
      sorter: false,
      width: 120,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      sorter: false,
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (contact) => (
        <Tooltip placement="topLeft" title={contact}>
          {contact}
        </Tooltip>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: false,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (createdBy) => (
        <Tooltip placement="topLeft" title={createdBy}>
          {createdBy}
        </Tooltip>
      ),
    },
    {
      title: "Created D/T",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Logos",
      dataIndex: "logos",
      key: "logos",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 110,
    },
    {
      title: "GFX",
      dataIndex: "gfx",
      key: "gfx",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 110,
    },
    {
      title: "Panos",
      dataIndex: "panos",
      key: "panos",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 110,
    },
    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 40,
      align: "Center",
      render: (_, record) => (
        <Popover
          content={
            <div>
              <p className="options">
                <Link
                  className="options"
                  to={`viewbranddetails/${record.brand_id}`}
                >
                  <EyeOutlined /> <span className="">View Details</span>
                </Link>
              </p>
              <p className="options">
                <Link
                  className="options"
                  to={`editbranddetails/${record.brand_id}`}
                >
                  <EditOutlined /> <span className="">Edit Brand</span>
                </Link>
              </p>
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 65,
      render: (_, record) => (
        <div className="px-1">
          <Link to={`editbranddetails/${record.brand_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    let sortObj = {
      page_no: pagination?.current ?? page_no,
      no_of_records,
    };
    if (
      pagination &&
      pagination.pageSize &&
      no_of_records !== pagination.pageSize
    ) {
      set_page_no(1);
      set_no_of_records(pagination.pageSize);
      sortObj = {
        page_no: 1,
        no_of_records: pagination.pageSize,
      };
    }
    sortObj = {
      ...sortObj,
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
      search: searchData,
      customer: fields?.customer,
      id: fields?.id,
      is_customer: fields?.is_customer,
      brand_tier: fields?.brand_tier,
      event_id: fields?.event_id,
      full_name: fields?.full_name,
    };
    dispatch(getBrand(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
    const searchObj = {
      search: e.target.value,
      customer: fields?.customer,
      id: fields?.id,
      is_customer: fields?.is_customer,
      page_no,
      no_of_records,
    };
    dispatch(getBrand(searchObj));
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center listing_upper_div">
        {generateHelmet("Gravit8", "Brand Management")}
        <div>
          <h4 className="fs-24">Brand Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Brand Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Link to="createnewbrand">
            {userType && userType === "BRAND ADMIN" ? null : (
              <Button className="button-pink rounded px-3 mt-1 mb-3">
                <span className="fw-bold"> + Add New Brand </span>
              </Button>
            )}
          </Link>
        </div>
      </div>
      <div className="bg-white px-2 table_class_test listing_lower_div">
        <div className="d-flex justify-content-between mb-3">
          <div className="w-75 mt-3">
            <Input
              size="medium"
              value={searchData}
              onChange={handleInputSearch}
              placeholder="Search by Cust. Name, Event Name, Brand Name, Created By, Zone Name, User Name"
              style={{ width: "70%" }}
              prefix={<SearchOutlined />}
              allowClear
            />
          </div>
          <div className="d-flex mt-3">
            <Button
              className=" rounded"
              onClick={() => setShowFilter(!showFilter)}
            >
              <Filter />
              <span className="ms-2"> FILTER</span> <DownOutlined />
            </Button>
          </div>
        </div>
        <div className="mb-2">
          {showFilter ? (
            <div>
              <Select
                name="customer"
                placeholder="Customer Name"
                style={{
                  width: 280,
                }}
                onChange={(val) => handleFieldsChange(val, "customer")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.customer}
              >
                {customerList?.map((data) => {
                  return (
                    <Option value={data.customer_id}>
                      {data.account_name}
                    </Option>
                  );
                })}
              </Select>
              <Select
                name="event_id"
                placeholder="Event Name"
                className="ms-2"
                style={{
                  width: 280,
                }}
                onChange={(val) => handleFieldsChange(val, "event_id")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.event_id}
              >
                {eventList &&
                  eventList?.map((data) => {
                    return (
                      <Option value={data.event_id}>{data.event_name}</Option>
                    );
                  })}
              </Select>
              <Select
                name="creatorName"
                placeholder="Creator Name"
                className="ms-2"
                onChange={(id) => {
                  if (id) handleFieldsChange(JSON.parse(id), "id");
                  else handleFieldsChange(null, "id");
                }}
                style={{
                  width: 280,
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.full_name ?? null}
              >
                {creatorList &&
                  creatorList?.map((data) => {
                    return (
                      <Option value={JSON.stringify(data)}>
                        {data.full_name}
                      </Option>
                    );
                  })}
              </Select>
              <Select
                name="brand_tier"
                placeholder="Tier"
                className="ms-2"
                onChange={(val) => handleFieldsChange(val, "brand_tier")}
                style={{
                  width: 280,
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.brand_tier}
              >
                {tierList &&
                  tierList?.map((data) => {
                    return <Option value={data.tier_id}>{data.tier}</Option>;
                  })}
              </Select>
            </div>
          ) : null}
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            showSizeChanger: true,
            pageSize: no_of_records,
            total: brandSelector?.count ? brandSelector.count : 1,
          }}
          loading={{
            indicator: <CommonLoader />,
            spinning: loading,
          }}
          scroll={{
            x: 1500,
            y: "59vh",
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default BrandManagement;
