import React from "react";

export const Video = () => {
  return (
    <>
      <svg
        id="_117-video-camera"
        data-name="117-video-camera"
        xmlns="http://www.w3.org/2000/svg"
        width="21.152"
        height="14.625"
        viewBox="0 0 21.152 14.625"
      >
        <g id="Group_1423" data-name="Group 1423" opacity="0.7">
          <path
            id="Path_49274"
            data-name="Path 49274"
            d="M20.326,90.654a.826.826,0,0,0,.826-.826V83.093a2.479,2.479,0,0,0-3.97-1.98l-.022.016-2.536,2.183V82.3a3.309,3.309,0,0,0-3.3-3.3H3.3A3.309,3.309,0,0,0,0,82.3V90.32a3.309,3.309,0,0,0,3.3,3.3H11.32a3.309,3.309,0,0,0,3.3-3.3v-.966l2.515,2.166.042.034a2.479,2.479,0,0,0,3.97-1.98.826.826,0,1,0-1.652,0,.826.826,0,0,1-1.305.673l-3.57-3.074v-1.68l3.57-3.074a.826.826,0,0,1,1.305.673v6.735A.826.826,0,0,0,20.326,90.654Zm-7.354-.335a1.654,1.654,0,0,1-1.652,1.652H3.3A1.654,1.654,0,0,1,1.652,90.32V82.3A1.654,1.654,0,0,1,3.3,80.652H11.32A1.654,1.654,0,0,1,12.972,82.3Z"
            transform="translate(0 -79)"
            fill="#000"
          />
        </g>
      </svg>
    </>
  );
};
