import React, { useEffect, useState } from "react";
import BrandZoneDetails from "../ExpoMap/BrandZoneDetails";
import Brands from "../ExpoMap/Brands";
import { generateHelmet } from "../../utils/commonFunctions";

const Brand = (props) => {
  var CryptoJS = require("crypto-js");
  const [brandObj, setBrandObj] = useState({
    event_id: null,
    brand_id: null,
    show: false,
  });

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    if (ciphertext) {
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData === "SUPER ADMIN" || decryptedData === "CUSTOMER") {
        localStorage.setItem("event_name", "");
      }
    }
  }, []);

  const eventName = localStorage.getItem("event_name");

  if (brandObj.show) {
    return <BrandZoneDetails socket={props.socket} brandObj={brandObj} />;
  } else
    return (
      <div>
        {generateHelmet(eventName?.length > 0 ? eventName : "Gravit8")}
        <div className="d-flex justify-content-between align-items-center py-1">
          <div>
            <h4 className="fs-24 mt-1">Brands</h4>
          </div>
        </div>
        <div className="p-2 bg-white">
          <Brands socket={props.socket} setBrandObj={setBrandObj} />
        </div>
      </div>
    );
};

export default Brand;
