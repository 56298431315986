import { Avatar, Badge } from "antd";
import React, { useEffect, useState } from "react";
import "./messagesui.css";
import { Bin } from "../../components/svgJS/Bin";
import { BinMessages } from "../../components/svgJS/BinMessages";
import { ApiCall } from "../../utils/api";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderData,
  setHelpAndSupportData,
} from "../../redux/slice/headerData";

const MessagesList = (props) => {
  let {
    getMessagesUserDetails,
    usersList,
    closeMessageChat,
    openUserChatId,
    userChatHeaderData,
    getMessagesUsersList,
  } = props;

  const helpAndSupportNotificationData = useSelector(
    (state) => state?.header?.helpAndSupportData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(openUserChatId, "openUserChatId");
    if (openUserChatId && !isEmpty(usersList)) {
      usersList?.forEach((item) => {
        if (item?.user_id === openUserChatId) {
          console.log(item?.user_id, openUserChatId, "compare ids");
          closeMessageChat();
          setTimeout(() => {
            getMessagesUserDetails(item);
          }, 500);
        }
      });
    }
  }, [openUserChatId, usersList]);

  // console.log(userChatHeaderData, "userChatHeaderData mess list");

  const callUserDeleteApi = async (linkId) => {
    let body = {
      // link_id: userChatHeaderData?.link_id,
      link_id: linkId,
    };

    const response = await ApiCall(
      "DELETE",
      body,
      "/admin/conversation/delete",
      null
    );
    console.log("delete resp", response);
    if (response?.data?.code === 200) {
      closeMessageChat();

      setTimeout(() => {
        getMessagesUsersList();
      }, 500);
    } else {
      toast.error(response?.data?.message);
    }
  };

  return (
    <div className="mess_list_container">
      <div className="mess_list_title_container">
        <h5 className="mess_list_title">Chats</h5>
      </div>
      <div className="mess_list">
        {usersList &&
          usersList?.map((item) => {
            return (
              <div
                onClick={() => {
                  closeMessageChat();

                  getMessagesUserDetails(item);
                }}
                className="mess_list_user"
              >
                <div
                  style={{
                    width: "80%",
                  }}
                  className="mess_user_left_side"
                >
                  <Badge
                    className={
                      item?.is_online
                        ? "mess_online_chat_dot"
                        : "mess_offline_chat_dot"
                    }
                    dot
                  >
                    <Avatar src={item?.image_url} />
                  </Badge>

                  <div className="mess_user_name_container">
                    <span
                      style={{
                        letterSpacing: "1.5px",
                      }}
                      className="mess_user_name crop_mess_list_user_details"
                    >
                      {item?.user_name}
                    </span>
                    {item?.company ? (
                      <span
                        style={{
                          fontWeight: "600",
                          color: "black",
                          letterSpacing: "1px",
                        }}
                        className="crop_mess_list_user_details"
                      >
                        {item?.company}
                      </span>
                    ) : null}

                    <span className="crop_mess_list_user_details">
                      {item?.last_unread_message ?? item?.last_read_message}
                    </span>
                  </div>
                </div>

                <span className="mess_list_date">
                  {item?.last_conversation_timestamp} <br />
                  {item?.unread_main_conversation ? (
                    <span className="mess_unread_count">
                      {item?.unread_main_conversation}
                    </span>
                  ) : null}
                </span>

                <span className="mess_list_bin">
                  <div
                    onClick={() => {
                      callUserDeleteApi(item?.link_id);
                    }}
                  >
                    <BinMessages />
                  </div>
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MessagesList;
