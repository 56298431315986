import React, { useState, useRef, useEffect } from "react";
import "./CreateMeeting.css";
import { DeleteOutlined } from "@ant-design/icons";
import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Button,
  Table,
  Select,
  DatePicker,
} from "antd";
import { EyeView } from "../../components/svgJS/EyeView";
import { BoothRed } from "../../components/svgJS/BoothRed";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import {
  getPeopleForListing,
  addDelegateMeeting,
} from "../../utils/commonApis";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash-es";
import { UserDataCard } from "../common/Modal/ProfileModal";
import { getDisabledTime } from "../../utils/commonFunctions";
import { useMediaQuery } from "react-responsive";
import RedAsterick from "../../components/Widgets/RedAsterick";

const CreateMeeting = ({
  isCreateMeetingModalVisible,
  closeCreateMeetingModal,
  userData,
}) => {
  const { TextArea } = Input;
  const { Option } = Select;
  let attendeesToSend = [];
  const [peopleList, setPeopleList] = useState([]);
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [selectedProfileValue, setSelectedProfileValue] = useState();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [profileModal, setProfileModal] = useState(false);
  const [fields, setFields] = useState({
    meeting_title: "",
    meeting_description: "",
    event_id: localStorage.getItem("event_id"),
    start_date_time: moment(),
    end_date_time: moment(),
    attendees: attendeesToSend,
  });
  const [forceUpdate, setForceUpdate] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const isMobile = useMediaQuery({ minWidth: 480 });
  console.log("createMeeting userData ->", userData);
  useEffect(async () => {
    const peopleData = await getPeopleForListing();
    setPeopleList(peopleData.rows);

    let agents_list = peopleData?.rows;
    let selectedAgents_list = selectedAttendees;
    let index = agents_list.findIndex(
      (agent) => agent?.user_id === userData?.user_id
    );
    console.log("createmeeting agentlist & index ->", agents_list, index);

    if (index !== -1) {
      selectedAgents_list?.push(agents_list[index]);
      agents_list?.splice(index, 1);
    } else {
      selectedAgents_list?.push(userData);
    }
    console.log("createmeeting selectedAgents_list ->", selectedAgents_list);
    setSelectedAttendees([...selectedAgents_list]);
    setPeopleList([...agents_list]);
    attendeesToSend = selectedAttendees?.map((data) => data?.user_id);
    setFields({ ...fields, attendees: attendeesToSend });
  }, []);
  console.log("createmeeting selectedAttendees ->", selectedAttendees);

  const handleCreate = async () => {
    if (validator.current.allValid()) {
      let finalData = {
        meeting_title: fields?.meeting_title,
        meeting_description: fields?.meeting_description,
        event_id: parseInt(fields?.event_id),
        start_date_time:
          date + " " + moment(fields?.start_date_time).format("HH:mm"),
        end_date_time:
          date + " " + moment(fields?.end_date_time).format("HH:mm"),
        attendees: fields?.attendees,
      };
      const apiResponse = await addDelegateMeeting(finalData);
      if (apiResponse?.code == 200) {
        toast.success(apiResponse?.message);
      } else {
        toast.error(apiResponse?.message);
      }
      closeCreateMeetingModal();
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  const handleFieldChange = (val, name) => {
    if (name === "date") {
      setDate(val);
    } else if (name === "end_date_time") {
      if (moment(val) < moment(fields.start_date_time, "HH:mm")) {
        toast.error("Please select correct time");
      } else {
        setFields({
          ...fields,
          [name]: val,
        });
      }
    } else if (name === "start_date_time") {
      if (moment(fields.end_date_time, "HH:mm") < moment(val)) {
        toast.error("Please select correct time");
      } else {
        setFields({
          ...fields,
          [name]: val,
        });
      }
    } else {
      setFields({
        ...fields,
        [name]: val,
      });
    }
  };

  const handleAttendeesChange = (value) => {
    let agents_list = peopleList;
    let selectedAgents_list = selectedAttendees;
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);

    setSelectedAttendees([...selectedAgents_list]);
    setPeopleList([...agents_list]);

    attendeesToSend = selectedAttendees?.map((data) => data?.user_id);
    setFields({ ...fields, attendees: attendeesToSend });
  };

  const handleDeleteAttendees = (value) => {
    let agents_list = cloneDeep(peopleList);
    let selectedAgents_list = cloneDeep(selectedAttendees);
    let index = selectedAgents_list.findIndex(
      (agent) => parseInt(agent.user_id) === parseInt(value)
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);

    setSelectedAttendees([...selectedAgents_list]);
    setPeopleList([...agents_list]);

    attendeesToSend = selectedAgents_list?.map((data) => data.user_id);
    setFields({ ...fields, attendees: attendeesToSend });
  };

  const handleProfileData = (value) => {
    setProfileModal(true);
    setSelectedProfileValue(value);
  };

  const columns = [
    {
      title: "Delegate",
      dataIndex: "delegate",
    },
    {
      title: "Company",
      dataIndex: "company",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => (
        <div onClick={() => handleProfileData(index)}>
          <EyeView />
          <span className="fs-pink">View Profile</span>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "remove",
      render: (text, record, index) => (
        <div onClick={() => handleDeleteAttendees(record.remove)}>
          <DeleteOutlined />
        </div>
      ),
    },
  ];

  const data = selectedAttendees?.map((d, i) => {
    return {
      key: i,
      delegate: `${d?.user_name}`,
      company: `${d?.company}`,
      action: "view profile",
      remove: `${d?.user_id}`,
    };
  });

  const handleOKAddUser = () => {
    setProfileModal(false);
  };

  const handleCancelAddUser = () => {
    setProfileModal(false);
  };

  return (
    <Modal
      title=""
      visible={isCreateMeetingModalVisible}
      onOk={() => closeCreateMeetingModal()}
      onCancel={() => closeCreateMeetingModal()}
      className=""
      width={650}
      footer={null}
    >
      <div className="w-100">
        <Row className="mt-4">
          <Col
            span={isMobile ? 4 : 6}
            className="d-flex justify-content-end align-items-center"
          >
            <span>
              Meeting Title <RedAsterick />
            </span>
          </Col>
          <Col offset={1} span={17}>
            <Input
              name="meeting_title"
              placeholder="Enter a title here"
              className="createmeet_input"
              value={fields?.meeting_title}
              onChange={(val) => {
                handleFieldChange(val.target.value, "meeting_title");
              }}
              onBlur={() => validator.current.showMessageFor("meeting_title")}
            />
            {validator.current.message(
              "meeting_title",
              fields.meeting_title,
              "required",
              {
                className: "text-danger py-1",
              }
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            span={isMobile ? 4 : 6}
            className="d-flex justify-content-end align-items-center"
          >
            <span>Select Date</span>
          </Col>
          <Col offset={1} span={17}>
            <DatePicker
              name="date"
              id="date"
              format={"YYYY-MM-DD"}
              allowClear={false}
              className="w-100 createmeet_input"
              value={date ? moment(date) : moment()}
              onSelect={(val) => {
                handleFieldChange(moment(val).format("YYYY-MM-DD"), "date");
              }}
              onChange={(val) => {
                handleFieldChange(moment(val).format("YYYY-MM-DD"), "date");
              }}
              onBlur={() => validator.current.showMessageFor("date")}
              disabledDate={(current) => {
                return current && current < moment().startOf("day");
              }}
            />
            {validator.current.message("Date", date, "required", {
              className: "text-danger py-1",
            })}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            span={isMobile ? 4 : 6}
            className="d-flex justify-content-end align-items-center"
          >
            <span>Select Time</span>
          </Col>
          <Col offset={1} span={8}>
            <TimePicker
              name="start_date_time"
              id="start_date_time"
              showSecond={false}
              format={"HH:mm"}
              allowClear={false}
              showTime
              className="w-100 createmeet_input"
              value={fields?.start_date_time}
              onSelect={(val) => {
                handleFieldChange(val, "start_date_time");
              }}
              onChange={(val) => {
                handleFieldChange(val, "start_date_time");
              }}
              onBlur={() => validator.current.showMessageFor("start_date_time")}
            />

            {validator.current.message(
              "Start Time",
              fields?.start_date_time,
              "required",
              {
                className: "text-danger py-1",
              }
            )}
          </Col>
          <Col offset={1} span={8}>
            <TimePicker
              name="end_date_time"
              id="end_date_time"
              format="HH:mm"
              showTime
              allowClear={false}
              value={fields?.end_date_time}
              disabledTime={() => getDisabledTime(fields?.start_date_time)}
              className="w-100 createmeet_input"
              onSelect={(val) => {
                handleFieldChange(val, "end_date_time");
              }}
              onChange={(val) => {
                handleFieldChange(val, "end_date_time");
              }}
              onBlur={() => validator.current.showMessageFor("end_date_time")}
            />
            {validator.current.message(
              "End Time",
              fields.end_date_time,
              "required",
              {
                className: "text-danger py-1",
              }
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col span={isMobile ? 4 : 6} className="d-flex justify-content-end">
            <span>
              Description <RedAsterick />
            </span>
          </Col>
          <Col offset={1} span={17}>
            <TextArea
              rows={4}
              placeholder="Enter description here"
              value={fields?.meeting_description}
              onChange={(val) => {
                handleFieldChange(val.target.value, "meeting_description");
              }}
              onBlur={() =>
                validator.current.showMessageFor("meeting_description")
              }
            />
            {validator.current.message(
              "meeting_description",
              fields.meeting_description,
              "required",
              {
                className: "text-danger py-1",
              }
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            span={isMobile ? 4 : 6}
            className="d-flex justify-content-end align-items-center"
          >
            <span>Add Attendees</span>
          </Col>
          <Col offset={1} span={17}>
            {/* <Input
                prefix={<SearchOutlined />}
                className="createmeet_input"
                placeholder="type attendee name or email address"
              /> */}
            <Select
              showSearch
              className="w-100 fs-bold-14"
              placeholder="Type attendee name or email address"
              optionFilterProp="children"
              value="Attendee's"
              onChange={(val) => handleAttendeesChange(val)}
              // onBlur={() =>
              //   validator.current.showMessageFor("brand_id")
              // }
              autoComplete="none"
              listHeight={120}
              listItemHeight={4}
            >
              {peopleList &&
                peopleList?.map((data, i) => (
                  <Option key={"peopleList_" + i} value={data?.user_id}>
                    {data?.user_name}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        <h5 className="mt-2 mb-2">Attendees</h5>
        <Table
          className="createmeet_table"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: 120 }}
        />

        <Modal
          title="Profile Details"
          visible={profileModal}
          onOk={handleOKAddUser}
          onCancel={handleCancelAddUser}
          footer={null}
          width={1000}
        >
          <UserDataCard
            // value={selectedProfileValue}
            meetingData={selectedAttendees[selectedProfileValue]}
            fromMeeting={true}
          />
        </Modal>
        <div className="w-100 d-flex mt-3 justify-content-end">
          <Button
            onClick={() => {
              closeCreateMeetingModal();
            }}
            className="createmeet_deleteBtn  fs-16"
          >
            <span className="ms-2">Cancel</span>
          </Button>
          <Button
            onClick={handleCreate}
            className="createmeet_saveBtn mx-2 fs-16"
          >
            <BoothRed />
            <span className="ms-2">Save Meeting</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateMeeting;
