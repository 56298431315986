import React from "react";
import { Select, Table } from "antd";

const SelectSingleAgent = () => {
  const dummyData = [
    {
      brand: "Dell",
      product: "M6300",
      agent: "Richard",
      logins: 2,
      total_time: "57 Mins",
      average_time: 3,
      invites_sent: 5,
    },
  ];

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 100,
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      width: 150,
    },
    {
      title: "#Logins",
      dataIndex: "logins",
      key: "logins",
      width: 100,
    },
    {
      title: "Total Time",
      dataIndex: "total_time",
      key: "total_time",
      width: 100,
    },
    {
      title: "Average Time",
      dataIndex: "average_time",
      key: "average_time",
      width: 100,
    },
    {
      title: "#Invites Sent",
      dataIndex: "invites_sent",
      key: "invites_sent",
      width: 100,
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    // dispatch(
    //   getCustomer({
    //     sort_type: sorter.columnKey,
    //     sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    //   })
    // );
  };
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <Select
          showSearch
          //   style={{ width: 300 }}
          placeholder="Select Agent Name"
          optionFilterProp="children"
          className="fs-bold-14"
          name="booth_location_id"
          //   onChange={(val) => {
          //     setFieldValue("booth_location_id", val);
          //     setFieldValue("survey_id", null);
          //   }}
          listHeight={120}
          listItemHeight={4}
          //   value={values?.booth_location_id}
        >
          {/* {boothLocationList &&
            boothLocationList.map((data) => (
              <Option value={data.booth_location_id}>
                {data.booth_location}
              </Option>
            ))} */}
        </Select>
        <Select
          showSearch
          //   style={{ width: 300 }}
          placeholder="Event Activity"
          optionFilterProp="children"
          className="fs-bold-14"
          name="booth_location_id"
          //   onChange={(val) => {
          //     setFieldValue("booth_location_id", val);
          //     setFieldValue("survey_id", null);
          //   }}
          listHeight={120}
          listItemHeight={4}
          //   value={values?.booth_location_id}
        >
          {/* {boothLocationList &&
            boothLocationList.map((data) => (
              <Option value={data.booth_location_id}>
                {data.booth_location}
              </Option>
            ))} */}
        </Select>
      </div>
      <Table
        className="mt-3"
        columns={columns}
        dataSource={dummyData}
        pagination={{
          showSizeChanger: true,
        }}
        scroll={{
          x: 1300,
        }}
        bordered={false}
        showSizeChange={true}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default SelectSingleAgent;
