import React, { useState, useRef, useEffect } from "react";
import "./ProductZoneChat.css";
import { Row, Col, Select, Button, TimePicker, Modal } from "antd";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { ApiCall } from "../../utils/api";
import { getUserTypeAgents, getZoneTaggedAgents } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { cloneDeep, isEmpty } from "lodash";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  createISOTimeString,
  createTZString,
  getDisabledTime,
  mapSelectedDdlData,
} from "../../utils/commonFunctions";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
import { toast } from "react-toastify";
import { getMinutesDiff } from "../EventCalender/eventCalender.utils";

const ProductZoneChat = (props) => {
  const { Option } = Select;
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  let initialStartDateTime =
    props?.isFromCalender === "zone"
      ? moment(
          moment(props?.data?.appointmentData?.startDate)?.format("HH:mm"),
          TIME_FORMAT
        )
      : props?.data?.startTime
      ? moment(props?.data?.startTime, TIME_FORMAT)
      : null;

  let initialEndDateTime =
    props?.isFromCalender === "zone"
      ? moment(
          moment(props?.data?.appointmentData?.endDate).format("HH:mm"),
          TIME_FORMAT
        )
      : props?.data?.endTime
      ? moment(props?.data?.endTime, TIME_FORMAT)
      : null;

  const [fields, setFields] = useState({
    brand_id: null,
    zone_id: null,
    start_date_time: initialStartDateTime,
    end_date_time: initialEndDateTime,
    is_update: true,
    product_area_chat_tagged_agents: [],
  });
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [openAssignStaffList, setOpenAssignStaffList] = useState(false);

  useEffect(async () => {
    //assign staff
    let userApiProps = { is_zone_calendar: true };
    if (props && props?.data?.brand_id)
      userApiProps = { ...userApiProps, brand_id: props.data.brand_id };
    const agentsList = await getZoneTaggedAgents({
      zone_id: props?.data?.zone_id,
    });
    setAgents(agentsList);
    setLoading(true);
    if (props?.data?.chat_configuration_id) {
      await ApiCall("get", null, "/admin/zoneareastaff/details/get", {
        chat_configuration_id: props.data.chat_configuration_id,
      })
        .then((response) => {
          const data =
            response?.data?.data?.details
              ?.product_area_chat_configuration_details[0];
          if (data) initializeZoneStaffingData(data, agentsList);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, []);

  const initializeZoneStaffingData = async (data, usersCollection) => {
    const start_date_time = createTZString(data?.start_date_time);
    const end_date_time = createTZString(data?.end_date_time);
    const default_time = moment(moment().format("HH:mm"), TIME_FORMAT);
    setFields({
      chat_configuration_id: data?.chat_configuration_id ?? null,
      brand_id: data?.brand_id ?? null,
      zone_id: data?.zone_id ?? null,
      start_date_time: data?.start_date_time
        ? moment(start_date_time.format("HH:mm"), TIME_FORMAT)
        : default_time,
      end_date_time: data?.end_date_time
        ? moment(end_date_time.format("HH:mm"), TIME_FORMAT)
        : default_time,
      is_update: true,
      product_area_chat_tagged_agents:
        data?.product_area_chat_tagged_agents?.map((elem) => elem?.user_id),
    });

    const selectedIdArr = data?.product_area_chat_tagged_agents.map(
      (elem) => elem.user_id
    );

    await mapSelectedDdlData(selectedIdArr, [], usersCollection, "user_id")
      .then((dataToMap) => {
        if (dataToMap && dataToMap.mainList) setAgents(dataToMap.mainList);
        if (dataToMap && dataToMap.selectedList)
          setSelectedAgents(dataToMap.selectedList);
      })
      .catch((err) => {});

    setLoading(false);
  };

  const handleSubmit = async () => {
    if (validator.current.allValid()) {
      const finalSelectedAgents = selectedAgents?.map((item) => item.user_id);
      let clonedFields = cloneDeep(fields);
      if (
        moment(clonedFields.start_date_time) >
        moment(clonedFields.end_date_time)
      )
        toast.error("Please select correct time");
      else if (
        getMinutesDiff(
          moment(clonedFields.start_date_time),
          moment(clonedFields.end_date_time)
        ) < 10
      )
        toast.error("Please select atleast 10 mins duration");
      else if (isEmpty(finalSelectedAgents))
        toast.error("Please select atleast 10 mins duration");
      else {
        setLoading(true);
        clonedFields.product_area_chat_tagged_agents = finalSelectedAgents;
        clonedFields.brand_id = props.data.brand_id;
        clonedFields.zone_id = props.data.zone_id;
        clonedFields.is_update = props.data.chat_configuration_id
          ? true
          : false;

        const start_date_str =
          props.data.currentDateForCalender +
          " " +
          moment(clonedFields.start_date_time).format("HH:mm:ss");
        const start_utc = createISOTimeString(start_date_str);
        const end_date_str =
          props.data.currentDateForCalender +
          " " +
          moment(clonedFields.end_date_time).format("HH:mm:ss");
        const end_utc = createISOTimeString(end_date_str);
        clonedFields.start_date_time = start_utc;
        clonedFields.end_date_time = end_utc;

        let endPoint = "";
        let method = "";
        if (props.data.chat_configuration_id) {
          method = "PUT";
          endPoint = "/admin/zoneareastaff/update";
        } else {
          method = "POST";
          endPoint = "/admin/zoneareastaff/create";
        }
        await ApiCall(method, clonedFields, endPoint, null)
          .then((response) => {
            if (props.isFromCalender && props.isFromCalender === "zone")
              props.data.getZoneCalenderData();
            else props.onSave();
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
        if (props.isFromCalender && props.isFromCalender === "zone")
          props.data.visibleChange();
      }
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  const handleStaffingFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  function handleAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents || [];
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    if (selectedAgents_list) {
      selectedAgents_list.push(agents_list[index]);
      agents_list.splice(index, 1);
      setSelectedAgents([...selectedAgents_list]);
      setAgents([...agents_list]);
    }
  }

  function handleSelectedAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);
  }

  function renderUI() {
    return (
      <>
        <div className={props.editCase ? "ant-modal-body" : ""}>
          {loading ? (
            <CommonLoader />
          ) : (
            <>
              <Row>
                <Col
                  span={6}
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fs-16">
                    Start Time
                    <RedAsterick />
                  </span>
                </Col>
                <Col span={18}>
                  <TimePicker
                    allowClear={false}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    id="staffing_start_date_time"
                    name="staffing_start_date_time"
                    format={"HH:mm"}
                    className="w-100 date_time zIndex1051"
                    value={fields?.start_date_time}
                    onSelect={(val) => {
                      handleStaffingFieldsChange(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "start_date_time"
                      );
                    }}
                    onChange={(val) => {
                      handleStaffingFieldsChange(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "start_date_time"
                      );
                    }}
                    onBlur={() =>
                      validator.current.showMessageFor(
                        "staffing_start_date_time"
                      )
                    }
                  />
                  {validator.current.message(
                    "",
                    fields?.start_date_time,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col
                  className="d-flex align-items-center justify-content-center"
                  span={6}
                >
                  <span className="fs-16">
                    End Time
                    <RedAsterick />
                  </span>
                </Col>
                <Col span={18}>
                  <TimePicker
                    allowClear={false}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    name="staffing_end_date_time"
                    disabledTime={() =>
                      getDisabledTime(fields?.start_date_time)
                    }
                    format={"HH:mm"}
                    className="w-100 date_time"
                    value={fields?.end_date_time}
                    onSelect={(val) => {
                      if (
                        moment(moment(val).format("HH:mm"), TIME_FORMAT) <
                        fields?.start_date_time
                      ) {
                        handleStaffingFieldsChange(
                          moment(fields?.end_date_time),
                          "end_date_time"
                        );
                        toast.error("Please select correct time");
                      } else
                        handleStaffingFieldsChange(
                          moment(moment(val).format("HH:mm"), TIME_FORMAT),
                          "end_date_time"
                        );
                    }}
                    onChange={(val) => {
                      if (
                        moment(moment(val).format("HH:mm"), TIME_FORMAT) <
                        fields?.start_date_time
                      ) {
                        handleStaffingFieldsChange(
                          moment(fields?.end_date_time),
                          "end_date_time"
                        );
                        toast.error("Please select correct time");
                      } else
                        handleStaffingFieldsChange(
                          moment(moment(val).format("HH:mm"), TIME_FORMAT),
                          "end_date_time"
                        );
                    }}
                    onBlur={() =>
                      validator.current.showMessageFor("staffing_end_date_time")
                    }
                  />
                  {validator.current.message(
                    "",
                    fields?.end_date_time,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
              <div className="row mt-4 ">
                <Row className="mb-3">
                  <Col span={6}>
                    <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                      Agents
                      <RedAsterick />
                    </h5>
                  </Col>
                  <Col span={9}>
                    <h6 className="productZoneSession_assignstaffs_heading">
                      All Agents
                    </h6>
                  </Col>
                  <Col span={9}>
                    <h6 className="productZoneSession_assignstaffs_heading">
                      Tagged Agents
                    </h6>
                  </Col>
                </Row>
                <Row className="modal_row mb-3">
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={8}>
                    <div className={"dropdown_container"} id="area">
                      <Select
                        getPopupContainer={() =>
                          document.getElementById("area")
                        }
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        value="All Agents"
                        onChange={handleAgentsChange}
                        open={openAssignStaffList}
                        onFocus={() => setOpenAssignStaffList(true)}
                        onBlur={() => setOpenAssignStaffList(false)}
                        onSearch={() => setOpenAssignStaffList(true)}
                        listItemHeight={6}
                        listHeight={200}
                        className="border border-gray fs-bold-14"
                      >
                        {agents?.map((data) => (
                          <Option
                            key={"agents_" + data.user_id}
                            value={data.user_id}
                            className="dropdown_options"
                          >
                            {data.first_name + " " + data.last_name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {validator.current.message(
                      "Tagged Agents",
                      selectedAgents,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                  <Col span={8} offset={2}>
                    <div className={"dropdown_container"} id="area1">
                      <Select
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        showSearch
                        placeholder="Search Agents"
                        optionFilterProp="children"
                        value="Tagged Agents"
                        listItemHeight={6}
                        listHeight={200}
                        open={openAssignStaffList}
                        onFocus={() => setOpenAssignStaffList(true)}
                        onSearch={() => setOpenAssignStaffList(true)}
                        className="border border-gray fs-bold-14"
                        name="assigned_agents"
                        onBlur={() => {
                          setOpenAssignStaffList(false);
                          validator.current.showMessageFor("assigned_agents");
                        }}
                      >
                        {selectedAgents?.map((data) => (
                          <Option
                            key={"assigned_agents_" + data.user_id}
                            value={data.user_id}
                            className="dropdown_options"
                          >
                            {data.first_name + " " + data.last_name}
                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleSelectedAgentsChange(data?.user_id)
                                }
                              />
                            </span>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
        {props.editCase ? renderFooter() : null}
      </>
    );
  }

  function renderFooter() {
    return (
      <div className="ant-modal-footer">
        <Button
          className="w-25 fs-16 rounded staffing_save_btn"
          key="cancel"
          type="primary"
          onClick={() => {
            if (props.isFromCalender && props.isFromCalender === "zone")
              props.data.visibleChange();
            else props.onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-25 fs-16 rounded staffing_save_btn"
          key="saveZoneChat"
          type="primary"
          htmlType="submit"
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </div>
    );
  }

  if (props.editCase) return renderUI();
  else
    return (
      <Modal
        title={
          <>
            <h4 className="text-center fs-pink">Product/area chat staffing</h4>
            <p className="text-center fs-16 fs-pink">
              Zone name (pulled in from zone editor)
            </p>
          </>
        }
        visible={true}
        width={950}
        onOk={() => {
          if (!loading) {
            if (props.isFromCalender && props.isFromCalender === "zone")
              props.data.visibleChange();
            else props.onCancel();
          }
        }}
        onCancel={() => {
          if (!loading) {
            if (props.isFromCalender && props.isFromCalender === "zone")
              props.data.visibleChange();
            else props.onCancel();
          }
        }}
        footer={[
          <Button
            className="w-25 fs-16 rounded staffing_save_btn"
            key="cancel"
            type="primary"
            onClick={() => {
              if (props.isFromCalender && props.isFromCalender === "zone")
                props.data.visibleChange();
              else props.onCancel();
            }}
          >
            Cancel
          </Button>,
          <Button
            className="w-25 fs-16 rounded staffing_save_btn"
            key="saveZoneChat"
            type="primary"
            htmlType="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>,
        ]}
      >
        {renderUI()}
      </Modal>
    );
};

export default ProductZoneChat;
