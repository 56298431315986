import React, { useState, useEffect } from "react";
import CommonLoader from "../../components/Widgets/CommonLoader";
import "./commonPopup.css";
import { Button, Modal } from "antd";
import { wallClock } from "../../components/Images/Images";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import moment from "moment/moment";

const SessionConfirmation = (props) => {
  const { data } = props;

  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 400 });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const saveResponseData = async (bool) => {
    let objToPass = {};
    if (bool) objToPass = { ...data, rsvp_type: "YES" };
    else objToPass = { ...data, rsvp_type: "NO" };
    data !== null &&
      (await ApiCall("POST", objToPass, "/admin/mycalendarrsvp/save", null)
        .then((response) => {
          if (response.data.code === 200) {
            toast.success(response.data.message);
            window.location.reload();
            // getMyCalendarAppointmentDetails()
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        }));
  };

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "w-100 p-4"
              : "w-100"
          }
        >
          <div>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-1">
                <img src={wallClock} />
              </div>
              <div className={`${isMobile && "col-5"} w-75 fs-14`}>
                <b>
                  {moment(data?.startDate).format("hh:mm")} -
                  {" " + moment(data?.endDate).format("hh:mm")}
                </b>
              </div>
            </div>
            <div className="row mt-3 mb-2">
              <div className="col-2"></div>
              <div className="col-10">
                <Button
                  type="primary"
                  className="button-pink py-1 rounded"
                  onClick={() => saveResponseData(true)}
                >
                  Accept
                </Button>
                <Button
                  type="primary"
                  className="button-pink py-1 mx-3 rounded"
                  onClick={() => saveResponseData(false)}
                >
                  Reject
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  return (
    <Modal
      title={"Update Meeting"}
      visible={true}
      width={950}
      onOk={() => {
        if (!loading) props.onCancel();
      }}
      onCancel={() => {
        if (!loading) props.onCancel();
      }}
      footer={null}
    >
      {renderUI()}
    </Modal>
  );
};

export default SessionConfirmation;
