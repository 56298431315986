import React from "react";
import CommonLoader from "../../../../../components/Widgets/CommonLoader";

const VistaZoneShowMessage = (props) => {
  if (props?.flag && !props?.message) return <CommonLoader />;
  return (
    <div className="w-100 h-100 flex_center">
      <h1 className="fs-pink">{props?.message}</h1>
    </div>
  );
};

export default VistaZoneShowMessage;
