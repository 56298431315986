import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import VideoConference from "./VideoConference";
import "./VideoConference.css";
import { useDispatch, useSelector } from "react-redux";
import {
  myCalendarAppoinmentVisible,
  setGoogleAuthData,
  setNetTableJitsiModalClosed,
  setVideoConferenceData,
} from "../../redux/slice/videoConference";
import Streaming from "./Streaming";
import { toast } from "react-toastify";

const MeetContainer = (props) => {
  let socket = props?.socket;
  const [isVideoConfVisible, setIsVideoConfVisible] = useState(true);
  const [chatId, setChatId] = useState(null);
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");
  let CryptoJS = require("crypto-js");
  const auditorium_session_id = useSelector(
    (state) => state?.videoConference?.auditorium_session_id
  );

  const is_speaker = useSelector((state) => state?.videoConference?.is_speaker);
  const is_producer_curator = useSelector(
    (state) => state?.videoConference?.is_producer_curator
  );

  const is_demo_room = useSelector(
    (state) => state?.videoConference?.is_demo_room
  );

  const is_video_call = useSelector(
    (state) => state?.videoConference?.is_video_call
  );

  const is_meeting = useSelector((state) => state?.videoConference?.is_meeting);

  const isFromNetworking = useSelector(
    (state) => state?.videoConference?.isFromNetworking
  );

  const auditorium_configuration_id = useSelector(
    (state) => state?.videoConference?.auditorium_configuration_id
  );

  const is_moderator = useSelector(
    (state) => state?.videoConference?.is_moderator
  );

  const isFromProductZone = useSelector(
    (state) => state?.videoConference?.isFromProductZone
  );

  const receiver_chat_id = useSelector(
    (state) => state?.videoConference?.receiver_chat_id
  );

  const isFromExternalSourceEnabled = useSelector(
    (state) => state?.videoConference?.isFromExternalSourceEnabled
  );

  useEffect(() => {
    if (localStorage.getItem("user_type")) {
      const ciphertext = localStorage.getItem("user_type");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(user_type);
    }
  }, []);

  useEffect(() => {
    const rejectCallListener = (obj) => {
      console.log("rejectCallListener", obj);
      console.log("chat_id", props?.chat_id);

      if (
        obj?.notificationFrom === "helpandsupport" &&
        obj?.user_id === props?.loginUserId
      ) {
        toast.error("Call Declined");
        onCancel();
      }

      if (props?.chat_id === obj?.chat_id) {
        toast.error("Call Declined");
        onCancel();
      }
    };

    const closeVideoCallPopupListener = (data) => {
      console.log("closeVideoCallPopupListener", data);
      console.log("chat_id", props?.chat_id);

      if (
        props?.chat_id === data?.chat_id ||
        props?.loginUserId === data?.chat_id
      ) {
        toast.error("Call Ended");
        onCancel();
      }
    };

    socket?.on("rejectCallListener", rejectCallListener);

    socket?.on("closeVideoCallPopupListener", closeVideoCallPopupListener);

    return () => {
      socket?.off("rejectCallListener");
      socket?.off("closeVideoCallPopupListener");
    };
  }, [socket]);

  const onCancel = () => {
    setIsVideoConfVisible(false);
    let obj = {
      videoConferenceUrl: "",
      createPollFlag: false,
      is_producer_curator: false,
      isFromNetworking: false,
      auditorium_session_id: null,
      myCalendarAppoinmentVisible: false,
      is_speaker: false,
      loading: false,
      shuffleTime: null,
      networking_session_id: null,
      waterFallTime: null,
      isNetworkTableJitsiModalClosed: false,
      is_demo_room: false,
      is_video_call: false,
      is_meeting: false,
      isPollOptionsVisible: false,
      isFromProductZone: false,
      receiver_chat_id: null,
      is_call_accepted: false,
    };

    dispatch(setVideoConferenceData(obj));

    dispatch(setNetTableJitsiModalClosed(true));

    if (window.location.pathname === "/mycalendar") {
      dispatch(myCalendarAppoinmentVisible());
    }
  };

  return (
    <div>
      <Modal
        wrapClassName="video-coneference-modal"
        visible={isVideoConfVisible}
        footer={null}
        onCancel={() => {
          if (is_video_call) {
            console.log("closeVideoCallNotification");

            console.log("receiver_chat_id", receiver_chat_id);
            let test123 = socket.emit(
              "closeVideoCallNotification",
              JSON.stringify({
                chat_id: receiver_chat_id,
              })
            );

            let test1234 = socket.emit(
              "closeVideoCallPopup",
              JSON.stringify({
                chat_id: receiver_chat_id,
              })
            );

            console.log("test123", test123);
            console.log("test1234", test1234);
          }
          onCancel();
        }}
      >
        {(is_speaker ||
          is_producer_curator ||
          isFromNetworking ||
          is_meeting ||
          is_demo_room ||
          is_video_call ||
          isFromProductZone) &&
        !isFromExternalSourceEnabled ? (
          <VideoConference
            isProducerCurator={is_producer_curator}
            socket={socket}
            is_moderator={is_moderator}
            closeMeetingModal={() => onCancel()}
            isVideoCall={is_video_call}
          />
        ) : (
          <Streaming
            isProducerCurator={is_producer_curator}
            isFromPopup={{
              auditorium_session_id,
              flag: true,
              auditorium_configuration_id,
            }}
            socket={socket}
            is_moderator={is_moderator}
          />
        )}
      </Modal>
    </div>
  );
};

export default MeetContainer;
