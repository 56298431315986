import React from "react";

export const Sort = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.256"
        height="18.022"
        viewBox="0 0 15.256 18.022"
      >
        <g id="sort" transform="translate(0.684 0.751)">
          <g id="Group_1" data-name="Group 1">
            <path
              id="Union_1"
              data-name="Union 1"
              d="M.189,16.846A.3.3,0,0,1,0,16.566V.3A.3.3,0,0,1,.3,0h.734a.3.3,0,0,1,.3.3V14.064l3.1-3.1a.309.309,0,0,1,.215-.088.3.3,0,0,1,.216.088l.518.518a.306.306,0,0,1,0,.43L.521,16.782A.306.306,0,0,1,.3,16.87.3.3,0,0,1,.189,16.846Z"
              transform="translate(8.592 -0.099)"
            />
            <path
              id="Union_1_-_Outline"
              data-name="Union 1 - Outline"
              d="M.3,17.37a.807.807,0,0,1-.8-.8V.3A.805.805,0,0,1,.3-.5h.734a.805.805,0,0,1,.8.8V12.856l2.245-2.247L4.1,10.6a.808.808,0,0,1,1.131.006l.519.519a.8.8,0,0,1,0,1.137L.874,17.135A.812.812,0,0,1,.3,17.37ZM.5.5V16.1l4.4-4.4-.242-.242L1.7,14.417a.5.5,0,0,1-.854-.353V.5Z"
              transform="translate(8.592 -0.099)"
            />
          </g>
          <path
            id="Union_3"
            data-name="Union 3"
            d="M4.444,16.871a.3.3,0,0,1-.306-.3V2.808l-3.1,3.1a.308.308,0,0,1-.432,0L.091,5.39A.309.309,0,0,1,0,5.174a.313.313,0,0,1,.091-.216L4.96.09a.3.3,0,0,1,.52.216V16.567a.3.3,0,0,1-.3.3Z"
            transform="translate(-0.184 -0.251)"
          />
          <path
            id="Union_3_-_Outline"
            data-name="Union 3 - Outline"
            d="M5.175,17.371H4.444a.806.806,0,0,1-.806-.8V4.015L1.392,6.262a.808.808,0,0,1-1.136,0l-.52-.522A.813.813,0,0,1-.5,5.175a.81.81,0,0,1,.231-.563l.007-.007L4.606-.264A.808.808,0,0,1,5.177-.5a.8.8,0,0,1,.306.061.8.8,0,0,1,.5.745V16.567a.806.806,0,0,1-.229.564l-.006.006A.812.812,0,0,1,5.175,17.371Zm-.538-1h.342V.777l-4.4,4.4.241.242L3.784,2.454a.5.5,0,0,1,.854.353Z"
            transform="translate(-0.184 -0.251)"
          />
        </g>
      </svg>
    </>
  );
};
