import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  headerObj: {},
  events: [],
  initial_event_id: null,
  event_id: null,
  logo_url: localStorage.getItem("logo_url"),
  isFromNotification: false,
  notificationForCalendar: {
    hallType: "",
    sessionId: null,
  },
  helpAndSupportData: {
    supportType: "",
    userId: null,
  },
  peopleEventData: {
    key: "",
    value: null,
  },
  reloadChannelDataFlag: false,
  reloadSupportDataFlag: false,
};

export const setHeaderData = createAsyncThunk(
  "setHeader/setHeaderData",
  async ({ title, breadcrumbs }) => {
    return { title, breadcrumbs };
  }
);

export const setHeaderImage = createAsyncThunk(
  "setHeader/setHeaderImage",
  async ({ logo_url }) => {
    return { logo_url };
  }
);

export const setHeaderEventList = createAsyncThunk(
  "setEventHeaderList/setEventHeaderList",
  async (events, event_id) => {
    return { events };
  }
);

export const setHeaderDefaultEventId = createAsyncThunk(
  "setHeaderDefaultEventID/setEventDefaultHeaderID",
  async (event_id) => {
    return { event_id };
  }
);

export const setHeaderNewEventId = createAsyncThunk(
  "setHeaderNewEventID/setEventNewHeaderID",
  async (event_id) => {
    return { event_id };
  }
);

export const setCalendarNotificationData = createAsyncThunk(
  "setCalendarNotificationData/setCalendarNotificationData",
  async ({ hallType, sessionId }) => {
    return {
      hallType,
      sessionId,
    };
  }
);

export const setHelpAndSupportData = createAsyncThunk(
  "setHelpAndSupportData/setHelpAndSupportData",
  async ({ supportType, userId }) => {
    return {
      supportType,
      userId,
    };
  }
);

export const setPeopleEventData = createAsyncThunk(
  "setPeopleEventData/setPeopleEventData",
  async ({ key, value }) => {
    return {
      key,
      value,
    };
  }
);

export const reloadChannelsData = createAsyncThunk(
  "reloadChannelsData/reloadChannelsData",
  async ({ flag }) => {
    return flag;
  }
);

export const reloadSupportData = createAsyncThunk(
  "reloadSupportData/reloadSupportData",
  async ({ flag }) => {
    return flag;
  }
);

export const setHeaderSlice = createSlice({
  name: "HeaderData",
  initialState,
  reducers: {},
  extraReducers: {
    [setHeaderData.pending]: (state) => {
      state.loading = true;
    },
    [setHeaderData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.headerObj = payload;
    },
    [setHeaderData.rejected]: (state) => {
      state.loading = false;
    },
    [setHeaderImage.pending]: (state) => {
      state.loading = true;
    },
    [setHeaderImage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.logo_url = payload.logo_url;
    },
    [setHeaderImage.rejected]: (state) => {
      state.loading = false;
    },
    [setHeaderEventList.pending]: (state) => {
      state.loading = true;
    },
    [setHeaderEventList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.events = payload.events;
    },
    [setHeaderEventList.rejected]: (state) => {
      state.loading = false;
    },
    [setHeaderDefaultEventId.pending]: (state) => {
      state.loading = true;
    },
    [setHeaderDefaultEventId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.initial_event_id = payload.event_id;
    },
    [setHeaderDefaultEventId.rejected]: (state) => {
      state.loading = false;
    },
    [setHeaderNewEventId.pending]: (state) => {
      state.loading = true;
    },
    [setHeaderNewEventId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.event_id = payload.event_id;
    },
    [setHeaderNewEventId.rejected]: (state) => {
      state.loading = false;
    },
    [setCalendarNotificationData.pending]: (state) => {
      state.loading = true;
    },
    [setCalendarNotificationData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notificationForCalendar = {
        hallType: payload?.hallType,
        sessionId: payload?.sessionId,
      };
    },
    [setCalendarNotificationData.rejected]: (state) => {
      state.loading = false;
    },
    [setHelpAndSupportData.pending]: (state) => {
      state.loading = true;
    },
    [setHelpAndSupportData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.helpAndSupportData = {
        supportType: payload?.supportType,
        userId: payload?.userId,
      };
    },
    [setHelpAndSupportData.rejected]: (state) => {
      state.loading = false;
    },
    [setPeopleEventData.pending]: (state) => {
      state.loading = true;
    },
    [setPeopleEventData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.peopleEventData = {
        type: payload?.key,
        value: payload?.value,
      };
    },
    [setPeopleEventData.rejected]: (state) => {
      state.loading = false;
    },
    [reloadChannelsData.pending]: (state) => {
      state.loading = true;
    },
    [reloadChannelsData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reloadChannelDataFlag = payload;
    },
    [reloadChannelsData.rejected]: (state) => {
      state.loading = false;
    },
    [reloadSupportData.pending]: (state) => {
      state.loading = true;
    },
    [reloadSupportData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reloadSupportDataFlag = payload;
    },
    [reloadSupportData.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const headerReducer = setHeaderSlice.reducer;
