import React from "react";
import { Select, Table } from "antd";

const CompareProductsTeams = () => {
  const dummyData = [
    {
      brand: "Dell",
      product: "M6300",
      agent: "Richard",
      networked_with: 6,
      total_networking_time: 23,
      meetings: "57 Mins",
      meeting_time: "57 Mins",
      avg_mfg_time: "57 Mins",
      chat_connects: 12,
      chat_message: 22,
      chat_time: "57 Mins",
      profile_views: 12,
      viewing_other_profiles: 12,
      social_wall_posts: 12,
      surveys_completes: 12,
    },
  ];

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 100,
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      width: 150,
    },
    {
      title: "#Networked With",
      dataIndex: "networked_with",
      key: "networked_with",
      width: 100,
    },
    {
      title: "Total Networking Time",
      dataIndex: "total_networking_time",
      key: "total_networking_time",
      width: 100,
    },
    {
      title: "#Meetings",
      dataIndex: "meetings",
      key: "meetings",
      width: 100,
    },
    {
      title: "Meeting Time",
      dataIndex: "meeting_time",
      key: "meeting_time",
      width: 100,
    },
    {
      title: "Avg. mfg Time",
      dataIndex: "avg_mfg_time",
      key: "avg_mfg_time",
      width: 100,
    },
    {
      title: "#Chat Connects",
      dataIndex: "chat_connects",
      key: "chat_connects",
      width: 100,
    },
    {
      title: "#Chat Messages",
      dataIndex: "chat_message",
      key: "chat_message",
      width: 100,
    },
    {
      title: "#Chat Time",
      dataIndex: "chat_time",
      key: "chat_time",
      width: 100,
    },
    {
      title: "#Profile Views",
      dataIndex: "profile_views",
      key: "profile_views",
      width: 100,
    },
    {
      title: "#Viewing Other Profiles",
      dataIndex: "viewing_other_profiles",
      key: "viewing_other_profiles",
      width: 100,
    },
    {
      title: "#Social Wall Posts",
      dataIndex: "social_wall_posts",
      key: "social_wall_posts",
      width: 100,
    },
    {
      title: "#Surveys Completed",
      dataIndex: "surveys_completes",
      key: "surveys_completes",
      width: 100,
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    // dispatch(
    //   getCustomer({
    //     sort_type: sorter.columnKey,
    //     sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    //   })
    // );
  };
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <Select
          showSearch
          //   style={{ width: 300 }}
          placeholder="Select Products"
          optionFilterProp="children"
          className="fs-bold-14"
          name="booth_location_id"
          //   onChange={(val) => {
          //     setFieldValue("booth_location_id", val);
          //     setFieldValue("survey_id", null);
          //   }}
          listHeight={120}
          listItemHeight={4}
          //   value={values?.booth_location_id}
        >
          {/* {boothLocationList &&
            boothLocationList.map((data) => (
              <Option value={data.booth_location_id}>
                {data.booth_location}
              </Option>
            ))} */}
        </Select>
        <Select
          showSearch
          //   style={{ width: 300 }}
          placeholder="Networking Activity"
          optionFilterProp="children"
          className="fs-bold-14"
          name="booth_location_id"
          //   onChange={(val) => {
          //     setFieldValue("booth_location_id", val);
          //     setFieldValue("survey_id", null);
          //   }}
          listHeight={120}
          listItemHeight={4}
          //   value={values?.booth_location_id}
        >
          {/* {boothLocationList &&
            boothLocationList.map((data) => (
              <Option value={data.booth_location_id}>
                {data.booth_location}
              </Option>
            ))} */}
        </Select>
      </div>
      <Table
        className="mt-3"
        columns={columns}
        dataSource={dummyData}
        pagination={{
          showSizeChanger: true,
        }}
        scroll={{
          x: 1300,
          // y: "58vh",
        }}
        bordered={false}
        showSizeChange={true}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default CompareProductsTeams;
