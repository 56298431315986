import { createGlobalStyle } from "styled-components";

export const theme = {
  palette: {
    default: "#000000",
    black: "#000000",
    white: "#ffff",
  },

  shadow: `0px 100px 80px rgba(0, 0, 0, 0.07),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)`,

  transition: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
    },
    easing: "cubic-bezier(0.4, 0, 0.2, 1)",
  },

  animation: {
    hover: 0.7,
  },
};

const GlobalStyles = createGlobalStyle(() => {
  const windowSizes = {
    width: "100%",
    height: "100%",
  };

  return {
    "*": {
      boxSizing: "border-box",
      margin: "0",
      padding: "0",

      "&:focus": {
        outline: "none",
      },
      "&:before": {
        boxSizing: "border-box",
      },
      "&:after": {
        boxSizing: "border-box",
      },
    },

    // Why do we use percentage here?
    // The default fontsize of a browser is 16px, if we want to use rem for the all children element
    // to make the app maintainable we set the root font size as 10px. 10/16 = 62.5%
    // This respects users' configured preference for font size in their browser while also sizing our app
    // consistently and clearly.
    // html: {
    //   // ...windowSizes,
    //   fontSize: "62.5%",
    // },

    body: {
      ...windowSizes,
    },

    "#root": {
      // ...windowSizes,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& > *": {
        backgroundColor: theme.palette.white,
      },
    },

    img: {
      // width: "100%",
      userDrag: "none",
    },
  };
});

export default GlobalStyles;
