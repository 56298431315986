import React, { useEffect, useState } from "react";
import { Result, Button } from "antd";

const PageNotAvailable = (props) => {
  var CryptoJS = require("crypto-js");

  const [userType, setUserType] = useState("");

  var decryptedData = "";
  useEffect(() => {
    if (localStorage.getItem("user_type")) {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);
    }
  }, [CryptoJS.AES]);

  return (
    <div className="m-auto text-center h-100" style={{ height: "150%" }}>
      <div className="text-start m-4">
        {userType && userType === "SUPER ADMIN" ? (
          <Button onClick={() => props.history.push("/eventmanagement")}>
            Back Home
          </Button>
        ) : userType === "CUSTOMER" ? (
          <Button onClick={() => props.history.push("/eventmanagement")}>
            Back Home
          </Button>
        ) : userType === "AGENT" ? (
          <Button onClick={() => props.history.push("/expomap")}>
            Back Home
          </Button>
        ) : userType === "DELEGATE" ? (
          <Button onClick={() => props.history.push("/expomap")}>
            Back Home
          </Button>
        ) : userType === "SPEAKER" ? (
          <Button onClick={() => props.history.push("/expomap")}>
            Back Home
          </Button>
        ) : (
          <Button onClick={() => props.history.push("/login")}>
            Back Home
          </Button>
        )}
      </div>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </div>
  );
};

export default PageNotAvailable;
