import {
  COMETCHAT_CONSTANTS,
  TIME_FORMAT,
  UPLOAD_BASIC_PROPS,
  UTC_DATE_TIME_FORMAT,
} from "../components/constsnts/common.constants";
import { toast } from "react-toastify";
import moment from "moment";
import { cloneDeep, isEqual, uniqWith } from "lodash";
import { ifTimeSlotIncluded } from "../views/EventCalender/eventCalender.utils";
import { CometChat } from "@cometchat-pro/chat";
import {
  setVisitBoothData,
  setVistaBrandData,
  setVistaEventId,
} from "../redux/slice/vista";
import { isEmpty } from "lodash-es";
import uuid from "react-uuid";
import { Helmet } from "react-helmet";
var momentTz = require("moment-timezone");

export const isValidImage = (fileObj) => {
  const isCorrectMime = UPLOAD_BASIC_PROPS.imageProps.mimeTypeArr.includes(
    fileObj.type
  );
  const isWithinMaxLimit =
    fileObj.size / 1024 / 1024 < UPLOAD_BASIC_PROPS.imageProps.maxSize;
  if (!isCorrectMime) {
    toast.error(
      "Please upload " + UPLOAD_BASIC_PROPS.imageProps.fileType + " file"
    );
    return false;
  } else if (!isWithinMaxLimit) {
    toast.error(
      "File must be smaller than " +
        UPLOAD_BASIC_PROPS.imageProps.maxSize.toString() +
        " MB"
    );
    return false;
  } else return true;
};

export const isValidImageVideo = (fileObj) => {
  const isCorrectMime = UPLOAD_BASIC_PROPS.videoProps.mimeTypeArr.includes(
    fileObj.type
  );
  const isWithinMaxLimit =
    fileObj.size / 1024 / 1024 < UPLOAD_BASIC_PROPS.videoProps.maxSize;
  if (!isCorrectMime) {
    toast.error(
      "Please upload " + UPLOAD_BASIC_PROPS.videoProps.fileType + " file"
    );
    return false;
  } else if (!isWithinMaxLimit) {
    toast.error(
      "File must be smaller than " +
        UPLOAD_BASIC_PROPS.videoProps.maxSize.toString() +
        " MB"
    );
    return false;
  } else return true;
};

export const getDisabledHours = (d, t) => {
  let hours = [];
  if (d && t) {
    for (let i = 0; i < moment(d + " " + t).hour(); i++) {
      hours.push(i);
    }
    return hours;
  } else return null;
};

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const getDisabledTime = (current) => {
  return {
    disabledHours: () => range(0, current.get("hours")),
    // disabledMinutes: () => range(0, current.get("minutes")),
  };
};

export function getFileNameFromPath(path) {
  let filename = "";
  if (path) filename = path.replace(/^.*[\\\/]/, "");
  if (filename === null || filename === "null") return "";
  else return filename;
}

export function getDatesBetweenDates(startDate, endDate) {
  let dateArray = [];
  let currentDate = moment(startDate);
  let stopDate = moment(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}

export function getNearestFutureDate(dates) {
  let todayDateString = moment().format("YYYY-MM-DD");
  const now = new Date(todayDateString);
  let closest = Infinity;
  dates.forEach(function (d) {
    const dateToCheck = new Date(d);
    if (
      dateToCheck >= now &&
      (dateToCheck < new Date(closest) || dateToCheck < closest)
    ) {
      closest = d;
    }
  });
  if (closest === Infinity) return [];
  return closest;
}

export function isImage(urlToCheck) {
  if (
    urlToCheck &&
    (urlToCheck.includes("jpg") ||
      urlToCheck.includes("jpeg") ||
      urlToCheck.includes("png") ||
      urlToCheck.includes("JPG") ||
      urlToCheck.includes("JPEG") ||
      urlToCheck.includes("PNG"))
  )
    return true;
  else return false;
}

export const todayIsBeetweenTwoDates = (startDate, endDate) => {
  const today = moment().format("LL");

  const todayMoment = moment(today);

  const sd = moment(startDate).format("LL");
  const ed = moment(endDate).format("LL");

  if ((sd === ed && sd === today) || ed === today || sd === today) {
    return true;
  } else if (todayMoment.isAfter(startDate) && todayMoment.isBefore(endDate)) {
    return true;
  } else {
    return false;
  }
};

export function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.userAgentData?.platform ?? window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function compareAllStartEndDates(data) {
  let areEventDatesProper = "yes";
  data &&
    data.forEach((ele) => {
      if (
        ele.start_date &&
        ele.end_date &&
        moment(ele.end_date) < moment(ele.start_date)
      )
        areEventDatesProper = "no";
    });
  return areEventDatesProper;
}
export function compareAllStartEndTimes(data) {
  let areEventTimesProper = "yes";
  data &&
    data.forEach((ele) => {
      if (
        ele.start_time &&
        ele.end_time &&
        moment(ele.end_time) <= moment(ele.start_time)
      )
        areEventTimesProper = "no";
    });
  return areEventTimesProper;
}

export function isPastDate(dateStringToCheck) {
  const dateToCheck = new Date(dateStringToCheck);
  let todayDateString = moment().format("YYYY-MM-DD");
  const now = new Date(todayDateString);

  if (dateToCheck < now) return "yes";
  else return "no";
}

export function compareAllDatesWithEveryArrayElement(data) {
  let areAllDatesDifferent = "yes";
  let clonedData = [];
  if (data) {
    clonedData = cloneDeep(data);
    clonedData = clonedData.filter((ele) => !ele.is_delete);
  }
  clonedData &&
    clonedData.forEach((ele, indexHere) => {
      let cutArray = [];

      //remove the element from array and then check with every element in that cut array
      if (clonedData.length > 1) {
        cutArray = clonedData.filter((ele, eleIndex) => eleIndex !== indexHere);
      }

      cutArray &&
        cutArray.forEach((checkEle) => {
          const startEleDate = moment(ele.start_date);
          const endEleDate = moment(ele.end_date);
          const startCheckDate = moment(checkEle.start_date);
          const endCheckDate = moment(checkEle.end_date);

          if (
            ele.start_date === checkEle.start_date ||
            ele.end_date === checkEle.end_date ||
            ele.end_date === checkEle.start_date ||
            ele.start_date === checkEle.end_date ||
            (startEleDate.isBefore(startCheckDate) &&
              startEleDate.isBefore(endCheckDate) &&
              endEleDate.isAfter(startCheckDate) &&
              endEleDate.isBefore(endCheckDate)) ||
            (startEleDate.isAfter(startCheckDate) &&
              startEleDate.isBefore(endCheckDate)) ||
            (endEleDate.isAfter(startCheckDate) &&
              endEleDate.isBefore(endCheckDate))
          ) {
            areAllDatesDifferent = "no";
          }
        });
    });

  return areAllDatesDifferent;
}

export async function mapSelectedDdlData(
  idsFromAPIs,
  selectedIds,
  clonedData,
  keyToCheck
) {
  return new Promise(function (resolve, reject) {
    if (idsFromAPIs) {
      let mainList = clonedData;
      let selectedList = selectedIds;
      idsFromAPIs.map((val) => {
        clonedData &&
          clonedData.forEach((ele) => {
            if (ele[keyToCheck] === val) {
              let index = mainList.findIndex((ele) => ele[keyToCheck] === val);
              selectedList.push(mainList[index]);
              mainList.splice(index, 1);
            }
          });
      });
      selectedList = uniqWith(selectedList, isEqual);
      resolve({ mainList, selectedList });
    } else {
      reject({ mainList: clonedData, selectedList: [] });
    }
  });
}

export function checkIfChatbotTimeSlot(dataToCheck) {
  if (dataToCheck && dataToCheck.chat_bot_start_time) {
    let chat_start_time = moment(dataToCheck.chat_bot_start_time, TIME_FORMAT);
    let chat_end_time = moment(dataToCheck.chat_bot_end_time, TIME_FORMAT);
    let getNowTime = moment();

    if (
      ifTimeSlotIncluded(getNowTime, getNowTime, chat_start_time, chat_end_time)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function sortData(sorter, dataToSort) {
  let clonedData = cloneDeep(dataToSort);
  clonedData.sort(function (a, b) {
    if (sorter.columnKey === "date_time_to_invite") {
      let dateA = new Date(a.date_time_to_invite).getTime();
      let dateB = new Date(b.date_time_to_invite).getTime();
      if (sorter.order === "ascend") return dateA > dateB ? 1 : -1;
      else return dateB > dateA ? 1 : -1;
    } else {
      if (sorter.order === "ascend")
        return b[sorter.columnKey] - a[sorter.columnKey];
      else return a[sorter.columnKey] - b[sorter.columnKey];
    }
  });

  return clonedData;
}

export const initCometChat = () => {
  if (!CometChat.isInitialized()) {
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(COMETCHAT_CONSTANTS.REGION)
      .build();
    CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(
      (res) => {
        console.log("CometChat initialized successfully", res);
      },
      (error) => {
        console.log("CometChat initialization failed with error:", error);
      }
    );
  }
};

export async function getComentChatUser() {
  await initCometChat();
  const chat_uid = localStorage.getItem("chat_uid");
  if (chat_uid) {
    return await CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
      (response) => {
        return {
          isValidUser: true,
          data: response,
          uid: response.getUid(),
        };
      },
      (error) => {
        console.log("CometChat Login failed with exception:", { error });
        return {
          isValidUser: false,
          data: null,
          uid: "",
        };
      }
    );
  } else {
    return {
      isValidUser: false,
      data: null,
      uid: "",
    };
  }
}

export function convertTimeStamp(dateToConvert, format) {
  const dateTOSend = moment(dateToConvert).format(format);
  return dateTOSend;
}

export function isRouteExistInMenu(CryptoJS) {
  const cipherTextSideMenu = localStorage.getItem("side_menu");
  let bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
  const decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  let is_route_present = false;
  decryptedDataSideMenu.forEach((element) => {
    if (element.route.includes(window?.location?.pathname))
      is_route_present = true;
    else if (element.sub_routes && !isEmpty(element.sub_routes)) {
      element.sub_routes.forEach((e) => {
        if (window.location.pathname.includes(e)) is_route_present = true;
      });
    }
  });

  return is_route_present;
}
export function vistaRedirectToBooth(
  tier,
  zones,
  sequence,
  userType,
  brand_id,
  eventID,
  history,
  dispatch,
  final_booth_url
) {
  if (final_booth_url) {
    if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
      dispatch(setVistaEventId(eventID));
    }
    dispatch(
      setVisitBoothData({
        tier,
        sequence,
        zones,
        final_booth_url,
        brand_id,
      })
    );
    dispatch(
      setVistaBrandData({
        brand_id: brand_id,
      })
    );

    history.push("/expomap");
  } else {
    toast.error("Vista Not Uploaded");
  }
}
export function isAdminLevelUser(userType) {
  if (
    userType === "SUPER ADMIN" ||
    userType === "CUSTOMER" ||
    userType === "BRAND ADMIN"
  )
    return true;
  else return false;
}

export function isNonAdminLevelUser(userType) {
  if (userType === "AGENT" || userType === "DELEGATE" || userType === "SPEAKER")
    return true;
  else return false;
}

export function isAdminCustomer(userType) {
  if (userType === "SUPER ADMIN" || userType === "CUSTOMER") return true;
  else return false;
}

export function isNotificationType(type) {
  if (
    type === "CHAT_DELEGATE" ||
    type === "VIDEO_DELEGATE" ||
    type === "CHAT_AGENT" ||
    type === "VIDEO_AGENT" ||
    type === "MY_CALENDAR_ALERTS_MAIN_AUDITORIUM" ||
    type === "MY_CALENDAR_ALERTS_SATELLITE_AUDITORIUM" ||
    type === "MY_CALENDAR_ALERTS_NETWORKING_LOUNGE" ||
    type === "MY_CALENDAR_ALERTS_PRODUCT_ZONE" ||
    type === "CALENDAR INVITES" ||
    type === "ONE_TO_ONE_CHAT" ||
    type === "ONE_TO_ONE_VIDEO" ||
    type === "EVENT_HELP_SUPPORT_DELEGATE" ||
    type === "EVENT_HELP_SUPPORT_AGENT" ||
    type === "TECHNICAL_HELP_SUPPORT_DELEGATE" ||
    type === "TECHNICAL_HELP_SUPPORT_AGENT" ||
    type === "MEETING" ||
    type === "ONE_TO_ONE_CHAT_RESPONSE" ||
    type === "ONE_TO_ONE_VIDEO_RESPONSE"
  )
    return true;
  else return false;
}

export function isAGENTNotificationType(type) {
  if (
    type === "CHAT_AGENT" ||
    type === "VIDEO_AGENT" ||
    type === "EVENT_HELP_SUPPORT_AGENT" ||
    type === "TECHNICAL_HELP_SUPPORT_AGENT"
  )
    return true;
  else return false;
}

export function isValidHttpURL(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function getStreamId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

export const createISOTimeString = (timeString) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isoStr = momentTz
    .tz(
      timeString, //"2021-01-27 14:02:45",
      UTC_DATE_TIME_FORMAT, //"DD-MM-YYYY HH:mm";
      timezone
    )
    .toISOString();
  return isoStr;
};

export const createTZString = (timeString) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return momentTz(timeString).tz(timezone);
};

export const convertToFormatWithTimezone = (stringToConvert, format) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const finalStr = moment(stringToConvert).tz(timezone).format(format);

  return finalStr;
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  // ctx.fillStyle = "#FFFFF";
  // ctx.strokeStyle = `hsl(${"#FF0000"}, 100%, 50%)`;
  // ctx.strokeStyle = "#FF0000";

  // ctx.fillStyle = "blue";
  // ctx.fillRect(10, 10, 100, 100);

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);
  ctx.fillStyle = "#fff"; // Set the background color to white
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // draw rotated image
  // ctx.drawImage(image, 0, 0);

  // ctx.rect(0, 0, canvas.width, canvas.height);
  // ctx.fillStyle = "red";
  // ctx.fill();

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  // console.log("pixel crop", pixelCrop);
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  var newData = data.data;
  for (var i = 0; i < newData.length; i += 4) {
    if (newData[i + 3] < 255) {
      newData[i] = 255;
      newData[i + 1] = 255;
      newData[i + 2] = 255;
      newData[i + 3] = 255;
    }
  }

  console.log("data", data);
  // // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // // paste generated rotate image at the top left corner
  ctx.fillStyle = "#0000FF";
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      let file = new File([blob], `${uuid()}.jpg`, { type: "image/jpeg" });

      resolve(file);
    }, "image/jpeg");
  });
}

export function diff_hours(dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(diff);
}

export function saveFile(url, fileName) {
  let endPoint = url.split(".").pop();
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = function () {
    const a = document.createElement("a");

    a.href = window.URL.createObjectURL(xhr.response);
    a.download = fileName + "." + endPoint || "gravit8_download"; // Set the file name.
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    // delete a;
  };

  xhr.open("GET", url);
  xhr.send();
}

export const isSuperAdminOrCustomer = (userType) => {
  return userType === "SUPER ADMIN" || userType === "CUSTOMER" ? true : false;
};

export const setCookie = (name, value, days = 1) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const clearAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

export const removeNegativeValues = (e, handleChange) => {
  const { value } = e.target;
  const reg = /^-?\d*(\.\d*)?$/;
  if (reg.test(value) && value && !value.includes("-")) {
    e.target.value = value;
    handleChange(e);
  }
};

export const generateHelmet = (title, description = "") => {
  return (
    <Helmet>
      <title>{title}</title>
      {description?.length > 0 ? (
        <meta name="description" content={description} />
      ) : null}
    </Helmet>
  );
};

export const findValueFromList = (
  list = [],
  value,
  keyNameToMatch,
  keyValueToReturn
) => {
  const filteredData = list?.find((obj) => obj?.[keyNameToMatch] === value);
  if (filteredData?.[keyValueToReturn]?.length > 0) {
    return filteredData?.[keyValueToReturn];
  }
  return "";
};

export const isValidNumber = (val) => {
  let value = parseFloat(val);
  if (
    typeof value !== "number" ||
    isNaN(value) ||
    !isFinite(value) ||
    value <= 0 ||
    !Number.isInteger(value) ||
    value.toString().includes(".")
  ) {
    return false;
  }
  return true;
};

export const handleCommonKeyDown = (evt) => {
  if (["e", "E", "+", "-", "."].includes(evt.key)) {
    evt.preventDefault();
  }
};

export const generateRandomKey = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}|<>?-=[];,./";
  let key = "";
  const keyLength = 32;
  for (let i = 0; i < keyLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    key += characters[randomIndex];
  }
  return key;
};
