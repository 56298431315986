import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

export const getPeople = createAsyncThunk(
  "people/getPeople",
  async (params) => {
    const response = await ApiCall("GET", "", "/admin/people/get", params);
    return response.data.data;
  }
);

export const peopleSlice = createSlice({
  name: "people",
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getPeople.pending]: (state, action) => {
      state.loading = true;
    },
    [getPeople.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [getPeople.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const peopleReducer = peopleSlice.reducer;
