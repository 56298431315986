import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Popover,
  Breadcrumb,
  Input,
  Select,
  Tooltip,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { getZone, changeStatus } from "../../redux/slice/zoneManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "../../components/svgJS/Filter";
import {
  getCustomerListForListing,
  getEventsForListing,
  getTiers,
  getZoneCreators,
  getBrandsForListing,
} from "../../utils/commonApis";
import {
  createTZString,
  generateHelmet,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import { cloneDeep, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { PAGINATION_DEFAULT_SETTINGS } from "../../components/constsnts/common.constants";
import CommonLoader from "../../components/Widgets/CommonLoader";
import moment from "moment";

const ZoneManagement = () => {
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");
  const { Option } = Select;
  const zoneSelector = useSelector((state) => state.zoneManagement.data);
  let loading = useSelector((state) => state.zoneManagement.loading);
  const [searchData, setsearchData] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [eventList, setEventList] = useState();
  const [tierList, setTierList] = useState();
  const [brandList, setBrandList] = useState();
  const [creatorList, setCreatorList] = useState();
  const [fields, setFields] = useState({
    customer: null,
    event_id: null,
    brand_id: null,
    id: null,
    is_customer: null,
    full_name: null,
    brand_tier: null,
  });
  const [page_no, set_page_no] = useState(PAGINATION_DEFAULT_SETTINGS.page_no);
  const [no_of_records, set_no_of_records] = useState(
    PAGINATION_DEFAULT_SETTINGS.no_of_records
  );

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      window.scrollTo(0, 0);
      dispatch(getZone({ page_no, no_of_records }));
      let customerResponse = await getCustomerListForListing({
        is_details: false,
      });
      setCustomerList(customerResponse);
      let tiersResponse = await getTiers();
      setTierList(tiersResponse);
    }
  }, [CryptoJS.AES]);

  useEffect(async () => {
    const eventParams = {
      is_filter: true,
      customer_id: fields?.customer,
    };
    let eventResponse = await getEventsForListing(eventParams);
    setEventList(eventResponse);
  }, [fields?.customer]);

  useEffect(async () => {
    const brandParams = {
      is_filter: true,
      event_id: fields?.event_id,
    };
    let brandResponse = await getBrandsForListing(brandParams);
    setBrandList(brandResponse);
  }, [fields?.event_id]);

  useEffect(async () => {
    const creatorParams = {
      customer_id: fields?.customer,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
    };
    let creatorResponse = await getZoneCreators(creatorParams);
    setCreatorList(creatorResponse);
  }, [fields?.customer, fields?.brand_id, fields?.event_id]);

  const tableData =
    zoneSelector &&
    !isEmpty(zoneSelector) &&
    zoneSelector.rows &&
    zoneSelector.rows.map((elem) => {
      const start_date_time_createdAt = createTZString(elem.created_at);

      const eleStartDateCreatedAt = moment(
        start_date_time_createdAt.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      return {
        ...elem,
        zone_id: elem.zone_id,
        created_at: eleStartDateCreatedAt.format("DD-MM-YYYY"),
        zone_title: elem.zone_title,
        brands: elem.zone_brand_title,
        locationonwall: elem.booth_location?.booth_location ?? "-",
        agent: elem.total_agents_count + "/" + elem.no_of_agents,
        customername: elem.account_name ?? "NA",
        eventdomain: elem.sub_domain ?? "NA",
        total_assets_count: {
          upper: elem?.total_assets_count,
          lower: elem?.no_of_assets_per_zone,
        },
        contact: elem?.main_contact_person,
        is_active: elem.is_active,
        createdby: elem?.created_user_name ?? "NA",
      };
    });

  const handleStatus = (id, bool, record) => {
    if (bool === false) {
      if (
        record &&
        (record.locationonwall === "" ||
          isEmpty(record.locationonwall) ||
          record.locationonwall === "-")
      )
        toast.error(
          "Please select the location from edit zone to active this zone"
        );
      else dispatch(changeStatus({ zone_id: id, is_active: !bool }));
    } else {
      dispatch(changeStatus({ zone_id: id, is_active: !bool }));
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "zone_id",
      key: "zone_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 70,
    },
    {
      title: "Brand",
      dataIndex: "brands",
      key: "brands",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (brands) => (
        <Tooltip placement="topLeft" title={brands}>
          {brands}
        </Tooltip>
      ),
    },
    {
      title: "Zone Title",
      dataIndex: "zone_title",
      key: "zone_title",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (zone_title) => (
        <Tooltip placement="topLeft" title={zone_title}>
          {zone_title}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 130,
      render: (text, record) => (
        <button
          className={
            record.is_active === true
              ? "bg-button-green active_btns"
              : "bg-button-red  inactive_btns"
          }
          onClick={() => handleStatus(record.zone_id, record.is_active, record)}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Location",
      dataIndex: "locationonwall",
      key: "locationonwall",
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },

    {
      title: "Assets",
      dataIndex: "total_assets_count",
      key: "total_assets_count",
      sorter: true,
      width: 90,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (record) => (
        <>
          <span>{record?.upper}</span>/<span>{record?.lower}</span>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      width: 90,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (contact) => (
        <Tooltip placement="topLeft" title={contact}>
          {contact}
        </Tooltip>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdby",
      key: "createdby",
      width: 165,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Created D/T",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (customername) => (
        <Tooltip placement="topLeft" title={customername}>
          {customername}
        </Tooltip>
      ),
    },
    {
      title: "Event Domain",
      dataIndex: "eventdomain",
      key: "eventdomain",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (eventdomain) => (
        <Tooltip placement="topLeft" title={eventdomain}>
          {eventdomain}
        </Tooltip>
      ),
    },

    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 40,
      align: "Center",
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link to={`viewzone/${record.zone_id}`}>
                <p className="options">
                  <EyeOutlined /> <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editzone/${record.zone_id}`}>
                <p className="options">
                  <EditOutlined /> <span className="">Edit Zone</span>
                </p>
              </Link>
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 65,
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`editzone/${record.zone_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    let sortObj = {
      page_no: pagination?.current ?? page_no,
      no_of_records,
    };
    if (
      pagination &&
      pagination.pageSize &&
      no_of_records !== pagination.pageSize
    ) {
      set_page_no(1);
      set_no_of_records(pagination.pageSize);
      sortObj = {
        page_no: 1,
        no_of_records: pagination.pageSize,
      };
    }
    sortObj = {
      ...sortObj,
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
      search: searchData,
      customer: fields?.customer,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      brand_tier: fields?.brand_tier,
      id: fields?.id,
      is_customer: fields?.is_customer,
      full_name: fields?.full_name,
    };
    dispatch(getZone(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
    const searchObj = {
      search: e.target.value,
      customer: fields?.customer,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      brand_tier: fields?.brand_tier,
      id: fields?.id,
      is_customer: fields?.is_customer,
      page_no,
      no_of_records,
    };
    dispatch(getZone(searchObj));
  };

  const handleFieldsChange = (val, name) => {
    let cloneFields = cloneDeep(fields);
    if (name === "id") {
      cloneFields = {
        ...cloneFields,
        [name]: val?.id,
        is_customer: val?.is_customer,
        full_name: val?.full_name,
      };
    } else if (name === "customer") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        event_id: null,
        brand_id: null,
        id: null,
        is_customer: null,
        full_name: null,
        brand_tier: null,
      };
    } else if (name === "event_id") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        brand_id: null,
        id: null,
        is_customer: null,
        full_name: null,
        brand_tier: null,
      };
    } else if (name === "brand_id") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        id: null,
        is_customer: null,
        full_name: null,
        brand_tier: null,
      };
    } else {
      cloneFields = { ...cloneFields, [name]: val };
    }
    setFields({ ...cloneFields });
    const searchObj = {
      search: searchData,
      customer: cloneFields?.customer,
      event_id: cloneFields?.event_id,
      brand_id: cloneFields?.brand_id,
      brand_tier: cloneFields?.brand_tier,
      id: cloneFields?.id,
      is_customer: cloneFields?.is_customer,
      page_no,
      no_of_records,
    };
    dispatch(getZone(searchObj));
  };

  return (
    <div>
      <div className="d-flex justify-content-between listing_upper_div">
        {generateHelmet("Gravit8", "Zone Management")}
        <div>
          <h4 className="fs-24">Zone Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Zone Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Link to="createnewzone">
            <Button className="rounded px-3 py-1 mt-2 mb-1">
              <span className="fw-bold"> + Create New Zone </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="bg-white px-2 listing_lower_div">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-75 mt-3">
            <Input
              size="medium"
              placeholder="Search by Customer Name, Event Name, Brand Name, Created By, Zone Name"
              style={{ width: "70%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
            />
          </div>
          <div className="d-flex mt-3">
            <Button
              className=" rounded"
              onClick={() => setShowFilter(!showFilter)}
            >
              <Filter />
              <span className="ms-2"> FILTER</span> <DownOutlined />
            </Button>
          </div>
        </div>
        <div className="mb-2">
          {showFilter ? (
            <div>
              <Select
                name="customer"
                placeholder="Customer Name"
                style={{
                  width: 220,
                }}
                onChange={(val) => handleFieldsChange(val, "customer")}
                allowClear
                showSearch
                listHeight={120}
                listItemHeight={4}
                optionFilterProp="children"
                value={fields?.customer}
              >
                {customerList?.map((data) => {
                  return (
                    <Option value={data.customer_id}>
                      {data.account_name}
                    </Option>
                  );
                })}
              </Select>
              <Select
                name="event_id"
                placeholder="Event Name"
                className="ms-2"
                style={{
                  width: 220,
                }}
                onChange={(val) => handleFieldsChange(val, "event_id")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.event_id}
              >
                {eventList &&
                  eventList?.map((data) => {
                    return (
                      <Option value={data.event_id}>{data.event_name}</Option>
                    );
                  })}
              </Select>
              <Select
                name="brand_id"
                placeholder="Brand Name"
                className="ms-2"
                style={{
                  width: 220,
                }}
                onChange={(val) => handleFieldsChange(val, "brand_id")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.brand_id}
              >
                {brandList &&
                  brandList?.map((data) => {
                    return (
                      <Option value={data.brand_id}>{data.brand_title}</Option>
                    );
                  })}
              </Select>
              <Select
                name="creatorName"
                placeholder="Creator Name"
                className="ms-2"
                style={{
                  width: 220,
                }}
                value={fields?.full_name ?? null}
                onChange={(id) => {
                  if (id) handleFieldsChange(JSON.parse(id), "id");
                  else handleFieldsChange(null, "id");
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
              >
                {creatorList &&
                  creatorList?.map((data) => {
                    return (
                      <Option value={JSON.stringify(data)}>
                        {data.full_name}
                      </Option>
                    );
                  })}
              </Select>
              <Select
                name="brand_tier"
                placeholder="Tier"
                className="ms-2"
                onChange={(val) => handleFieldsChange(val, "brand_tier")}
                style={{
                  width: 220,
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.brand_tier}
              >
                {tierList &&
                  tierList?.map((data) => {
                    return <Option value={data.tier_id}>{data.tier}</Option>;
                  })}
              </Select>
            </div>
          ) : null}
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            showSizeChanger: true,
            pageSize: no_of_records,
            total: zoneSelector?.count ? zoneSelector.count : 1,
          }}
          loading={{
            indicator: <CommonLoader />,
            spinning: loading,
          }}
          scroll={{
            x: 1300,
            y: "58vh",
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default ZoneManagement;
