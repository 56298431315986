import React, { useState, useRef, useEffect } from "react";
import {
  VideoCameraOutlined,
  SendOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Input } from "antd";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { User } from "../../components/svgJS/User";
import "./messagesui.css";
import { Picker } from "emoji-mart";
import { VideoAttach } from "../../components/svgJS/VideoAttach";
import { ImageAttach } from "../../components/svgJS/ImageAttach";
import { AudioAttach } from "../../components/svgJS/AudioAttach";
import { FileAttach } from "../../components/svgJS/FileAttach";
import { EditChat } from "../../components/svgJS/EditChat";
import { BinMessages } from "../../components/svgJS/BinMessages";
import { cloneDeep } from "lodash";
import { createTZString } from "../../utils/commonFunctions";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { ThreadChat } from "../../components/svgJS/ThreadChat";
import { CloseButton } from "react-bootstrap";
import { Close } from "@mui/icons-material";

const MessageThread = (props) => {
  let {
    userChatHeaderData,
    messagesData,
    socket,
    userId,
    showThread,
    closeThreadPopup,
  } = props;
  console.log("socket", socket);
  console.log("showThread", showThread);

  const [allThreadMessages, setAllThreadMessages] = useState(
    showThread?.thread_messages
  );

  const [messageThreadText, setMessageThreadText] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const exclude = ["search", "recent"];
  const [videoFileUpload, setVideoFileUpload] = useState(null);
  const [selectedEditThreadMessageData, setSelecteEditThreadMessageData] =
    useState(null);

  const sendMessageThreadRef = useRef(null);
  const messagesThreadEndRef = useRef(null);

  const videoAttachRef = useRef(null);
  const imageAttachRef = useRef(null);
  const fileAttachRef = useRef(null);
  const audioAttachRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, []);

  useEffect(() => {
    const sendThreadMessageListener = (obj) => {
      console.log("sendThreadMessageListener", obj);
      let latestMessage = obj?.data;
      console.log(
        showThread?.thread_id,
        latestMessage?.thread_id,
        "compare link ids"
      );

      let checkIfThreadExist = showThread?.thread_id;
      let updateThreadChat = false;

      if (checkIfThreadExist) {
        if (showThread?.thread_id === latestMessage?.thread_id) {
          updateThreadChat = true;
        }
      } else {
        if (showThread?.conversation_id === obj?.conversation_id) {
          updateThreadChat = true;
        }
      }

      if (updateThreadChat) {
        let new_date_time = createTZString(obj?.created_at);

        setAllThreadMessages((previousData) => {
          let newMessages = cloneDeep(previousData ?? []);
          console.log("newMessages", newMessages);
          newMessages?.push({
            ...latestMessage,
            time: new_date_time.format("HH:mm A"),
          });
          return newMessages;
        });
      }

      setTimeout(() => {
        scrollToBottom();
      }, 500);
    };

    const sendThreadMediaMessageListener = (obj) => {
      console.log("sendThreadMediaMessageListener", obj);
      let latestMessage = obj;
      console.log(
        showThread?.thread_id,
        latestMessage?.thread_id,
        "compare link ids"
      );

      let checkIfThreadExist = showThread?.thread_id;
      let updateThreadChat = false;

      if (checkIfThreadExist) {
        if (showThread?.thread_id === latestMessage?.thread_id) {
          updateThreadChat = true;
        }
      } else {
        if (showThread?.conversation_id === obj?.conversation_id) {
          updateThreadChat = true;
        }
      }

      if (updateThreadChat) {
        let new_date_time = createTZString(obj?.created_at);

        setAllThreadMessages((previousData) => {
          let newMessages = cloneDeep(previousData ?? []);
          console.log("newMessages", newMessages);
          newMessages?.push({
            ...latestMessage,
            time: new_date_time.format("HH:mm A"),
          });
          return newMessages;
        });
      }

      setTimeout(() => {
        scrollToBottom();
      }, 500);
    };

    const updateThreadMessageListener = (obj) => {
      console.log("updateThreadMessageListener", obj);

      if (obj?.link_id === showThread?.link_id) {
        setAllThreadMessages((prevData) => {
          let newArr = prevData?.map((elem) => {
            if (elem?.thread_message_id === obj?.thread_message_id) {
              return {
                ...elem,
                message_text: obj?.message_text,
              };
            }
            return elem;
          });

          return newArr;
        });
      }
    };

    const deleteThreadMessageListener = (obj) => {
      console.log("deleteThreadMessageListener", obj);
      if (obj?.thread_id === showThread?.thread_id) {
        setAllThreadMessages((prevData) => {
          let newArr = prevData?.map((elem) => {
            if (elem?.thread_message_id === obj?.thread_message_id) {
              return {
                ...elem,
                is_deleted: true,
              };
            }
            return elem;
          });

          return newArr;
        });
      }
    };

    const deleteThreadMediaMessageListener = (obj) => {
      console.log("deleteThreadMediaMessageListener", obj);
      if (obj?.thread_id === showThread?.thread_id) {
        setAllThreadMessages((prevData) => {
          let newArr = prevData?.map((elem) => {
            if (elem?.thread_message_id === obj?.thread_message_id) {
              return {
                ...elem,
                is_deleted: true,
              };
            }
            return elem;
          });

          return newArr;
        });
      }
    };

    socket?.on("sendThreadMessageListener", sendThreadMessageListener);
    socket?.on(
      "sendThreadMediaMessageListener",
      sendThreadMediaMessageListener
    );

    socket?.on("updateThreadMessageListener", updateThreadMessageListener);
    socket?.on("deleteThreadMessageListener", deleteThreadMessageListener);
    socket?.on(
      "deleteThreadMediaMessageListener",
      deleteThreadMediaMessageListener
    );

    return () => {
      socket?.off("sendThreadMessageListener", sendThreadMessageListener);
    };
  }, [socket]);

  const handleAddThreadMessage = () => {
    if (messageThreadText) {
      if (selectedEditThreadMessageData?.thread_id) {
        //If existing message is being edited
        editMessageThreadHandler();
      } else {
        let objToSend = {
          thread_id: showThread?.thread_id,
          sender_id:
            showThread?.chatAlign === "right"
              ? showThread?.sender_id
              : showThread?.receiver_id,
          receiver_id:
            showThread?.chatAlign === "left"
              ? showThread?.receiver_id
              : showThread?.sender_id,
          conversation_id: showThread?.conversation_id,
          message_text: messageThreadText,
          message_media_type: "Text",
        };
        console.log("objToSend", objToSend);

        let messageSocket = socket.emit(
          "sendThreadMessage",
          JSON.stringify(objToSend)
        );
      }

      setMessageThreadText("");
    }

    scrollToBottom();
  };

  const sendThreadMediaAPI = async (fileType, file) => {
    let dataToSend = {
      thread_id: showThread?.thread_id,
      sender_id: showThread?.sender_id,
      receiver_id: showThread?.receiver_id,
      message_media_type: fileType,
      conversation_id: showThread?.conversation_id,
    };

    console.log("dataToSend", dataToSend);
    let formData = new FormData();
    formData.append("data", JSON.stringify(dataToSend));
    formData.append("files", file);

    const response = await ApiCall(
      "POST",
      formData,
      "/admin/threadconversation/media/create",
      null
    );

    console.log("sendThreadMediaAPI response", sendThreadMediaAPI);

    if (response?.data?.code === 200) {
      // let latestMessage = response?.data?.data;
      // let new_date_time = createTZString(response?.data?.data?.created_at);
      // setAllThreadMessages((previousData) => {
      //   let newMessages = cloneDeep(previousData);
      //   newMessages.push({
      //     ...latestMessage,
      //     time: new_date_time.format("HH:mm A"),
      //   });
      //   return newMessages;
      // });
    } else {
      toast.error(response?.data?.message);
    }
    scrollToBottom();
  };

  const renderOtherMessageUI = (item) => {
    console.log(item?.sender_id, userId, "check idssss");

    if (item?.sender_id === userId) {
      return (
        <>
          <div className="d-flex flex-column">
            <div className="message_container mess_right_side_div">
              <div className="mess_edit_bin_icons_container">
                {item?.message_media_type === "Text" ? (
                  <div
                    onClick={() => {
                      setMessageThreadText(item?.message_text);
                      setSelecteEditThreadMessageData(item);
                    }}
                  >
                    <EditChat />
                  </div>
                ) : null}

                <div
                  onClick={() => {
                    deleteMessageThreadHandler(item);
                  }}
                >
                  <BinMessages />
                </div>
              </div>

              {!item?.is_deleted ? (
                <>
                  {item?.message_media_type === "Text" ? (
                    <div className={"mess_container_self"}>
                      {item?.message_text}
                    </div>
                  ) : item?.message_media_type === "Image" ? (
                    <div className={""}>
                      <img
                        width={"200"}
                        style={{
                          objectFit: "contain",
                        }}
                        src={item?.message_media_url}
                      />
                    </div>
                  ) : item?.message_media_type === "Video" ? (
                    <div className={""}>
                      <video
                        loop={true}
                        src={item?.message_media_url}
                        width="300px"
                        controls="true"
                        autoPlay={true}
                      />

                      {/* {item?.message_text} */}
                    </div>
                  ) : item?.message_media_type === "Audio" ? (
                    <div className={""}>
                      <audio controls src={item?.message_media_url}></audio>
                    </div>
                  ) : item?.message_media_type === "File" ? (
                    <div className={"mess_container_self"}>
                      <a
                        href={item?.message_media_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="message__file"
                      >
                        <i class="css-12q6dla"></i>
                        <p
                          style={{
                            color: "white",
                          }}
                        >
                          File
                        </p>
                      </a>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className={"mess_chat_deleted"}>
                  ⚠️
                  {" You deleted this message"}
                </div>
              )}

              {/* 
              <video
                height="100%"
                loop={true}
                autoPlay={true}
                controls={true}
                width="100%"
                src="https://brainvire-grv8-staging-static.s3.eu-west-2.amazonaws.com/staging/Event/177/2308673d-d18b-431d-a4bd-6ae02091a3ae/3225401850.mp4"
              /> */}
            </div>
            <div className="right_sided_message_div mess_time_section">
              {item?.time}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="message_container left_sided_message_div">
            <div className="d-flex flex-column">
              <div className="">
                <div className="d-flex"></div>
                <div className="justify-content-between">
                  <span className="">
                    <div className="mess_edit_bin_icons_container">
                      <EditChat />
                      <BinMessages />
                    </div>

                    {!item?.is_deleted ? (
                      <>
                        {item?.message_media_type === "Text" ? (
                          <div
                            style={{
                              backgroundColor: "#f6f6f6",
                            }}
                            className={"mess_container_other"}
                          >
                            {item?.message_text}
                          </div>
                        ) : item?.message_media_type === "Image" ? (
                          <div className={""}>
                            <img
                              width={"200"}
                              style={{
                                objectFit: "contain",
                              }}
                              src={item?.message_media_url}
                            />
                          </div>
                        ) : item?.message_media_type === "Video" ? (
                          <div className={""}>
                            <video
                              loop={true}
                              src={item?.message_media_url}
                              width="300px"
                              controls="true"
                              autoPlay={true}
                            />

                            {/* {item?.message_text} */}
                          </div>
                        ) : item?.message_media_type === "Audio" ? (
                          <div className={""}>
                            <audio
                              controls
                              src={item?.message_media_url}
                            ></audio>
                          </div>
                        ) : item?.message_media_type === "File" ? (
                          <div className={"mess_container_self"}>
                            <a
                              href={item?.message_media_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              class="message__file"
                            >
                              <i class="css-12q6dla"></i>
                              <p
                                style={{
                                  color: "white",
                                }}
                              >
                                File
                              </p>
                            </a>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div className={"mess_chat_deleted"}>
                        ⚠️
                        {" This message was deleted"}
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="time_section mt-1">{item?.time}</div>
            </div>
          </div>
        </>
      );
    }
  };

  const renderInitialThreadMessageUI = (item) => {
    return (
      <>
        <div className="message_container left_sided_message_div">
          <div className="d-flex flex-column">
            <div className="">
              <div className="d-flex"></div>
              <div className="justify-content-between">
                <span className="">
                  {/* <div className="mess_edit_bin_icons_container">
                      <EditChat />
                      <BinMessages />
                    </div> */}
                  {item?.message_media_type === "Text" ? (
                    <div
                      style={{
                        backgroundColor: "#f6f6f6",
                      }}
                      className={"mess_container_other"}
                    >
                      {item?.message_text}
                    </div>
                  ) : item?.message_media_type === "Image" ? (
                    <div className={""}>
                      <img
                        width={"200"}
                        style={{
                          objectFit: "contain",
                        }}
                        src={item?.message_media_url}
                      />
                    </div>
                  ) : item?.message_media_type === "Video" ? (
                    <div className={""}>
                      <video
                        loop={true}
                        src={item?.message_media_url}
                        width="300px"
                        controls="true"
                        autoPlay={true}
                      />

                      {/* {item?.message_text} */}
                    </div>
                  ) : item?.message_media_type === "Audio" ? (
                    <div className={""}>
                      <audio controls src={item?.message_media_url}></audio>
                    </div>
                  ) : item?.message_media_type === "File" ? (
                    <div className={"mess_container_self"}>
                      <a
                        href={item?.message_media_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="message__file"
                      >
                        <i class="css-12q6dla"></i>
                        <p
                          style={{
                            color: "white",
                          }}
                        >
                          File
                        </p>
                      </a>
                    </div>
                  ) : null}
                </span>
              </div>
            </div>
            <div className="time_section mt-1">{item?.time}</div>
          </div>
        </div>
      </>
    );
  };

  const deleteMessageThreadHandler = async (body) => {
    console.log("body", body);
    if (body?.message_media_type === "Text")
      socket?.emit("deleteThreadMessage", JSON.stringify(body));
    else {
      let testSocket = socket?.emit(
        "deleteThreadMediaMessage",
        JSON.stringify(body)
      );
      console.log("testSocket", testSocket);
    }
  };

  const editMessageThreadHandler = async () => {
    let modifiedSelectedEditMessageData = {
      ...selectedEditThreadMessageData,
      message_text: messageThreadText,
    };
    socket?.emit(
      "updateThreadMessage",
      JSON.stringify(modifiedSelectedEditMessageData)
    );
    setSelecteEditThreadMessageData(null);
    setMessageThreadText("");
  };

  const scrollToBottom = () => {
    messagesThreadEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  console.log("message text", messageThreadText);

  console.log("selectedEditThreadMessageData", selectedEditThreadMessageData);

  console.log("allThread Message", allThreadMessages);
  return (
    <div className="mess_chat_thread_container">
      <div className="mess_thread_chat_header">
        <div className="mess_chat_header_left">
          <Avatar src={userChatHeaderData?.img} />
          <div className="mess_chat_user_name_container">
            <span className="mess_chat_user_name">Thread</span>
            <span>{userChatHeaderData?.userName}</span>
          </div>
        </div>
        <div className="mess_chat_header_right">
          <Close
            className="cursor-pointer"
            onClick={() => {
              closeThreadPopup();
            }}
          />
        </div>
      </div>
      <div className="mess_chat_messages">
        <div className={"mess_area"}>
          {renderInitialThreadMessageUI(showThread?.initialThreadMessage)}
          {allThreadMessages?.map((item) => {
            return (
              <div key={"message_" + item?.conversation_id}>
                {renderOtherMessageUI(item)}
              </div>
            );
          })}

          <div ref={messagesThreadEndRef} />
          {/* Scroll at bottom of messages */}

          {/* {allThreadMessages === [] || isEmpty(allThreadMessages) ? (
          <div className="empty_chat">
            <Empty
              description={<span className="fs-pink">No messages found</span>}
            />
          </div>
        ) : null} */}

          {showEmojiPicker ? (
            <Picker
              title={""}
              emoji="point_up"
              native
              onClick={(emoji) => {
                console.log("emoji", emoji);
                setMessageThreadText((current) => current + emoji.native);
              }}
              showPreview={false}
              exclude={exclude}
              //   i18n={{ categories: this.state.categories }}

              style={{
                bottom: "2px",
                position: "absolute",
                overflow: "hidden",
                zIndex: "2",
                width: "100%",
                height: "353px",
              }}
            />
          ) : null}
        </div>

        <div className="mess_chat_bottom_section">
          {selectedEditThreadMessageData?.thread_id ? (
            <div className="mess_edit_chat_container">
              <div className="d-flex flex-column">
                <span>Edit message</span>
                <span>{selectedEditThreadMessageData?.message_text}</span>
              </div>
              <div>
                <div
                  onClick={() => {
                    setSelecteEditThreadMessageData(null);
                    setMessageThreadText(null);
                  }}
                >
                  <Close />
                </div>
              </div>
            </div>
          ) : null}

          <Input
            placeholder="Enter your message here"
            autoFocus
            className="mess_chat_input_bottom_section"
            value={messageThreadText}
            onChange={(e) => {
              setMessageThreadText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddThreadMessage();
              }
            }}
            suffix={
              <span className="send_btn">
                <SendOutlined
                  ref={sendMessageThreadRef}
                  className="iconnn"
                  onClick={() => handleAddThreadMessage()}
                />
              </span>
            }
          />
          <div className="mess_chat_bottom_attachment_container">
            <div className="mess_chat_bottom_attachment_left_container">
              <div
                className="mess_attach_video"
                onClick={() => {
                  videoAttachRef.current.click();
                }}
              >
                <VideoAttach />
                <input
                  onChange={(e) => {
                    sendThreadMediaAPI("Video", e.target.files[0]);
                  }}
                  className="mess_attach_inputs"
                  accept="video/*"
                  type="file"
                  ref={videoAttachRef}
                />
              </div>
              <div
                className="mess_attach_video"
                onClick={() => {
                  // videoAttachRef.current.click();
                  imageAttachRef.current.click();
                }}
              >
                <ImageAttach />
                <input
                  onChange={(e) => {
                    sendThreadMediaAPI("Image", e.target.files[0]);
                  }}
                  className="mess_attach_inputs"
                  accept="image/*"
                  type="file"
                  ref={imageAttachRef}
                />
              </div>
              <div
                className="mess_attach_video"
                onClick={() => {
                  audioAttachRef.current.click();
                }}
              >
                <AudioAttach />
                <input
                  onChange={(e) => {
                    // setVideoFileUpload(e);
                    sendThreadMediaAPI("Audio", e.target.files[0]);
                  }}
                  className="mess_attach_inputs"
                  accept="audio/*"
                  type="file"
                  ref={audioAttachRef}
                />
              </div>
              <div
                className="mess_attach_video"
                onClick={() => {
                  fileAttachRef.current.click();
                }}
              >
                <FileAttach />
                <input
                  onChange={(e) => {
                    // setVideoFileUpload(e);
                    sendThreadMediaAPI("File", e.target.files[0]);
                  }}
                  className="mess_attach_inputs"
                  type="file"
                  ref={fileAttachRef}
                />
              </div>
            </div>
            <div className="mess_chat_bottom_attachment_right_container">
              <SmileOutlined
                onClick={() => {
                  setShowEmojiPicker((current) => !current);
                }}
                style={{
                  fontSize: "18px",
                  marginRight: "10px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageThread;
