import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useCallback } from "react";
import { useEffect } from "react";
import Cropper from "react-easy-crop";
import "./CropImageModal.css";
import uuid from "react-uuid";

const CropImageModal = ({
  imageToBeCropped,
  cropSizeData,
  sendCroppedImageFile,
  closeCropModalOnCancel,
}) => {
  const [logoImageModal, setLogoImageModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [croppedArea, setCroppedArea] = useState();

  useEffect(() => {
    setLogoImageModal(true);
  }, []);

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setCroppedArea(croppedArea);
  }, []);

  const getCroppedImg = (imageSrc, pixelCrop) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        ctx.drawImage(
          img,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
        resolve(canvas);
      };
      img.onerror = reject;
    });
  };

  const handleCrop = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageToBeCropped,
        croppedAreaPixels
      );
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = croppedImage.width;
      canvas.height = croppedImage.height;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(croppedImage, 0, 0);
      canvas.toBlob(
        (blob) => {
          // Use the blob as needed
          let file = new File([blob], `${uuid()}.jpg`, { type: "image/jpeg" });

          sendCroppedImageFile(file);
        },
        "image/jpeg",
        1
      );
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageToBeCropped]);

  return (
    <div>
      <Modal
        wrapClassName="logoImageModalWrapper"
        className="logoImageModal"
        visible={logoImageModal}
        onOk={() => {
          setLogoImageModal(false);
        }}
        onCancel={() => {
          setLogoImageModal(false);
          closeCropModalOnCancel();
          setZoom(1);
        }}
        footer={[
          <Button
            onClick={() => {
              handleCrop();
              setLogoImageModal(false);
              setZoom(1);
            }}
          >
            Save
          </Button>,
        ]}
      >
        <Cropper
          image={imageToBeCropped}
          // image="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
          crop={crop}
          cropSize={{
            width: cropSizeData?.width,
            height: cropSizeData?.height,
          }}
          // zoomWithScroll={false}
          onWheelRequest={false}
          restrictPosition={false}
          zoom={zoom}
          minZoom={0.1}
          maxZoom={10}
          // zoomWithScroll={true}
          // aspect={4 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
        <div className="logoImageZoomControllerContainer">
          <input
            type="range"
            value={zoom}
            step={0.1}
            min={0.1}
            max={10}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />
        </div>
      </Modal>
    </div>
  );
};

export default CropImageModal;
