import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

export const getBrandCalendar = createAsyncThunk(
  "brandCalendar/getBrandCalendar",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/brandcalendar/details/get",
      params
    );

    return response;
  }
);
