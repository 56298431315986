import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./productZoneDemo.css";
import "./../EventCalender/eventCalender.css";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  Switch,
  Empty,
  Table,
  TimePicker,
  Select,
  DatePicker,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { blackbin } from "../../components/Images/Images";
import FileUpload from "../common/FileUpload";
import {
  getZoneDemoDetails,
  updateZoneDemoDetails,
  createZoneDemo,
  getInvitationDetails,
} from "../../redux/slice/productZoneChat";
import {
  getJobTitles,
  getCountryList,
  getZoneDropdowns,
  getBrandDropdowns,
  getUserTypeAgents,
  getEventDetails,
} from "../../utils/commonApis";
import _, { cloneDeep, orderBy } from "lodash";
import RedAsterick from "../../components/Widgets/RedAsterick";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  createISOTimeString,
  createTZString,
  getDisabledTime,
  mapSelectedDdlData,
  sortData,
} from "../../utils/commonFunctions";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
import { dialogs } from "../../components/constsnts/string.constants";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";
import { getMinutesDiff } from "../EventCalender/eventCalender.utils";

const ProductZoneDemo = (props) => {
  let currentDate = moment().format("YYYY/MM/DD HH:mm");
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [agentsList, setAgentsList] = useState([]);
  const [taggedAgents, setTaggedAgents] = useState([]);
  const [whiteListUsers, setWhiteListUsers] = useState([]);
  const [blackListUsers, setBlackListUsers] = useState([]);
  const [whiteListBrands, setWhiteListBrands] = useState([]);
  const [blackListBrands, setBlackListBrands] = useState([]);
  const [whiteListCountries, setWhiteListCountries] = useState([]);
  const [blackListCountries, setBlackListCountries] = useState([]);
  const [whiteListJobTitles, setWhiteListJobTitles] = useState([]);
  const [blackListJobTitles, setBlackListJobTitles] = useState([]);
  const [invited_brands, set_invited_brands] = useState([]);
  const [invited_zones, set_invited_zones] = useState([]);
  const [invited_user_types, set_invited_user_types] = useState([]);
  const [invited_countries, set_invited_countries] = useState([]);
  const [invited_job_titles, set_invited_job_titles] = useState([]);
  const [previous_pending_invites, set_previous_pending_invites] = useState([]);
  const invitation_modal_default = {
    isOpen: false,
    data: null,
    isLoader: false,
  };
  const [invitationModal, setInvitationModal] = useState(
    invitation_modal_default
  );
  const [invitationTableData, setInvitationTableData] = useState([]);
  const [delete_scheduled_invitations, set_delete_scheduled_invitations] =
    useState([]);
  const [usersCollection, setUsersCollection] = useState([]);
  const [brandsCollection, setBrandsCollection] = useState([]);
  const [countryCollection, setCountryCollection] = useState([]);
  const [jobTitleCollection, setJobTitleCollection] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);
  const [openAssignStaffList, setOpenAssignStaffList] = useState(false);
  const [openUserList, setOpenUsersList] = useState(false);
  const [openBrandsList, setOpenBrandsList] = useState(false);
  const [openCountriesList, setOpenCountriesList] = useState(false);
  const [openJobTitlesList, setOpenJobTitlesList] = useState(false);

  let initialStartDateTime =
    props?.isFromCalender === "zone"
      ? moment(
          moment(props?.data?.appointmentData?.startDate)?.format("HH:mm"),
          TIME_FORMAT
        )
      : props?.data?.startTime //From Brand Calendar
      ? moment(props?.data?.startTime.format("HH:mm"), TIME_FORMAT)
      : null;

  let initialEndDateTime =
    props?.isFromCalender === "zone"
      ? moment(
          moment(props?.data?.appointmentData?.endDate).format("HH:mm"),
          TIME_FORMAT
        )
      : props?.data?.endTime //From Brand Calendar
      ? moment(props?.data?.endTime.format("HH:mm"), TIME_FORMAT)
      : null;

  const [fields, setFields] = useState({
    start_date_time: initialStartDateTime,
    end_date_time: initialEndDateTime,
    session_title: "",
    session_description: "",
    is_ical_download_enabled: false,
    invitation_title: "",
    invitation_description: "",
    is_iCal_download_enabled: false,
    is_reinvite_enabled: false,
    send_invitation_now: false,
    date_time_to_invite: undefined,
    schedule_now: false,
  });
  const [productZoneDemoFileUpload, setProductZoneDemoFileUpload] =
    useState("");
  const [allUserList, setAllUserList] = useState([]);
  const [allBrandsList, setAllBrandsList] = useState([]);
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [allJobTitleList, setAllJobTitleList] = useState([]);

  const [inviteesToggle, setInviteesToggle] = useState(false);
  const [existingStartEndTime, setExistingStartEndTime] = useState({
    startTime: "",
    endTime: "",
  });

  useEffect(async () => {
    setLoading(true);
    if (props.data?.configuration_id)
      await getProductZoneDemoData(props.data.configuration_id);
    else {
      await populateListsData();
      setProductZoneDemoFileUpload("");
    }
    setLoading(false);
  }, [props]);

  const handleInviteesTableChange = (pagination, filters, sorter) => {
    if (sorter) {
      const sortedData = sortData(sorter, invitationTableData);
      setInvitationTableData(sortedData);
    }
  };

  const invitation_table_columns = [
    {
      title: "Date/Time",
      key: "date_time_to_invite",
      dataIndex: "date_time_to_invite",
      width: "25%",
      align: "center",
      sorter: (a, b) =>
        moment(a.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss") -
        moment(b.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss"),
      render: (text, record) => {
        return moment(record.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "User Name",
      key: "user_name",
      dataIndex: "user_name",
      width: "25%",
      align: "center",
      render: (_, record) => {
        return record.first_name + " " + record.last_name;
      },
    },
    {
      title: "User Type",
      key: "user_type",
      dataIndex: "user_type",
      width: "25%",
      align: "center",
      sorter: (a, b) => a.user_type.localeCompare(b.user_type),
    },
    {
      title: "Invite Status",
      key: "rsvp_type",
      dataIndex: "rsvp_type",
      width: "25%",
      align: "center",
      render: (_, record) => {
        return getStatusButton(record);
      },
      sorter: (a, b) => b.rsvp_type.localeCompare(a.rsvp_type),
    },
  ];

  const getStatusButton = (ele) => {
    if (ele.rsvp_type === "PENDING")
      return (
        <Button className="adminSatellite_section_2_pending">Pending</Button>
      );
    else if (ele.rsvp_type === "YES")
      return (
        <Button className="adminSatellite_section_2_accepted">Accepted</Button>
      );
    else if (ele.rsvp_type === "NO")
      return (
        <Button className="adminSatellite_section_2_reject">Rejected</Button>
      );
    else return null;
  };

  const populateListsData = async (responseData = null) => {
    let eventData = await getEventDetails({
      event_id: props.data.event_id,
    });
    let jobTitlesList = await getJobTitles({
      event_id: props.data.event_id,
    });

    let countriesList = await getCountryList({
      event_id: props.data.event_id,
    });

    if (eventData && eventData.registration_and_communication_details) {
      let countryFlag =
        eventData.registration_and_communication_details?.country_collection;
      if (countryFlag && countriesList) {
        let countryIds =
          eventData?.registration_and_communication_details
            ?.country_collection_ids;
        let result = countriesList.filter((o1) =>
          countryIds.some((o2) => o1?.country_id === o2)
        );
        setCountryCollection(result);
      } else setCountryCollection(countriesList);

      let jobFlag =
        eventData?.registration_and_communication_details?.job_title_collection;
      if (jobFlag && jobTitlesList) {
        let jobTitleIds =
          eventData?.registration_and_communication_details
            ?.job_title_collection_ids;
        let result2 = jobTitlesList.filter((o1) =>
          jobTitleIds.some((o2) => o1?.job_title_id === o2)
        );
        setJobTitleCollection(result2);
      } else setJobTitleCollection(jobTitlesList);
    } else {
      setCountryCollection(countriesList);
      setJobTitleCollection(jobTitlesList);
    }

    let brandsList = await getBrandDropdowns(props.data.event_id);
    let zonesList = await getZoneDropdowns(props.data.event_id);
    //white list
    let usersList = await getUserTypeAgents({
      event_id: props.data.event_id,
      is_zone_calendar: true,
    });
    setUsersCollection(usersList);

    //assign staff
    let agentsList = await getUserTypeAgents({
      brand_id: props.data.brand_id,
      is_zone_calendar: true,
    });

    setBrandsCollection(brandsList);
    setZoneCollection(zonesList);
    if (responseData) {
      mapAllAgents(responseData?.assign_staff_ids, taggedAgents, agentsList);
      let userListData = mapListData(
        usersList,
        "user_id",
        responseData?.restricted_users
      );

      setWhiteListUsers(orderBy(userListData.whiteList, "first_name", "asc"));
      setBlackListUsers(userListData.blackList);

      setAllUserList(userListData.whiteList.concat(userListData.blackList));

      let brandListData = mapListData(
        brandsList,
        "brand_id",
        responseData?.restricted_brands
      );
      setBlackListBrands(brandListData.blackList);
      setBrandsCollection(brandListData.whiteList);

      setWhiteListBrands(
        orderBy(brandListData.whiteList, "brand_title", "asc")
      );

      setAllBrandsList(brandListData.blackList.concat(brandListData.whiteList));

      let countriesListData = mapListData(
        countriesList,
        "country_id",
        responseData?.restricted_countries
      );
      setBlackListCountries(countriesListData.blackList);
      setCountryCollection(countriesListData.whiteList);
      setWhiteListCountries(
        orderBy(countriesListData.whiteList, "country", "asc")
      );

      setAllCountriesList(
        countriesListData.blackList.concat(countriesListData.whiteList)
      );

      let jobTitlesListData = mapListData(
        jobTitlesList,
        "job_title_id",
        responseData?.restricted_job_titles
      );
      setBlackListJobTitles(jobTitlesListData.blackList);
      setJobTitleCollection(jobTitlesListData.whiteList);

      setWhiteListJobTitles(
        orderBy(jobTitlesListData.whiteList, "job_title", "asc")
      );

      setAllJobTitleList(
        jobTitlesListData.blackList.concat(jobTitlesListData.whiteList)
      );
    } else {
      mapAllAgents([], taggedAgents, agentsList);
      setWhiteListUsers(orderBy(usersList, "first_name", "asc"));
      setAllUserList(orderBy(usersList, "first_name", "asc")); //setAll users, brands, countries, jobtitle list is for Allow all and block all functionality

      setWhiteListBrands(orderBy(brandsList, "brand_title", "asc"));
      setAllBrandsList(orderBy(brandsList, "brand_title", "asc"));

      setWhiteListCountries(orderBy(countriesList, "country", "asc"));
      setAllCountriesList(orderBy(countriesList, "country", "asc"));

      setWhiteListJobTitles(orderBy(jobTitlesList, "job_title", "asc"));
      setAllJobTitleList(orderBy(jobTitlesList, "job_title", "asc"));
    }
  };

  async function mapAllAgents(staff_ids_arr, taggedAgents, agentsList) {
    await mapSelectedDdlData(staff_ids_arr, taggedAgents, agentsList, "user_id")
      .then((dataToMap) => {
        if (dataToMap && dataToMap.mainList) setAgentsList(dataToMap.mainList);
        if (dataToMap && dataToMap.selectedList)
          setTaggedAgents(dataToMap.selectedList);
      })
      .catch((err) => {});
  }

  const getProductZoneDemoData = async (configuration_id) => {
    const response = await dispatch(getZoneDemoDetails({ configuration_id }));

    if (response.payload.data.data) {
      let responseData = response.payload.data.data;
      await populateListsData(response.payload.data.data);
      let modifiedResponse = fields;

      modifiedResponse.session_title =
        responseData.session_details.session_title;
      modifiedResponse.session_description =
        responseData.session_details.session_description;
      const start_date_time = createTZString(
        responseData.session_details?.start_date_time
      );
      const end_date_time = createTZString(
        responseData.session_details?.end_date_time
      );

      setExistingStartEndTime({
        startTime: moment(
          start_date_time?.format("HH:mm"),
          TIME_FORMAT
        )?.format("HH:mm"),
        endTime: moment(end_date_time?.format("HH:mm"), TIME_FORMAT)?.format(
          "HH:mm"
        ),
      });

      modifiedResponse.start_date_time = moment(
        start_date_time.format("HH:mm"),
        TIME_FORMAT
      );
      modifiedResponse.end_date_time = moment(
        end_date_time.format("HH:mm"),
        TIME_FORMAT
      );
      modifiedResponse.is_ical_download_enabled =
        responseData.session_details.is_ical_download_enabled;

      if (modifiedResponse.date_time_to_invite) {
        const start_date_str = moment(
          modifiedResponse.date_time_to_invite
        ).format("YYYY/MM/DD HH:mm");
        const start_utc = createISOTimeString(start_date_str);
        modifiedResponse.date_time_to_invite = start_utc;
      }

      setFields({ ...modifiedResponse });
      set_previous_pending_invites(responseData.previous_pending_invites);
    }
  };

  const mapListData = (list, key, valuesList) => {
    let blackList = [],
      whiteList = [];
    list.forEach((x) => {
      if (!blackList.includes(x[key] || !whiteList.includes(x[key]))) {
        if (valuesList.includes(x[key])) blackList.push(x);
        else whiteList.push(x);
      }
    });
    return { blackList, whiteList };
  };

  const checkIfInviationIsSelected = () => {
    if (inviteesToggle) {
      //If Invitation toggle is off then no need to check for invitations validations
      if (fields?.send_invitation_now || fields?.schedule_now) return true;
      else return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    if (validator.current.allValid()) {
      const flag = checkIfInviationIsSelected();
      if (flag) {
        if (moment(fields.start_date_time) > moment(fields.end_date_time))
          toast.error("Please select correct time");
        else if (
          getMinutesDiff(
            moment(fields.start_date_time),
            moment(fields.end_date_time)
          ) < 10
        )
          toast.error("Please select atleast 10 mins duration");
        else {
          setLoading(true);
          const start_date_str =
            props.data.currentDateForCalender +
            " " +
            moment(fields.start_date_time).format("HH:mm:ss");

          const start_utc = createISOTimeString(start_date_str);
          const end_date_str =
            props.data.currentDateForCalender +
            " " +
            moment(fields.end_date_time).format("HH:mm:ss");
          const end_utc = createISOTimeString(end_date_str);
          let isTimeModified = false;

          if (props?.data?.configuration_id) {
            let newStartTime = moment(fields?.start_date_time).format("HH:mm");
            let newEndTime = moment(fields?.end_date_time).format("HH:mm");

            if (
              existingStartEndTime?.startTime !== newStartTime ||
              existingStartEndTime?.endTime !== newEndTime
            ) {
              isTimeModified = true;
            }
          }

          let data = {
            session_details: {
              brand_id: props.data.brand_id,
              zone_id: props.data.zone_id,
              start_date_time: start_utc,
              end_date_time: end_utc,
              session_title: fields.session_title,
              session_description: fields.session_description,
              is_ical_download_enabled: fields.is_ical_download_enabled,
            },
            assign_staff_ids: _.map(taggedAgents, "user_id"),
            restricted_users: _.map(blackListUsers, "user_id"),
            restricted_brands: _.map(blackListBrands, "brand_id"),
            restricted_countries: _.map(blackListCountries, "country_id"),
            restricted_job_titles: _.map(blackListJobTitles, "job_title_id"),
            invitation_setup: {
              invitation_title: fields.invitation_title,
              invitation_description: fields.invitation_description,
              is_iCal_download_enabled: fields.is_iCal_download_enabled,
              is_reinvite_enabled: fields.is_reinvite_enabled,
              send_invitation_now: !fields.send_invitation_now,
            },
            invited_brands,
            invited_zones,
            invited_user_types,
            invited_countries,
            invited_job_titles,
            allow_invitation: inviteesToggle,
            is_time_modified: isTimeModified,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            current_timestamp: moment().format("YYYY-MM-DD"),
          };
          if (fields.send_invitation_now)
            data.invitation_setup.date_time_to_invite =
              fields.date_time_to_invite;
          if (props.data.configuration_id)
            data["configuration_id"] = props.data.configuration_id;

          let formData = new FormData();
          formData.append("data", JSON.stringify(data));
          formData.set("invitation_list", null);
          if (productZoneDemoFileUpload)
            formData.set("media", productZoneDemoFileUpload);
          else formData.set("media", null);
          await dispatch(
            data.configuration_id
              ? updateZoneDemoDetails(formData)
              : createZoneDemo(formData)
          );
          if (props.isFromCalender && props.isFromCalender === "zone") {
            props.data.getZoneCalenderData();
            props.data.visibleChange();
          } else props.onSave(true);

          fields.invitation_title = "";
          fields.invitation_description = "";
          fields.send_invitation_now = false;
          fields.schedule_now = false;
          setLoading(false);
        }
      } else toast.error("When to Send Invitation is mandatory");
    } else {
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
      setForceUpdate(!forceUpdate);
    }
  };

  const onCancel = () => {
    if (props.isFromCalender && props.isFromCalender === "zone")
      props.data.visibleChange();
    else props.onCancel();
  };

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  function getLeftRightDDLs(leftOrRight, leftDDL, rightDDL, indexToShift) {
    if (leftOrRight === "left") {
      rightDDL.push(leftDDL[indexToShift]);
      leftDDL.splice(indexToShift, 1);
    } else {
      leftDDL.unshift(rightDDL[indexToShift]);
      rightDDL.splice(indexToShift, 1);
    }
    return { leftDDL, rightDDL };
  }

  async function handleAllowAllBlockAll(key, type) {
    let leftDDL, rightDDL;

    if (key === "blackListUsers") {
      if (type === "blockAll") {
        rightDDL = cloneDeep(allUserList);
        leftDDL = [];
      }

      if (type === "allowAll") {
        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(allUserList);
      }
      setWhiteListUsers(leftDDL);
      setBlackListUsers(rightDDL);
    } else if (key === "blackListBrands") {
      if (type === "blockAll") {
        rightDDL = cloneDeep(allBrandsList);
        leftDDL = [];
      }

      if (type === "allowAll") {
        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(allBrandsList);
      }
      setWhiteListBrands(leftDDL);
      setBlackListBrands(rightDDL);
    } else if (key === "blackListCountries") {
      if (type === "blockAll") {
        rightDDL = cloneDeep(allCountriesList);
        leftDDL = [];
      }

      if (type === "allowAll") {
        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(allCountriesList);
      }
      setWhiteListCountries(leftDDL);
      setBlackListCountries(rightDDL);
    } else if (key === "blackListJobTitles") {
      if (type === "blockAll") {
        rightDDL = cloneDeep(allJobTitleList);
        leftDDL = [];
      }

      if (type === "allowAll") {
        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(allJobTitleList);
      }

      setWhiteListJobTitles(leftDDL);
      setBlackListJobTitles(rightDDL);
    }
  }

  async function handleDDLChange(value, type, leftOrRight) {
    let leftDDL, rightDDL, indexToShift;
    if (type === "taggedAgents") {
      leftDDL = cloneDeep(agentsList);
      rightDDL = cloneDeep(taggedAgents);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.user_id === value);
      else indexToShift = rightDDL.findIndex((ele) => ele.user_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );
      setAgentsList(finalRes.leftDDL);
      setTaggedAgents(finalRes.rightDDL);

      if (leftOrRight === "left") {
        //remove it from the white list dropdown
        const clonedWhiteListUsers = cloneDeep(whiteListUsers);
        const filteredWhiteListUsers = clonedWhiteListUsers.filter(
          (ele) => ele.user_id !== value
        );
        setWhiteListUsers(filteredWhiteListUsers);
      } else {
        //adding it to the white list dropdown
        const clonedUsers = cloneDeep(usersCollection);
        const filteredUsers = clonedUsers.filter(
          (ele) => ele.user_id === value
        );
        let clonedWhiteListUsers = cloneDeep(whiteListUsers);
        clonedWhiteListUsers = [...clonedWhiteListUsers, ...filteredUsers];
        setWhiteListUsers(clonedWhiteListUsers);
      }
    } else if (type === "blackListUsers") {
      leftDDL = cloneDeep(whiteListUsers);
      rightDDL = cloneDeep(blackListUsers);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.user_id === value);
      else indexToShift = rightDDL.findIndex((ele) => ele.user_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );

      setWhiteListUsers(orderBy(finalRes.leftDDL, "first_name", "asc"));

      setBlackListUsers(finalRes.rightDDL);
    } else if (type === "blackListBrands") {
      leftDDL = cloneDeep(whiteListBrands);
      rightDDL = cloneDeep(blackListBrands);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.brand_id === value);
      else indexToShift = rightDDL.findIndex((ele) => ele.brand_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );

      setWhiteListBrands(orderBy(finalRes.leftDDL, "brand_title", "asc"));
      setBlackListBrands(finalRes.rightDDL);
    } else if (type === "blackListCountries") {
      leftDDL = cloneDeep(whiteListCountries);
      rightDDL = cloneDeep(blackListCountries);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.country_id === value);
      else indexToShift = rightDDL.findIndex((ele) => ele.country_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );

      setWhiteListCountries(orderBy(finalRes.leftDDL, "country", "asc"));
      setBlackListCountries(finalRes.rightDDL);
    } else if (type === "blackListJobTitles") {
      leftDDL = cloneDeep(whiteListJobTitles);
      rightDDL = cloneDeep(blackListJobTitles);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.job_title_id === value);
      else
        indexToShift = rightDDL.findIndex((ele) => ele.job_title_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );

      setWhiteListJobTitles(orderBy(finalRes.leftDDL, "job_title", "asc"));
      setBlackListJobTitles(finalRes.rightDDL);
    }
  }

  const openInviteesModalAndApiCall = async (ele, isFromSingle) => {
    let params = {
      configuration_id: ele.configuration_id,
    };
    if (isFromSingle) params.invitation_setup_id = ele.invitation_setup_id;

    let invitationData = await getInvitationDetails(params);
    setInvitationTableData(invitationData);
    setInvitationModal({
      isOpen: true,
      data: ele,
      isLoader: true,
    });
  };

  function renderPopupForm() {
    return (
      <>
        <div className={props.editCase ? "ant-modal-body" : ""}>
          {loading ? (
            <CommonLoader />
          ) : (
            <>
              <div className="productZoneSession_section_1_form">
                <Row className="adminSatellite_row">
                  <Col span={6} className="productZoneSession_label mb-3">
                    <p>
                      Start Time
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={18}>
                    <TimePicker
                      allowClear={false}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      id="start_date_time"
                      name="start_date_time"
                      format={"HH:mm"}
                      className="adminSatellite_date_time w-100"
                      value={fields.start_date_time}
                      onSelect={(val) => {
                        handleFieldsChange(
                          moment(moment(val).format("HH:mm"), TIME_FORMAT),
                          "start_date_time"
                        );
                      }}
                      onChange={(val) => {
                        handleFieldsChange(
                          moment(moment(val).format("HH:mm"), TIME_FORMAT),
                          "start_date_time"
                        );
                      }}
                      onBlur={() =>
                        validator.current.showMessageFor("start_date_time")
                      }
                    />
                    {validator.current.message(
                      "",
                      fields.start_date_time,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row className="adminSatellite_row">
                  <Col span={6} className="productZoneSession_label mb-3">
                    <p className="text-end">
                      End Time
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={18}>
                    <TimePicker
                      allowClear={false}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      id="end_date_time"
                      name="end_date_time"
                      disabledTime={() =>
                        getDisabledTime(fields.start_date_time)
                      }
                      disabled={!fields.start_date_time}
                      format={"HH:mm"}
                      className="adminSatellite_date_time w-100"
                      value={fields.end_date_time}
                      onSelect={(val) => {
                        if (
                          moment(moment(val).format("HH:mm"), TIME_FORMAT) <
                          fields.start_date_time
                        ) {
                          handleFieldsChange(
                            fields.end_date_time,
                            "end_date_time"
                          );
                          toast.error("Please select correct time");
                        } else
                          handleFieldsChange(
                            moment(moment(val).format("HH:mm"), TIME_FORMAT),
                            "end_date_time"
                          );
                      }}
                      onChange={(val) => {
                        if (
                          moment(moment(val).format("HH:mm"), TIME_FORMAT) <
                          fields.start_date_time
                        ) {
                          handleFieldsChange(
                            fields.end_date_time,
                            "end_date_time"
                          );
                          toast.error("Please select correct time");
                        } else
                          handleFieldsChange(
                            moment(moment(val).format("HH:mm"), TIME_FORMAT),
                            "end_date_time"
                          );
                      }}
                      onBlur={() =>
                        validator.current.showMessageFor("end_date_time")
                      }
                    />
                    {validator.current.message(
                      "",
                      fields.end_date_time,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row className="productZoneSession_row">
                  <Col span={6} className="productZoneSession_label">
                    <p>
                      Title <RedAsterick />
                    </p>
                  </Col>
                  <Col span={18}>
                    <Input
                      name="session_title"
                      className="productZoneSession_inputTitle"
                      placeholder="Enter a Title here"
                      value={fields.session_title}
                      onChange={(e) =>
                        handleFieldsChange(e.target.value, "session_title")
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("session_title")
                      }
                    />
                    {validator.current.message(
                      "Title",
                      fields.session_title,
                      "required|min:1|max:100",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row className="productZoneSession_row">
                  <Col span={6} className="productZoneSession_label">
                    <p style={{ marginTop: "9px" }}>
                      Description
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={18}>
                    <TextArea
                      name="session_description"
                      value={fields.session_description}
                      className="productZoneSession_inputDescription"
                      placeholder="Enter a description here"
                      rows={4}
                      onChange={(e) =>
                        handleFieldsChange(
                          e.target.value,
                          "session_description"
                        )
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("session_description")
                      }
                    />

                    {validator.current.message(
                      "Description",
                      fields.session_description,
                      "required|min:1|max:200",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row className="productZoneSession_row">
                  <Col span={6} className="productZoneSession_label">
                    <p>Download iCal</p>
                  </Col>

                  <Col span={6} style={{ alignSelf: "center" }}>
                    <Switch
                      checked={fields.is_ical_download_enabled}
                      onChange={(val) =>
                        handleFieldsChange(val, "is_ical_download_enabled")
                      }
                    />
                  </Col>
                </Row>
              </div>
              <div className="productZoneSession_assignstaffs_form">
                <Row className="mb-3">
                  <Col span={6}>
                    <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                      Assign Staff
                    </h5>
                  </Col>
                  <Col span={9}>
                    <h6 className="productZoneSession_assignstaffs_heading">
                      Tagged Agents
                    </h6>
                  </Col>
                  <Col span={9}>
                    <h6 className="productZoneSession_assignstaffs_heading">
                      Assigned
                    </h6>
                  </Col>
                </Row>
                <Row className="modal_row mb-3">
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={8}>
                    <div
                      id="assigned_staff"
                      className="adminSatellite_section_5_taggedagents_container"
                    >
                      <Select
                        getPopupContainer={() =>
                          document.getElementById("assigned_staff")
                        }
                        dropdownAlign={{
                          overflow: { adjustY: 0 },
                        }}
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search Agents"
                        className="border border-gray fs-bold-14"
                        onChange={(value) =>
                          handleDDLChange(value, "taggedAgents", "left")
                        }
                        open={openAssignStaffList}
                        onFocus={() => setOpenAssignStaffList(true)}
                        onBlur={() => setOpenAssignStaffList(false)}
                        onSearch={() => setOpenAssignStaffList(true)}
                        listHeight={200}
                        listItemHeight={6}
                        value="All Agents"
                      >
                        {agentsList?.map((data) => {
                          return (
                            <Option
                              key={"restricted_users_" + data.user_id}
                              value={data.user_id}
                              className="dropdown_options"
                            >
                              {data.first_name + " " + data.last_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div
                      id="selected_restricted_users_div"
                      className="adminSatellite_section_5_taggedagents_container"
                    >
                      <Select
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        dropdownAlign={{ overflow: { adjustY: 0 } }}
                        showSearch
                        placeholder="Search Tagged Agents"
                        className="border border-gray fs-bold-14"
                        listHeight={200}
                        listItemHeight={6}
                        optionFilterProp="children"
                        value="Tagged Agents"
                        name="assigned_agents"
                        onBlur={() =>
                          validator.current.showMessageFor("assigned_agents")
                        }
                      >
                        {taggedAgents?.map((data) => {
                          return (
                            <Option
                              key={"tagged_agents_" + data.user_id}
                              value={data.user_id}
                              className="dropdown_options"
                            >
                              {data.first_name + " " + data.last_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}></Col>
                  <Col span={18}></Col>
                </Row>
              </div>

              <div className="productZoneSession_restrict_access_form">
                <Row>
                  <Col span={24}>
                    <h5 className="fs-pink productZoneSession_restrict_access_title">
                      Restrict Access
                    </h5>
                  </Col>
                </Row>
                <Row className="productZoneSession_row">
                  <Col span={24}>
                    <p className="productZoneSession_restrict_access_info">
                      NB: Configure who can view / access your event.
                      <br /> Attendees with any black list attributes will not
                      be able to to see the event on any calendars or access the
                      event.
                      <br /> If an attendee/attribute is on the White List they
                      will be able to see/attaned the event regardless of any
                      black list attributes.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={8}>
                    <h6 className="adminSatellite_userListTitle">
                      Allow List Users
                      <Button
                        className="ms-2"
                        onClick={() => {
                          handleAllowAllBlockAll("blackListUsers", "allowAll");
                        }}
                      >
                        Allow All
                      </Button>
                    </h6>
                  </Col>

                  <Col span={8} offset={2}>
                    <h6 className="adminSatellite_userListTitle">
                      Block List Users
                      <Button
                        className="ms-2"
                        onClick={() => {
                          handleAllowAllBlockAll("blackListUsers", "blockAll");
                        }}
                      >
                        Block All
                      </Button>
                    </h6>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="text-end">
                    <p className="mx-3 mt-3">User List</p>
                  </Col>
                  <Col span={8}>
                    <div
                      id="restricted_users_div"
                      className="adminSatellite_section_5_taggedagents_container my-2"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search Agents"
                        className="border border-gray fs-bold-14"
                        onChange={(value) =>
                          handleDDLChange(value, "blackListUsers", "left")
                        }
                        open={openUserList}
                        onFocus={() => setOpenUsersList(true)}
                        onBlur={() => setOpenUsersList(false)}
                        onSearch={() => setOpenUsersList(true)}
                        getPopupContainer={() =>
                          document.getElementById("restricted_users_div")
                        }
                        listHeight={200}
                        listItemHeight={6}
                        value="All Agents"
                      >
                        {whiteListUsers?.map((data) => {
                          return (
                            <Option
                              key={"restricted_users_" + data.user_id}
                              value={data.user_id}
                              className="dropdown_options"
                            >
                              {data.first_name + " " + data.last_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div
                      id="selected_restricted_users_div"
                      className="adminSatellite_section_5_taggedagents_container my-2"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search Tagged Agents"
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById(
                            "selected_restricted_users_div"
                          )
                        }
                        open={openUserList}
                        onFocus={() => setOpenUsersList(true)}
                        onBlur={() => setOpenUsersList(false)}
                        onSearch={() => setOpenUsersList(true)}
                        listHeight={200}
                        listItemHeight={6}
                        value="Tagged Agents"
                      >
                        {blackListUsers?.map((data) => {
                          return (
                            <Option
                              key={"restricted_users_selected_" + data.user_id}
                              value={data.user_id}
                              className="dropdown_options"
                            >
                              <div className="d-flex justify-content-between">
                                <span className="dropdown_options_text">
                                  {data.first_name + " " + data.last_name}
                                </span>

                                <span className="float-end">
                                  <CloseOutlined
                                    className="float-right text-danger mr-0"
                                    onClick={() =>
                                      handleDDLChange(
                                        data.user_id,
                                        "blackListUsers",
                                        "right"
                                      )
                                    }
                                  />
                                </span>
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="text-end"></Col>
                  <Col span={8}></Col>
                  <Col span={8} offset={2}></Col>
                </Row>
                <Row>
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={8}>
                    <h6 className="adminSatellite_userListTitle">
                      Allow List Brands
                      <Button
                        className="ms-2"
                        onClick={() => {
                          handleAllowAllBlockAll("blackListBrands", "allowAll");
                        }}
                      >
                        Allow All
                      </Button>
                    </h6>
                  </Col>

                  <Col span={8} offset={2}>
                    <h6 className="adminSatellite_userListTitle">
                      Block List Brands
                      <Button
                        className="ms-2"
                        onClick={() => {
                          handleAllowAllBlockAll("blackListBrands", "blockAll");
                        }}
                      >
                        Block All
                      </Button>
                    </h6>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="text-end">
                    <p className="mx-3 mt-3">Brand List</p>
                  </Col>
                  <Col span={8}>
                    <div
                      id="restricted_brands_div"
                      className="dropdown_container my-2"
                    >
                      <Select
                        id="restricted_brands"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        onChange={(value) => {
                          handleDDLChange(value, "blackListBrands", "left");
                        }}
                        open={openBrandsList}
                        onFocus={() => setOpenBrandsList(true)}
                        onBlur={() => setOpenBrandsList(false)}
                        onSearch={() => setOpenBrandsList(true)}
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById("restricted_brands_div")
                        }
                        listHeight={200}
                        listItemHeight={6}
                        value="All Brands"
                      >
                        {whiteListBrands &&
                          whiteListBrands.map((data) => (
                            <Option
                              key={"restricted_brands_" + data.brand_id}
                              value={data.brand_id}
                              className="dropdown_options"
                            >
                              {data.brand_title}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div
                      id="selected_restricted_brands_div"
                      className="dropdown_container my-2"
                    >
                      <Select
                        id="selected_restricted_brands"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        open={openBrandsList}
                        onFocus={() => setOpenBrandsList(true)}
                        onBlur={() => setOpenBrandsList(false)}
                        onSearch={() => setOpenBrandsList(true)}
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById("restricted_brands_div")
                        }
                        listHeight={200}
                        listItemHeight={6}
                        value="All Brands"
                      >
                        {blackListBrands &&
                          blackListBrands.map((data) => (
                            <Option
                              key={
                                "selected_restricted_brands_" + data.brand_id
                              }
                              value={data.brand_id}
                              className="dropdown_options"
                            >
                              <div className="d-flex justify-content-between">
                                <span className="dropdown_options_text">
                                  {data.brand_title}
                                </span>
                                <span className="float-end">
                                  <CloseOutlined
                                    className="float-right text-danger mr-0"
                                    onClick={() =>
                                      handleDDLChange(
                                        data.brand_id,
                                        "blackListBrands",
                                        "right"
                                      )
                                    }
                                  />
                                </span>
                              </div>
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="text-end"></Col>
                  <Col span={8}></Col>
                  <Col span={8} offset={2}></Col>
                </Row>
                <Row>
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={8}>
                    <h6 className="adminSatellite_userListTitle">
                      Allow List Countries
                      <Button
                        className="ms-2"
                        onClick={() => {
                          handleAllowAllBlockAll(
                            "blackListCountries",
                            "allowAll"
                          );
                        }}
                      >
                        Allow All
                      </Button>
                    </h6>
                  </Col>

                  <Col span={8} offset={2}>
                    <h6 className="adminSatellite_userListTitle">
                      Block List Countries
                      <Button
                        className="ms-2"
                        onClick={() => {
                          handleAllowAllBlockAll(
                            "blackListCountries",
                            "blockAll"
                          );
                        }}
                      >
                        Block All
                      </Button>
                    </h6>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="text-end">
                    <p className="mx-3 mt-3">Country List</p>
                  </Col>
                  <Col span={8}>
                    <div
                      id="restricted_countries_div"
                      className="dropdown_container"
                    >
                      <Select
                        id="restricted_countries"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        onChange={(value) =>
                          handleDDLChange(value, "blackListCountries", "left")
                        }
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById("restricted_countries_div")
                        }
                        open={openCountriesList}
                        onFocus={() => setOpenCountriesList(true)}
                        onBlur={() => setOpenCountriesList(false)}
                        onSearch={() => setOpenCountriesList(true)}
                        listHeight={200}
                        listItemHeight={6}
                        value="All Countries"
                      >
                        {whiteListCountries &&
                          whiteListCountries.map((data) => (
                            <Option
                              key={"restricted_countries_" + data.country_id}
                              value={data.country_id}
                              className="dropdown_options"
                            >
                              {data.country}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div
                      id="selected_restricted_countries_div"
                      className="dropdown_container"
                    >
                      <Select
                        id="selected_restricted_countries"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById(
                            "selected_restricted_countries_div"
                          )
                        }
                        open={openCountriesList}
                        onFocus={() => setOpenCountriesList(true)}
                        onBlur={() => setOpenCountriesList(false)}
                        onSearch={() => setOpenCountriesList(true)}
                        listHeight={200}
                        listItemHeight={6}
                        value="Tagged Countries"
                      >
                        {blackListCountries &&
                          blackListCountries.map((data) => (
                            <Option
                              key={
                                "selected_restricted_countries_" +
                                data.country_id
                              }
                              value={data.country_id}
                              className="dropdown_options"
                            >
                              <div className="d-flex justify-content-between">
                                <span className="dropdown_options_text">
                                  {data.country}
                                </span>
                                <span className="float-end">
                                  <CloseOutlined
                                    className="float-right text-danger mr-0"
                                    onClick={() =>
                                      handleDDLChange(
                                        data.country_id,
                                        "blackListCountries",
                                        "right"
                                      )
                                    }
                                  />
                                </span>
                              </div>
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={8}></Col>

                  <Col span={8} offset={2}></Col>
                </Row>
                <Row>
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={8}>
                    <h6 className="adminSatellite_userListTitle">
                      Allow List Job Titles
                      <Button
                        className="ms-2"
                        onClick={() => {
                          handleAllowAllBlockAll(
                            "blackListJobTitles",
                            "allowAll"
                          );
                        }}
                      >
                        Allow All
                      </Button>
                    </h6>
                  </Col>

                  <Col span={8} offset={2}>
                    <h6 className="adminSatellite_userListTitle">
                      Block List Job Titles
                      <Button
                        className="ms-2"
                        onClick={() => {
                          handleAllowAllBlockAll(
                            "blackListJobTitles",
                            "blockAll"
                          );
                        }}
                      >
                        Block All
                      </Button>
                    </h6>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="text-end">
                    <p className="mx-3 mt-3">Job Titles List</p>
                  </Col>
                  <Col span={8}>
                    <div
                      id="restricted_job_titles_div"
                      className="dropdown_container"
                    >
                      <Select
                        id="restricted_job_titles"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        open={openJobTitlesList}
                        onFocus={() => setOpenJobTitlesList(true)}
                        onBlur={() => setOpenJobTitlesList(false)}
                        onSearch={() => setOpenJobTitlesList(true)}
                        onChange={(value) =>
                          handleDDLChange(value, "blackListJobTitles", "left")
                        }
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById("restricted_job_titles_div")
                        }
                        listHeight={200}
                        listItemHeight={6}
                        value="All Job Titles"
                      >
                        {whiteListJobTitles &&
                          whiteListJobTitles.map((data) => (
                            <Option
                              key={"restricted_job_titles_" + data.job_title_id}
                              value={data.job_title_id}
                              className="dropdown_options"
                            >
                              {data.job_title}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div
                      id="selected_restricted_job_titles_div"
                      className="dropdown_container"
                    >
                      <Select
                        id="selected_restricted_job_titles"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        className="border border-gray fs-bold-14"
                        getPopupContainer={() =>
                          document.getElementById(
                            "selected_restricted_job_titles_div"
                          )
                        }
                        open={openJobTitlesList}
                        onFocus={() => setOpenJobTitlesList(true)}
                        onBlur={() => setOpenJobTitlesList(false)}
                        onSearch={() => setOpenJobTitlesList(true)}
                        listHeight={200}
                        listItemHeight={6}
                        value="All Job Titles"
                      >
                        {blackListJobTitles &&
                          blackListJobTitles.map((data) => (
                            <Option
                              key={
                                "selected_restricted_job_titles_" +
                                data.job_title_id
                              }
                              value={data.job_title_id}
                              className="dropdown_options"
                            >
                              <div className="d-flex justify-content-between">
                                <span className="dropdown_options_text">
                                  {data.job_title}
                                </span>
                                <span className="float-end">
                                  <CloseOutlined
                                    className="float-right text-danger mr-0"
                                    onClick={() =>
                                      handleDDLChange(
                                        data.job_title_id,
                                        "blackListJobTitles",
                                        "right"
                                      )
                                    }
                                  />
                                </span>
                              </div>
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                </Row>
              </div>
              {invitationModal.isOpen &&
              props.isFromCalender &&
              props.isFromCalender === "zone" ? (
                <div className="productZoneSession_prevpending_form plugin_modal ant-modal-wrap">
                  <div
                    style={{ padding: "40px", width: "650px" }}
                    className="ant-modal"
                  >
                    <div className="ant-modal-content">
                      <div className="ant-modal-header">
                        <div className="ant-modal-title">
                          <div className="invite_center">
                            Previously Invited
                          </div>
                        </div>
                        <div
                          className="ant-modal-close"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            if (e.currentTarget !== e.target) {
                              e.stopPropagation();
                              e.preventDefault();
                              setInvitationModal(invitation_modal_default);
                            }
                          }}
                        >
                          <span className="ant-modal-close-x">
                            <CloseOutlined />
                          </span>
                        </div>
                      </div>
                      <div className="ant-modal-body">
                        <Table
                          columns={invitation_table_columns}
                          pagination={false}
                          dataSource={invitationTableData}
                          bordered={false}
                          onChange={handleInviteesTableChange}
                          scroll={{ y: 240 }}
                          rowKey={(obj) => obj.rsvp_detail_id + "_invi"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="productZoneSession_prevpending_form">
                  <Row>
                    <Col span={24}>
                      <h5 className="adminNetworking_section_4_title">
                        Previous & Pending Invitees
                      </h5>
                    </Col>
                  </Row>
                  {props.data && props.data.configuration_id ? (
                    <>
                      {previous_pending_invites ? (
                        <Row className="modal_row">
                          <Col span={6} />
                          <Col span={9} className="adminSatellite_eyeViewBtn">
                            <Button
                              type="primary"
                              className="adminSatellite_section_8_previousInvitedBtn"
                              size={"large"}
                              style={{ zIndex: 1300 }}
                              onClick={() =>
                                openInviteesModalAndApiCall(
                                  previous_pending_invites[0],
                                  false
                                )
                              }
                            >
                              View List of Previously Invited
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                      <Row className="modal_row">
                        <Col span={6} />
                        <Col span={9}>
                          <h6 className="adminSatellite_section_8_heading">
                            Currently Scheduled Invitations
                          </h6>
                        </Col>
                      </Row>
                      {previous_pending_invites ? (
                        previous_pending_invites.map((ele, index) => {
                          if (ele && !ele.is_delete)
                            return (
                              <Row
                                className="modal_row"
                                key={"pending_inv_" + ele.date_time_to_invite}
                              >
                                <Col span={6} className="modal_field_label">
                                  Date & Time
                                </Col>
                                <Col span={7} className="invite_center">
                                  {ele.date_time_to_invite
                                    ? moment(ele.date_time_to_invite).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : null}
                                </Col>
                                <Col span={9} offset={2}>
                                  <Button
                                    style={{ zIndex: 1300 }}
                                    type="primary"
                                    className="adminSatellite_section_8_viewListBtn"
                                    size={"large"}
                                    onClick={async () =>
                                      openInviteesModalAndApiCall(ele, true)
                                    }
                                  >
                                    View List
                                  </Button>
                                  <img
                                    style={{ marginLeft: "15px" }}
                                    src={blackbin}
                                    onClick={() => {
                                      let clonedDeleteIds = _.cloneDeep(
                                        delete_scheduled_invitations
                                      );
                                      clonedDeleteIds.push(
                                        ele.networking_lounge_invitation_setup_id
                                      );
                                      set_delete_scheduled_invitations(
                                        clonedDeleteIds
                                      );

                                      let clonedData = _.cloneDeep(
                                        previous_pending_invites
                                      );
                                      clonedData[index].is_delete = true;
                                      set_previous_pending_invites(clonedData);
                                    }}
                                  />
                                </Col>
                              </Row>
                            );
                          else return null;
                        })
                      ) : (
                        <Empty description={"No Scheduled Invitations"} />
                      )}
                    </>
                  ) : (
                    <Empty description="No invitees" />
                  )}
                </div>
              )}

              {props?.isFromCalender !== "zone" ? (
                <Modal
                  title={
                    <div className="invite_center">{"Previously Invited"}</div>
                  }
                  visible={invitationModal.isOpen}
                  width={650}
                  onOk={() => setInvitationModal(invitation_modal_default)}
                  onCancel={() => setInvitationModal(invitation_modal_default)}
                  footer={null}
                >
                  <div className="px-3">
                    <Table
                      columns={invitation_table_columns}
                      pagination={false}
                      dataSource={invitationTableData}
                      bordered={false}
                      onChange={handleInviteesTableChange}
                      scroll={{ y: 240 }}
                      rowKey={(obj) => obj.rsvp_detail_id + "_invi"}
                    />
                  </div>
                </Modal>
              ) : null}

              <Row>
                <Col span={24}>
                  <h5 className="adminNetworking_section_5_title fs-pink mt-4">
                    New Invitees
                    <Switch
                      className="ms-2"
                      onChange={(val) => {
                        setInviteesToggle(!inviteesToggle);
                        if (val === false) {
                        }
                      }}
                    ></Switch>
                  </h5>
                </Col>
              </Row>
              {inviteesToggle ? (
                <>
                  <div
                    className="adminNetworking_section_5_form"
                    style={{ borderBottom: "1px solid #eae4f2" }}
                  >
                    <Row>
                      <Col span={6}></Col>
                      <Col span={9}>
                        <h6 className="adminNetworking_section_5_heading">
                          Filter Attendees
                        </h6>
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p>Brand</p>
                      </Col>
                      <Col span={18}>
                        <Select
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          disabled={!inviteesToggle ? true : false}
                          mode="multiple"
                          className="w-100"
                          placeholder="Select Brands"
                          value={invited_brands}
                          onChange={(val) => set_invited_brands(val)}
                        >
                          {brandsCollection &&
                            brandsCollection.map((item, i) => {
                              return (
                                <Option
                                  key={"invited_brands_" + item.brand_id}
                                  value={item.brand_id}
                                >
                                  {item.brand_title}
                                </Option>
                              );
                            })}
                        </Select>
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p>Zone</p>
                      </Col>
                      <Col span={18}>
                        <Select
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          disabled={!inviteesToggle ? true : false}
                          className="w-100"
                          mode="multiple"
                          placeholder="Select Zones"
                          value={invited_zones}
                          onChange={(val) => set_invited_zones(val)}
                        >
                          {zoneCollection &&
                            zoneCollection.map((item, i) => {
                              return (
                                <Option
                                  key={"invited_zones" + item.zone_id}
                                  value={item.zone_id}
                                >
                                  {item.zone_title}
                                </Option>
                              );
                            })}
                        </Select>
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p>User Type</p>
                      </Col>
                      <Col span={18}>
                        <Select
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          disabled={!inviteesToggle ? true : false}
                          className="w-100"
                          mode="multiple"
                          placeholder="Select User Types"
                          value={invited_user_types}
                          onChange={(val) => set_invited_user_types(val)}
                        >
                          <Option value="AGENT">AGENT</Option>
                          <Option value="DELEGATE">DELEGATE</Option>
                          <Option value="SPEAKER">SPEAKER</Option>
                        </Select>
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p>Country</p>
                      </Col>
                      <Col span={18}>
                        <Select
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          disabled={!inviteesToggle ? true : false}
                          className="w-100"
                          mode="multiple"
                          placeholder="Select Countries"
                          value={invited_countries}
                          onChange={(val) => set_invited_countries(val)}
                        >
                          {countryCollection &&
                            countryCollection.map((item, i) => {
                              return (
                                <Option
                                  key={"invited_countries" + item.country_id}
                                  value={item.country_id}
                                >
                                  {item.country}
                                </Option>
                              );
                            })}
                        </Select>
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p>Job Title</p>
                      </Col>
                      <Col span={18}>
                        <Select
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          disabled={!inviteesToggle ? true : false}
                          className="w-100"
                          mode="multiple"
                          placeholder="Select Job Titles"
                          value={invited_job_titles}
                          onChange={(val) => set_invited_job_titles(val)}
                        >
                          {jobTitleCollection &&
                            jobTitleCollection.map((item, i) => {
                              return (
                                <Option
                                  key={"invited_job_titles" + item.job_title_id}
                                  value={item.job_title_id}
                                >
                                  <span>{item.job_title}</span>
                                </Option>
                              );
                            })}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                  <div className="adminSatellite_section_10_form">
                    <Row>
                      <Col span={24}>
                        <h5 className="section_title section_theme_title fs-pink">
                          Invitation Setup
                        </h5>
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p>
                          Title
                          <RedAsterick />
                        </p>
                      </Col>
                      <Col span={18}>
                        <Input
                          name="invitation_title"
                          className="adminSatellite_inputTitle"
                          placeholder="Enter a Title here"
                          value={fields.invitation_title}
                          disabled={!inviteesToggle ? true : false}
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "invitation_title"
                            )
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("invitation_title")
                          }
                          minLength={1}
                          maxLength={100}
                        />
                        {validator.current.message(
                          "",
                          fields.invitation_title,
                          "required",
                          { className: "text-danger py-1" }
                        )}
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p style={{ alignSelf: "flex-start" }}>
                          Description
                          <RedAsterick />
                        </p>
                      </Col>
                      <Col span={18}>
                        <TextArea
                          name="invitation_description"
                          className="adminSatellite_inputDescription"
                          placeholder="Enter a description here"
                          disabled={!inviteesToggle ? true : false}
                          rows={4}
                          value={fields.invitation_description}
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "invitation_description"
                            )
                          }
                          onBlur={() =>
                            validator.current.showMessageFor(
                              "invitation_description"
                            )
                          }
                          minLength={0}
                          maxLength={200}
                        />
                        {validator.current.message(
                          "",
                          fields.invitation_description,
                          "required",
                          { className: "text-danger py-1" }
                        )}
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p className="fs-14">Attach iCal</p>
                      </Col>
                      <Col span={18}>
                        <Switch
                          defaultChecked
                          disabled={!inviteesToggle ? true : false}
                          value={fields.is_iCal_download_enabled}
                        />
                      </Col>
                    </Row>
                    <Row className="modal_row">
                      <Col span={6} className="modal_field_label">
                        <p className="fs-14">Re-invite</p>
                      </Col>
                      <Col span={18}>
                        <Switch
                          defaultChecked
                          value={fields.is_reinvite_enabled}
                          disabled={!inviteesToggle ? true : false}
                        />
                        <span style={{ marginLeft: "30px" }}>
                          (if already invited)
                        </span>
                      </Col>
                    </Row>

                    <Form className="productZoneSession_whentosend_form">
                      <Row className="productZoneSession_row">
                        <Col span={24}>
                          <h5 className="fs-pink productZoneSession_whentosend_title">
                            When to Send Invitation
                          </h5>
                        </Col>
                      </Row>

                      <Row className="productZoneSession_row">
                        <Col span={6} className="productZoneSession_label">
                          <p className="fs-14">Scheduled</p>
                        </Col>
                        <Col span={18}>
                          <Switch
                            checked={fields?.send_invitation_now}
                            disabled={!inviteesToggle ? true : false}
                            onClick={(val) => {
                              setFields({
                                ...fields,
                                send_invitation_now: val,
                                schedule_now: !val,
                              });
                            }}
                          />
                        </Col>
                      </Row>

                      {fields.send_invitation_now ? (
                        <Row>
                          <Col span={6} className="productZoneSession_label">
                            <p>Date & Time</p>
                          </Col>
                          <Col span={7}>
                            <DatePicker
                              allowClear={false}
                              suffixIcon={<CalenderGrey width="15" />}
                              name="date_time_to_invite"
                              showTime
                              className="productZoneSession_date_time"
                              disabledDate={(d) =>
                                !d || d.isSameOrBefore(currentDate)
                              }
                              disabledTime={(d) =>
                                !d || d.isSameOrBefore(currentDate)
                              }
                              value={moment(fields.date_time_to_invite)}
                              format={"DD/MM/YYYY HH:mm"}
                              onChange={(val) =>
                                handleFieldsChange(val, "date_time_to_invite")
                              }
                              onBlur={() =>
                                validator.current.showMessageFor(
                                  "date_time_to_invite"
                                )
                              }
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                            />
                            {validator.current.message(
                              "",
                              fields.date_time_to_invite,
                              "required",
                              { className: "text-danger py-1" }
                            )}
                          </Col>
                        </Row>
                      ) : null}
                      <Row>
                        <Col
                          span={6}
                          className="productZoneSession_label"
                        ></Col>
                        <Col span={7}>
                          <span className="fs-pink productZoneSession_or">
                            OR
                          </span>
                        </Col>
                      </Row>
                      <Row className="productZoneSession_row">
                        <Col span={6} className="productZoneSession_label">
                          <p className="fs-14">Now</p>
                        </Col>
                        <Col span={18}>
                          <Switch
                            checked={fields?.schedule_now}
                            disabled={!inviteesToggle ? true : false}
                            onClick={(val) => {
                              setFields({
                                ...fields,
                                send_invitation_now: !val,
                                schedule_now: val,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
        {props.editCase ? renderFooter() : null}
      </>
    );
  }

  function renderFooter() {
    return (
      <div className="ant-modal-footer">
        <Button
          className="productZoneSession_cancelBtn ms-4"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          className="productZoneSession_saveBtn ms-4"
          onClick={() => handleSubmit()}
        >
          Save Changes & Send Invitations
        </Button>
      </div>
    );
  }

  if (props.editCase) return renderPopupForm();
  return (
    <Modal
      title={"Product Zone - Session"}
      visible={true}
      onOk={() => {
        if (!loading) onCancel();
      }}
      onCancel={() => {
        if (!loading) onCancel();
      }}
      width={950}
      footer={[
        <Button
          className="productZoneSession_cancelBtn ms-4"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>,
        <Button
          className="productZoneSession_saveBtn ms-4"
          onClick={() => handleSubmit()}
        >
          Save Changes & Send Invitations
        </Button>,
      ]}
    >
      {renderPopupForm()}
    </Modal>
  );
};
export default ProductZoneDemo;
