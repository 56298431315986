import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Survey from "../../surveyManagement/surveyscreens";
import "./SurveyModal.css";

const SurveyModal = () => {
  const history = useHistory();

  const [isUserSurveyModalVisible, setIsUserSurveyModalVisible] =
    useState(false);

  useEffect(() => {
    setIsUserSurveyModalVisible(true);
  }, []);

  return (
    <div>
      <Modal
        visible={isUserSurveyModalVisible}
        onCancel={() => {
          setIsUserSurveyModalVisible(false);
          localStorage.setItem("registration_survey_id", null);

          history.push("/concourse");
        }}
        wrapClassName="userSurvey_wrapper_modal"
        className="userSurvey_modal"
        footer={null}
      >
        <Survey
          isFromSurveyModal={true}
          handleCloseUserSurveyModal={() => {
            setIsUserSurveyModalVisible(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default SurveyModal;
