import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { CometChatNavBar } from "./CometChatNavBar";
import { CometChatMessages } from "../Messages";
import { CometChatIncomingCall, CometChatIncomingDirectCall } from "../Calls";

import { CometChatContextProvider } from "../../util/CometChatContext";
import * as enums from "../../util/enums.js";
import { theme } from "../../resources/theme";
import Translator from "../../resources/localization/translator";

import { unifiedStyle, unifiedSidebarStyle, unifiedMainStyle } from "./style";
import { ApiCall } from "../../../../../utils/api";

class CometChatUI extends React.Component {
  loggedInUser = null;

  constructor(props) {
    super(props);
    this.state = {
      sidebarview: false,
      userData: null,
      visitBoothData: null,
    };

    this.navBarRef = React.createRef();
    this.contextProviderRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.chatWithUser.length === 0 &&
      this.props.chatWithGroup.length === 0
    ) {
      this.toggleSideBar();
    }
  }

  navBarAction = async (action, type, item) => {
    switch (action) {
      case enums.ACTIONS["ITEM_CLICKED"]:
        let flag = await this.messageButtonsApiCall(item.uid);
        if (flag) {
          this.itemClicked(item, type); //aditya click action
        }

        break;
      case enums.ACTIONS["TOGGLE_SIDEBAR"]:
        this.toggleSideBar();
        break;
      default:
        break;
    }
  };

  itemClicked = (item, type) => {
    this.contextProviderRef.setTypeAndItem(type, item);
    this.toggleSideBar();
  };

  actionHandler = (action, item, count, ...otherProps) => {
    switch (action) {
      case enums.ACTIONS["TOGGLE_SIDEBAR"]:
        this.toggleSideBar();
        break;
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
      case enums.GROUP_MEMBER_KICKED:
      case enums.GROUP_MEMBER_BANNED:
        this.groupUpdated(action, item, count, ...otherProps);
        break;
      default:
        break;
    }
  };

  toggleSideBar = () => {
    const sidebarview = this.state.sidebarview;
    this.setState({ sidebarview: !sidebarview });
  };

  messageButtonsApiCall = async (uid) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/profilebooth/details/get",
      {
        chat_id: uid,
      }
    );

    if (response?.data?.code === 200) {
      let user = response?.data?.data;
      let boothData = response?.data?.data?.booth_details;
      let event_id = null;
      if (localStorage.getItem("event_id")) {
        event_id: localStorage.getItem("event_id");
      }
      this.setState({
        userData: {
          bio: user?.bio,
          company: user?.company,
          country: user?.country,
          email: user?.email,
          event_domain_name: user?.event_domain_name,
          image_url: user?.image_url,
          is_calendar_access: user?.is_calendar_access,
          job_title: user?.job_title,
          tier_name: user?.tier_name,
          user_name: user?.user_name,
          user_type: user?.user_type,
          chat_id: user?.chat_id,
          user_id: user?.user_id,
          users_users_social_media_handles_user_id:
            user?.users_users_social_media_handles_user_id,
        },
        visitBoothData: boothData
          ? {
              tier: boothData?.brand_tier,
              zones: boothData?.zones,
              sequence: boothData?.sequence ?? null,
              brand_id: boothData?.brand_id,
              eventID: boothData?.event_id ?? event_id,
              final_booth_url: boothData?.final_booth_url,
            }
          : null,
      });

      return true;
    } else {
      this.setState({
        userData: null,
        visitBoothData: null,
      });
      return true;
    }
  };

  /**
	 If the logged in user is banned, kicked or scope changed, update the chat window accordingly
	 */
  groupUpdated = (key, message, group, options) => {
    switch (key) {
      case enums.GROUP_MEMBER_BANNED:
      case enums.GROUP_MEMBER_KICKED: {
        if (
          this.contextProviderRef.state.type ===
            CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.state.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          this.contextProviderRef.setItem({});
          this.contextProviderRef.setType("");
        }
        break;
      }
      case enums.GROUP_MEMBER_SCOPE_CHANGED: {
        if (
          this.contextProviderRef.state.type ===
            CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.state.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          const newObject = Object.assign(
            {},
            this.contextProviderRef.state.item,
            { scope: options["scope"] }
          );
          this.contextProviderRef.setItem(newObject);
          this.contextProviderRef.setType(CometChat.ACTION_TYPE.TYPE_GROUP);
        }
        break;
      }
      default:
        break;
    }
  };

  render() {
    let messageScreen = (
      <CometChatMessages
        theme={this.props.theme}
        lang={this.props.lang}
        _parent="unified"
        actionGenerated={this.actionHandler}
        extraProps={this.props.extraProps}
        userData={this.state.userData}
        visitBoothData={this.state.visitBoothData}
      />
    );

    return (
      <CometChatContextProvider
        ref={(el) => (this.contextProviderRef = el)}
        user={this.props.chatWithUser}
        group={this.props.chatWithGroup}
        language={this.props.lang}
      >
        <div
          css={unifiedStyle(this.props)}
          className="cometchat cometchat--unified"
          dir={Translator.getDirection(this.props.lang)}
        >
          <div
            css={unifiedSidebarStyle(this.state, this.props)}
            className="unified__sidebar chat-left"
            // style={{width:'255px !important'}}
          >
            <CometChatNavBar
              hep={"sss"}
              ref={(el) => (this.navBarRef = el)}
              theme={this.props.theme}
              actionGenerated={this.navBarAction}
              extraProps={this.props.extraProps}
            />
          </div>
          <div
            css={unifiedMainStyle(this.state, this.props)}
            className="unified__main chat-right"
          >
            {messageScreen}
          </div>
          <CometChatIncomingCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
          <CometChatIncomingDirectCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
        </div>
      </CometChatContextProvider>
    );
  }
}

// Specifies the default values for props:
CometChatUI.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
  chatWithUser: "",
  chatWithGroup: "",
};

CometChatUI.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
  chatWithUser: PropTypes.string,
  chatWithGroup: PropTypes.string,
};

export { CometChatUI };
