import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

export const updateEvent = createAsyncThunk(
  "updateEvent/updateEvent",
  async (body) => {
    const response = await ApiCallForm("PUT", body, "/admin/events/update", "");

    return response;
  }
);

export const updateEventSettings = createAsyncThunk(
  "updateEvent/updateEventSettings",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/settings/update",
      "",
      ""
    );

    return response;
  }
);

export const updateRegisterEvent = createAsyncThunk(
  "updateEvent/updateRegisterEvent",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/registrationandcommunication/update",
      "",
      ""
    );

    return response;
  }
);

export const updateTierEvent = createAsyncThunk(
  "updateEvent/updateTierEvent",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/tiers/update",
      "",
      ""
    );

    return response;
  }
);

export const updateNavigationAndReceptionEvent = createAsyncThunk(
  "updateEvent/updateNavigationAndReceptionEvent",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/navigationplusreception/update",
      "",
      ""
    );

    return response;
  }
);

export const updateSocialWallEvent = createAsyncThunk(
  "updateEvent/updateSocialWallEvent",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/socialwall/update",
      "",
      ""
    );

    return response;
  }
);

export const updateEventSlice = createSlice({
  name: "updateEvent",
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {},
  extraReducers: {
    [updateEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.eventList = payload;
    },
    [updateEvent.rejected]: (state) => {
      state.loading = false;
    },
    [updateEventSettings.pending]: (state) => {
      state.loading = true;
    },
    [updateEventSettings.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateRegisterEventList = payload;
    },
    [updateEventSettings.rejected]: (state) => {
      state.loading = false;
    },
    [updateRegisterEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateRegisterEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateRegisterEventList = payload;
    },
    [updateRegisterEvent.rejected]: (state) => {
      state.loading = false;
    },
    [updateTierEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateTierEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateTierEventList = payload;
    },
    [updateTierEvent.rejected]: (state) => {
      state.loading = false;
    },

    [updateNavigationAndReceptionEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateNavigationAndReceptionEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateNavigationAndReceptionEventList = payload;
    },
    [updateNavigationAndReceptionEvent.rejected]: (state) => {
      state.loading = false;
    },
    [updateSocialWallEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateSocialWallEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateSocialWallEventList = payload;
    },
    [updateSocialWallEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const updateEventReducer = updateEventSlice.reducer;
