import React from "react";

export const PadLock = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.493"
        height="19.992"
        viewBox="0 0 17.493 19.992"
      >
        <g id="_168-padlock" data-name="168-padlock" transform="translate(-32)">
          <g id="Group_1278" data-name="Group 1278" transform="translate(32)">
            <g id="Group_1277" data-name="Group 1277">
              <path
                id="Path_49263"
                data-name="Path 49263"
                d="M48.712,14.135a.781.781,0,0,0,.781-.781V10.464a3.127,3.127,0,0,0-3.124-3.124H45.43V4.587a4.687,4.687,0,0,0-9.371,0V7.341h-.936A3.127,3.127,0,0,0,32,10.464v6.4a3.127,3.127,0,0,0,3.124,3.124H46.369a3.127,3.127,0,0,0,3.124-3.124.781.781,0,1,0-1.562,0,1.564,1.564,0,0,1-1.562,1.562H35.124a1.564,1.564,0,0,1-1.562-1.562v-6.4A1.564,1.564,0,0,1,35.124,8.9H46.369a1.564,1.564,0,0,1,1.562,1.562v2.889A.781.781,0,0,0,48.712,14.135ZM43.869,7.341H37.621V4.587a3.125,3.125,0,0,1,6.247,0Z"
                transform="translate(-32)"
              />
            </g>
          </g>
          <g
            id="Group_1280"
            data-name="Group 1280"
            transform="translate(40.235 12.727)"
          >
            <g
              id="Group_1279"
              data-name="Group 1279"
              transform="translate(0 0)"
            >
              <circle
                id="Ellipse_52"
                data-name="Ellipse 52"
                cx="1"
                cy="1"
                r="1"
                transform="translate(-0.235 0.273)"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
