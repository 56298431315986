import React, { useState, useEffect } from "react";
import Header from "../../views/common/Header";
import SideBar from "../../views/common/SideBar";
import { Button, Divider, Layout } from "antd";
import { fetchTickerList } from "../../utils/commonApis";
import moment from "moment";
import { isArray, isEmpty } from "lodash";
import { BoothRed } from "../svgJS/BoothRed";
import Marquee from "react-fast-marquee";
import "./layout.css";
import { useMediaQuery } from "react-responsive";
import {
  createTZString,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const AdminLayout = (props) => {
  let CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  const history = useHistory();

  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isMobileDrawerOpen, setisMobileDrawerOpen] = useState(false);
  const [tickerToDisplay, setTickerToDisplay] = useState(null);
  const [tickerFlag, setTickerFlag] = useState(null);
  const isMobile = useMediaQuery({ minWidth: 480 });
  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };
  const toggleMobileDrawer = () => {
    setisMobileDrawerOpen(!isMobileDrawerOpen);
  };
  const [userType, setUserType] = useState();

  useEffect(() => {
    async function stateLoad() {
      const response = await fetchTickerList();

      if (response && isArray(response) && !isEmpty(response)) {
        response.forEach((item) => {
          let ele = item.ticker_master;
          if (ele?.start_time && ele?.end_time) {
            const start_date_time = createTZString(ele?.start_time);
            const end_date_time = createTZString(ele?.end_time);
            const eleStartTime = moment(start_date_time).format("HH:mm");
            const eleEndTime = moment(end_date_time).format("HH:mm");
            const eleStartDate = moment(start_date_time).format("YYYY-MM-DD");

            const currentDate = moment().format("YYYY-MM-DD");

            const currentTime = moment().format("HH:mm");

            if (
              currentDate === eleStartDate &&
              currentTime >= eleStartTime &&
              currentTime <= eleEndTime
            ) {
              setTickerToDisplay(ele);

              localStorage.setItem("tickerToDisplay", "true");
            }
          }
        });
      }
    }

    stateLoad();
  }, [props]);

  useEffect(async () => {
    if (localStorage.getItem("user_type")) {
      const ciphertext = localStorage.getItem("user_type");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(user_type);
    }
  }, [CryptoJS.AES]);

  const dataFromSideBar = (data) => {
    setTickerFlag(parseInt(data));
  };

  return (
    <Layout style={{ height: "inherit" }}>
      <SideBar
        isSidebarCollapsed={isSidebarCollapsed}
        toggleSidebar={toggleSidebar}
        dataFromSideBar={dataFromSideBar}
        isMobileDrawerOpen={isMobileDrawerOpen}
        toggleMobileDrawer={toggleMobileDrawer}
      />
      <Layout
        className={
          (isMobile === false ? "bg-light" : "site-layout") +
          (isSidebarCollapsed ? " ml-80px" : " ml-200px") +
          (isMobile === false ? "m-0" : "")
        }
        style={{ zIndex: 0, height: "inherit" }}
      >
        <Header
          isSidebarCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
          isMobileDrawerOpen={isMobileDrawerOpen}
          toggleMobileDrawer={toggleMobileDrawer}
          {...props}
        />
        <Layout.Content
          className="top_layout"
          style={{
            margin: "65px 15px 15px 15px",
            minHeight: 280,
            height: "inherit",
          }}
        >
          {/* (tickerFlag <= 12 || tickerFlag === 19) &&
          tickerFlag !== 7 &&
          tickerFlag !== 1 */}
          {props.children}
        </Layout.Content>
        {/* {tickerToDisplay && tickerToDisplay.ticker_name ? (
          <Layout.Footer
            className="d-flex flex-row"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            
          </Layout.Footer>
        ) : null} */}

        <Layout.Footer>
          {tickerToDisplay && // This code is for hiding the ticker for particular modules i.e admin modules,people & messages
          tickerToDisplay?.ticker_name ? (
            <div
              className={
                isSidebarCollapsed
                  ? "d-flex flex-row margin_top_ticker ticker_width_side_bar_collapsed"
                  : "d-flex flex-row margin_top_ticker ticker_width_side_bar_not_collapsed"
              }
            >
              <div className="layout_footer_left d-flex align-content-center">
                <span
                  style={{ color: "white", width: "20%", textAlign: "center" }}
                >
                  {tickerToDisplay?.ticker_fixed_title}
                </span>
                <Divider type="vertical" />
                <Marquee pauseOnHover gradient={false}>
                  <span className="marquee_text">
                    {tickerToDisplay?.ticker_description}
                  </span>
                </Marquee>
              </div>
              <div className="layout_footer_right d-flex align-content-center align-items-center">
                <Button
                  onClick={() => {
                    let boothData = tickerToDisplay?.ticker_linking?.brand;

                    let tier = boothData?.brand_tier;
                    let zones = boothData?.zones;
                    let sequence = null;
                    let brand_id = boothData?.brand_id;
                    let eventID = boothData?.event_id;
                    let final_booth_url = boothData?.final_booth_url;

                    vistaRedirectToBooth(
                      tier,
                      zones,
                      sequence,
                      userType,
                      brand_id,
                      eventID,
                      history,
                      dispatch,
                      final_booth_url
                    );
                  }}
                  className="secondary_button"
                >
                  <BoothRed />
                  <span style={{ marginLeft: "2%" }}>Go To Booth</span>
                </Button>
              </div>
            </div>
          ) : null}
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
