import React, { useState } from "react";
import "./index.css";
import { Form, Input, Button } from "antd";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);

  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const isMobile = useMediaQuery({ minWidth: 480 });

  const onFinish = async (values) => {
    setLoading(true);
    const response = await ApiCall(
      "POST",
      values,
      "/admin/forgotpassword",
      "",
      true
    );
    if (response.data.code === 200) toast.success(response.data.message);
    else toast.error(response.data.message);

    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    toast.error(errorInfo.errorFields[0].errors[0]);
  };

  return (
    <div className="fp_bg d-flex flex-column align-items-center">
      <img
        className="login_img_logo forgotpass_logo mb-2"
        src={localStorage.getItem("event_logo_url")}
        alt="logo"
      />
      <div
        className={`fp_container mt-3 text-center ${
          !isMobile ? "w-75" : `${Tablet ? "w-50" : "w-25"}`
        } `}
      >
        <h4 className="fs-pink mb-5 mt-4">Forgot Password</h4>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="mx-2"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              className="forgotpass_input"
              placeholder="Enter Email address"
            />
          </Form.Item>

          {/* <Form.Item wrapperCol={{ offset: 4, span: 16 }}> */}
          <Button
            type="primary"
            loading={loading}
            className="fp_btn w-100 mt-3 bg-pink"
            htmlType="submit"
          >
            Send Email
          </Button>
          {/* </Form.Item> */}
          <p className="fs-14 m-3">
            <Link to="/login">
              <span className="ms-2 fs-pink">
                <u>Sign in</u>
              </span>
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
