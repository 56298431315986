import React, { useEffect, useState, useRef } from "react";
import "../eventCalender.css";
import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Select,
  Switch,
  Button,
  DatePicker,
  InputNumber,
  Table,
  Empty,
} from "antd";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { blackbin } from "../../../components/Images/Images";
import SimpleReactValidator from "simple-react-validator";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import { useDispatch, useSelector } from "react-redux";
import { getZone } from "../../../redux/slice/zoneManagementSlice";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import {
  getNetworkLoungeSession,
  getCountryList,
  getJobTitles,
  getNetworkInvitationDetails,
  getUserTypeAgents,
  getBrandDropdowns,
  getZoneDropdowns,
  getZoneDetails,
} from "../../../utils/commonApis";
import _, { cloneDeep, isEmpty, orderBy } from "lodash";
import { mapSelectedDdlData } from "./modals.utils";
import { CalenderGrey } from "../../../components/svgJS/CalenderGrey";
import {
  createNetworkSession,
  updateNetworkSession,
} from "../../../redux/slice/eventCalender";
import { toast } from "react-toastify";
import { dialogs } from "../../../components/constsnts/string.constants";
import { getMinutesDiff, ifTimeSlotAvailable } from "../eventCalender.utils";
import {
  sortData,
  createISOTimeString,
  createTZString,
  getDisabledTime,
} from "../../../utils/commonFunctions";

const NetworkingSession = (props) => {
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const format = "HH:mm";
  const { rows: zoneRow } = useSelector((state) => state.zoneManagement.data);
  const validator = useRef(new SimpleReactValidator());
  const validatorAddBrand = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [
    networking_lounge_session_details,
    set_networking_lounge_session_details,
  ] = useState({
    is_active: true,
    start_date_time: moment(moment().format("HH:mm"), TIME_FORMAT),
    end_date_time: moment(moment().format("HH:mm"), TIME_FORMAT),
    session_title: "",
    session_description: "",
    is_iCal_enabled: false,
    is_iCal_download_enabled: false,
    is_speed_dating_enabled: false,
    is_waterfall_intros_enabled: false,
    seats_per_table: 0,
    seconds_per_intro: 0,
    mins_per_shuffle: 0,
  });

  const [restricted_users, set_restricted_users] = useState([]);
  const [selected_restricted_users, set_selected_restricted_users] = useState(
    []
  );
  const [final_restricted_users, set_final_restricted_users] = useState([]);
  const [restricted_brands, set_restricted_brands] = useState([]);
  const [selected_restricted_brands, set_selected_restricted_brands] = useState(
    []
  );
  const [final_restricted_brands, set_final_restricted_brands] = useState([]);
  const [restricted_countries, set_restricted_countries] = useState([]);
  const [selected_restricted_countries, set_selected_restricted_countries] =
    useState([]);
  const [final_restricted_countries, set_final_restricted_countries] = useState(
    []
  );
  const [restricted_job_titles, set_restricted_job_titles] = useState([]);
  const [selected_restricted_job_titles, set_selected_restricted_job_titles] =
    useState([]);
  const [final_restricted_job_titles, set_final_restricted_job_titles] =
    useState([]);

  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [countryCollection, setCountryCollection] = useState([]);
  const [jobTitleCollection, setJobTitleCollection] = useState([]);
  const [brandsCollection, setBrandsCollection] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);
  const [brandCollectionForInvitees, setBrandCollectionForInvitees] = useState(
    []
  );
  const [zoneCollectionForInvitees, setZoneCollectionForInvitees] = useState(
    []
  );
  const [defaultFlags] = useState({
    is_chat_enabled: false,
    is_demo_room_enabled: false,
    is_assets_enabled: false,
    is_booth_link_enabled: true,
    is_video_call_enabled: false,
  });
  const defaultBlankNewBrand = {
    brand_id: null,
    sponsorship_id: "",
    networking_configuration_id: "",
    networking_session_id: "",
    brand_title: "",
    zone_id: null,
    is_delete: false,
    sequence: 0,
    ...defaultFlags,
  };
  const [
    networking_lounge_sponsoring_brands,
    set_networking_lounge_sponsoring_brands,
  ] = useState([]);
  const [add_new_brand, set_add_new_brand] = useState(defaultBlankNewBrand);
  const [selectedZoneData, setSelectedZoneData] = useState(defaultFlags);
  const [invitation_setup, set_invitation_setup] = useState({
    invitation_title: "",
    invitation_description: "",
    is_iCal_download_enabled: false,
    is_reinvite_enabled: false,
    send_invitation_now: false,
    date_time_to_invite: undefined,
    is_scheduled: false,
  });
  const [invited_brands, set_invited_brands] = useState([]);
  const [invited_zones, set_invited_zones] = useState([]);
  const [invited_user_types, set_invited_user_types] = useState([]);
  const [invited_countries, set_invited_countries] = useState([]);
  const [invited_job_titles, set_invited_job_titles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [networkSessionAllData, setNetLoungeSessionAllData] = useState({});
  const [previous_pending_invites, set_previous_pending_invites] = useState([]);
  const [invitationModal, setInvitationModal] = useState({
    isOpen: false,
    data: null,
    isLoader: false,
  });
  const [invitationTableData, setInvitationTableData] = useState([]);
  const [delete_scheduled_invitations, set_delete_scheduled_invitations] =
    useState([]);

  const [inviteesToggle, setInviteesToggle] = useState(false);
  const [existingStartEndTime, setExistingStartEndTime] = useState({
    startTime: "",
    endTime: "",
  });

  const [openUserList, setOpenUsersList] = useState(false);
  const [openBrandsList, setOpenBrandsList] = useState(false);
  const [openCountriesList, setOpenCountriesList] = useState(false);
  const [openJobTitlesList, setOpenJobTitlesList] = useState(false);

  useEffect(async () => {
    const agentListAPI = await getUserTypeAgents({
      event_id: props.sessionData.event_id,
    });
    setAgentsCollectionData(agentListAPI);
    const brandListAPI = await getBrandDropdowns(props.sessionData.event_id);
    setBrandsCollection(brandListAPI);
    setBrandCollectionForInvitees(brandListAPI);
    const countryListAPI = await getCountryList();
    const jobTitlesListAPI = await getJobTitles();
    const zoneListAPI = await getZoneDropdowns(props.sessionData.event_id);
    setZoneCollection(zoneListAPI);
    setZoneCollectionForInvitees(zoneListAPI);

    if (props.sessionData) {
      let sessionAPIData = await getNetworkLoungeSession({
        networking_configuration_id:
          props.sessionData.networking_configuration_id,
        networking_session_id: props.sessionData.networking_session_id,
      });

      setNetLoungeSessionAllData(sessionAPIData);
      set_previous_pending_invites(sessionAPIData.previous_pending_invites);
      setTimeout(() => {
        setLocalStateData({
          sessionAPIData,
          agentListAPI,
          brandListAPI,
          countryListAPI,
          jobTitlesListAPI,
        });
      }, 500);
    } else setLoading(false);
  }, []);

  async function setLocalStateData(localProps) {
    const { sessionAPIData } = localProps;
    if (sessionAPIData.networking_lounge_session_details) {
      let sessData = sessionAPIData.networking_lounge_session_details;
      const start_date_time = createTZString(sessData?.start_date_time);
      const end_date_time = createTZString(sessData?.end_date_time);
      const default_time = moment(moment().format("HH:mm"), TIME_FORMAT);

      sessData.start_date_time = sessData?.start_date_time
        ? moment(start_date_time.format("HH:mm"), TIME_FORMAT)
        : default_time;
      sessData.end_date_time = sessData?.end_date_time
        ? moment(end_date_time.format("HH:mm"), TIME_FORMAT)
        : default_time;

      setExistingStartEndTime({
        startTime: moment(
          start_date_time?.format("HH:mm"),
          TIME_FORMAT
        )?.format("HH:mm"),
        endTime: moment(end_date_time?.format("HH:mm"), TIME_FORMAT)?.format(
          "HH:mm"
        ),
      });

      sessData.mins_per_shuffle = sessData?.mins_per_shuffle ?? 0;
      set_networking_lounge_session_details(sessData);
    } else {
      let cloned_networking_lounge_session_details = cloneDeep(
        networking_lounge_session_details
      );
      const start_date_t = moment(
        props.sessionData.hourItem.start_time,
        TIME_FORMAT
      );
      const end_date_t = moment(
        props.sessionData.hourItem.end_time,
        TIME_FORMAT
      );

      cloned_networking_lounge_session_details.start_date_time = start_date_t;
      cloned_networking_lounge_session_details.end_date_time = end_date_t;
      set_networking_lounge_session_details(
        cloned_networking_lounge_session_details
      );
    }

    if (sessionAPIData.invited_brands)
      set_invited_brands(sessionAPIData.invited_brands);

    if (sessionAPIData.invited_zones)
      set_invited_zones(sessionAPIData.invited_zones);

    if (sessionAPIData.invited_user_types)
      set_invited_user_types(sessionAPIData.invited_user_types);

    if (sessionAPIData.invited_countries)
      set_invited_countries(sessionAPIData.invited_countries);

    if (sessionAPIData.invited_job_titles)
      set_invited_job_titles(sessionAPIData.invited_job_titles);

    if (sessionAPIData.networking_lounge_sponsoring_brands)
      set_networking_lounge_sponsoring_brands(
        sessionAPIData.networking_lounge_sponsoring_brands
      );

    mapAllDDL(localProps);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const mapAllDDL = async (localProps) => {
    const { sessionAPIData, agentListAPI, brandListAPI } = localProps;
    const { countryListAPI, jobTitlesListAPI } = localProps;
    const dataHere = sessionAPIData;

    if (agentListAPI) {
      if (dataHere && !isEmpty(dataHere.restricted_users)) {
        set_final_restricted_users(dataHere.restricted_users);
        const clonedAgents = cloneDeep(agentListAPI);
        let abc = await mapSelectedDdlData(
          dataHere.restricted_users,
          selected_restricted_users,
          clonedAgents,
          "user_id"
        )
          .then((agentsToMap) => {
            if (agentsToMap && agentsToMap.mainList)
              set_restricted_users(
                orderBy(agentsToMap.mainList, "first_name", "asc")
              );
            if (agentsToMap && agentsToMap.selectedList)
              set_selected_restricted_users(agentsToMap.selectedList);
          })
          .catch((err) => {});
      } else {
        const clonedAgents = cloneDeep(agentListAPI);
        set_restricted_users(orderBy(clonedAgents, "first_name", "asc"));
      }
    }

    if (dataHere && !isEmpty(dataHere.restricted_brands)) {
      set_final_restricted_brands(dataHere.restricted_brands);
      const clonedBrands = cloneDeep(brandListAPI);
      let abc = await mapSelectedDdlData(
        dataHere.restricted_brands,
        selected_restricted_brands,
        clonedBrands,
        "brand_id"
      )
        .then((brandsToMap) => {
          if (brandsToMap && brandsToMap.mainList)
            set_restricted_brands(
              orderBy(brandsToMap.mainList, "brand_title", "asc")
            );
          if (brandsToMap && brandsToMap.selectedList)
            set_selected_restricted_brands(brandsToMap.selectedList);
        })
        .catch((err) => {});
    } else set_restricted_brands(orderBy(brandListAPI, "brand_title", "asc"));

    if (
      props.sessionData.currentEventData &&
      props.sessionData.currentEventData.registration_and_communication_details
    ) {
      if (countryListAPI) {
        let event_country_ids =
          props.sessionData.currentEventData
            .registration_and_communication_details.country_collection_ids;

        if (event_country_ids) {
          let countriesOfEvents = [];
          countryListAPI.forEach((ele) => {
            if (event_country_ids.includes(ele.country_id))
              countriesOfEvents.push(ele);
          });

          if (countriesOfEvents) {
            setCountryCollection(countriesOfEvents);

            if (dataHere && !isEmpty(dataHere.restricted_countries)) {
              set_final_restricted_countries(dataHere.restricted_countries);
              let abc = await mapSelectedDdlData(
                dataHere.restricted_countries,
                selected_restricted_countries,
                countriesOfEvents,
                "country_id"
              )
                .then((countriesToMap) => {
                  if (countriesToMap && countriesToMap.mainList)
                    set_restricted_countries(
                      orderBy(countriesToMap.mainList, "country", "asc")
                    );
                  if (countriesToMap && countriesToMap.selectedList)
                    set_selected_restricted_countries(
                      countriesToMap.selectedList
                    );
                })
                .catch((err) => {});
            } else
              set_restricted_countries(
                orderBy(countriesOfEvents, "country", "asc")
              );
          }
        }
      }

      if (jobTitlesListAPI) {
        let event_job_titles_ids =
          props.sessionData.currentEventData
            .registration_and_communication_details.job_title_collection_ids;

        if (event_job_titles_ids) {
          let titlesOfEvents = [];
          jobTitlesListAPI.forEach((ele) => {
            if (event_job_titles_ids.includes(ele.job_title_id))
              titlesOfEvents.push(ele);
          });

          if (titlesOfEvents) {
            setJobTitleCollection(titlesOfEvents);
            if (dataHere && !isEmpty(dataHere.restricted_job_titles)) {
              set_final_restricted_job_titles(dataHere.restricted_job_titles);
              let abc = await mapSelectedDdlData(
                dataHere.restricted_job_titles,
                selected_restricted_job_titles,
                titlesOfEvents,
                "job_title_id"
              )
                .then((jobTitlesToMap) => {
                  if (jobTitlesToMap && jobTitlesToMap.mainList)
                    set_restricted_job_titles(
                      orderBy(jobTitlesToMap.mainList, "job_title", "asc")
                    );
                  if (jobTitlesToMap && jobTitlesToMap.selectedList)
                    set_selected_restricted_job_titles(
                      jobTitlesToMap.selectedList
                    );
                })
                .catch((err) => {});
            } else
              set_restricted_job_titles(
                orderBy(titlesOfEvents, "job_title", "asc")
              );
          }
        }
      }
    }
  };

  async function handleAllowAllBlockAll(key, type) {
    let leftDDL, rightDDL;

    if (key === "restricted_users") {
      if (type === "blockAll") {
        let newArrUsers = selected_restricted_users.concat(restricted_users);

        rightDDL = cloneDeep(newArrUsers);
        leftDDL = [];
      }

      if (type === "allowAll") {
        let newArrUsers = selected_restricted_users.concat(restricted_users);

        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(newArrUsers);
      }
    } else if (key === "restricted_brands") {
      if (type === "blockAll") {
        let newArrBrands = selected_restricted_brands.concat(restricted_brands);

        rightDDL = cloneDeep(newArrBrands);
        leftDDL = [];
      }

      if (type === "allowAll") {
        let newArrBrands = selected_restricted_brands.concat(restricted_brands);

        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(newArrBrands);
      }
    } else if (key === "restricted_countries") {
      if (type === "blockAll") {
        let newArrCountries =
          selected_restricted_countries.concat(restricted_countries);

        rightDDL = cloneDeep(newArrCountries);
        leftDDL = [];
      }

      if (type === "allowAll") {
        let newArrCountries =
          selected_restricted_countries.concat(restricted_countries);
        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(newArrCountries);
      }
    } else if (key === "restricted_job_titles") {
      if (type === "blockAll") {
        let newArrJobTitles = selected_restricted_job_titles.concat(
          restricted_job_titles
        );

        rightDDL = cloneDeep(newArrJobTitles);
        leftDDL = [];
      }

      if (type === "allowAll") {
        let newArrJobTitles = selected_restricted_job_titles.concat(
          restricted_job_titles
        );
        rightDDL = cloneDeep([]);

        leftDDL = cloneDeep(newArrJobTitles);
      }
    }

    assignDDLData(leftDDL, rightDDL, key);
  }

  async function handleDDLChange(value, key, leftOrRight) {
    let leftDDL, rightDDL, index;

    if (key === "restricted_users") {
      leftDDL = cloneDeep(restricted_users);
      rightDDL = cloneDeep(selected_restricted_users);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.user_id === value);
      else index = rightDDL.findIndex((ele) => ele.user_id === value);
    }

    if (key === "restricted_brands") {
      leftDDL = cloneDeep(restricted_brands);
      rightDDL = cloneDeep(selected_restricted_brands);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.brand_id === value);
      else index = rightDDL.findIndex((ele) => ele.brand_id === value);

      //filter the blacklisted brands and zones from invitees
      if (leftOrRight === "left") {
        const clonedBrandsInvitees = cloneDeep(brandCollectionForInvitees);
        const filteredBrandsInvitees = clonedBrandsInvitees.filter(
          (ele) => ele.brand_id !== value
        );
        setBrandCollectionForInvitees(filteredBrandsInvitees);
        const clonedZonesInvitees = cloneDeep(zoneCollectionForInvitees);
        const filteredZonesInvitees = clonedZonesInvitees.filter(
          (ele) => ele.brand_id !== value
        );
        setZoneCollectionForInvitees(filteredZonesInvitees);
      } else {
        const clonedBrands = cloneDeep(brandsCollection);
        const clonedZones = cloneDeep(zoneCollection);
        const filteredBrands = clonedBrands.filter(
          (ele) => ele.brand_id === value
        );
        const filteredZones = clonedZones.filter(
          (ele) => ele.brand_id === value
        );
        let clonedBrandsInvitees = cloneDeep(brandCollectionForInvitees);
        let clonedZonesInvitees = cloneDeep(zoneCollectionForInvitees);
        clonedBrandsInvitees = [...clonedBrandsInvitees, ...filteredBrands];
        clonedZonesInvitees = [...clonedZonesInvitees, ...filteredZones];
        setBrandCollectionForInvitees(clonedBrandsInvitees);
        setZoneCollectionForInvitees(clonedZonesInvitees);
      }
    }

    if (key === "restricted_countries") {
      leftDDL = cloneDeep(restricted_countries);
      rightDDL = cloneDeep(selected_restricted_countries);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.country_id === value);
      else index = rightDDL.findIndex((ele) => ele.country_id === value);
    }

    if (key === "restricted_job_titles") {
      leftDDL = cloneDeep(restricted_job_titles);
      rightDDL = cloneDeep(selected_restricted_job_titles);
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.job_title_id === value);
      else index = rightDDL.findIndex((ele) => ele.job_title_id === value);
    }

    if (leftOrRight === "left") {
      rightDDL.push(leftDDL[index]);
      leftDDL.splice(index, 1);
    } else {
      leftDDL.unshift(rightDDL[index]);
      rightDDL.splice(index, 1);
    }

    assignDDLData(leftDDL, rightDDL, key);
  }

  function assignDDLData(leftDDL, rightDDL, key) {
    let onlyIdArr;

    if (key === "restricted_users") {
      onlyIdArr = rightDDL.map((elem) => elem.user_id);
      set_restricted_users(orderBy(leftDDL, "first_name", "asc"));
      set_selected_restricted_users(rightDDL);
      set_final_restricted_users(onlyIdArr);
    }

    if (key === "restricted_brands") {
      onlyIdArr = rightDDL.map((elem) => elem.brand_id);
      set_restricted_brands(orderBy(leftDDL, "brand_title", "asc"));
      set_selected_restricted_brands(rightDDL);
      set_final_restricted_brands(onlyIdArr);
    }

    if (key === "restricted_countries" && selected_restricted_countries) {
      onlyIdArr = rightDDL.map((elem, index) => elem.country_id);
      set_restricted_countries(orderBy(leftDDL, "country", "asc"));
      set_selected_restricted_countries(rightDDL);
      set_final_restricted_countries(onlyIdArr);
    }

    if (key === "restricted_job_titles") {
      onlyIdArr = rightDDL.map((elem, index) => elem.job_title_id);
      set_restricted_job_titles(orderBy(leftDDL, "job_title", "asc"));
      set_selected_restricted_job_titles(rightDDL);
      set_final_restricted_job_titles(onlyIdArr);
    }
  }

  const createUpdateAPI = async (dataToUpdate) => {
    setLoading(true);

    let formData = new FormData();
    formData.append("data", JSON.stringify(dataToUpdate));

    if (dataToUpdate.networking_session_id) {
      const updateRes = await dispatch(updateNetworkSession(formData));

      if (updateRes?.payload?.data?.code === 200) {
        setLoading(false);
        toast.success(updateRes.payload.data.message);
        props.onSuccess(true);
      } else {
        setLoading(false);
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      const createRes = await dispatch(createNetworkSession(formData));

      if (createRes?.payload?.data?.code === 200) {
        setLoading(false);

        toast.success(createRes.payload.data.message);
        props.onSuccess(true);
      } else {
        setLoading(false);
        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleSubmit = () => {
    //form is getting  submitted even if invitation title and description are empty
    //below logic is to resolve it
    if (inviteesToggle) {
      if (!invitation_setup.invitation_title) {
        validator.current.fields = {
          ...validator.current.fields,
          invitation_title: false,
        };
      }
      if (!invitation_setup.invitation_description) {
        validator.current.fields = {
          ...validator.current.fields,
          invitation_description: false,
        };
      }
    }

    if (validator.current.allValid()) {
      let dataToUpdate = cloneDeep(networkSessionAllData);
      let checkForValidSponsors = [];
      let new_net_session_details = cloneDeep(
        networking_lounge_session_details
      );

      const start_date_str =
        props.sessionData.currentDateForCalender +
        " " +
        moment(new_net_session_details.start_date_time).format("HH:mm:ss");
      const start_utc = createISOTimeString(start_date_str);
      const end_date_str =
        props.sessionData.currentDateForCalender +
        " " +
        moment(new_net_session_details.end_date_time).format("HH:mm:ss");
      const end_utc = createISOTimeString(end_date_str);
      new_net_session_details.start_date_time = start_utc;
      new_net_session_details.end_date_time = end_utc;

      dataToUpdate.networking_lounge_session_details = new_net_session_details;

      if (networking_lounge_sponsoring_brands) {
        dataToUpdate.networking_lounge_sponsoring_brands =
          networking_lounge_sponsoring_brands;

        checkForValidSponsors = networking_lounge_sponsoring_brands?.filter(
          (ele) => {
            if (!ele?.is_delete) {
              return ele;
            }
          }
        );
      }

      dataToUpdate.restricted_users = final_restricted_users;
      dataToUpdate.restricted_brands = final_restricted_brands;
      dataToUpdate.restricted_countries = final_restricted_countries;
      dataToUpdate.restricted_job_titles = final_restricted_job_titles;

      dataToUpdate.invited_brands = invited_brands;
      dataToUpdate.invited_zones = invited_zones;
      dataToUpdate.invited_user_types = invited_user_types;
      dataToUpdate.invited_countries = invited_countries;
      dataToUpdate.invited_job_titles = invited_job_titles;
      dataToUpdate.allow_invitation = inviteesToggle;
      dataToUpdate.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      dataToUpdate.current_timestamp = moment().format("YYYY-MM-DD");

      if (dataToUpdate.networking_session_id) {
        let isTimeModified = false;

        let newStartTime =
          networking_lounge_session_details?.start_date_time?.format("HH:mm");
        let newEndTime =
          networking_lounge_session_details?.end_date_time?.format("HH:mm");

        if (
          existingStartEndTime?.startTime !== newStartTime ||
          existingStartEndTime?.endTime !== newEndTime
        ) {
          isTimeModified = true;
        }
        dataToUpdate.is_time_modified = isTimeModified;
      }

      if (invitation_setup) {
        let cloned_invitation_setup = cloneDeep(invitation_setup);

        const start_date_str = moment(
          invitation_setup?.date_time_to_invite
        ).format("YYYY/MM/DD HH:mm");
        const start_utc = createISOTimeString(start_date_str);
        cloned_invitation_setup.date_time_to_invite = start_utc;
        dataToUpdate.invitation_setup = cloned_invitation_setup;
      }

      if (delete_scheduled_invitations)
        dataToUpdate.delete_scheduled_invitations =
          delete_scheduled_invitations;

      dataToUpdate.event_id = props.sessionData.event_id;
      dataToUpdate.networking_configuration_id =
        props.sessionData.networking_configuration_id;
      dataToUpdate.networking_session_id =
        props.sessionData.networking_session_id ?? "";

      if (
        !ifTimeSlotAvailable(
          moment(
            dataToUpdate.networking_lounge_session_details.start_date_time
          ),
          moment(dataToUpdate.networking_lounge_session_details.end_date_time),
          props.sessionData.existingTimings
        )
      )
        toast.error("Timeslot is not available");
      else if (
        moment(dataToUpdate.networking_lounge_session_details.start_date_time) >
        moment(dataToUpdate.networking_lounge_session_details.end_date_time)
      )
        toast.error(dialogs?.inCorrectTimeError);
      else if (
        getMinutesDiff(
          moment(
            dataToUpdate.networking_lounge_session_details.start_date_time
          ),
          moment(dataToUpdate.networking_lounge_session_details.end_date_time)
        ) < 10
      )
        toast.error("Please select atleast 10 mins duration");
      else {
        if (
          !dataToUpdate.networking_lounge_sponsoring_brands ||
          isEmpty(dataToUpdate.networking_lounge_sponsoring_brands) ||
          checkForValidSponsors?.length < 1
        )
          toast.error("Please add a Sponsoring Brand");
        else if (
          dataToUpdate.networking_lounge_session_details
            .is_speed_dating_enabled &&
          dataToUpdate.networking_lounge_session_details.mins_per_shuffle === 0
        ) {
          toast.error(
            "Please add minutes per shuffle as speed dating is enabled"
          );
        } else if (
          dataToUpdate.networking_lounge_session_details
            .is_waterfall_intros_enabled &&
          dataToUpdate.networking_lounge_session_details.seconds_per_intro === 0
        ) {
          toast.error(
            "Please add seconds per intro as waterfall intros is enabled"
          );
        } else {
          if (inviteesToggle) {
            if (
              !invitation_setup.is_scheduled &&
              !invitation_setup.send_invitation_now
            ) {
              toast.error("Please select time period for sending invitations");
            } else createUpdateAPI(dataToUpdate);
          } else createUpdateAPI(dataToUpdate);
        }
      }
    } else {
      toast.error(dialogs.missingFields);
      validator.current.showMessages();
      let abd = validator.current.getErrorMessages();

      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const handleSubmitAddBrand = () => {
    if (validatorAddBrand.current.allValid()) {
      //add the data to brands drag and drop table
      //and reset the add new table data

      let cloned_add_new_brand = cloneDeep(add_new_brand);

      let cloned_networking_lounge_sponsoring_brands = cloneDeep(
        networking_lounge_sponsoring_brands?.filter(
          (item) => item?.is_delete !== true
        )
      ); //After deleting the brand new brand was not getting added so added this condition of filter
      cloned_add_new_brand.sponsorship_id = "";
      cloned_add_new_brand.networking_session_id = "";
      cloned_add_new_brand.networking_configuration_id = "";
      cloned_add_new_brand.is_delete = false;

      if (
        !cloned_networking_lounge_sponsoring_brands ||
        isEmpty(cloned_networking_lounge_sponsoring_brands)
      ) {
        cloned_add_new_brand.sequence = 1;
        cloned_networking_lounge_sponsoring_brands.push(cloned_add_new_brand);
        set_networking_lounge_sponsoring_brands(
          cloned_networking_lounge_sponsoring_brands
        );
      } else {
        let tempArr = cloned_networking_lounge_sponsoring_brands;
        cloned_networking_lounge_sponsoring_brands = [];

        tempArr.forEach((ele) => {
          if (ele.is_delete === false)
            cloned_networking_lounge_sponsoring_brands.push(
              cloned_add_new_brand
            );
          else cloned_networking_lounge_sponsoring_brands.push(ele);
        });
        set_networking_lounge_sponsoring_brands(
          cloned_networking_lounge_sponsoring_brands
        );
        // }
      }

      set_add_new_brand(defaultBlankNewBrand);
      setSelectedZoneData(defaultFlags);
    } else {
      validatorAddBrand.current.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const onChangeFields = (val, key, parentKey) => {
    if (parentKey === "networking_lounge_session_details")
      if (
        (key === "is_waterfall_intros_enabled" ||
          key === "is_speed_dating_enabled") &&
        val === false
      ) {
        let newValues;
        if (key === "is_waterfall_intros_enabled")
          newValues = { seconds_per_intro: 0 };
        else newValues = { mins_per_shuffle: 0 };
        set_networking_lounge_session_details({
          ...networking_lounge_session_details,
          ...newValues,
          [key]: val,
        });
      } else {
        set_networking_lounge_session_details({
          ...networking_lounge_session_details,
          [key]: val,
        });
      }

    if (parentKey === "add_new_brand")
      set_add_new_brand({ ...add_new_brand, [key]: val });

    if (parentKey === "invitation_setup") {
      let cloned_invitation_setup = cloneDeep(invitation_setup);
      if (key === "is_scheduled") {
        cloned_invitation_setup.is_scheduled = val;
        cloned_invitation_setup.send_invitation_now = false;
        set_invitation_setup(cloned_invitation_setup);
      } else if (key === "send_invitation_now") {
        cloned_invitation_setup.is_scheduled = false;
        cloned_invitation_setup.send_invitation_now = val;
        set_invitation_setup(cloned_invitation_setup);
      } else {
        set_invitation_setup({ ...cloned_invitation_setup, [key]: val });
      }
    }
  };

  const renderSwitches = (key, parentKey, checked) => {
    if (parentKey === "invitation_setup")
      return (
        <Switch
          disabled={!inviteesToggle ? true : false}
          checked={checked}
          onChange={(val) => onChangeFields(val, key, parentKey)}
          className="adminSatellite_switch"
        />
      );
    else
      return (
        <Switch
          checked={checked}
          onChange={(val) => onChangeFields(val, key, parentKey)}
          className="adminSatellite_switch"
        />
      );
  };

  const networking_lounge_sponsoring_brands_columns = [
    {
      title: "Brand Title",
      dataIndex: "brand_title",
      width: "100%",
      render: (_, ele, indexToDelete) => {
        if (ele && ele.brand_title && !ele.is_delete)
          return (
            <div className="d-flex flex-column">
              <Row className="adminSatellite_section_2_brand_row1">
                <Col span={23}>
                  <p className="adminSatellite_section_2_brand_name">
                    {ele.brand_title}
                  </p>
                </Col>
                <Col span={1}>
                  <img
                    style={{ marginRight: "10.79px" }}
                    className="adminNetworking_delete_icon"
                    src={blackbin}
                    onClick={() => {
                      let clonedDataSource = cloneDeep(
                        networking_lounge_sponsoring_brands
                      );
                      // clonedDataSource.splice(indexToDelete, 1);
                      clonedDataSource[indexToDelete].is_delete = true;
                      clonedDataSource[indexToDelete].sequence = null;

                      let finalData = [];
                      clonedDataSource &&
                        clonedDataSource.forEach((ele, index) => {
                          if (!ele.is_delete)
                            finalData.push({ ...ele, sequence: index });
                        });
                      //amandeep
                      finalData.push(clonedDataSource[indexToDelete]);

                      set_networking_lounge_sponsoring_brands(finalData);
                    }}
                  />
                </Col>
              </Row>
            </div>
          );
        else return null;
      },
    },
  ];

  const handleInviteesTableChange = (pagination, filters, sorter) => {
    if (sorter) {
      const sortedData = sortData(sorter, invitationTableData);
      setInvitationTableData(sortedData);
    }
  };

  const invitation_table_columns = [
    {
      title: "Date/Time",
      key: "date_time_to_invite",
      dataIndex: "date_time_to_invite",
      width: "25%",
      align: "center",
      sorter: (a, b) =>
        moment(a.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss") -
        moment(b.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss"),
      render: (_, record) => {
        return moment(record.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "User Name",
      key: "user_name",
      dataIndex: "user_name",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return record.first_name + " " + record.last_name;
      },
    },
    {
      title: "User Type",
      key: "user_type",
      dataIndex: "user_type",
      width: "25%",
      align: "center",
      sorter: (a, b) => a.user_type.localeCompare(b.user_type),
    },
    {
      title: "Invite Status",
      key: "rsvp_type",
      dataIndex: "rsvp_type",
      width: "25%",
      align: "center",
      render: (_, record) => {
        return getStatusButton(record);
      },
      sorter: (a, b) => b.rsvp_type.localeCompare(a.rsvp_type),
    },
  ];

  const getStatusButton = (ele) => {
    if (ele.rsvp_type === "PENDING")
      return (
        <Button className="adminSatellite_section_2_pending">Pending</Button>
      );
    else if (ele.rsvp_type === "YES")
      return (
        <Button className="adminSatellite_section_2_accepted">Accepted</Button>
      );
    else if (ele.rsvp_type === "NO")
      return (
        <Button className="adminSatellite_section_2_reject">Rejected</Button>
      );
    else return null;
  };

  const openInviteesModalAndApiCall = async (ele, isFromSingle) => {
    setInvitationModal({
      isOpen: true,
      data: ele,
      isLoader: true,
    });
    let params = {
      networking_configuration_id: ele.networking_configuration_id,
      networking_session_id: ele.networking_session_id,
    };
    if (isFromSingle)
      params.networking_lounge_invitation_setup_id =
        ele.networking_lounge_invitation_setup_id;

    let invitationData = await getNetworkInvitationDetails(params);
    setInvitationTableData(invitationData);
  };

  return (
    <Modal
      title={props.title}
      visible={true}
      onOk={() => {
        if (!loading) props.onCloseModal(false);
      }}
      onCancel={() => {
        if (!loading) props.onCloseModal(false);
      }}
      width={950}
      footer={[
        <Button
          disabled={loading}
          className="px-3 button-pink rounded"
          key="cancel"
          style={{ width: "11%" }}
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </Button>,
        <Button
          disabled={loading}
          className="px-3 rounded"
          key="save"
          type="primary"
          style={{ width: "25%" }}
          onClick={() => {
            validator.current.purgeFields();
            handleSubmit();
          }}
        >
          Save Changes & Send Invitations
        </Button>,
      ]}
    >
      {loading ? (
        <CommonLoader />
      ) : (
        <>
          <div className="adminNetworking_section_1_form">
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Active</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_active",
                  "networking_lounge_session_details",
                  networking_lounge_session_details.is_active
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>
                  Start & End Time
                  <RedAsterick />
                </p>
              </Col>
              <Col span={8}>
                <TimePicker
                  allowClear={false}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabled={
                    props.sessionData &&
                    props.sessionData.networking_session_id &&
                    props.sessionData.userType === "BRAND ADMIN"
                      ? true
                      : false
                  }
                  name="start_date_time"
                  id="start_date_time"
                  format={format}
                  value={networking_lounge_session_details?.start_date_time}
                  className="expohall_timepicker"
                  style={{ width: "100%" }}
                  onSelect={(val) => {
                    if (
                      moment(
                        networking_lounge_session_details?.end_date_time,
                        "HH:mm"
                      ) > moment(val)
                    ) {
                      onChangeFields(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "start_date_time",
                        "networking_lounge_session_details"
                      );
                    } else {
                      toast.error(dialogs?.inCorrectTimeError);
                    }
                  }}
                  onChange={(val) => {
                    if (
                      moment(
                        networking_lounge_session_details?.end_date_time,
                        "HH:mm"
                      ) > moment(val)
                    ) {
                      onChangeFields(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "start_date_time",
                        "networking_lounge_session_details"
                      );
                    } else {
                      toast.error(dialogs?.inCorrectTimeError);
                    }
                  }}
                  onBlur={() =>
                    validator.current.showMessageFor("start_date_time")
                  }
                />
                {validator.current.message(
                  "",
                  networking_lounge_session_details.start_date_time,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>

              <Col span={8} offset={2}>
                <TimePicker
                  allowClear={false}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabled={
                    props.sessionData &&
                    props.sessionData.networking_session_id &&
                    props.sessionData.userType === "BRAND ADMIN"
                      ? true
                      : false
                  }
                  disabledTime={() =>
                    getDisabledTime(
                      networking_lounge_session_details?.start_date_time
                    )
                  }
                  name="end_date_time"
                  id="end_date_time"
                  format={format}
                  value={networking_lounge_session_details?.end_date_time}
                  className="expohall_timepicker"
                  style={{ width: "100%" }}
                  onSelect={(val) => {
                    if (
                      moment(val) >
                      moment(
                        networking_lounge_session_details?.start_date_time,
                        "HH:mm"
                      )
                    ) {
                      onChangeFields(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "end_date_time",
                        "networking_lounge_session_details"
                      );
                    } else {
                      toast.error(dialogs?.inCorrectTimeError);
                    }
                  }}
                  onChange={(val) => {
                    if (
                      moment(val) >
                      moment(
                        networking_lounge_session_details?.start_date_time,
                        "HH:mm"
                      )
                    ) {
                      onChangeFields(
                        moment(moment(val).format("HH:mm"), TIME_FORMAT),
                        "end_date_time",
                        "networking_lounge_session_details"
                      );
                    } else {
                      toast.error(dialogs?.inCorrectTimeError);
                    }
                  }}
                  onBlur={() =>
                    validator.current.showMessageFor("end_date_time")
                  }
                />
                {validator.current.message(
                  "",
                  networking_lounge_session_details.end_date_time,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>
                  Title
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <Input
                  name="session_title"
                  className="adminNetworking_inputTitle"
                  placeholder="Enter a Title here"
                  value={networking_lounge_session_details.session_title}
                  onChange={(e) =>
                    onChangeFields(
                      e.target.value,
                      "session_title",
                      "networking_lounge_session_details"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor(
                      "networking_lounge_session_details"
                    )
                  }
                  minLength={1}
                  maxLength={30}
                />
                {validator.current.message(
                  "Title",
                  networking_lounge_session_details.session_title,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p style={{ marginTop: "9px" }}>
                  Description
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <TextArea
                  name="session_description"
                  value={networking_lounge_session_details.session_description}
                  className="adminNetworking_inputDescription"
                  placeholder="Enter a description here"
                  rows={4}
                  onChange={(e) =>
                    onChangeFields(
                      e.target.value,
                      "session_description",
                      "networking_lounge_session_details"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("session_description")
                  }
                  minLength={1}
                  maxLength={200}
                />
                {validator.current.message(
                  "Description",
                  networking_lounge_session_details.session_description,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Seats per table</p>
              </Col>
              <Col span={18}>
                <InputNumber
                  name="seats_per_table"
                  min={1}
                  max={6}
                  maxLength={1}
                  defaultValue={
                    networking_lounge_session_details.seats_per_table
                  }
                  onChange={(val) =>
                    onChangeFields(
                      val,
                      "seats_per_table",
                      "networking_lounge_session_details"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("seats_per_table")
                  }
                  className="adminNetworking_section_1_inputSeat"
                />
                {validator.current.message(
                  "Seats per table",
                  networking_lounge_session_details.seats_per_table,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Speed Dating</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_speed_dating_enabled",
                  "networking_lounge_session_details",
                  networking_lounge_session_details.is_speed_dating_enabled
                )}
              </Col>
            </Row>
            {networking_lounge_session_details.is_speed_dating_enabled ? (
              <Row className="modal_row">
                <Col span={6} className="modal_field_label">
                  <p>Mins Per Shuffle</p>
                </Col>
                <Col span={18}>
                  <InputNumber
                    name="mins_per_shuffle"
                    min={1}
                    max={999}
                    value={networking_lounge_session_details.mins_per_shuffle}
                    className="adminNetworking_section_1_inputSeat"
                    onChange={(val) =>
                      onChangeFields(
                        val,
                        "mins_per_shuffle",
                        "networking_lounge_session_details"
                      )
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("mins_per_shuffle")
                    }
                  />
                  {validator.current.message(
                    "Mins Per Shuffle",
                    networking_lounge_session_details.mins_per_shuffle,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
            ) : null}
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Waterfall Intros</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_waterfall_intros_enabled",
                  "networking_lounge_session_details",
                  networking_lounge_session_details.is_waterfall_intros_enabled
                )}
              </Col>
            </Row>
            {networking_lounge_session_details.is_waterfall_intros_enabled ? (
              <Row className="modal_row">
                <Col span={6} className="modal_field_label">
                  <p>Seconds Per Intro</p>
                </Col>
                <Col span={18}>
                  <InputNumber
                    name="seconds_per_intro"
                    min={0}
                    max={999}
                    value={networking_lounge_session_details.seconds_per_intro}
                    onChange={(val) =>
                      onChangeFields(
                        val,
                        "seconds_per_intro",
                        "networking_lounge_session_details"
                      )
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("seconds_per_intro")
                    }
                    className="adminNetworking_section_1_inputSeat"
                  />
                  {validator.current.message(
                    "Mins Per Shuffle",
                    networking_lounge_session_details.seconds_per_intro,
                    "required",
                    {
                      className: "text-danger py-1",
                    }
                  )}
                </Col>
              </Row>
            ) : null}

            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Download iCal</p>
              </Col>
              <Col span={6} style={{ alignSelf: "center" }}>
                {renderSwitches(
                  "is_iCal_download_enabled",
                  "networking_lounge_session_details",
                  networking_lounge_session_details.is_iCal_download_enabled
                )}
              </Col>
            </Row>
          </div>
          <div className="adminNetworking_section_2_form">
            <Row>
              <Col span={24}>
                <h5 className="adminNetworking_section_2_title fs-pink">
                  Sponsoring Brand
                  <RedAsterick />
                </h5>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="adminNetworking_section_2_info">
                  NB: The brands of speakers will automatically be added to the
                  list below, you can remove them (
                  <img className="adminNetworking_delete_icon" src={blackbin} />
                  ) or add more brands here.
                </p>
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col span={6} className="modal_field_label">
                <p>Assigned Brands</p>
              </Col>
              <Col span={18} className="adminNetworking_fields">
                {networking_lounge_sponsoring_brands &&
                !isEmpty(networking_lounge_sponsoring_brands) ? (
                  <Table
                    columns={networking_lounge_sponsoring_brands_columns}
                    pagination={false}
                    dataSource={networking_lounge_sponsoring_brands}
                    showHeader={false}
                    bordered={false}
                    rowClassName={"brand_row"}
                    scroll={{ y: 240 }}
                  />
                ) : (
                  <Empty description="No Data Found" />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={8} className="modal_field_label">
                <h6 className="addBrandTitle">Add a Sponsor Brand</h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Brand</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="brand_id"
                  optionFilterProp="children"
                  placeholder="Select Brand"
                  className="w-100 fs-bold-14"
                  value={
                    add_new_brand.brand_id ? add_new_brand.brand_id : undefined
                  }
                  onChange={(e) => {
                    let cloned_add_new_brand = add_new_brand;
                    cloned_add_new_brand.brand_id = e;
                    let itemSelected = brandsCollection.find(
                      (item) => item.brand_id === e
                    );
                    cloned_add_new_brand.brand_title = itemSelected.brand_title;
                    cloned_add_new_brand.zone_id = undefined;

                    cloned_add_new_brand.is_chat_enabled = false;
                    cloned_add_new_brand.is_demo_room_enabled = false;
                    cloned_add_new_brand.is_assets_enabled = false;
                    cloned_add_new_brand.is_booth_link_enabled = true;
                    cloned_add_new_brand.is_video_call_enabled = false;

                    set_add_new_brand(cloned_add_new_brand);
                    setSelectedZoneData(defaultFlags);
                    dispatch(getZone({ brand_id: e, is_active: true }));
                  }}
                  onBlur={() =>
                    validatorAddBrand.current.showMessageFor("brand_id")
                  }
                  listHeight={120}
                  listItemHeight={4}
                >
                  {brandsCollection &&
                    brandsCollection.map((item, i) => {
                      return (
                        <Option
                          key={item.brand_id + "_brand"}
                          value={item.brand_id}
                        >
                          {item.brand_title}
                        </Option>
                      );
                    })}
                </Select>
                {validatorAddBrand.current.message(
                  "Brand Name",
                  add_new_brand.brand_id,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Zone</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="zone_id"
                  optionFilterProp="children"
                  className="w-100 fs-bold-14"
                  placeholder="Select Zone"
                  value={
                    add_new_brand.zone_id ? add_new_brand.zone_id : undefined
                  }
                  onChange={async (val) => {
                    const res = await getZoneDetails({ zone_id: val });

                    if (res?.zone_id) {
                      const fiveFlags = {
                        is_chat_enabled: res.is_text_chat_enable,
                        is_demo_room_enabled: res.is_video_demo_room_enable,
                        is_assets_enabled:
                          res.zones_assets_id?.length > 0 ? true : false,
                        is_booth_link_enabled: res.is_on_booth_leads_enable,
                        is_video_call_enabled:
                          res.is_one_to_one_video_calls_enable,
                      };
                      setSelectedZoneData(fiveFlags);
                      set_add_new_brand({
                        ...add_new_brand,
                        ...fiveFlags,
                        zone_id: val,
                      });
                    } else onChangeFields(val, "zone_id", "add_new_brand");
                  }}
                  onBlur={() =>
                    validatorAddBrand.current.showMessageFor("zone_id")
                  }
                  listHeight={120}
                  listItemHeight={4}
                >
                  {zoneRow &&
                    zoneRow.map((item, i) => {
                      return (
                        <Option
                          key={"add_new_brand" + item.zone_id}
                          value={item.zone_id}
                        >
                          {item.zone_title}
                        </Option>
                      );
                    })}
                </Select>
                {validatorAddBrand.current.message(
                  "Zone",
                  add_new_brand.zone_id,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Chat</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={!selectedZoneData.is_chat_enabled ? true : false}
                  checked={add_new_brand.is_chat_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_chat_enabled,
                      "is_chat_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Demo Room</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={
                    !selectedZoneData.is_demo_room_enabled ? true : false
                  }
                  checked={add_new_brand.is_demo_room_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_demo_room_enabled,
                      "is_demo_room_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Asset/s</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={!selectedZoneData.is_assets_enabled ? true : false}
                  checked={add_new_brand.is_assets_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_assets_enabled,
                      "is_assets_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Booth Link</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={
                    !selectedZoneData.is_booth_link_enabled ? true : false
                  }
                  checked={add_new_brand.is_booth_link_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_booth_link_enabled,
                      "is_booth_link_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Video Call</p>
              </Col>
              <Col span={18}>
                <Switch
                  disabled={
                    !selectedZoneData.is_video_call_enabled ? true : false
                  }
                  checked={add_new_brand.is_video_call_enabled}
                  onChange={() =>
                    onChangeFields(
                      !add_new_brand.is_video_call_enabled,
                      "is_video_call_enabled",
                      "add_new_brand"
                    )
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={6} />
              <Col span={6}>
                <Button
                  type="primary"
                  className="adminSatellite_section_2_savechanges"
                  size={"large"}
                  onClick={handleSubmitAddBrand}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </div>
          <div className="adminSatellite_section_7_form">
            <Row>
              <Col span={24}>
                <h5 className="adminSatellite_section_7_title fs-pink">
                  Restrict Access
                </h5>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={24}>
                <p className="adminSatellite_section_3_info">
                  NB: Configure who can view / access your event.
                  <br /> Attendees with any black list attributes will not be
                  able to to see the event on any calendars or access the event.
                  <br /> If an attendee/attribute is on the White List they will
                  be able to see/attaned the event regardless of any black list
                  attributes.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label">
                <p className="pt-2">Agents</p>
              </Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  Allow List Users
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (selected_restricted_users.length !== 0) {
                        handleAllowAllBlockAll("restricted_users", "allowAll");
                      }
                    }}
                  >
                    Allow All
                  </Button>
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle ">
                  Block List Users
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (restricted_users.length !== 0) {
                        handleAllowAllBlockAll("restricted_users", "blockAll");
                      }
                    }}
                  >
                    Block All
                  </Button>
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div
                  id="restricted_users_div"
                  className="adminSatellite_section_5_taggedagents_container"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search Agents"
                    className="border border-gray fs-bold-14"
                    onChange={(val) =>
                      handleDDLChange(val, "restricted_users", "left")
                    }
                    getPopupContainer={() =>
                      document.getElementById("restricted_users_div")
                    }
                    open={openUserList}
                    onFocus={() => setOpenUsersList(true)}
                    onBlur={() => setOpenUsersList(false)}
                    onSearch={() => setOpenUsersList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="All Agents"
                  >
                    {restricted_users?.map((data) => {
                      return (
                        <Option
                          key={"restricted_users_" + data.user_id}
                          value={data.user_id}
                          className="dropdown_options"
                        >
                          {data.first_name + " " + data.last_name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_users_div"
                  className="adminSatellite_section_5_taggedagents_container"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search Tagged Agents"
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("selected_restricted_users_div")
                    }
                    open={openUserList}
                    onFocus={() => setOpenUsersList(true)}
                    onBlur={() => setOpenUsersList(false)}
                    onSearch={() => setOpenUsersList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="Tagged Agents"
                  >
                    {selected_restricted_users?.map((data) => {
                      return (
                        <Option
                          key={"restricted_users_selected_" + data.user_id}
                          value={data.user_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.first_name + " " + data.last_name}
                            </span>

                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.user_id,
                                    "restricted_users",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}></Col>
              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label">
                <p className="pt-2">Brand List</p>
              </Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  Allow List Brands
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (selected_restricted_brands.length !== 0) {
                        handleAllowAllBlockAll("restricted_brands", "allowAll");
                      }
                    }}
                  >
                    Allow All
                  </Button>
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Block List Brands
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (restricted_brands.length !== 0) {
                        handleAllowAllBlockAll("restricted_brands", "blockAll");
                      }
                    }}
                  >
                    Block All
                  </Button>
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div id="restricted_brands_div" className="dropdown_container">
                  <Select
                    id="restricted_brands"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) => {
                      const filteredData =
                        networking_lounge_sponsoring_brands &&
                        networking_lounge_sponsoring_brands.filter(
                          (item) => item.brand_id === value
                        );
                      if (filteredData && !isEmpty(filteredData))
                        toast.error(
                          "This brand cannot be blacklisted as it is in hosting brands"
                        );
                      else handleDDLChange(value, "restricted_brands", "left");
                    }}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_brands_div")
                    }
                    open={openBrandsList}
                    onFocus={() => setOpenBrandsList(true)}
                    onBlur={() => setOpenBrandsList(false)}
                    onSearch={() => setOpenBrandsList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="All Brands"
                  >
                    {restricted_brands &&
                      restricted_brands.map((data) => (
                        <Option
                          key={"restricted_brands_" + data.brand_id}
                          value={data.brand_id}
                          className="dropdown_options"
                        >
                          {data.brand_title}
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_brands_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_brands"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_brands_div")
                    }
                    open={openBrandsList}
                    onFocus={() => setOpenBrandsList(true)}
                    onBlur={() => setOpenBrandsList(false)}
                    onSearch={() => setOpenBrandsList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="All Brands"
                  >
                    {selected_restricted_brands &&
                      selected_restricted_brands.map((data) => (
                        <Option
                          key={"selected_restricted_brands_" + data.brand_id}
                          value={data.brand_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.brand_title}
                            </span>

                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.brand_id,
                                    "restricted_brands",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}></Col>
              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label">
                <p className="pt-2">Country List</p>
              </Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  Allow List Countries
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (selected_restricted_countries.length !== 0) {
                        handleAllowAllBlockAll(
                          "restricted_countries",
                          "allowAll"
                        );
                      }
                    }}
                  >
                    Allow All
                  </Button>
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Block List Countries
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (restricted_countries.length !== 0) {
                        handleAllowAllBlockAll(
                          "restricted_countries",
                          "blockAll"
                        );
                      }
                    }}
                  >
                    Block All
                  </Button>
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div
                  id="restricted_countries_div"
                  className="dropdown_container"
                >
                  <Select
                    id="restricted_countries"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) =>
                      handleDDLChange(value, "restricted_countries", "left")
                    }
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_countries_div")
                    }
                    open={openCountriesList}
                    onFocus={() => setOpenCountriesList(true)}
                    onBlur={() => setOpenCountriesList(false)}
                    onSearch={() => setOpenCountriesList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="All Countries"
                  >
                    {restricted_countries &&
                      restricted_countries.map((data) => (
                        <Option
                          key={"restricted_countries_" + data.country_id}
                          value={data.country_id}
                          className="dropdown_options"
                        >
                          {data.country}
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_countries_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_countries"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById(
                        "selected_restricted_countries_div"
                      )
                    }
                    open={openCountriesList}
                    onFocus={() => setOpenCountriesList(true)}
                    onBlur={() => setOpenCountriesList(false)}
                    onSearch={() => setOpenCountriesList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="Tagged Countries"
                  >
                    {selected_restricted_countries &&
                      selected_restricted_countries.map((data) => (
                        <Option
                          key={
                            "selected_restricted_countries_" + data.country_id
                          }
                          value={data.country_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.country}
                            </span>

                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.country_id,
                                    "restricted_countries",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}></Col>

              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label">
                <p className="pt-2">Job Titles List</p>
              </Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  Allow List Job Titles
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (selected_restricted_job_titles.length !== 0) {
                        handleAllowAllBlockAll(
                          "restricted_job_titles",
                          "allowAll"
                        );
                      }
                    }}
                  >
                    Allow All
                  </Button>
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Block List Job Titles
                  <Button
                    className="ms-2 allow_block_btns"
                    onClick={() => {
                      if (restricted_job_titles.length !== 0) {
                        handleAllowAllBlockAll(
                          "restricted_job_titles",
                          "blockAll"
                        );
                      }
                    }}
                  >
                    Block All
                  </Button>
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div
                  id="restricted_job_titles_div"
                  className="dropdown_container"
                >
                  <Select
                    id="restricted_job_titles"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) =>
                      handleDDLChange(value, "restricted_job_titles", "left")
                    }
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_job_titles_div")
                    }
                    open={openJobTitlesList}
                    onFocus={() => setOpenJobTitlesList(true)}
                    onBlur={() => setOpenJobTitlesList(false)}
                    onSearch={() => setOpenJobTitlesList(true)}
                    listHeight={200}
                    listItemHeight={6}
                    value="All Job Titles"
                  >
                    {restricted_job_titles &&
                      restricted_job_titles.map((data) => (
                        <Option
                          key={"restricted_job_titles_" + data.job_title_id}
                          value={data.job_title_id}
                          className="dropdown_options"
                        >
                          {data.job_title}
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_job_titles_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_job_titles"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById(
                        "selected_restricted_job_titles_div"
                      )
                    }
                    open={openJobTitlesList}
                    onFocus={() => setOpenJobTitlesList(true)}
                    onBlur={() => setOpenJobTitlesList(false)}
                    onSearch={() => setOpenJobTitlesList(true)}
                    listHeight={100}
                    listItemHeight={4}
                    value="All Job Titles"
                  >
                    {selected_restricted_job_titles &&
                      selected_restricted_job_titles.map((data) => (
                        <Option
                          key={
                            "selected_restricted_job_titles_" +
                            data.job_title_id
                          }
                          value={data.job_title_id}
                          className="dropdown_options"
                        >
                          <div className="d-flex justify-content-between">
                            <span className="dropdown_options_text">
                              {data.job_title}
                            </span>

                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.job_title_id,
                                    "restricted_job_titles",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </div>

          <div className="adminNetworking_section_4_form">
            <Row>
              <Col span={24}>
                <h5 className="adminNetworking_section_4_title">
                  Previous & Pending Invitees
                </h5>
              </Col>
            </Row>
            {props.sessionData && props.sessionData.networking_session_id ? (
              <>
                {previous_pending_invites ? (
                  <Row className="modal_row">
                    <Col span={6} />
                    <Col span={9} className="adminSatellite_eyeViewBtn">
                      <Button
                        type="primary"
                        className="adminSatellite_section_8_previousInvitedBtn"
                        size={"large"}
                        onClick={() =>
                          openInviteesModalAndApiCall(
                            previous_pending_invites[0],
                            false
                          )
                        }
                      >
                        View List of Previously Invited
                      </Button>
                    </Col>
                  </Row>
                ) : null}

                <Row className="modal_row">
                  <Col span={6} />
                  <Col span={9}>
                    <h6 className="adminSatellite_section_8_heading">
                      Currently Scheduled Invitations
                    </h6>
                  </Col>
                </Row>
                {networkSessionAllData && previous_pending_invites ? (
                  previous_pending_invites.map((ele, index) => {
                    if (ele && !ele.is_delete)
                      return (
                        <Row className="modal_row">
                          <Col span={6} className="modal_field_label">
                            Date & Time
                          </Col>
                          <Col span={7} className="invite_center">
                            {ele.date_time_to_invite
                              ? moment(ele.date_time_to_invite).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </Col>
                          <Col span={9} offset={2}>
                            <Button
                              type="primary"
                              className="adminSatellite_section_8_viewListBtn"
                              size={"large"}
                              loading={invitationModal.isLoader}
                              onClick={async () =>
                                openInviteesModalAndApiCall(ele, true)
                              }
                            >
                              View List
                            </Button>
                            <img
                              style={{ marginLeft: "15px" }}
                              src={blackbin}
                              onClick={() => {
                                let clonedDeleteIds = cloneDeep(
                                  delete_scheduled_invitations
                                );
                                clonedDeleteIds.push(
                                  ele.networking_lounge_invitation_setup_id
                                );
                                set_delete_scheduled_invitations(
                                  clonedDeleteIds
                                );

                                let clonedData = cloneDeep(
                                  previous_pending_invites
                                );
                                clonedData[index].is_delete = true;
                                set_previous_pending_invites(clonedData);
                              }}
                            />
                          </Col>
                        </Row>
                      );
                  })
                ) : (
                  <Empty description={"No Scheduled Invitations"} />
                )}
              </>
            ) : (
              <Empty description="No invitees" />
            )}
          </div>
          <Modal
            title={<div className="invite_center">{"Previously Invited"}</div>}
            visible={invitationModal.isOpen}
            width={650}
            onOk={() =>
              setInvitationModal({ isOpen: false, data: null, isLoader: false })
            }
            onCancel={() =>
              setInvitationModal({ isOpen: false, data: null, isLoader: false })
            }
            footer={null}
          >
            <div className="px-3">
              <Table
                columns={invitation_table_columns}
                pagination={false}
                dataSource={invitationTableData}
                bordered={false}
                onChange={handleInviteesTableChange}
                scroll={{ y: 240 }}
                rowKey={(obj) => obj.rsvp_detail_id + "_invi"}
              />
            </div>
          </Modal>

          <Row className="ms-4">
            <Col span={24} className="d-flex">
              <h5 className="ms-1 fs-pink mt-4">
                New Invitees
                <Switch
                  className="ms-3"
                  onChange={(val) => {
                    setInviteesToggle(!inviteesToggle);
                    if (val === false) {
                      set_invited_brands([]);
                      set_invited_countries([]);
                      set_invited_job_titles([]);
                      set_invited_user_types([]);
                      set_invited_zones([]);
                      set_invitation_setup({
                        is_iCal_download_enabled: false,
                        is_reinvite_enabled: false,
                        send_invitation_now: false,

                        is_scheduled: false,
                      });
                    }
                  }}
                ></Switch>
              </h5>
            </Col>
          </Row>

          {inviteesToggle ? (
            <>
              <div
                className="adminNetworking_section_5_form"
                style={{ borderBottom: "1px solid #eae4f2" }}
              >
                <Row>
                  <Col span={6}></Col>
                  <Col span={9}>
                    <h6 className="adminNetworking_section_5_heading">
                      Filter Attendees
                    </h6>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>Brand</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      disabled={!inviteesToggle ? true : false}
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Select Brands"
                      value={invited_brands}
                      onChange={(val) => set_invited_brands(val)}
                    >
                      {brandCollectionForInvitees &&
                        brandCollectionForInvitees.map((item, i) => {
                          return (
                            <Option
                              key={"invited_brands_" + item.brand_id}
                              value={item.brand_id}
                            >
                              {item.brand_title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>Zone</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      disabled={!inviteesToggle ? true : false}
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Select Zones"
                      value={invited_zones}
                      onChange={(val) => set_invited_zones(val)}
                    >
                      {zoneCollectionForInvitees &&
                        zoneCollectionForInvitees.map((item, i) => {
                          return (
                            <Option
                              key={"invited_zones" + item.zone_id}
                              value={item.zone_id}
                            >
                              {item.zone_title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>User Type</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      style={{ width: "100%" }}
                      mode="multiple"
                      disabled={!inviteesToggle ? true : false}
                      placeholder="Select User Types"
                      value={invited_user_types}
                      onChange={(val) => set_invited_user_types(val)}
                    >
                      <Option value="AGENT">AGENT</Option>
                      <Option value="DELEGATE">DELEGATE</Option>
                      <Option value="SPEAKER">SPEAKER</Option>
                    </Select>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>Country</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Select Countries"
                      disabled={!inviteesToggle ? true : false}
                      value={invited_countries}
                      onChange={(val) => set_invited_countries(val)}
                    >
                      {countryCollection &&
                        countryCollection.map((item, i) => {
                          return (
                            <Option
                              key={"invited_countries" + item.country_id}
                              value={item.country_id}
                            >
                              {item.country}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>Job Title</p>
                  </Col>
                  <Col span={18}>
                    <Select
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Select Job Titles"
                      value={invited_job_titles}
                      disabled={!inviteesToggle ? true : false}
                      onChange={(val) => set_invited_job_titles(val)}
                    >
                      {jobTitleCollection &&
                        jobTitleCollection.map((item, i) => {
                          return (
                            <Option
                              key={"invited_job_titles" + item.job_title_id}
                              value={item.job_title_id}
                            >
                              <span>{item.job_title}</span>
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
              </div>
              <div className="adminSatellite_section_10_form">
                <Row>
                  <Col span={24}>
                    <h5 className="section_title section_theme_title fs-pink">
                      Invitation Setup
                    </h5>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p>
                      Title
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={18}>
                    <Input
                      name="invitation_title"
                      disabled={!inviteesToggle ? true : false}
                      className="adminSatellite_inputTitle"
                      placeholder="Enter a Title here"
                      value={invitation_setup.invitation_title}
                      onChange={(e) =>
                        onChangeFields(
                          e.target.value,
                          "invitation_title",
                          "invitation_setup"
                        )
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("invitation_title")
                      }
                      minLength={1}
                      maxLength={100}
                    />
                    {validator.current.message(
                      "",
                      invitation_setup.invitation_title,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p style={{ alignSelf: "flex-start" }}>
                      Description
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={18}>
                    <TextArea
                      name="invitation_description"
                      disabled={!inviteesToggle ? true : false}
                      className="adminSatellite_inputDescription"
                      placeholder="Enter a description here"
                      rows={4}
                      value={invitation_setup.invitation_description}
                      onChange={(e) =>
                        onChangeFields(
                          e.target.value,
                          "invitation_description",
                          "invitation_setup"
                        )
                      }
                      onBlur={() =>
                        validator.current.showMessageFor(
                          "invitation_description"
                        )
                      }
                      minLength={0}
                      maxLength={200}
                    />
                    {validator.current.message(
                      "",
                      invitation_setup.invitation_description,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p className="fs-14">Attach iCal</p>
                  </Col>
                  <Col span={18}>
                    {renderSwitches(
                      "is_iCal_download_enabled",
                      "invitation_setup",
                      invitation_setup.is_iCal_download_enabled
                    )}
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p className="fs-14">Re-invite</p>
                  </Col>
                  <Col span={18}>
                    {renderSwitches(
                      "is_reinvite_enabled",
                      "invitation_setup",
                      invitation_setup.is_reinvite_enabled
                    )}
                    <span style={{ marginLeft: "30px" }}>
                      (if already invited)
                    </span>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={24}>
                    <h5 className="section_title section_black_title">
                      When to Send Invitation
                    </h5>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p className="fs-14">Scheduled</p>
                  </Col>
                  <Col span={18}>
                    {renderSwitches(
                      "is_scheduled",
                      "invitation_setup",
                      invitation_setup.is_scheduled
                    )}
                  </Col>
                </Row>
                {invitation_setup.is_scheduled ? (
                  <Row>
                    <Col span={6} className="modal_field_label">
                      <p>
                        Date & Time
                        <RedAsterick />
                      </p>
                    </Col>
                    <Col span={7}>
                      <DatePicker
                        disabled={!invitation_setup.is_scheduled}
                        suffixIcon={<CalenderGrey width="15" />}
                        id="invitation_setup_date_time_to_invite"
                        name="invitation_setup_date_time_to_invite"
                        showTime
                        className="w-100"
                        disabledDate={(d) =>
                          !d ||
                          d.isSameOrBefore(
                            invitation_setup?.date_time_to_invite
                          )
                        }
                        disabledTime={(d) =>
                          !d ||
                          d.isSameOrBefore(
                            invitation_setup?.date_time_to_invite
                          )
                        }
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        value={moment(invitation_setup?.date_time_to_invite)}
                        allowClear={false}
                        format={"DD/MM/YYYY HH:mm"}
                        onChange={(e) =>
                          onChangeFields(
                            moment(e).format("YYYY-MM-DD HH:mm:ss"),
                            "date_time_to_invite",
                            "invitation_setup"
                          )
                        }
                        onBlur={() =>
                          validator.current.showMessageFor(
                            "invitation_setup_date_time_to_invite"
                          )
                        }
                      />
                      {invitation_setup.is_scheduled &&
                        isEmpty(invitation_setup.date_time_to_invite) &&
                        validator.current.message(
                          "Date & Time",
                          invitation_setup?.date_time_to_invite,
                          "required",
                          { className: "text-danger py-1" }
                        )}
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col span={6} className="modal_field_label"></Col>
                  <Col span={7}>
                    <span className="adminSatellite_or">OR</span>
                  </Col>
                </Row>
                <Row className="modal_row">
                  <Col span={6} className="modal_field_label">
                    <p className="fs-14">Now</p>
                  </Col>
                  <Col span={18}>
                    {renderSwitches(
                      "send_invitation_now",
                      "invitation_setup",
                      invitation_setup.send_invitation_now
                    )}
                  </Col>
                </Row>
              </div>
            </>
          ) : null}
        </>
      )}
    </Modal>
  );
};
export default NetworkingSession;
