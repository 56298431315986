import React from "react";
import "./EntryGatePopup.css";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import AppContext, { ACTIONS } from "../Vista/src/contexts/AppContext";
import { setNetworkingEntryGateLocationNumber } from "../../redux/slice/vista";

const EntryGatePopup = () => {
  const { dispatch: AppDispatch } = useContext(AppContext);
  const dispatchRedux = useDispatch();

  const concourseData = useSelector(
    (state) => state?.vistaBrand?.concourseData
  );

  let netArr =
    concourseData?.event_settings?.event_settings_networking_lounge_details;

  return (
    <div className="entry_gate_bg">
      <div className="net_lounge_box_container">
        {netArr?.map((item, i) => {
          return (
            <>
              <div
                className="net_lounge_box"
                onClick={() => {
                  dispatchRedux(
                    setNetworkingEntryGateLocationNumber(`net_lounge_${i + 1}`)
                  );
                  AppDispatch({
                    type: ACTIONS.SET_VISTA_SRC,
                    value: {
                      src: item?.networking_url,
                      currentPosition: "networkingPublished",
                    },
                  });

                  AppDispatch({
                    type: ACTIONS.SET_ACTIVE_WINDOW,
                    value: "",
                  });
                }}
              >
                <span className="net_lounge_box_text">{`Networking Lounge ${
                  i + 1
                }`}</span>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default EntryGatePopup;
