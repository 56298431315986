import React from "react";

function RedAsterick() {
  return (
    <sup>
      <span className="text-danger" style={{ fontSize: "1.1rem" }}>
        *
      </span>
    </sup>
  );
}

export default RedAsterick;
