import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Modal } from "antd";
import { Input } from "antd";
import {
  SearchOutlined,
  ExclamationOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { User } from "../../components/svgJS/User";
import { SendRed } from "../../components/svgJS/SendRed";
import { Video } from "../../components/svgJS/Video";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  acceptVideoRequest,
  oneToOneVideoRequestAPI,
  videoRequestAPI,
} from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import MyProfile from "../MyProfile/MyProfile";
import TextArea from "antd/lib/input/TextArea";
import { getComentChatUser } from "../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import MessagesUI from "../MessagesUI/MessagesUi";
import {
  reloadSupportData,
  setHelpAndSupportData,
} from "../../redux/slice/headerData";

const EventAndTechnicalSupport = (props) => {
  const {
    isEventSupport,
    socket,
    event_support_flags,
    tech_support_flags,
    currentEventID,
  } = props;
  var CryptoJS = require("crypto-js");
  const [isActive, setActive] = useState({ key: 0, show: true });
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [subScribedChannels, setSubScribedChannels] = useState([]);
  const [currentChannelData, setCurrentChannelData] = useState({});
  const [loader, setLoader] = useState(false);
  const [userType, setUserType] = useState("");
  const [insideLoader, setInsideLoader] = useState(false);
  const [messgaeQuery, setmessgaeQuery] = useState("");
  const [userProfileModal, setUserProfileModal] = useState({
    isOpen: false,
    otherUser: { user_id: "" },
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery({ minWidth: 400 });
  const Tablet = useMediaQuery({ minWidth: 400, maxWidth: 1025 });
  const helpAndSupportNotificationData = useSelector(
    (state) => state?.header?.helpAndSupportData
  );

  const reloadSupportDataFlag = useSelector(
    (state) => state?.header?.reloadSupportDataFlag
  );
  console.log(reloadSupportDataFlag, "reloadSupportDataFlag");
  // console.log("helpAndSupportNotificationData", helpAndSupportNotificationData);

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);
    const cometRes = await getComentChatUser();
    setCometChatUser(cometRes);
    if (
      decryptedData &&
      (decryptedData === "BRAND ADMIN" || decryptedData === "AGENT")
    ) {
      setLoader(true);
      const responseChannels = await getChannels();
      if (responseChannels) {
        setLoader(false);
        console.log("responseChannels", responseChannels);
        setSubScribedChannels(responseChannels);
      } else setLoader(false);
    }

    setActive({ key: 2, show: true });
  }, []);

  useEffect(() => {
    if (reloadSupportDataFlag) {
      fetchChannelDetailsAPI(subScribedChannels[0]?.channel_id);
      setTimeout(() => {
        dispatch(
          reloadSupportData({
            flag: false,
          })
        );
      }, 500);
    }
  }, [reloadSupportDataFlag]);

  const acceptMessageRequest = async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/message/respond",
      ""
    );
    return response;
  };

  async function respondAPI(ele) {
    setInsideLoader(true);
    let response = await acceptMessageRequest({
      message_id: ele.message_id,
      user_id: ele.user_id,
      message_text: ele.message_text,
      support_type: isEventSupport
        ? "EVENT SUPPORT"
        : "EVENT TECHNICAL SUPPORT",
      user_type: ele?.user_type,
    });
    if (response?.data?.code === 200) {
      toast.success(response.data.message);
      setActive({ key: 2, show: true });
      fetchChannelDetailsAPI(subScribedChannels[0]?.channel_id);
    } else {
      toast.error(response.data.message);
      setInsideLoader(false);
    }
    dispatch(
      setHelpAndSupportData({
        supportType: isEventSupport
          ? "EVENT_HELP_SUPPORT_AGENT"
          : "TECHNICAL_HELP_SUPPORT_AGENT",
        userId: ele?.user_id,
      })
    );
  }

  const getChannels = async (params) => {
    const response = await ApiCall(
      "get",
      null,
      "/admin/events/helpandsupport/get/details",
      isEventSupport
        ? { support_type: "EVENT SUPPORT" }
        : { support_type: "EVENT TECHNICAL SUPPORT" }
    );
    if (response?.data?.code === 200) {
      return response.data.data;
    } else return [];
  };

  async function fetchChannelDetailsAPI(channel_id) {
    setInsideLoader(true);
    const response = await ApiCall(
      "GET",
      "",
      `/admin/channel/detail/get?channel_id=${channel_id}`,
      ""
    );
    if (response?.data?.code === 200) {
      if (response?.data?.message) {
        setCurrentChannelData(response.data.data);
      }
      setInsideLoader(false);
    } else {
      setInsideLoader(false);
    }
  }

  async function acceptVideoAPI(ele) {
    setInsideLoader(true);
    let response = await acceptVideoRequest({
      call_request_id: ele.call_request_id,
      user_id: ele.user_id,
      video_call_link: ele.video_call_link,
    });
    if (response?.data?.code === 200) {
      toast.success(response.data.message);
      fetchChannelDetailsAPI(subScribedChannels[0]?.channel_id);
      setTimeout(() => {
        window.open(ele.video_call_link, "_blank");
      }, 2000);
    } else {
      if (response?.data?.message) toast.error(response.data.message);
      setInsideLoader(false);
    }
  }
  {
    console.log(currentChannelData, "currentChannelData");
  }

  const renderZoneDellReception = (currentChannelData) => {
    if (insideLoader) return <CommonLoader />;
    if (currentChannelData)
      return (
        <div className="messages_content p-3 w-100">
          {currentChannelData &&
            currentChannelData.messageRequests &&
            currentChannelData.messageRequests.map((ele) => (
              <div
                key={"message_request_" + ele.message_id}
                className="messages_card mb-3 d-flex"
              >
                <div className="w-50 bg-grey p-2 border rounded">
                  <h6 className="fs-pink">{ele.user_name}</h6>
                  <p>{ele.message_text}</p>
                </div>

                <div className="my-auto ms-2">
                  <Button
                    onClick={() => {
                      setUserProfileModal({
                        isOpen: true,
                        otherUser: { user_id: ele.user_id },
                      });
                    }}
                    className="messages_profileBtn d-flex justify-content-evenly align-items-center w-100"
                  >
                    <User />
                    <span style={{ paddingLeft: "5px" }}>Profile</span>
                  </Button>
                  <Button
                    onClick={() => {
                      respondAPI(ele);
                    }}
                    className="messages_respondBtn mt-2 d-flex justify-content-evenly align-items-center w-100"
                  >
                    <SendRed />
                    <span style={{ paddingLeft: "5px" }}>Respond</span>
                  </Button>
                </div>
              </div>
            ))}

          {currentChannelData &&
            currentChannelData.videoRequests &&
            currentChannelData.videoRequests.map((ele) => (
              <div
                key={"call_request_" + ele.call_request_id}
                className="d-flex mt-3 w-100"
              >
                <div className="d-flex p-2 border border-danger rounded w-50">
                  <div className="messages_warning_icon  rounded-circle  border border-danger p-1 d-flex justify-content-center align-items-center">
                    <ExclamationOutlined />
                  </div>
                  <span className="fs-pink ms-2">
                    {ele.user_name + " has requested a video call."}
                  </span>
                </div>
                <Button
                  className="videoBtn my-auto ms-2 d-flex justify-content-evenly align-items-center"
                  onClick={() => acceptVideoAPI(ele)}
                >
                  <Video />
                  Accept
                </Button>
              </div>
            ))}
        </div>
      );
  };

  const renderIndividualMessage = () => {
    return (
      <div id="cometchat" style={{ width: "100%", height: "100%" }}>
        {/* {cometChatUser.isValidUser ? (
          <CometChatUI
            extraProps={{
              themeColor: "#c1a51e",
              userType: userType,
              dispatch: dispatch,
              history: history,
              socket: props.socket,
            }}
          />
        ) : null} */}
        <MessagesUI
          currentEventID={currentEventID}
          helpAndSupportNotificationData={helpAndSupportNotificationData}
          socket={socket}
          conversationType={
            isEventSupport ? "EVENT_SUPPORT" : "TECHNICAL_SUPPORT"
          }
        />
      </div>
    );
  };

  function clearProfileModal() {
    setUserProfileModal({ isOpen: false, otherUser: { user_id: "" } });
  }

  const createQuery = async () => {
    const res = await ApiCall(
      "POST",
      {
        support_type: isEventSupport
          ? "EVENT SUPPORT"
          : "EVENT TECHNICAL SUPPORT",
        message_text: messgaeQuery,
        event_id: currentEventID,
        user_type: userType,
      },
      "/admin/events/helpandsupport/create",
      ""
    );
    if (res?.data?.code === 200) {
      toast.success(res?.data?.message);
      setmessgaeQuery("");
    } else {
      toast.error("");
    }
  };

  function renderChatRequestUI() {
    return (
      <>
        <h5 className="fs-24">Chat Request</h5>
        <div className="d-flex mx-3 align-items-center">
          <TextArea
            className="mx-2"
            value={messgaeQuery}
            style={{
              height: "35px",
              width: "335px",
              overflow: "hidden",
            }}
            onChange={(e) => setmessgaeQuery(e.target.value)}
          />
          <Button onClick={() => createQuery()}>Send</Button>
        </div>
      </>
    );
  }

  function renderVideoRequestUI() {
    return (
      <Button
        onClick={async () => {
          const response = await oneToOneVideoRequestAPI({
            support_type: isEventSupport
              ? "EVENT SUPPORT"
              : "TECHINICAL SUPPORT",
            event_id: currentEventID,
          });
          if (response.data.code === 200) {
            // if (socket.connected) {
            //   let test112 = socket.emit(
            //     "delegateToAgentVideoCallRequest",
            //     JSON.stringify({
            //       channel_id: zoneData?.channel_id,
            //       // user_id: loginUserId,
            //       chat_id: localStorage.getItem("chat_uid"),
            //       // first_name: userName,
            //     })
            //   );
            toast.success(response.data.message);
          } else toast.error(response.data.message);
        }}
        className="ms_btn mt-1"
      >
        <VideoCameraOutlined />
        <span>Video Call Request</span>
      </Button>
    );
  }

  if (loader) return <CommonLoader />;
  else
    return (
      <>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {userType === "CUSTOMER" ||
            userType === "DELEGATE" ||
            userType === "SPEAKER" ? (
              <>
                {isEventSupport &&
                event_support_flags &&
                event_support_flags.is_chat_active
                  ? renderChatRequestUI()
                  : null}
                {isEventSupport &&
                event_support_flags &&
                event_support_flags.is_video_call_active
                  ? renderVideoRequestUI()
                  : null}
                {!isEventSupport &&
                tech_support_flags &&
                tech_support_flags.is_chat_active
                  ? renderChatRequestUI()
                  : null}
                {!isEventSupport &&
                tech_support_flags &&
                tech_support_flags.is_video_call_active
                  ? renderVideoRequestUI()
                  : null}
              </>
            ) : null}
          </div>
        </div>
        <div
          className={`messages_container_event_technical  w-100 mt-2 ${
            isMobile && !Tablet ? "d-flex" : ""
          }`}
        >
          {userProfileModal.isOpen ? (
            <Modal
              width={900}
              title="User Profile"
              visible={true}
              onOk={() => clearProfileModal()}
              onCancel={() => clearProfileModal()}
              className="user_profile_modal"
            >
              <MyProfile otherUser={userProfileModal.otherUser} />
            </Modal>
          ) : null}
          {userType === "BRAND ADMIN" || userType === "AGENT" ? (
            <>
              <div className="messages_sidebar">
                <div className="messages_search_wrapper mt-2 mx-auto">
                  <Input
                    size="medium"
                    className="w-100 rounded fs-bold-14"
                    allowClear
                    placeholder="Search Channels"
                    style={{ width: "70%" }}
                    prefix={<SearchOutlined />}
                  />
                </div>
                <div
                  className={
                    isActive.key === 1
                      ? "mt-4 activeBtn messageSidebar"
                      : "mt-4 messageSidebar"
                  }
                >
                  <h6
                    className="fs-pink mb-3 px-4 fs-14"
                    onClick={() => {
                      setActive({ key: 1, show: true });
                      console.log(
                        subScribedChannels[0]?.channel_id,
                        "channel id"
                      );
                      fetchChannelDetailsAPI(subScribedChannels[0]?.channel_id);
                    }}
                  >
                    Support Requests
                  </h6>
                </div>
                <div
                  className={
                    isActive.key === 2
                      ? "mt-4 activeBtn messageSidebar"
                      : "mt-4 messageSidebar"
                  }
                  onClick={() => setActive({ key: 2, show: true })}
                >
                  <h6 className="fs-pink mb-3 px-4 fs-14">
                    Individual messages
                  </h6>
                </div>
              </div>
              <div className="w-100 h-100">
                {isActive.key === 1
                  ? renderZoneDellReception(currentChannelData)
                  : isActive.key === 2
                  ? renderIndividualMessage()
                  : null}
              </div>
            </>
          ) : userType === "SUPER ADMIN" ||
            userType === "CUSTOMER" ||
            userType === "DELEGATE" ||
            userType === "SPEAKER" ? (
            renderIndividualMessage()
          ) : null}
        </div>
      </>
    );
};

export default EventAndTechnicalSupport;
