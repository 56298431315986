import React from "react";

export const ExpoHallSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.786"
        height="19.783"
        viewBox="0 0 19.786 19.783"
      >
        <path
          id="Halls"
          d="M7.161,19.783a1.621,1.621,0,0,1-1.13-.46A1.56,1.56,0,0,1,5.565,18.2V9.106a2,2,0,0,1,2-2h4.659a2,2,0,0,1,2,2v9.086a1.568,1.568,0,0,1-.466,1.126,1.625,1.625,0,0,1-1.154.464,1.564,1.564,0,0,1-1.109-.474l-1.6-1.63-1.6,1.632a1.568,1.568,0,0,1-1.1.472H7.161Zm.4-11.13a.453.453,0,0,0-.453.453V18.2a.056.056,0,0,0,0,.019.069.069,0,0,0,.049.019.078.078,0,0,0,.021,0l0,0,2.154-2.2a.773.773,0,0,1,1.1,0L12.6,18.228l.007.007.016,0a.08.08,0,0,0,.049-.019s.006-.006.006-.026V9.106a.454.454,0,0,0-.453-.453Zm12.222,6.492V7.825a3.093,3.093,0,0,0-1.325-2.537L11.66.551a3.1,3.1,0,0,0-3.534,0l-6.8,4.737A3.093,3.093,0,0,0,0,7.825v7.32a3.1,3.1,0,0,0,3.092,3.092h.155a.773.773,0,1,0,0-1.546H3.091a1.548,1.548,0,0,1-1.546-1.546V7.825a1.546,1.546,0,0,1,.662-1.268l6.8-4.737a1.552,1.552,0,0,1,1.767,0l6.8,4.737a1.546,1.546,0,0,1,.662,1.268v7.32a1.548,1.548,0,0,1-1.546,1.546H16.54a.773.773,0,1,0,0,1.546h.155a3.1,3.1,0,0,0,3.092-3.092Zm0,0"
          transform="translate(0 0.001)"
          opacity="0.7"
        />
      </svg>
    </>
  );
};
