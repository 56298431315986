import React, { useState, useEffect } from "react";
import CommonLoader from "../../components/Widgets/CommonLoader";
import "./commonPopup.css";
import { Modal } from "antd";
import { wallClock } from "../../components/Images/Images";
import moment from "moment";

const SessionRejected = (props) => {
  const { data } = props;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "w-100 p-4"
              : "w-100"
          }
        >
          <div className="row">
            <div className="col-1"></div>
            <div className="col-1">
              <img src={wallClock} />
            </div>
            <div className="col fs-14">
              <b>
                {moment(data?.startDate).format("hh:mm")} -
                {" " + moment(data?.endDate).format("hh:mm")}
              </b>
            </div>
          </div>
          <div className="row mt-3 mb-2">
            <div className="col-2"></div>
            <div className="col">
              <p className="fs-bold fs-16">Session Rejected</p>
            </div>
          </div>
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  return (
    <Modal
      title={"Update Meeting"}
      visible={true}
      width={950}
      onOk={() => {
        if (!loading) props.onCancel();
      }}
      onCancel={() => {
        if (!loading) props.onCancel();
      }}
      footer={null}
    >
      {renderUI()}
    </Modal>
  );
};

export default SessionRejected;
