import React from "react";

export const Lifesaver = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.771"
      height="19.784"
      viewBox="0 0 19.771 19.784"
    >
      <path
        id="_071-lifesaver"
        data-name="071-lifesaver"
        d="M19.472,5.984A9.983,9.983,0,0,0,18.8,4.691l.042-.042a.773.773,0,0,0,0-1.092L16.656,1.372a.773.773,0,0,0-1.093,0l-.058.058A9.859,9.859,0,0,0,6.481.808a10,10,0,0,0-1.245.643L5.185,1.4a.773.773,0,0,0-1.093,0L1.908,3.584a.772.772,0,0,0,0,1.092l.048.049a9.894,9.894,0,0,0-.009,10.317l-.013.013a.773.773,0,0,0,0,1.092L4.12,18.332a.773.773,0,0,0,1.093,0l.01-.01a9.853,9.853,0,0,0,9.073.651,10.012,10.012,0,0,0,1.293-.672l0,0a.773.773,0,0,0,1.093,0l2.185-2.185a.773.773,0,0,0,0-1.092l-3.339-3.339A5.445,5.445,0,0,0,15.494,8L17.671,5.82a8.453,8.453,0,0,1,.382.775,8.309,8.309,0,0,1,.53,4.813.773.773,0,1,0,1.519.281,9.849,9.849,0,0,0-.63-5.7ZM7.092,2.227a8.318,8.318,0,0,1,7.282.335L12.186,4.75a5.446,5.446,0,0,0-3.635.014L6.367,2.581q.353-.194.725-.354Zm7.2,7.664a3.9,3.9,0,0,1-2.073,3.445.767.767,0,0,0-.209.1,3.9,3.9,0,1,1,2.282-3.549ZM4.639,3.038,7.131,5.529A5.5,5.5,0,0,0,6.037,6.62L3.547,4.13ZM2.724,13.187a8.3,8.3,0,0,1,.362-7.333L5.268,8.036a5.446,5.446,0,0,0-.006,3.692L3.079,13.912c-.129-.235-.248-.478-.354-.725Zm.85,2.414,2.453-2.453a5.488,5.488,0,0,0,1.091,1.094L4.667,16.694Zm10.111,1.954a8.312,8.312,0,0,1-7.332-.362l2.181-2.181A5.447,5.447,0,0,0,12.283,15l2.177,2.177a8.454,8.454,0,0,1-.775.381Zm3.545-1.981-1.093,1.093L13.69,14.219a5.489,5.489,0,0,0,1.083-1.1ZM13.614,5.506l2.5-2.5L17.2,4.1,14.716,6.589A5.493,5.493,0,0,0,13.614,5.506Zm0,0"
        transform="translate(-0.499 0.001)"
        opacity="0.7"
      />
    </svg>
  );
};
