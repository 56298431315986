import React, { useState } from "react";
import "./OpenMeeting.css";
import {
  SearchOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Button,
  Table,
  Carousel
} from "antd";

import { Booth } from "../../components/svgJS/Booth";
import { EyeView } from "../../components/svgJS/EyeView";

import { BoothRed } from "../../components/svgJS/BoothRed";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { Download } from "../../components/svgJS/Download";

import { Bin } from "../../components/svgJS/Bin";

const OpenMeeting = () => {
  // Start of Create Meeting Modal
  const { TextArea } = Input;
  const [isOpenMeetingModalVisible, setOpenMeetingModalVisible] = useState(
    false
  );

  const showOpenMeetingModal = () => {
    setOpenMeetingModalVisible(true);
  };

  const handleOpenMeetingOk = () => {
    setOpenMeetingModalVisible(false);
  };

  const handleOpenMeetingCancel = () => {
    setOpenMeetingModalVisible(false);
  };

  const columns = [
    {
      title: "Delegate",
      dataIndex: "delegate"
    },
    {
      title: "Company",
      dataIndex: "company"
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div>
          <EyeView />
          <span className="fs-pink">View Profile</span>
        </div>
      )
    }
  ];
  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      delegate: `Richard Woodward ${i}`,
      company: "Gravit8",
      action: `London, Park Lane no. ${i}`
    });
  }

  // End of Create Meeting Modal

  return (
    <>
      <Button className="ms-2" type="primary" onClick={showOpenMeetingModal}>
        Open Open Meeting
      </Button>

      <Modal
        title=""
        visible={isOpenMeetingModalVisible}
        onOk={handleOpenMeetingOk}
        onCancel={handleOpenMeetingCancel}
        className=""
        width={650}
        footer={null}
      >
        <div className=" w-100 p-4 ">
          <span className="fs-16 opacity-75">Meeting Title</span> <br />
          <h6>Looking Forward</h6>
          <span className="fs-16 opacity-75">Time</span> <br />
          <span className="fs-16">14.10 - 15.10</span> <br />
          <span className="fs-16 opacity-75">Description</span> <br />
          <p className="fs-16">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <div className="d-flex justify-content-around mb-3">
            <Button className="openmeet_downloadBtn">
              <Download />
              <span className="ms-2">Download iCal </span>
            </Button>
            <Button className="openmeet_joinBtn mx-2 fs-16">
              <BoothRed />
              <span className="ms-2"> Join Meeting</span>
            </Button>
            <Button className="openmeet_deleteBtn  fs-16">
              <Bin />
              <span className="ms-2">Delete</span>
            </Button>
          </div>
          <h6>Attendees</h6>
          <Table
            className="openmeet_table"
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: 240 }}
          />
          <div className="w-100 d-flex mt-4 mb-2">
            <div className="w-50"></div>
            <Button className="openmeet_deleteEventBtn  fs-16">
              <Bin />
              <span className="ms-2"> Delete Event</span>
            </Button>
            <Button className="openmeet_joinBtn mx-2 fs-16">
              <BoothRed />
              <span className="ms-2">Join Session</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OpenMeeting;
