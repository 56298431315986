import React from "react";
import { Input, Select, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const AllAgents = () => {
  const dummyData = [
    {
      brand: "Dell",
      product: "M6300",
      agent: "Richard",
      booth_visited: 6,
      zone_visited: 21,
      total_booth_time: "57 Days",
      asset_views: 33,
      asset_id: 3,
      asset_likes: 12,
      total_asset_time: "21 Mins",
      demo_room_entered: 4,
    },
  ];

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 100,
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      width: 150,
    },
    {
      title: "#Booths Visited",
      dataIndex: "booth_visited",
      key: "booth_visited",
      width: 100,
    },
    {
      title: "Zones Visited",
      dataIndex: "zone_visited",
      key: "zone_visited",
      width: 100,
    },
    {
      title: "Total Booth Time",
      dataIndex: "total_booth_time",
      key: "total_booth_time",
      width: 100,
    },
    {
      title: "Asset Views",
      dataIndex: "asset_views",
      key: "asset_views",
      width: 100,
    },
    {
      title: "Asset d/Id's",
      dataIndex: "asset_id",
      key: "asset_id",
      width: 100,
    },
    {
      title: "Asset Likes",
      dataIndex: "asset_likes",
      key: "asset_likes",
      width: 100,
    },
    {
      title: "Total Asset Time",
      dataIndex: "total_asset_time",
      key: "total_asset_time",
      width: 100,
    },
    {
      title: "Demo Rooms Entered",
      dataIndex: "demo_room_entered",
      key: "demo_room_entered",
      width: 100,
    },
    {
      title: "Streams Viewed",
      dataIndex: "stream_viewed",
      key: "stream_viewed",
      width: 100,
    },
    {
      title: "Total Stream View Time",
      dataIndex: "total_stream_view_time",
      key: "total_stream_view_time",
      width: 100,
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    // dispatch(
    //   getCustomer({
    //     sort_type: sorter.columnKey,
    //     sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    //   })
    // );
  };
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <Input
          className="w-25"
          placeholder="Search Name"
          prefix={<SearchOutlined />}
        />
        <Select
          showSearch
          //   style={{ width: 300 }}
          placeholder="Learning/Industry or Competitor Research"
          optionFilterProp="children"
          className="fs-bold-14"
          name="booth_location_id"
          //   onChange={(val) => {
          //     setFieldValue("booth_location_id", val);
          //     setFieldValue("survey_id", null);
          //   }}
          listHeight={120}
          listItemHeight={4}
          //   value={values?.booth_location_id}
        >
          {/* {boothLocationList &&
            boothLocationList.map((data) => (
              <Option value={data.booth_location_id}>
                {data.booth_location}
              </Option>
            ))} */}
        </Select>
      </div>
      <Table
        className="mt-3"
        columns={columns}
        dataSource={dummyData}
        pagination={{
          showSizeChanger: true,
        }}
        scroll={{
          x: 1300,
        }}
        bordered={false}
        showSizeChange={true}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default AllAgents;
