export const dialogs = {
  missingFields: "Fields are missing",
  event: {
    eventUpdateSuccess: "Event updated successfully",
  },
  eventCalender: {
    validation: {
      currentFutureSession:
        "Please select current date or future date to create/update sponser",
      currentFutureSponser:
        "Please select current date or future date to create/update sponser",
    },
  },
  brandZoneCalender: {
    validation: {
      currentFutureDate:
        "Please select current date or future date to create/update demo room and staffing",
    },
  },
  inCorrectTimeError: "Please select correct time",
};
