import React, { useEffect } from "react";
import { Button, Tabs } from "antd";
import { Link } from "react-router-dom";
import AllAgents from "./AllAgents";
import SelectSingleAgent from "./SelectSingleAgent";
import CompareProductsTeams from "./CompareProductsTeams";
import { isRouteExistInMenu } from "../../utils/commonFunctions";

const { TabPane } = Tabs;

const Analytics = () => {
  var CryptoJS = require("crypto-js");
  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    }
  }, []);
  return (
    <div>
      <div className="d-flex justify-content-between py-2 align-items-center">
        <div>
          <h4 className="fs-24 m-0 p-0">Analytics</h4>
          {/* <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Analytics</Breadcrumb.Item>
          </Breadcrumb> */}
        </div>
        <div>
          <Link to="createnewaccount">
            <Button disabled className="rounded px-3">
              <span className="fw-normal"> PDF </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="bg-white  w-100">
        <Tabs>
          <TabPane
            tab={
              <>
                <span className="mx-2">All Agents</span>
              </>
            }
            key="1"
          >
            <AllAgents />
          </TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Agents tagged to brand</span>
              </>
            }
            key="2"
          ></TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Agents tagged to a product</span>
              </>
            }
            key="3"
          ></TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Select a single agent</span>
              </>
            }
            key="4"
          >
            <SelectSingleAgent />
          </TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Only me</span>
              </>
            }
            key="5"
          ></TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Compare brands</span>
              </>
            }
            key="6"
          ></TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Compare product teams</span>
              </>
            }
            key="7"
          >
            <CompareProductsTeams />
          </TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Event dashboard</span>
              </>
            }
            key="8"
          ></TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Analytics;
