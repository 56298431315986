import React, { useState, useEffect } from "react";
import { Drawer, Layout, Menu } from "antd";
import "./SideBar.css";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router";

import { collapsedLogo } from "../../components/Images/Images";
import { useMediaQuery } from "react-responsive";

const SideBar = (props) => {
  var CryptoJS = require("crypto-js");
  const [menuDetails, setMenuDetails] = useState(null);
  const [defaultSelected, setDefaultSelected] = useState([]);
  const isMobile = useMediaQuery({ minWidth: 480 });
  const [over, setOver] = useState({ key: null, show: false });
  const [overAdmin, setOverAdmin] = useState({ key: null, show: false });
  const { location } = props;
  const history = useHistory();
  let pathname = location.pathname;

  useEffect(() => {
    const ciphertext = localStorage.getItem("side_menu");
    if (ciphertext) {
      var bytes = CryptoJS.AES.decrypt(ciphertext, "side_menu");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } else window.open("/login", "_self");
    let menu_index = 0;
    decryptedData &&
      decryptedData?.forEach((menu_iteam, parentIndex) => {
        if (menu_iteam.route === pathname) menu_index = parentIndex;
        else if (menu_iteam.sub_routes) {
          menu_iteam.sub_routes.forEach((sub_menu_iteam) => {
            if (pathname.includes(sub_menu_iteam)) menu_index = parentIndex;
          });
        }
      });

    setMenuDetails(decryptedData);
    if (window?.location?.pathname === "/myprofile") setDefaultSelected([]);
    else if (
      window.location.pathname === "/pagenotfound" ||
      window.location.pathname === "/"
    )
      setDefaultSelected([]);
    else
      setDefaultSelected([
        decryptedData &&
          decryptedData[menu_index] &&
          decryptedData[menu_index]?.menu_id?.toString(),
      ]);

    props?.dataFromSideBar(defaultSelected);
  }, [window.location.pathname, CryptoJS.AES]);

  const non_admin_menu =
    menuDetails && menuDetails.filter((data) => data.is_admin_menu === false);
  const admin_menu =
    menuDetails && menuDetails.filter((data) => data.is_admin_menu === true);
  if (isMobile) {
    return (
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={props?.isSidebarCollapsed}
        className={`bg-light sidebar-div ${
          !isMobile ? "sidebar_div_mobile" : ""
        }`}
      >
        {props?.isSidebarCollapsed ? (
          <div className="logo">
            <img
              src={
                localStorage.getItem("minimized_brand_logo_for_menu") ??
                localStorage.getItem("minimized_logo_url") ??
                collapsedLogo
              }
              onClick={() => {
                history.push("/concourse");
              }}
              alt="logo"
              className="img-fluid cursor-pointer"
            />
          </div>
        ) : (
          <div className="logo">
            <img
              src={
                localStorage.getItem("brand_logo_for_menu") ??
                localStorage.getItem("event_logo_url")
              }
              onClick={() => {
                history.push("/concourse");
              }}
              alt="logo"
              className="img-fluid sidebar_logo cursor-pointer"
            />
          </div>
        )}
        <Menu theme="light" mode="inline" selectedKeys={defaultSelected}>
          {non_admin_menu &&
            non_admin_menu.map((data, idx) => (
              <Menu.Item
                key={data.menu_id.toString()}
                className="list"
                onMouseOver={() => setOver({ key: idx, show: true })}
                onMouseOut={() => setOver({ key: idx, show: false })}
                icon={
                  <img
                    src={
                      over.show && over.key === idx
                        ? data.white_icon_url
                        : parseInt(defaultSelected[0]) === data.menu_id
                        ? data.white_icon_url
                        : data.icon_url
                    }
                    alt="sidebar icon"
                    className="rounded sidebar_icon"
                  />
                }
                onClick={() => setDefaultSelected([data.menu_id.toString()])}
              >
                <Link to={data.route} className="link-list">
                  {data.menu}
                </Link>
              </Menu.Item>
            ))}
          {admin_menu && admin_menu.length > 0 ? (
            <Menu.Item
              key="121"
              disabled
              style={{ cursor: "pointer" }}
              className="text-secondary"
            >
              Admin Console
            </Menu.Item>
          ) : null}
          {admin_menu &&
            admin_menu.map((data, idx) => (
              <Menu.Item
                key={data.menu_id.toString()}
                className="list"
                onMouseOver={() =>
                  setOverAdmin({
                    key: idx,
                    show: true,
                  })
                }
                onMouseOut={() => setOverAdmin({ key: idx, show: false })}
                icon={
                  <img
                    src={
                      overAdmin.show && overAdmin.key === idx
                        ? data.white_icon_url
                        : parseInt(defaultSelected[0]) === data.menu_id
                        ? data.white_icon_url
                        : data.icon_url
                    }
                    alt="sidebar icon"
                    className="rounded sidebar_icon"
                  />
                }
                onClick={() => setDefaultSelected([data.menu_id.toString()])}
              >
                <Link to={data.route} className="link-list">
                  {data.menu}
                </Link>
              </Menu.Item>
            ))}
        </Menu>
      </Layout.Sider>
    );
  } else {
    return (
      <>
        <Drawer
          visible={props.isMobileDrawerOpen}
          onClose={props.toggleMobileDrawer}
          placement="top"
        >
          <Menu theme="light" mode="inline" selectedKeys={defaultSelected}>
            {non_admin_menu &&
              non_admin_menu.map((data, idx) => (
                <Menu.Item
                  key={data.menu_id.toString()}
                  className="list"
                  onMouseOver={() => setOver({ key: idx, show: true })}
                  onMouseOut={() => setOver({ key: idx, show: false })}
                  icon={
                    <img
                      src={
                        over.show && over.key === idx
                          ? data.white_icon_url
                          : parseInt(defaultSelected[0]) === data.menu_id
                          ? data.white_icon_url
                          : data.icon_url
                      }
                      alt="sidebar icon"
                      className="rounded sidebar_icon"
                    />
                  }
                  onClick={() => setDefaultSelected([data.menu_id.toString()])}
                >
                  <Link to={data.route} className="link-list">
                    {data.menu}
                  </Link>
                </Menu.Item>
              ))}
            {admin_menu && admin_menu.length > 0 ? (
              <Menu.Item
                key="121"
                disabled
                style={{ cursor: "pointer" }}
                className="text-secondary"
              >
                Admin Console
              </Menu.Item>
            ) : null}
            {admin_menu &&
              admin_menu.map((data, idx) => (
                <Menu.Item
                  key={data.menu_id.toString()}
                  className="list"
                  onMouseOver={() =>
                    setOverAdmin({
                      key: idx,
                      show: true,
                    })
                  }
                  onMouseOut={() => setOverAdmin({ key: idx, show: false })}
                  icon={
                    <img
                      src={
                        overAdmin.show && overAdmin.key === idx
                          ? data.white_icon_url
                          : parseInt(defaultSelected[0]) === data.menu_id
                          ? data.white_icon_url
                          : data.icon_url
                      }
                      alt="sidebar icon"
                      className="rounded sidebar_icon"
                    />
                  }
                  onClick={() => setDefaultSelected([data.menu_id.toString()])}
                >
                  <Link to={data.route} className="link-list">
                    {data.menu}
                  </Link>
                </Menu.Item>
              ))}
          </Menu>
        </Drawer>
      </>
    );
  }
};

export default withRouter(SideBar);
