import React from "react";

const GoogleSuccessPage = () => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-content-center flex-wrap">
      <h1 className="fs-pink mt-4">Google Sign In Succesfully</h1>
    </div>
  );
};

export default GoogleSuccessPage;
