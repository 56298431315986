import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

const initialState = {
  eventList: {
    eventEditorData: {},
    registerEventData: {},
    tiersData: {},
    navigationData: {},
  },
  loading: false,
};

export const createEvent = createAsyncThunk(
  "createEvent/createEvent",
  async (body) => {
    const response = await ApiCallForm(
      "POST",
      body,
      "/admin/events/create",
      ""
    );

    return response;
  }
);

export const createEventSettings = createAsyncThunk(
  "createEvent/createEventSettings",
  async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/settings/create",
      "",
      ""
    );

    return response;
  }
);

export const registerEvent = createAsyncThunk(
  "createEvent/registerEvent",
  async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/registrationandcommunication/create",
      "",
      ""
    );

    return response;
  }
);

export const tierEvent = createAsyncThunk(
  "createEvent/tierEvent",
  async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/tiers/create",
      "",
      ""
    );

    return response;
  }
);

export const navigationAndReceptionEvent = createAsyncThunk(
  "createEvent/navigationAndReceptionEvent",
  async (body) => {
    const response = await ApiCallForm(
      "POST",
      body,
      "/admin/events/navigationplusreception/create",
      ""
    );

    return response;
  }
);

export const socialWallEvent = createAsyncThunk(
  "createEvent/socialWallEvent",
  async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/socialwall/create",
      "",
      ""
    );

    return response;
  }
);

export const createEventSlice = createSlice({
  name: "createEvent",
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {},
  extraReducers: {
    [createEvent.pending]: (state) => {
      state.loading = true;
    },
    [createEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [createEvent.rejected]: (state) => {
      state.loading = false;
    },
    [createEventSettings.pending]: (state) => {
      state.loading = true;
    },
    [createEventSettings.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [createEventSettings.rejected]: (state) => {
      state.loading = false;
    },
    [registerEvent.pending]: (state) => {
      state.loading = true;
    },
    [registerEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [registerEvent.rejected]: (state) => {
      state.loading = false;
    },
    [tierEvent.pending]: (state) => {
      state.loading = true;
    },
    [tierEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [tierEvent.rejected]: (state) => {
      state.loading = false;
    },

    [navigationAndReceptionEvent.pending]: (state) => {
      state.loading = true;
    },
    [navigationAndReceptionEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [navigationAndReceptionEvent.rejected]: (state) => {
      state.loading = false;
    },
    [socialWallEvent.pending]: (state) => {
      state.loading = true;
    },
    [socialWallEvent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [socialWallEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const createEventReducer = createEventSlice.reducer;
