import React, { useState, useEffect, useRef } from "react";
import "../EventCalender/eventCalender.css";
import { Modal, Button, Input, Row, Col, Tooltip, Carousel } from "antd";
import { Download } from "../../components/svgJS/Download";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  addToMyCalender,
  getAudiSession,
  sendMessageAPI,
  updateAssetDownloadCount,
} from "../../utils/commonApis";
import {
  DEFAULT_CHATBOT_HISTORY,
  TIME_FORMAT,
} from "../../components/constsnts/common.constants";
import {
  videoRequestAPI,
  fetchChatbotHistory,
  getChatbotJson,
  saveChatbotHistory,
} from "../../utils/commonApis";
import {
  checkIfChatbotTimeSlot,
  createTZString,
  getComentChatUser,
  getCookie,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import CustomChatbot from "../../components/Widgets/CustomChatbot";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { User } from "../../components/svgJS/User";
import { Booth } from "../../components/svgJS/Booth";
import ICalendarLink from "react-icalendar-link";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { CalenderRed } from "../../components/svgJS/CalenderRed";
import "./commonPopup.css";
import {
  setGoogleAuthData,
  setVideoConferenceData,
} from "../../redux/slice/videoConference";
import moment from "moment";
import { ApiCall } from "../../utils/api";
var CryptoJS = require("crypto-js");

const AudiViewDelegates = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const messageValidator = useRef(new SimpleReactValidator());
  const { TextArea } = Input;
  const ciphertext = getCookie("user_id");
  const bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
  const loggedInUserId = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [localData, setLocalData] = useState({});
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState(
    DEFAULT_CHATBOT_HISTORY
  );
  const [loading, setLoading] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [canJoinSession, setCanJoinSession] = useState(false);
  const [is_moderator, set_is_moderator] = useState(false);
  const [disableSendBtn, setDisableSendBtn] = useState(false);

  useEffect(async () => {
    const cometRes = await getComentChatUser();
    setCometChatUser(cometRes);

    if (data?.auditorium_configuration_id) {
      setLoading(true);
      let sessionAPIData = await getAudiSession({
        auditorium_configuration_id: data?.auditorium_configuration_id,
        auditorium_session_id: data?.auditorium_session_id,
      });
      if (sessionAPIData) {
        const speakerData =
          sessionAPIData &&
          sessionAPIData?.hosting_brand_details?.map((ele) => {
            return ele?.auditorium_hosting_speakers;
          });

        const speakersHere = [];
        speakerData?.forEach((ele) => {
          ele &&
            ele.forEach((ele) => {
              speakersHere.push(ele.user);
            });
        });
        if (speakersHere) setSpeakers(speakersHere);

        if (sessionAPIData?.auditorium_hosting_speakers)
          setSpeakers(sessionAPIData?.auditorium_hosting_speakers);

        const d1 = new Date(
          sessionAPIData?.auditorium_session_details?.start_date_time
        );
        const st = d1.toISOString().replace("Z", "");
        const d2 = new Date(
          sessionAPIData?.auditorium_session_details?.end_date_time
        );
        const et = d2.toISOString().replace("Z", "");
        const start_date_time = createTZString(
          sessionAPIData?.auditorium_session_details?.start_date_time
        );
        const end_date_time = createTZString(
          sessionAPIData?.auditorium_session_details?.end_date_time
        );

        let newStartTime = start_date_time.format("HH:mm");

        let newEndTime = end_date_time.format("HH:mm");

        if (
          sessionAPIData &&
          sessionAPIData.message_board_details &&
          sessionAPIData.message_board_details.assigned_agent_ids
        ) {
          if (
            sessionAPIData.message_board_details.assigned_agent_ids.includes(
              loggedInUserId
            )
          )
            set_is_moderator(true);
        }
        setLocalData({
          ...sessionAPIData,
          startTime: st,
          endTime: et,
          title: sessionAPIData?.session_title,
          description: sessionAPIData?.session_description,
          startDateTime: newStartTime,
          endDateTime: newEndTime,
          start_date_time: moment(
            start_date_time.format("HH:mm"),
            TIME_FORMAT
          ).format("HH:mm"),
          end_date_time: moment(
            end_date_time.format("HH:mm"),
            TIME_FORMAT
          ).format("HH:mm"),
        });
        const currentDate = moment().format("YYYY-MM-DD");
        const currentTime = moment(moment().format("HH:mm"), TIME_FORMAT);
        const sessStartTime = moment(
          start_date_time.format("HH:mm"),
          TIME_FORMAT
        );
        const sessEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);

        if (
          currentDate ===
            sessionAPIData?.auditorium_session_details?.start_date &&
          currentTime.isAfter(sessStartTime) &&
          currentTime.isBefore(sessEndTime)
        )
          setCanJoinSession(true);

        setLoading(false);
      } else setLoading(false);
    }

    if (data && data.event_id) {
      const chatHistory = await fetchChatbotHistory({
        event_id: data.event_id,
      });
      if (chatHistory && !isEmpty(chatHistory))
        set_chatbot_history(chatHistory);
    }
  }, []);

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      setDisableSendBtn(true);
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
    setDisableSendBtn(false);
  }

  const saveResponseData = async (bool) => {
    let objToPass = {};
    if (bool) objToPass = { ...data, rsvp_type: "YES" };
    else objToPass = { ...data, rsvp_type: "NO" };
    data !== null &&
      (await ApiCall("POST", objToPass, "/admin/mycalendarrsvp/save", null)
        .then((response) => {
          if (response.data.code === 200) {
            toast.success(response.data.message);
            window.location.reload();
            // getMyCalendarAppointmentDetails()
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        }));
  };

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "w-100 p-4"
              : "w-100"
          }
        >
          {isChatBot && chatbot_json ? (
            <CustomChatbot
              onClose={async (conversation_details) => {
                await saveChatbotHistory({
                  event_id: data.event_id,
                  conversation_details,
                });
                setIsChatBot(false);
              }}
              responseJson={chatbot_json}
              chatbot_history={chatbot_history}
              onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
              extraProps={{ event_id: data.event_id }}
            />
          ) : null}
          <Row>
            <span className="popup_label">Title :</span>
          </Row>
          <Row>
            <b>
              {localData?.auditorium_session_details?.session_title ?? "NA"}
            </b>
          </Row>
          <Row>
            <span className="popup_label mt-2">Time :</span>
          </Row>
          <Row>
            <b>
              {localData?.startDateTime ?? "NA"} -
              {" " + localData?.endDateTime ?? "NA"}
            </b>
          </Row>
          <Row>
            <span className="popup_label mt-2">Description :</span>
          </Row>
          <Row>
            <b>
              {localData?.auditorium_session_details?.session_description ??
                "NA"}
            </b>
          </Row>
          <Row>
            {localData?.auditorium_session_details?.is_iCal_enabled === true &&
            window.navigator.platform.toLowerCase().includes("mac") ? (
              <ICalendarLink
                className="audiEvent_downloadBtn"
                event={localData}
              >
                <div>
                  <span>Download iCal</span>
                </div>
              </ICalendarLink>
            ) : null}
            {window.location.pathname !== "/mycalendar" ? (
              <Button
                className="joinsess_calendarBtn mt-2"
                onClick={async () => {
                  await addToMyCalender({
                    event_id: localData.event_id,
                    hall_type: localData.hall_type,
                    hall_id: localData?.auditorium_configuration_id,
                    session_id: localData?.auditorium_session_id,
                  });
                }}
              >
                <CalenderRed />
                <span className="ms-2">Add to My Calendar </span>
              </Button>
            ) : null}
          </Row>
          <Row>
            <span className="popup_label mt-2">Speaker(s)</span>
          </Row>

          {speakers && speakers.length > 0 ? (
            <div style={{ padding: "3%", paddingTop: "1%" }}>
              <Carousel
                style={{ width: "800px !important" }}
                className="audiEvent_brand"
                arrows
                nextArrow={
                  <div>
                    <i class="arrow right"></i>
                  </div>
                }
                prevArrow={
                  <div>
                    <i class="arrow left"></i>
                  </div>
                }
              >
                {speakers &&
                  speakers?.map((ele) => {
                    return (
                      <div className="d-flex p-3 mb-2 my-2">
                        <div className="all_speaker_images_container">
                          <img
                            className="all_speaker_images"
                            src={
                              ele?.image_url
                                ? ele?.image_url
                                : "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
                            }
                          />
                        </div>

                        <div className="ms-3">
                          <span className="opacity-75">Speaker</span>
                          <h6>
                            {ele?.first_name} {ele?.last_name}
                          </h6>
                          <span>Bio</span>
                          <p className="text-dark">{ele?.bio ?? "NA"}</p>
                        </div>
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          ) : null}
          {speakers && speakers.length === 0 ? (
            <Row>
              <b>NA</b>
            </Row>
          ) : null}

          <Row>
            <span className="popup_label mt-2">Brand(s)</span>
          </Row>
          {localData?.hosting_brand_details?.length > 0 ? (
            <div style={{ padding: "3%", paddingTop: "1%" }}>
              <Carousel
                style={{ width: "800px !important" }}
                className="audiEvent_brand"
                arrows
                nextArrow={
                  <div>
                    <i class="arrow right "></i>
                  </div>
                }
                prevArrow={
                  <div>
                    <i class="arrow left "></i>
                  </div>
                }
              >
                {localData?.hosting_brand_details?.map((ele) => {
                  let chatbotRes = checkIfChatbotTimeSlot(ele);
                  return (
                    <div className="d-flex my-1 py-3 px-2">
                      <div className="all_sposnor_images_container">
                        <img
                          className="all_sposnor_images border border-grey"
                          src={
                            ele?.logo_url
                              ? ele?.logo_url
                              : "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
                          }
                        />
                      </div>

                      <div className="ms-3">
                        <h6 className="audi_view_brand_title_mobile">
                          {ele?.brand_title}
                        </h6>
                        <p className="audi_view_brand_tier_mobile">
                          {ele?.brand_tier}
                        </p>
                        <div className="d-flex mt-3 audi_view_brands_buttons_mobile">
                          {ele?.is_assets_enabled === true &&
                          ele?.asset_file_url &&
                          ele?.asset_name &&
                          ele?.asset_file_url !== null &&
                          ele?.asset_name !== null ? (
                            <Tooltip
                              placement="right"
                              title={ele?.original_file_name}
                              className="mx-2"
                            >
                              <Button
                                className="border p-1"
                                onClick={async () => {
                                  if (ele?.can_download_asset) {
                                    window.location.href = ele?.asset_file_url;
                                    updateAssetDownloadCount(
                                      ele?.zone_asset_id
                                    );
                                  } else {
                                    toast.error(
                                      "Max Number of Downloads Reached"
                                    );
                                  }
                                }}
                                download={ele?.original_file_name}
                              >
                                <Download />
                              </Button>
                            </Tooltip>
                          ) : null}
                          {ele?.is_booth_link_enabled ? (
                            <Tooltip
                              placement="right"
                              title={"Visit Booth"}
                              className="mx-2"
                            >
                              <Button
                                className="border p-1"
                                onClick={() => {
                                  let tier = ele?.brand_tier_id;
                                  let zones = ele?.brand?.zones;
                                  let sequence = null;
                                  let brand_id = ele?.assigned_brand_id;
                                  let final_booth_url =
                                    ele?.brand?.final_booth_url;

                                  vistaRedirectToBooth(
                                    tier,
                                    zones,
                                    sequence,
                                    null,
                                    brand_id,
                                    null,
                                    history,
                                    dispatch,
                                    final_booth_url
                                  );
                                }}
                              >
                                <BoothRed />
                              </Button>
                            </Tooltip>
                          ) : null}
                          {(ele?.is_chat_enabled && ele.channel_id) ||
                          (chatbotRes && ele.chat_bot_start_date_time) ? (
                            <Tooltip
                              placement="right"
                              title="Chat"
                              className="mx-2"
                            >
                              <Button
                                className="border p-1"
                                onClick={async () => {
                                  if (
                                    chatbotRes &&
                                    ele.chat_bot_start_date_time
                                  ) {
                                    let chat_bot_res = await getChatbotJson({
                                      zone_id: ele.zone_id,
                                    });
                                    if (
                                      chat_bot_res &&
                                      !isEmpty(chat_bot_res)
                                    ) {
                                      set_chatbot_json(chat_bot_res);
                                      setIsChatBot(true);
                                    } else
                                      toast.error(
                                        "Chatbot details doesn't exist"
                                      );
                                  } else {
                                    if (ele.channel_id)
                                      setMessageModal({
                                        isOpen: true,
                                        channel_id: ele.channel_id,
                                      });
                                  }
                                }}
                              >
                                <Chat />
                              </Button>
                            </Tooltip>
                          ) : null}
                          {ele?.is_demo_room_enabled ? (
                            <Tooltip
                              placement="right"
                              title={"Join the demo room"}
                              className="mx-2"
                            >
                              <Button
                                className="border p-1"
                                onClick={() => {
                                  dispatch(
                                    setVideoConferenceData({
                                      videoConferenceFlag: true,
                                      videoConferenceUrl:
                                        ele?.zone_demo_room_link,
                                      is_demo_room: true,
                                    })
                                  );
                                }}
                              >
                                <Video />
                              </Button>
                            </Tooltip>
                          ) : null}
                          {ele?.is_video_call_enabled ? (
                            <Tooltip
                              placement="right"
                              title={"Next Available Agent"}
                              className="mx-2"
                            >
                              <Button
                                className="border p-1"
                                onClick={async () => {
                                  const response = await videoRequestAPI(
                                    ele.channel_id
                                  );
                                  if (response.data.code === 200)
                                    toast.success(response.data.message);
                                  else toast.error(response.data.message);
                                }}
                              >
                                <User />
                              </Button>
                            </Tooltip>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : null}
          {localData?.hosting_brand_details?.length === 0 ? (
            <Row>
              <b>NA</b>
            </Row>
          ) : null}
          {messageModal.isOpen && window.location.pathname === "/mycalendar" ? (
            <>
              <Row className="ticker_configuration_row mt-2">
                <Col span={6} className="ticker_configuration_label">
                  <p>
                    Message
                    <RedAsterick />
                  </p>
                </Col>
                <Col span={12}>
                  <TextArea
                    name="message_text"
                    id="message_text"
                    className="ticker_configuration_inputDescription"
                    placeholder="Enter a message"
                    rows={4}
                    value={message_text}
                    onChange={(e) => set_message_text(e.target.value)}
                    onBlur={() =>
                      messageValidator.current.showMessageFor("message_text")
                    }
                  />
                  {messageValidator.current.message(
                    "Message",
                    message_text,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={14} className="text-end">
                  <Button
                    className="px-3 rounded"
                    key="save"
                    type="primary"
                    onClick={() => callSendMessageAPI(messageModal?.channel_id)}
                    disabled={disableSendBtn}
                  >
                    Send
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    className="px-3 button-pink rounded ms-3"
                    key="cancel"
                    onClick={() => clearMessageAndModal()}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          ) : null}
          {messageModal.isOpen && window.location.pathname !== "/mycalendar" ? (
            <Modal
              title={<b>Send Message</b>}
              visible={messageModal.isOpen}
              onOk={() => clearMessageAndModal()}
              onCancel={() => clearMessageAndModal()}
              footer={[
                <Button
                  className="px-3 button-pink rounded"
                  key="cancel"
                  onClick={() => clearMessageAndModal()}
                >
                  Cancel
                </Button>,
                <Button
                  className="px-3 rounded"
                  key="save"
                  type="primary"
                  onClick={() => callSendMessageAPI(messageModal.channel_id)}
                  disabled={disableSendBtn}
                >
                  Send
                </Button>,
              ]}
              className="message_modal"
            >
              <Row className="ticker_configuration_row mt-2">
                <Col span={6} className="ticker_configuration_label">
                  <p>
                    Message
                    <RedAsterick />
                  </p>
                </Col>
                <Col span={12}>
                  <TextArea
                    name="message_text"
                    id="message_text"
                    className="ticker_configuration_inputDescription"
                    placeholder="Enter a message"
                    rows={4}
                    value={message_text}
                    onChange={(e) => set_message_text(e.target.value)}
                    onBlur={() =>
                      messageValidator.current.showMessageFor("message_text")
                    }
                  />
                  {messageValidator.current.message(
                    "Message",
                    message_text,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
            </Modal>
          ) : null}

          {props?.data?.rsvp_type === "PENDING" ? (
            <>
              <div className="d-flex justify-content-end px-3">
                <Button
                  type="primary"
                  className="button-pink py-1 rounded"
                  onClick={() => saveResponseData(true)}
                >
                  Accept
                </Button>
                <Button
                  type="primary"
                  className="button-pink py-1 mx-3 rounded"
                  onClick={() => saveResponseData(false)}
                >
                  Reject
                </Button>
              </div>
            </>
          ) : canJoinSession ? (
            <div className="w-100 d-flex justify-content-end mt-4 mb-2">
              <div className="w-50"></div>
              <Button
                className="audiEvent_joinBtn mx-4 fs-16"
                onClick={() => {
                  if (window.location.pathname === "/mycalendar") {
                    props?.closeTooltip();
                  }

                  let googleAuthFlag = localData?.is_producer_curator;
                  let videoConferenceObj = {
                    videoConferenceFlag: true,
                    videoConferenceUrl: localData?.join_session_url,
                    createPollFlag: localData?.is_producer_curator,
                    is_producer_curator: localData?.is_producer_curator,
                    isFromNetworking: false,
                    auditorium_session_id: localData?.auditorium_session_id,
                    auditorium_configuration_id:
                      localData?.auditorium_configuration_id,
                    is_speaker: localData?.is_speaker,
                    is_moderator,
                    isPollOptionsVisible: true,
                    stream_url:
                      localData?.auditorium_session_details?.stream_url,
                    stream_key:
                      localData?.auditorium_session_details?.stream_key,
                    showMessageBoard: localData?.message_board_details
                      ?.message_board_enabled
                      ? true
                      : false,
                    isFromExternalSourceEnabled:
                      localData?.source_details?.is_from_external_source,
                  };

                  dispatch(setVideoConferenceData(videoConferenceObj));
                }}
              >
                <Booth />
                <span className="ms-2">Join Session</span>
              </Button>
            </div>
          ) : null}
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  else
    return (
      <Modal
        title={"Session Details"}
        visible={true}
        width={950}
        onOk={() => {
          if (!loading) {
            if (props.isFromCalender === "event") props.onCloseModal(false);
            else props.onCancel();
          }
        }}
        onCancel={() => {
          if (!loading) {
            if (props.isFromCalender === "event") props.onCloseModal(false);
            else props.onCancel();
          }
        }}
        footer={null}
      >
        {renderUI()}
      </Modal>
    );
};

export default AudiViewDelegates;
