import React, { useState, useEffect, useContext } from "react";
import "./ExpoHalls.css";
import { Row, Col, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Empty, Tooltip } from "antd";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { getBrands } from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderDefaultEventId,
  setHeaderEventList,
} from "../../redux/slice/headerData";
import AppContext, { ACTIONS } from "../Vista/src/contexts/AppContext";
import { hallData, standsData } from "../Vista/src/apiData";

import { cloneDeep } from "lodash";
import {
  setBoothLocationNumber,
  setExpoHallData,
  setExpoHallLocationNumber,
  setExpoHallUrl,
  setExpoMappingArr,
  setVistaBrandData,
  setVistaEventId,
  setVistaExpoHallSequence,
  setVistaLocationId,
} from "../../redux/slice/vista";
import { isRouteExistInMenu } from "../../utils/commonFunctions";
import { toast } from "react-toastify";

const { Option } = Select;

const ExpoHalls = (props) => {
  const [selectedBoothBorder, setSelectedBoothBorder] = useState({
    display_name: "",
    flag: false,
  });
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");
  const { dispatch: AppDispatch } = useContext(AppContext);
  const [userType, setUserType] = useState("");
  const [expoHallDetails, setExpoHallDetails] = useState([]);
  const [allExpoHalls, setAllExpoHalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [eventList, setEventList] = useState([]);
  // const event_id = useSelector((state) => state?.header?.event_id); //from header component
  const [fields, setFields] = useState({
    event_id: null,
    brand_id: null,
    brand_tier: null,
  });

  const concourseData = useSelector(
    (state) => state?.vistaBrand?.concourseData
  );

  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId);

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    if (ciphertext) {
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);
    }
  }, [CryptoJS.AES]);

  const getExpoHallDetails = async (event_id) => {
    setLoading(true);
    const response = await ApiCall(
      "GET",
      "",
      "/admin/expohallsmap/details/get",
      event_id
    );
    if (response?.data?.code === 200) {
      setLoading(false);
      dispatch(setExpoHallData(response?.data?.data));
      return response.data.data;
    } else {
      setLoading(false);
      return [];
    }
  };

  useEffect(() => {
    getEventList();
  }, [brandData]);

  useEffect(async () => {
    if (fields.event_id) {
      const brandData = await getBrands({
        event_id: fields?.event_id,
      });
      if (brandData.length === 1) {
        setFields({ ...fields, brand_id: brandData[0].brand_id });
      }
      setBrandData(brandData);
    }
  }, [fields?.event_id]);

  useEffect(() => {
    setFields({ ...fields, event_id: props.event_id });
  }, [props?.event_id]);

  useEffect(async () => {
    dispatch(setHeaderDefaultEventId(fields.event_id ?? props.event_id));
    let responseData = await getExpoHallDetails({
      event_id: fields?.event_id ?? props?.event_id ?? vistaEventId,
    });

    const alphabet_arr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    if (responseData) {
      let finalExpoHalls = [];
      responseData.forEach((parentElement, parentIndex) => {
        if (
          !isEmpty(parentElement.expo_hall_mappings) &&
          parentElement.expo_hall_mappings
        ) {
          let new10HallMappings = [];
          let alreadyPresentLength = parentElement.expo_hall_mappings.length;

          for (let i = 0; i < 10; i++) {
            new10HallMappings.push({
              display_name:
                (parentIndex + 1).toString() + "-" + alphabet_arr[i],
              isElementPresent: false,
              sequence: i,
              defaultSequence: i + 1,
            });
          }

          let existedMappings = [];
          parentElement.expo_hall_mappings.forEach(
            (childElement, childIndex) => {
              existedMappings.push({
                ...childElement,
                display_name:
                  (parentIndex + 1).toString() + "-" + alphabet_arr[childIndex],
                isElementPresent: true,
                defaultSequence: childIndex + 1,
              });
            }
          );
          existedMappings = existedMappings.sort(
            (a, b) => a.sequence - b.sequence
          );
          const sequenceArr = existedMappings.map((ele) => ele.sequence);
          if (sequenceArr)
            sequenceArr.forEach((ele, indexHere) => {
              new10HallMappings[ele - 1] = existedMappings[indexHere];
              new10HallMappings[ele - 1].defaultSequence = ele;
            });

          finalExpoHalls.push({
            ...parentElement,
            expo_hall_mappings: new10HallMappings,
          });
        } else {
          let new10HallMappings = [];
          for (let i = 0; i < 10; i++) {
            new10HallMappings.push({
              display_name:
                (parentIndex + 1).toString() + "-" + alphabet_arr[i],
              isElementPresent: false,
              sequence: i,
              defaultSequence: i + 1,
            });
          }
          finalExpoHalls.push({
            ...parentElement,
            expo_hall_mappings: new10HallMappings,
          });
        }
      });

      let allExpoHalls = cloneDeep(finalExpoHalls);
      let totalFinalAllHalls = [];
      allExpoHalls &&
        allExpoHalls.forEach((parentEle, indexParentHall) => {
          if (
            parentEle &&
            parentEle.expo_hall_mappings &&
            parentEle.expo_hall_mappings.length === 10
          ) {
            const clonedMappings1 = cloneDeep(parentEle.expo_hall_mappings);
            const clonedMappings2 = cloneDeep(parentEle.expo_hall_mappings);
            let clonedMappingsF5 = clonedMappings1.splice(0, 5);
            let clonedMappingsS5 = clonedMappings2.splice(5, 5);

            let upperArr = [];
            let lowerArr = [];
            let combineArr = [];

            clonedMappingsF5.forEach((ele, ind) => {
              if (ind % 2 === 0) lowerArr.push(ele); //A C E
              else upperArr.push(ele); //B D
            });

            clonedMappingsS5.forEach((ele, ind) => {
              if (ind % 2 === 0) upperArr.push(ele); //B D F H J
              else lowerArr.push(ele); //A C E G I
            });

            if (indexParentHall % 2 !== 0)
              combineArr = [...lowerArr, ...upperArr];
            else combineArr = [...upperArr.reverse(), ...lowerArr.reverse()];
            totalFinalAllHalls.push({
              ...parentEle,
              expo_hall_mappings: combineArr,
            });
          } else totalFinalAllHalls.push(parentEle);
        });

      if (totalFinalAllHalls) setAllExpoHalls(totalFinalAllHalls);
    }
    setExpoHallDetails(responseData);
  }, [fields.event_id]);

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  let expoHallSelection;

  if (props.getDataFromExpoHall) {
    expoHallSelection = (expo_hall_configuration_id, elemIndex) => {
      props?.getDataFromExpoHall(expo_hall_configuration_id, elemIndex);
    };
  }

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", "")
      .then((response) => {
        const { rows } = response?.data?.data;
        if (rows.length === 1) {
          setFields({ ...fields, event_id: rows[0].event_id });
        }
        setEventList(rows);
        dispatch(setHeaderEventList(rows));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  console.log("all Expo halls", allExpoHalls);

  if (loading) return <CommonLoader />;
  else
    return (
      <div className={props?.fromViewBrand ? "only_for_view" : ""}>
        <div className={props.isFromVista ? "bg-white" : ""}>
          {props?.fromBrand ? (
            ""
          ) : (
            <div className="expohalls_header ">
              <Row className="h-100">
                <Col
                  className="d-flex justify-content-center align-items-center "
                  span={8}
                  sm={24}
                  xs={24}
                  md={8}
                  xl={8}
                >
                  <div className="d-flex position-relative w-75">
                    <SearchOutlined className="expohalls_searchicon" />
                    <Select
                      showSearch
                      className="expohalls_input_search"
                      placeholder="Search Exibitors"
                      optionFilterProp="children"
                      bordered={false}
                      value={fields.brand_id}
                      disabled={
                        brandData && brandData.length < 2 ? true : false
                      }
                    >
                      {brandData &&
                        brandData?.map((data, i) => {
                          return (
                            <Option key={"brand_" + i} value={data.brand_id}>
                              {data.brand_title}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                </Col>
                <Col
                  className="d-lg-flex align-items-center d-sm-block"
                  span={8}
                  sm={24}
                  xs={24}
                  md={8}
                  xl={8}
                >
                  <p className="text-center fs-12">
                    Click the brand icon you wish to visit or type <br /> the
                    brand name in the search box
                  </p>
                </Col>

                {(!props.isFromVista &&
                  userType &&
                  userType === "SUPER ADMIN") ||
                userType === "CUSTOMER" ? (
                  <Col className="d-flex mt-2 flex-column px-3" span={6}>
                    {props?.fromBrand !== true ? (
                      <div className="d-flex position-relative w-100 mt-2">
                        <Select
                          showSearch
                          placeholder="Select Event"
                          name="event_id"
                          allowClear
                          className="brands_event_search"
                          disabled={
                            eventList && eventList.length < 2 ? true : false
                          }
                          value={fields.event_id}
                          onChange={(val) => {
                            handleFieldsChange(val, "event_id");
                            dispatch(setVistaEventId(val));
                          }}
                          optionFilterProp="children"
                          listHeight={120}
                          listItemHeight={4}
                          bordered={false}
                        >
                          {eventList &&
                            eventList?.map((data, i) => {
                              return (
                                <Option
                                  key={"event_" + i}
                                  value={data.event_id}
                                >
                                  {data.event_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          )}
          <div className="expohalls_container ">
            {/* Top Block  */}
            <div className="expohalls_topblock"></div>
            {/* /Top Section/ */}

            {allExpoHalls &&
              allExpoHalls.map((item, i) => {
                return (
                  <>
                    <div
                      className="expohalls_hall"
                      key={"expohall_" + i}
                      onClick={() => {
                        dispatch(setVistaExpoHallSequence(item?.sequence));
                        dispatch(setExpoHallUrl(item?.expo_hall_url));

                        dispatch(
                          setVistaLocationId(item.expo_hall_configuration_id)
                        );

                        dispatch(
                          setExpoHallLocationNumber(
                            item.expo_hall_configuration_id
                          )
                        );
                        if (
                          props.isFromVista &&
                          item.expo_hall_configuration_id
                        ) {
                          let getExpoHallNumber = item.hall_title.split(" ")[2];
                          let expoMappingArr = [];
                          if (getExpoHallNumber % 2 === 0) {
                            expoMappingArr = item.expo_hall_mappings;
                          } else {
                            expoMappingArr = item.expo_hall_mappings.reverse();
                          }

                          hallData[0].url = item?.expo_hall_url;
                          dispatch(setExpoMappingArr(expoMappingArr));
                          expoMappingArr.forEach((val, index) => {
                            hallData[0].stands[val.defaultSequence - 1].status =
                              val.isElementPresent;
                            hallData[0].stands[
                              val.defaultSequence - 1
                            ].standID = val.brand_tier;
                            hallData[0].stands[
                              val.defaultSequence - 1
                            ].standUrl = val.final_booth_url;
                            hallData[0].stands[
                              val.defaultSequence - 1
                            ].brand_id = val?.brand_id;
                            hallData[0].stands[val.defaultSequence - 1].zones =
                              val?.zones;
                            hallData[0].stands[
                              val.defaultSequence - 1
                            ].brand_tier = val?.brand_tier;
                          });

                          AppDispatch({
                            type: ACTIONS.SET_ACTIVE_WINDOW,
                            value: "",
                          });
                          AppDispatch({
                            type: ACTIONS.SET_HALL,
                            value: hallData[0],
                          });
                        }
                      }}
                    >
                      {item.expo_hall_mappings.map((elem, elemIndex) => {
                        return (
                          <>
                            {props === null ? (
                              <div
                                className={
                                  selectedBoothBorder.display_name ===
                                    elem.display_name && !elem.logo_url
                                    ? "expohalls_booth booth_border"
                                    : "expohalls_booth "
                                }
                                key={"booth_" + elemIndex}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(setVistaBrandData(elem));
                                  if (
                                    elem.expo_hall_mapping_id &&
                                    props.isFromVista
                                  ) {
                                  }

                                  if (!elem?.logo_url) {
                                    setSelectedBoothBorder({
                                      display_name: elem?.display_name,
                                      flag: true,
                                    });
                                  }

                                  expoHallSelection(
                                    item.expo_hall_configuration_id,
                                    elem.sequence + 1
                                  );
                                }}
                              >
                                {elem.logo_url || !isEmpty(elem.logo_url) ? (
                                  <img
                                    className="expohall_booth_logo"
                                    src={elem.logo_url}
                                  />
                                ) : (
                                  <Tooltip
                                    title={
                                      elem?.brand_title ?? elem.display_name
                                    }
                                    placement="top"
                                  >
                                    <span className="expo_brand_title_text">
                                      {elem?.brand_title ?? elem.display_name}
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            ) : (
                              <div
                                className={` ${
                                  selectedBoothBorder.display_name ===
                                    elem.display_name && !elem?.logo_url
                                    ? "expohalls_booth booth_border"
                                    : "expohalls_booth "
                                } ${
                                  props?.brand_id &&
                                  elem?.brand_id === props?.brand_id
                                    ? "current_booth_border"
                                    : ""
                                } `}
                                key={"booth_" + elemIndex}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (!elem?.logo_url) {
                                    setSelectedBoothBorder({
                                      display_name: elem?.display_name,
                                    });
                                  }
                                  if (props.getDataFromExpoHall) {
                                    expoHallSelection(
                                      item.expo_hall_configuration_id,
                                      elem.sequence + 1
                                    );
                                  }

                                  if (
                                    elem?.final_booth_url &&
                                    props?.isFromVista
                                  ) {
                                    dispatch(
                                      setExpoHallUrl(item?.expo_hall_url)
                                    );

                                    dispatch(setVistaBrandData(elem));
                                    dispatch(setVistaLocationId(elem.brand_id));
                                    dispatch(
                                      setBoothLocationNumber(elem.brand_id)
                                    );

                                    if (
                                      elem.expo_hall_mapping_id &&
                                      elem.brand_tier &&
                                      props.isFromVista
                                    ) {
                                      AppDispatch({
                                        type: ACTIONS.SET_ACTIVE_WINDOW,
                                        value: "",
                                      });

                                      let selectedStand =
                                        standsData[elem.brand_tier - 1];
                                      selectedStand.exit = Math.ceil(
                                        elem.sequence / 2
                                      );

                                      selectedStand.url = elem?.final_booth_url;

                                      if (elem.zones) {
                                        elem.zones.forEach((item) => {
                                          if (item?.booth_location_id) {
                                            let obj = standsData[
                                              elem.brand_tier - 1
                                            ].hotspots.find((val) => {
                                              return (
                                                val.index ===
                                                item?.booth_location_id
                                              );
                                            });

                                            if (obj) {
                                              obj.status = item?.is_active;
                                            }
                                          }
                                        });
                                      }
                                      selectedStand.lookAtBooth =
                                        elem?.sequence;

                                      //Logic for Active Inavtive hotspots for booth
                                      let getExpoHallNumber =
                                        item.hall_title.split(" ")[2];

                                      let expoMappingArr = [];
                                      if (getExpoHallNumber % 2 === 0) {
                                        expoMappingArr =
                                          item.expo_hall_mappings;
                                      } else {
                                        expoMappingArr =
                                          item.expo_hall_mappings.reverse();
                                      }

                                      hallData[0].url = item?.expo_hall_url;
                                      dispatch(
                                        setExpoMappingArr(expoMappingArr)
                                      );
                                      expoMappingArr.forEach((val, index) => {
                                        hallData[0].stands[
                                          val.defaultSequence - 1
                                        ].status = val.isElementPresent;
                                        hallData[0].stands[
                                          val.defaultSequence - 1
                                        ].standID = val.brand_tier;
                                        hallData[0].stands[
                                          val.defaultSequence - 1
                                        ].standUrl = val.final_booth_url;
                                        hallData[0].stands[
                                          val.defaultSequence - 1
                                        ].brand_id = val?.brand_id;
                                        hallData[0].stands[
                                          val.defaultSequence - 1
                                        ].zones = val?.zones;
                                        hallData[0].stands[
                                          val.defaultSequence - 1
                                        ].brand_tier = val?.brand_tier;
                                      });

                                      AppDispatch({
                                        type: ACTIONS.SET_STAND,
                                        value: selectedStand,
                                      });
                                    }
                                  } else {
                                    if (props?.isFromVista) {
                                      toast.error("Vista Not Uploaded");
                                    }
                                  }
                                }}
                              >
                                {elem.logo_url || !isEmpty(elem.logo_url) ? (
                                  <img
                                    className="expohall_booth_logo"
                                    src={elem.logo_url}
                                  />
                                ) : (
                                  <Tooltip
                                    title={
                                      elem?.brand_title ?? elem.display_name
                                    }
                                    placement="top"
                                  >
                                    <span className="expo_brand_title_text">
                                      {elem?.brand_title ?? elem.display_name}
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </>
                        );
                      })}

                      <div className="expohall_hallname">
                        <h6> {item.hall_title ? item.hall_title : "NA"}</h6>
                      </div>
                    </div>
                  </>
                );
              })}

            {expoHallDetails.length < 1 && (
              <div className="mx-auto text-dark text-bold mt-2 fs-18">
                No Expo Hall available for this event
              </div>
            )}

            {expoHallDetails.length === 1 ? (
              <>
                <div className="expohalls_hall bg-white">
                  {/* <Empty className="mx-auto" /> */}
                </div>
                <div className="expohalls_hall bg-white">
                  {/* <Empty className="mx-auto" /> */}
                </div>
                <div className="expohalls_hall bg-white">
                  {/* <Empty className="mx-auto" /> */}
                </div>
              </>
            ) : expoHallDetails.length === 2 ? (
              <>
                <div className="expohalls_hall bg-white">
                  {/* <Empty className="mx-auto" /> */}
                </div>
                <div className="expohalls_hall bg-white">
                  {/* <Empty className="mx-auto" /> */}
                </div>
              </>
            ) : expoHallDetails.length === 3 ? (
              <>
                <div className="expohalls_hall bg-white">
                  {/* <Empty className="mx-auto" /> */}
                </div>
              </>
            ) : (
              ""
            )}

            {/* <div className="expohalls_bottomblock"></div> */}
          </div>
        </div>
      </div>
    );
};

export default ExpoHalls;
