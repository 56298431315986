import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

export const getUsers = createAsyncThunk(
  "userManagement/getUsers",
  async (params) => {
    const response = await ApiCall("GET", "", "/admin/user/get", params);
    return response.data.data;
  }
);

export const getPeople = createAsyncThunk(
  "userManagement/getPeople",
  async (params) => {
    const response = await ApiCall("GET", "", "/admin/people/get", params);

    return response.data.data;
  }
);

export const changeStatus = createAsyncThunk(
  "userManagement/changeStatus",
  async (body) => {
    await ApiCall("PUT", body, "/admin/user/status/update");
    const response = await ApiCall("GET", "", "/admin/user/get");
    return response.data.data;
  }
);

export const viewUserDetails = createAsyncThunk(
  "userManagement/viewUserDetails",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/user/details/get",
      params
    );
    return response.data;
  }
);

export const addUser = createAsyncThunk(
  "userManagement/addUser",
  async (body) => {
    const response = await ApiCall("POST", body, "/admin/user/add");

    return response;
  }
);

export const editUser = createAsyncThunk(
  "userManagement/editUser",
  async (body) => {
    const response = await ApiCall("PUT", body, "/admin/user/update");

    return response;
  }
);

export const inviteUser = createAsyncThunk(
  "userManagement/inviteUser",
  async (body) => {
    const response = await ApiCall("POST", body, "/admin/user/invite");
    return response;
  }
);

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, { payload, meta }) => {
      if (payload) state.data = payload;
      else state.data = [];
      state.loading = false;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
    },
    [addUser.pending]: (state, action) => {
      state.loading = true;
    },
    [addUser.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
    },
    [addUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [editUser.pending]: (state, action) => {
      state.loading = true;
    },
    [editUser.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
    },
    [editUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [changeStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [changeStatus.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [changeStatus.rejected]: (state, action) => {
      state.loading = false;
    },
    [inviteUser.pending]: (state, action) => {
      state.loading = true;
    },
    [inviteUser.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
    },
    [inviteUser.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const userReducer = userManagementSlice.reducer;
