import React from "react";

export const ThreadChat = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5923 3.00001C11.0965 2.99785 9.62745 3.39634 8.33749 4.15413C7.04754 4.91191 5.98372 6.00137 5.25642 7.30946C4.52913 8.61754 4.16487 10.0966 4.20145 11.5931C4.23802 13.0896 4.6741 14.5491 5.46442 15.82L3 21L8.17504 18.5336C9.27872 19.2204 10.5267 19.6412 11.8206 19.7631C13.1144 19.8849 14.4189 19.7043 15.6312 19.2357C16.8435 18.767 17.9305 18.023 18.8065 17.0622C19.6826 16.1015 20.3237 14.9503 20.6794 13.6993C21.0352 12.4483 21.0958 11.1318 20.8565 9.85335C20.6172 8.57494 20.0846 7.36961 19.3005 6.33232C18.5165 5.29503 17.5025 4.45412 16.3384 3.87586C15.1743 3.2976 13.8919 2.99777 12.5923 3.00001V3.00001Z"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.99359 9H16.1878"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.79456 11.4H17.3868"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.99359 13.8H16.1878"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
