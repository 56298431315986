import React, { useEffect } from "react";
import "./index.css";
import { isRouteExistInMenu } from "../../utils/commonFunctions";
import Survey from "../surveyManagement/surveyscreens";

const SurveyFeedback = () => {
  var CryptoJS = require("crypto-js");

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    }
  }, []);

  return <Survey fromFeedback={true} />;
};

export default SurveyFeedback;
