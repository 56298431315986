import React from "react";

export const ImageAttach = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M6.53296 8.24104C6.53296 8.7396 6.71733 9.21773 7.04552 9.57026C7.37371 9.92279 7.81883 10.1208 8.28296 10.1208C8.74709 10.1208 9.19221 9.92279 9.5204 9.57026C9.84858 9.21773 10.033 8.7396 10.033 8.24104C10.033 7.74249 9.84858 7.26435 9.5204 6.91182C9.19221 6.55929 8.74709 6.36124 8.28296 6.36124C7.81883 6.36124 7.37371 6.55929 7.04552 6.91182C6.71733 7.26435 6.53296 7.74249 6.53296 8.24104Z"
            stroke="#808080"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.5282 16.7177L16.4477 10.1459C16.3765 10.0316 16.2805 9.93755 16.1678 9.87207C16.0552 9.80658 15.9294 9.77158 15.8013 9.7701C15.6733 9.76862 15.5468 9.8007 15.4329 9.86357C15.319 9.92643 15.221 10.0182 15.1476 10.1309L13.0522 13.3541L11.7549 12.2343C11.6697 12.1609 11.5712 12.1074 11.4657 12.0772C11.3602 12.047 11.25 12.0407 11.1421 12.0587C11.0342 12.0767 10.931 12.1186 10.839 12.1818C10.747 12.245 10.6683 12.3281 10.6078 12.4257L7.93384 16.7368"
            stroke="#808080"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.775 2.75C3.4037 2.75 3.0476 2.90844 2.78505 3.19047C2.5225 3.47249 2.375 3.855 2.375 4.25384V19.2462C2.375 19.645 2.5225 20.0275 2.78505 20.3095C3.0476 20.5916 3.4037 20.75 3.775 20.75H21.975C22.3463 20.75 22.7024 20.5916 22.9649 20.3095C23.2275 20.0275 23.375 19.645 23.375 19.2462V7.21541C23.375 6.82321 23.2323 6.44654 22.9774 6.16573L20.2866 3.20316C20.1561 3.05962 20.0002 2.94558 19.828 2.86775C19.6559 2.78993 19.471 2.74989 19.2842 2.75H3.775Z"
            stroke="#808080"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
