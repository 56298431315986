import React, { useState, useEffect } from "react";
import { Button, Steps, message, Breadcrumb } from "antd";
import "./event.css";
import EventEditor from "./ViewEventsDetails/EventEditor";
import RegistrationAndCommunication from "./ViewEventsDetails/RegistrationAndCommunication";
import NavigationAndReception from "./ViewEventsDetails/NavigationAndReception";
import SocialWall from "./ViewEventsDetails/SocialWall";
import { ApiCall } from "../../utils/api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Tiers from "./ViewEventsDetails/Tiers";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { isRouteExistInMenu } from "../../utils/commonFunctions";
import EventSettings from "./ViewEventsDetails/EventSettings";

function ViewEvent(props) {
  const { match } = props;
  const { Step } = Steps;
  var CryptoJS = require("crypto-js");
  const [eventData, setEventData] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    async function stateLoad() {
      let is_route_present = await isRouteExistInMenu(CryptoJS);
      if (is_route_present === false) {
        window.location.href = "/pagenotfound";
      } else {
        if (match?.params?.event_id) {
          const response = await ApiCall(
            "GET",
            "",
            `/admin/events/details/get?event_id=${match?.params?.event_id}`,
            ""
          );

          setLoading(false);
          if (response?.data?.code === 200) {
            setEventData(response?.data?.data);
          } else if (response?.data?.code === 404) {
            toast.error(response?.data?.message);
            setAccessDenied(true);
          } else toast.error(response?.data?.message);
        }
      }
    }
    stateLoad();
  }, [CryptoJS.AES]);

  const steps = [
    {
      title: "Event Editor",
      content: <EventEditor data={eventData.editor_details} />,
    },
    {
      title: "Event Settings",
      content: (
        <EventSettings
          data={eventData.event_settings_details}
          onNavigateToRegistration={() => setCurrentStep(currentStep + 1)}
          onNavigateToTier={() => setCurrentStep(currentStep + 2)}
          onNavigateToReception={() => setCurrentStep(currentStep + 3)}
          onNavigateToSocialWall={() => setCurrentStep(currentStep + 4)}
        />
      ),
    },
    {
      title: "Registration and Communication",
      content: (
        <RegistrationAndCommunication
          data={eventData.registration_and_communication_details}
        />
      ),
    },
    {
      title: "Tiers",
      content: <Tiers tierData={eventData.tier_details} />,
    },
    {
      title: "Navigation + Reception",
      content: (
        <NavigationAndReception
          data={eventData.navigation_plus_reception_details}
        />
      ),
    },
    {
      title: "Social Wall",
      content: <SocialWall data={eventData.social_wall_details} />,
    },
  ];

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onChange = (current) => {
    setCurrentStep(current);
  };

  if (loading) return <CommonLoader />;
  else
    return (
      <div className="site-layout-background">
        {accessDenied === false && (
          <div className="customer-management-form">
            <div className="background-color-gray">
              <div className="position-fixed d-flex justify-content-between bg-light w-100 common-sticky-header height_70px">
                <div>
                  <h4 className="fs-24">View Event Details</h4>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <Link to="/eventmanagement">Admin Console</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/eventmanagement">Event Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>View Event Details</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
            <Steps
              type="navigation"
              current={currentStep}
              onChange={onChange}
              className="site-navigation-steps"
              style={{
                position: "sticky",
                zIndex: "999999",
                top: "130px",
                margin: 0,
                padding: 0,
              }}
            >
              {steps.map((item) => (
                <Step
                  key={item.title}
                  title={item.title}
                  className="bg-white"
                />
              ))}
            </Steps>
            <div className="px-4 bg-white">
              <div className="steps-content">{steps[currentStep].content}</div>
              <div className="steps-action py-4">
                {currentStep > 0 && (
                  <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                    Previous
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => next()}
                    className="justify-content-end"
                  >
                    Next
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => message.success("Processing complete!")}
                  >
                    Done
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        {accessDenied === true && (
          <Link to="/eventmanagement">
            <Button className="px-3 d-block button-pink rounded mt-4 my-2">
              Cancel
            </Button>
          </Link>
        )}
      </div>
    );
}

export default ViewEvent;
