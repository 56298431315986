import * as React from "react";
import "./zoneCalender.css";
import { isAdminLevelUser } from "../../utils/commonFunctions";
import ProductZoneChat from "../commonPopups/ProductZoneChat";
import ProductZoneDemo from "../commonPopups/ProductZoneDemo";
import { isEmpty } from "lodash";
export default class AppointmentFormContainerBasic extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      appointmentChanges: {},
    };

    this.getAppointmentData = () => {
      const { appointmentData } = this.props;
      return appointmentData;
    };
    this.getAppointmentChanges = () => {
      const { appointmentChanges } = this.state;
      return appointmentChanges;
    };

    this.changeAppointment = this.changeAppointment.bind(this);
    this.commitAppointment = this.commitAppointment.bind(this);
  }

  changeAppointment({ field, changes }) {
    const nextChanges = {
      ...this.getAppointmentChanges(),
      [field]: changes,
    };
    this.setState({ appointmentChanges: nextChanges });
  }

  commitAppointment(type) {
    const { commitChanges } = this.props;
    const appointment = {
      ...this.getAppointmentData(),
      ...this.getAppointmentChanges(),
    };
    if (type === "deleted") {
      commitChanges({ [type]: appointment.id });
    } else if (type === "changed") {
      commitChanges({ [type]: { [appointment.id]: appointment } });
    } else {
      commitChanges({ [type]: appointment });
    }
    this.setState({ appointmentChanges: {} });
  }

  render() {
    const { visible, appointmentData, target, onHide, userType } = this.props;
    if (!visible) return null;
    else if (!isAdminLevelUser(userType)) return null;
    else if (!appointmentData || isEmpty(appointmentData)) return null;
    else if (appointmentData.priorityId !== 2)
      return (
        <div
          style={{
            width: "90%",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,0)",
          }}
        >
          {appointmentData.priorityId === 1 ? (
            <ProductZoneDemo
              data={{ ...appointmentData, ...this.props }}
              isFromCalender="zone"
            />
          ) : null}
          {appointmentData.priorityId === 3 ? (
            <ProductZoneChat
              data={{ ...appointmentData, ...this.props }}
              isFromCalender="zone"
            />
          ) : null}
        </div>
      );
    else return null;
  }
}
