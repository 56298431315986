import React from "react";

export const SendRed = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          id="send"
          d="M.278,19.836a.743.743,0,0,1-.256-.76L2.292,10,.022.923A.743.743,0,0,1,1.075.078L19.59,9.336a.743.743,0,0,1,0,1.329L1.075,19.922a.743.743,0,0,1-.8-.085Zm1.578-1.966L17.6,10,1.856,2.13,3.638,9.258H10a.743.743,0,0,1,0,1.485H3.638Z"
          fill="#ef0855"
        />
      </svg>
    </>
  );
};
