import React from "react";

export const FileAttach = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2 21C16.5183 21 16.8235 20.8736 17.0485 20.6485C17.2736 20.4235 17.4 20.1183 17.4 19.8V15C17.4 14.6817 17.2736 14.3765 17.0485 14.1515C16.8235 13.9264 16.5183 13.8 16.2 13.8H9V12.6C9 12.2817 8.87357 11.9765 8.64853 11.7515C8.42348 11.5264 8.11826 11.4 7.8 11.4H4.2C3.88174 11.4 3.57652 11.5264 3.35147 11.7515C3.12643 11.9765 3 12.2817 3 12.6V19.8C3 20.1183 3.12643 20.4235 3.35147 20.6485C3.57652 20.8736 3.88174 21 4.2 21H16.2Z"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.79999 11.4V4.2C7.79999 3.88174 7.92642 3.57652 8.15146 3.35147C8.3765 3.12643 8.68173 3 8.99999 3H17.5032C17.8212 3.00007 18.1262 3.12639 18.3512 3.3512L20.6488 5.6488C20.8736 5.87376 20.9999 6.17876 21 6.4968V17.4C21 17.7183 20.8736 18.0235 20.6485 18.2485C20.4235 18.4736 20.1182 18.6 19.8 18.6H17.4"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.4 6.60001H17.4"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.4 10.2H17.4"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
