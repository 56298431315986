import React from "react";
import { logo } from "../Images/Images";
import "./index.css";
import Text from "antd/lib/typography/Text";

function CommonLoader(props) {
  return (
    <div className="appLoader">
      <img src={logo} alt="logo" style={{ width: "200px", height: "200px" }} />
      <Text
        style={{
          marginTop: "-2%",
          fontWeight: 800,
          fontSize: "20px",
        }}
      >
        {props.textForLoader ? props.textForLoader : "Please wait..."}
      </Text>
    </div>
  );
}

export default CommonLoader;
