import React, { useEffect, useState, useContext } from "react";
import "./ExpoMap.css";

import { Layout, Menu, Result } from "antd";
import { ApiCall } from "../../utils/api";

import VistaApp from "../Vista/src/VistaApp";

import BrandZoneDetails from "../ExpoMap/BrandZoneDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderData,
  setHeaderEventList,
} from "../../redux/slice/headerData";
import { clearLocationApi, getEvents } from "../../utils/commonApis";
import { setVisitBoothData, setVistaEventId } from "../../redux/slice/vista";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  getCookie,
  isRouteExistInMenu,
  generateHelmet,
} from "../../utils/commonFunctions";

import Brands from "./Brands";
import { SmileOutlined } from "@ant-design/icons";

const { Content } = Layout;

const ExpoMap = (props) => {
  let CryptoJS = require("crypto-js");

  const [loader, setLoader] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");
  const dispatch = useDispatch();

  const ciphertext = localStorage.getItem("user_type");
  let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
  const [userType, setUserType] = useState("");

  const [isEventExist, setIsEventExist] = useState(false);
  let vistaEventId = useSelector((state) => state?.vistaBrand?.eventId); //from Vista redux
  let reloadVista = useSelector((state) => state?.vistaBrand?.reloadVista); //from Vista redux

  let vistaLocationInterval = useSelector(
    (state) => state?.vistaBrand?.vistaLocationInterval
  ); //from Vista redux

  const [eventID, setEventID] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [userId, setUserId] = useState(null);

  const socket = props?.socket;

  useEffect(() => {
    let event_id = null;
    let user_id = null;
    localStorage.setItem("event_name", "");
    if (localStorage.getItem("event_id")) {
      if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
        event_id = vistaEventId;
      } else {
        event_id = localStorage.getItem("event_id");
      }
      setEventID(event_id);
    }
    if (getCookie("user_id")) {
      const ciphertext = getCookie("user_id");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
      user_id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserId(user_id);
    }

    let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    //Keep this useffect for unmount seperate
    return () => {
      dispatch(
        setHeaderData({
          title: "",
          breadcrumbs: [],
        })
      );
      dispatch(setHeaderEventList([]));
      dispatch(setVistaEventId(null));

      dispatch(
        setVisitBoothData({
          tier: null,
          sequence: null,
          final_booth_url: null,
        })
      );

      socket.emit(
        "updateLocation",
        JSON.stringify({
          location: "userLeft",
          event_id: event_id,
          user_id: user_id,
          user_type: user_type,
        })
      );

      // window.removeEventListener(
      //   "unload",
      //   handleTabClosed
      // ); //To remove user if user closes the tab
    };
  }, []);

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(user_type);

      const events = await getEvents();

      dispatch(setHeaderEventList(events));
      setEventList(events);
      if (user_type !== "SUPER ADMIN" && user_type !== "CUSTOMER") {
        checkIFEventExistOrNot(
          parseInt(localStorage.getItem("event_id")),
          events
        );
      } else {
        setLoader(false);
      }

      dispatch(
        setHeaderData({
          title: "Expo Map",
          breadcrumbs: ["Expo Map", "Expo Halls"],
        })
      );
    }
  }, [CryptoJS.AES]);

  // useEffect(() => {
  //   return async () => {
  //     clearLocationApi("userLeft", vistaLocationInterval);
  //   };
  // }, [vistaLocationInterval]);

  useEffect(async () => {
    if (vistaEventId && eventList) {
      setLoader(true);
      checkIFEventExistOrNot(vistaEventId, eventList);
    }
  }, [vistaEventId, eventList]);

  useEffect(() => {
    if (selectedMenuItem === "1") {
      dispatch(
        setHeaderData({
          title: "Expo Map",
          breadcrumbs: ["Expo Map", "Expo Halls"],
        })
      );
    } else if (selectedMenuItem === "2") {
      dispatch(
        setHeaderData({
          title: "Expo Map",
          breadcrumbs: ["Expo Map", "Brands"],
        })
      );
    }
  }, [selectedMenuItem]);

  const handleTabClosed = async (e) => {
    e.preventDefault();
    e.returnValue = "";

    // clearLocationApi("userLeft", vistaLocationInterval);
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", "")
      .then((response) => {
        const { rows } = response?.data?.data;
      })
      .catch((err) => {});
  };

  const checkIFEventExistOrNot = (id, list) => {
    if (list && list.some((item) => item.event_id === id)) {
      setIsEventExist(true);
    } else {
      setIsEventExist(false);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const eventName = localStorage.getItem("event_name");

  if (loader) return <CommonLoader />;
  else
    return (
      <div>
        {generateHelmet(eventName, "Expo Map")}
        {isEventExist ? (
          <div>
            <VistaApp socket={"test123"} props={props} />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center height_80vh">
            {!vistaEventId &&
            (userType === "SUPER ADMIN" || userType === "CUSTOMER") ? (
              <div className="empty_event">
                <Result
                  icon={<SmileOutlined />}
                  title={
                    <h4 className="fs-pink">
                      Please choose your event in the top right corner
                    </h4>
                  }
                />
              </div>
            ) : (
              <h5 className="fs-pink">No Event Assigned yet</h5>
            )}
          </div>
        )}
      </div>
    );
};

export default ExpoMap;
