import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import {
  Row,
  Col,
  Carousel,
  Button,
  Select,
  DatePicker,
  Divider,
  Tooltip,
  Avatar,
  Input,
  Empty,
  Modal,
  Result,
} from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { SearchOutlined, SmileOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { Booth } from "../../components/svgJS/Booth";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { Download } from "../../components/svgJS/Download";
import { Calendar6 } from "../../components/svgJS/Calendar6";
import { User } from "../../components/svgJS/User";
import {
  getEventNetworkingLoungeDetails,
  getNetworkingLoungeSessionDetails,
  getNetworkingSessionTableDetails,
  onNetworkingJoinSessClickFlag,
} from "../../redux/slice/networking";
import { useDispatch, useSelector } from "react-redux";
import NetworkTableUI from "../networkTable";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  addToMyCalender,
  sendMessageAPI,
  updateAssetDownloadCount,
  videoRequestAPI,
} from "../../utils/commonApis";
import {
  DEFAULT_CHATBOT_HISTORY,
  TIME_FORMAT,
} from "../../components/constsnts/common.constants";
import {
  getEvents,
  fetchChatbotHistory,
  getChatbotJson,
  saveChatbotHistory,
} from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import {
  checkIfChatbotTimeSlot,
  createISOTimeString,
  createTZString,
  findValueFromList,
  generateHelmet,
  getComentChatUser,
  isRouteExistInMenu,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import CustomChatbot from "../../components/Widgets/CustomChatbot";
import ProfileModal from "../common/Modal/ProfileModal";

import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import ICalendarLink from "react-icalendar-link";
import CountDownTimer from "../VideoConference/CountDownTimer";
import { useMediaQuery } from "react-responsive";
import {
  setDemoRoomFlag,
  setVideoConferenceData,
} from "../../redux/slice/videoConference";
import placeholder_image from "../../../src/assets/images/profile_placeholder.png";

const Networking = (props) => {
  const { socket } = props;

  let CryptoJS = require("crypto-js");
  const messageValidator = useRef(new SimpleReactValidator());
  const { Option } = Select;
  const dateFormat = "LL";
  const { TextArea } = Input;
  const isMobile = useMediaQuery({ minWidth: 400 });
  const joinTableRef = useRef(null);
  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId); //from Vista redux
  const dispatch = useDispatch();

  const eventNetworkingLoungeDetails = useSelector(
    (state) => state.networking.eventNetLoungeData
  );

  const networkingLoungeSessionDetails = useSelector(
    (state) => state.networking.networkingLoungeSessionData
  );

  const networkingSessionTableDetails = useSelector(
    (state) => state.networking.networkingTableData
  );

  const joinSessClickDetails = useSelector(
    (state) => state.networking.joinSessClickDetails
  );

  const loadNetworking = useSelector((state) => state.networking.loading);

  const [userType, setUserType] = useState();
  const [disableEvent, setDisableEvent] = useState(false);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [showEmpty, setShowEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [cardSponsorData, setCardSponsorData] = useState([]);
  const [showTable, setShowTable] = useState(
    props?.fromEventCalendar ? true : false
  );
  const [joinTableBtn, setJoinTableBtn] = useState(false);
  const [joinTableIDs, setJoinTableIDs] = useState({
    networking_configuration_id: props?.networking_configuration_id ?? null,
    networking_session_id: props?.networking_session_id ?? null,
  });
  const [eventid, setEventID] = useState();
  const [sponsorData, setSponsorData] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment());
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [eventNetLoungeData, setEventNetLoungeData] = useState([]);
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState(
    DEFAULT_CHATBOT_HISTORY
  );
  const [tableData, setTableData] = useState({});
  const [currentLoungeIndex, setCurrentLoungeIndex] = useState(0);
  const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const [intervalTable, setIntervalTable] = useState(null);
  let [userNearbyArr, setUserNearbyArr] = useState([]);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [userData, setUserData] = useState(null);
  const [joinTableSessionTimings, setJoinTableSessionTimings] = useState({
    startTime: null,
    endTime: null,
  });
  const [disableSendBtn, setDisableSendBtn] = useState(false);

  useEffect(async () => {
    setLoading(true);
    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(user_type);
      const eventsData = await getEvents();
      let eventIdHere = "";
      if (
        user_type === "DELEGATE" ||
        user_type === "SPEAKER" ||
        user_type === "BRAND ADMIN" ||
        user_type === "AGENT"
      ) {
        let localData_event_id = null;
        if (localStorage.getItem("event_id")) {
          localData_event_id = parseInt(localStorage.getItem("event_id"));
        }
        const events = eventsData.filter((item) => {
          return item.event_id === localData_event_id;
        });
        setEventList(events);
        let id = events[0]?.event_id;
        eventIdHere = id;
        setEventID(id);
        setDisableEvent(true);
      } else {
        if (eventsData.length === 1) {
          setEventID(eventsData[0].event_id);
          localStorage.setItem("event_name", eventsData[0].event_name);
          eventIdHere = eventsData[0].event_id;
        }
        setEventList(eventsData);
      }

      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        localStorage.setItem("event_name", "");
      }

      if (eventIdHere) {
        const chatHistory = await fetchChatbotHistory({
          event_id: eventIdHere,
        });
        if (chatHistory && !isEmpty(chatHistory))
          set_chatbot_history(chatHistory);
      }

      const cometRes = await getComentChatUser();
      setCometChatUser(cometRes);
    }
    setLoading(false);
  }, [CryptoJS.AES]);

  useEffect(() => {
    //The unmount is not working in the above useEffect so made this new useEffect to clear the shuffle interval
    return () => {
      clearInterval(intervalTable);
    };
  }, [intervalTable]);

  useEffect(() => {
    if (currentDate && eventid) {
      dispatch(
        getEventNetworkingLoungeDetails({
          event_id: eventid,
          date: currentDate.format("YYYY-MM-DD"),
        })
      );
    }
  }, [currentDate, eventid]);

  useEffect(() => {
    if (eventNetworkingLoungeDetails && eventid) {
      setLoading(true);
      initializeEventNetLoungeData();
      getUserLocationVista();
      setLoading(false);
    }
  }, [eventNetworkingLoungeDetails]);

  useEffect(() => {
    if (eventNetLoungeData) {
      changeInLounges(currentLoungeIndex);
    }
  }, [eventNetLoungeData]);

  useEffect(() => {
    if (networkingSessionTableDetails) {
      changesInTables(currentTableIndex);
    }
  }, [networkingSessionTableDetails]);

  useEffect(() => {
    setLoading(true);
    if (networkingLoungeSessionDetails && eventid) {
      setCurrentAvailableSessions();
      setCurrentAvailableSponsors();
    }
    setLoading(false);
  }, [networkingLoungeSessionDetails, currentDate]);

  useEffect(() => {
    if (
      (window.location.pathname === "/expomap" ||
        window.location.pathname === "/concourse") &&
      vistaEventId
    ) {
      setEventID(vistaEventId);
    }
  }, [vistaEventId]);

  useEffect(() => {
    if (joinSessClickDetails?.flag && joinTableIDs && joinTableBtn) {
      initializedJoinTableData();
      dispatch(
        onNetworkingJoinSessClickFlag({
          flag: false,
          networking_session_id: null,
          networking_configuration_id: null,
        })
      );
    }
  }, [joinSessClickDetails?.flag, joinTableIDs, joinTableBtn]);

  useEffect(() => {
    const totalTableJoineesListener = (res) => {
      console.log("totalTableJoineesListener", res);

      console.log(
        res?.data?.networking_lounge_session_table_id ===
          tableData?.networking_lounge_session_table_id,
        "compare totalTableJoineesListener ids"
      );
      if (
        res?.data?.networking_lounge_session_table_id ===
        tableData?.networking_lounge_session_table_id
      ) {
        setTableData(res?.data);
      }
    };

    socket.on("totalTableJoinees", totalTableJoineesListener);

    return () => {
      socket?.off("totalTableJoinees", totalTableJoineesListener);
    };
  }, [socket, tableData]);

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      setDisableSendBtn(true);
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
    setDisableSendBtn(false);
  }

  const initializeEventNetLoungeData = () => {
    let selectedDate = moment(currentDate).format("YYYY-MM-DD");
    const arr = eventNetworkingLoungeDetails?.filter((ele) => {
      const start_date_time = createTZString(ele.start_date_time);
      const eleStartDate = moment.tz(start_date_time, "YYYY-MM-DD");

      if (selectedDate === eleStartDate.format("YYYY-MM-DD")) return ele;
    });

    setEventNetLoungeData(arr);
  };

  const setCurrentAvailableSessions = () => {
    const { session_details } = networkingLoungeSessionDetails;
    const card_sponsorDatas = [];
    const selectedDate = moment(currentDate).format("YYYY-MM-DD");
    const sessData = session_details?.filter((ele) => {
      const start_date_time = createTZString(ele.start_date_time);
      const end_date_time = createTZString(ele.end_date_time);
      const eleStartDate = moment(
        start_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      const eleEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);
      let currentTime = moment(moment().format("HH:mm"), TIME_FORMAT);
      if (selectedDate === eleStartDate.format("YYYY-MM-DD")) {
        if (currentTime >= eleEndTime) {
          //To filter out if Session is over
          return;
        } else {
          card_sponsorDatas.push(ele?.networking_lounge_sponsoring_brands);
          return ele;
        }
      }
    });
    setCardSponsorData(card_sponsorDatas);

    if (sessData?.length === 0) {
      setShowEmpty(true);
      setJoinTableBtn(false);
    } else {
      setShowEmpty(false);
    }

    setSessionData(sessData);
    if (sessData?.length !== 0) {
      let obj = sessData?.find((ele) => {
        const start_date_time = createTZString(ele.start_date_time);
        const end_date_time = createTZString(ele.end_date_time);
        const eleStartTime = moment(
          start_date_time.format("HH:mm"),
          TIME_FORMAT
        );
        const eleEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);
        const currentTime = moment(moment().format("HH:mm"), TIME_FORMAT);
        if (currentTime >= eleStartTime && currentTime <= eleEndTime)
          return ele;
      });

      if (obj) {
        setJoinTableBtn(true);
        //Aman
        const start_date_time = createTZString(obj.start_date_time);
        const end_date_time = createTZString(obj.end_date_time);

        // let utcStartTime = moment(
        //   moment(obj.start_date_time).utc().format("YYYY-MM-DD HH:mm:ss A")
        // );

        const eleStartTime = moment(
          start_date_time.format("HH:mm:ss A"),
          "HH:mm:ss A"
        );

        const eleEndTime = moment(
          end_date_time.format("HH:mm:ss A"),
          "HH:mm:ss A"
        );

        setJoinTableSessionTimings({
          startTime: eleStartTime,
          endTime: eleEndTime,
        });

        setJoinTableIDs({
          networking_configuration_id: obj?.networking_configuration_id,
          networking_session_id: obj?.networking_session_id,
          event_id: eventid,
        });
      } else setJoinTableBtn(false);
    }
  };

  const setCurrentAvailableSponsors = () => {
    const { sponsor_details, default_sponsor_details } =
      networkingLoungeSessionDetails;

    const selectedDate = moment(currentDate).format("YYYY-MM-DD");
    const sponsData = sponsor_details?.filter((ele) => {
      const start_date_time = createTZString(ele.start_date_time);
      const end_date_time = createTZString(ele.end_date_time);
      const eleStartDate = moment(
        start_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      const eleStartTime = moment(start_date_time.format("HH:mm"), TIME_FORMAT);
      const eleEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);
      const currentTime = moment(moment().format("HH:mm"), TIME_FORMAT);

      if (selectedDate === eleStartDate.format("YYYY-MM-DD")) {
        if (currentTime >= eleStartTime && currentTime <= eleEndTime)
          return ele;
        else return;
      }
    });
    if (sponsData?.length === 0) {
      let modifiedSponsorData = {
        ...default_sponsor_details,
        is_booth_link_enable: default_sponsor_details?.is_on_booth_leads_enable,
        is_demo_room_enable: default_sponsor_details?.is_video_demo_room_enable,
        is_video_call_enable:
          default_sponsor_details?.is_one_to_one_video_calls_enable,
        is_asset_enable: default_sponsor_details?.asset_file_url,
        logo_url: default_sponsor_details?.brand_logo,
        zone_demo_room_link: default_sponsor_details?.zone_demo_room_link,
      };

      sponsData.push(modifiedSponsorData);
    }
    setSponsorData(sponsData);
  };

  const changeInLounges = (index) => {
    if (eventNetLoungeData) {
      const item = eventNetLoungeData.find((elem, i) => {
        return i === index;
      });

      if (item) {
        dispatch(
          getNetworkingLoungeSessionDetails({
            networking_configuration_id: item?.networking_configuration_id,
            event_id: eventid,
          })
        );
      }
    }
  };

  const changesInTables = (index) => {
    setLoadingTable(true);
    setCurrentTableIndex(index);

    if (networkingSessionTableDetails) {
      const currentTableData = networkingSessionTableDetails?.find(
        (elem, i) => {
          return i === index;
        }
      );

      if (currentTableData) {
        setTableData(currentTableData);
      }
    }
    setLoadingTable(false);
  };

  const searchSessions = (e) => {
    // setFilteredSessions([]);
    let searchTerm = e.target.value.toLowerCase().trim();

    let allSearchedData = [];
    if (searchTerm !== "" || !isEmpty(searchTerm)) {
      sessionData &&
        sessionData?.forEach((item) => {
          if (item?.session_title?.toLowerCase().includes(searchTerm)) {
            allSearchedData.push(item);
          }
        });
    }

    setFilteredSessions(allSearchedData);
  };

  const getUserLocationVista = async () => {
    setUserNearbyArr([]);
    const response = await ApiCall("GET", "", "/admin/vista/userlocation/get", {
      location: "networkingPublished",
    });
    if (response && response.data.code === 200) {
      if (response?.data?.data && !isEmpty(response?.data?.data)) {
        response?.data?.data?.forEach((user) => {
          setUserNearbyArr((prev) => [
            ...prev,
            {
              userName: user.first_name + " " + user.last_name,
              ...user,
            },
          ]);
        });
      }
    }
  };

  const initializedJoinTableData = async () => {
    setLoadingTable(true);
    dispatch(getNetworkingSessionTableDetails(joinTableIDs));
    setJoinTableBtn(false);

    setShowTable(true);
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <>
        <div className="networking_next_arrow" onClick={onClick}>
          <i className="arrow right"></i>
        </div>
      </>
    );
  };

  const PrevArrow = (props) => {
    const { disabled, onClick } = props;

    return disabled ? (
      <div className="networking_prev_arrow">
        <i className="arrowDisabled left"></i>
      </div>
    ) : (
      <div className="networking_prev_arrow" onClick={onClick}>
        <i className="arrow left"></i>
      </div>
    );
  };

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  let chatbotRes = checkIfChatbotTimeSlot(sponsorData);
  const eventName = localStorage?.getItem("event_name");

  const handleOnEventChange = (val) => {
    setEventID(val);
    const eventNameToSet = findValueFromList(
      eventList,
      val,
      "event_id",
      "event_name"
    );

    localStorage?.setItem("event_name", eventNameToSet);
  };

  return (
    <div className={props.isFromVista ? "bg-80vh bg-white" : "bg-80vh"}>
      <div className="d-flex justify-content-between m-0 p-0">
        {generateHelmet(
          eventName?.length > 0 ? eventName : "Gravit8",
          "Networking"
        )}
        {props?.fromEventCalendar === true ? null : (
          <div className="d-flex flex-wrap py-2">
            {props.isFromVista ? null : (
              <h4 className="fs-24 mt-1">Networking</h4>
            )}

            {showTable ? (
              <Carousel
                afterChange={(index) => {
                  setCurrentTableIndex(index);

                  dispatch(getNetworkingSessionTableDetails(joinTableIDs));
                }}
                className="networking_carousel "
                arrows
                // {...settings}
                nextArrow={<NextArrow />}
                prevArrow={
                  <PrevArrow
                    disabled={currentTableIndex === 0 ? true : false}
                  />
                }
              >
                {networkingSessionTableDetails &&
                  networkingSessionTableDetails?.map((data, i) => {
                    return (
                      <div
                        key={data?.table_name + "_" + i.toString()}
                        className="d-flex justify-content-center align-items-center mt-2"
                      >
                        <h6>{data?.table_name}</h6>
                      </div>
                    );
                  })}
              </Carousel>
            ) : eventid &&
              eventNetLoungeData &&
              eventNetLoungeData?.length !== 0 ? (
              <Carousel
                afterChange={(index) => {
                  setCurrentLoungeIndex(index);
                  changeInLounges(index);
                }}
                className={`${
                  props && props?.isFromVista
                    ? "networking_carousel  ml-380px"
                    : "networking_carousel"
                }`}
                arrows
                {...settings}
              >
                {eventNetLoungeData &&
                  eventNetLoungeData?.map((data, i) => {
                    return (
                      <>
                        <div
                          key={data?.lounge_title + "_" + i.toString()}
                          className="d-flex justify-content-center align-items-center mt-2"
                        >
                          <h6
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {data?.lounge_title}
                          </h6>
                        </div>
                      </>
                    );
                  })}
              </Carousel>
            ) : null}

            {joinTableBtn ? (
              <Button
                ref={joinTableRef}
                onClick={() => {
                  initializedJoinTableData();
                }}
                className="join_table mx-4 fs-16 mb-3"
              >
                <BoothRed />
                <span className="ms-2">Join Table</span>
              </Button>
            ) : null}
          </div>
        )}
        {props?.fromEventCalendar === true ? null : userType ===
            "SUPER ADMIN" || userType === "CUSTOMER" ? (
          <div
            className={props && props.isFromVista ? "mr-3 mb-3 my-2" : "my-2"}
          >
            <span className="mx-2">Event Name</span>
            <Select
              showSearch
              className="audi_event_search"
              placeholder="Select Events"
              optionFilterProp="children"
              disabled={disableEvent}
              bordered={false}
              value={eventid}
              listHeight={120}
              listItemHeight={4}
              onChange={(val) => {
                handleOnEventChange(val);
              }}
            >
              {eventList &&
                eventList.map((data, index) => {
                  return (
                    <Option key={"event_" + index} value={data.event_id}>
                      {data.event_name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        ) : null}
      </div>

      {loadNetworking ? (
        <CommonLoader />
      ) : (
        <>
          {showTable && tableData ? (
            <NetworkTableUI
              history={props.history}
              socket={props?.socket}
              sessionTimings={joinTableSessionTimings}
              networkingSessionTableDetails={networkingSessionTableDetails}
              currentLoungeIndex={currentLoungeIndex}
              currentTableIndex={currentTableIndex}
              brandSponsorData={sponsorData}
              joinTableIDs={joinTableIDs}
              currentTableData={tableData}
              loadingTable={loadingTable}
              clearTableInterval={(interval) => {
                setIntervalTable(interval);
              }}
              initialCallOrLoaderDone={() => {
                setLoading(false);
                setShowTable(true);
                setLoadingTable(false);
              }}
              eventid={eventid}
            />
          ) : eventid ? (
            <div className="networking_container w-100 d-flex">
              {isChatBot && chatbot_json ? (
                <CustomChatbot
                  onClose={async (conversation_details) => {
                    await saveChatbotHistory({
                      event_id: eventid,
                      conversation_details,
                    });
                    setIsChatBot(false);
                  }}
                  responseJson={chatbot_json}
                  chatbot_history={chatbot_history}
                  onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
                  extraProps={{ event_id: eventid }}
                />
              ) : null}
              <div className="networking_left_container d-flex flex-column">
                <h5 className="mb-3 mx-auto">Sponsored by</h5>

                <div className="net_hosting_sponsor_img_container">
                  {sponsorData && sponsorData?.[0]?.logo_url ? (
                    <img
                      className="networking_img mb-3 mx-auto"
                      src={sponsorData && sponsorData?.[0]?.logo_url}
                    />
                  ) : (
                    <div className="w-100 d-flex justify-content-center">
                      <h5 className="">No Sponsor Added</h5>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-center  w-100 mb-3 mx-auto">
                  {sponsorData?.[0]?.is_asset_enable &&
                  sponsorData?.[0]?.is_asset_enable !== "NA" &&
                  sponsorData?.[0]?.asset_file_url ? (
                    <Tooltip placement="right" title="Download">
                      <Button
                        onClick={() => {
                          if (sponsorData?.[0]?.can_download_asset) {
                            window.open(
                              sponsorData?.[0]?.asset_file_url,
                              "_blank"
                            );
                            updateAssetDownloadCount(
                              sponsorData?.[0]?.zone_asset_id
                            );
                            dispatch(
                              getEventNetworkingLoungeDetails({
                                event_id: eventid,
                                date: currentDate.format("YYYY-MM-DD"),
                              })
                            );
                          } else {
                            toast.error("Maximum Number of Downloads Reached");
                          }
                        }}
                        className="ms-2 border"
                      >
                        <Download />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {sponsorData?.[0]?.is_booth_link_enable &&
                  sponsorData?.[0]?.is_booth_link_enable !== "NA" ? (
                    <Tooltip placement="right" title="Visit Booth">
                      <Button
                        className="border ms-2"
                        onClick={() => {
                          let tier = sponsorData?.[0]?.brand?.brand_tier;
                          let zones = sponsorData?.[0]?.brand?.zones;
                          let sequence = null;
                          let brand_id = sponsorData?.[0]?.brand_id;
                          let eventID = eventid;
                          let final_booth_url =
                            sponsorData?.[0]?.brand?.final_booth_url;

                          vistaRedirectToBooth(
                            tier,
                            zones,
                            sequence,
                            userType,
                            brand_id,
                            eventID,
                            props.history,
                            dispatch,
                            final_booth_url
                          );
                        }}
                      >
                        <Booth />
                      </Button>
                    </Tooltip>
                  ) : null}

                  {(sponsorData?.[0]?.is_chat_enabled &&
                    sponsorData?.[0]?.is_chat_enabled !== "NA" &&
                    sponsorData?.[0]?.channel_id) ||
                  (chatbotRes && sponsorData?.[0]?.chat_bot_start_date_time) ? (
                    <Tooltip placement="right" title="Chat">
                      <Button
                        className="border ms-2"
                        onClick={async () => {
                          if (
                            chatbotRes &&
                            sponsorData.chat_bot_start_date_time
                          ) {
                            let chat_bot_res = await getChatbotJson({
                              zone_id: sponsorData.zone_id,
                            });
                            if (chat_bot_res && !isEmpty(chat_bot_res)) {
                              set_chatbot_json(chat_bot_res);
                              setIsChatBot(true);
                            } else toast.error("Chatbot details doesn't exist");
                          } else {
                            setMessageModal({
                              isOpen: true,
                              channel_id: sponsorData?.[0]?.channel_id,
                            });
                          }
                        }}
                      >
                        <Chat />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {messageModal.isOpen ? (
                    <Modal
                      title={<b>Send Message</b>}
                      visible={messageModal.isOpen}
                      onOk={() => clearMessageAndModal()}
                      onCancel={() => clearMessageAndModal()}
                      footer={[
                        <Button
                          className="px-3 button-pink rounded"
                          key="cancel"
                          onClick={() => clearMessageAndModal()}
                        >
                          Cancel
                        </Button>,
                        <Button
                          className="px-3 rounded"
                          key="save"
                          type="primary"
                          onClick={() =>
                            callSendMessageAPI(messageModal?.channel_id)
                          }
                          disabled={disableSendBtn}
                        >
                          Send
                        </Button>,
                      ]}
                      className="message_modal"
                    >
                      <Row className="ticker_configuration_row mt-2">
                        <Col span={6} className="ticker_configuration_label">
                          <p>
                            Message
                            <RedAsterick />
                          </p>
                        </Col>
                        <Col span={12}>
                          <TextArea
                            name="message_text"
                            id="message_text"
                            className="ticker_configuration_inputDescription"
                            placeholder="Enter a message"
                            rows={4}
                            value={message_text}
                            onChange={(e) => set_message_text(e.target.value)}
                            maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                            onBlur={() =>
                              messageValidator.current.showMessageFor(
                                "message_text"
                              )
                            }
                          />
                          {messageValidator.current.message(
                            "Message",
                            message_text,
                            "required",
                            { className: "text-danger py-1" }
                          )}
                        </Col>
                      </Row>
                    </Modal>
                  ) : null}
                  {sponsorData?.[0]?.zone_demo_room_link &&
                  sponsorData?.[0]?.is_demo_room_enable !== "NA" &&
                  sponsorData?.[0]?.is_demo_room_enable ? (
                    <Tooltip placement="right" title="Demo Room">
                      <Button
                        onClick={() => {
                          dispatch(
                            setVideoConferenceData({
                              videoConferenceFlag: true,
                              videoConferenceUrl:
                                sponsorData[0]?.zone_demo_room_link,
                              is_demo_room: true,
                            })
                          );
                        }}
                        className="border ms-2"
                        disabled={
                          sponsorData && !sponsorData[0]?.is_demo_room_enable
                        }
                      >
                        <Video />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {sponsorData?.[0]?.is_video_call_enable &&
                  sponsorData?.[0]?.is_video_call_enable !== "NA" ? (
                    <Tooltip placement="right" title="Next Available Agent">
                      <Button
                        onClick={async () => {
                          let channel_id = sponsorData?.[0]?.channel_id;
                          const response = await videoRequestAPI(channel_id);
                          if (response.data.code === 200)
                            toast.success(response.data.message);
                          else toast.error(response.data.message);
                        }}
                        className="border ms-2"
                      >
                        <User />
                      </Button>
                    </Tooltip>
                  ) : null}
                </div>
                <h6 className="mb-3 mx-auto">Agenda</h6>
                <DatePicker
                  className="networking_date mb-2"
                  value={currentDate}
                  format={dateFormat}
                  onChange={(val) => {
                    setCurrentDate(val);
                  }}
                />
                <Input
                  placeholder="Search by Session"
                  className="net_search_input"
                  onChange={(e) => searchSessions(e)}
                  maxLength={INPUT_TEXT_LIMITS.MAX}
                  prefix={<SearchOutlined />}
                />

                <div className=" mt-2 w-100">
                  {filteredSessions?.map((item, i) => {
                    return (
                      <div
                        key={item?.session_title + "_" + i.toString()}
                        className="net_options mt-2 custom_border"
                      >
                        <span>{item?.session_title}</span>
                      </div>
                    );
                  })}
                </div>
                <Divider />
                {sessionData &&
                  sessionData?.map((ele, i) => {
                    const start_date_time = createTZString(ele.start_date_time);
                    const end_date_time = createTZString(ele.end_date_time);
                    const eleStartTime = moment(
                      start_date_time.format("HH:mm"),
                      TIME_FORMAT
                    );
                    const eleEndTime = moment(
                      end_date_time.format("HH:mm"),
                      TIME_FORMAT
                    );
                    let color =
                      ele?.networking_lounge_sponsoring_brands?.[0]
                        ?.brand_color;
                    return (
                      <>
                        <div
                          key={"session_" + i.toString()}
                          className="d-flex position-relative "
                        >
                          <div className="w-25 h-25">
                            <p className="position-absolute top-0 start-0 m-0">
                              {eleStartTime.format("HH:mm")}
                            </p>
                            <p className="position-absolute bottom-0 start-0 m-0">
                              {eleEndTime.format("HH:mm")}
                            </p>
                          </div>
                          <div
                            style={{
                              background: color,
                            }}
                            className="networking_session networking_session_chart d-flex flex-column p-2 ms-2"
                          >
                            <span className="text-white">
                              {ele?.session_title ? ele?.session_title : "NA"}
                            </span>
                            <span className="text-white">
                              {ele?.session_description
                                ? ele?.session_description
                                : "NA"}
                            </span>
                            <span className="text-white">
                              {eleStartTime.format("HH:mm") +
                                "-" +
                                eleEndTime.format("HH:mm")}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="networking_right_container w-100  d-flex ">
                {!loading ? (
                  <>
                    {showEmpty ? (
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <Empty
                          description={
                            <span className="fs-pink">
                              No Upcoming Sessions
                            </span>
                          }
                        />
                      </div>
                    ) : null}

                    {sessionData &&
                      sessionData?.map((ele, index) => {
                        const start_date_time = createTZString(
                          ele.start_date_time
                        );
                        const end_date_time = createTZString(ele.end_date_time);
                        const eleStartTime = moment(
                          start_date_time.format("HH:mm"),
                          TIME_FORMAT
                        );
                        const eleEndTime = moment(
                          end_date_time.format("HH:mm"),
                          TIME_FORMAT
                        );
                        const currentTime = moment(
                          moment().format("HH:mm"),
                          TIME_FORMAT
                        );

                        let data = ele;
                        const start_utc = createISOTimeString(
                          ele.start_date_time
                        ).replace("Z", "");
                        const end_utc = createISOTimeString(
                          ele.end_date_time
                        ).replace("Z", "");

                        data = {
                          ...data,
                          startTime: start_utc,
                          endTime: end_utc,
                          title: data?.session_title,
                          description: data?.session_description,
                        };

                        return (
                          <>
                            <div
                              key={"networking" + index}
                              className="networking_session_columns"
                            >
                              <h5 className={!isMobile && "my-3"}>
                                {currentTime >= eleStartTime &&
                                currentTime <= eleEndTime
                                  ? "Up Now"
                                  : "Up Next"}
                              </h5>
                              {/* Card 1 */}
                              <div className="bg-white p-3">
                                <span>Title :</span>
                                <h5>
                                  {data?.session_title
                                    ? data?.session_title
                                    : "NA"}
                                </h5>
                                <span>Time :</span>
                                <h6>
                                  {eleStartTime.format("HH:mm") +
                                    " - " +
                                    eleEndTime.format("HH:mm")}
                                </h6>
                                <span>Description :</span>
                                <h6>{data?.session_description}</h6>
                                <Row className="mb-3">
                                  <Col span={12}>
                                    <span className="opacity-75">
                                      Seats per Table :
                                    </span>
                                    <br />
                                    <span className="text-dark fs-bold-14">
                                      {data?.seats_per_table}
                                    </span>
                                    <br />
                                    <span className="opacity-75">
                                      Shuffle Time :
                                    </span>
                                    <br />
                                    <span className="text-dark fs-bold-14">
                                      {data?.mins_per_shuffle
                                        ? data?.mins_per_shuffle
                                        : "NA"}
                                      {" minutes"}
                                    </span>
                                    <br />
                                    <span className="opacity-75">
                                      Waterfall (Intro) Time :
                                    </span>
                                    <br />
                                    <span className="text-dark fs-bold-14">
                                      {data?.seconds_per_intro
                                        ? data?.seconds_per_intro + " "
                                        : "NA"}
                                    </span>
                                    <br />
                                    <span className="opacity-75">
                                      Attending Live Currently :
                                    </span>
                                    <br />
                                    <span className="text-dark fs-bold-14">
                                      {data?.attending_live_currently ?? "NA"}
                                    </span>
                                    <br />
                                  </Col>
                                  <Col span={12}>
                                    <span className="opacity-75">
                                      Speed Dating Enabled? :
                                    </span>
                                    <br />
                                    <span className="text-dark fs-bold-14">
                                      {data?.is_speed_dating_enabled
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                    <br />
                                    <span className="opacity-75">
                                      Waterfall Intros Enabled :
                                    </span>
                                    <br />
                                    <span className="text-dark fs-bold-14">
                                      {data?.is_waterfall_intros_enabled
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                    <br />
                                    <span className="opacity-75">
                                      Provisionally Attending :
                                    </span>
                                    <br />
                                    <span className="text-dark fs-bold-14">
                                      NA
                                    </span>
                                    <br />
                                  </Col>
                                </Row>
                                {currentTime >= eleStartTime &&
                                currentTime <= eleEndTime ? (
                                  <Button
                                    className="networking_join_sessionBtn"
                                    onClick={() => joinTableRef.current.click()}
                                  >
                                    <span className="ms-3">
                                      <Booth />
                                      <span className="ms-2">
                                        Join Session Now
                                      </span>
                                    </span>
                                  </Button>
                                ) : (
                                  <>
                                    {data?.is_iCal_download_enabled &&
                                    window.navigator.platform
                                      .toLowerCase()
                                      .includes("mac") ? (
                                      <ICalendarLink
                                        className="networking_downloadBtn"
                                        event={data}
                                      >
                                        <div>
                                          <span>Download iCal</span>
                                        </div>
                                      </ICalendarLink>
                                    ) : null}
                                    {data?.added_to_my_calendar ? (
                                      <Button
                                        onClick={async () => {
                                          await addToMyCalender({
                                            event_id: eventid,
                                            hall_type: "NETWORKING",
                                            hall_id:
                                              data?.networking_configuration_id,
                                            session_id:
                                              data?.networking_session_id,
                                          });
                                        }}
                                        className="networking_calendarBtn ms-2"
                                      >
                                        <Calendar6 />
                                        <span className="ms-2">
                                          Add to My Calendar
                                        </span>
                                      </Button>
                                    ) : null}
                                  </>
                                )}
                              </div>
                              <Carousel
                                className="networking_card_carousel w-100  bg-white mt-3"
                                arrows
                                {...settings}
                              >
                                {cardSponsorData &&
                                  cardSponsorData[index]?.map((data, i) => {
                                    return (
                                      <div
                                        key={"cardsponser_" + i.toString()}
                                        className="w-100 d-flex  align-items-center mt-2"
                                      >
                                        <div className="hosting_sponsor_net_img_container">
                                          <img
                                            className="hosting_sponsor_net_img"
                                            src={data?.logo_url}
                                            // src="https://www.emotivebrand.com/wp-content/uploads/2016/09/tumblr_o05v3eZmyT1ugn1wu_og_1280-1080x675.png"
                                          />
                                        </div>
                                        <div className="d-flex flex-column w-100">
                                          {data?.is_assets_enabled &&
                                          data?.zone_assets?.[0]
                                            ?.asset_file_url ? (
                                            <Tooltip
                                              placement="right"
                                              title="Download"
                                            >
                                              <Button
                                                onClick={() => {
                                                  if (
                                                    data?.zone_assets[0]
                                                      ?.can_download_asset
                                                  ) {
                                                    window.open(
                                                      data?.zone_assets[0]
                                                        ?.asset_file_url,
                                                      "_blank"
                                                    );
                                                    updateAssetDownloadCount(
                                                      data?.zone_assets[0]
                                                        ?.zone_asset_id
                                                    );
                                                    dispatch(
                                                      getEventNetworkingLoungeDetails(
                                                        {
                                                          event_id: eventid,
                                                          date: currentDate.format(
                                                            "YYYY-MM-DD"
                                                          ),
                                                        }
                                                      )
                                                    );
                                                  } else {
                                                    toast.error(
                                                      "Maximum Number of Downloads Reached"
                                                    );
                                                  }
                                                }}
                                                className="networking_pdfBtn ms-1 mb-3"
                                              >
                                                <Download />

                                                <span className="ms-2 fs-16 networking_asset_button_text asset_download_mobile_width">
                                                  {data?.zone_assets?.[
                                                    data?.zone_assets?.length -
                                                      1
                                                  ]?.asset_name +
                                                    data?.zone_assets?.[
                                                      data?.zone_assets
                                                        ?.length - 1
                                                    ]?.original_file_name?.substring(
                                                      data?.zone_assets?.[
                                                        data?.zone_assets
                                                          ?.length - 1
                                                      ]?.original_file_name?.indexOf(
                                                        "."
                                                      )
                                                    )}
                                                </span>
                                              </Button>
                                            </Tooltip>
                                          ) : null}

                                          <div className="w-100 mb-3">
                                            {data?.is_booth_link_enabled ? (
                                              <Tooltip
                                                placement="right"
                                                title="Visit Booth"
                                              >
                                                <Button
                                                  className="border p-1 ms-2"
                                                  onClick={() => {
                                                    let tier =
                                                      data?.brand?.brand_tier;
                                                    let zones =
                                                      data?.brand?.zones;
                                                    let sequence = null;
                                                    let brand_id =
                                                      data?.brand_id;
                                                    let eventID = eventid;
                                                    let final_booth_url =
                                                      data?.brand
                                                        ?.final_booth_url;

                                                    vistaRedirectToBooth(
                                                      tier,
                                                      zones,
                                                      sequence,
                                                      userType,
                                                      brand_id,
                                                      eventID,
                                                      props.history,
                                                      dispatch,
                                                      final_booth_url
                                                    );
                                                  }}
                                                >
                                                  <Booth />
                                                </Button>
                                              </Tooltip>
                                            ) : null}

                                            {data?.is_chat_enabled &&
                                            data?.channel_id ? (
                                              <Tooltip
                                                placement="right"
                                                title="Chat"
                                              >
                                                <Button
                                                  onClick={() => {
                                                    setMessageModal({
                                                      isOpen: true,
                                                      channel_id:
                                                        data?.channel_id,
                                                    });
                                                  }}
                                                  className="border p-1 ms-2"
                                                >
                                                  <Chat />
                                                </Button>
                                              </Tooltip>
                                            ) : null}

                                            {data?.zone_demo_room_link &&
                                            data?.is_demo_room_enabled ? (
                                              <Tooltip
                                                placement="right"
                                                title="Demo room"
                                              >
                                                <Button
                                                  onClick={() => {
                                                    dispatch(
                                                      setVideoConferenceData({
                                                        videoConferenceFlag: true,
                                                        videoConferenceUrl:
                                                          data?.zone_demo_room_link,
                                                        is_demo_room: true,
                                                      })
                                                    );
                                                  }}
                                                  className="border p-1 ms-2"
                                                >
                                                  <Video />
                                                </Button>
                                              </Tooltip>
                                            ) : null}
                                            {data?.is_video_call_enabled ? (
                                              <Tooltip
                                                placement="right"
                                                title="Next Available Agent"
                                              >
                                                <Button
                                                  onClick={async () => {
                                                    let channel_id =
                                                      data?.channel_id;
                                                    const response =
                                                      await videoRequestAPI(
                                                        channel_id
                                                      );
                                                    if (
                                                      response.data.code === 200
                                                    )
                                                      toast.success(
                                                        response.data.message
                                                      );
                                                    else
                                                      toast.error(
                                                        response.data.message
                                                      );
                                                  }}
                                                  className="border p-1 ms-2"
                                                >
                                                  <User />
                                                </Button>
                                              </Tooltip>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </Carousel>
                            </div>
                          </>
                        );
                      })}
                    <div
                      style={{
                        height: "auto",
                        maxHeight: "430px",
                        width: "50px",
                        overflow: "auto",
                        position: "fixed",
                        right: 0,
                      }}
                      className="bg-white   ms-2 mt-5 "
                      id="net_tooltip"
                    >
                      {userNearbyArr &&
                        userNearbyArr.map((user) => {
                          return (
                            <div
                              key={"usernearby_" + user.userId}
                              className="networking_tooltip   p-1"
                              onClick={() => {
                                setUserData(user);
                                setIsProfileModalVisible(true);
                              }}
                            >
                              <Tooltip
                                placement="left"
                                title={() => (
                                  <div className="d-flex p-2 networking_tooltipContent">
                                    <div>
                                      <span className="fs-18 text-nowrap">
                                        {user?.userName}
                                      </span>
                                      <br />
                                      <span>{user?.company}</span>
                                    </div>
                                    <Avatar
                                      shape="square"
                                      size={64}
                                      className="ms-2"
                                      src={user?.image_url ?? placeholder_image}
                                    />
                                  </div>
                                )}
                              >
                                <Avatar
                                  shape="square"
                                  src={user.image_url ?? placeholder_image}
                                />
                              </Tooltip>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <CommonLoader />
                )}
              </div>
            </div>
          ) : userType &&
            (userType === "SUPER ADMIN" || userType === "CUSTOMER") ? (
            <div className="empty_event">
              <Result
                icon={<SmileOutlined />}
                title={
                  <h4 className="fs-pink">
                    Please choose your event in the top right corner
                  </h4>
                }
              />
            </div>
          ) : null}
        </>
      )}

      <ProfileModal
        isProfileModalVisible={isProfileModalVisible}
        userData={userData}
        changeModalFlag={() => {
          setIsProfileModalVisible(false);
        }}
      />
    </div>
  );
};

export default Networking;
