import React, { useState, useEffect } from "react";
import { Button, Switch, Input } from "antd";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../../components/svgJS/CalenderGrey";
import { isImage } from "../../../utils/commonFunctions";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import "../EditEventDetails/index.css";
import CommonLoader from "../../../components/Widgets/CommonLoader";

const EventSettings = ({
  data,
  onNavigateToRegistration,
  onNavigateToReception,
  onNavigateToTier,
  onNavigateToSocialWall,
}) => {
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const [sponsorBanner, setSponsorBanner] = useState(null);
  const [sponsorBoothLeft, setSponsorBoothLeft] = useState(null);
  const [sponsorBoothCenter, setSponsorBoothCenter] = useState(null);
  const [sponsorBoothRight, setSponsorBoothRight] = useState(null);
  const [expochecked] = useState(
    data && data.is_expo_hall_active ? true : false
  );
  const [satAuditoriumChecked] = useState(
    data && data.is_sat_auditorium_active ? true : false
  );
  const [networkingChecked] = useState(
    data && data.is_networking_active ? true : false
  );
  const [expoHallFileState, setExpoHallFileState] = useState(null);
  const [mainAudiFileState, setMainAudiFileState] = useState(null);
  const [satAudiFileState, setSatAudiFileState] = useState(null);
  const [netLoungeFileState, setNetLoungeFileState] = useState(null);
  const [concourseFileState, setConcourseFileState] = useState(null);
  const [exteriorFileState, setExteriorFileState] = useState(null);
  const [sponsorBannerThumbNail] = useState(null);
  const [sponsorBoothLeftThumbNail] = useState(null);
  const [sponsorBoothCenterThumbNail] = useState(null);
  const [sponsorBoothRightThumbNail] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(async () => {
    if (data && data.expo_hall_original_filename) {
      setExpoHallFileState({
        name: data.expo_hall_original_filename,
      });
    }
    if (data && data.main_hall_original_filename) {
      setMainAudiFileState({
        name: data.main_hall_original_filename,
      });
    }
    if (data && data.sat_audi_original_filename) {
      setSatAudiFileState({
        name: data.sat_audi_original_filename,
      });
    }
    if (data && data.networking_original_filename) {
      setNetLoungeFileState({
        name: data.networking_original_filename,
      });
    }
    if (data && data.exterior_original_filename) {
      setExteriorFileState({
        name: data.exterior_original_filename,
      });
    }
    if (data && data.concourse_original_filename) {
      setConcourseFileState({
        name: data.concourse_original_filename,
      });
    }

    if (data && data.sponsor_banner_url) {
      let logoFileName = data.sponsor_banner_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data && data.sponsor_banner_original_filename)
        logoFileName = data.sponsor_banner_original_filename;

      setSponsorBanner({
        name: logoFileName,
        sponsor_banner_url: data.sponsor_banner_url,
      });
    }
    if (data && data.sponsor_booth_left_url) {
      let logoFileName = data.sponsor_booth_left_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data && data.sponsor_booth_left_original_filename)
        logoFileName = data.sponsor_booth_left_original_filename;

      setSponsorBoothLeft({
        name: logoFileName,
        sponsor_booth_left_url: data.sponsor_booth_left_url,
      });
    }

    if (data && data.sponsor_booth_center_url) {
      let logoFileName = data.sponsor_booth_center_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data && data.sponsor_booth_center_original_filename)
        logoFileName = data.sponsor_booth_center_original_filename;

      setSponsorBoothCenter({
        name: logoFileName,
        sponsor_booth_center_url: data.sponsor_booth_center_url,
      });
    }

    if (data && data.sponsor_booth_right_url) {
      let logoFileName = data.sponsor_booth_right_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data && data.sponsor_booth_right_original_filename)
        logoFileName = data.sponsor_booth_right_original_filename;

      setSponsorBoothRight({
        name: logoFileName,
        sponsor_booth_right_url: data.sponsor_booth_right_url,
      });
    }
    setLoader(false);
  }, []);

  if (loader) return <CommonLoader />;
  else if (data) {
    return (
      <div className="container-fluid mt-80">
        <section>
          <div className="row my-4">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Settings</h6>
            </div>
          </div>
          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Registration
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_registration_active
                    ? data.is_registration_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToRegistration();
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>
          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Ticker Tape
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_ticker_tape_active
                    ? data.is_ticker_tape_active
                    : false
                }
                className="invisible"
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}></div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2"> Sponser Calendar</span>
              </Button>
            </div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>
          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary mx-2">
                Brand Tier
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_brand_tier_active
                    ? data.is_brand_tier_active
                    : false
                }
                className="invisible"
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToTier();
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>
          <div className="row">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Expo Halls
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                defaultChecked={expochecked}
                checked={expochecked}
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <div
                className="btn-group mx-auto border-gray event-editor-increment-btn rounded"
                role="group"
                aria-label="Second group"
              >
                <Input
                  disabled
                  className="text-center"
                  style={{ width: "50%" }}
                  value={
                    data && data.no_of_expo_halls ? data.no_of_expo_halls : 0
                  }
                />
              </div>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}>
              <label className="text-secondary">
                Expo Halls 360 Upload
                <RedAsterick />
              </label>
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style"></div>
              {expoHallFileState ? (
                <span> {expoHallFileState.name} </span>
              ) : null}
            </div>
          </div>

          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Navigation Bar
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_navigation_bar_active
                    ? data.is_navigation_bar_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToReception();
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>
          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Main Auditorium
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_main_hall_active
                    ? data.is_main_hall_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}></div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}>
              <label className="text-secondary">
                Main Auditorium 360 Upload
                <RedAsterick />
              </label>
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style"></div>
              {mainAudiFileState ? (
                <span>{mainAudiFileState.name} </span>
              ) : null}
            </div>
          </div>
          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Reception
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_reception_active
                    ? data.is_reception_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToReception();
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>
          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Sat. Auditoriums
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                defaultChecked={satAuditoriumChecked}
                checked={satAuditoriumChecked}
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <div
                className="btn-group mx-auto border-gray event-editor-increment-btn  rounded"
                role="group"
                aria-label="Second group"
              >
                <Input
                  disabled
                  className="text-center"
                  style={{ width: "50%" }}
                  value={
                    data && data.no_of_sat_auditorium
                      ? data.no_of_sat_auditorium
                      : 0
                  }
                />
              </div>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}>
              <label className="text-secondary">
                Sat Auditoriums 360 Upload
                <RedAsterick />
              </label>
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style"></div>
              {satAudiFileState ? <span>{satAudiFileState.name} </span> : null}
            </div>
          </div>

          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Social Wall <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_social_wall_active
                    ? data.is_social_wall_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToSocialWall();
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className="button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Sponser Calendar</span>
              </Button>
            </div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>
          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Networking
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                defaultChecked={networkingChecked}
                checked={networkingChecked}
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <div
                className="btn-group mx-auto border-gray event-editor-increment-btn rounded"
                role="group"
                aria-label="Second group"
              >
                <Input
                  disabled
                  className="text-center"
                  style={{ width: "50%" }}
                  value={
                    data && data.no_of_networking ? data.no_of_networking : 0
                  }
                />
              </div>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}>
              <label className="text-secondary">
                Networking 360 Upload
                <RedAsterick />
              </label>
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style"></div>
              {netLoungeFileState ? (
                <span>{netLoungeFileState.name} </span>
              ) : null}
            </div>
          </div>
          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Concourse
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_concourse_active
                    ? data.is_concourse_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}></div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className="button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Sponser Calendar</span>
              </Button>
            </div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style"></div>
              {concourseFileState ? (
                <span className="mx-3"> {concourseFileState.name} </span>
              ) : null}
            </div>
          </div>
          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Exterior
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_exterior_active
                    ? data.is_exterior_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}></div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            {data.is_exterior_active ? (
              <div
                className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
              ></div>
            ) : null}
            <div
              className={
                Tablet
                  ? "col-4 text-start my-3"
                  : !data.is_exterior_active
                  ? "col-4 text-start"
                  : "col-2 text-start"
              }
            >
              {data.is_exterior_active ? (
                <>
                  {exteriorFileState ? (
                    <span className="mx-3">{exteriorFileState.name}</span>
                  ) : null}
                </>
              ) : (
                <>
                  <label className="text-secondary mx-1 pt-1">
                    Login background theme
                  </label>
                  <Input
                    disabled
                    type="color"
                    id=""
                    value={data.login_background_theme}
                    addonBefore={data.login_background_theme}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </>
              )}
            </div>
          </div>
          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Sponsor Banner <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_sponsor_banner_active
                    ? data.is_sponsor_banner_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              {sponsorBanner ? (
                <>
                  <div className="d-flex flex-row">
                    <span className="mx-3">{sponsorBanner.name}</span>
                  </div>
                  {(sponsorBannerThumbNail ||
                    sponsorBanner.sponsor_banner_url) &&
                  (isImage(sponsorBannerThumbNail) ||
                    isImage(sponsorBanner.sponsor_banner_url)) ? (
                    <img
                      src={
                        sponsorBannerThumbNail
                          ? sponsorBannerThumbNail
                          : sponsorBanner.sponsor_banner_url
                      }
                      alt="Sponsor Banner File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            <div className={Tablet ? "col-2" : "col-1"}>
              <Tooltip placement="right" title={"Aspect ratio should be 16:9"}>
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>
          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">
                Sponsor Booth <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                disabled
                checked={
                  data && data.is_sponsor_booth_active
                    ? data.is_sponsor_booth_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              {sponsorBoothLeft ? (
                <>
                  <div className="d-flex flex-row">
                    <span className="mx-3">{sponsorBoothLeft.name}</span>
                  </div>
                  {(sponsorBoothLeftThumbNail ||
                    sponsorBoothLeft.sponsor_booth_left_url) &&
                  (isImage(sponsorBoothLeftThumbNail) ||
                    isImage(sponsorBoothLeft.sponsor_booth_left_url)) ? (
                    <img
                      src={
                        sponsorBoothLeftThumbNail
                          ? sponsorBoothLeftThumbNail
                          : sponsorBoothLeft.sponsor_booth_left_url
                      }
                      alt="Sponsor Banner File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            <div className={Tablet ? "col-2" : "col-1"}>
              <Tooltip placement="right" title={"Aspect ratio should be 16:9"}>
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              {sponsorBoothCenter ? (
                <>
                  <div className="d-flex flex-row">
                    <span className="mx-3">{sponsorBoothCenter.name}</span>
                  </div>
                  {(sponsorBoothCenterThumbNail ||
                    sponsorBoothCenter.sponsor_booth_center_url) &&
                  (isImage(sponsorBoothCenterThumbNail) ||
                    isImage(sponsorBoothCenter.sponsor_booth_center_url)) ? (
                    <img
                      src={
                        sponsorBoothCenterThumbNail
                          ? sponsorBoothCenterThumbNail
                          : sponsorBoothCenter.sponsor_booth_center_url
                      }
                      alt="Sponsor Booth Center File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            <div className={Tablet ? "col-2" : "col-1"}>
              <Tooltip placement="right" title={"Aspect ratio should be 16:9"}>
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              {sponsorBoothRight ? (
                <>
                  <div className="d-flex flex-row">
                    <span className="mx-3">{sponsorBoothRight.name}</span>
                  </div>
                  {(sponsorBoothRightThumbNail ||
                    sponsorBoothRight.sponsor_booth_right_url) &&
                  (isImage(sponsorBoothRightThumbNail) ||
                    isImage(sponsorBoothRight.sponsor_booth_right_url)) ? (
                    <img
                      src={
                        sponsorBoothRightThumbNail
                          ? sponsorBoothRightThumbNail
                          : sponsorBoothRight.sponsor_booth_right_url
                      }
                      alt="Sponsor Right Booth File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-1 text-start"}
            >
              <Tooltip placement="right" title={"Aspect ratio should be 16:9"}>
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
          </div>
        </section>
      </div>
    );
  } else return null;
};

export default EventSettings;
