import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  TimePicker,
  Button,
  Select,
  Switch,
  Tooltip,
} from "antd";
import moment from "moment";
import "../eventCalender.css";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import {
  createConcourseSponser,
  createSponser,
  updateConcourseSponser,
  updateSponser,
} from "../../../redux/slice/eventCalender";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import { getMinutesDiff, ifTimeSlotAvailable } from "../eventCalender.utils";
import {
  getBrandDropdowns,
  getZoneDetails,
  getZoneDropdowns,
} from "../../../utils/commonApis";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import FileUpload from "../../common/FileUpload";
import { DeleteOutlined } from "@ant-design/icons";
import {
  createISOTimeString,
  createTZString,
  getDisabledTime,
} from "../../../utils/commonFunctions";
import { dialogs } from "../../../components/constsnts/string.constants";

const Sponser = (props) => {
  const dispatch = useDispatch();
  const { sponserData } = props;
  const { Option } = Select;
  const format = "HH:mm";
  const validator = useRef(new SimpleReactValidator());
  const [zones, setZones] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);
  const [brandsCollection, setBrandsCollection] = useState([]);
  const [sponserForceUpdate, setSponserForceUpdate] = useState(false);
  const [defaultFlags] = useState({
    is_chat_enabled: sponserData.is_chat_enabled ?? false,
    is_demo_room_enable: sponserData.is_demo_room_enable ?? false,
    is_asset_enable: sponserData.is_asset_enable ?? false,
    is_booth_link_enable: sponserData.is_booth_link_enable ?? true,
    is_video_call_enable: sponserData.is_video_call_enable ?? false,
  });
  const [sponserLocalData, setSponserLocalData] = useState(null);
  const [selectedZoneData, setSelectedZoneData] = useState(defaultFlags);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (sponserData.zone_id) {
      const res = await getZoneDetails({ zone_id: sponserData.zone_id });
      setSelectedZoneData({
        is_chat_enabled: res.is_text_chat_enable,
        is_demo_room_enable: res.is_video_demo_room_enable,
        is_asset_enable: res.zones_assets_id?.length > 0 ? true : false,
        is_booth_link_enable: res.is_on_booth_leads_enable,
        is_video_call_enable: res.is_one_to_one_video_calls_enable,
      });
    }

    const brandListAPI = await getBrandDropdowns(props.sponserData.event_id);
    setBrandsCollection(brandListAPI);
    const zonesHere = await getZoneDropdowns(props.sponserData.event_id);
    if (sponserData && sponserData.brand_id) {
      const filteredData =
        zonesHere &&
        zonesHere.filter((item) => item.brand_id === sponserData.brand_id);
      setZones(filteredData);
    } else setZones(zonesHere);
    setZoneCollection(zonesHere);
    const start_date_time_new = createTZString(sponserData?.start_date_time);
    const end_date_time_new = createTZString(sponserData?.end_date_time);
    setSponserLocalData({
      ...sponserData,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      current_timestamp: moment().format("YYYY-MM-DD"),
      brand_configuration_id: sponserData.brand_configuration_id ?? "",
      brand_id: sponserData?.brand_id ?? null,
      zone_id: sponserData.zone_id ?? null,
      start_date_time: sponserData?.start_date_time
        ? moment(start_date_time_new.format("HH:mm"), TIME_FORMAT)
        : moment(props.sponserData.hourItem.start_time, TIME_FORMAT),
      end_date_time: sponserData?.end_date_time
        ? moment(end_date_time_new.format("HH:mm"), TIME_FORMAT)
        : moment(props.sponserData.hourItem.end_time, TIME_FORMAT),
      ...defaultFlags,
    });
    setLoading(false);
  }, []);

  const createUpdateAPI = async (dataToUpdate) => {
    setLoading(true);

    if (dataToUpdate.brand_configuration_id) {
      let updateRes = null;
      if (sponserData.title === "Concourse Sponsors") {
        let formData = new FormData();
        formData.append("data", JSON.stringify(dataToUpdate));
        formData.append("sponsor_banner_file", dataToUpdate.sponsor_banner_url);
        formData.append(
          "sponsor_booth_right_file",
          dataToUpdate.sponsor_booth_right_url
        );
        formData.append(
          "sponsor_booth_center_file",
          dataToUpdate.sponsor_booth_center_url
        );
        formData.append(
          "sponsor_booth_left_file",
          dataToUpdate.sponsor_booth_left_url
        );

        updateRes = await dispatch(updateConcourseSponser(formData));
      } else updateRes = await dispatch(updateSponser(dataToUpdate));

      if (updateRes?.payload?.data?.code === 200) {
        toast.success(updateRes.payload.data.message);
        setLoading(false);
        props.onSuccess(true);
      } else {
        setLoading(false);
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      let createRes = null;
      if (sponserData.title === "Concourse Sponsors") {
        let formData = new FormData();
        formData.append("data", JSON.stringify(dataToUpdate));
        formData.append("sponsor_banner_file", dataToUpdate.sponsor_banner_url);
        formData.append(
          "sponsor_booth_right_file",
          dataToUpdate.sponsor_booth_right_url
        );
        formData.append(
          "sponsor_booth_center_file",
          dataToUpdate.sponsor_booth_center_url
        );
        formData.append(
          "sponsor_booth_left_file",
          dataToUpdate.sponsor_booth_left_url
        );

        createRes = await dispatch(createConcourseSponser(formData));
      } else {
        createRes = await dispatch(createSponser(dataToUpdate));
      }

      if (createRes?.payload?.data?.code === 200) {
        setLoading(false);

        toast.success(createRes.payload.data.message);
        props.onSuccess(true);
      } else {
        setLoading(false);

        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleSubmit = () => {
    if (validator.current.allValid()) {
      const { sponserData } = props;
      let clonedData = cloneDeep(sponserLocalData);
      const dateHere = sponserData.currentDateForCalender;
      const start_date_str =
        dateHere + " " + moment(clonedData.start_date_time).format("HH:mm:ss");
      const start_utc = createISOTimeString(start_date_str);
      const end_date_str =
        dateHere + " " + moment(clonedData.end_date_time).format("HH:mm:ss");
      const end_utc = createISOTimeString(end_date_str);
      clonedData.start_date_time = start_utc;
      clonedData.end_date_time = end_utc;

      if (
        !ifTimeSlotAvailable(
          moment(clonedData.start_date_time),
          moment(clonedData.end_date_time),
          props.sponserData.existingTimings
        )
      )
        toast.error("Timeslot is not available");
      else if (
        moment(clonedData.start_date_time) > moment(clonedData.end_date_time)
      )
        toast.error(dialogs?.inCorrectTimeError);
      else if (
        getMinutesDiff(
          moment(clonedData.start_date_time),
          moment(clonedData.end_date_time)
        ) < 10
      )
        toast.error("Please select atleast 10 mins duration");
      else {
        if (sponserData.title === "Expo Hall Sponsorship") {
          clonedData.hall_type = "EXPO";
          clonedData.hall_id = clonedData.expo_hall_configuration_id;
        }

        if (sponserData.title === "Main Auditorium Sponsorship") {
          clonedData.hall_type = "MAIN";
          clonedData.hall_id = clonedData.auditorium_configuration_id;
        }

        if (sponserData.title === "Satellite Auditorium Sponsorship") {
          clonedData.hall_type = "SATELLITE";
          clonedData.hall_id = clonedData.auditorium_configuration_id;
        }

        if (sponserData.title === "Network Lounge Sponsorship") {
          clonedData.hall_type = "NETWORKING";
          clonedData.hall_id = clonedData.networking_configuration_id;
        }

        if (sponserData.title === "Social Wall Sponsorship") {
          clonedData.hall_type = "SOCIAL";
          clonedData.hall_id = null;
        }

        if (sponserData.title === "Concourse Sponsors") {
          clonedData.hall_type = "CONCOURSE";
          clonedData.hall_id = null;
        }

        createUpdateAPI(clonedData);
      }
    } else {
      validator.current.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setSponserForceUpdate(!sponserForceUpdate);
    }
  };

  const onChangeFields = (val, name) => {
    if (name === "sponsor_banner_url") {
      setSponserLocalData({
        ...sponserLocalData,
        [name]: val,
        sponsor_banner_original_filename: val.name,
      });
    } else if (name === "sponsor_booth_left_url") {
      setSponserLocalData({
        ...sponserLocalData,
        [name]: val,
        sponsor_booth_left_original_filename: val.name,
      });
    } else if (name === "sponsor_booth_right_url") {
      setSponserLocalData({
        ...sponserLocalData,
        [name]: val,
        sponsor_booth_right_original_filename: val.name,
      });
    } else if (name === "sponsor_booth_center_url") {
      setSponserLocalData({
        ...sponserLocalData,
        [name]: val,
        sponsor_booth_center_original_filename: val.name,
      });
    } else {
      setSponserLocalData({ ...sponserLocalData, [name]: val });
    }
  };

  async function setAllTheFlags(zone_id) {
    const res = await getZoneDetails({ zone_id });

    if (res?.zone_id) {
      const fiveFlags = {
        is_chat_enabled: res.is_text_chat_enable,
        is_demo_room_enable: res.is_video_demo_room_enable,
        is_asset_enable: res.zones_assets_id?.length > 0 ? true : false,
        is_booth_link_enable: res.is_on_booth_leads_enable,
        is_video_call_enable: res.is_one_to_one_video_calls_enable,
      };
      setSelectedZoneData(fiveFlags);
      setSponserLocalData({
        ...sponserLocalData,
        ...fiveFlags,
        zone_id,
      });
    } else onChangeFields(zone_id, "zone_id");
  }

  const deleteConcourseFileUpload = (name) => {
    if (name === "sponsor_banner_url") {
      setSponserLocalData({
        ...sponserLocalData,
        [name]: null,
        sponsor_banner_original_filename: null,
      });
    } else if (name === "sponsor_booth_left_url") {
      setSponserLocalData({
        ...sponserLocalData,
        [name]: null,
        sponsor_booth_left_original_filename: null,
      });
    } else if (name === "sponsor_booth_right_url") {
      setSponserLocalData({
        ...sponserLocalData,
        [name]: null,
        sponsor_booth_right_original_filename: null,
      });
    } else if (name === "sponsor_booth_center_url") {
      setSponserLocalData({
        ...sponserLocalData,
        [name]: null,
        sponsor_booth_center_original_filename: null,
      });
    }
  };

  return (
    <Modal
      title={<b>{sponserData.title}</b>}
      visible={true}
      onOk={() => {
        if (!loading) props.onCloseModal();
      }}
      onCancel={() => {
        if (!loading) props.onCloseModal();
      }}
      footer={
        loading
          ? null
          : [
              <Button
                className="px-3 button-pink rounded"
                key="cancel"
                style={{ width: "11%" }}
                onClick={() => props.onCloseModal()}
              >
                Cancel
              </Button>,
              <Button
                className="px-3 rounded"
                key="save"
                type="primary"
                style={{ width: "11%" }}
                onClick={handleSubmit}
              >
                Save
              </Button>,
            ]
      }
      width={750}
    >
      {loading ? (
        <CommonLoader />
      ) : (
        <div style={{ padding: "30px" }}>
          <Row className="mt-2" gutter={[16, 16]}>
            <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
              <span> Start & End Time</span>
            </Col>
            <Col xs={24} sm={16} md={12} lg={8} xl={8}>
              <TimePicker
                allowClear={false}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                disabled={
                  sponserData &&
                  sponserData.brand_configuration_id &&
                  sponserData.userType === "BRAND ADMIN"
                    ? true
                    : false
                }
                name="start_date_time"
                id="start_date_time"
                format={format}
                value={
                  sponserLocalData ? sponserLocalData?.start_date_time : null
                }
                style={{ width: "100%" }}
                onSelect={(val) => {
                  if (
                    moment(sponserLocalData?.end_date_time, "HH:mm") >
                    moment(val)
                  ) {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "start_date_time"
                    );
                  } else {
                    toast.error(dialogs?.inCorrectTimeError);
                  }
                }}
                onChange={(val) => {
                  if (
                    moment(sponserLocalData?.end_date_time, "HH:mm") >
                    moment(val)
                  ) {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "start_date_time"
                    );
                  } else {
                    toast.error(dialogs?.inCorrectTimeError);
                  }
                }}
                onBlur={() =>
                  validator.current.showMessageFor("start_date_time")
                }
              />

              {sponserLocalData &&
                validator.current.message(
                  "Start Time",
                  sponserLocalData.start_date_time,
                  "required",
                  { className: "text-danger py-1" }
                )}
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={8}>
              <TimePicker
                allowClear={false}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                disabled={
                  sponserData &&
                  sponserData.brand_configuration_id &&
                  sponserData.userType === "BRAND ADMIN"
                    ? true
                    : false
                }
                disabledTime={() =>
                  getDisabledTime(sponserLocalData?.start_date_time)
                }
                name="end_date_time"
                id="end_date_time"
                value={sponserLocalData ? sponserLocalData.end_date_time : null}
                format={format}
                style={{ width: "100%" }}
                onSelect={(val) => {
                  if (
                    moment(val) >
                    moment(sponserLocalData?.start_date_time, "HH:mm")
                  ) {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "end_date_time"
                    );
                  } else {
                    toast.error(dialogs?.inCorrectTimeError);
                  }
                }}
                onChange={(val) => {
                  if (
                    moment(val) >
                    moment(sponserLocalData?.start_date_time, "HH:mm")
                  ) {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "end_date_time"
                    );
                  } else {
                    toast.error(dialogs?.inCorrectTimeError);
                  }
                }}
                onBlur={() => validator.current.showMessageFor("end_date_time")}
              />
              {sponserLocalData &&
                validator.current.message(
                  "End Time",
                  sponserLocalData.end_date_time,
                  "required",
                  { className: "text-danger py-1" }
                )}
            </Col>
          </Row>

          <Row className="mt-3" gutter={[16, 16]}>
            <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
              <span>Brand Name</span>
            </Col>
            <Col xs={24} sm={16} md={12} lg={7} xl={16}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                showSearch
                optionFilterProp="children"
                placeholder="Select Brand"
                className="w-100 fs-bold-14"
                value={sponserLocalData ? sponserLocalData.brand_id : null}
                onChange={(e) => {
                  if (zoneCollection) {
                    const filteredData =
                      zoneCollection &&
                      zoneCollection.filter((item) => item.brand_id === e);

                    setSponserLocalData({
                      ...sponserLocalData,
                      brand_id: e,
                      zone_id: null,
                    });
                    setZones(filteredData);
                  } else
                    setSponserLocalData({ ...sponserLocalData, brand_id: e });
                }}
                listHeight={120}
                listItemHeight={4}
              >
                {brandsCollection &&
                  brandsCollection.map((item, i) => {
                    return (
                      <Option
                        key={item.brand_id + "_brand"}
                        value={item.brand_id}
                      >
                        {item.brand_title}
                      </Option>
                    );
                  })}
              </Select>
              {sponserLocalData &&
                validator.current.message(
                  "Brand Name",
                  sponserLocalData.brand_id,
                  "required",
                  { className: "text-danger py-1" }
                )}
            </Col>
          </Row>
          <Row className="mt-3" gutter={[16, 16]}>
            <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
              <span>Zone</span>
            </Col>
            <Col xs={24} sm={16} md={12} lg={8} xl={16}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                disabled={sponserLocalData.brand_id ? false : true}
                showSearch
                optionFilterProp="children"
                placeholder="Select Zone"
                className="w-100 fs-bold-14"
                value={
                  sponserLocalData?.zone_id ? sponserLocalData.zone_id : null
                }
                onChange={(e) => {
                  setAllTheFlags(e);
                }}
                listHeight={120}
                listItemHeight={4}
              >
                {zones.map((item, i) => {
                  return (
                    <Option key={item.zone_id + "_zone"} value={item.zone_id}>
                      {item.zone_title}
                    </Option>
                  );
                })}
              </Select>
              {validator.current.message(
                "Zone",
                sponserLocalData.zone_id,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="mt-4" gutter={[16, 16]}>
            <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
              <span>Chat</span>
            </Col>
            <Col xs={24} sm={16} md={12} lg={8} xl={16}>
              <Switch
                disabled={!selectedZoneData.is_chat_enabled ? true : false}
                checked={sponserLocalData.is_chat_enabled}
                onChange={() =>
                  onChangeFields(
                    !sponserLocalData.is_chat_enabled,
                    "is_chat_enabled"
                  )
                }
              />
            </Col>
          </Row>

          <Row className="mt-4" gutter={[16, 16]}>
            <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
              <span>Demo Room</span>
            </Col>
            <Col xs={24} sm={16} md={12} lg={8} xl={16}>
              <Switch
                disabled={!selectedZoneData.is_demo_room_enable ? true : false}
                checked={sponserLocalData.is_demo_room_enable}
                onChange={() =>
                  onChangeFields(
                    !sponserLocalData.is_demo_room_enable,
                    "is_demo_room_enable"
                  )
                }
              />
            </Col>
          </Row>

          <Row className="mt-4" gutter={[16, 16]}>
            <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
              <span>Asset/s</span>
            </Col>
            <Col xs={24} sm={16} md={12} lg={8} xl={16}>
              <Switch
                disabled={!selectedZoneData.is_asset_enable ? true : false}
                checked={sponserLocalData.is_asset_enable}
                onChange={() =>
                  onChangeFields(
                    !sponserLocalData.is_asset_enable,
                    "is_asset_enable"
                  )
                }
              />
            </Col>
          </Row>

          <Row className="mt-4" gutter={[16, 16]}>
            <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
              <span>Booth Link</span>
            </Col>
            <Col xs={24} sm={16} md={12} lg={8} xl={16}>
              <Switch
                disabled={!selectedZoneData.is_booth_link_enable ? true : false}
                checked={sponserLocalData.is_booth_link_enable}
                onChange={() =>
                  onChangeFields(
                    !sponserLocalData.is_booth_link_enable,
                    "is_booth_link_enable"
                  )
                }
              />
            </Col>
          </Row>

          <Row className="mt-4" gutter={[16, 16]}>
            <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
              <span>Video Call</span>
            </Col>
            <Col xs={24} sm={16} md={12} lg={8} xl={16}>
              <Switch
                disabled={!selectedZoneData.is_video_call_enable ? true : false}
                checked={sponserLocalData.is_video_call_enable}
                onChange={() =>
                  onChangeFields(
                    !sponserLocalData.is_video_call_enable,
                    "is_video_call_enable"
                  )
                }
              />
            </Col>
          </Row>
          {sponserData.title === "Concourse Sponsors" ? (
            <>
              <Row className="mt-4" gutter={[16, 16]}>
                <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
                  <span>Sponsor Banner</span>
                </Col>
                <Col xs={24} sm={16} md={12} lg={8} xl={16}>
                  <div className="d-flex">
                    <div className="concourse_vista_video_upload">
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg  px-4 py-2"
                        labelTitle="Video Upload"
                        name="sponsor-banner"
                        inputType="file"
                        inputOnChange={(e) => {
                          onChangeFields(
                            e.target.files[0],
                            "sponsor_banner_url"
                          );
                        }}
                        inputId="sponsor-banner"
                        inputName="sponsor-banner"
                        onBlur={() =>
                          validator.current.showMessageFor("sponsor-banner")
                        }
                        value=""
                      />
                    </div>
                    {sponserLocalData &&
                    sponserLocalData?.sponsor_banner_original_filename ? (
                      <>
                        <Tooltip
                          placement="topLeft"
                          title={
                            sponserLocalData?.sponsor_banner_original_filename
                          }
                        >
                          <span className="mx-3 concourse_uploaded_file_name">
                            {sponserLocalData?.sponsor_banner_original_filename}
                          </span>
                        </Tooltip>
                        <span
                          onClick={() => {
                            deleteConcourseFileUpload("sponsor_banner_url");
                          }}
                        >
                          <DeleteOutlined />
                        </span>
                      </>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4" gutter={[16, 16]}>
                <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
                  <span>Sponsor Booth</span>
                </Col>
                <Col xs={24} sm={16} md={12} lg={8} xl={16}>
                  <div className="d-flex">
                    <div className="concourse_vista_video_upload">
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg  px-4 py-2"
                        labelTitle="Left Upload"
                        name="booth-left"
                        inputType="file"
                        inputOnChange={(e) => {
                          onChangeFields(
                            e.target.files[0],
                            "sponsor_booth_left_url"
                          );
                        }}
                        inputId="booth-left"
                        inputName="booth-left"
                        onBlur={() =>
                          validator.current.showMessageFor("booth-left")
                        }
                        value=""
                      />
                    </div>
                    {sponserLocalData &&
                    sponserLocalData?.sponsor_booth_left_original_filename ? (
                      <>
                        <Tooltip
                          placement="topLeft"
                          title={
                            sponserLocalData?.sponsor_booth_left_original_filename
                          }
                        >
                          <span className="mx-3 concourse_uploaded_file_name">
                            {
                              sponserLocalData?.sponsor_booth_left_original_filename
                            }
                          </span>
                        </Tooltip>
                        <span
                          onClick={() => {
                            deleteConcourseFileUpload("sponsor_booth_left_url");
                          }}
                        >
                          <DeleteOutlined />
                        </span>
                      </>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4" gutter={[16, 16]}>
                <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
                  <span>Sponsor Booth</span>
                </Col>
                <Col xs={24} sm={16} md={12} lg={8} xl={16}>
                  <div className="d-flex">
                    <div className="concourse_vista_video_upload">
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg  px-4 py-2"
                        labelTitle="Center Upload"
                        name="booth-center"
                        inputType="file"
                        inputOnChange={(e) => {
                          onChangeFields(
                            e.target.files[0],
                            "sponsor_booth_center_url"
                          );
                        }}
                        inputId="booth-center"
                        inputName="booth-center"
                        onBlur={() =>
                          validator.current.showMessageFor("booth-center")
                        }
                        value=""
                      />
                    </div>
                    {sponserLocalData &&
                    sponserLocalData?.sponsor_booth_center_original_filename ? (
                      <>
                        <Tooltip
                          placement="topLeft"
                          title={
                            sponserLocalData?.sponsor_booth_center_original_filename
                          }
                        >
                          <span className="mx-3 concourse_uploaded_file_name">
                            {
                              sponserLocalData?.sponsor_booth_center_original_filename
                            }
                          </span>
                        </Tooltip>
                        <span
                          onClick={() => {
                            deleteConcourseFileUpload(
                              "sponsor_booth_center_url"
                            );
                          }}
                        >
                          <DeleteOutlined />
                        </span>
                      </>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4" gutter={[16, 16]}>
                <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
                  <span>Sponsor Booth</span>
                </Col>
                <Col xs={24} sm={16} md={12} lg={8} xl={16}>
                  <div className="d-flex">
                    <div className="concourse_vista_video_upload">
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg px-4 py-2"
                        labelTitle="Right Upload"
                        name="booth-right"
                        inputType="file"
                        inputOnChange={(e) => {
                          onChangeFields(
                            e.target.files[0],
                            "sponsor_booth_right_url"
                          );
                        }}
                        inputId="booth-right"
                        inputName="booth-right"
                        onBlur={() =>
                          validator.current.showMessageFor("booth-right")
                        }
                        value=""
                      />
                    </div>
                    {sponserLocalData &&
                    sponserLocalData?.sponsor_booth_right_original_filename ? (
                      <>
                        <Tooltip
                          placement="topLeft"
                          title={
                            sponserLocalData?.sponsor_booth_right_original_filename
                          }
                        >
                          <span className="mx-3 concourse_uploaded_file_name">
                            {
                              sponserLocalData?.sponsor_booth_right_original_filename
                            }
                          </span>
                        </Tooltip>
                        <span
                          onClick={() => {
                            deleteConcourseFileUpload(
                              "sponsor_booth_right_url"
                            );
                          }}
                        >
                          <DeleteOutlined />
                        </span>
                      </>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </Modal>
  );
};

export default Sponser;
