import React from "react";

export const Meeting = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          id="_180-meeting"
          data-name="180-meeting"
          d="M20,2.366v4.49a2.368,2.368,0,0,1-2.366,2.366.328.328,0,0,0-.328.328V10.5a1.1,1.1,0,0,1-1.875.777l-.01-.01-1.505-1.56a1.332,1.332,0,0,0-.491-.349A.781.781,0,0,1,14,7.906a2.891,2.891,0,0,1,1.03.7l.01.01.717.744a1.893,1.893,0,0,1,1.881-1.7.8.8,0,0,0,.8-.8V2.366a.8.8,0,0,0-.8-.8H14.141a.781.781,0,1,1,0-1.563h3.494A2.368,2.368,0,0,1,20,2.366ZM5.937,5.508a.781.781,0,1,0-.781-.781A.781.781,0,0,0,5.937,5.508Zm2.734,0a.781.781,0,1,0-.781-.781A.781.781,0,0,0,8.672,5.508Zm-5.43,0a.781.781,0,1,0-.781-.781A.781.781,0,0,0,3.242,5.508ZM20,18.876v.343a.781.781,0,0,1-1.562,0v-.343a.542.542,0,0,0-.178-.406,3.252,3.252,0,0,0-4.214,0,.542.542,0,0,0-.178.406v.343a.781.781,0,0,1-1.563,0v-.343a.539.539,0,0,0-.179-.4A3.136,3.136,0,0,0,10,17.7a3.136,3.136,0,0,0-2.126.777.539.539,0,0,0-.179.4v.343a.781.781,0,0,1-1.563,0v-.343a.542.542,0,0,0-.178-.406A3.1,3.1,0,0,0,3.848,17.7a3.1,3.1,0,0,0-2.107.775.542.542,0,0,0-.178.406v.343a.781.781,0,0,1-1.563,0v-.343A2.106,2.106,0,0,1,.7,17.3a4.59,4.59,0,0,1,1.185-.766A2.344,2.344,0,1,1,5.9,16.58a4.589,4.589,0,0,1,1.014.655,4.623,4.623,0,0,1,1.074-.681,2.344,2.344,0,1,1,4.021,0,4.623,4.623,0,0,1,1.074.681,4.589,4.589,0,0,1,1.075-.684,2.344,2.344,0,1,1,4.016.014A4.591,4.591,0,0,1,19.3,17.3a2.106,2.106,0,0,1,.7,1.572ZM3.125,15.352a.781.781,0,1,0,.781-.781A.782.782,0,0,0,3.125,15.352ZM10,16.133a.781.781,0,1,0-.781-.781A.782.782,0,0,0,10,16.133Zm5.391-.781a.781.781,0,1,0,.781-.781A.782.782,0,0,0,15.391,15.352ZM0,6.856V2.366A2.368,2.368,0,0,1,2.366,0H9.548a2.368,2.368,0,0,1,2.366,2.366v4.49A2.368,2.368,0,0,1,9.548,9.222H7.267a1.67,1.67,0,0,0-1.184.488l-1.5,1.56-.01.01A1.1,1.1,0,0,1,2.693,10.5V9.55a.328.328,0,0,0-.328-.328A2.369,2.369,0,0,1,0,6.856Zm1.563,0a.8.8,0,0,0,.8.8,1.893,1.893,0,0,1,1.881,1.7l.717-.744.01-.01a3.222,3.222,0,0,1,2.293-.95H9.548a.8.8,0,0,0,.8-.8V2.366a.8.8,0,0,0-.8-.8H2.366a.8.8,0,0,0-.8.8Zm0,0"
          transform="translate(0 0)"
          opacity="0.7"
        />
      </svg>
    </>
  );
};
