import React, { useState, useEffect, useRef } from "react";
import { Button, Select, Switch, Modal, Checkbox, Input } from "antd";
import { CKEditor } from "ckeditor4-react";
import { useMediaQuery } from "react-responsive";
import "../index.css";
import { cloneDeep, isEmpty } from "lodash";
import SimpleReactValidator from "simple-react-validator";
import {
  getSurveys,
  getTiers,
  getRelations,
  getCountryList,
  getJobTitles,
  addNewJobTitle,
} from "../../../utils/commonApis";
import { dialogs } from "../../../components/constsnts/string.constants";
import { toast } from "react-toastify";
import AddNewSurvey from "../../surveyManagement/AddNewSurvey";

function RegistrationAndCommunication(props) {
  const { onChangeData, initiateValidations, onValid, onChangeCKEditorData } =
    props;

  const { Option } = Select;
  const validator = useRef(new SimpleReactValidator());
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const [data, setData] = useState(props.registerData);
  const [relationData, setRelationData] = useState(data.relationData);
  const [surveyList, setSurveyList] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [jobTitleList, setJobTitleList] = useState([]);
  const [countryDataCollection, setCountryDataCollection] = useState([]);
  const [jobTitlesCollection, setJobTitlesCollection] = useState([]);
  const [countryModalVisible, setCountryModalVisible] = useState(false);
  const [jobModalVisible, setJobModalVisible] = useState(false);
  const [countryIdsLocal, setCountryIdsLocal] = useState(
    props.registerData.country_collection_ids
  );
  const [jobTitleIdsLocal, setJobTitleIdsLocal] = useState(
    props.registerData.job_title_collection_ids
  );
  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState(false);
  const [isSelectedAllCountries, setIsSelectedAllCountries] = useState(false);
  const [isSelectedAllTitles, setIsSelectedAllTitles] = useState(false);
  const [showAddJobField, setShowAddJobField] = useState(false);
  const [newJobTitle, setNewJobTitle] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0);
    const tiersRes = await getTiers();
    setTiers(tiersRes);
    const registerSurveys = await getSurveys({
      event_id: data.event_id ?? null,
      for_event: true,
    });
    setSurveyList(registerSurveys);
    const countryData = await getCountryList();
    setCountryDataCollection(countryData);
    const jobTitlesData = await getJobTitles();
    setJobTitlesCollection(jobTitlesData);

    const countryIds = countryData.map((a) => a.country_id);
    const titleIds = jobTitlesData.map((a) => a.job_title_id);

    if (
      !isEmpty(props.registerData.country_collection_ids) &&
      props.registerData.country_collection_ids
    ) {
      if (props.registerData.country_collection_ids === countryIds)
        setIsSelectedAllCountries(true);
      setCountryIdsLocal(props.registerData.country_collection_ids);
    } else if (countryData) {
      setCountryIdsLocal(countryIds);
      setIsSelectedAllCountries(true);
    }

    if (!isEmpty(props.registerData.job_title_collection_ids)) {
      if (props.registerData.job_title_collection_ids === titleIds)
        setIsSelectedAllTitles(true);
      setJobTitleIdsLocal(props.registerData.job_title_collection_ids);
    } else if (jobTitlesData) {
      setJobTitleIdsLocal(titleIds);
      setIsSelectedAllTitles(true);
    }

    setData(props.registerData);
    setAllRelationsFromData(props.registerData);
  }, []);

  useEffect(() => {
    setCountryList(countryDataCollection);
  }, [countryDataCollection]);

  useEffect(() => {
    setJobTitleList(jobTitlesCollection);
  }, [jobTitlesCollection]);

  const onChangeCountryTitle = async (e, ele, countryOrTitle) => {
    if (countryOrTitle === "country") {
      let prevIds = cloneDeep(countryIdsLocal);
      if (prevIds.includes(ele.country_id)) {
        const indexOfEle = prevIds.indexOf(ele.country_id);
        if (indexOfEle > -1) {
          prevIds.splice(indexOfEle, 1);
        }

        setIsSelectedAllCountries(false);

        setCountryIdsLocal(prevIds);
        await onChangeData({ country_collection_ids: prevIds });
      } else {
        prevIds.push(ele.country_id);
        setCountryIdsLocal(prevIds);
        await onChangeData({ country_collection_ids: prevIds });
      }
    } else {
      let prevIds = cloneDeep(jobTitleIdsLocal);
      if (prevIds.includes(ele.job_title_id)) {
        const indexOfEle = prevIds.indexOf(ele.job_title_id);
        if (indexOfEle > -1) {
          prevIds.splice(indexOfEle, 1);
        }
        setIsSelectedAllTitles(false);
        setJobTitleIdsLocal(prevIds);
        await onChangeData({ job_title_collection_ids: prevIds });
      } else {
        prevIds.push(ele.job_title_id);
        setJobTitleIdsLocal(prevIds);
        await onChangeData({ job_title_collection_ids: prevIds });
      }
    }
  };

  // useEffect(() => {
  //   if (!data.is_registration_survey_active) {
  //     // validator.current.purgeFields();
  //     validator.current.hideMessageFor("Survey");
  //   }
  // }, [data.is_registration_survey_active]);

  useEffect(async () => {
    setData(props.registerData);
    if (isEmpty(relationData)) {
      const relationRes = await getRelations();
      setRelationData(relationRes);
    }
  }, [props.registerData]);

  useEffect(() => {
    if (initiateValidations) {
      validator.current.purgeFields();
      setTimeout(() => {
        handleFormSubmit();
      }, 500);
    }
  }, [initiateValidations]);

  const handleFormSubmit = () => {
    if (
      data.country_collection &&
      !countryIdsLocal &&
      isEmpty(countryIdsLocal)
    ) {
      toast.error("Please select a country");
      onValid(false);
    } else if (
      data.job_title_collection &&
      !jobTitleIdsLocal &&
      isEmpty(jobTitleIdsLocal)
    ) {
      toast.error("Please select a job title");
      onValid(false);
    } else {
      if (validator.current.allValid()) {
        onValid(true);
      } else {
        validator.current.showMessages();
        toast.error(dialogs.missingFields);
        onValid(false);
      }
    }
  };

  const setAllRelationsFromData = async (data) => {
    let relationDataSetFromProps = {
      searchesData: data?.relationData?.searchesArray,
      initiateDataText: data?.relationData?.initiateDataTextArray,
      initiateDataVideo: data?.relationData?.inviteDataVideoArray,
      calendarData: data?.relationData?.calenderDataArray,
      inviteData: data?.relationData?.inviteDataArray,
    };

    relationDataSetFromProps = JSON.parse(
      JSON.stringify(relationDataSetFromProps)
    );

    if (isEmpty(relationDataSetFromProps.searchesData)) {
      const relationRes = await getRelations();

      setRelationData(relationRes);
    } else setRelationData(relationDataSetFromProps);
  };

  const onHandleChangeOther = async (key1, value) => {
    if (key1 === "country_collection") {
      if (value === true) {
        const countryIds = countryList?.map((a) => a.country_id);
        setCountryIdsLocal(countryIds);

        await onChangeData({
          country_collection_ids: countryIds,
          [key1]: value,
        });
      } else {
        setCountryIdsLocal([]);
        onChangeData({ [key1]: value, country_collection_ids: [] });
      }
    } else if (key1 === "job_title_collection") {
      if (value === true) {
        const jobTitleIds = jobTitleList.map((a) => a.job_title_id);
        setJobTitleIdsLocal(jobTitleIds);
        await onChangeData({
          job_title_collection_ids: jobTitleIds,
          [key1]: value,
        });
      } else {
        setJobTitleIdsLocal([]);
        onChangeData({ [key1]: value, job_title_collection_ids: [] });
      }
    } else {
      onChangeData({ [key1]: value });
    }
  };

  const onChangeSwitches = async (
    value,
    relationId,
    keyToSet,
    commId = null
  ) => {
    let prevValue = cloneDeep(relationData);
    let prevSearch = prevValue[keyToSet];
    let tempArray = [];
    prevSearch.forEach((element) => {
      let ele = element;
      if (element.relation_id === relationId) {
        ele.selected = value;
        ele.is_networking_only = value;
        if (!isEmpty(commId)) ele.communication_settings_id = commId;
      }
      tempArray.push(ele);
    });

    prevValue[keyToSet] = tempArray;
    await setRelationData(prevValue);
    await onChangeData({ relationData: prevValue });
  };

  const onChangeDropdowns = async (value, relation_id, tier, keyToSet) => {
    let prevValue = cloneDeep(relationData);
    let prevSearch = prevValue[keyToSet];
    let tempArray = [];
    prevSearch.forEach((element, index) => {
      let ele = element;
      if (ele.relation_id === relation_id) {
        ele.value = value;
        ele.tier_id = value;
        ele.tier = tier;
      }
      tempArray.push(ele);
    });

    if (keyToSet === "calendarData");
    {
    }

    prevValue[keyToSet] = tempArray;
    await setRelationData(prevValue);
    await onChangeData({ relationData: prevValue });
  };

  const submitNewJobTitle = async () => {
    let dataForNewTitle = {
      job_title: newJobTitle,
      image_url: "testing_jobTitle_demo_2.com",
      sequence: 15,
      is_active: true,
    };
    const jobResponse = await addNewJobTitle(dataForNewTitle);
    if (jobResponse?.data?.code === 200) {
      toast.success(jobResponse?.data?.message);
      setJobModalVisible(false);
      setNewJobTitle("");
      setShowAddJobField(false);
      const jobTitlesData = await getJobTitles();
      setJobTitlesCollection(jobTitlesData);
    } else {
      toast.error(jobResponse?.data?.message);
    }
  };

  return (
    <div className="container-fluid">
      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">
              Registration Data Collection
            </h6>
          </div>
        </div>
        <div className="row mt-4">
          {/* <div className="col-1"></div> */}
          <div className={Tablet ? "col-4 text-end" : "col-3 text-end"}>
            {/* <div className="row"> */}
            {/* <div className="col-2"></div> */}
            {/* <div className="col-3 text-end mt-1"> */}
            <label className="text-secondary mx-1">
              <span>Country</span>
            </label>
            <Switch
              onChange={(value) =>
                onHandleChangeOther("country_collection", value)
              }
              checked={
                data && data.country_collection
                  ? data.country_collection
                  : false
              }
              className="mx-2"
            />
          </div>
          <div className="col-3 text-start">
            <Button
              disabled={data && data.country_collection ? false : true}
              className="px-3 button-pink rounded"
              onClick={() => setCountryModalVisible(true)}
            >
              Edit Dropdown
            </Button>
            <Modal
              title="Select Countries"
              visible={countryModalVisible}
              onCancel={() => {
                onChangeData({ country_collection_ids: countryIdsLocal });
                setCountryModalVisible(false);
              }}
              footer={null}
            >
              {countryList ? (
                <div className="row">
                  <div
                    className="col"
                    style={{ marginLeft: "2%", marginTop: "2%" }}
                  >
                    <Checkbox
                      onChange={async (e) => {
                        setIsSelectedAllCountries(e.target.checked);
                        if (e.target.checked && countryList) {
                          const countryIds = countryList.map(
                            (a) => a.country_id
                          );
                          setCountryIdsLocal(countryIds);
                          await onChangeData({
                            country_collection_ids: countryIds,
                          });
                        } else {
                          setCountryIdsLocal([]);
                          await onChangeData({
                            country_collection_ids: [],
                          });
                        }
                      }}
                      checked={
                        props.registerData.country_collection_ids.length !==
                          0 && isSelectedAllCountries
                      }
                    />
                    <span style={{ marginLeft: "2%" }}>Select All</span>
                  </div>
                </div>
              ) : null}
              <div className="social-media-div">
                <ul className="w-100" style={{ listStyleType: "none" }}>
                  {countryList &&
                    countryList.map((ele) => {
                      if (ele.country && ele.is_active)
                        return (
                          <li
                            key={"country_" + ele.country_id}
                            className="w-100 border my-2 px-2 py-1"
                          >
                            <span className="mx-2">
                              <Checkbox
                                onChange={(e) =>
                                  onChangeCountryTitle(e, ele, "country")
                                }
                                checked={
                                  props.registerData.country_collection_ids
                                    .length !== 0 &&
                                  countryIdsLocal.includes(ele.country_id)
                                    ? true
                                    : false
                                }
                              />
                            </span>
                            {ele.country}
                          </li>
                        );
                    })}
                </ul>
              </div>
            </Modal>
          </div>
          {/* </div> */}
          {/* </div> */}
          <div className="col-5"></div>
        </div>
        <div className="row mt-4">
          <div className={Tablet ? "col-6" : "col-3 text-end"}>
            <label className="text-secondary">
              <span>Allow Other Countries </span>
            </label>
            <Switch
              onChange={(value) =>
                onHandleChangeOther("allow_other_country", value)
              }
              disabled={data && data.country_collection ? false : true}
              checked={
                data && data.allow_other_country
                  ? data.allow_other_country
                  : false
              }
              className="mx-2"
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className={Tablet ? "col-4 text-end" : "col-3 text-end"}>
            {/* <div className="row">
                <div className="col-2"></div>
                <div className={Tablet ? "col-4" : "col-4 text-end mt-1"}> */}
            <label className="text-secondary">
              <span>Job Title</span>
            </label>
            <Switch
              onChange={(value) =>
                onHandleChangeOther("job_title_collection", value)
              }
              checked={
                data && data.job_title_collection
                  ? data.job_title_collection
                  : false
              }
              className="mx-2"
            />
          </div>
          <div className="col-2 text-start">
            <Button
              disabled={data && data.job_title_collection ? false : true}
              className="px-3 button-pink rounded"
              onClick={() => setJobModalVisible(true)}
            >
              Edit Dropdown
            </Button>
            <Modal
              title="Select Job Titles"
              visible={jobModalVisible}
              onCancel={() => {
                onChangeData({
                  job_title_collection_ids: jobTitleIdsLocal,
                });
                setJobModalVisible(false);
              }}
              footer={null}
            >
              {jobTitleList ? (
                <div className="row">
                  <div className="col" style={{ marginLeft: "2%" }}>
                    <div className="mb-3">
                      {showAddJobField === false && (
                        <Button
                          className="rounded"
                          onClick={() => setShowAddJobField(true)}
                        >
                          Add New Job Title
                        </Button>
                      )}
                      {showAddJobField === true && (
                        <Input
                          placeholder="Enter Job Title"
                          value={newJobTitle}
                          onChange={(event) =>
                            setNewJobTitle(event.target.value)
                          }
                        />
                      )}
                      {showAddJobField === true && (
                        <Button
                          className="rounded my-2"
                          onClick={submitNewJobTitle}
                        >
                          Add Job Title
                        </Button>
                      )}
                      {showAddJobField === true && (
                        <Button
                          className="rounded my-2 mx-2"
                          onClick={() => setShowAddJobField(false)}
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                    <Checkbox
                      onChange={async (e) => {
                        setIsSelectedAllTitles(e.target.checked);
                        if (e.target.checked && jobTitleList) {
                          const jobTitleIds = jobTitleList.map(
                            (a) => a.job_title_id
                          );
                          setJobTitleIdsLocal(jobTitleIds);
                          await onChangeData({
                            job_title_collection_ids: jobTitleIds,
                          });
                        } else {
                          setJobTitleIdsLocal([]);
                          await onChangeData({
                            job_title_collection_ids: [],
                          });
                        }
                      }}
                      checked={
                        props?.registerData?.job_title_collection_ids?.length !=
                          0 && isSelectedAllTitles
                      }
                    />
                    <span style={{ marginLeft: "2%" }}>Select All</span>
                  </div>
                </div>
              ) : null}
              <div className="social-media-div">
                <ul style={{ listStyleType: "none" }} className="px-4">
                  {jobTitleList &&
                    jobTitleList.map((ele) => {
                      if (ele.job_title && ele.is_active)
                        return (
                          <li className="w-100 border my-2 px-2 py-1">
                            <span className="mx-2">
                              <Checkbox
                                onChange={(e) =>
                                  onChangeCountryTitle(e, ele, "title")
                                }
                                checked={
                                  props?.registerData?.job_title_collection_ids
                                    ?.length !== 0 &&
                                  jobTitleIdsLocal?.includes(ele.job_title_id)
                                    ? true
                                    : false
                                }
                              />
                            </span>
                            {ele.job_title}
                          </li>
                        );
                    })}
                </ul>
              </div>
            </Modal>
          </div>
          {/* </div>
            </div> */}
          <div className="col-6"></div>
        </div>
        <div className="row mt-4">
          <div className={Tablet ? "col-6" : "col-3 text-end"}>
            <label className="text-secondary">
              <span>Allow Other Job Titles</span>
            </label>
            <Switch
              onChange={(value) =>
                onHandleChangeOther("allow_other_job_title", value)
              }
              disabled={data && data.job_title_collection ? false : true}
              checked={
                data && data.allow_other_job_title
                  ? data.allow_other_job_title
                  : false
              }
              className="mx-2"
            />
          </div>
        </div>

        <div className="row mt-4">
          {/* <div className={Tablet ? "col-9" : "col-6"}>
              <div className="row"> */}
          {/* <div className={Tablet ? "col-3 text-end" : "col-2"}></div> */}
          <div className={Tablet ? "col-6" : "col-3 text-end"}>
            <label className="text-secondary">
              <span>Obligatory check box 1</span>
            </label>
            <Switch
              onChange={(value) => onHandleChangeOther("check_box_1", value)}
              checked={
                data && data.check_box_1
                  ? data.check_box_1
                  : data &&
                    data.event_registration_terms_and_conditions_settings &&
                    data.event_registration_terms_and_conditions_settings[0]
                  ? data.event_registration_terms_and_conditions_settings[0]
                      .is_active
                  : false
              }
              className="mx-2"
            />
          </div>
          <div className="col-4 text-start mt-1">
            <span className="fw-bolder font-size-16">Add Text and Link</span>
          </div>
        </div>
        {/* </div>
          </div> */}
        {(data && data.check_box_1) ||
        (data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[0] &&
          data.event_registration_terms_and_conditions_settings[0]
            .is_active) ? (
          <div className="row mt-4">
            <div className="col-3"></div>
            <div className="col-9">
              <CKEditor
                initData={props.ckeditor1Data}
                data={props.ckeditor1Data}
                onChange={(event, editor) => {
                  const data1 = event.editor.getData();
                  onChangeCKEditorData("term_description_1", data1);
                }}
                config={{
                  resize_enabled: true,
                  allowedContent: true,
                  fullPage: false,
                  toolbarGroups: [
                    // { name: "clipboard", groups: ["clipboard", "undo"] },
                    // {
                    //     name: "editing",
                    //     groups: ["find", "selection", "spellchecker"]
                    // },
                    // { name: "forms" },
                    // { name: "tools" },
                    // { name: "document", groups: ["mode", "document", "doctools"] },
                    {
                      name: "document",
                      groups: ["mode", "document", "doctools"],
                    },
                    { name: "clipboard", groups: ["clipboard", "undo"] },
                    {
                      name: "editing",
                      groups: ["find", "selection", "spellchecker"],
                    },
                    { name: "forms" },
                    "/",
                    {
                      name: "basicstyles",
                      groups: ["basicstyles", "cleanup"],
                    },
                    {
                      name: "paragraph",
                      groups: ["list", "indent", "blocks", "align", "bidi"],
                    },
                    { name: "links" },
                    { name: "insert" },
                    "/",
                    { name: "styles" },
                    { name: "colors" },
                    { name: "tools" },
                    { name: "others" },
                    { name: "about" },
                  ],
                }}
              />
            </div>
          </div>
        ) : null}

        <div className="row mt-4">
          {/* <div className={Tablet ? "col-9" : "col-6"}>
              <div className="row">
                <div className={Tablet ? "col-1" : "col-2"}></div> */}
          <div className={Tablet ? "col-6" : "col-3 text-end"}>
            <label className="text-secondary">
              <span>Obligatory check box 2</span>
            </label>
            <Switch
              onChange={(value) => {
                onHandleChangeOther("check_box_2", value);
              }}
              checked={
                data && data.check_box_2
                  ? data.check_box_2
                  : data &&
                    data.event_registration_terms_and_conditions_settings &&
                    data.event_registration_terms_and_conditions_settings[1]
                  ? data.event_registration_terms_and_conditions_settings[1]
                      .is_active
                  : false
              }
              className="mx-2"
            />
          </div>
          <div className="col-4 text-start mt-1">
            <span className="fw-bolder font-size-16">Add Text and Link</span>
          </div>
        </div>
        {/* </div>
          </div> */}
        {(data && data.check_box_2) ||
        (data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[1] &&
          data.event_registration_terms_and_conditions_settings[1]
            .is_active) ? (
          <div className="row mt-4">
            <div className="col-3"></div>
            <div className="col-9">
              <CKEditor
                initData={props.ckeditor2Data}
                data={props.ckeditor2Data}
                onChange={(event, editor) => {
                  const data2 = event.editor.getData();
                  onChangeCKEditorData("term_description_2", data2);
                }}
                config={{
                  resize_enabled: true,
                  allowedContent: true,
                  fullPage: false,
                  toolbarGroups: [
                    // { name: "clipboard", groups: ["clipboard", "undo"] },
                    // {
                    //     name: "editing",
                    //     groups: ["find", "selection", "spellchecker"]
                    // },
                    // { name: "forms" },
                    // { name: "tools" },
                    // { name: "document", groups: ["mode", "document", "doctools"] },
                    {
                      name: "document",
                      groups: ["mode", "document", "doctools"],
                    },
                    { name: "clipboard", groups: ["clipboard", "undo"] },
                    {
                      name: "editing",
                      groups: ["find", "selection", "spellchecker"],
                    },
                    { name: "forms" },
                    "/",
                    {
                      name: "basicstyles",
                      groups: ["basicstyles", "cleanup"],
                    },
                    {
                      name: "paragraph",
                      groups: ["list", "indent", "blocks", "align", "bidi"],
                    },
                    { name: "links" },
                    { name: "insert" },
                    "/",
                    { name: "styles" },
                    { name: "colors" },
                    { name: "tools" },
                    { name: "others" },
                    { name: "about" },
                  ],
                }}
              />
            </div>
          </div>
        ) : null}
        <div className="row mt-4">
          {/* <div className="col-6">
              <div className="row">
                <div className="col-2"></div> */}
          <div className={Tablet ? "col-6" : "col-3 text-end"}>
            <label className="text-secondary">
              <span>Obligatory check box 3</span>
            </label>
            <Switch
              onChange={(value) => onHandleChangeOther("check_box_3", value)}
              checked={
                data && data.check_box_3
                  ? data.check_box_3
                  : data &&
                    data.event_registration_terms_and_conditions_settings &&
                    data.event_registration_terms_and_conditions_settings[2]
                  ? data.event_registration_terms_and_conditions_settings[2]
                      .is_active
                  : false
              }
              className="mx-2"
            />
          </div>
          <div className="col-4 text-start mt-1">
            <span className="fw-bolder font-size-16">Add Text and Link</span>
          </div>
        </div>
        {/* </div>
          </div> */}

        {(data && data.check_box_3) ||
        (data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[2] &&
          data.event_registration_terms_and_conditions_settings[2]
            .is_active) ? (
          <div className="row mt-4">
            <div className="col-3"></div>
            <div className="col-9">
              <CKEditor
                initData={props.ckeditor3Data}
                data={props.ckeditor3Data}
                onChange={(event, editor) => {
                  const data3 = event.editor.getData();
                  onChangeCKEditorData("term_description_3", data3);
                }}
                config={{
                  resize_enabled: true,
                  allowedContent: true,
                  fullPage: false,
                  toolbarGroups: [
                    {
                      name: "document",
                      groups: ["mode", "document", "doctools"],
                    },
                    { name: "clipboard", groups: ["clipboard", "undo"] },
                    {
                      name: "editing",
                      groups: ["find", "selection", "spellchecker"],
                    },
                    { name: "forms" },
                    "/",
                    {
                      name: "basicstyles",
                      groups: ["basicstyles", "cleanup"],
                    },
                    {
                      name: "paragraph",
                      groups: ["list", "indent", "blocks", "align", "bidi"],
                    },
                    { name: "links" },
                    { name: "insert" },
                    "/",
                    { name: "styles" },
                    { name: "colors" },
                    { name: "tools" },
                    { name: "others" },
                    { name: "about" },
                  ],
                }}
              />
            </div>
          </div>
        ) : null}

        {data && data.event_id ? (
          <>
            <div className="row mt-4 border-bottom-gray pb-5 mb-2">
              {/* <div className={Tablet ? "col-7" : "col-12"}>
                  <div className="row"> */}
              <div className={Tablet ? "col-6" : "col-3 text-end mt-1"}>
                <label className="text-secondary">
                  <span>Registration Survey</span>
                </label>
                <Switch
                  onChange={(value) =>
                    onHandleChangeOther("is_registration_survey_active", value)
                  }
                  checked={
                    data && data.is_registration_survey_active
                      ? data.is_registration_survey_active
                      : false
                  }
                  className="mx-2"
                />
              </div>
              {data && data.is_registration_survey_active ? (
                <>
                  <div className="col-4 text-start mt-1">
                    <span className="fw-bolder font-size-16">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="None"
                        defaultValue={
                          data && data.registration_survey_id
                            ? data.registration_survey_id
                            : null
                        }
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        listHeight={120}
                        listItemHeight={4}
                        className="w-100 fs-bold-14"
                        allowClear
                        onChange={(e) =>
                          onHandleChangeOther("registration_survey_id", e)
                        }
                      >
                        {surveyList &&
                          surveyList.map((item, i) => {
                            return (
                              <Option value={item.survey_id}>
                                {item.survey_title}
                              </Option>
                            );
                          })}
                        {surveyList.length === 0 && (
                          <Option disabled>
                            No registration surveys are created
                          </Option>
                        )}
                      </Select>
                      {data.is_registration_survey_active &&
                        validator.current.message(
                          "Survey",
                          data.registration_survey_id,
                          "required",
                          { className: "text-danger py-1 mx-4" }
                        )}
                    </span>
                  </div>
                  <div className="col-3 mt-1 ">
                    <Button
                      onClick={() => setIsSurveyModalVisible(true)}
                      className="px-3 button-pink rounded"
                    >
                      + Create New Survey
                    </Button>
                  </div>
                </>
              ) : null}
              {/* </div>
                </div> */}
              <div className="col-6"></div>
            </div>
          </>
        ) : null}
      </section>

      <Modal
        visible={isSurveyModalVisible}
        onCancel={() => setIsSurveyModalVisible(false)}
        footer={null}
        width={1100}
        title="Add Survey"
      >
        <div className="p-2">
          <AddNewSurvey
            event_id={data?.event_id}
            isFromEvent={true}
            isFromEventSecondStep={true}
            onCancelFromModal={() => setIsSurveyModalVisible(false)}
            onSuccessFromModal={async () => {
              setIsSurveyModalVisible(false);
              const surveyData = await getSurveys();
              setSurveyList(surveyData);
            }}
          />
        </div>
      </Modal>
      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Profiles</h6>
          </div>
        </div>

        <div className="row mt-2 mb-4">
          <div className="col-12 mt-1 pb-4">
            <span className="fs-16">
              NB: Select the MINIMUM tier that each functionality is available
              for, all tiers above the tier selected will also have
              functionality enabled.
            </span>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-3 mt-1 text-end">
                <label className="text-secondary mt-1">
                  <span>Agent Profiles</span>
                </label>
              </div>
              <div className={`${Tablet ? "pt-2" : ""} col-6 text-start mt-1`}>
                <span className="fw-bolder font-size-16">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="None"
                    defaultValue={
                      data && data.agent_tier_id
                        ? data.agent_tier_id
                        : data?.registration_profile_settings?.[0]?.tier?.tier
                    }
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    className="w-100 fs-bold-14"
                    onChange={(e, i) => onHandleChangeOther("agent_tier_id", e)}
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {tiers &&
                      tiers.map((item, i) => {
                        return (
                          <Option value={item.tier_id}>{item.tier}</Option>
                        );
                      })}
                  </Select>
                </span>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-3 mt-1 text-end">
                <label className="text-secondary mt-1">
                  <span>Agent Social Links</span>
                </label>
              </div>
              <div className={`${Tablet ? "pt-2" : ""} col-6 text-start mt-1`}>
                <span className="fw-bolder font-size-16">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="None"
                    className="w-100 fs-bold-14"
                    defaultValue={
                      data && data.agent_social_link_tier_id
                        ? data.agent_social_link_tier_id
                        : data?.registration_profile_settings?.[0]
                            ?.social_link_tier?.tier
                    }
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    onChange={(e) =>
                      onHandleChangeOther("agent_social_link_tier_id", e)
                    }
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {tiers &&
                      tiers.map((item, i) => {
                        return (
                          <Option value={item.tier_id}>{item.tier}</Option>
                        );
                      })}
                  </Select>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 mb-4 pb-4 border-bottom-gray">
          <div className="col-6">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-3 mt-1 text-end">
                <label className="text-secondary mt-1">
                  <span>Delegate Profiles</span>
                </label>
              </div>
              <div className={`${Tablet ? "pt-2" : ""} col-6 text-start mt-1`}>
                <span className="fw-bolder font-size-16">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="None"
                    defaultValue={
                      data && data.delegate_tier_id
                        ? data.delegate_tier_id
                        : data?.registration_profile_settings?.[1]?.tier?.tier
                    }
                    className="w-100 fs-bold-14"
                    onChange={(e) => onHandleChangeOther("delegate_tier_id", e)}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {tiers &&
                      tiers.map((item, i) => {
                        return (
                          <Option value={item.tier_id}>{item.tier}</Option>
                        );
                      })}
                  </Select>
                </span>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-3 mt-1 text-end">
                <label className="text-secondary mt-1">
                  <span>Delegate Social Links</span>
                </label>
              </div>
              <div className={`${Tablet ? "pt-2" : ""} col-6 text-start mt-1`}>
                <span className="fw-bolder font-size-16">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="None"
                    className="w-100 fs-bold-14"
                    defaultValue={
                      data && data.delegate_social_link_tier_id
                        ? data.delegate_social_link_tier_id
                        : data?.registration_profile_settings?.[1]
                            ?.social_link_tier?.tier
                    }
                    onChange={(e) =>
                      onHandleChangeOther("delegate_social_link_tier_id", e)
                    }
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {tiers &&
                      tiers.map((item, i) => {
                        return (
                          <Option value={item.tier_id}>{item.tier}</Option>
                        );
                      })}
                  </Select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Searches</h6>
          </div>
        </div>
        <div className="row mt-4 pb-4 border-bottom-gray">
          {relationData.searchesData?.map((item) => {
            return (
              <div className="col-6" style={{ marginBottom: "20px" }}>
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-3 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation?.relation_title
                        ? item?.relation?.relation_title
                        : item?.relation_title}
                    </label>
                    <Switch
                      onChange={(value) => {
                        onChangeSwitches(
                          value,
                          item.relation
                            ? item?.relation?.relation_id
                            : item?.relation_id,
                          "searchesData",
                          item?.communication_settings_id
                        );
                      }}
                      checked={
                        item && item.is_networking_only
                          ? item.is_networking_only
                          : false
                      }
                      className="mx-2 d-none"
                    />
                  </div>
                  <div
                    className={`${Tablet ? "pt-2" : ""} col-6 text-start mt-1`}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      id="search"
                      placeholder="None"
                      defaultValue={item?.tier_id}
                      className="w-100 fs-bold-14"
                      onChange={(value, event) => {
                        onChangeDropdowns(
                          value,
                          item?.relation
                            ? item?.relation?.relation_id
                            : item?.relation_id,
                          event?.children,
                          "searchesData"
                        );
                      }}
                      listHeight={120}
                      listItemHeight={4}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {tiers.map((item, i) => {
                        return (
                          <Option value={item?.tier_id}>{item.tier}</Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-6 mt-4">
            <h6 className="font-pink fw-bolder fs-20">
              Initiate 1-1 Text Chats (from Profile/Search )
            </h6>
          </div>
          <div className="col-6 mt-4">
            <h6 className="font-pink fw-bolder fs-20 mx-4">
              Initiate Video Calls (from Profile/Search/Chat)
            </h6>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-end">
            <span className="mx-5"></span>
            <span className="pb-4">
              {/* <Tooltip
                  placement="right"
                  title="Networking only?"
                  color="#ef0855"
                >
                  <img
                    src={info}
                    alt="info"
                    className="registration-and-communication-info-logo p-2"
                  />
                </Tooltip> */}
            </span>
          </div>
          <div className="col-6 text-end">
            <span className="mx-5">
              {/* <b>Video </b> (From Profile / Search) */}
            </span>
            <span className="pb-4">
              {/* <Tooltip
                  placement="right"
                  title="Networking only?"
                  color="#ef0855"
                >
                  <img
                    src={info}
                    alt="info"
                    className="registration-and-communication-info-logo p-2"
                  />
                </Tooltip> */}
            </span>
          </div>
        </div>

        <div className="row mt-4 pb-4 border-bottom-gray">
          <div className="col-6">
            {relationData?.initiateDataText?.map((item, i) => {
              return (
                <>
                  <div
                    className="row"
                    style={{ marginBottom: "20px" }}
                    key={"initiateDataText_" + i}
                  >
                    <div className="col-5 text-end">
                      <label className="text-secondary">
                        {item?.relation?.relation_title
                          ? item?.relation?.relation_title
                          : item?.relation_title}
                      </label>
                      <Switch
                        onChange={(value) => {
                          onChangeSwitches(
                            value,
                            item.relation
                              ? item.relation.relation_id
                              : item.relation_id,
                            "initiateDataText"
                          );
                        }}
                        checked={
                          item && item.is_networking_only
                            ? item.is_networking_only
                            : false
                        }
                        className="mx-2 d-none"
                      />
                    </div>
                    <div
                      className={`${Tablet ? "pt-2" : ""} col-6 text-start `}
                    >
                      <Select
                        id="initiatetext"
                        showSearch
                        optionFilterProp="children"
                        defaultValue={item?.tier_id}
                        onChange={(value, event) => {
                          onChangeDropdowns(
                            value,
                            item.relation
                              ? item?.relation?.relation_id
                              : item?.relation_id,
                            event?.children,
                            "initiateDataText"
                          );
                        }}
                        placeholder="None"
                        className="w-100 fs-bold-14"
                        allowClear
                        listHeight={120}
                        listItemHeight={4}
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                      >
                        {tiers.map((item, i) => {
                          return (
                            <Option value={item?.tier_id}>{item?.tier}</Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="col-6">
            {relationData?.initiateDataVideo?.map((item, i) => {
              return (
                <div
                  className="row"
                  style={{ marginBottom: "20px" }}
                  key={"initiateDataVideo_" + i}
                >
                  <div className="col-5 text-end">
                    <label className="text-secondary">
                      {item?.relation?.relation_title
                        ? item?.relation?.relation_title
                        : item?.relation_title}
                    </label>
                    <Switch
                      onChange={(value) => {
                        onChangeSwitches(
                          value,
                          item.relation
                            ? item?.relation?.relation_id
                            : item?.relation_id,
                          "initiateDataVideo"
                        );
                      }}
                      checked={
                        item && item.is_networking_only
                          ? item.is_networking_only
                          : false
                      }
                      className="mx-2 d-none"
                    />
                  </div>

                  <div className={`${Tablet ? "pt-2" : ""} col-6 text-start `}>
                    <Select
                      id="initiatevideo"
                      showSearch
                      optionFilterProp="children"
                      defaultValue={item?.tier_id}
                      onChange={(value, event) => {
                        onChangeDropdowns(
                          value,
                          item.relation
                            ? item?.relation?.relation_id
                            : item?.relation_id,
                          event?.children,
                          "initiateDataVideo"
                        );
                      }}
                      listHeight={120}
                      listItemHeight={4}
                      placeholder="None"
                      className="w-100 fs-bold-14"
                      allowClear
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {tiers.map((item, i) => {
                        return (
                          <Option value={item?.tier_id}>{item?.tier}</Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Calendars & Meetings</h6>
          </div>
        </div>
        <div className="row mt-4 pb-4 border-bottom-gray">
          {relationData?.calendarData?.map((item, i) => {
            return (
              <div
                className="col-6"
                style={{ marginBottom: "20px" }}
                key={"calenderData_" + i}
              >
                <div className="row">
                  {/* <div className="col-1"></div> */}
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation?.relation_title
                        ? item?.relation?.relation_title
                        : item?.relation_title}
                    </label>
                    <Switch
                      onChange={(value) => {
                        onChangeSwitches(
                          value,
                          item.relation
                            ? item?.relation?.relation_id
                            : item?.relation_id,
                          "calendarData"
                        );
                      }}
                      checked={
                        item && item.is_networking_only
                          ? item.is_networking_only
                          : false
                      }
                      className="mx-2 d-none"
                    />
                  </div>
                  <div
                    className={`${Tablet ? "pt-2" : ""} col-6 text-start mt-1`}
                  >
                    <Select
                      id="calendar"
                      showSearch
                      optionFilterProp="children"
                      defaultValue={item?.tier_id}
                      placeholder="None"
                      className="w-100 fs-bold-14"
                      allowClear
                      onChange={(value, event) =>
                        onChangeDropdowns(
                          value,
                          item?.relation_id,
                          event?.children,
                          "calendarData"
                        )
                      }
                      listHeight={120}
                      listItemHeight={4}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {tiers.map((item, i) => {
                        return (
                          <Option value={item.tier_id}>{item.tier}</Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Inviting Delegates</h6>
          </div>
        </div>
        <div className="row mt-4 mb-4 border-bottom-gray pb-4">
          {relationData?.inviteData?.map((item, i) => {
            return (
              <div className="col-6" key={"inviteData_" + i}>
                <div className="row">
                  {/* <div className="col-1"></div> */}
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation?.relation_title
                        ? item?.relation?.relation_title
                        : item?.relation_title}
                    </label>
                    <Switch
                      onChange={(value) =>
                        onChangeSwitches(
                          value,
                          item.relation
                            ? item?.relation?.relation_id
                            : item?.relation_id,
                          "inviteData"
                        )
                      }
                      checked={
                        item && item.is_networking_only
                          ? item.is_networking_only
                          : false
                      }
                      className="mx-2 d-none"
                    />
                  </div>
                  <div
                    className={`${Tablet ? "pt-2" : ""} col-6 text-start mt-1`}
                  >
                    <Select
                      id="invite"
                      showSearch
                      optionFilterProp="children"
                      defaultValue={item.tier_id}
                      placeholder="None"
                      className="w-100 fs-bold-14"
                      allowClear
                      onChange={(value, event) => {
                        onChangeDropdowns(
                          value,
                          item.relation
                            ? item?.relation?.relation_id
                            : item?.relation_id,
                          event?.children,
                          "inviteData"
                        );
                      }}
                      listHeight={120}
                      listItemHeight={4}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {tiers.map((item, i) => {
                        return (
                          <Option value={item?.tier_id}>{item?.tier}</Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default RegistrationAndCommunication;
