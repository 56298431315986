import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Radio, Select, Space, Switch } from "antd";
import "./VideoConference.css";
import { useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import uuid from "react-uuid";
import { getCookie } from "../../utils/commonFunctions";

const Poll = ({
  changeNetworkingFlag,
  changePollFlag,
  clickCreatePoll,
  changeClickCreatePollFlag,
  changeClickViewPollFlag,
  clickViewPoll,
  changeIsProducerFlag,
  streamingAudiId,
}) => {
  let CryptoJS = require("crypto-js");
  const [loginUserId, setLoginUserId] = useState(null);

  const [createPoll, setCreatePoll] = useState(false);
  const hidePollRef = useRef(null);

  const [showPoll, setShowPoll] = useState(false);
  const [showCreatePoll, setShowCreatePoll] = useState(false);
  const [showViewPoll, setShowViewPoll] = useState(false);
  const [showViewResult, setShowViewResult] = useState(false);

  const [pollSubmitBtn, setPollSubmitBtn] = useState(true);

  const [videoConferenceUrl, setVideoConferenceUrl] = useState("");
  const [isFromNetworkingFlag, setIsFromNetworkingFlag] = useState("");
  const [chartType, setChartType] = useState("pie");
  const [viewChartTypePollId, setViewChartTypePollId] = useState(null);

  const [pollSelectedOptionValue, setPollSelectedOptionValue] = useState(1);
  const [loader, setLoader] = useState(false);

  const [resultArr, setResultArr] = useState({
    labels: [],
    datasets: [
      {
        label: "number of votes",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });
  const { Option } = Select;

  const [pollFields, setPollFields] = useState({
    pollName: "",
    question: "",
    answers: [
      {
        option: "",
      },
    ],
    show_result_chart: false,
    result_chart_type: "pie",
  });

  const [pollData, setPollData] = useState(null);

  const conferenceUrl = useSelector(
    (state) => state?.videoConference?.videoConferenceUrl
  );

  const createPollFlag = useSelector(
    (state) => state?.videoConference?.createPollFlag
  );

  const isFromNetworking = useSelector(
    (state) => state?.videoConference?.isFromNetworking
  );

  const auditorium_session_id = useSelector(
    (state) => state?.videoConference?.auditorium_session_id
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  useEffect(() => {
    if (getCookie("user_id")) {
      let cipher_login_user_id = getCookie("user_id");
      let bytes_login_user_id = CryptoJS.AES.decrypt(
        cipher_login_user_id,
        "user_id"
      );
      let jsonparse_user_id = JSON.parse(
        bytes_login_user_id.toString(CryptoJS.enc.Utf8)
      );

      setLoginUserId(jsonparse_user_id);
    }
  }, []);

  useEffect(() => {
    setVideoConferenceUrl(conferenceUrl);
  }, [conferenceUrl]);

  useEffect(() => {
    setCreatePoll(createPollFlag);

    changeIsProducerFlag(createPollFlag);
  }, [createPollFlag]);

  useEffect(() => {
    setIsFromNetworkingFlag(isFromNetworking);
    changeNetworkingFlag(isFromNetworking);
  }, [isFromNetworking]);

  useEffect(() => {
    if (clickCreatePoll) {
      changePollFlag(true);
      setTimeout(() => {
        setShowPoll(true);
        setShowCreatePoll(true);
      }, 100);
      setShowViewResult(false);

      setShowViewPoll(false);
      setViewChartTypePollId(null);
    } else {
      setViewChartTypePollId(null);

      setShowPoll(false);
      setShowCreatePoll(false);
      setShowViewPoll(false);
    }
  }, [clickCreatePoll]);

  useEffect(() => {
    if (clickViewPoll) {
      getPollListingDetails();
      changePollFlag(true);
      setShowPoll(true);
      setShowCreatePoll(false);
      setShowViewResult(false);

      setShowViewPoll(true);
      setViewChartTypePollId(null);
    } else {
      setShowPoll(false);
      setShowViewPoll(false);
      setShowCreatePoll(false);
      setViewChartTypePollId(null);
    }
  }, [clickViewPoll]);

  const onClickCreatePoll = () => {
    setShowPoll(true);
    changePollFlag(true);
    setShowCreatePoll(true);
    setShowViewPoll(false);
  };

  const createPollAPI = async () => {
    const checkForValidAnswers = (arr) => {
      return arr?.every((element) => typeof element === "string");
    };
    if (
      pollFields?.pollName?.length < 1 ||
      pollFields?.question?.length < 1 ||
      !checkForValidAnswers(pollFields?.answers)
    ) {
      toast.error("Fields are missing");
      return;
    }

    let body = {
      poll_name: pollFields?.pollName,
      poll_question: pollFields?.question,
      auditorium_session_id: auditorium_session_id,
      show_result_chart: pollFields?.show_result_chart,

      poll_answer: pollFields?.answers,
    };

    const response = await ApiCall(
      "POST",
      body,
      "/admin/auditoriumsessionconfiguration/poll/create"
    );

    if (response?.data?.code === 200) {
      toast.success("Poll Created Succesfully");
      setPollFields({
        pollName: "",
        question: "",
        answers: [],
        show_result_chart: false,
        result_chart_type: null,
      });

      setShowPoll(false);
      setShowCreatePoll(false);
      setShowViewPoll(false);
      changePollFlag(false);

      changeClickCreatePollFlag();
      changeClickViewPollFlag();
    } else {
      toast.error(response?.data?.message);
    }
  };

  const getPollListingDetails = async () => {
    let getDeviceId = localStorage.getItem("device_id");

    const response = await ApiCall(
      "GET",
      "",
      "/admin/auditoriumsessionconfiguration/polllisting/get",
      {
        auditorium_session_id:
          window.location.pathname !== "/streaming"
            ? auditorium_session_id
            : streamingAudiId,
        user_id: window.location.pathname !== "/streaming" ? loginUserId : null,
        device_id:
          window.location.pathname !== "/streaming"
            ? ""
            : getDeviceId ?? "abcd",
      },
      true
    );

    if (response?.data?.code === 200) {
      setPollData(response?.data?.data);
    } else {
      toast.error(response?.data?.message);
    }
  };

  const saveUserAnswer = async (poll_id, poll_answer_id, chart_type) => {
    if (poll_answer_id === 1) {
      toast.error("Please select an answer");
      return;
    }
    setChartType("pie");
    setViewChartTypePollId(poll_id);
    setLoader(true);
    let device_id = uuid();
    let body = {
      poll_id: poll_id,
      poll_answer_id: poll_answer_id,
      user_id: window.location.pathname !== "/streaming" ? loginUserId : null,
      device_id: window.location.pathname !== "/streaming" ? "" : device_id,
    };
    const response = await ApiCall(
      "POST",
      body,
      "/admin/auditoriumsessionconfiguration/userpollanswer/create",
      "",
      true
    );
    if (response?.data?.code === 200) {
      toast.success(response?.data?.message);
      localStorage.setItem("device_id", device_id);
      getPollListingDetails();
      setPollSelectedOptionValue(1);
      setResultArr({
        labels: response?.data?.data?.labels.map((item, i) => `Option ${item}`),
        datasets: [
          {
            label: chart_type?.toLowerCase() === "bar" ? "Number of Votes" : "",
            data: response?.data?.data?.datasets?.map((item) => item?.data),
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(82, 215, 38, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(82, 215, 38, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });

      setShowViewResult(true);
      setLoader(false);
    } else {
      toast.error(response?.data?.message);
      setLoader(false);
    }
  };

  const onPollOptionChange = (e) => {
    setPollSelectedOptionValue(e.target.value);
  };

  const callViewResultAPI = async (poll_id, chart_type) => {
    setLoader(true);
    const response = await ApiCall(
      "GET",
      "",
      "/admin/auditoriumsessionconfiguration/pollresult/get",
      {
        poll_id: poll_id,
      },
      true
    );

    if (response?.data?.code === 200) {
      setResultArr({
        labels: response?.data?.data?.labels.map((item, i) => `Option ${item}`),
        datasets: [
          {
            label: chart_type?.toLowerCase() === "bar" ? "Votes" : "",
            data: response?.data?.data?.datasets?.map((item) => item?.data),
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(82, 215, 38, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(82, 215, 38, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
      setLoader(false);
    } else {
      toast.error(response?.data?.message);
      setLoader(false);
    }
  };

  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const optionsPie = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "",
      },
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  const optionsBar = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "",
      },
      legend: {
        display: false,
        position: "right",
      },
    },
  };

  return (
    <>
      {showPoll ? (
        <div>
          {showCreatePoll ? (
            <div className="video-conference-create-poll-container">
              <div className="video-conference-create-poll-fields">
                <div className="video-conference-create-poll-label">
                  Enter Poll Name Here
                </div>
                <div className="video-conference-create-poll-question-poll">
                  <Input
                    name="poll_name"
                    value={pollFields?.pollName}
                    placeholder="Enter Poll Name Here"
                    className="mb-2 w-100"
                    onChange={(e) => {
                      setPollFields({
                        ...pollFields,
                        pollName: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="video-conference-create-poll-fields">
                <div className="video-conference-create-poll-label">
                  Enter Question Here
                </div>
                <div className="video-conference-create-poll-question-poll">
                  <Input
                    value={pollFields?.question}
                    placeholder="Enter Question Here"
                    className="mb-2 w-100"
                    onChange={(e) => {
                      setPollFields({
                        ...pollFields,
                        question: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="video-conference-create-poll-answers-container">
                {pollFields?.answers?.map((item, i) => {
                  return (
                    <>
                      <div className="video-conference-create-poll-answers-fields">
                        <div className="video-conference-create-poll-answer-label">
                          <span>{`Answer ${i + 1}`}</span>
                        </div>
                        <div className="video-conference-create-poll-answer-input">
                          <Input
                            key={"pollcreateanswers_" + i}
                            placeholder={"Enter Answer here"}
                            className="mb-2 w-100"
                            onChange={(e) => {
                              let arr = pollFields?.answers;
                              arr[i] = e.target.value;
                              setPollFields({
                                ...pollFields,
                                answers: arr,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
                <Button
                  className="video-conference-poll-answers-btn mb-1"
                  type="primary"
                  onClick={() => {
                    let arr = pollFields?.answers;
                    arr.push({
                      option: "",
                    });
                    setPollFields({
                      ...pollFields,
                      answers: arr,
                    });
                  }}
                >
                  + Add an answer
                </Button>
              </div>

              <div className="video-conference-create-poll-fields">
                <div className="video-conference-create-poll-label"></div>
                <div>
                  <Button
                    onClick={() => {
                      createPollAPI();
                    }}
                    className=""
                  >
                    Create
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          {showViewPoll ? (
            <>
              <div className="video-conference-show-poll-container mb-1">
                {pollData &&
                  pollData?.map((item, i) => {
                    return (
                      <>
                        <div
                          className="video-conference-show-poll-list-chart-container mt-1"
                          // className={
                          //   window.location.pathname !== "/streaming"
                          //     ? "video-conference-show-poll-list-chart-container mt-1"
                          //     : "video-conference-show-poll-list-chart-container-streaming mt-1"
                          // }
                        >
                          <div
                            className="video-conference-show-poll-fields"
                            // className={
                            //   window.location.pathname !== "/streaming"
                            //     ? "video-conference-show-poll-fields"
                            //     : "video-conference-show-poll-fields-streaming"
                            // }
                            key={"getPollData_" + i}
                          >
                            <h4 className="fs-pink">{item?.poll_question}</h4>
                            {item?.auditorium_session_poll_users_answers
                              ?.length === 0 ? (
                              <Radio.Group
                                onChange={onPollOptionChange}
                                value={pollSelectedOptionValue}
                                disabled={
                                  item?.auditorium_session_poll_users_answers
                                    ?.length === 0
                                    ? false
                                    : true
                                }
                              >
                                <Space direction="vertical">
                                  {item &&
                                    item?.auditorium_session_poll_answers?.map(
                                      (elem, i) => {
                                        return (
                                          <Radio
                                            key={"pollanswer_" + i}
                                            value={elem?.poll_answer_id}
                                          >
                                            {elem?.poll_answer}
                                          </Radio>
                                        );
                                      }
                                    )}
                                </Space>
                              </Radio.Group>
                            ) : (
                              <div>
                                {item &&
                                  item?.auditorium_session_poll_answers?.map(
                                    (elem, i) => {
                                      return (
                                        <p key={"pollanswer_" + i}>
                                          {`Option ${i + 1} - ${
                                            elem?.poll_answer
                                          }`}
                                        </p>
                                      );
                                    }
                                  )}
                              </div>
                            )}

                            <div className="video-conference-bottom-btns mt-2">
                              {item?.auditorium_session_poll_users_answers
                                ?.length === 0 ? (
                                <Button
                                  onClick={() => {
                                    saveUserAnswer(
                                      item?.poll_id,
                                      pollSelectedOptionValue,
                                      "pie"
                                    );
                                  }}
                                  className="video-conference-create-poll-save-btn"
                                  loading={loader}
                                >
                                  Submit
                                </Button>
                              ) : item?.poll_id !== viewChartTypePollId ? (
                                <Button
                                  loading={loader}
                                  onClick={() => {
                                    // setShowViewPoll(false);
                                    // changeClickViewPollFlag(false);
                                    setShowViewResult(true);
                                    callViewResultAPI(item?.poll_id, "pie");
                                    setChartType("pie");
                                    setViewChartTypePollId(item?.poll_id);
                                  }}
                                  className="video-conference-create-poll-save-btn"
                                >
                                  View Result
                                </Button>
                              ) : (
                                <Button
                                  loading={loader}
                                  onClick={() => {
                                    getPollListingDetails();
                                    callViewResultAPI(item?.poll_id, chartType);
                                  }}
                                  className="video-conference-create-poll-save-btn"
                                >
                                  Refresh Result
                                </Button>
                              )}
                            </div>
                          </div>
                          <div className="d-flex">
                            {item?.poll_id === viewChartTypePollId ? (
                              <>
                                <div className="video-conference-result-poll-container mt-1 mb-1">
                                  {chartType?.toLowerCase() === "pie" ? (
                                    <div
                                      className="video-conference-result-chart-type"
                                      style={{
                                        width: "258px",
                                        height: "186px",
                                      }}
                                    >
                                      <Pie
                                        data={resultArr}
                                        options={optionsPie}
                                      />
                                    </div>
                                  ) : chartType?.toLowerCase() === "bar" ? (
                                    <div
                                      className="video-conference-result-chart-type"
                                      style={{
                                        width: "433px",
                                        height: "200px",
                                      }}
                                    >
                                      <Bar
                                        options={optionsBar}
                                        data={resultArr}
                                      />
                                    </div>
                                  ) : null}
                                </div>

                                <div className="video-conference-show-poll-chart-select">
                                  <Select
                                    showSearch
                                    value={chartType}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="result_chart_type"
                                    className="fs-bold-14 w-100"
                                    onChange={(val) => {
                                      setShowViewResult(true);

                                      setChartType(val);
                                      setViewChartTypePollId(item?.poll_id);
                                      callViewResultAPI(item?.poll_id, val);
                                    }}
                                  >
                                    <Option value="pie">PIE Chart</Option>
                                    <Option value="bar">BAR Graph</Option>
                                  </Select>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Poll;
