import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Checkbox, Input, Button } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "./index.css";
import { logo } from "../../components/Images/Images";
import { useSelector, useDispatch } from "react-redux";
import {
  getCustomerDetails,
  createRegisterCustomer,
} from "../../redux/slice/customerManagementSlice";
import { toast } from "react-toastify";
import CommonLoader from "../../components/Widgets/CommonLoader";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { INPUT_TEXT_LIMITS } from "../../components/constsnts/common.constants";

const CustomerRegistration = (props) => {
  const location = useLocation();
  const Mobile = useMediaQuery({ maxWidth: 500 });
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state?.customerManagement?.customerDetails?.data?.data
  );
  const loading = useSelector((state) => state?.customerManagement?.loading);
  const [forceUpdate, setForceUpdate] = useState(false);
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        regex:
          "Password must contain at least 1 Alphabet, 1 number, 1 special character",
      },
    })
  );
  const [fields, setFields] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [termsAndConditionCheck, setTermsAndConditionCheck] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    const query = new URLSearchParams(location.search);
    const customer_id = parseInt(query.get("customer_id"));
    if (navigator.onLine)
      window.less.modifyVars({ "@primary-color": "#ef0855" }).then(() => {});

    dispatch(getCustomerDetails({ customer_id }));
    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (user) {
      initializeUserData();
    }
  }, [user]);

  const initializeUserData = () => {
    const query = new URLSearchParams(location.search);
    const customer_id = parseInt(query.get("customer_id"));
    if (user) {
      setFields({
        customer_id: customer_id,
        account_name: user?.details?.account_name,
        main_account_name: user?.details?.main_contact_person,
        email: user?.details?.main_contact_email,
        password: "",
        confirmPassword: "",
      });
    }
  };

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      if (fields.password !== fields.confirmPassword) {
        toast.error("Confirm Passord does not match");
      } else if (termsAndConditionCheck === false) {
        toast.error("Please indicate that you accept the Terms & Conditions");
      } else {
        setPageLoading(true);
        let newUser = {
          customer_id: fields?.customer_id,
          email: fields?.email,
          password: fields?.password,
          is_signup: true,
        };

        const addCustomerResponse = await dispatch(
          createRegisterCustomer(newUser)
        );
        if (addCustomerResponse.payload.code === 200) {
          toast.success(addCustomerResponse.payload.message);
          props.history.push("/login");
        } else {
          toast.error(addCustomerResponse.payload.message);
        }
      }
      setPageLoading(false);
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  if (pageLoading) return <CommonLoader />;
  else
    return (
      <div className="registration-bg-img-div">
        <div className="site-card-border-less-wrapper py-5 text-center">
          <img src={logo} alt="logo" />
          <Card
            bordered={true}
            className={"m-auto mt-4 rounded cutsomer-registration-div"}
          >
            <h5 className="text-center mt-3 fs-26"> Sign up </h5>
            <form autocomplete="off">
              <div className="container px-5">
                <div className="row">
                  <div className={Mobile ? "col-12" : "col-6"}>
                    <div className="form-group text-start">
                      <label className="fs-16">
                        Account Name
                        <RedAsterick />
                      </label>
                      <Input
                        name="account_name"
                        type="text"
                        className="form-control py-2"
                        placeholder="Enter Account Name"
                        autoComplete="account_name"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        value={fields?.account_name}
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "account_name")
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("account_name")
                        }
                      />
                      {validator.current.message(
                        "Account Name",
                        fields.account_name,
                        "required",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  <div className={Mobile ? "col-12 mt-1" : "col-6"}>
                    <div className="form-group text-start">
                      <label className="fs-16">
                        Main Contact Name
                        <RedAsterick />
                      </label>
                      <Input
                        name="main_contact_name"
                        type="text"
                        className="form-control py-2"
                        placeholder="Enter Main Contact Name"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        autoComplete="main_account_name"
                        value={fields.main_account_name}
                        onChange={(e) =>
                          handleFieldsChange(
                            e.target.value,
                            "main_account_name"
                          )
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("main_contact_name")
                        }
                      />
                      {validator.current.message(
                        "Main contact name",
                        fields.main_account_name,
                        "required",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>

                  <div className={Mobile ? "col-12 mt-1" : "col-6"}>
                    <div className="form-group text-start">
                      <label className="fs-16">
                        Email Address
                        <RedAsterick />
                      </label>
                      <Input
                        name="email"
                        type="email"
                        disabled
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        className="form-control py-2"
                        placeholder="Enter Email Address"
                        autoComplete="customer_email"
                        value={fields.email}
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "email")
                        }
                        onBlur={() => validator.current.showMessageFor("email")}
                      />
                      {validator.current.message(
                        "Email",
                        fields.email,
                        "required",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  {Mobile ? null : <div className="col-6 mt-4"></div>}

                  <div className={Mobile ? "col-12 mt-1" : "col-6 mt-2"}>
                    <div className="form-group text-start">
                      <label className="fs-16">Password</label>
                      <Input.Password
                        name="password"
                        type="password"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        className="form-control py-2 customer_regis_passInput"
                        placeholder="Enter password"
                        onBlur={() =>
                          validator.current.showMessageFor("password")
                        }
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "password")
                        }
                        autoComplete="new-password"
                      />
                      {validator.current.message(
                        "Password",
                        fields.password,
                        "required|min:5|regex:(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\"/@#$%^&+=._!-*(){};'<>?/~`\\[\\]])",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  <div className={Mobile ? "col-12 mt-1" : "col-6 mt-2"}>
                    <div className="form-group text-start">
                      <label className="fs-16">Confirm Password</label>
                      <Input.Password
                        name="confirmPassword"
                        type="password"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        className="form-control py-2 customer_regis_passInput"
                        placeholder="Enter password"
                        onBlur={() =>
                          validator.current.showMessageFor("confirmPassword")
                        }
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "confirmPassword")
                        }
                        autoComplete="new-password"
                      />
                      {validator.current.message(
                        "Confirm Password",
                        fields.confirmPassword,
                        "required|min:5|regex:(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\"/@#$%^`&+=._!-*(){};'<>?/~`\\[\\]])",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <Checkbox
                      className="mx-1"
                      onChange={(e) => {
                        if (e.target.checked) setTermsAndConditionCheck(true);
                        else setTermsAndConditionCheck(false);
                      }}
                    />
                    <span>
                      Please check the box to show that you have read and
                      understood our
                      <span className="fs-pink">
                        <a
                          target={"_blank"}
                          className="ms-1"
                          href="https://www.gravit8.co/terms-conditions/"
                        >
                          Terms & Conditions
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="col-12">
                    <Button
                      type="submit"
                      className="w-100 mt-2 register_submit_btn"
                      onClick={handleRegisterSubmit}
                      loading={loading}
                    >
                      Register
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <div className="mt-2 text-danger"></div>
            <p className="fs-14">
              Already Registered?
              <Link to="/">
                <span className="fs-pink ms-1">
                  <u>Sign In</u>
                </span>
              </Link>
            </p>
          </Card>
        </div>
      </div>
    );
};

export default CustomerRegistration;
