import React from "react";

export const Union = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="#000000"
			>
				<path id="Union_1" data-name="Union 1" d="M-1975.419,20h-11.462A3.125,3.125,0,0,1-1990,16.875V15.7a.781.781,0,0,1,.779-.781h2.73V3.125A3.125,3.125,0,0,1-1983.372,0h12.593a.781.781,0,0,1,.779.781.781.781,0,0,1-.779.781,1.563,1.563,0,0,0-1.56,1.562v9.844a.781.781,0,0,1-.779.781.781.781,0,0,1-.78-.781V3.125a3.116,3.116,0,0,1,.418-1.562h-9.892a1.563,1.563,0,0,0-1.56,1.562v11.8h7.213a.781.781,0,0,1,.78.781v1.172a1.563,1.563,0,0,0,1.52,1.562,1.564,1.564,0,0,0,1.52-1.562.781.781,0,0,1,.78-.781.781.781,0,0,1,.779.781A3.126,3.126,0,0,1-1975.3,20a.761.761,0,0,1-.078,0Zm-13.021-3.125a1.563,1.563,0,0,0,1.559,1.562h8.8a3.106,3.106,0,0,1-.419-1.562v-.391h-9.941Zm7.716-5.842-.006-.006-1.6-1.557a.751.751,0,0,1-.013-1.06.744.744,0,0,1,.536-.226.744.744,0,0,1,.524.213l1.59,1.552a.033.033,0,0,0,.023.008.042.042,0,0,0,.022-.007l2.455-3.429a.751.751,0,0,1,.609-.313.744.744,0,0,1,.436.14.75.75,0,0,1,.174,1.046l-2.475,3.456-.024.033a1.543,1.543,0,0,1-1.1.571c-.033,0-.068,0-.1,0A1.529,1.529,0,0,1-1980.725,11.033Z" transform="translate(1990)" opacity="0.7"/>
			</svg>
		</>
	);
};
