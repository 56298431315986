import React, { useState } from "react";
import { Input } from "antd";
import Text from "antd/lib/typography/Text";
import { searchSessionsFromData } from "../eventCalender.utils";
import { MenuUnfoldOutlined, SearchOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import Sider from "antd/lib/layout/Sider";

const EventCalenderSider = (props) => {
  const { allSessions, isCollapsedSider, setIsCollapsedSider } = props;
  const [filteredSessions, setFilteredSessions] = useState([]);

  const searchSessions = (event) => {
    setFilteredSessions([]);
    let searchTerm = event.target.value.toLowerCase().trim();
    let allSearchedData = [];
    if (searchTerm !== "" || !isEmpty(searchTerm))
      allSearchedData = searchSessionsFromData(allSessions, searchTerm);
    if (searchTerm === "" || isEmpty(searchTerm)) setFilteredSessions([]);
    setFilteredSessions(allSearchedData);
  };

  return (
    <Sider className="border-right bg-white" width={180}>
      <div className="p-3">
        <div className="d-flex flex-row-reverse" style={{ width: "100%" }}>
          <MenuUnfoldOutlined
            onClick={() => setIsCollapsedSider(!isCollapsedSider)}
          />
        </div>
        <Input
          size="medium"
          className="w-100 rounded fs-bold-14 mt-2"
          onChange={(e) => searchSessions(e)}
          allowClear
          placeholder="Search Session"
          style={{ width: "70%" }}
          prefix={<SearchOutlined />}
        />
        <div className="row mt-1 p-2">
          <span
            style={{ fontSize: "1rem" }}
            className="text-secondary p-0 m-0 px-1"
          >
            {filteredSessions.length} Results
          </span>
          {filteredSessions?.map((data, index) => {
            return (
              <div
                key={"filteredSessions_" + index.toString()}
                className="side_searchbar_div my-1 event_calender_searchbar"
              >
                <Text
                  strong
                  ellipsis
                  className="my-1"
                  style={{ fontSize: "0.5rem" }}
                >
                  {data.session_title}
                </Text>
                <Text ellipsis className="mb-1" style={{ fontSize: "0.5rem" }}>
                  {data.session_description}
                </Text>
                <div className="row mb-1">
                  <span
                    className="col-6 fs-pink border-end"
                    style={{ fontSize: "0.5rem" }}
                  >
                    <b>Go to Session</b>
                  </span>
                  <span
                    className="col-6 fs-pink"
                    style={{ fontSize: "0.5rem" }}
                  >
                    <b>Go to Booth</b>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Sider>
  );
};

export default EventCalenderSider;
