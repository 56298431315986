import React, { useEffect, useState } from "react";
import "./VideoConference.css";
import Poll from "./Poll";
import { Button } from "antd";
import { ApiCall } from "../../utils/api";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { getStreamId } from "../../utils/commonFunctions";
import GroupChat from "../GroupChat/GroupChat";
import { useSelector } from "react-redux";
import CommonLoader from "../../components/Widgets/CommonLoader";

const Streaming = ({ isFromPopup, socket, is_moderator, stream_url }) => {
  const jitsiContainerId = "jitsi-container-id";
  const location = useLocation();
  const [showNetworkingData, setShowNetworkingData] = useState(false);
  const [pollFlag, setPollFlag] = useState(false);
  const [clickCreatePoll, setClickCreatePoll] = useState(false);
  const [clickViewPoll, setClickViewPoll] = useState(false);
  const [isProducerFlag, setIsProducerFlag] = useState(false);
  const [streamId, setStreamId] = useState("");
  const [isGroupChatVisible, setIsGroupChatVisible] = useState(false);
  const [videoUrlData, setVideoUrlData] = useState({
    url: "",
    type: "",
  });
  const [isStreamingStarted, setIsStreamingStarted] = useState(false);
  const [isExternalSourceEnabled, setIsExternalSourceEnabled] = useState(false);
  const [loaderStreaming, setLoaderStreaming] = useState(false);
  const [showDefaultMessage, setShowDefaultMessage] = useState(
    "Streaming is not started"
  );

  const query = new URLSearchParams(location.search);
  const auditorium_session_id =
    query.get("auditorium_session_id") ?? isFromPopup?.auditorium_session_id;

  const auditorium_configuration_id =
    query.get("auditorium_configuration_id") ??
    isFromPopup?.auditorium_configuration_id;

  const showMessageBoard = useSelector(
    (state) => state?.videoConference?.showMessageBoard
  );

  useEffect(() => {
    let event_color_theme = "#ef0855";
    let default_button_background_color = "#ffffff";
    let default_button_background_second_color =
      default_button_background_color;
    let default_button_text_color = "#ef0855";
    let highlight_button_background_color = "#ef0855";
    let highlight_button_background_second_color =
      highlight_button_background_color;
    let highlight_button_text_color = "#ffffff";
    let default_button_gradient_angle = 0;
    let highlight_button_gradient_angle = 0;

    window.less
      .modifyVars({
        "@primary-color": event_color_theme,
        "@default_button_background_color": default_button_background_color,
        "@default_button_background_second_color":
          default_button_background_second_color,
        "@default_button_text_color": default_button_text_color,
        "@highlight_button_background_color": highlight_button_background_color,
        "@highlight_button_background_second_color":
          highlight_button_background_second_color,
        "@highlight_button_text_color": highlight_button_text_color,
        "@default_button_gradient_angle": default_button_gradient_angle + "deg",
        "@highlight_button_gradient_angle":
          highlight_button_gradient_angle + "deg",
      })
      .then(() => {});

    localStorage.setItem("event_color_theme", event_color_theme);
    localStorage.setItem(
      "btnColorData",
      JSON.stringify({
        default_button_background_color,
        default_button_background_second_color,
        default_button_gradient_angle: default_button_gradient_angle + "deg",
        default_button_text_color,
        highlight_button_background_color,
        highlight_button_background_second_color,
        highlight_button_gradient_angle:
          highlight_button_gradient_angle + "deg",
        highlight_button_text_color,
      })
    );

    getStreamingEmbedUrl();
  }, []);

  useEffect(() => {
    const streamingStarted = (obj) => {
      console.log("Streaming Started Emitted From Backend");
      console.log(
        obj?.auditorium_session_id,
        auditorium_session_id,
        obj?.auditorium_session_id === auditorium_session_id,
        "compare session id"
      );
      if (obj?.auditorium_session_id === auditorium_session_id) {
        getStreamingEmbedUrl();
      }
    };

    socket?.on("streamingStarted", streamingStarted);

    return () => {
      socket?.off("streamingStarted");
    };
  }, [socket]);

  const getStreamingEmbedUrl = async () => {
    setLoaderStreaming(true);
    const response = await ApiCall(
      "GET",
      "",
      "/admin/auditoriumsession/streamingdetails/get",
      { auditorium_session_id },
      true
    );

    if (response?.data?.code === 200) {
      let stream_url = response?.data?.data?.stream_url;
      let isExternalSourceEnabled =
        response?.data?.data?.is_from_external_source;

      setIsExternalSourceEnabled(response?.data?.data?.is_from_external_source);

      console.log("stream url", stream_url);

      if (isExternalSourceEnabled) {
        // External Source code
        initialzeExternalSourceVideoUrl(stream_url);
      } else {
        // Jitsi Streaming code
        if (stream_url) {
          let stream_id = getStreamId(stream_url);
          if (!stream_id) {
            // https://youtube.com/live/QGSk-GN0SH8 if its in this format
            let video_id = stream_url.split("/").pop();
            setStreamId(video_id);

            setVideoUrlData({
              url: `https://www.youtube-nocookie.com/embed/${video_id}?&rel=0&autoplay=1&showinfo=1&modestbranding=1`,
              type: "youtube",
            });
            setIsStreamingStarted(true);
          } else {
            setStreamId(stream_id);

            setVideoUrlData({
              url: `https://www.youtube-nocookie.com/embed/${stream_id}?&rel=0&autoplay=1&showinfo=1&modestbranding=1`,
              type: "youtube",
            });
            setIsStreamingStarted(true);
          }
        }
      }
    }
    setLoaderStreaming(false);
  };

  function extractVimeoVideoId(url) {
    const regex = /vimeo\.com\/(\d+)/;
    const match = url.match(regex);

    if (match) {
      return match[1];
    }

    return null;
  }

  const initialzeExternalSourceVideoUrl = (streamUrl) => {
    if (streamUrl.includes("youtube")) {
      let stream_id = getStreamId(streamUrl);
      if (!stream_id) {
        // https://youtube.com/live/QGSk-GN0SH8 if its in this format
        let video_id = streamUrl.split("/").pop();

        setVideoUrlData({
          url: `https://www.youtube-nocookie.com/embed/${video_id}?&rel=0&autoplay=1&showinfo=1&modestbranding=1`,
          type: "youtube",
        });
        setIsStreamingStarted(true);
      } else {
        setVideoUrlData({
          url: `https://www.youtube-nocookie.com/embed/${stream_id}?&rel=0&autoplay=1&showinfo=1&modestbranding=1`,
          type: "youtube",
        });
        setIsStreamingStarted(true);
      }
    } else if (streamUrl.includes("facebook")) {
      setIsStreamingStarted(true);

      setVideoUrlData({
        url: `https://www.facebook.com/plugins/video.php?href=${streamUrl}&mute=0&autoplay=true`,
        type: "facebook",
      });
    } else if (streamUrl.includes("twitch")) {
      setIsStreamingStarted(true);

      const channelName = streamUrl.split("/")[3];

      let newStreamUrl = `https://player.twitch.tv/?channel=${channelName}&parent=${window.location.hostname}&branding=false&controls=false`;

      setVideoUrlData({
        url: newStreamUrl,
        type: "twitch",
      });
    } else if (streamUrl.includes("vimeo")) {
      console.log(streamUrl.includes("vimeo"), "vimeo");
      setIsStreamingStarted(true);

      let videoId = extractVimeoVideoId(streamUrl);
      console.log("video id", videoId);

      setVideoUrlData({
        url: `https://player.vimeo.com/video/${videoId}?&controls=false&autoplay=true`,
        type: "vimeo",
      });
    } else {
      setShowDefaultMessage("Streaming link is not valid");
    }
  };

  if (loaderStreaming) return <CommonLoader />;
  else
    return (
      <>
        <div
          id={jitsiContainerId}
          style={{
            height: ` ${
              !isFromPopup?.flag
                ? `${
                    pollFlag || isGroupChatVisible
                      ? isGroupChatVisible
                        ? "48vh"
                        : "59vh"
                      : "100vh"
                  }`
                : `${
                    pollFlag || isGroupChatVisible
                      ? isGroupChatVisible
                        ? "47%"
                        : "56%"
                      : "100%"
                  }`
            }`,
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {!isStreamingStarted ? (
            <div
              className="streaminh-not-started-div flex_center"
              style={{
                height:
                  pollFlag || isGroupChatVisible
                    ? isGroupChatVisible
                      ? "88%"
                      : "88%"
                    : "94%",
                width: "100%",
              }}
            >
              <h1 className="fs-pink">{showDefaultMessage}</h1>
            </div>
          ) : (
            <iframe
              title="jitsi-meet-box"
              allow="camera; autoplay; microphone; display-capture; clipboard-write"
              src={videoUrlData?.url}
              frameborder="0"
              allowfullscreen="true"
              sandbox="allow-forms allow-scripts  allow-same-origin allow-top-navigation"
              style={{
                height:
                  pollFlag || isGroupChatVisible
                    ? isGroupChatVisible
                      ? "88%"
                      : "88%"
                    : "94%",
                width: "100%",
              }}
            />
          )}

          <div className="video-conference-btns-container-streaming">
            {showMessageBoard ? (
              <Button
                disabled={!isStreamingStarted ? true : false}
                className="video-conference-message-btn-streaming"
                onClick={() => {
                  setIsGroupChatVisible(!isGroupChatVisible);
                  setClickViewPoll(false);
                  setPollFlag(false);
                }}
              >
                <div className="flex_center">
                  Message
                  {isGroupChatVisible ? (
                    <UpOutlined className="ms-2" />
                  ) : (
                    <DownOutlined className="ms-2" />
                  )}
                </div>
              </Button>
            ) : null}

            {isProducerFlag ? (
              <Button
                className="video-conference-create-poll-btn"
                disabled={!isStreamingStarted ? true : false}
                onClick={() => {
                  let flag = clickCreatePoll;

                  if (flag) {
                    setClickCreatePoll(false);
                    setClickViewPoll(false);
                    setIsGroupChatVisible(false);
                    setPollFlag(false);
                  } else {
                    setClickViewPoll(false);
                    setIsGroupChatVisible(false);

                    setClickCreatePoll(true);
                  }
                }}
              >
                <div className="flex_center">
                  Create Poll
                  {clickCreatePoll ? (
                    <UpOutlined className="ms-2" />
                  ) : (
                    <DownOutlined className="ms-2" />
                  )}
                </div>
              </Button>
            ) : null}

            <Button
              className="video-conference-view-poll-btn-streaming"
              disabled={!isStreamingStarted ? true : false}
              onClick={() => {
                let flag = clickViewPoll;
                if (flag) {
                  setClickCreatePoll(false);
                  setClickViewPoll(false);
                  setPollFlag(false);
                  setIsGroupChatVisible(false);
                } else {
                  setClickViewPoll(true);
                  setClickCreatePoll(false);
                  setIsGroupChatVisible(false);
                }
              }}
            >
              <div className="flex_center">
                View Poll
                {clickViewPoll ? (
                  <UpOutlined className="ms-2" />
                ) : (
                  <DownOutlined className="ms-2" />
                )}
              </div>
            </Button>
          </div>
        </div>
        {isGroupChatVisible ? (
          <GroupChat
            socket={socket}
            is_moderator={is_moderator}
            auditorium_session_id={auditorium_session_id}
            auditorium_configuration_id={auditorium_configuration_id}
          />
        ) : null}

        <Poll
          streamingAudiId={auditorium_session_id}
          clickCreatePoll={clickCreatePoll}
          clickViewPoll={clickViewPoll}
          changeClickCreatePollFlag={() => setClickCreatePoll(false)} //triggerd when hide poll is called
          changeClickViewPollFlag={() => setClickViewPoll(false)} //triggerd when hide poll is called
          changeNetworkingFlag={(flag) => setShowNetworkingData(flag)}
          changePollFlag={(flag) => setPollFlag(flag)}
          changeIsProducerFlag={(flag) => setIsProducerFlag(flag)}
        />
      </>
    );
};

export default Streaming;
