import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "./brandCalendar.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getBrandCalendar } from "./../../redux/slice/brandCalendar";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import SearchBar from "../common/SearchBar";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { Breadcrumb, Tooltip } from "antd";
import _, { cloneDeep, isEmpty } from "lodash";
import { useStateIfMounted } from "use-state-if-mounted";
import { getBrandDetails } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  createTZString,
  diff_hours,
  isAdminLevelUser,
  isNonAdminLevelUser,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import { SearchOutlined } from "@ant-design/icons";
import { hoursWiseEventsArray } from "./brandCalenderUtils";
import { toast } from "react-toastify";
import ProductZoneChat from "../commonPopups/ProductZoneChat";
import ProductZoneDemo from "../commonPopups/ProductZoneDemo";
import ZoneSessionView from "../commonPopups/ZoneSessionView";
import {
  getCurrentTimeAndPercentage,
  isPastDate,
} from "../EventCalender/eventCalender.utils";
import { dialogs } from "../../components/constsnts/string.constants";
import AudiNetViewAdmins from "../commonPopups/AudiNetViewAdmins";
import AudiViewDelegates from "../commonPopups/AudiViewDelegates";
import NetViewDelegates from "../commonPopups/NetViewDelegates";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
import PastDateDelegate from "../commonPopups/PastDateDelegate";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { currentTimeLineDiv } from "../EventCalender/Components/CommonComponents";

const BrandCalendarUI = ({ match }) => {
  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  const history = useHistory();
  const Tablet = useMediaQuery({ maxWidth: 900 });
  const scollToRef = useRef();
  const itemsRef = useRef([]);

  const [showZoneSessionPopup, setShowZoneSessionPopup] =
    useStateIfMounted(false);
  const [zoneSessionViewPopup, setZoneSessionViewPopup] =
    useStateIfMounted(false);
  const [showZoneChatPopup, setShowZoneChatPopup] = useStateIfMounted(false);
  const [calendarData, setCalendarData] = useStateIfMounted([]);
  const [isCollapsedSider, setIsCollapsedSider] = useStateIfMounted(true);
  const [hoursWiseEvents] = useStateIfMounted(hoursWiseEventsArray);
  const [selectedDate, setSelectedDate] = useStateIfMounted(new Date());
  const [searchData, setSearchData] = useStateIfMounted([]);
  const [appointmentData, setAppointmentData] = useStateIfMounted(null);
  const [zoneChatData, setZoneChatData] = useStateIfMounted({});
  const [brandDetails, setBrandDetails] = useStateIfMounted(null);
  const [loader, setLoader] = useStateIfMounted(true);
  const [userType, setUserType] = useState("");
  const [sessionViewData, setSessionViewData] = useStateIfMounted({});
  const [showAudiNetAdminsPopup, setShowAudiNetAdminsPopup] =
    useStateIfMounted(false);
  const [showAudiDelegatesPopup, setShowAudiDelegatesPopup] =
    useStateIfMounted(false);
  const [showNetDelegatesPopup, setShowNetDelegatesPopup] =
    useStateIfMounted(false);
  const [errorModal, setErrorModal] = useStateIfMounted(false);
  const [audiNetSessionData, setAudiNetSessionData] = useStateIfMounted({});
  const [brandColor, setBrandColor] = useState(null);
  const [liveTime, setLiveTime] = useState(getCurrentTimeAndPercentage());

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    // ------- This Route is not coming from backend need's to be added from backend first
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes2 = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);

      async function stateLoad() {
        const res = await getBrandDetails({ brand_id: match.params.brand_id });

        if (res?.details?.total_no_of_zone_count === 0) {
          toast.error(
            "No zone is created against this brand, create a zone first to visit Brand Calendar"
          );
        }
        if (res?.details?.total_no_of_zone_count !== 0) {
          setBrandDetails(res?.details);
          await getBrandCalenderDetails();
        } else setLoader(false);
      }
      stateLoad();
    }
  }, [match?.params?.brand_id, CryptoJS.AES]);

  const scrollToDiv = () => {
    let currentTimeIndex = parseInt(liveTime?.currentHourIndex);
    itemsRef.current[currentTimeIndex - 1] &&
      itemsRef.current[currentTimeIndex - 1].scrollIntoView();
  };

  function setSessionAttributes(e) {
    let ele = e;
    const start_date_time = createTZString(ele?.start_date_time);
    const end_date_time = createTZString(ele?.end_date_time);
    const st_time_moment = moment(start_date_time.format("HH:mm"), TIME_FORMAT);
    const et_time_moment = moment(end_date_time.format("HH:mm"), TIME_FORMAT);

    let minutes = st_time_moment.minutes();
    ele["offsetHeight"] = minutes > 0 ? minutes + 5 : minutes;
    ele["startHours"] = st_time_moment.hours();
    ele["endHours"] = et_time_moment.hours();
    ele["startTime"] = st_time_moment.format(TIME_FORMAT);
    ele["endTime"] = et_time_moment.format(TIME_FORMAT);
    ele["duration"] = diff_hours(
      new Date(e.end_date_time),
      new Date(e.start_date_time)
    );
    return ele;
  }

  const getBrandCalenderDetails = async (currentDate = "") => {
    setLoader(true);
    let dateHere = currentDate ? currentDate : selectedDate;
    const response = await dispatch(
      getBrandCalendar({
        event_id: match?.params?.event_id,
        brand_id: match?.params?.brand_id,
        current_date: moment(dateHere.toDateString()).format("YYYY-MM-DD"),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );

    let brandCalendarData = response?.payload?.data?.data;
    let hoursArray = [];
    if (brandCalendarData) {
      let searchData = [];
      setBrandColor(brandCalendarData?.brand_color);

      brandCalendarData.product_zone_demo_details.forEach((x) => {
        x.product_zone_session_configurations &&
          x.product_zone_session_configurations.forEach(async (e) => {
            const attributedEle = await setSessionAttributes(e);
            searchData.push(attributedEle);
          });
        x["events"] = x.product_zone_session_configurations;
        let hours = _.map(x.events, "endHours");
        hoursArray = hoursArray.concat(hours);
      });

      brandCalendarData.product_zone_chat_details.forEach((x) => {
        x.product_area_chat_configurations &&
          x.product_area_chat_configurations.forEach(async (e) => {
            const attributedEle = await setSessionAttributes(e);
            searchData.push(attributedEle);
          });
        x["events"] = x.product_area_chat_configurations;
        let hours = _.map(x.events, "endHours");
        hoursArray = hoursArray.concat(hours);
      });

      brandCalendarData.auditorium_sessions &&
        brandCalendarData.auditorium_sessions.forEach(async (x) => {
          const attributedEle = await setSessionAttributes(x);
          let hours = _.map(attributedEle.events, "endHours");
          hoursArray = hoursArray.concat(hours);
          searchData.push(attributedEle);
        });

      brandCalendarData.networking_lounge_sessions &&
        brandCalendarData.networking_lounge_sessions.forEach(async (x) => {
          const attributedEle = await setSessionAttributes(x);
          let hours = _.map(attributedEle.events, "endHours");
          hoursArray = hoursArray.concat(hours);
          searchData.push(attributedEle);
        });

      let modifiedResponse = [
        {
          key: "productZoneSession",
          title: brandCalendarData.product_zone_demo_title,
          zones: brandCalendarData.product_zone_demo_details,
        },
        {
          key: "productAuditoriumSession",
          title: brandCalendarData.auditorium_session_title,
          zones: [],
          events: brandCalendarData.auditorium_sessions,
        },
        {
          key: "productNetworkingSession",
          title: brandCalendarData.networking_lounge_session_title,
          zones: [],
          events: brandCalendarData.networking_lounge_sessions,
        },
      ];

      if (
        userType !== "DELEGATE" &&
        userType !== "SPEAKER" &&
        userType !== "AGENT"
      )
        modifiedResponse.push({
          key: "productZoneChat",
          title: brandCalendarData.product_zone_chat_title,
          zones: brandCalendarData.product_zone_chat_details,
        });

      setCalendarData(modifiedResponse);
      setSearchData(searchData);
      setLiveTime(getCurrentTimeAndPercentage());
      setTimeout(() => {
        setLoader(false);
        scrollToDiv();
      }, 1000);
    } else setLoader(false);
  };

  const previousDate = async () => {
    let currentDate = cloneDeep(selectedDate);
    currentDate.setDate(currentDate.getDate() - 1);
    setSelectedDate(currentDate);
    getBrandCalenderDetails(currentDate);
  };

  const nextDate = async () => {
    let currentDate = cloneDeep(selectedDate);
    currentDate.setDate(currentDate.getDate() + 1);
    setSelectedDate(currentDate);
    getBrandCalenderDetails(currentDate);
  };

  const openZonePopup = (sessionData, zone_id, type) => {
    let currentDateForCalender;
    if (selectedDate === new Date())
      currentDateForCalender = moment(selectedDate.toDateString()).format(
        "YYYY-MM-DD"
      );
    else currentDateForCalender = moment(selectedDate).format("YYYY-MM-DD");

    let appointmentData = {
      event_id: match?.params?.event_id,
      brand_id: match?.params?.brand_id,
      zone_id,
      configuration_id: sessionData.configuration_id || null,
      currentDateForCalender,
      brandDetails,
      sessionData,
    };

    if (!sessionData.configuration_id) {
      let startTime = new Date(selectedDate).setHours(sessionData.startHours);
      let endTime = new Date(selectedDate).setHours(sessionData.startHours + 1);
      appointmentData["startTime"] = moment(new Date(startTime).setMinutes(0));
      appointmentData["endTime"] = moment(new Date(endTime).setMinutes(0));
    }

    if (type !== "productZoneChat") {
      setAppointmentData(appointmentData);
      setSessionViewData(appointmentData?.sessionData);
      if (
        userType === "DELEGATE" ||
        userType === "SPEAKER" ||
        userType === "AGENT"
      ) {
        if (
          appointmentData?.sessionData &&
          appointmentData?.sessionData.session_title
        )
          setZoneSessionViewPopup(true);
      } else setShowZoneSessionPopup(true);
    } else {
      if (
        userType !== "DELEGATE" &&
        userType !== "SPEAKER" &&
        userType !== "AGENT"
      ) {
        let zoneChatData = {
          chat_configuration_id: sessionData.chat_configuration_id,
          brandDetails,
          currentDateForCalender,
          event_id: match?.params?.event_id,
          brand_id: match?.params?.brand_id,
          zone_id,
          startTime: sessionData?.startTime,
          endTime: sessionData?.endTime,
        };
        setZoneChatData(zoneChatData);
        setShowZoneChatPopup(true);
      }
    }
  };

  const onSave = async (data) => {
    await getBrandCalenderDetails();
    setShowZoneSessionPopup(false);
    setZoneSessionViewPopup(false);
    setShowZoneChatPopup(false);
    setShowAudiNetAdminsPopup(false);
    setShowAudiDelegatesPopup(false);
    setShowNetDelegatesPopup(false);
    setErrorModal(false);
  };

  const onCancel = () => {
    setShowZoneSessionPopup(false);
    setZoneSessionViewPopup(false);
    setShowZoneChatPopup(false);
    setShowAudiNetAdminsPopup(false);
    setShowAudiDelegatesPopup(false);
    setShowNetDelegatesPopup(false);
    setErrorModal(false);
  };

  function openAudiNetSessionPopup(audiNetData) {
    let isOldDate = isPastDate(audiNetData?.start_date);
    setAudiNetSessionData(audiNetData);
    if (isAdminLevelUser(userType)) {
      if (isOldDate === "yes") {
        setErrorModal(true);
      } else {
        setShowAudiNetAdminsPopup(true);
      }
    } else if (isNonAdminLevelUser(userType)) {
      if (isOldDate === "yes") {
        setErrorModal(true);
      } else if (audiNetData?.auditorium_configuration_id)
        setShowAudiDelegatesPopup(true);
      else if (audiNetData?.networking_configuration_id)
        setShowNetDelegatesPopup(true);
    }
  }

  if (loader) return <CommonLoader />;
  else if (!brandDetails || isEmpty(brandDetails))
    return (
      <div className="w-100 text-center font-weight-bold text-danger">
        Default Zone not Assigned
      </div>
    );
  return (
    <div>
      <div>
        <h4 className="fs-24">Brand Calendar</h4>
        <Breadcrumb className="my-2" separator=">">
          <Breadcrumb.Item
            onClick={() => {
              history.push("/brand");
            }}
          >
            Brands
          </Breadcrumb.Item>
          <Breadcrumb.Item>Brand Calendar</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="brand-calendar-wrapper bg-white">
        {!isCollapsedSider ? (
          <div className="search-container">
            <SearchBar
              className="rounded"
              details={searchData}
              placeholder={"Search session, speaker"}
              isMenu={true}
              onClickMenu={() => setIsCollapsedSider(!isCollapsedSider)}
            />
          </div>
        ) : null}
        <div
          style={{ width: isCollapsedSider ? "100%" : "80%" }}
          className="calendar-container"
        >
          <div className="today-container">
            {isCollapsedSider ? (
              <Tooltip title={"Search Session"}>
                <SearchOutlined
                  className="px-3"
                  onClick={() => setIsCollapsedSider(!isCollapsedSider)}
                />
              </Tooltip>
            ) : null}
            {moment(selectedDate, "YYYY-MM-DD").isSame(
              moment(),
              "day"
            ) ? null : (
              <Button
                className="fs-pink custom_border"
                size="medium"
                onClick={async () => {
                  setSelectedDate(new Date());
                  await getBrandCalenderDetails();
                }}
              >
                TODAY
              </Button>
            )}
            <IconButton aria-label="prev" onClick={() => previousDate()}>
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton aria-label="next" onClick={() => nextDate()}>
              <NavigateNextIcon />
            </IconButton>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label=""
                inputFormat="d MMM, yyyy"
                value={selectedDate}
                onChange={async (val) => {
                  setSelectedDate(val);
                  await getBrandCalenderDetails();
                }}
                className={"custom-date-picker"}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          {calendarData &&
          calendarData[0] &&
          calendarData[3] &&
          calendarData[0].zones &&
          calendarData[3].zones &&
          calendarData[0].zones.length === 0 &&
          calendarData[3].zones.length === 0 ? (
            <div className="w-100 py-2 text-center font-weight-bold text-danger">
              No zones are created/active for this brand
            </div>
          ) : null}

          <div className="calendar-header">
            <div className="calendar-header-item time-header" />
            {calendarData?.map((e) => {
              return (
                <div
                  key={"title_" + e.title}
                  className="calendar-header-item"
                  style={{ width: 100 / calendarData.length + "%" }}
                >
                  <div
                    className={
                      e.zones.length > 0 ? "zone-title" : "header-title"
                    }
                  >
                    {e.title}
                  </div>
                  {e.zones.length > 0 ? (
                    <div className="zone-container">
                      {e.zones.map((zone) => {
                        return (
                          <div
                            key={"zone_title_" + zone.zone_title}
                            style={{
                              width: 100 / calendarData.length + "%",
                              fontSize: Tablet ? "6px" : "9px",
                            }}
                          >
                            {zone?.booth_location
                              ? zone?.booth_location
                              : zone?.zone_title
                              ? zone?.zone_title_full
                              : ""}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className="calendar-data pt-2">
            {hoursWiseEvents?.map((e, parentI) => {
              let liveTimeCondition =
                liveTime &&
                liveTime.timeSlot &&
                liveTime.currentHourIndex === e.startHours;

              return (
                <div
                  className={
                    e.defaultTime ? "calendar-row empty-row" : "calendar-row "
                  }
                  key={"timewise_" + parentI.toString()}
                  ref={(el) => (itemsRef.current[parentI] = el)}
                >
                  <div className="time-element">{e.startTime}</div>
                  {calendarData?.map((data, midI) => {
                    return (
                      <div
                        key={"calendar-section-" + midI.toString()}
                        className="calendar-section"
                        style={{ width: 100 / calendarData.length + "%" }}
                      >
                        {liveTimeCondition &&
                        liveTime &&
                        liveTime.minuteDifference
                          ? currentTimeLineDiv(
                              liveTime.minuteDifference,
                              scollToRef
                            )
                          : null}
                        {data.zones.length > 0 ? (
                          data.zones.map((zone, childI) => {
                            return (
                              <div
                                key={"zone-item-" + childI.toString()}
                                className="zone-item"
                                onClick={() => {
                                  const currentDateForCalender = moment(
                                    selectedDate.toDateString()
                                  ).format("YYYY-MM-DD");
                                  const isOldDate = isPastDate(
                                    currentDateForCalender
                                  );
                                  if (isOldDate === "yes")
                                    toast.error(
                                      dialogs.brandZoneCalender.validation
                                        .currentFutureDate
                                    );
                                  else openZonePopup(e, zone.zone_id, data.key);
                                }}
                                style={{
                                  width: 100 / data.zones.length + "%",
                                }}
                              >
                                {zone.events
                                  ? zone.events.map((eventData) => {
                                      return eventData.startHours ==
                                        e.startHours ? (
                                        <div
                                          key={
                                            "event-card-left-" +
                                            eventData.session_title
                                          }
                                          className="event-card-left"
                                          style={{
                                            height:
                                              75 * eventData.duration + "px",
                                            marginTop:
                                              eventData.offsetHeight + "px",
                                            backgroundColor: brandColor,
                                          }}
                                          onClick={(event) => {
                                            const currentDateForCalender =
                                              moment(
                                                selectedDate.toDateString()
                                              ).format("YYYY-MM-DD");
                                            const isOldDate = isPastDate(
                                              currentDateForCalender
                                            );
                                            if (isOldDate === "yes")
                                              toast.error(
                                                dialogs.brandZoneCalender
                                                  .validation.currentFutureDate
                                              );
                                            else
                                              openZonePopup(
                                                eventData,
                                                zone.zone_id,
                                                data.key
                                              );
                                            event.preventDefault();
                                            event.stopPropagation();
                                          }}
                                        >
                                          <div>
                                            <div>{eventData.session_title}</div>
                                            <div>
                                              {eventData.session_description}
                                            </div>
                                            <div>
                                              {eventData.startTime
                                                ? eventData.startTime +
                                                  " - " +
                                                  eventData.endTime
                                                : null}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="border p-1">
                                              <BoothRed />
                                            </div>
                                          </div>
                                        </div>
                                      ) : null;
                                    })
                                  : null}
                              </div>
                            );
                          })
                        ) : (
                          <div className="session-item">
                            {data.events && data.events.length > 0
                              ? data.events.map((eventData) => {
                                  return eventData.startHours ==
                                    e.startHours ? (
                                    <div
                                      key={
                                        "event-card-2-" +
                                        eventData.session_title
                                      }
                                      className="event-card"
                                      style={{
                                        height: 75 * eventData.duration + "px",
                                        marginTop:
                                          eventData.offsetHeight + "px",
                                      }}
                                      onClick={() =>
                                        openAudiNetSessionPopup(eventData)
                                      }
                                    >
                                      <div>{eventData.session_title}</div>
                                      <div>{eventData.session_description}</div>
                                      <div>
                                        {eventData.startTime
                                          ? eventData.startTime +
                                            " - " +
                                            eventData.endTime
                                          : null}
                                      </div>
                                    </div>
                                  ) : null;
                                })
                              : null}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {zoneSessionViewPopup ? (
        <ZoneSessionView
          data={sessionViewData}
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : null}

      {showZoneSessionPopup ? (
        <ProductZoneDemo
          data={appointmentData}
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : null}

      {showZoneChatPopup ? (
        <ProductZoneChat
          data={zoneChatData}
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : null}

      {showAudiNetAdminsPopup ? (
        <AudiNetViewAdmins
          data={audiNetSessionData}
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : null}

      {showAudiDelegatesPopup ? (
        <AudiViewDelegates
          data={audiNetSessionData}
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : null}

      {showNetDelegatesPopup ? (
        <NetViewDelegates
          data={audiNetSessionData}
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : null}

      {errorModal ? (
        <PastDateDelegate
          data={audiNetSessionData}
          onSave={onSave}
          onCancel={onCancel}
          isFromCalender="brand"
        />
      ) : null}
    </div>
  );
};

export default BrandCalendarUI;
