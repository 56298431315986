import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
import {ApiCall} from '../../utils/api'

const initialState = {
  userList:[],
  loading:false
}


export const getUsers = createAsyncThunk(
    
    'getUsers/getUsers',
    
    async (params) => {
    
    const response=await ApiCall("GET","","/admin/user/get",params)
    
    return response.data.data
  })

export const getUsersSlice = createSlice({
  name: 'getUsers',
  initialState,
  reducers: {},
  extraReducers:{
    [getUsers.pending]: (state) => {
      state.loading = true
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userList = payload
    },
    [getUsers.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const usersReducer = getUsersSlice.reducer

// export const { fetchEventListing} = eventManagementSlice.actions

// export default eventManagementSlice.reducer