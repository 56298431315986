import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: ${(props) =>
    props.isLogin === "/login" ? "100vh" : "calc(100vh - 125px)"};
  // height:100vh;
  display: flex;
  position: relative;
`;

export const Container = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const MenuContainer = styled.div`
  display: flex;
  width: 200px;
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 10px;

  button {
    background: rgba(255, 255, 255, 0.6);
  }
`;
