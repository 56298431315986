import React from "react";

export const EditRed = props => {
  return (
    <>
      <svg
        id="_162-edit"
        fill={props.fill}
        data-name="162-edit"
        xmlns="http://www.w3.org/2000/svg"
        width="16.893"
        height="16.893"
        viewBox="0 0 16.893 16.893"
        className="svg_editred"
      >
        <g id="Group_1153" data-name="Group 1153">
          <path
            id="Path_49236"
            data-name="Path 49236"
            d="M15.9.99a3.38,3.38,0,0,0-4.78,0L1.677,10.436a.66.66,0,0,0-.169.29L.024,16.056a.66.66,0,0,0,.816.812l5.33-1.514a.66.66,0,0,0,.287-1.1L3.1,10.882l7.83-7.83,2.912,2.912L5.664,14.2a.66.66,0,0,0,.932.934L15.9,5.77a3.38,3.38,0,0,0,0-4.78ZM4.731,14.39l-3.12.886.874-3.139ZM14.97,4.836l-.2.2L11.86,2.119l.2-.2A2.06,2.06,0,0,1,14.97,4.836Z"
            transform="translate(0)"
          />
        </g>
      </svg>
    </>
  );
};
