import React from "react";

export const BoothRed = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        // fill="#ef0855"
        className="icon-svg"
      >
        <g id="Booth" transform="translate(56.225 8.764)" opacity="0.7">
          <path
            id="Union_16"
            data-name="Union 16"
            d="M16.8,19.219a.78.78,0,0,1,.779-.781.822.822,0,0,0,.82-.822.78.78,0,1,1,1.56,0A2.384,2.384,0,0,1,17.582,20,.78.78,0,0,1,16.8,19.219ZM11.852,20H6.163a.781.781,0,0,1,0-1.562H13.8A.781.781,0,0,1,13.8,20ZM0,17.616a.78.78,0,1,1,1.56,0,.821.821,0,0,0,.82.822.781.781,0,0,1,0,1.562A2.384,2.384,0,0,1,0,17.616ZM9.958,15a.784.784,0,0,1-.006-1.107l2.921-2.964,0,0a.784.784,0,0,0,0-1.107L9.956,6.894a.782.782,0,1,1,1.1-1.107l2.921,2.924a2.354,2.354,0,0,1,0,3.319l-2.92,2.962A.78.78,0,0,1,9.958,15Zm8.483-.977V6.368a.779.779,0,1,1,1.559,0v7.654a.779.779,0,1,1-1.559,0ZM0,14.022V6.368a.78.78,0,1,1,1.56,0v7.654a.78.78,0,1,1-1.56,0Zm5.664-2.867a.783.783,0,0,1,0-1.566h5.7a.783.783,0,0,1,0,1.566ZM18.4,2.384a.822.822,0,0,0-.82-.822.781.781,0,0,1,0-1.562,2.385,2.385,0,0,1,2.38,2.384.78.78,0,1,1-1.56,0ZM0,2.384A2.384,2.384,0,0,1,2.381,0a.781.781,0,0,1,0,1.562.822.822,0,0,0-.821.822A.779.779,0,1,1,0,2.384Zm11.85-.822H6.163A.781.781,0,0,1,6.163,0H13.8a.781.781,0,0,1,0,1.562Z"
            transform="translate(-56.225 -8.764)"
          />
        </g>
      </svg>
    </>
  );
};
