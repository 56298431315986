import React from "react";

export const AddGroup = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill}
    >
      <path
        id="add-group"
        d="M11.583,13.612a7.417,7.417,0,0,0-1.558-.832,3.977,3.977,0,0,0,1.293-2.623,5.879,5.879,0,0,1,7.456-.61.781.781,0,0,0,.894-1.281,7.425,7.425,0,0,0-1.558-.832,3.984,3.984,0,1,0-5.364-.009q-.164.063-.325.134a7.418,7.418,0,0,0-1.376.791,3.983,3.983,0,1,0-6.391,4.416A7.513,7.513,0,0,0,.117,17.439,2.344,2.344,0,0,0,2.348,20.5h6.8a.781.781,0,0,0,0-1.563h-6.8A.782.782,0,0,1,1.6,17.917,6.027,6.027,0,0,1,7.331,13.84a5.843,5.843,0,0,1,3.359,1.053.781.781,0,1,0,.894-1.281Zm3.85-11.548a2.422,2.422,0,1,1-2.422,2.422,2.424,2.424,0,0,1,2.422-2.422ZM7.348,7.41A2.422,2.422,0,1,1,4.926,9.832,2.425,2.425,0,0,1,7.348,7.41ZM20,16.79a.781.781,0,0,1-.781.781H17.073v2.148a.781.781,0,1,1-1.562,0V17.571H13.363a.781.781,0,0,1,0-1.563h2.148V13.86a.781.781,0,1,1,1.562,0v2.148h2.148A.781.781,0,0,1,20,16.79Zm0,0"
        transform="translate(-0.003 -0.501)"
      />
    </svg>
  );
};
