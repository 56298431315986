import React from "react";

export const NetworkingLounge = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <g id="_149-diagram" data-name="149-diagram" opacity="0.7">
          <g id="Group_1131" data-name="Group 1131">
            <g id="Group_1130" data-name="Group 1130">
              <path
                id="Path_638"
                data-name="Path 638"
                d="M51.781,13.314a.781.781,0,0,0,.781-.781v-.775A1.564,1.564,0,0,1,54.125,10.2h4.1v2.344a.781.781,0,1,0,1.562,0V10.2h4.1a1.564,1.564,0,0,1,1.562,1.563v.775a.781.781,0,0,0,1.563,0v-.775a3.129,3.129,0,0,0-3.125-3.125h-4.1V7.078h8.25A.781.781,0,0,0,68.82,6.3V.781A.781.781,0,0,0,68.039,0H49.977A.781.781,0,0,0,49.2.781V6.3a.781.781,0,0,0,.781.781h8.25V8.633h-4.1A3.129,3.129,0,0,0,51,11.758v.775A.781.781,0,0,0,51.781,13.314Zm-1.023-7.8V1.563h16.5V5.516Z"
                transform="translate(-49.008)"
              />
              <path
                id="Path_639"
                data-name="Path 639"
                d="M373.8,382h-4.023a.781.781,0,0,0-.781.781V386.3a.781.781,0,0,0,.781.781H373.8a.781.781,0,0,0,.781-.781v-3.516A.781.781,0,0,0,373.8,382Zm-.781,3.516h-2.461v-1.953h2.461Z"
                transform="translate(-354.586 -367.078)"
              />
              <path
                id="Path_640"
                data-name="Path 640"
                d="M4.8,382H.781a.781.781,0,0,0-.781.781V386.3a.781.781,0,0,0,.781.781H4.8a.781.781,0,0,0,.781-.781v-3.516A.781.781,0,0,0,4.8,382Zm-.781,3.516H1.563v-1.953H4.023Z"
                transform="translate(0 -367.078)"
              />
              <path
                id="Path_641"
                data-name="Path 641"
                d="M188.844,382h-4.062a.781.781,0,0,0-.781.781V386.3a.781.781,0,0,0,.781.781h4.063a.781.781,0,0,0,.781-.781v-3.516A.781.781,0,0,0,188.844,382Zm-.781,3.516h-2.5v-1.953h2.5Z"
                transform="translate(-176.813 -367.078)"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
