import React from "react";

export const Download = () => {
  return (
    <>
      <svg
        id="download"
        xmlns="http://www.w3.org/2000/svg"
        width="18.335"
        height="18.335"
        viewBox="0 0 18.335 18.335"
      >
        <g id="Group_1323" data-name="Group 1323">
          <g id="Group_1322" data-name="Group 1322">
            <path
              id="Path_49268"
              data-name="Path 49268"
              d="M236.716,10.206a.716.716,0,0,0,.716-.716V.716a.716.716,0,1,0-1.432,0V9.49A.716.716,0,0,0,236.716,10.206Z"
              transform="translate(-227.549)"
            />
            <path
              id="Path_49269"
              data-name="Path 49269"
              d="M102.1,228.167l0,0a2.151,2.151,0,0,0,3.038,0l3.965-3.949a.716.716,0,1,0-1.011-1.015l-3.966,3.95a.716.716,0,0,1-1.011,0l-3.891-3.947a.716.716,0,1,0-1.02,1.006Z"
              transform="translate(-94.49 -215.014)"
            />
            <path
              id="Path_49270"
              data-name="Path 49270"
              d="M17.619,412a.716.716,0,0,0-.716.716,1.434,1.434,0,0,1-1.432,1.432H2.865a1.434,1.434,0,0,1-1.432-1.432.716.716,0,1,0-1.432,0,2.868,2.868,0,0,0,2.865,2.865H15.47a2.868,2.868,0,0,0,2.865-2.865A.716.716,0,0,0,17.619,412Z"
              transform="translate(0 -397.246)"
            />
          </g>
        </g>
      </svg>
    </>
  );
};
