import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";

const initialState = {
  eventList: [],
  loading: false,
};

export const getEventList = createAsyncThunk(
  "eventManagement/getEventList",
  async (params) => {
    const response = await ApiCall("GET", "", "/admin/events/get", {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const checkIfError = JSON.parse(JSON.stringify(response));
    if (checkIfError?.name === "Error") {
      toast.error(checkIfError?.message);
      return [];
    } else return response?.data?.data;
  }
);

export const changeStatus = createAsyncThunk(
  "eventManagement/changeStatus",
  async (body) => {
    const response = await ApiCall(
      "PUT",
      body,
      "/admin/events/status/update"
    ).then((res) => {
      return res;
    });
    return response;
  }
);

export const eventManagementSlice = createSlice({
  name: "eventManagement",
  initialState,
  reducers: {},
  extraReducers: {
    [getEventList.pending]: (state) => {
      state.loading = true;
    },
    [getEventList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.eventList = payload;
    },
    [getEventList.rejected]: (state) => {
      state.loading = false;
    },
    [changeStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [changeStatus.fulfilled]: (state, { payload, meta }) => {
      state.eventList = payload;
      state.loading = false;
    },
    [changeStatus.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const eventReducer = eventManagementSlice.reducer;
