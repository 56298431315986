import React, { useState, useRef, useEffect } from "react";
import {
  VideoCameraOutlined,
  SendOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Input, Tooltip } from "antd";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { User } from "../../components/svgJS/User";
import "./messagesui.css";
import { Picker } from "emoji-mart";
import { VideoAttach } from "../../components/svgJS/VideoAttach";
import { ImageAttach } from "../../components/svgJS/ImageAttach";
import { AudioAttach } from "../../components/svgJS/AudioAttach";
import { FileAttach } from "../../components/svgJS/FileAttach";
import { EditChat } from "../../components/svgJS/EditChat";
import { BinMessages } from "../../components/svgJS/BinMessages";
import { cloneDeep } from "lodash";
import {
  createTZString,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { ThreadChat } from "../../components/svgJS/ThreadChat";
import { Api, Close, Warning } from "@mui/icons-material";
import { MessageDelivered } from "../../components/svgJS/MessageDelivered";
import ProfileModal from "../common/Modal/ProfileModal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { oneToOneVideoAPI } from "../../utils/commonApis";
import { MessageSent } from "../../components/svgJS/MessageSent";
import CommonLoader from "../../components/Widgets/CommonLoader";
let CryptoJS = require("crypto-js");

const MessagesChat = (props) => {
  let {
    userChatHeaderData,
    messagesData,
    socket,
    userId,
    setShowThread,
    closeThreadPopup,
    initiliazeNewThreadValue,
    getMessagesUserDetails,
  } = props;
  console.log("socket", socket);

  const [allMessages, setAllMessages] = useState(messagesData);
  const [messageText, setMessageText] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const exclude = ["search", "recent"];
  const [videoFileUpload, setVideoFileUpload] = useState(null);
  const [selectedEditMessageData, setSelecteEditMessageData] = useState(null);
  const [userHeaderButtonsData, setUserHeaderButtonsData] = useState(null);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [loginUserType, setLoginUserType] = useState(null);
  const [userOnline, setUserOnline] = useState(false);
  const [messChatLoader, setMessChatLoader] = useState(false);

  const sendMessageRef = useRef(null);
  const messagesEndRef = useRef(null);

  const videoAttachRef = useRef(null);
  const imageAttachRef = useRef(null);
  const fileAttachRef = useRef(null);
  const audioAttachRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getHeaderButtonDetails();
    // isUserOnline();

    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, []);

  useEffect(() => {
    const sendChatMessageListener = (obj) => {
      console.log("sendChatMessageListener", obj);
      let latestMessage = obj?.data;
      console.log(
        userChatHeaderData?.link_id,
        latestMessage?.link_id,
        "compare link ids"
      );
      if (userChatHeaderData?.link_id === latestMessage?.link_id) {
        let new_date_time = createTZString(obj?.created_at);

        setAllMessages((previousData) => {
          let newMessages = cloneDeep(previousData);
          newMessages.push({
            ...latestMessage,
            time: new_date_time.format("HH:mm A"),
          });
          return newMessages;
        });

        if (latestMessage?.sender_id !== userId) {
          socket?.emit(
            "updateReadStatus",
            JSON.stringify({
              conversation_id: latestMessage?.conversation_id,
              link_id: latestMessage?.link_id,
              is_read: true,
            })
          );
        }
      }

      scrollToBottom();
    };

    const sendMediaMessageListener = (obj) => {
      console.log("sendMediaMessageListener", obj);
      let latestMessage = obj;
      console.log(
        userChatHeaderData?.link_id,
        latestMessage?.link_id,
        "compare link ids"
      );
      if (userChatHeaderData?.link_id === latestMessage?.link_id) {
        let new_date_time = createTZString(obj?.created_at);

        setAllMessages((previousData) => {
          let newMessages = cloneDeep(previousData);
          newMessages.push({
            ...latestMessage,
            time: new_date_time.format("HH:mm A"),
          });
          return newMessages;
        });
      }

      setTimeout(() => {
        scrollToBottom();
      }, 500);
    };

    const deleteMainChatMessageListener = (obj) => {
      console.log("deleteMainChatMessageListener", obj);
      if (obj?.link_id === userChatHeaderData?.link_id) {
        setAllMessages((prevData) => {
          let newArr = prevData?.map((elem) => {
            if (elem?.conversation_id === obj?.conversation_id) {
              return {
                ...elem,
                is_deleted: true,
              };
            }
            return elem;
          });

          return newArr;
        });
      }
    };

    const deleteMainChatMediaListener = (obj) => {
      console.log("deleteMainChatMediaListener", obj);
      if (obj?.link_id === userChatHeaderData?.link_id) {
        setAllMessages((prevData) => {
          let newArr = prevData?.map((elem) => {
            if (elem?.conversation_id === obj?.conversation_id) {
              return {
                ...elem,
                is_deleted: true,
              };
            }
            return elem;
          });

          return newArr;
        });
      }
    };

    const updateChatListener = (obj) => {
      console.log("updateChatListener", obj);

      if (obj?.link_id === userChatHeaderData?.link_id) {
        setAllMessages((prevData) => {
          let newArr = prevData?.map((elem) => {
            if (elem?.conversation_id === obj?.conversation_id) {
              return {
                ...elem,
                message_text: obj?.message_text,
              };
            }
            return elem;
          });

          return newArr;
        });
      }
    };

    const updateReadStatusListener = (obj) => {
      console.log("updateReadStatusListener", obj);

      if (obj?.link_id === userChatHeaderData?.link_id) {
        setAllMessages((prevData) => {
          let newArr = prevData?.map((elem) => {
            if (elem?.conversation_id === obj?.conversation_id) {
              return {
                ...elem,
                is_read: true,
              };
            }
            return elem;
          });

          return newArr;
        });
      }
    };

    socket?.on("sendChatMessageListener", sendChatMessageListener);
    socket?.on("sendMediaMessageListener", sendMediaMessageListener);

    socket?.on("deleteMainChatMessageListener", deleteMainChatMessageListener);
    socket?.on("deleteMainChatMediaListener", deleteMainChatMediaListener);

    socket?.on("updateChatListener", updateChatListener);

    socket?.on("updateReadStatusListener", updateReadStatusListener);

    return () => {
      socket?.off("sendChatMessageListener", sendChatMessageListener);
      socket?.off("sendMediaMessageListener", sendMediaMessageListener);

      socket?.off(
        "deleteMainChatMessageListener",
        deleteMainChatMessageListener
      );
      socket?.off("deleteMainChatMediaListener", deleteMainChatMediaListener);

      socket?.off("updateChatListener", updateChatListener);

      socket?.off("updateReadStatusListener", updateReadStatusListener);
    };
  }, [socket]);

  useEffect(async () => {
    if (localStorage.getItem("user_type")) {
      const ciphertext = localStorage.getItem("user_type");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setLoginUserType(user_type);
    }
  }, [CryptoJS.AES]);

  const handleAddMessage = () => {
    if (messageText) {
      if (selectedEditMessageData?.conversation_id) {
        //If existing message is being edited
        editMessageHandler();
      } else {
        let objToSend = {
          link_id: userChatHeaderData?.link_id,
          sender_id: userId,
          receiver_id: userChatHeaderData?.receiver_id,
          // receiver_id: 9,
          message_text: messageText,
          message_media_type: "Text",
        };
        console.log("objToSend", objToSend);

        let messageSocket = socket?.emit(
          "sendChatMessage",
          JSON.stringify(objToSend)
        );
      }
      setMessageText("");
    }

    scrollToBottom();
  };

  const sendMediaAPI = async (fileType, file) => {
    let dataToSend = {
      link_id: userChatHeaderData?.link_id,
      sender_id: userId,
      receiver_id: userChatHeaderData?.receiver_id,
      message_media_type: fileType,
    };

    console.log("dataToSend", dataToSend);
    let formData = new FormData();
    formData.append("data", JSON.stringify(dataToSend));
    formData.append("files", file);

    const response = await ApiCall(
      "POST",
      formData,
      "/admin/chatconversation/media/create",
      null
    );

    console.log("sendMediaAPI response", sendMediaAPI);

    if (response?.data?.code === 200) {
      // let latestMessage = response?.data?.data;
      // let new_date_time = createTZString(response?.data?.data?.created_at);
      // setAllMessages((previousData) => {
      //   let newMessages = cloneDeep(previousData);
      //   newMessages.push({
      //     ...latestMessage,
      //     time: new_date_time.format("HH:mm A"),
      //   });
      //   return newMessages;
      // });
    } else {
      toast.error(response?.data?.message);
    }
    scrollToBottom();
  };

  const handleThread = (item, direction) => {
    initiliazeNewThreadValue(item, direction);
  };

  const deleteMessageHandler = async (body) => {
    console.log("body", body);
    if (body?.message_media_type === "Text")
      socket?.emit("deleteMainChatMessage", JSON.stringify(body));
    else {
      let testSocket = socket?.emit(
        "deleteMainChatMedia",
        JSON.stringify(body)
      );
      console.log("testSocket", testSocket);
    }
  };

  const editMessageHandler = async () => {
    let modifiedSelectedEditMessageData = {
      ...selectedEditMessageData,
      message_text: messageText,
    };
    socket?.emit("updateChat", JSON.stringify(modifiedSelectedEditMessageData));
    setSelecteEditMessageData(null);
    setMessageText("");
  };

  const renderOtherMessageUI = (item) => {
    if (item?.sender_id === userId) {
      return (
        <>
          <div className="d-flex flex-column">
            <div className="message_container mess_right_side_div">
              {!item?.is_deleted ? (
                <div className="mess_edit_bin_icons_container">
                  <div
                    onClick={() => {
                      closeThreadPopup();
                      handleThread(item, "right");
                    }}
                  >
                    <ThreadChat />
                  </div>

                  <div
                    onClick={() => {
                      setMessageText(item?.message_text);
                      setSelecteEditMessageData(item);
                    }}
                  >
                    <EditChat />
                  </div>
                  <div
                    onClick={() => {
                      deleteMessageHandler(item);
                    }}
                  >
                    <BinMessages />
                  </div>
                </div>
              ) : null}

              {!item?.is_deleted ? (
                <>
                  {item?.message_media_type === "Text" ? (
                    <div className={"mess_container_self"}>
                      {item?.message_text}
                    </div>
                  ) : item?.message_media_type === "Image" ? (
                    <div className={""}>
                      <img
                        width={"200"}
                        style={{
                          objectFit: "contain",
                        }}
                        src={item?.message_media_url}
                      />
                    </div>
                  ) : item?.message_media_type === "Video" ? (
                    <div className={""}>
                      <video
                        loop={true}
                        src={item?.message_media_url}
                        width="300px"
                        controls="true"
                        autoPlay={true}
                      />

                      {/* {item?.message_text} */}
                    </div>
                  ) : item?.message_media_type === "Audio" ? (
                    <div className={""}>
                      <audio controls src={item?.message_media_url}></audio>
                    </div>
                  ) : item?.message_media_type === "File" ? (
                    <div className={"mess_container_self"}>
                      <a
                        href={item?.message_media_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="message__file"
                      >
                        <i class="css-12q6dla"></i>
                        <p
                          style={{
                            color: "white",
                          }}
                        >
                          File
                        </p>
                      </a>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className={"mess_chat_deleted"}>
                  ⚠️
                  {" You deleted this message"}
                </div>
              )}

              {/* 
              <video
                height="100%"
                loop={true}
                autoPlay={true}
                controls={true}
                width="100%"
                src="https://brainvire-grv8-staging-static.s3.eu-west-2.amazonaws.com/staging/Event/177/2308673d-d18b-431d-a4bd-6ae02091a3ae/3225401850.mp4"
              /> */}
            </div>
            <div className="right_sided_message_div mess_time_section">
              {item?.users_conversation_thread
                ?.users_conversation_thread_messages?.length > 0 ? (
                <span
                  onClick={() => {
                    closeThreadPopup();

                    handleThread(item, "right");
                  }}
                  className="messages_thread_replies"
                >
                  {item?.users_conversation_thread
                    ?.users_conversation_thread_messages?.length + " replies"}
                </span>
              ) : null}
              <span>
                {item?.time + " "}
                {item?.is_read ? <MessageDelivered /> : <MessageSent />}
              </span>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="message_container left_sided_message_div">
            <div className="d-flex flex-column">
              <div className="">
                <div className="d-flex"></div>
                <div className="justify-content-between">
                  <span className="">
                    {/* <div className="mess_edit_bin_icons_container">
                      <EditChat />
                      <BinMessages />
                    </div> */}

                    {!item?.is_deleted ? (
                      <>
                        {item?.message_media_type === "Text" ? (
                          <div
                            style={{
                              backgroundColor: "#f6f6f6",
                            }}
                            className={"mess_container_other"}
                          >
                            {item?.message_text}
                          </div>
                        ) : item?.message_media_type === "Image" ? (
                          <div className={""}>
                            <img
                              width={"200"}
                              style={{
                                objectFit: "contain",
                              }}
                              src={item?.message_media_url}
                            />
                          </div>
                        ) : item?.message_media_type === "Video" ? (
                          <div className={""}>
                            <video
                              loop={true}
                              src={item?.message_media_url}
                              width="300px"
                              controls="true"
                              autoPlay={true}
                            />

                            {/* {item?.message_text} */}
                          </div>
                        ) : item?.message_media_type === "Audio" ? (
                          <div className={""}>
                            <audio
                              controls
                              src={item?.message_media_url}
                            ></audio>
                          </div>
                        ) : item?.message_media_type === "File" ? (
                          <div className={"mess_container_self"}>
                            <a
                              href={item?.message_media_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              class="message__file"
                            >
                              <i class="css-12q6dla"></i>
                              <p
                                style={{
                                  color: "white",
                                }}
                              >
                                File
                              </p>
                            </a>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div className={"mess_chat_deleted"}>
                        ⚠️
                        {" This message was deleted"}
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="time_section mt-1">
                {item?.time}

                {item?.users_conversation_thread
                  ?.users_conversation_thread_messages?.length > 0 ? (
                  <span
                    onClick={() => {
                      closeThreadPopup();

                      handleThread(item, "left");
                    }}
                    className="messages_thread_replies"
                  >
                    {item?.users_conversation_thread
                      ?.users_conversation_thread_messages?.length + " replies"}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const getHeaderButtonDetails = async () => {
    setMessChatLoader(true);
    const response = await ApiCall(
      "GET",
      "",
      "/admin/profilebooth/details/get",
      {
        user_id: userChatHeaderData?.receiver_id,
      }
    );

    if (response?.data?.code === 200) {
      let user = response?.data?.data;
      let boothData = response?.data?.data?.booth_details;
      let event_id = null;
      if (localStorage.getItem("event_id")) {
        event_id: localStorage.getItem("event_id");
      }
      setUserHeaderButtonsData({
        userData: {
          bio: user?.bio,
          company: user?.company,
          country: user?.country,
          email: user?.email,
          event_domain_name: user?.event_domain_name,
          image_url: user?.image_url,
          is_calendar_access: user?.is_calendar_access,
          job_title: user?.job_title,
          tier_name: user?.tier_name,
          user_name: user?.user_name,
          user_type: user?.user_type,
          chat_id: user?.chat_id,
          user_id: user?.user_id,
          users_users_social_media_handles_user_id:
            user?.users_users_social_media_handles_user_id,
          user_type: user?.user_type,
        },
        visitBoothData: boothData
          ? {
              tier: boothData?.brand_tier,
              zones: boothData?.zones,
              sequence: boothData?.sequence ?? null,
              brand_id: boothData?.brand_id,
              eventID: boothData?.event_id ?? event_id,
              final_booth_url: boothData?.final_booth_url,
            }
          : null,
      });
    } else {
      // toast.error(response?.data?.message)
    }
    setMessChatLoader(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // const isUserOnline = async () => {
  //   const response = await ApiCall(
  //     "GET",
  //     "",
  //     "/admin/conversationreciever/online/check",
  //     {
  //       user_id: userChatHeaderData?.receiver_id,
  //       user_type: userChatHeaderData?.userType,
  //     }
  //   );

  //   console.log("is online resp", response);

  //   if (response?.data?.code === 200) {
  //     setUserOnline(response?.data?.data?.is_online);
  //   }
  // };

  // console.log("message text", messageText);
  // console.log("video", videoFileUpload);
  // console.log("userChatHeaderData", userChatHeaderData);
  // console.log("allMessages", allMessages);

  if (messChatLoader) {
    return <CommonLoader />;
  } else
    return (
      <div className="mess_chat_container">
        <div className="mess_chat_header">
          <div className="mess_chat_header_left">
            <Badge
              className={
                userChatHeaderData?.isOnline
                  ? "mess_online_chat_dot"
                  : "mess_offline_chat_dot"
              }
              dot
            >
              <Avatar src={userChatHeaderData?.img} />
            </Badge>
            <div className="mess_chat_user_name_container">
              <span className="mess_chat_user_name">
                {userChatHeaderData?.userName}
              </span>
              <span>
                {/* {userChatHeaderData?.isOnline ? "Online" : "Offline"} */}
                {userHeaderButtonsData?.userData?.company}
              </span>
            </div>
          </div>
          <div className="mess_chat_header_right">
            <Button
              onClick={() => {
                setIsProfileModalVisible(true);
              }}
              className=" rounded"
            >
              <User />
              <span className="ms-2">Profile</span>
            </Button>
            {userHeaderButtonsData?.visitBoothData !== null ? (
              <Button
                onClick={() => {
                  let {
                    tier,
                    zones,
                    sequence,
                    brand_id,
                    eventID,
                    final_booth_url,
                  } = userHeaderButtonsData?.visitBoothData;

                  vistaRedirectToBooth(
                    tier,
                    zones,
                    sequence,
                    loginUserType,
                    brand_id,
                    eventID,
                    history,
                    dispatch,
                    final_booth_url
                  );
                }}
                className="rounded messages_booth_btn"
              >
                <BoothRed />
                <span className="ms-2">Visit Booth</span>
              </Button>
            ) : null}

            <Button
              className="rounded messages_video_call_btn"
              onClick={async () => {
                const response = await oneToOneVideoAPI({
                  user_id: userHeaderButtonsData?.userData?.user_id,
                  chat_id: userHeaderButtonsData?.userData?.chat_id,
                  fbase_token: userHeaderButtonsData?.userData?.fbase_token,
                  user_type: userHeaderButtonsData?.userData?.user_type,
                });
                if (response?.data?.code === 200) {
                  if (response?.data?.data) {
                    toast.success(response.data.message);

                    dispatch(
                      setVideoConferenceData({
                        videoConferenceFlag: true,
                        videoConferenceUrl:
                          response?.data?.data.video_call_link,
                        is_video_call: true,
                        receiver_chat_id:
                          userHeaderButtonsData?.userData?.chat_id,
                      })
                    );
                  }
                } else toast.error(response.data.message);
              }}
              style={{ display: "flex" }}
            >
              <VideoCameraOutlined style={{ fontSize: "19px" }} />
              <span>Video Call</span>
            </Button>
          </div>
        </div>
        <div className="mess_chat_messages">
          <div className={"mess_area"}>
            {allMessages?.map((item) => {
              return (
                <div key={"message_" + item?.conversation_id}>
                  {renderOtherMessageUI(item)}
                </div>
              );
            })}
            {/* Scroll at bottom of messages */}

            {/* {allMessages === [] || isEmpty(allMessages) ? (
          <div className="empty_chat">
            <Empty
              description={<span className="fs-pink">No messages found</span>}
            />
          </div>
        ) : null} */}

            {showEmojiPicker ? (
              <Picker
                title={""}
                emoji="point_up"
                native
                onClick={(emoji) => {
                  console.log("emoji", emoji);
                  setMessageText((current) => current + emoji.native);
                }}
                showPreview={false}
                exclude={exclude}
                //   i18n={{ categories: this.state.categories }}

                style={{
                  bottom: "2px",
                  position: "absolute",
                  overflow: "hidden",
                  zIndex: "2",
                  width: "100%",
                  height: "225px",
                }}
              />
            ) : null}
            <div ref={messagesEndRef} />
          </div>

          <div className="mess_chat_bottom_section">
            {selectedEditMessageData?.conversation_id ? (
              <div className="mess_edit_chat_container">
                <div className="d-flex flex-column">
                  <span>Edit message</span>
                  <span>{selectedEditMessageData?.message_text}</span>
                </div>
                <div>
                  <div
                    onClick={() => {
                      setSelecteEditMessageData(null);
                      setMessageText(null);
                    }}
                  >
                    <Close />
                  </div>
                </div>
              </div>
            ) : null}

            <Input
              placeholder="Enter your message here"
              autoFocus
              className="mess_chat_input_bottom_section"
              value={messageText}
              onChange={(e) => {
                setMessageText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddMessage();
                }
              }}
              suffix={
                <span className="send_btn">
                  <SendOutlined
                    ref={sendMessageRef}
                    className="iconnn"
                    onClick={() => handleAddMessage()}
                  />
                </span>
              }
            />
            <div className="mess_chat_bottom_attachment_container">
              <div className="mess_chat_bottom_attachment_left_container">
                <Tooltip placement="bottom" title={"Video"}>
                  <div
                    className="mess_attach_video"
                    onClick={() => {
                      videoAttachRef.current.click();
                    }}
                  >
                    <VideoAttach />

                    <input
                      onChange={(e) => {
                        sendMediaAPI("Video", e.target.files[0]);
                      }}
                      className="mess_attach_inputs"
                      accept="video/*"
                      type="file"
                      ref={videoAttachRef}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="bottom" title={"Image"}>
                  <div
                    className="mess_attach_video"
                    onClick={() => {
                      // videoAttachRef.current.click();
                      imageAttachRef.current.click();
                    }}
                  >
                    <ImageAttach />

                    <input
                      onChange={(e) => {
                        sendMediaAPI("Image", e.target.files[0]);
                      }}
                      className="mess_attach_inputs"
                      accept="image/*"
                      type="file"
                      ref={imageAttachRef}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="bottom" title={"Audio"}>
                  <div
                    className="mess_attach_video"
                    onClick={() => {
                      audioAttachRef.current.click();
                    }}
                  >
                    <AudioAttach />

                    <input
                      onChange={(e) => {
                        // setVideoFileUpload(e);
                        sendMediaAPI("Audio", e.target.files[0]);
                      }}
                      className="mess_attach_inputs"
                      accept="audio/*"
                      type="file"
                      ref={audioAttachRef}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="bottom" title={"File"}>
                  <div
                    className="mess_attach_video"
                    onClick={() => {
                      fileAttachRef.current.click();
                    }}
                  >
                    <FileAttach />

                    <input
                      onChange={(e) => {
                        // setVideoFileUpload(e);
                        sendMediaAPI("File", e.target.files[0]);
                      }}
                      className="mess_attach_inputs"
                      type="file"
                      ref={fileAttachRef}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="mess_chat_bottom_attachment_right_container">
                <SmileOutlined
                  onClick={() => {
                    setShowEmojiPicker((current) => !current);
                  }}
                  style={{
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {isProfileModalVisible ? (
          <ProfileModal
            isProfileModalVisible={isProfileModalVisible}
            userData={userHeaderButtonsData?.userData}
            changeModalFlag={() => {
              setIsProfileModalVisible(false);
            }}
          />
        ) : null}
      </div>
    );
};

export default MessagesChat;
