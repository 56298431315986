import React, { useState, useEffect, useRef } from "react";
import "./MyProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select, Row, Col, Switch, Modal, Divider } from "antd";
import { BinWhite } from "../../components/svgJS/Bin";
import { PadLock } from "../../components/svgJS/PadLock";
import { toast } from "react-toastify";
import CommonLoader from "../../components/Widgets/CommonLoader";
import SimpleReactValidator from "simple-react-validator";
import { getCountryList, getJobTitles } from "../../utils/commonApis";
import FileUpload from "../common/FileUpload";
import { getProfileDetails, updateProfile } from "../../redux/slice/myProfile";
import MyProfileCustomer from "./MyProfileCustomer";
import { useHistory } from "react-router";
import RedAsterick from "../../components/Widgets/RedAsterick";
import Text from "antd/lib/typography/Text";
import { ApiCall } from "../../utils/api";
import { dialogs } from "../../components/constsnts/string.constants";
import { setHeaderImage } from "../../redux/slice/headerData";
import { useMediaQuery } from "react-responsive";
import placeholder_image from "../../assets/images/profile_placeholder.png";

const MyProfile = (props) => {
  var CryptoJS = require("crypto-js");
  const { TextArea } = Input;
  const history = useHistory();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [profileLocalData, setProfileLocalData] = useState({});
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [previewPic, setPreviewPic] = useState();
  const [profPic, setProfPic] = useState();
  const [existingPass, setExistingPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteAcountModal, setdeleteAcountModal] = useState(false);
  const [customerProfileData, setCustomerProfileData] = useState(null);
  const [checkCustomer, setCheckCustomer] = useState(false);
  const [userType, setUserType] = useState("");
  const [isRequiredFields, setIsRequiredFields] = useState({
    isJobRequired: 0,
    isCountryRequired: 0,
  });
  const [socialAccess, setSocialAccess] = useState("");

  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const Tab = useMediaQuery({ maxWidth: 900 });
  const isMobile = useMediaQuery({ maxWidth: 450 });

  useEffect(async () => {
    setLoading(true);
    if (props.otherUser)
      await dispatch(getProfileDetails({ user_id: props.otherUser.user_id }));
    else await dispatch(getProfileDetails({}));
    const countriesHere = await getCountryList();
    setCountries(countriesHere);
    const jobTitlesHere = await getJobTitles();
    setJobTitles(jobTitlesHere);
    setLoading(false);
  }, []);

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    if (ciphertext) {
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);
    }
    let is_social_link_access = JSON.parse(
      localStorage.getItem("is_social_link_access")
    );
    setSocialAccess(is_social_link_access);
  }, [CryptoJS.AES]);

  useEffect(async () => {
    setIsRequiredFields({
      isJobRequired:
        profileData?.event_setting_flags?.job_title_collection ?? 0,
      isCountryRequired:
        profileData?.event_setting_flags?.country_collection ?? 0,
    });

    //Check if its not a customer
    if (!profileData?.customer_id) {
      setCheckCustomer(false);
      setProfileLocalData({
        user_profile_details: {
          first_name: profileData?.user_profile_details?.first_name ?? "",
          last_name: profileData?.user_profile_details?.last_name ?? "",
          fullname: `${profileData?.user_profile_details?.first_name} ${profileData?.user_profile_details?.last_name}`,
          email: profileData?.user_profile_details?.email ?? "",
          bio: profileData?.user_profile_details?.bio ?? "",
          company: profileData?.user_profile_details?.company ?? "",
          job_title_id: profileData?.user_profile_details?.job_title_id ?? null,
          job_title:
            profileData?.user_profile_details?.job_title?.job_title ?? null, //This is for free form text
          country_id: profileData?.user_profile_details?.country_id ?? null,
          image_url:
            profileData && profileData?.user_profile_details?.image_url,
          user_type: profileData?.user_profile_details?.user_type ?? "",
        },
        external_social_links_details:
          profileData?.external_social_links_details?.map((item) => {
            return {
              user_social_id: item?.user_social_id ?? null,
              social_media_url: item?.social_media_url ?? "",
              social_media_id: item?.social_media_id ?? null,
              social_media: item?.social_media ?? "",
              sequence: item?.sequence ?? null,
              is_active: item?.is_active ?? false,
              is_enabled: item?.social_media_url ? true : false,
            };
          }),
        in_app_notifications: profileData?.in_app_notifications?.map((item) => {
          return {
            notification_settings_id: item?.notification_settings_id ?? null,
            is_enabled: item?.is_enabled ?? false,
            notification_type_id: item?.notification_type_id ?? null,
            notification_type: item?.notification_type ?? "",
            notification_title: item?.notification_title ?? "",
            sequence: item?.sequence ?? null,
            is_active: item?.is_active ?? false,
          };
        }),
        email_notifications: profileData?.email_notifications?.map((item) => {
          return {
            notification_settings_id: item?.notification_settings_id ?? null,
            is_enabled: item?.is_enabled ?? false,
            notification_type_id: item?.notification_type_id ?? null,
            notification_type: item?.notification_type ?? "",
            notification_title: item?.notification_title ?? "",
            sequence: item?.sequence ?? null,
            is_active: item?.is_active ?? false,
          };
        }),
      });
      setPreviewPic(
        profileData?.user_profile_details?.image_url?.includes(null)
          ? placeholder_image
          : profileData?.user_profile_details?.image_url
      );
    } else {
      setCheckCustomer(true);
      setCustomerProfileData({ ...profileData });
    }
  }, [profileData]);

  const handleImgChange = (e) => {
    setProfPic(e.target.files[0]);
    setPreviewPic(URL.createObjectURL(e.target.files[0]));
  };

  const updateProfileAPI = async () => {
    if (validator.current.allValid()) {
      setLoading(true);
      let dataToSend = profileLocalData;
      dataToSend.external_social_links_details =
        dataToSend.external_social_links_details.filter((item) => {
          if (item.user_social_id && !item.social_media_url) {
            return {
              ...item,
              social_media_url: null,
            };
          } else if (item.social_media_url) {
            return item;
          }
        });
      delete dataToSend.user_profile_details.email;
      delete dataToSend.user_profile_details.image_url;

      if (existingPass && newPass) {
        dataToSend.user_profile_details.existing_password = existingPass;
        dataToSend.user_profile_details.new_password = newPass;
      }
      let formData = new FormData();
      formData.append("data", JSON.stringify(dataToSend));
      formData.append("profile_picture", profPic);
      const updateRes = await dispatch(updateProfile(formData));
      if (updateRes?.payload?.code === 200) {
        if (updateRes?.payload?.data?.image_url) {
          dispatch(
            setHeaderImage({
              logo_url: updateRes.payload.data.image_url,
            })
          );
          localStorage.setItem("logo_url", updateRes.payload.data.image_url);
        }
        await dispatch(getProfileDetails());
        toast.success(updateRes.payload.message);
        setLoading(false);
      } else {
        setLoading(false);
        if (updateRes?.payload?.code !== 200)
          toast.error(updateRes.payload.message);
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
      }
    } else {
      setLoading(false);
      toast.error(dialogs.missingFields);
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  const handleDeleteAcc = async () => {
    const res = await ApiCall("PUT", {}, "/admin/user/myprofile/inactive");
    if (res?.data?.code === 200) {
      toast.success(res?.data?.message);
      localStorage.removeItem("Authorization");
      history.push("/login");
    } else {
      toast.error(res?.data?.message);
    }
  };

  if (loading) return <CommonLoader />;
  else
    return (
      <>
        {!props.otherUser ? (
          <>
            <h4 className={isMobile ? "mt-4" : "mb-1"}>My Profile</h4>
            <span className="opacity-50 ">My Profile</span>
          </>
        ) : null}
        {!checkCustomer ? (
          <div className="w-100 mt-2 p-4 bg-white view-all-common">
            <Row className="border-bottom pb-1">
              <Col
                className={`d-flex flex-column align-items-center ${
                  Tablet ? "mb-4" : ""
                }`}
                span={Tablet ? 24 : 9}
              >
                <div className="myprofile_img_container d-flex mb-3 justify-content-center align-items-center">
                  <img
                    src={previewPic ?? placeholder_image}
                    className={props.otherUser ? "w-50 h-50" : "w-100 h-100"}
                  />
                </div>
                {!props.otherUser ? (
                  <FileUpload
                    labelClassName="rounded border-pink btn-svg px-3  py-1"
                    labelTitle="Upload"
                    inputType="file"
                    inputOnChange={handleImgChange}
                    inputId="my_profile_logo"
                    inputName="my_profile_logo"
                    name="logo"
                  />
                ) : null}
              </Col>
              <Col>
                <Row>
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={6}
                  >
                    First Name <RedAsterick />
                  </Col>
                  <Col offset={1} span={17}>
                    <Input
                      disabled={props.otherUser ? true : false}
                      name="myprofile_nameInput"
                      className="myprofile_Input"
                      placeholder="Enter a name here"
                      value={profileLocalData?.user_profile_details?.first_name}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            first_name: e.target.value,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_nameInput")
                      }
                    />
                    {validator.current.message(
                      "First Name",
                      profileLocalData?.user_profile_details?.first_name,
                      "required",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={6}
                  >
                    Surname <RedAsterick />
                  </Col>
                  <Col offset={1} span={17}>
                    <Input
                      disabled={props.otherUser ? true : false}
                      name="myprofile_nameInput"
                      className="myprofile_Input"
                      placeholder="Enter a name here"
                      value={profileLocalData?.user_profile_details?.last_name}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            last_name: e.target.value,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_nameInput")
                      }
                    />
                    {validator.current.message(
                      "surname",
                      profileLocalData?.user_profile_details?.last_name,
                      "required",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={6}
                  >
                    <span className="mr-1 align-self-start">Bio</span>
                  </Col>
                  <Col offset={1} span={17}>
                    <TextArea
                      name="myprofile_textAreaInput"
                      id="myprofile_textAreaInput"
                      className="myprofile_textAreaInput"
                      placeholder="Enter Bio"
                      disabled={props.otherUser ? true : false}
                      rows={4}
                      value={profileLocalData?.user_profile_details?.bio}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            bio: e.target.value,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={6}
                  >
                    <span className="mr-1">
                      Company <RedAsterick />
                    </span>
                  </Col>
                  <Col offset={1} span={17}>
                    <Input
                      disabled={props.otherUser ? true : false}
                      name="myprofile_company"
                      className="myprofile_Input"
                      placeholder="Enter Company Name here"
                      value={profileLocalData?.user_profile_details?.company}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            company: e.target.value,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_company")
                      }
                    />
                    {validator.current.message(
                      "Company",
                      profileLocalData?.user_profile_details?.company,
                      "required",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={6}
                  >
                    <span className="mr-1">
                      Email <RedAsterick />
                    </span>
                  </Col>
                  <Col offset={1} span={17}>
                    <Input
                      disabled
                      name="myprofile_email"
                      className="myprofile_Input"
                      placeholder="Enter Email"
                      value={profileLocalData?.user_profile_details?.email}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            email: e.target.value,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_email")
                      }
                    />
                    {validator.current.message(
                      "Email",
                      profileLocalData?.user_profile_details?.email,
                      "required|email",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                    <Text style={{ marginTop: "5px", marginBottom: "5px" }}>
                      Contact the Gravit8 team to edit main email contact
                    </Text>
                  </Col>
                </Row>
                {userType === "DELEGATE" ? (
                  <Row className="mt-3">
                    <Col
                      className="d-flex justify-content-end align-items-center"
                      span={6}
                    >
                      <span className="mr-1">
                        Job Role
                        {isRequiredFields?.isJobRequired ? (
                          <RedAsterick />
                        ) : null}
                      </span>
                    </Col>
                    <Col offset={1} span={17}>
                      <Select
                        showSearch
                        disabled={props.otherUser ? true : false}
                        name="myprofile_jobrole"
                        bordered={false}
                        className="myprofile_select w-100"
                        placeholder="Enter Job Role"
                        value={
                          profileLocalData?.user_profile_details?.job_title_id
                        }
                        onChange={(val) =>
                          setProfileLocalData({
                            ...profileLocalData,
                            user_profile_details: {
                              ...profileLocalData.user_profile_details,
                              job_title_id: val,
                            },
                          })
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("myprofile_jobrole")
                        }
                      >
                        {jobTitles &&
                          jobTitles.map((item, i) => {
                            return (
                              <Option
                                key={item.job_title_id + "_jobtitle"}
                                value={item.job_title_id}
                              >
                                {item.job_title}
                              </Option>
                            );
                          })}
                      </Select>
                      {isRequiredFields?.isJobRequired
                        ? validator.current.message(
                            "Job Role",
                            profileLocalData?.user_profile_details
                              ?.job_title_id,
                            "required",
                            {
                              className: "text-danger py-1",
                            }
                          )
                        : null}
                    </Col>
                  </Row>
                ) : (
                  <Row className="mt-3">
                    <Col
                      className="d-flex justify-content-end align-items-center"
                      span={6}
                    >
                      <span className="mr-1">
                        Job Role
                        {isRequiredFields?.isJobRequired ? (
                          <RedAsterick />
                        ) : null}
                      </span>
                    </Col>
                    <Col offset={1} span={17}>
                      <Input
                        disabled={props.otherUser ? true : false}
                        name="myprofile_jobInput"
                        className="myprofile_jobInput"
                        placeholder=""
                        value={
                          profileLocalData?.user_profile_details?.job_title
                        }
                        onChange={(e) =>
                          setProfileLocalData({
                            ...profileLocalData,
                            user_profile_details: {
                              ...profileLocalData.user_profile_details,
                              job_title: e.target.value,
                              job_title_id: null,
                            },
                          })
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("myprofile_jobInput")
                        }
                      />
                      {isRequiredFields?.isJobRequired
                        ? validator.current.message(
                            "Job Role",
                            profileLocalData?.user_profile_details?.job_title,
                            "required",
                            {
                              className: "text-danger py-1",
                            }
                          )
                        : null}
                    </Col>
                  </Row>
                )}
                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={6}
                  >
                    <span className="mr-1">
                      Country
                      {isRequiredFields?.isCountryRequired ? (
                        <RedAsterick />
                      ) : null}
                    </span>
                  </Col>
                  <Col offset={1} span={17}>
                    <Select
                      disabled={props.otherUser ? true : false}
                      showSearch
                      name="myprofile_country"
                      bordered={false}
                      className="myprofile_select w-100 "
                      placeholder="Enter Country"
                      value={profileLocalData?.user_profile_details?.country_id}
                      onChange={(val) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            country_id: val,
                          },
                        })
                      }
                      optionFilterProp="children"
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_country")
                      }
                    >
                      {countries &&
                        countries.map((item, i) => {
                          return (
                            <Option
                              key={item.brand_id + "_country"}
                              value={item.country_id}
                            >
                              {item.country}
                            </Option>
                          );
                        })}
                    </Select>
                    {isRequiredFields?.isCountryRequired
                      ? validator.current.message(
                          "",
                          profileLocalData?.user_profile_details?.country_id,
                          "required",
                          {
                            className: "text-danger py-1",
                          }
                        )
                      : null}
                  </Col>
                </Row>
                <Row className="mt-3 mb-5">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={6}
                  ></Col>
                  {!props.otherUser ? (
                    <Col offset={1}>
                      <div>
                        <Button
                          onClick={() => setIsModalVisible(true)}
                          className={`myprofile_passwordBtn ${
                            isMobile && "w-100"
                          }`}
                        >
                          <PadLock />
                          <span className="ms-2">Change Password</span>
                        </Button>
                        <Button
                          className={`myprofile_delete ${
                            isMobile && "w-100 mt-2"
                          } ${Tablet ? "" : "ms-3"}`}
                          onClick={() => setdeleteAcountModal(true)}
                        >
                          <BinWhite />
                          <span className="ms-2">Delete Account</span>
                        </Button>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
            {!props.otherUser &&
            userType !== "SUPER ADMIN" &&
            userType !== "CUSTOMER" ? (
              <>
                {socialAccess ||
                userType === "SUPER ADMIN" ||
                userType === "CUSTOMER" ||
                userType === "SPEAKER" ? (
                  <>
                    <div className="mt-5 ">
                      <h5 className="fs-pink">External & Social Links</h5>
                      <p>
                        NB: If you paste your details into these boxes then
                        anyone using this site will be able to view/contact you
                        via these platforms.
                      </p>
                    </div>
                    {profileLocalData?.external_social_links_details &&
                      profileLocalData?.external_social_links_details.map(
                        (item) => {
                          return (
                            <Row className="mt-3">
                              <Col
                                className={`d-flex ${
                                  isMobile
                                    ? "justify-content-start align-self-center"
                                    : "justify-content-end align-self-center"
                                }`}
                                span={isMobile ? 5 : 7}
                              >
                                <span className={`${!isMobile && "mx-5"}`}>
                                  {item?.social_media}
                                </span>
                              </Col>
                              <Col
                                className="d-flex align-items-center"
                                span={isMobile ? 4 : 2}
                              >
                                <Switch
                                  checked={item?.is_enabled}
                                  onChange={(val) => {
                                    if (val) {
                                      setProfileLocalData({
                                        ...profileLocalData,
                                        external_social_links_details:
                                          profileLocalData?.external_social_links_details.map(
                                            (elem) => {
                                              if (
                                                elem.social_media ===
                                                item.social_media
                                              ) {
                                                return {
                                                  ...elem,
                                                  is_enabled: true,
                                                };
                                              } else {
                                                return elem;
                                              }
                                            }
                                          ),
                                      });
                                    } else {
                                      setProfileLocalData({
                                        ...profileLocalData,
                                        external_social_links_details:
                                          profileLocalData?.external_social_links_details.map(
                                            (elem) => {
                                              if (
                                                elem.social_media ===
                                                item.social_media
                                              ) {
                                                return {
                                                  ...elem,
                                                  social_media_url: null,
                                                  is_enabled: false,
                                                };
                                              } else {
                                                return elem;
                                              }
                                            }
                                          ),
                                      });
                                    }
                                  }}
                                />
                              </Col>
                              <Col
                                offset={Tab && !isMobile ? 1 : 0}
                                span={isMobile && 15}
                              >
                                <Input
                                  name="external_links"
                                  disabled={!item?.is_enabled}
                                  value={item?.social_media_url}
                                  onChange={(e, i) => {
                                    setProfileLocalData({
                                      ...profileLocalData,
                                      external_social_links_details:
                                        profileLocalData?.external_social_links_details.map(
                                          (val) => {
                                            if (
                                              item?.social_media ===
                                              val?.social_media
                                            ) {
                                              return {
                                                ...val,
                                                social_media_url:
                                                  e.target.value,
                                              };
                                            } else return val;
                                          }
                                        ),
                                    });
                                  }}
                                  placeholder="https://webaddress.com"
                                  className={`${
                                    isMobile ? "mt-2" : "myprofile_Input"
                                  }`}
                                  onBlur={() =>
                                    validator.current.showMessageFor(
                                      "external_links"
                                    )
                                  }
                                />
                              </Col>
                              {item?.is_enabled &&
                                validator.current.message(
                                  "",
                                  item?.social_media_url,
                                  "required|url",
                                  {
                                    className: "ms-2 text-danger py-1",
                                  }
                                )}
                            </Row>
                          );
                        }
                      )}
                  </>
                ) : null}
                <Divider />
                <div className="border-bottom-gray mb-4 pb-5">
                  <div className="mt-5 mb-4 ">
                    <h5 className="fs-pink">In-app Notifications</h5>
                  </div>
                  <Row className="mt-4 ">
                    <Col
                      className={`d-flex ${
                        isMobile
                          ? "justify-content-start"
                          : "justify-content-end"
                      }`}
                      span={!isMobile && !Tablet ? 6 : 13}
                    >
                      <span className="">All (off = do not disturb mode)</span>
                    </Col>
                    <Col offset={1}>
                      <Switch
                        onChange={(val) => {
                          setProfileLocalData({
                            ...profileLocalData,
                            in_app_notifications:
                              profileLocalData?.in_app_notifications.map(
                                (elem) => {
                                  return {
                                    ...elem,
                                    is_enabled: false,
                                  };
                                }
                              ),
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  {profileLocalData?.in_app_notifications &&
                    profileLocalData?.in_app_notifications.map((item) => {
                      return (
                        <Row className="mt-4">
                          <Col
                            className={`d-flex ${
                              isMobile
                                ? "justify-content-start"
                                : "justify-content-end"
                            }`}
                            span={!isMobile && !Tablet ? 6 : 13}
                          >
                            <span className="">{item?.notification_title}</span>
                          </Col>
                          <Col offset={1}>
                            <Switch
                              checked={item?.is_enabled}
                              onChange={(val) => {
                                setProfileLocalData({
                                  ...profileLocalData,
                                  in_app_notifications:
                                    profileLocalData?.in_app_notifications.map(
                                      (elem) => {
                                        if (
                                          item?.notification_title ===
                                          elem?.notification_title
                                        ) {
                                          return {
                                            ...elem,
                                            is_enabled: !item?.is_enabled,
                                          };
                                        } else {
                                          return elem;
                                        }
                                      }
                                    ),
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                </div>
                {profileLocalData?.user_profile_details?.user_type ===
                "DELEGATE" ? (
                  <>
                    <div className="mt-5 mb-4">
                      <h5 className="fs-pink">Email Notifications</h5>
                    </div>
                    <Row className="mt-4">
                      <Col
                        className={`d-flex ${
                          isMobile
                            ? "justify-content-start"
                            : "justify-content-end"
                        }`}
                        span={!isMobile && !Tablet ? 6 : 13}
                      >
                        <span className="">
                          All (off = do not disturb mode)
                        </span>
                      </Col>
                      <Col offset={1}>
                        <Switch
                          onChange={(val) => {
                            setProfileLocalData({
                              ...profileLocalData,
                              email_notifications:
                                profileLocalData?.email_notifications.map(
                                  (elem) => {
                                    return {
                                      ...elem,
                                      is_enabled: false,
                                    };
                                  }
                                ),
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    {profileLocalData?.email_notifications &&
                      profileLocalData?.email_notifications.map((item) => {
                        return (
                          <Row className="mt-4">
                            <Col
                              className={`d-flex ${
                                isMobile
                                  ? "justify-content-start"
                                  : "justify-content-end"
                              }`}
                              span={!isMobile && !Tablet ? 6 : 13}
                            >
                              <span className="">
                                {item?.notification_title}
                              </span>
                            </Col>
                            <Col offset={1}>
                              <Switch
                                checked={item?.is_enabled}
                                onChange={(val) => {
                                  setProfileLocalData({
                                    ...profileLocalData,
                                    email_notifications:
                                      profileLocalData?.email_notifications.map(
                                        (elem) => {
                                          if (
                                            item?.notification_title ===
                                            elem?.notification_title
                                          ) {
                                            return {
                                              ...elem,
                                              is_enabled: !item?.is_enabled,
                                            };
                                          } else return elem;
                                        }
                                      ),
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                  </>
                ) : null}
              </>
            ) : null}

            <div className="d-flex justify-content-center">
              <a target={"_blank"} href="/privacyandpolicy">
                Privacy And Policy
              </a>
            </div>
            <Modal
              title="Change Password"
              visible={isModalVisible}
              onOk={() => setIsModalVisible(false)}
              onCancel={() => setIsModalVisible(false)}
              width={600}
            >
              <div className="profile_modal mx-5 mt-4">
                <h6>Existing Password:</h6>
                <Input
                  name="myprofile_existing_pass"
                  className="myprofile_Input"
                  placeholder="Enter Existing Password"
                  value={existingPass}
                  onChange={(e) => setExistingPass(e.target.value)}
                />
                <h6 className="mt-4">New Password:</h6>

                <Input
                  name="myprofile_new_pass"
                  className="myprofile_Input"
                  placeholder="Enter New Password"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
              </div>
            </Modal>

            <Modal
              footer={null}
              visible={deleteAcountModal}
              onOk={() => setdeleteAcountModal(false)}
              onCancel={() => setdeleteAcountModal(false)}
              width={600}
            >
              <div className=" mx-5 mt-4 text-center">
                <h5>Are you sure you want to delete this user?</h5>
                <div className="my-4">
                  <Button onClick={() => setdeleteAcountModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleDeleteAcc();
                    }}
                    className="mx-2"
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </Modal>
            {!props.otherUser ? (
              <div className="d-flex justify-content-end mt-2">
                <Button
                  size={"large"}
                  type="primary"
                  onClick={updateProfileAPI}
                  className="m-5"
                >
                  Update
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <MyProfileCustomer
            getProfileDetails={getProfileDetails}
            profileData={customerProfileData}
          />
        )}
      </>
    );
};

export default MyProfile;
