import React, { useState, useEffect } from "react";
import { ApiCall } from "../../utils/api";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const VideoPlayerPage = (props) => {
  const [bannerUrl, setBannerUrl] = useState("");

  useEffect(async () => {
    getBanner();
  }, []);

  const getBanner = () => {
    let timeStamp = moment().format("YYYY-MM-DD HH:mm:ss");

    ApiCall(
      "get",
      null,
      "/admin/vista/bannervideo/details/get",
      {
        event_id: localStorage.getItem("event_id"),
        current_timestamp: timeStamp,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      true
    )
      .then((response) => {
        if (response) {
          let searchValue = props.location.search.split("=")[1];

          switch (searchValue) {
            case "sponsorLeft":
              setBannerUrl(response?.data?.data?.sponsor_booth_left_url);
              break;
            case "sponsorRight":
              setBannerUrl(response?.data?.data?.sponsor_booth_right_url);
              break;
            case "sponsorCenter":
              setBannerUrl(response?.data?.data?.sponsor_booth_center_url);
              break;
            default:
              setBannerUrl(response?.data?.data?.sponsor_banner_url);
          }
        } else {
          setBannerUrl("");
        }
      })
      .catch((err) => {});
  };

  let extension = bannerUrl?.substring(bannerUrl.lastIndexOf(".") + 1);

  if (extension === "mp4")
    return (
      <video
        muted={
          props.location.search.split("=")[1] === "sponsorCenter" ? false : true
        }
        style={{
          background: "black",
          position: "fixed",
          objectFit: "fill",
        }}
        loop={true}
        src={bannerUrl ?? null}
        height="100%"
        width="100%"
        // controls="controls"
        autoPlay={true}
      />
    );
  else
    return (
      <img
        src={bannerUrl ?? null}
        width="100%"
        height={"100%"}
        alt="concourse-sponsor"
        style={{
          background: "black",
          objectFit: "fill",
        }}
      />
    );
};

export default VideoPlayerPage;
