import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";
import { isNotificationType } from "../../utils/commonFunctions";

const initialState = {
  notificationsData: [],
  newNotificationsCount: 0,
  loading: false,
};

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/user/notifications/get",
      params
    );
    if (response?.data?.data) return response.data.data;
    else return [];
  }
);

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.loading = true;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notificationsData = payload;
      let newCount = 0;
      if (payload)
        payload.forEach((ele) => {
          if (ele.is_read === 0 && isNotificationType(ele.notification_type))
            newCount += 1;
        });
      state.newNotificationsCount = newCount;
    },
    [getNotifications.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const notificationReducer = notificationSlice.reducer;
