import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiCall } from "../../utils/api";

export const getEventNetworkingLoungeDetails = createAsyncThunk(
  "networking/getEventNetworkingLounge",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/networkinglounges/get",
      params
    );

    if (response?.data?.code === 200) {
      if (response?.data?.data) return response?.data?.data;
      else return [];
    } else {
      toast.error(response?.data?.message);
      return [];
    }
  }
);

export const getNetworkingLoungeSessionDetails = createAsyncThunk(
  "networking/getEventNetworkingSessionDetails",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/networkingloungesessions/get",
      params
    );
    if (response?.data?.code === 200) {
      if (response?.data?.data) return response?.data?.data;
      else return [];
    } else {
      toast.error(response?.data?.message);
      return [];
    }
  }
);

export const getNetworkingSessionTableDetails = createAsyncThunk(
  "networking/getNetworkingSessionTableDetails",
  async (params) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/networkingsession/jointables/get",
      params
    );

    if (response?.data?.code === 200) {
      if (response?.data?.data) {
        return response?.data?.data;
      } else {
        return [];
      }
    } else {
      toast.error(response?.data?.message);
      return [];
    }
  }
);

export const onNetworkingJoinSessClickFlag = createAsyncThunk(
  "onNetworkingJoinSessClickFlag",

  async ({ flag, networking_session_id, networking_configuration_id }) => {
    return {
      flag,
      networking_session_id,
      networking_configuration_id,
    };
  }
);

export const setUserTableDataFromSocket = createAsyncThunk(
  "setUserTableDataFromSocket",

  async (userArr) => {
    return userArr;
  }
);

export const networkingSlice = createSlice({
  name: "networking",
  initialState: {
    loading: false,
    eventNetLoungeData: [],
    networkingLoungeSessionData: [],
    networkingTableData: [],
    joinSessClickDetails: {
      flag: false,
      networking_session_id: null,
      networking_configuration_id: null,
    },
    userTableDataArrFromSocket: null,
  },
  reducers: {},
  extraReducers: {
    [getEventNetworkingLoungeDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getEventNetworkingLoungeDetails.fulfilled]: (state, { payload, meta }) => {
      state.eventNetLoungeData = payload;
      state.loading = false;
    },
    [getEventNetworkingLoungeDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [getNetworkingLoungeSessionDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getNetworkingLoungeSessionDetails.fulfilled]: (
      state,
      { payload, meta }
    ) => {
      state.networkingLoungeSessionData = payload;
      state.loading = false;
    },
    [getNetworkingLoungeSessionDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [getNetworkingSessionTableDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getNetworkingSessionTableDetails.fulfilled]: (
      state,
      { payload, meta }
    ) => {
      state.networkingTableData = payload;
      state.loading = false;
    },
    [getNetworkingSessionTableDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [onNetworkingJoinSessClickFlag.pending]: (state, action) => {
      state.loading = true;
    },
    [onNetworkingJoinSessClickFlag.fulfilled]: (state, { payload }) => {
      state.joinSessClickDetails = payload;
      state.loading = false;
    },
    [onNetworkingJoinSessClickFlag.rejected]: (state, action) => {
      state.loading = false;
    },
    // [setUserTableDataFromSocket.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [setUserTableDataFromSocket.fulfilled]: (state, { payload }) => {
    //   state.networkingTableData = payload;
    //   state.loading = false;
    // },
    // [setUserTableDataFromSocket.rejected]: (state, action) => {
    //   state.loading = false;
    // },
  },
});

export const networkingReducer = networkingSlice.reducer;
