import React from 'react';
import { Spin } from 'antd';

function Loader() {
    return (
        <div className = "container mt-5">
            <div className = "row mt-5">
                <div className = "col-12 text-center">
              <Spin />
                </div>

            </div>
        </div>
    )
}

export default Loader
