import React, { useState, useRef, useEffect } from "react";
import { Modal, Row, Col, TimePicker, Input, Select, Button } from "antd";
import moment from "moment";
import "../eventCalender.css";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import SimpleReactValidator from "simple-react-validator";
import {
  createTickerConfig,
  updateTickerConfig,
} from "../../../redux/slice/eventCalender";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getZones,
  getEvents,
  getUsers,
  getBrandDropdowns,
  getTickerPickerList,
  getTickerData,
} from "../../../utils/commonApis";
import { getMinutesDiff, ifTimeSlotAvailable } from "../eventCalender.utils";
import { cloneDeep, isEmpty } from "lodash";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import {
  createISOTimeString,
  createTZString,
  getDisabledTime,
} from "../../../utils/commonFunctions";
import { dialogs } from "../../../components/constsnts/string.constants";

const TickerConfiguration = (props) => {
  const { tickerConfigData } = props;

  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const format = "HH:mm";
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [tickerLocalData, setTickerLocalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tickerPicker, setTickerPicker] = useState([]);
  const [brandsCollection, setBrandsCollection] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);
  const [usersCollection, setUsersCollection] = useState([]);
  const [eventsCollection, setEventsCollection] = useState([]);
  const [options, setOptions] = useState({ data: [], name: "" });

  useEffect(async () => {
    const brandsHere = await getBrandDropdowns(props.tickerConfigData.event_id);

    setBrandsCollection(brandsHere);
    const zonesHere = await getZones();
    setZoneCollection(zonesHere);
    const usersHere = await getUsers({ is_active: true });
    setUsersCollection(usersHere);
    const eventsHere = await getEvents();
    setEventsCollection(eventsHere);
    const tickerPickerHere = await getTickerPickerList();

    setTickerPicker(tickerPickerHere);
    let tickerAPIData = await getTickerData({
      event_ticker_id: tickerConfigData.event_ticker_id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (tickerAPIData) {
      mapTickerSchema(tickerAPIData, usersHere, brandsHere, zonesHere);

      setLoading(false);
    } else {
      mapTickerSchema(null, usersHere);
      setLoading(false);
    }
  }, []);

  const mapTickerSchema = (tickerAPIData, usersHere, brandsHere, zonesHere) => {
    let start_date_time, end_date_time;
    if (tickerAPIData?.ticker_details?.start_date_time) {
      start_date_time = createTZString(
        tickerAPIData?.ticker_details?.start_date_time
      );
      end_date_time = createTZString(
        tickerAPIData?.ticker_details?.end_date_time
      );
    } else {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      start_date_time = moment().tz(timezone);
      end_date_time = moment().tz(timezone);
    }

    let tickerSchema = {
      event_ticker_id: tickerAPIData?.event_ticker_id ?? null,
      event_id: tickerAPIData?.event_ticker_id ?? null,
      ticker_details: {
        ticker_id: tickerAPIData?.ticker_details?.ticker_id ?? null,
        ticker_name: tickerAPIData?.ticker_details?.ticker_name ?? null,
        ticker_fixed_title:
          tickerAPIData?.ticker_details?.ticker_fixed_title ?? null,
        ticker_description:
          tickerAPIData?.ticker_details?.ticker_description ?? null,
        start_date_time: tickerAPIData?.ticker_details?.start_date_time
          ? moment(start_date_time.format("HH:mm"), TIME_FORMAT)
          : moment(props.tickerConfigData.hourItem.start_time, TIME_FORMAT),
        end_date_time: tickerAPIData?.ticker_details?.end_date_time
          ? moment(end_date_time.format("HH:mm"), TIME_FORMAT)
          : moment(props.tickerConfigData.hourItem.end_time, TIME_FORMAT),
      },
      ticker_link_details: {
        ticker_linking_id:
          tickerAPIData?.ticker_link_details?.ticker_linking_id ?? null,
        brand_id: tickerAPIData?.ticker_link_details?.brand_id ?? null,
        reference_type:
          tickerAPIData?.ticker_link_details?.reference_type ?? null,
        reference_id: tickerAPIData?.ticker_link_details?.reference_id ?? null,
      },
    };

    setTickerLocalData(tickerSchema);
    if (tickerSchema?.ticker_link_details) {
      handleAllDropdowns(
        tickerSchema,
        tickerSchema?.ticker_link_details.reference_type,
        usersHere,
        brandsHere,
        zonesHere
      );
    }
  };

  const handleAllDropdowns = async (
    tickerLocalDataHere,
    val,
    usersHere,
    brandsHere,
    zonesHere
  ) => {
    switch (val) {
      case "BRAND":
        let brandsData = !isEmpty(brandsCollection)
          ? brandsCollection
          : brandsHere;
        let clonedBrands = cloneDeep(brandsData);
        const filteredData =
          clonedBrands &&
          clonedBrands.filter(
            (item) =>
              tickerLocalDataHere &&
              tickerLocalDataHere?.ticker_link_details &&
              item.brand_id ===
                tickerLocalDataHere?.ticker_link_details.brand_id
          );

        setOptions({ data: filteredData, name: "brand" });
        break;
      case "ZONE":
        {
          let zoneData = !isEmpty(zoneCollection) ? zoneCollection : zonesHere;
          let clonedZones = cloneDeep(zoneData);
          const filteredData =
            clonedZones &&
            clonedZones.filter(
              (item) =>
                tickerLocalDataHere &&
                tickerLocalDataHere?.ticker_link_details &&
                item.brand_id ===
                  tickerLocalDataHere?.ticker_link_details.brand_id
            );
          setOptions({ data: filteredData, name: "zone" });
        }
        break;
      case "PERSON":
        {
          let clonedUsers = cloneDeep(usersHere);
          const filteredData =
            clonedUsers &&
            clonedUsers.filter(
              (item) =>
                tickerLocalDataHere &&
                tickerLocalDataHere.ticker_link_details &&
                item.brand_id ===
                  tickerLocalDataHere.ticker_link_details.brand_id
            );

          setOptions({ data: filteredData, name: "user" });
        }

        break;
      case "EVENT":
        {
          if (tickerLocalDataHere?.ticker_link_details?.brand_id) {
            const eventsHere = await getEvents({
              brand_id: tickerLocalDataHere?.ticker_link_details?.brand_id,
            });
            setOptions({ data: eventsHere, name: "event" });
          } else {
            setOptions({ data: eventsCollection, name: "event" });
          }
        }

        break;
      default:
        setOptions({ data: [], name: "" });
    }
  };

  const createUpdateAPI = async (dataToUpdate) => {
    setLoading(true);
    if (dataToUpdate.event_ticker_id) {
      let updateRes = await dispatch(updateTickerConfig(dataToUpdate));

      if (updateRes?.payload?.data?.code === 200) {
        toast.success(updateRes.payload.data.message);
        setLoading(false);
        props.onSuccess(true);
      } else {
        setLoading(false);
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      let createRes = await dispatch(createTickerConfig(dataToUpdate));

      if (createRes?.payload?.data?.code === 200) {
        toast.success(createRes.payload.data.message);
        setLoading(false);
        props.onSuccess(true);
      } else {
        setLoading(false);
        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleTickerConfigSubmit = () => {
    if (validator.current.allValid()) {
      const { tickerConfigData } = props;
      const dateHere = tickerConfigData.currentDateForCalender;
      let clonedData = cloneDeep(tickerLocalData);

      const start_date_str =
        dateHere +
        " " +
        moment(clonedData.ticker_details.start_date_time).format("HH:mm:ss");
      const start_utc = createISOTimeString(start_date_str);

      const end_date_str =
        dateHere +
        " " +
        moment(clonedData.ticker_details.end_date_time).format("HH:mm:ss");
      const end_utc = createISOTimeString(end_date_str);

      clonedData.ticker_details.start_date_time = start_utc;
      clonedData.ticker_details.end_date_time = end_utc;
      clonedData.event_id = props.tickerConfigData.event_id;

      if (
        !ifTimeSlotAvailable(
          moment(clonedData.ticker_details.start_date_time),
          moment(clonedData.ticker_details.end_date_time),
          props.tickerConfigData.existingTimings
        )
      )
        toast.error("Timeslot is not available");
      else if (
        moment(clonedData.ticker_details.start_date_time) >
        moment(clonedData.ticker_details.end_date_time)
      )
        toast.error(dialogs?.inCorrectTimeError);
      else if (
        getMinutesDiff(
          moment(clonedData.ticker_details.start_date_time),
          moment(clonedData.ticker_details.end_date_time)
        ) < 10
      )
        toast.error("Please select atleast 10 mins duration");
      else {
        createUpdateAPI(clonedData);
      }
    } else {
      validator.current.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const onChangeFields = (val, key, parentKey) => {
    let clonedData = cloneDeep(tickerLocalData);
    let dataToUpdate = clonedData[parentKey];
    dataToUpdate[key] = val;
    setTickerLocalData({ ...tickerLocalData, [parentKey]: dataToUpdate });
  };

  const checkIfTickerPickerAlreadyExist = () => {
    let tickerAvailableFlagAndMessage = {
      flag: false,
      message: "",
    };
    let tickerName = tickerLocalData?.ticker_details?.ticker_name;

    tickerPicker?.forEach((item) => {
      if (item?.ticker_name === tickerName) {
        tickerAvailableFlagAndMessage = {
          flag: true,
          message: "Ticker Name Already Exist",
        };
        return tickerAvailableFlagAndMessage;
      }
    });
    return tickerAvailableFlagAndMessage;
  };

  return (
    <Modal
      title="Ticker Configuration"
      visible={true}
      width={950}
      onOk={() => props.onCloseModal()}
      onCancel={() => props.onCloseModal()}
      footer={[
        <Button
          disabled={loading}
          className="ticker_configuration_cancelBtn"
          key="cancel"
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </Button>,
        <Button
          disabled={loading}
          className="ticker_configuration_SaveBtn"
          key="save"
          type="primary"
          onClick={handleTickerConfigSubmit}
        >
          Save
        </Button>,
      ]}
    >
      {loading ? (
        <CommonLoader />
      ) : (
        <div className="ticker_configuration_form">
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Start & End Time</p>
            </Col>
            <Col span={8}>
              <TimePicker
                allowClear={false}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                disabled={
                  tickerConfigData &&
                  tickerConfigData.event_ticker_id &&
                  tickerConfigData.userType === "BRAND ADMIN"
                    ? true
                    : false
                }
                name="start_date_time"
                className="ticker_configuration_timepicker"
                format={format}
                style={{ width: "100%" }}
                value={tickerLocalData?.ticker_details?.start_date_time}
                onSelect={(val) => {
                  if (
                    moment(
                      tickerLocalData?.ticker_details?.end_date_time,
                      "HH:mm"
                    ) > moment(val)
                  ) {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "start_date_time",
                      "ticker_details"
                    );
                  } else {
                    toast.error(dialogs?.inCorrectTimeError);
                  }
                }}
                onChange={(val) => {
                  if (
                    moment(
                      tickerLocalData?.ticker_details?.end_date_time,
                      "HH:mm"
                    ) > moment(val)
                  ) {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "start_date_time",
                      "ticker_details"
                    );
                  }
                }}
                onBlur={() =>
                  validator.current.showMessageFor("start_date_time")
                }
              />
              {validator.current.message(
                "Start Time",
                tickerLocalData?.ticker_details?.start_date_time,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
            <Col span={8} offset={2}>
              <TimePicker
                allowClear={false}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                disabled={
                  tickerConfigData &&
                  tickerConfigData.event_ticker_id &&
                  tickerConfigData.userType === "BRAND ADMIN"
                    ? true
                    : false
                }
                disabledTime={() =>
                  getDisabledTime(
                    tickerLocalData?.ticker_details?.start_date_time
                  )
                }
                name="end_date_time"
                className="ticker_configuration_timepicker"
                value={tickerLocalData?.ticker_details?.end_date_time}
                format={format}
                style={{ width: "100%" }}
                onSelect={(val) => {
                  if (
                    moment(val) >
                    moment(
                      tickerLocalData?.ticker_details?.start_date_time,
                      "HH:mm"
                    )
                  ) {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "end_date_time",
                      "ticker_details"
                    );
                  } else {
                    toast.error(dialogs?.inCorrectTimeError);
                  }
                }}
                onChange={(val) => {
                  if (
                    moment(val) >
                    moment(
                      tickerLocalData?.ticker_details?.start_date_time,
                      "HH:mm"
                    )
                  ) {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "end_date_time",
                      "ticker_details"
                    );
                  } else {
                    toast.error(dialogs?.inCorrectTimeError);
                  }
                }}
                onBlur={() => validator.current.showMessageFor("end_date_time")}
              />
              {validator.current.message(
                "End Time",
                tickerLocalData?.ticker_details?.end_date_time,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Ticker Picker</p>
            </Col>
            <Col span={8}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                showSearch
                optionFilterProp="children"
                bordered={false}
                className="ticker_configuration_selectBrand"
                placeholder="+ Create New Ticker"
                value={tickerLocalData?.ticker_details?.ticker_id}
                onChange={(val) => {
                  let clonedData = cloneDeep(tickerLocalData);
                  let clonedTickerDetails = cloneDeep(
                    clonedData.ticker_details
                  );
                  let record =
                    tickerPicker &&
                    tickerPicker.filter((item) => item.ticker_id === val);
                  if (record && record[0]) {
                    clonedTickerDetails.ticker_id = record[0].ticker_id;
                    clonedTickerDetails.ticker_name = record[0].ticker_name;
                    clonedTickerDetails.ticker_description =
                      record[0].ticker_description;
                    if (record[0].ticker_fixed_title)
                      clonedTickerDetails.ticker_fixed_title =
                        record[0].ticker_fixed_title;
                  }

                  clonedData.ticker_details = clonedTickerDetails;
                  setTickerLocalData(clonedData);
                }}
              >
                {tickerPicker &&
                  tickerPicker.map((item, i) => {
                    return (
                      <Option
                        key={item.ticker_id + "_ticker"}
                        value={item.ticker_id}
                      >
                        {item.ticker_name}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="ticker_configuration_label">
              <p>Ticker Name</p>
            </Col>
            <Col span={18}>
              <Input
                name="tickerConfig_ticker_name"
                className="ticker_configuration_inputTitle"
                placeholder="Enter a name here"
                value={tickerLocalData?.ticker_details?.ticker_name}
                onChange={(e) =>
                  onChangeFields(
                    e.target.value,
                    "ticker_name",
                    "ticker_details"
                  )
                }
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_ticker_name")
                }
              />
              {validator.current.message(
                "Name",
                tickerLocalData?.ticker_details?.ticker_name,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="ticker_configuration_label">
              <p>Fixed Title</p>
            </Col>
            <Col span={18}>
              <Input
                name="tickerConfig_fixed_title"
                className="ticker_configuration_inputTitle"
                placeholder="Enter a name here"
                value={tickerLocalData?.ticker_details?.ticker_fixed_title}
                onChange={(e) =>
                  onChangeFields(
                    e.target.value,
                    "ticker_fixed_title",
                    "ticker_details"
                  )
                }
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_fixed_title")
                }
                minLength={1}
                maxLength={100}
              />
              {validator.current.message(
                "Fixed Title",
                tickerLocalData?.ticker_details?.ticker_fixed_title,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row
            className="ticker_configuration_row"
            style={{ borderBottom: "1px solid #EAE4F2", paddingBottom: "30px" }}
          >
            <Col span={6} className="ticker_configuration_label">
              <p>Ticker Text</p>
            </Col>
            <Col span={18}>
              <TextArea
                name="tickerConfig_ticker_text"
                id="tickerConfig_ticker_text"
                className="ticker_configuration_inputDescription"
                placeholder="Enter a ticker text here"
                rows={4}
                value={tickerLocalData?.ticker_details?.ticker_description}
                onChange={(e) =>
                  onChangeFields(
                    e.target.value,
                    "ticker_description",
                    "ticker_details"
                  )
                }
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_ticker_text")
                }
              />
              {validator.current.message(
                "Ticker Text",
                tickerLocalData?.ticker_details?.ticker_description,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="ticker_configuration_row">
            <Col span={6} className="ticker_configuration_label">
              <h6 style={{ color: "#000000", opacity: 1 }}>Link</h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Brand</p>
            </Col>
            <Col span={8}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                showSearch
                optionFilterProp="children"
                name="tickerConfig_link_brand"
                bordered={false}
                className="ticker_configuration_selectBrand"
                placeholder="Select a Brand"
                value={tickerLocalData?.ticker_link_details?.brand_id}
                onChange={(val) => {
                  let clonedData = cloneDeep(tickerLocalData);
                  let dataToUpdate = clonedData?.ticker_link_details;
                  dataToUpdate.brand_id = val;
                  dataToUpdate.reference_id = undefined;
                  dataToUpdate.reference_type = undefined;
                  setTickerLocalData({
                    ...tickerLocalData,
                    ticker_link_details: dataToUpdate,
                  });
                  handleAllDropdowns(
                    tickerLocalData,
                    "PERSON",
                    usersCollection
                  );
                }}
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_link_brand")
                }
              >
                {brandsCollection &&
                  brandsCollection.map((item, i) => {
                    return (
                      <Option
                        key={item.brand_id + "_brand"}
                        value={item.brand_id}
                      >
                        {item.brand_title}
                      </Option>
                    );
                  })}
              </Select>
              {validator.current.message(
                "Brand Name",
                tickerLocalData?.ticker_link_details?.brand_id,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Type</p>
            </Col>
            <Col span={8}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                showSearch
                optionFilterProp="children"
                name="tickerConfig_link_type"
                bordered={false}
                className="ticker_configuration_selectBrand"
                placeholder="Select a Type"
                value={tickerLocalData?.ticker_link_details?.reference_type}
                onChange={(val) => {
                  if (tickerLocalData?.ticker_link_details?.brand_id) {
                    handleAllDropdowns(tickerLocalData, val, usersCollection);
                    let clonedData = cloneDeep(tickerLocalData);
                    let dataToUpdate = clonedData?.ticker_link_details;
                    dataToUpdate.reference_type = val;
                    dataToUpdate.reference_id = undefined;

                    if (val === "BRAND")
                      dataToUpdate.reference_id = dataToUpdate.brand_id;

                    setTickerLocalData({
                      ...tickerLocalData,
                      ticker_link_details: dataToUpdate,
                    });
                  } else toast.error("Please select a brand");
                }}
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_link_type")
                }
              >
                <Option value="BRAND">BRAND</Option>
                <Option value="ZONE">ZONE</Option>
                <Option value="PERSON">USER</Option>
                <Option value="EVENT">EVENT</Option>
              </Select>
              {validator.current.message(
                "Type",
                tickerLocalData?.ticker_link_details?.reference_type,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
            <Col span={8} offset={1}>
              <p style={{ marginTop: "5px" }}>(Brand, Zone, Person, Event)</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Select</p>
            </Col>
            <Col span={8}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                showSearch
                optionFilterProp="children"
                name="tickerConfig_link_select"
                bordered={false}
                className="ticker_configuration_selectBrand"
                placeholder="Select"
                value={tickerLocalData?.ticker_link_details?.reference_id}
                onChange={(val) =>
                  onChangeFields(val, "reference_id", "ticker_link_details")
                }
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_link_select")
                }
              >
                {options &&
                  options.data &&
                  options.data.map((item) => {
                    return (
                      <Option
                        key={item[`${options.name}_id`]}
                        value={item[`${options.name}_id`]}
                      >
                        {options.name === "user" || options.name === "event"
                          ? item[`${options.name}_name`]
                          : item[`${options.name}_title`]}
                      </Option>
                    );
                  })}
              </Select>
              {validator.current.message(
                "Select",
                tickerLocalData?.ticker_link_details?.reference_id,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
            <Col span={8} offset={1}>
              <p style={{ marginTop: "5px" }}>(Brand, Zone, Person, Event)</p>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default TickerConfiguration;
