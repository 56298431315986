import React from "react";
import { Tooltip } from "antd";
import Text from "antd/lib/typography/Text";

export const currentTimeLineDiv = (liveTimeHeightMargin, scollToRef) => {
  return (
    <div
      ref={scollToRef}
      id="current_time_line"
      className="current_time_line"
      style={{ marginTop: liveTimeHeightMargin }}
    />
  );
};

export const getBlankCell = (props) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      className={props.className ? props.className : "w-50 cell_bgColor"}
      onClick={props.onClick}
    >
      {props.liveTimeCondition
        ? currentTimeLineDiv(props.liveTime.heightMargin)
        : null}
    </div>
  );
};

const timeLabel = (startTime, endTime) => {
  return (
    <Text className="text-white" style={{ fontSize: "0.6rem" }}>
      {startTime
        ? startTime.format("hh:mm A") + " - " + endTime.format("hh:mm A")
        : ""}
    </Text>
  );
};

const getTexts = (props) => {
  const { title, startTime, endTime } = props;
  return (
    <Text
      ellipsis
      className="text-white d-flex flex-column"
      style={{ fontSize: "0.6rem" }}
    >
      <label className="text-white text-wrap" style={{ fontSize: "0.6rem" }}>
        {title}
      </label>
      {timeLabel(startTime, endTime)}
    </Text>
  );
};

export const getCellText = (props) => {
  return (
    <Tooltip
      color={props.bgColor}
      placement="top"
      title={getTexts(props)}
      className="mx-2"
    >
      {getTexts(props)}
    </Tooltip>
  );
};
