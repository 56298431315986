import React from "react";

export const EditChat = (props) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.1515 3.84825C19.879 3.57691 19.5554 3.36224 19.1995 3.21666C18.8435 3.07108 18.4623 2.99746 18.0777 3.00007C17.6931 3.00267 17.3129 3.08144 16.959 3.23183C16.605 3.38222 16.2844 3.60124 16.0156 3.87625L4.41596 15.4759L3 20.9997L8.52383 19.583L20.1235 7.98333C20.3986 7.71464 20.6177 7.3941 20.7681 7.04022C20.9185 6.68634 20.9973 6.30615 20.9999 5.92163C21.0025 5.53711 20.9289 5.15589 20.7833 4.80001C20.6376 4.44412 20.4229 4.12064 20.1515 3.84825V3.84825Z"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6844 4.20825L19.7915 8.31533"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.41675 15.4751L8.52782 19.579"
          stroke="#808080"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
