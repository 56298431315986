import React, { useEffect, useState, useRef } from "react";
import { Button, Select, Switch, Input, Divider, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AddGroup } from "../../../components/svgJS/AddGroup";
import { CalenderGrey } from "../../../components/svgJS/CalenderGrey";
import { useMediaQuery } from "react-responsive";
import _, { cloneDeep, isEmpty } from "lodash";
import SimpleReactValidator from "simple-react-validator";
import { getUserTypeAgents } from "../../../utils/commonApis";
import { dialogs } from "../../../components/constsnts/string.constants";
import { toast } from "react-toastify";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../../components/constsnts/common.constants";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import AddUser from "../../UserManagement/AddUser";

const SocialWall = ({
  socialWallData,
  onChangeData,
  onValid,
  initiateValidations,
}) => {
  const validator = useRef(new SimpleReactValidator());
  const { Option } = Select;
  const { TextArea } = Input;
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const [agents, setAgents] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [dataLocal, setDataLocal] = useState({});
  const [loader, setLoader] = useState(false);
  const [isAddUserModalVisible1, setIsAddUserModalIsVisible1] = useState(false);
  const [openUserList, setOpenUsersList] = useState(false);

  useEffect(async () => {
    setLoader(true);
    window.scrollTo(0, 0);

    let newParamsForAgents = "";
    if (socialWallData?.event_id) {
      newParamsForAgents = {
        event_id: socialWallData?.event_id,
      };
    }
    const agentListAPI = await getUserTypeAgents(newParamsForAgents);
    setAgentsCollectionData(agentListAPI);
    setDataLocal(socialWallData);
    setLoader(false);
  }, []);

  useEffect(() => {
    if (!isEmpty(socialWallData)) {
      setDataLocal(socialWallData);
    }
  }, [socialWallData]);

  useEffect(() => {
    if (!isEmpty(agentsCollectionData))
      setAgentsFromAPIAndUseEffect(socialWallData);
  }, [agentsCollectionData]);

  const setAgentsFromAPIAndUseEffect = (dataHere) => {
    let agents_list = [];
    if (dataHere && !isEmpty(dataHere.social_wall_moderators)) {
      dataHere.social_wall_moderators.map((val) => {
        if (
          agentsCollectionData &&
          agentsCollectionData.some((agent) => agent?.user_id === val)
        ) {
          agents_list = agentsCollectionData;
          let selectedAgents_list = selectedAgents;
          let index = agents_list.findIndex((agent) => agent.user_id === val);
          selectedAgents_list.push(agents_list[index]);
          agents_list.splice(index, 1);
          selectedAgents_list = _.uniqWith(selectedAgents_list, _.isEqual);
          setSelectedAgents([...selectedAgents_list]);
          setAgents([...agents_list]);
        }
      });
    } else {
      setAgents(agentsCollectionData);
    }
  };

  useEffect(() => {
    if (initiateValidations) {
      handleFormSubmit();
    }
  }, [initiateValidations]);

  const handleFormSubmit = () => {
    if (validator.current.allValid()) {
      onValid(true);
    } else {
      onValid(false);
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
    }
  };

  const onHandleChange = (key1, value) => {
    onChangeData({ [key1]: value });
  };

  const onHandleMedia = async (key, value, item) => {
    let prevValue = _.cloneDeep(dataLocal.social_wall_sharing_details);
    let prevSearch = prevValue;
    let tempArray = [];
    prevSearch.forEach((element) => {
      if (element.social_media_id === item.social_media_id) {
        element[key] = value;
        element.sharing_setting_id = item.sharing_setting_id;
      }
      tempArray.push(element);
    });
    onHandleChange("social_wall_sharing_details", prevValue);
  };

  async function handleAgentsChange(value) {
    let agents_list = cloneDeep(agents);
    let selectedAgents_list = cloneDeep(selectedAgents);
    const filteredData = await agents_list.filter(
      (item) => item.user_id === value
    );
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);
    const agentsIdArr = selectedAgents_list?.map((elem, index) => elem.user_id);
    onHandleChange("social_wall_moderators", agentsIdArr);
    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
  }

  async function handleSelectedAgentsChange(value) {
    let agents_list = cloneDeep(agents);
    let selectedAgents_list = cloneDeep(selectedAgents);
    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);
    const agentsIdArr = selectedAgents_list?.map((elem) => elem.user_id);
    onHandleChange("social_wall_moderators", agentsIdArr);
  }

  if (loader) return <CommonLoader />;
  else if (dataLocal)
    return (
      <div className={`${Tablet ? "px-2" : "px-5"}container-fluid `}>
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Main Setting</h6>
            </div>
          </div>
          <section>
            <div className="row mt-4">
              <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                <span className="text-secondary">
                  Default status of new posts
                  <RedAsterick />
                </span>
              </div>
              <div className="col-9">
                <Switch
                  value={dataLocal?.default_status_new_post}
                  onChange={(e) =>
                    onHandleChange(
                      "default_status_new_post",
                      !dataLocal?.default_status_new_post
                    )
                  }
                  checked={
                    dataLocal.default_status_new_post
                      ? dataLocal.default_status_new_post
                      : false
                  }
                />
              </div>
              <div className={Tablet ? "col-3" : "col-2"}></div>
              <div className="col-10 my-2 text-start">
                {dataLocal?.default_status_new_post === true ? (
                  <p className="fs-14 my-2">
                    By default, posts are: "On until turned Off"
                  </p>
                ) : (
                  <p className="fs-14 my-2">
                    By default, posts are: "Off until turned On"
                  </p>
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                <span className="text-secondary ">
                  Title
                  <RedAsterick />
                </span>
              </div>
              <div className={Tablet ? "col-8" : "col-10 "}>
                <Input
                  id="social_wall_title"
                  onChange={(e) =>
                    onHandleChange("social_wall_title", e.target.value)
                  }
                  defaultValue={dataLocal.social_wall_title}
                  placeholder="Enter Title Here"
                  className={
                    validator?.current?.fields?.Title === false &&
                    validator?.current?.messagesShown === true
                      ? "w-50 fs-bold-14 border_red"
                      : "w-50 fs-bold-14"
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("social_wall_title")
                  }
                  maxLength={INPUT_TEXT_LIMITS.MAX}
                />
                {validator.current.message(
                  "Title",
                  dataLocal.social_wall_title,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                <span
                  className="text-secondary"
                  placeholder="Enter Description here"
                >
                  Description
                </span>
              </div>
              <div className={Tablet ? "col-8 " : "col-10"}>
                <TextArea
                  rows={4}
                  className="w-50 fs-bold-14"
                  defaultValue={dataLocal.social_wall_description}
                  onChange={(e) =>
                    onHandleChange("social_wall_description", e.target.value)
                  }
                  maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                />
              </div>
            </div>
          </section>
          <div className="row mt-4">
            <div className={`${Tablet ? "col-5" : "col-4"}`}>
              <div className="row">
                <div className={`${Tablet ? "" : "col-1"}`}></div>
                <div
                  className={`${Tablet ? "col-3" : "col-5"}  text-end`}
                ></div>
                <div className="col-3 text-start">
                  <b>Shareable </b>
                </div>
                <div
                  className={`${Tablet ? "col-3 ms-4" : "col-3"}  text-start`}
                >
                  <b>Auto </b>
                </div>
              </div>
            </div>
            <div className={`${Tablet ? "col-6" : "col-8"}`}>
              <div className="row">
                <div className="col-6">
                  <b>Re-post Handle / Account</b>
                </div>
                <div className="col-6">
                  <b>Re-post Added Text</b>
                </div>
              </div>
            </div>
          </div>

          {dataLocal.social_wall_sharing_details?.map((item, i) => {
            return (
              <div className="row mt-4">
                <div className="col-4">
                  <div className="row">
                    <div className="col-6 text-end">{item?.social_media}</div>
                    <div className={`${Tablet ? "p-0" : ""} col-3 text-start`}>
                      <Switch
                        onChange={(value) =>
                          onHandleMedia("enable_sharing", value, item)
                        }
                        checked={
                          item.enable_sharing ? item.enable_sharing : false
                        }
                      />
                    </div>
                    <div className="col-3 text-start">
                      <Switch
                        onChange={(value) =>
                          onHandleMedia("enable_auto_repost", value, item)
                        }
                        checked={
                          item.enable_auto_repost
                            ? item.enable_auto_repost
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    Tablet ? "social_wall_repost_input_tablet" : ""
                  } col-4`}
                >
                  <Input
                    defaultValue={item.repost_account}
                    onChange={(e, i) =>
                      onHandleMedia("repost_account", e.target.value, item)
                    }
                    placeholder="Enter handle or account name here"
                    className="fs-bold-14"
                  />
                </div>
                <div className="col-4">
                  <Input
                    defaultValue={item.repost_text}
                    onChange={(e, i) =>
                      onHandleMedia("repost_text", e.target.value, item)
                    }
                    placeholder="Enter Text here"
                    className="fs-bold-14"
                  />
                </div>
              </div>
            );
          })}
          <Divider />
        </section>
        {dataLocal &&
        dataLocal.event_id &&
        window.location.pathname.includes("/editevent") ? (
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Assign Moderators</h6>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2 text-end mt-4">
                <span className="text-secondary mt-4">Agents</span>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4 mt-2"}
                id="socialEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>All Agents</b>
                </label>
                <br />
                <Select
                  name="agentsSocialEdit"
                  showSearch
                  optionFilterProp="children"
                  style={{ width: Tablet ? "100%" : 300 }}
                  dropdownStyle={{ width: Tablet ? "100%" : 300 }}
                  placeholder="Search to Select"
                  onChange={handleAgentsChange}
                  listItemHeight={4}
                  listHeight={130}
                  open={openUserList}
                  onFocus={() => setOpenUsersList(true)}
                  onBlur={() => {
                    setOpenUsersList(false);
                    validator.current.showMessageFor("agentsSocialEdit");
                  }}
                  onSearch={() => setOpenUsersList(true)}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("socialEdit")
                  }
                  value="All Agents"
                >
                  {agents?.map((data, key) => (
                    <Option
                      className="select-text-dark"
                      key={key}
                      value={data?.user_id}
                    >
                      {data?.first_name} {data?.last_name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div
                className={Tablet ? "col-5" : "col-4"}
                id="selectedAgentsSocialEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>Tagged Agents</b>
                </label>
                <br />
                <Select
                  showSearch
                  optionFilterProp="children"
                  name="selectedAgentsSocialEdit"
                  style={{ width: Tablet ? "100%" : 300 }}
                  dropdownStyle={{ width: Tablet ? "100%" : 300 }}
                  placeholder="Search Tagged Agents"
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("selectedAgentsSocialEdit")
                  }
                  open={openUserList}
                  onFocus={() => setOpenUsersList(true)}
                  onBlur={() => setOpenUsersList(false)}
                  onSearch={() => setOpenUsersList(true)}
                  listHeight={100}
                  listItemHeight={4}
                  value="Tagged Agent"
                >
                  {selectedAgents?.map((data) => {
                    return (
                      <Option value={data?.user_id}>
                        {data?.first_name} {data?.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChange(data?.user_id)
                            }
                          />
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="row mt-4 border-bottom-gray pb-4">
              <div className="col-2"></div>
              <div className="col-4">
                <p className="fs-pink fs-12">OR</p>
                <Button
                  className="px-3  rounded btn-svg"
                  onClick={() => setIsAddUserModalIsVisible1(true)}
                >
                  <AddGroup fill="#ef0855" />
                  <span className="mx-2">Add Agents</span>
                </Button>
                <Modal
                  title="Add User"
                  visible={isAddUserModalVisible1}
                  onOk={() => setIsAddUserModalIsVisible1(false)}
                  onCancel={() => setIsAddUserModalIsVisible1(false)}
                  footer={null}
                  width={1100}
                >
                  <AddUser
                    event_id={socialWallData?.event_id}
                    onCancelFromModal={() => setIsAddUserModalIsVisible1(false)}
                    onSuccessFromModal={() =>
                      setIsAddUserModalIsVisible1(false)
                    }
                  />
                </Modal>
                <Button
                  className={
                    Tablet
                      ? "px-3  rounded mt-2 btn-svg"
                      : "px-3  rounded mt-2 d-block btn-svg"
                  }
                >
                  <CalenderGrey />
                  <span className="mx-2">Social Wall Moderator Calendar</span>
                </Button>
              </div>
            </div>
          </section>
        ) : null}
      </div>
    );
};

export default SocialWall;
