/**
 * MainContainer Styles
 */

import styled from "styled-components"

const Container = styled.div`
    position:absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default Container;