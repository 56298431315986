import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Popover,
  Breadcrumb,
  Input,
  Select,
  Tooltip,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SelectOutlined,
  SearchOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./index.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEventList, changeStatus } from "../../redux/slice/eventManagement";
import moment from "moment";
import { clearEventData } from "../../redux/slice/editEvent.js";
import { clearNewEventData } from "../../redux/slice/createEventNew.js";
import { Filter } from "../../components/svgJS/Filter";
import { cloneDeep, isEmpty } from "lodash";
import {
  getCustomerListForListing,
  getEventCreators,
} from "../../utils/commonApis";
import {
  createTZString,
  generateHelmet,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { PAGINATION_DEFAULT_SETTINGS } from "../../components/constsnts/common.constants";
import { toast } from "react-toastify";

const EventManagement = () => {
  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  let eventSelector = useSelector((state) => state.eventManagement.eventList);
  let loading = useSelector((state) => state.eventManagement.loading);
  const { Option } = Select;
  const [userType, setUserType] = useState("");
  const [searchData, setSearchData] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [creatorList, setCreatorList] = useState();
  const [fields, setFields] = useState({
    customer: null,
    id: null,
    is_customer: null,
    full_name: null,
  });
  const [page_no, set_page_no] = useState(PAGINATION_DEFAULT_SETTINGS.page_no);
  const [no_of_records, set_no_of_records] = useState(
    PAGINATION_DEFAULT_SETTINGS.no_of_records
  );

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes2 = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);
      let customerResponse = await getCustomerListForListing({
        is_details: false,
      });
      setCustomerList(customerResponse);
      window.scrollTo(0, 0);
      dispatch(getEventList({ page_no, no_of_records }));
      dispatch(clearEventData({}));
      dispatch(clearNewEventData({}));
    }
  }, [CryptoJS.AES, dispatch]);

  useEffect(async () => {
    const creatorParams = {
      customer_id: fields?.customer ?? null,
    };
    let eventCreatorResponse = await getEventCreators(creatorParams);
    setCreatorList(eventCreatorResponse);
  }, [fields?.customer]);

  const handleStatus = (id, bool) => {
    dispatch(changeStatus({ event_id: id, is_active: !bool }))
      .then((response) => {
        if (![200, 201, "200", "201"].includes(response?.payload?.data?.code)) {
          toast.error(response?.payload?.data?.message);
        }
      })
      .finally(() => {
        dispatch(getEventList({ page_no, no_of_records }));
      });
  };

  const tableData =
    eventSelector &&
    !isEmpty(eventSelector) &&
    eventSelector.rows &&
    eventSelector.rows.map((elem, i) => {
      const start_date_time_createdAt = createTZString(elem.created_at);
      const eleStartDateCreatedAt = moment(
        start_date_time_createdAt.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      return {
        key: "event_table_row_" + i.toString(),
        event_id: elem?.event_id ?? "-",
        domain: elem?.sub_domain ?? "-",
        name: elem?.event_name ?? "NA",
        start: elem?.event_show_start_date_time ?? "-",
        end: elem?.event_show_end_date_time ?? "-",
        brands: elem?.total_brands_count + "/" + elem?.max_no_of_exhibitors,
        users: elem?.total_users_count + "/" + elem?.max_no_of_users,
        storage:
          elem?.total_max_storage_size + "/" + elem?.max_storage_size + " gb" ??
          "-",
        streaming:
          elem?.total_max_bandwidth_per_month +
            "/" +
            elem?.max_bandwidth_per_month +
            " gb" ?? "-",
        videoHours:
          elem?.total_max_video_room_hours_per_month +
            "/" +
            elem?.max_video_room_hours_per_month +
            " hours" ?? "-",
        contact: elem?.customer.main_contact_person ?? "-",
        created_at: eleStartDateCreatedAt.format("DD-MM-YYYY"),
        created_by: elem?.created_user_name ?? "-",
        eventdomainname: elem?.sub_domain ?? "-",
        customername: elem?.customer.customer_name ?? "-",
        eventduration: elem?.event_duration + " Days",
        brand_title: elem?.events_organizer_brand_id?.brand_title ?? "-",
        is_active: elem?.is_active === true ? true : false,
        sub_domain_link: elem?.sub_domain_link ?? "",
      };
    });

  const columns = [
    {
      title: "ID",
      dataIndex: "event_id",
      key: "event_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 70,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 150,
      render: (text, record) => {
        return (
          <button
            className={
              record.is_active === true
                ? "bg-button-green active_btns"
                : "bg-button-red"
            }
            onClick={() => handleStatus(record.event_id, record.is_active)}
            shape="round"
          >
            {record.is_active === true ? "Active" : "Inactive"}
          </button>
        );
      },
      sorter: true,
    },
    {
      title: "Start",
      dataIndex: "start",
      key: "start",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 110,
    },
    {
      title: "End",
      dataIndex: "end",
      key: "end",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 110,
    },
    {
      title: "#Brands",
      dataIndex: "brands",
      key: "brands",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 105,
    },
    {
      title: "#Users",
      dataIndex: "users",
      key: "users",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 105,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Streaming",
      dataIndex: "streaming",
      key: "streaming",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Video Hours",
      dataIndex: "videoHours",
      key: "videoHours",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 130,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (contact) => (
        <Tooltip placement="topLeft" title={contact}>
          {contact}
        </Tooltip>
      ),
      align: "Left",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 160,
      columnWidth: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (created_by) => (
        <Tooltip placement="topLeft" title={created_by}>
          {created_by}
        </Tooltip>
      ),
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 140,
    },
    {
      title: "",
      dataIndex: "operation",
      width: 40,
      align: "Center",
      fixed: "right",
      render: (_, record) => (
        <Popover
          content={
            <div>
              <Link to={`viewevent/${record.event_id}`}>
                <p className="options_color">
                  <EyeOutlined className="mx-1" />
                  <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editevent/${record.event_id}`}>
                <p className="options_color">
                  <EditOutlined className="mx-1" />
                  <span className="">Edit Event</span>
                </p>
              </Link>
              {userType && userType === "CUSTOMER" ? (
                <p></p>
              ) : (
                <p
                  className="options_color cursor-pointer"
                  onClick={() => {
                    window.open(record?.sub_domain_link, "_blank");
                  }}
                >
                  <SelectOutlined className="mx-1" />
                  <span className=""> View Domain </span>
                </p>
              )}
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      width: 65,
      fixed: "right",
      render: (record) => (
        <div className="px-1">
          <Link to={`editevent/${record.event_id}`}>
            <p className="options_color m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    let sortObj = {
      page_no: pagination?.current ?? page_no,
      no_of_records,
    };
    if (
      pagination &&
      pagination.pageSize &&
      no_of_records !== pagination.pageSize
    ) {
      set_page_no(1);
      set_no_of_records(pagination.pageSize);
      sortObj = {
        page_no: 1,
        no_of_records: pagination.pageSize,
      };
    }

    sortObj = {
      ...sortObj,
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
      search: searchData,
      customer: fields?.customer,
      id: fields?.id,
      is_customer: fields?.is_customer,
      full_name: fields?.full_name,
    };
    dispatch(getEventList(sortObj));
  };

  const handleInputSearch = (e) => {
    setSearchData(e.target.value);
    const searchObj = {
      search: e.target.value,
      customer: fields?.customer,
      id: fields?.id,
      is_customer: fields?.is_customer,
      page_no,
      no_of_records,
    };
    dispatch(getEventList(searchObj));
  };

  const handleFieldsChange = (val, name) => {
    let cloneFields = cloneDeep(fields);
    if (name === "id") {
      cloneFields = {
        ...cloneFields,
        [name]: val?.id,
        is_customer: val?.is_customer,
        full_name: val?.full_name,
      };
    } else if (name === "customer") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        id: null,
        is_customer: null,
        full_name: null,
      };
    } else {
      cloneFields = { ...cloneFields, [name]: val };
    }
    setFields({ ...cloneFields });
    const searchObj = {
      search: searchData,
      customer: cloneFields?.customer,
      id: cloneFields?.id,
      is_customer: cloneFields?.is_customer,
      page_no,
      no_of_records,
    };
    dispatch(getEventList(searchObj));
  };

  return (
    <div>
      <div className="d-flex justify-content-between listing_upper_div">
        {generateHelmet("Gravit8", "Event Management")}
        <div>
          <h4 className="fs-24">Event Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Event Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Link to="createevent">
            <Button className="button-pink rounded px-3 py-1 mt-2">
              <span className="fw-bold"> + Create New Event </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="bg-white px-2 listing_lower_div">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50 mt-3">
            <Input
              size="medium"
              placeholder="Search Cust Name, Event Name, Brand Name, Created By"
              style={{ width: "130%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
              allowClear
            />
          </div>
          <div className="d-flex mt-3">
            <Button
              className=" rounded"
              onClick={() => setShowFilter(!showFilter)}
            >
              <Filter />
              <span className="ms-2"> FILTER</span> <DownOutlined />
            </Button>
          </div>
        </div>
        <div className="mb-2">
          {showFilter ? (
            <div>
              <Select
                name="customer"
                placeholder="Customer Name"
                style={{
                  width: 280,
                }}
                onChange={(val) => handleFieldsChange(val, "customer")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.customer}
              >
                {customerList?.map((data) => {
                  return (
                    <Option value={data.customer_id}>
                      {data.account_name}
                    </Option>
                  );
                })}
              </Select>

              <Select
                name="creatorName"
                placeholder="Creator Name"
                className="ms-2"
                value={fields?.full_name ?? null}
                onChange={(id) => {
                  if (id) {
                    handleFieldsChange(JSON.parse(id), "id");
                  } else {
                    handleFieldsChange(null, "id");
                  }
                }}
                style={{
                  width: 280,
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
              >
                {creatorList &&
                  creatorList?.map((data) => {
                    return (
                      <Option value={JSON.stringify(data)}>
                        {data.full_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          ) : null}
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            showSizeChanger: true,
            pageSize: no_of_records,
            total: eventSelector?.count ? eventSelector.count : 1,
          }}
          loading={{
            indicator: <CommonLoader />,
            spinning: loading,
          }}
          scroll={{ x: 1300, y: "58vh" }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default EventManagement;
