import React from "react";

export const Calendar6 = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="#000000"
			>
				<g id="Group_1096" data-name="Group 1096">
					<g id="Group_1095" data-name="Group 1095">
						<path id="Path_617" data-name="Path 617" d="M16.875,1.563H15.859V.781a.781.781,0,0,0-1.562,0v.781H10.742V.781a.781.781,0,1,0-1.562,0v.781H5.664V.781A.781.781,0,1,0,4.1.781v.781H3.125A3.129,3.129,0,0,0,0,4.688V16.875A3.129,3.129,0,0,0,3.125,20H9.1a.781.781,0,1,0,0-1.562H3.125a1.564,1.564,0,0,1-1.562-1.562V4.688A1.564,1.564,0,0,1,3.125,3.125H4.1v.781a.781.781,0,1,0,1.563,0V3.125H9.18v.781a.781.781,0,1,0,1.562,0V3.125H14.3v.781a.781.781,0,0,0,1.563,0V3.125h1.016a1.564,1.564,0,0,1,1.563,1.563V9.141a.781.781,0,1,0,1.563,0V4.688A3.129,3.129,0,0,0,16.875,1.563Z"/>
						<path id="Path_618" data-name="Path 618" d="M274.906,270a4.906,4.906,0,1,0,4.906,4.906A4.912,4.912,0,0,0,274.906,270Zm0,8.191a3.284,3.284,0,1,1,3.284-3.284A3.288,3.288,0,0,1,274.906,278.191Z" transform="translate(-259.813 -259.813)" />
						<path id="Path_619" data-name="Path 619" d="M372.987,331.662h-.365v-.852a.811.811,0,1,0-1.622,0v1.662a.811.811,0,0,0,.811.811h1.176a.811.811,0,0,0,0-1.622Z" transform="translate(-356.717 -317.202)" />
						<circle id="Ellipse_5" data-name="Ellipse 5" cx="0.86" cy="0.86" r="0.86" transform="translate(7.516 10.23)" />
						<circle id="Ellipse_14" data-name="Ellipse 14" cx="0.86" cy="0.86" r="0.86" transform="translate(7.516 13.533)" />
						<circle id="Ellipse_9" data-name="Ellipse 9" cx="0.86" cy="0.86" r="0.86" transform="translate(4.192 6.926)" />
						<circle id="Ellipse_11" data-name="Ellipse 11" cx="0.86" cy="0.86" r="0.86" transform="translate(7.496 6.926)" />
						<circle id="Ellipse_12" data-name="Ellipse 12" cx="0.86" cy="0.86" r="0.86" transform="translate(10.799 6.926)" />
						<circle id="Ellipse_13" data-name="Ellipse 13" cx="0.86" cy="0.86" r="0.86" transform="translate(14.102 6.926)" />
						<circle id="Ellipse_6" data-name="Ellipse 6" cx="0.86" cy="0.86" r="0.86" transform="translate(4.192 13.54)" />
						<circle id="Ellipse_10" data-name="Ellipse 10" cx="0.86" cy="0.86" r="0.86" transform="translate(4.192 10.23)" />
					</g>
				</g>
			</svg>
		</>
	);
};
