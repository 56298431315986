import _ from "lodash";
import moment from "moment";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
export async function mapSelectedDdlData(
  idsFromAPIs,
  selectedIds,
  clonedData,
  keyToCheck
) {
  return new Promise(function (resolve, reject) {
    if (idsFromAPIs) {
      let mainList = clonedData;
      let selectedList = selectedIds;
      idsFromAPIs.map((val) => {
        clonedData &&
          clonedData.forEach((ele) => {
            if (ele[keyToCheck] === val) {
              let index = mainList.findIndex((ele) => ele[keyToCheck] === val);
              selectedList.push(mainList[index]);
              mainList.splice(index, 1);
            }
          });
      });
      selectedList = _.uniqWith(selectedList, _.isEqual);
      resolve({ mainList, selectedList });
    } else {
      reject({ mainList: clonedData, selectedList: [] });
    }
  });
}
