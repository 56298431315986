import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_CREATE_DATA = {
  event_id: "",
};

const initialState = {
  createEventData: INITIAL_CREATE_DATA,
};

export const createEventNewSlice = createSlice({
  name: "createEventData",
  initialState,
  reducers: {
    storeNewEventData(state, { payload }) {
      if (payload.keyToUpdate === "main")
        state.createEventData = { ...state.createEventData, ...payload.data };
      else
        state.createEventData[payload.keyToUpdate] = {
          ...state.createEventData[payload.keyToUpdate],
          ...payload.data,
        };
    },
    clearNewEventData(state, { payload }) {
      state.createEventData = INITIAL_CREATE_DATA;
    },
    getNewCurrentData(state, { payload }) {
      return state.createEventData;
    },
  },
});

export const { getNewCurrentData, storeNewEventData, clearNewEventData } =
  createEventNewSlice.actions;
export default createEventNewSlice.reducer;
