import React from "react";

export const Bin = props => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.214"
        height="20"
        viewBox="0 0 16.214 20"
        fill={props.fill}
        className="svg_bin"
      >
        <g id="bin" transform="translate(-48.466)">
          <path
            id="Path_49249"
            data-name="Path 49249"
            d="M284.456,171.65l-.441,8.324a.781.781,0,0,0,.739.822h.042a.781.781,0,0,0,.78-.74l.441-8.324a.781.781,0,1,0-1.56-.083Z"
            transform="translate(-226.347 -164.234)"
          />
          <path
            id="Path_49250"
            data-name="Path 49250"
            d="M177.438,170.912a.781.781,0,0,0-.739.821l.441,8.324a.781.781,0,0,0,.779.74h.042a.781.781,0,0,0,.739-.821l-.441-8.324A.781.781,0,0,0,177.438,170.912Z"
            transform="translate(-123.223 -164.235)"
          />
          <path
            id="Path_49251"
            data-name="Path 49251"
            d="M63.9,3.239H59.763V3.187A3.19,3.19,0,0,0,56.576,0h-.007a3.19,3.19,0,0,0-3.187,3.187v.052H49.247a.781.781,0,1,0,0,1.562H49.5l.648,12.239A3.125,3.125,0,0,0,53.269,20h6.607A3.124,3.124,0,0,0,63,17.041v-.007a.781.781,0,0,0-1.56-.084v.007a1.562,1.562,0,0,1-1.56,1.48H53.269a1.562,1.562,0,0,1-1.56-1.48L51.065,4.8H62.081l-.439,8.282a.781.781,0,0,0,1.56.083L63.645,4.8H63.9a.781.781,0,1,0,0-1.562Zm-8.953,0V3.187a1.626,1.626,0,0,1,1.624-1.624h.007A1.626,1.626,0,0,1,58.2,3.187v.052Z"
          />
        </g>
      </svg>
    </>
  );
};

export const BinWhite = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.214"
        height="20"
        viewBox="0 0 16.214 20"
        fill="#ffffff"
      >
        <g id="bin" transform="translate(-48.466)">
          <path
            id="Path_49249"
            data-name="Path 49249"
            d="M284.456,171.65l-.441,8.324a.781.781,0,0,0,.739.822h.042a.781.781,0,0,0,.78-.74l.441-8.324a.781.781,0,1,0-1.56-.083Z"
            transform="translate(-226.347 -164.234)"
          />
          <path
            id="Path_49250"
            data-name="Path 49250"
            d="M177.438,170.912a.781.781,0,0,0-.739.821l.441,8.324a.781.781,0,0,0,.779.74h.042a.781.781,0,0,0,.739-.821l-.441-8.324A.781.781,0,0,0,177.438,170.912Z"
            transform="translate(-123.223 -164.235)"
          />
          <path
            id="Path_49251"
            data-name="Path 49251"
            d="M63.9,3.239H59.763V3.187A3.19,3.19,0,0,0,56.576,0h-.007a3.19,3.19,0,0,0-3.187,3.187v.052H49.247a.781.781,0,1,0,0,1.562H49.5l.648,12.239A3.125,3.125,0,0,0,53.269,20h6.607A3.124,3.124,0,0,0,63,17.041v-.007a.781.781,0,0,0-1.56-.084v.007a1.562,1.562,0,0,1-1.56,1.48H53.269a1.562,1.562,0,0,1-1.56-1.48L51.065,4.8H62.081l-.439,8.282a.781.781,0,0,0,1.56.083L63.645,4.8H63.9a.781.781,0,1,0,0-1.562Zm-8.953,0V3.187a1.626,1.626,0,0,1,1.624-1.624h.007A1.626,1.626,0,0,1,58.2,3.187v.052Z"
          />
        </g>
      </svg>
    </>
  );
};
